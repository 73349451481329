import { componentBlockConfig, templateInfo } from 'templates/types';
import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';
import { createDefaultInAppConfig } from 'templates/utils';

/**
 * used in setting initial inapp api data
 * and also when watching form value changes
 */
const defaultValues = {
  // state_1
  state_1_title: 'Title',
  state_1_icon: '',
  state_1_icon_select: 'file',
  state_1_message: '',
  state_1_url: '',
  state_1_input1_placeholder: 'Enter',
  state_1_button1_label: 'reply',

  // preview
  preview_title: '<span><b>John Doe</b> commented on your post</span>',
  preview_button1_label: 'Reply',

  // state_2
  state_2_title: 'Notification title',
  state_2_icon: '',
  state_2_icon_select: 'file',
  state_2_message: '',
  state_2_url: '',

  // enabled
  state_1_button1_active: false,
  state_1_input1_active: false,
};

const info: templateInfo = {
  template: 'one_input',
  category: 'form',
  tags: ['content'],
};

const stateConfig = [
  { key: 'state_1', label: 'State 1' },
  { key: 'state_2', label: 'State 2' },
];

const stateToSettingsMapping = {
  state_2: 'state_1_button1_active',
  reject: 'state_1_button2_active',
};

const getBlockConfig = (values = defaultValues): componentBlockConfig => {
  return {
    state_1: [
      {
        id: 'state_1_input1_placeholder',
        type: 'textarea' as const,
        text: values.state_1_input1_placeholder,
        variant: 'primary',
      },
      {
        id: 'state_1_button1_label',
        type: 'button' as const,
        text: values.state_1_button1_label,
        variant: 'primary',
      },
    ],
    state_2: [],
  };
};

const state_1Config = createDefaultInAppConfig();
const { template } = state_1Config;

export const consoleConfig = {
  state_1: {
    template: [
      template[0],
      template[1],
      template[2],
      {
        type: 'div',
        name: 'parent',
        children: [
          {
            label: 'Input placeholder',
            required: false,
            placeholder: 'Enter placeholder',
            name: 'state_1_input1_placeholder',
            type: 'input',
            additionalSettings: {
              label: 'Input_id',
              required: false,
              placeholder: 'switch',
              name: 'state_1_input1_active',
              type: 'switch',
              additionalSettingsEnableHidden: true,
            },
          },
          {
            label: 'Button 1',
            required: false,
            placeholder: 'Enter label',
            name: 'state_1_button1_label',
            type: 'input',
            additionalSettings: {
              label: 'Button_id',
              required: false,
              placeholder: 'switch',
              name: 'state_1_button1_active',
              type: 'switch',
            },
          },
        ],
      },
      template[3],
    ],
    batched_template: {},
  },
  state_2: createDefaultInAppConfig({ namePrefix: 'state_2' }),
};

export const oneInputTemplate = {
  blockConfig: (values: any) => getBlockConfig(values),
  states: stateConfig,
  consoleInputs: consoleConfig as inAppTemplate,
  defaultValues,
  info,
  stateToSettingsMapping,
};
