import useSaveProvider from 'api/providers/save-provider';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import { Otp, otpInputStyle } from 'components/otp/Otp';
import { useForm } from 'react-hook-form';

function MobileNumberModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const saveProvider = useSaveProvider();

  const { handleSubmit, reset } = useForm({
    reValidateMode: 'onChange',
  });

  const handleClose = () => {
    reset({});
    onClose();
  };

  const onSubmit = () => {};

  return (
    <CustomModal
      modalCardClassName="!min-w-[465px] !max-w-[525px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="mb-6 w-full px-7">
        <h1 className="mb-2 text-center  text-2xl font-semibold">
          Mobile Number
        </h1>
        <p className="mb-9 text-center  text-sm   font-semibold capitalize text-[#BFBFBF]">
          Save this emergency backup code and store it somewhere safe. <br />
          if you lose your can backup codes to sign in
        </p>

        <div className="w-full justify-center rounded-[10px] bg-[#26292F] p-6 leading-6">
          <p className="mb-3  text-sm capitalize text-[#BFBFBF]">
            we’ll text a verification code to this mobile number <br /> whenever
            you sign in to your account
          </p>

          <div className="mb-4">
            <p className="mb-2  text-sm font-bold capitalize text-[#BFBFBF]">
              Phone Number
            </p>

            <div className="flex gap-3">
              <input
                type="text"
                className="w-full rounded-xl bg-night-100 px-3 py-2 outline-none"
              />
              <button className="linear flex w-fit justify-center rounded-xl  bg-brand-500 px-3 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700">
                Send
              </button>
            </div>
          </div>

          <div>
            <p className="mb-2  text-sm font-bold capitalize text-[#BFBFBF]">
              OTP
            </p>

            {/* verification */}
            <div className="mb-4 flex w-full items-center">
              <Otp
                length={5}
                inputStyle={{
                  ...otpInputStyle,
                  background: '#111315',
                }}
              />
            </div>

            <p className="text-sm font-semibold text-[#B22977]">Resent OTP</p>
          </div>
        </div>
      </div>

      <form className="mt-2 px-7" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-4 ">
          <button
            className="linear flex w-full justify-center rounded-xl bg-night-100 py-3 text-base font-medium text-white transition duration-200  dark:text-white "
            type="button"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            className="linear flex w-full justify-center rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
            type="submit"
            disabled={saveProvider.isLoading}
          >
            Verify
            {saveProvider.isLoading && <LoaderSpinner />}
          </button>
        </div>
      </form>
    </CustomModal>
  );
}

export default MobileNumberModal;
