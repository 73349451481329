import { Check, ChevronsUpDown } from 'lucide-react';
import * as React from 'react';
import { Noop } from 'react-hook-form';

import { SelectOptions } from 'components/fields/SelectField/types';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { Button } from 'components/shadcn/button';
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
} from 'components/shadcn/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';
import { ScrollArea } from 'components/shadcn/scroll-area';
import { cn } from 'utils/class-merge';
import { R } from 'utils/remeda-utils';

export const useSelectSearch = <T,>({
  results,
  isLoading,
  createOptions,
}: {
  results: T[];
  isLoading: boolean;
  createOptions?: (item: T) => SelectOptions;
}) => {
  const [options, setOptions] = React.useState<SelectOptions[]>([]);
  const [selectedOption, setSelectedOption] = React.useState<SelectOptions>();

  const baseOptions = React.useMemo(
    () => results?.map(item => createOptions(item)) ?? [],
    [createOptions, results],
  );

  // initial and search state
  React.useEffect(() => {
    // Helper function to include the selected option if it's not already present
    const ensureSelectedOption = (
      options: SelectOptions[],
      selectedOption?: SelectOptions,
    ) => {
      if (!selectedOption) return options;
      return R.uniqueBy([...options, selectedOption], option => option);
    };

    if (isLoading) return;

    setOptions(prevOptions => {
      // Ensure selectedOption is included in both baseOptions and prevOptions
      const baseWithSelected = ensureSelectedOption(
        baseOptions,
        selectedOption,
      );
      const prevWithSelected = ensureSelectedOption(
        prevOptions,
        selectedOption,
      );

      if (R.isDeepEqual(prevWithSelected, baseWithSelected)) return prevOptions;
      return baseOptions;
    });
  }, [baseOptions, isLoading, options.length, selectedOption]);

  // selecting new option
  React.useEffect(() => {
    if (isLoading) return;

    const alreadyHasSelectOption = baseOptions.some(
      option => option.value === selectedOption?.value,
    );

    if (!alreadyHasSelectOption && selectedOption) {
      setOptions(prevOptions => {
        const newOptions = [...baseOptions, selectedOption];

        if (R.isDeepEqual(prevOptions, newOptions)) {
          return prevOptions;
        }

        return newOptions;
      });
    }
  }, [baseOptions, isLoading, selectedOption]);

  return {
    options,
    setSelectedOption,
    selectedOption,
    setOptions,
  };
};

export function SelectSearchField({
  emptyResultMessage = 'No results found.',
  placeholder = 'Select',
  label,
  helperText,
  showIsRequiredAsterisk,
  setFilter,
  filter,
  isFetching,
  value,
  onChange,
  onBlur,
  dataOptions,
  commandInputPlaceholder = 'Search...',
  labelClassName,
  popOverClassName,
}: {
  emptyResultMessage?: string;
  placeholder?: string;
  label: string;
  helperText?: string;
  showIsRequiredAsterisk?: boolean;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
  filter: string;
  isFetching: boolean;
  value: any;
  onChange: (event: any) => void;
  onBlur: Noop;
  dataOptions?: SelectOptions[];
  commandInputPlaceholder?: string;
  labelClassName?: string;
  popOverClassName?: string;
}) {
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');
  const [timer, setTimer] = React.useState(null);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {label && (
        <label
          className={cn(
            `text-sm text-white ml-1.5 font-medium`,
            labelClassName,
          )}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}

      {helperText && (
        <p
          className="ml-1 text-sm text-white opacity-[35%] mb-1"
          dangerouslySetInnerHTML={{
            __html: helperText,
          }}
        ></p>
      )}

      <PopoverTrigger asChild>
        <Button
          type="button"
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'flex  mt-2 justify-between text-white w-full h-[43px] rounded-md border border-gray-440 bg-night-400 hover:bg-night-400/80 px-3 py-3 text-sm',
            popOverClassName,
          )}
        >
          {value
            ? dataOptions?.find(framework => framework.value === value)?.label
            : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>

      <PopoverContent className={cn('p-0 z-[1004] popover-content-width-full')}>
        <Command
          filter={(value, search) => {
            const sanitizedSearch = search.replace(
              /[-/\\^$*+?.()|[\]{}]/g,
              '\\$&',
            );

            const searchRegex = new RegExp(sanitizedSearch, 'i');

            const label =
              dataOptions?.find(option => option.value === value)?.label || '';

            return searchRegex.test(label) ? 1 : 0;
          }}
          className="relative"
        >
          {filter && isFetching && (
            <div className="absolute right-5 top-[10px]">
              <LoaderSpinner
                width="20"
                strokeColor="gray"
                parentClass="relative left-2"
              />
            </div>
          )}

          <CommandInput
            value={inputValue}
            onValueChange={value => {
              setInputValue(value);

              if (timer !== null) {
                clearTimeout(timer);
              }

              let searchTimer = setTimeout(() => {
                setFilter(value);
              }, 300);

              setTimer(searchTimer);
            }}
            onBlur={() => {
              setOpen(false);
              onBlur();
            }}
            onFocus={() => setOpen(true)}
            placeholder={commandInputPlaceholder}
          />

          <ScrollArea className="max-h-[185px] w-full rounded-md border overflow-auto">
            {dataOptions?.length === 0 && (
              <div className="py-6 text-center text-sm">
                {emptyResultMessage}
              </div>
            )}

            <CommandGroup className={`h-full  w-full`}>
              {dataOptions?.map(options => (
                <CommandItem
                  onMouseDown={e => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  key={options.value}
                  value={options.value}
                  onSelect={currentValue => {
                    onChange(currentValue === value ? '' : currentValue);
                    setInputValue('');
                    setFilter('');
                    setOpen(false);
                  }}
                >
                  <Check
                    className={cn(
                      'mr-2 h-4 w-4',
                      value === options.value ? 'opacity-100' : 'opacity-0',
                    )}
                  />
                  {options.label}
                </CommandItem>
              ))}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
