import { useGetEmailLayouts } from 'api/layouts';
import { getTemplateDataFromJson } from 'api/templates/get-template-json';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useLayoutStore } from 'store/layoutStore';
import { R } from 'utils/remeda-utils';
import { TemplateEditorAvailabeChannels } from '../variables/inputs';

export const useExtractEmailLayouts = () => {
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const [selectedDocumentLayout, setSelectedDocumentLayout] = React.useState();
  const { layoutIdentifier } = useLayoutStore(state => state);
  const getEmailLayouts = useGetEmailLayouts(true, channel === 'email');

  React.useEffect(() => {
    if (getEmailLayouts.isLoading) return;

    const selectedLayout = R.find(
      getEmailLayouts.data ?? [],
      layout => layout?.identifier === layoutIdentifier,
    );

    const data = selectedLayout?.jsonSpec?.bodyJsonTemplate;

    if (data) {
      try {
        getTemplateDataFromJson(data).then(r => {
          setSelectedDocumentLayout(r);
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, [getEmailLayouts.data, getEmailLayouts.isLoading, layoutIdentifier]);

  return selectedDocumentLayout;
};
