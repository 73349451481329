import { REGIONS } from 'variables/regions';

export type CodeContent = {
  apiKey: string;
  apiSecret: string;
  workflowIdentifier: string;
  recipient: string;
  region: string;
};

export const getFrontEndCodeSamples = (args: CodeContent) => {
  const isEURegion = args.region === REGIONS['EU_CENTRAL_1'];

  return {
    HTML_JS: `<script type="text/javascript" src="https://cdn.engagespot.com/in-app-inbox-v1.x.min.js"></script>
<script>
Engagespot.render('#HTML_Element_ID',{
   apiKey: "${args.apiKey}",
   userId: "unique-user-id", //Your user's unique identifier/email
   ${isEURegion && 'dataRegion: "eu"'}
  });
</script>`,

    react: `import {Engagespot} from "@engagespot/react-component";

<Engagespot 
  apiKey="${args.apiKey}" 
  userId="unique-user-id"
  ${isEURegion && 'dataRegion="eu"'}
/>`,

    nextjs: `'use client';

import {Engagespot} from "@engagespot/react-component";

<Engagespot 
  apiKey="${args.apiKey}" 
  userId="unique-user-id"
  ${isEURegion && 'dataRegion="eu"'}
/>`,

    angular: `import { Component, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { render } from '@engagespot/client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent implements AfterViewInit {
  @ViewChild('engagespotBellIcon') engagespotBellIcon: ElementRef;

  ngAfterViewInit() {
    render(this.engagespotBellIcon.nativeElement, {
      apiKey: "${args.apiKey}",
      userId: "YOUR_USERS_UNIQUE_ID",
      ${isEURegion && 'dataRegion: "eu"'}
    });
  }
}`,
  };
};

export const getBackendCodeSamples = (args: CodeContent) => {
  const isEURegion = args.region === REGIONS['EU_CENTRAL_1'];

  return {
    node: `const { EngagespotClient } = require('@engagespot/node');
const client = EngagespotClient({
  apiKey: '${args.apiKey}',
  apiSecret: '${args.apiSecret}',
  ${isEURegion && "dataRegion: 'eu'"}
});

client.send({
  notification: {
    workflow: {
      identifier: '${args.workflowIdentifier}',
    },
  },
  sendTo: {
    recipients: ['${args.recipient}'],
  },
});`,
    bash: `curl --location --request POST '${isEURegion ? 'https://api-eu.engagespot.co/v3/notifications' : 'https://api.engagespot.co/v3/notifications'}' \\
--header 'X-ENGAGESPOT-API-SECRET: ${args.apiSecret}' \\
--header 'X-ENGAGESPOT-API-KEY: ${args.apiKey}' \\
--header 'Content-Type: application/json' \\
--data-raw '{ 
    "notification":{ 
      "workflow":{ 
          "identifier":"${args.workflowIdentifier}" 
      }
    },
    "sendTo":{ 
      "recipients":["${args.recipient}"] 
    }
}'`,
    python: `from engagespot import Engagespot
client = Engagespot(api_key="${args.apiKey}", api_secret="${args.apiSecret}"${isEURegion ? ', data_region="eu"' : ''})
send_request = {
    "notification": {
        "workflow":{
            "identifier":"${args.workflowIdentifier}"
        }
    },
    "sendTo":{
        "recipients": ["${args.recipient}"]
    }
}
client.send(send_request)`,
    php: `use Engagespot\\EngagespotClient;
$config = [
'apiKey' => '${args.apiKey}',
'apiSecret' => '${args.apiSecret}',
${isEURegion && "'dataRegion' => 'eu'"}
];
$engagespot = new EngagespotClient($config);
$notificationData = [
  'notification' => [
      'workflow' => [
         'identifier' =>'${args.workflowIdentifier}'
      ],
  ],
  'sendTo' => [
    'recipients' => ['${args.recipient}'],
  ],
];
$engagespot->send($notificationData);
    `,
    ruby: `engagespot = Engagespot.new(api_key: '${args.apiKey}',
api_secret: '${args.apiSecret}'${isEURegion ? `, data_region:'eu'` : ''} ) 

send_request = {
  notification:{
      workflow: {
          identifier: "${args.workflowIdentifier}"
      },
  },
  sendTo:{
      recipients: ['${args.recipient}']
  }
}
engagespot.send(send_request)`,
  };
};

export const codeContent = (args: CodeContent) => {
  const backendCodeSamples = getBackendCodeSamples(args);
  const frontEndCodeSamples = getFrontEndCodeSamples(args);

  return {
    ...backendCodeSamples,
    ...frontEndCodeSamples,
  };
};

export type TKeyCodeContent = keyof ReturnType<typeof codeContent>;
