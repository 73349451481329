export const CountCards = ({
  className,
  content,
  heading,
}: {
  heading: string;
  content: string;
  className: string;
}) => {
  return (
    <div
      className={`relative flex flex-col border border-input  justify-between rounded-[20px] bg-night-100 p-9 pb-8  text-white ${className}`}
    >
      <h3 className={`mb-1 text-[1.97rem] font-bold`}>{heading}</h3>
      <p className={`mb-3 text-lg font-bold text-gray-600`}>{content}</p>
    </div>
  );
};
