import { Button } from 'components/shadcn/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/shadcn/dropdown-menu';
import { MoreHorizontal } from 'lucide-react';

type TableMenuConfig = {
  text: string;
  action: () => void;
  disabled?: boolean;
};

const TableMenu = ({ config }: { config: TableMenuConfig[] }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="h-8 w-8 p-0">
          <span className="sr-only">Open menu</span>
          <MoreHorizontal className="h-4 w-4 text-white" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        {config.map(menu => (
          <DropdownMenuItem
            key={menu.text}
            disabled={menu.disabled}
            onClick={e => {
              e.stopPropagation();
              menu?.action();
            }}
          >
            {menu?.text}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default TableMenu;
