import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';

type PatchUserDetails = {
  clientId: number;
  profilePicture?: string;
  firstName?: string;
  lastName?: string;
  timezone?: string;
};

const patch = async (values: PatchUserDetails) => {
  const { clientId, ...data } = values;
  return axiosGlobalInstance.patch(
    `${routes['clientUsers']}/${clientId}`,
    data,
  );
};

const usePatchUserDetails = () => {
  return useMutation({
    mutationFn: patch,
  });
};

export default usePatchUserDetails;
