import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';

type AddWorkspace = {
  name: string;
  region: string;
};

interface PostAddWorkspaceResponse {
  message: string;
  client: Client;
}

interface Client {
  name: string;
  version: string;
  licenseAuthToken: null;
  region: string | null;
  status: null;
  id: number;
  createdAt: string;
  updatedAt: string;
  defaultApp: number;
}

const post = async (data: AddWorkspace) => {
  return axiosGlobalInstance.post<PostAddWorkspaceResponse>(
    `${routes['clients']}`,
    data,
  );
};

export const usePostAddWorkspace = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: post,
    onSuccess: response => {
      queryClient.invalidateQueries([`${routes['clients']}`]);
    },
  });
};
