import { EDITIONS } from 'variables/editions';

export const getEdition = () => {
  return __EDITION__;
};

export const isEnterpriseEdition = () => {
  return __EDITION__ === EDITIONS['enterprise'];
};

export const isCommunityEdition = () => {
  return __EDITION__ === EDITIONS['community'];
};

export const isSaaS = () => {
  return __IS_SAAS__ === 'true';
};
