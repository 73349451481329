import { RotatingLines } from 'react-loader-spinner';

const LoaderSpinner = ({
  strokeColor = 'white',
  strokeWidth = '5',
  animationDuration = '0.75',
  width = '22',
  visible = true,
  parentClass = 'relative left-4',
}) => {
  return (
    <div className={parentClass}>
      <RotatingLines
        strokeColor={strokeColor}
        strokeWidth={strokeWidth}
        animationDuration={animationDuration}
        width={width}
        visible={visible}
      />
    </div>
  );
};

export default LoaderSpinner;
