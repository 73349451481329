export const AnalyticsCard = ({
  icon,
  heading,
  text,
  iconWidth = 16,
}: {
  icon: string;
  heading: string;
  text: string;
  iconWidth?: number;
}) => {
  return (
    <div className=" bg-night-100  rounded-[20px] py-4 px-5 flex flex-col gap-2 items-center min-w-[130px]">
      <img width={iconWidth} src={icon} alt="" />
      <h5 className="font-semibold text-2xl relative top-1">{heading}</h5>
      <p className="font-medium mt-0 text-lg">{text}</p>
    </div>
  );
};
