import { useStore } from '@nanostores/react';
import { $currentTemplate, $templateConfigCurrentState } from 'templates/store';
import { useParams } from 'react-router-dom';
import {
  createUniqueTemplateBlockId,
  getBlockActivateName,
  getBlockIsExternalLinkName,
  getBlockLinkName,
} from 'templates/utils';
import { allTemplateDetails } from 'templates';
import Copy from '@assets/svg/common/copy.svg';
import { useDisclosure } from '@chakra-ui/hooks';
import Arrow from 'assets/svg/template/arrow.svg';
import Popover from 'components/popover';
import Switch from 'components/switch';
import React from 'react';
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { copyToClipboard } from '../../apiCredentials/functions';
import { TemplateInputs } from '../variables/inputs';
import { TemplateTabs } from 'store/templateStore';
import InputField from 'components/fields/InputField';
import { R } from 'utils/remeda-utils';

export const AdditionalSettings = ({
  option,
  control,
  optionName,
  tab,
  register,
}: {
  option: TemplateInputs;
  control: Control<FieldValues, any>;
  optionName: string;
  tab: TemplateTabs;
  register: UseFormRegister<FieldValues>;
}) => {
  /** Uncomment if need to be collapsed based on additional settings value */
  // const isEnabled = useWatch({
  //   control,
  //   name: option?.additionalSettings?.name,
  // });
  // const [isCollapsed, setIsCollapsed] = React.useState(!isEnabled);
  /** */

  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const params = useParams();
  const templateIdentifier = params.templateIdentifier;
  const templateState = useStore($templateConfigCurrentState);
  const batchingState = tab === 'template' ? 0 : 1;

  const currentTemplate = useStore($currentTemplate);
  const templateBlocksConfig =
    allTemplateDetails?.[currentTemplate]?.['blockConfig']?.(undefined);
  const index = templateBlocksConfig[templateState].findIndex(
    config => config.id === optionName,
  );

  const uniqueId = createUniqueTemplateBlockId({
    templateIdentifier,
    batchingState,
    templateState,
    index: index ?? 0,
  });

  return (
    <div
      style={{
        paddingBottom: isCollapsed ? '0' : '1rem',
      }}
      className={`flex flex-col gap-4 rounded-lg px-0 pt-4`}
    >
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="flex justify-between text-left items-center"
        type="button"
      >
        <h4 className="text-sm font-medium">
          {option.additionalSettings?.overwriteHeading
            ? option.additionalSettings?.overwriteHeading
            : 'Advanced settings'}
        </h4>
        <img
          className={`${
            isCollapsed ? 'rotate-180' : 'rotate-0'
          } transition delay-150 ease-linear `}
          src={Arrow}
          alt=""
        />
      </button>

      <div
        style={{
          display: 'grid',
          gridTemplateRows: isCollapsed ? '0fr' : '1fr',
          overflow: 'hidden',
          transition: 'grid-template-rows 500ms',
        }}
      >
        <div
          className={`flex flex-col gap-3 overflow-hidden border border-[#525151] bg-night-100 transition delay-150 ${
            isCollapsed ? 'border-none px-0 py-0' : 'px-4 py-4'
          }  rounded-[9px]`}
        >
          <div className="flex items-center justify-between gap-2">
            <h6 className="text-sm font-normal">
              {option.additionalSettings.label}
            </h6>

            {!option?.additionalSettings.additionalSettingsEnableHidden && (
              <div className="flex items-center gap-2">
                <h6 className=" text-sm font-normal">Enable state</h6>

                <Controller
                  name={getBlockActivateName(option?.additionalSettings?.name)}
                  control={control}
                  render={({ field }) => (
                    <Switch
                      onChange={field.onChange}
                      checked={field.value}
                      extra={'border border-brand-400'}
                    />
                  )}
                />
              </div>
            )}
          </div>

          <UniqueId uniqueId={uniqueId} />

          {option.additionalSettings.type === 'button' && (
            <InputSwitchField
              optionName={option.additionalSettings.name}
              register={register}
              control={control}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const InputSwitchField = ({
  register,
  control,
  optionName,
}: {
  control: Control<FieldValues, any>;
  optionName: string;
  register: UseFormRegister<FieldValues>;
}) => {
  return (
    <div className={`flex items-center relative`}>
      <InputField<any>
        variant="auth"
        label={'Link'}
        placeholder={'Link to Page or URL'}
        type="text"
        register={register}
        name={getBlockLinkName(optionName)}
        extraLabelClass={'font-medium'}
        extraInputClass={
          'h-[40.5px] dark:bg-night-100 border !border-gray-440 rounded-md'
        }
        extra="w-full"
      />

      <div className="flex items-center gap-2 absolute right-0 top-0">
        <h6 className=" text-sm font-normal">Open in New Tab</h6>

        <Controller
          name={`${getBlockIsExternalLinkName(optionName)}`}
          control={control}
          render={({ field }) => (
            <Switch
              onChange={field.onChange}
              checked={R.isBoolean(field.value) ? field.value : true}
              extra={'border border-brand-400'}
            />
          )}
        />
      </div>
    </div>
  );
};

const UniqueId = ({ uniqueId }: { uniqueId: string }) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <div className="flex items-center bg-night-400 border border-[#3A3F47] relative justify-between rounded-md p-[6px]">
      <div className="overflow-hidden text-ellipsis whitespace-nowrap">
        {uniqueId}
      </div>

      <Popover
        trigger={
          <button
            type="button"
            className=""
            onClick={() => {
              onToggle();
              copyToClipboard(uniqueId);
              setTimeout(() => {
                onClose();
              }, 1500);
            }}
          >
            <img className="min-w-[22px] scale-[65%]" src={Copy} alt="copy" />
          </button>
        }
        content={<p className="dark:text-white">copied</p>}
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};
