import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import usePutChannelRoutes from 'api/channel-routes/patch-channel-routes';
import usePostChannelRoutes, {
  PostChannelRoutes,
} from 'api/channel-routes/post-channel-routes';
import useGetProviders from 'api/providers/get-providers';
import { routes } from 'api/routes';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useChannelRouteStore } from 'store/channelRouteStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { ChannelRouteDelay } from './ChannelRouteDelay';

export type FormChannelRoutes = PostChannelRoutes & {
  delay_reason: string;
};

const providerInstancesSchema = {
  instanceId: yup.string(),
  delay: yup.number().typeError('must be a number'),
};

function ChannelRoutesModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const toast = useCustomToast();
  const { appId } = useAuthStore(state => state);
  const params = useParams();
  const channel = params.channel;
  const getProviders = useGetProviders(channel);
  const configuredProviders = getProviders.data?.configuredProviders;
  const postChannelRoutes = usePostChannelRoutes();
  const putChannelRoutes = usePutChannelRoutes();
  const queryClient = useQueryClient();
  const { channelRoute, setChannelRoute } = useChannelRouteStore(
    state => state,
  );

  const defaultProviderInstance = React.useMemo(
    () => ({
      instanceId: '',
      delay: '0',
    }),
    [],
  );

  const schema = yup.object({
    name: yup.string().required('Name is required'),
    ...(!channelRoute?.isDefault && {
      entryCondition: yup.string().required('Entry Condition is required'),
    }),
    providerInstances: yup
      .array()
      .of(yup.object().shape(providerInstancesSchema)),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues,
  } = useForm<FormChannelRoutes>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      providerInstances: [defaultProviderInstance],
      delay_reason: 'failed',
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'providerInstances',
  });

  const providerInstancesWatch = useWatch({
    control,
    name: 'providerInstances',
  });

  const configuredProvidersSelectOptions = configuredProviders?.reduce(
    (acc, curr) => {
      return [
        ...acc,
        {
          label: `${curr.provider.name} (${
            curr.instanceName ?? 'DEFAULT INSTANCE'
          })`,
          value: String(curr.id),
        },
      ];
    },
    [
      {
        label: 'Select Provider',
        value: '',
      },
    ],
  );

  const getFinalActions = (type: 'add' | 'edit') => {
    return {
      onSuccess: () => {
        toast.success(
          `channel route ${type === 'add' ? 'added' : 'updated'}  successfully`,
        );
        queryClient.invalidateQueries([`${routes['routes']}`, appId, channel]);
        handleClose();
      },
      onError: (err: any) => {
        toast.showError(err);
      },
    };
  };

  const onSubmit: SubmitHandler<FormChannelRoutes> = values => {
    const providerInstances = values.providerInstances.reduce(
      (acc, curr, index) => {
        if (curr.instanceId === '') {
          return acc;
        }

        if (index === 0) {
          return [
            ...acc,
            {
              delay: 0,
              instanceId: curr.instanceId,
            },
          ];
        }

        return [...acc, curr];
      },
      [],
    );

    const { entryCondition, ...submitValues } = values;

    const data = {
      ...submitValues,
      entryCondition: Boolean(entryCondition) ? entryCondition : null,
      providerInstances,
      // add
      ...(!channelRoute && {
        appId,
        channel,
      }),
      // edit
      ...(channelRoute && {
        routeId: channelRoute.id,
      }),
    };

    const finalActions = getFinalActions(channelRoute ? 'edit' : 'add');

    if (channelRoute) {
      putChannelRoutes.mutate(data, finalActions);
    } else {
      postChannelRoutes.mutate(data, finalActions);
    }
  };

  const handleClose = () => {
    reset({
      name: '',
      entryCondition: '',
      providerInstances: [defaultProviderInstance, defaultProviderInstance],
      delay_reason: 'failed',
    });

    setChannelRoute(null);
    remove();
    onClose();
  };

  React.useEffect(() => {
    if (channelRoute) {
      const providerInstancesData = channelRoute.routeProviders
        .sort((prev, next) => (prev.order < next.order ? -1 : 1))
        .map(provider => ({
          instanceId: String(provider.providerInstance.id),
          delay: String(provider.triggerDelay),
        }));

      reset({
        name: channelRoute.name,
        entryCondition: channelRoute.entryCondition,
        providerInstances: [...providerInstancesData, defaultProviderInstance],
        delay_reason: 'failed',
      });
    } else {
      reset({
        name: '',
        entryCondition: '',
        providerInstances: [defaultProviderInstance, defaultProviderInstance],
        delay_reason: 'failed',
      });
    }
  }, [channelRoute, defaultProviderInstance, reset, isOpen]);

  return (
    <CustomModal
      modalCardClassName="!pb-[15px] !w-[650px] !max-w-[850px] !overflow-x-hidden !overflow-y-auto"
      modalContentClassName="!min-w-[450px] !top-[30%]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-[20px] text-2xl font-bold">Routing Configuration</h1>
      <p className="text-sm text-gray-650">
        With smart routing, you can configure multiple providers in a channel
        and selectively use them for your recipients.{' '}
        <a
          href="https://docs.engagespot.com/docs/features/channels/concepts/multiple-providers-and-routes"
          className="text-decoration-line: underline"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
      </p>
      <form className="rounded-xl p-4" onSubmit={handleSubmit(onSubmit)}>
        <InputField<FormChannelRoutes>
          variant="modal"
          extra="mb-3"
          label={'Name'}
          placeholder="Eg: US Users"
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'name'}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        <InputField<FormChannelRoutes>
          label={'Entry Condition'}
          placeholder={'Eg: user.countryCode === 91'}
          register={register}
          showIsRequiredAsterisk={!channelRoute?.isDefault}
          required={true}
          name={'entryCondition'}
          extraLabelClass={'font-medium'}
          variant="modal"
        />
        {errors.entryCondition && <ErrorBox error={errors.entryCondition} />}

        {/* Route Details */}
        <div className="mb-5 mt-8 rounded-lg border-2 border-[#26292F] p-4">
          <div>
            <h1 className="mb-[20px] text-xl font-semibold">Providers</h1>
            <p className="mb-5 text-sm text-gray-650">
              Primary provider is mandatory. You can select fallback providers
              to be used in-case the primary provider fails.
            </p>
            <div className="relative">
              {fields.map((field, index) => {
                const currentValue = getValues(
                  `providerInstances.${index}.instanceId`,
                );

                const isAddProvider = currentValue === '';

                const notPickedProvidersSelectOptions =
                  configuredProvidersSelectOptions?.filter(item => {
                    if (item.value === '') return true;

                    const alreadySelected = !providerInstancesWatch.some(
                      instance => {
                        return (
                          String(instance.instanceId) === String(item.value)
                        );
                      },
                    );

                    return alreadySelected;
                  });

                const currentSelectedOption =
                  configuredProvidersSelectOptions?.find(
                    instance => instance.value === currentValue,
                  );

                const providersSelectOptions = [
                  ...(notPickedProvidersSelectOptions ?? []),
                  currentSelectedOption,
                ];

                return (
                  <div
                    className="relative flex w-full justify-between gap-5"
                    key={field.id}
                  >
                    <ChannelRouteDelay
                      control={control}
                      register={register}
                      index={index}
                      setValue={setValue}
                      getValues={getValues}
                    />

                    <SelectField
                      variant="styled"
                      styleVariant="modal"
                      control={control}
                      extra="mb-3 w-[90%]"
                      label={
                        index === 0
                          ? `Primary Provider`
                          : `Priority ${index + 1}`
                      }
                      placeholder={
                        index === 0 ? `Primary Provider` : `Priority ${index}`
                      }
                      showIsRequiredAsterisk={false}
                      required={false}
                      name={`providerInstances.${index}.instanceId`}
                      options={
                        isAddProvider
                          ? notPickedProvidersSelectOptions ?? []
                          : providersSelectOptions ?? []
                      }
                      onChange={(value: any) => {
                        if (value === '' && !isAddProvider) {
                          return;
                        }

                        setValue(
                          `providerInstances.${index}.instanceId`,
                          value,
                        );

                        if (isAddProvider) {
                          append(defaultProviderInstance);
                        }
                      }}
                    />

                    <button
                      type="button"
                      onClick={() => {
                        remove(index);
                      }}
                      className={`relative top-2 self-center text-xl text-red-500 ${
                        isAddProvider
                          ? 'pointer-events-none opacity-0'
                          : 'opacity-100'
                      }`}
                    >
                      <IoCloseOutline />
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <CustomModalButtons
          handleClose={handleClose}
          primaryButtonText={channelRoute ? 'Update Route' : 'Create Route'}
          loading={postChannelRoutes.isLoading || putChannelRoutes.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default ChannelRoutesModal;
