import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import { useGetWorkflowChannels } from '../../hooks';
import { WorkflowNodeTypes, WorkflowSidebarCardsProps } from '../../types';
import WorkflowSidebarForms from '../form/WorkflowSidebarForms';
import WorkflowSidebarCards from './WorkflowSidebarCards';

const workflowFunctions: Omit<WorkflowSidebarCardsProps, 'handleDragEnd'>[] = [
  {
    type: 'delay',
    heading: 'Delay',
    text: 'Pause workflow execution for some time',
  },
  {
    type: 'branch',
    heading: 'Branch',
    text: 'Split workflow into multiple paths',
  },
  {
    type: 'batch',
    heading: 'Batch',
    text: 'Batch multiple triggers into one',
  },
  {
    type: 'updatePreference',
    heading: 'Update Preference',
    text: 'Update the preference for this user',
  },
  {
    type: 'triggerWorkflow',
    heading: 'Trigger Workflow',
    text: 'Trigger another workflow',
  },
  {
    type: 'waitForInput',
    heading: 'Wait for input',
    text: 'Wait for the user to respond to the message',
  },
  {
    type: 'fetch',
    heading: 'API Request',
    text: 'Perform an API request to fetch/update data',
  },
  {
    type: 'defineVariable',
    heading: 'Define Variable',
    text: 'Define a new variable in this execution context',
  },
  {
    type: 'editVariable',
    heading: 'Edit Variable',
    text: 'Edit value of a variable',
  },
];

const WorkflowSidebar = ({ handleDragEnd }: { handleDragEnd: () => void }) => {
  const { selectedNode } = useWorkflowStore(useShallow(state => state));
  const { allChannels } = useGetWorkflowChannels();

  return (
    <div
      style={{
        height: `calc(100vh - 4rem)`,
      }}
      className="w-[27%] bg-night-400 px-4 py-6 flex text-white flex-col gap-3 overflow-auto"
    >
      {selectedNode ? (
        <WorkflowSidebarForms type={selectedNode.type as WorkflowNodeTypes} />
      ) : (
        <>
          <div className="text-xl font-medium mb-3">Add new step</div>

          <div className="text-[#ABB0B8] font-medium mb-3">
            Build notification workflows using these built in steps
          </div>

          {/* functions */}
          <div>
            <div className="text-lg font-medium mb-3">Logic Steps</div>

            <div className="flex flex-col gap-4">
              {workflowFunctions.map(workflowFn => (
                <WorkflowSidebarCards
                  key={workflowFn.type}
                  {...workflowFn}
                  handleDragEnd={handleDragEnd}
                />
              ))}
            </div>
          </div>

          {/* channels */}
          {allChannels?.length > 0 && (
            <div className="mt-4">
              <div className="text-lg font-medium mb-3">Channel Steps</div>

              <div className="flex flex-col gap-4">
                {allChannels?.map(channels => {
                  return (
                    <WorkflowSidebarCards
                      key={channels.channel}
                      handleDragEnd={handleDragEnd}
                      heading={channels.channel}
                      text={`Send ${channels.channel} notification`}
                      type="channel"
                      draggedChannel={channels}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default WorkflowSidebar;
