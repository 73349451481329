import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetAppDetails } from './types';
import { routes } from 'api/routes';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetAppDetails> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(`${routes['apps']}/${appId}`);
  return data;
};

const useGetAppDetails = (appId: number) => {
  return useQuery({
    queryKey: [routes['apps'], appId],
    queryFn: get,
    enabled: Boolean(appId),
    staleTime: Infinity,
  });
};

export default useGetAppDetails;
