import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { GetTopicUsers } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<GetTopicUsers> => {
  const [, topicId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['topics']}/${topicId}/users`,
  );
  return data;
};

const useGetTopicUsers = (topicId: string) => {
  return useQuery({
    queryKey: [`${routes['topics']}/${topicId}/users`, topicId],
    queryFn: get,
    enabled: Boolean(topicId),
  });
};

export default useGetTopicUsers;
