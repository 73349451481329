import { useDisclosure } from '@chakra-ui/hooks';
import { GetWorkflows } from 'api/workflows/types';
import CopyCell from 'components/copy/CopyCell';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { Workflow } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import DeleteWorkflowModal from './DeleteModal';
import ToggleWorkflowModal from './ToggleModal';
import { Badge } from 'components/shadcn/badge';
import {
  findPublishStatus,
  PublishStatusBadge,
} from '../../commits/components/PublishStatusBadge';

function WorkflowTable(props: {
  tableData: any;
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
  isDefaultApp: boolean;
}) {
  const {
    tableData,
    editOpen: onOpen,
    globalFilter,
    loading,
    isDefaultApp,
  } = props;
  const deleteWorkflowModalActions = useDisclosure();
  const toggleWOrkflowModalActions = useDisclosure();
  const { setWorkflow, setWorkflowModalState, setSelectedNode } =
    useWorkflowStore(state => state);
  const navigate = useNavigate();

  type WorkflowAction =
    | 'edit_workflows'
    | 'delete_workflows'
    | 'view_workflows'
    | 'duplicate_workflows'
    | 'toggle_workflows'
    | 'app_copy_workflows';

  const handleCreateWorkflow = () => {
    setWorkflow(null);
    setWorkflowModalState('add');
    onOpen();
  };

  const handleMenuClick = (
    selected: GetWorkflows,
    actionType: WorkflowAction,
  ) => {
    setWorkflow(selected);

    if (actionType === 'view_workflows') {
      return navigate(`/admin/workflow-overview/${selected.id}`);
    }

    if (actionType === 'toggle_workflows') {
      return toggleWOrkflowModalActions.onOpen();
    }

    if (actionType === 'delete_workflows') {
      return deleteWorkflowModalActions.onOpen();
    }

    if (actionType === 'edit_workflows') {
      setWorkflowModalState('edit');
    }

    if (actionType === 'duplicate_workflows') {
      setWorkflowModalState('duplicate');
    }

    if (actionType === 'app_copy_workflows') {
      setWorkflowModalState('app_copy');
    }

    onOpen();
  };

  const menuIcons: {
    text: string;
    actionType: WorkflowAction;
  }[] = [
    { text: 'View', actionType: 'view_workflows' },
    { text: 'Disable', actionType: 'toggle_workflows' as const },
    ...(isDefaultApp
      ? [
          { text: 'Edit', actionType: 'edit_workflows' as const },
          { text: 'Duplicate', actionType: 'duplicate_workflows' as const },
          { text: 'Delete', actionType: 'delete_workflows' as const },
        ]
      : []),
  ];

  const columnConfig: ColumnConfig<GetWorkflows> = [
    {
      accessorKey: 'id',
      header: 'ID',
      cellFunction: info => info.getValue(),
      size: 50,
    },
    {
      accessorKey: 'identifier',
      header: 'Identifier',
      cellFunction: info => <CopyCell value={info.getValue()} />,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'category.name',
      header: 'Category',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'isActive',
      header: 'Status',
      cellFunction: info => {
        const isActive = Boolean(info.getValue());
        return (
          <Badge variant={isActive ? 'success' : 'danger'}>
            {isActive ? 'Enabled' : 'Disabled'}
          </Badge>
        );
      },
    },
    {
      accessorKey: 'hasUnpublishedChanges',
      header: 'Publish Status',
      cellFunction: info => {
        const item = info.row.original;
        const publishStatus = findPublishStatus({
          hasPublishedJsonSpec: true,
          hasUnpublishedChanges: Boolean(item.hasUnpublishedChanges),
        });
        return <PublishStatusBadge publishStatus={publishStatus} />;
      },
    },
    {
      accessorKey: 'category',
      cellFunction: info => (
        <TableMenu
          config={menuIcons.map(item => ({
            text:
              item.actionType === 'toggle_workflows'
                ? info.row.original?.isActive
                  ? 'Disable'
                  : 'Enable'
                : item.text,
            action: () => handleMenuClick(info.row.original, item.actionType),
          }))}
        />
      ),
    },
  ];

  const columns = columnGenerator<GetWorkflows>({ columnConfig });

  return (
    <>
      <DeleteWorkflowModal
        isOpen={deleteWorkflowModalActions.isOpen}
        onClose={deleteWorkflowModalActions.onClose}
      />

      <ToggleWorkflowModal
        isOpen={toggleWOrkflowModalActions.isOpen}
        onClose={toggleWOrkflowModalActions.onClose}
      />

      {/* table */}
      <CustomTable
        loading={loading}
        globalFilter={globalFilter}
        columns={columns}
        tableData={tableData}
        rowClick={row => {
          const selected = row.original;
          setSelectedNode(null);
          handleMenuClick(selected, 'view_workflows');
        }}
        extraTdClass={'hover:cursor-pointer'}
        emptyTableMessage="It looks like you haven't set up any workflow yet. Kickstart your automation journey by creating your first workflow now!"
        paginationType="table"
        emptyAdditionalJsx={() => (
          <EmptyTableMessage
            handleCreate={() => {
              handleCreateWorkflow();
            }}
            searchInput={''}
            docsLink={
              'https://docs.engagespot.co/docs/features/workflows/introduction'
            }
            heading={"You haven't created any workflow yet"}
            message={
              isDefaultApp
                ? 'Workflows in engagespot are the foundation of your notification system. You can create your first workflow.'
                : 'You can create workflows only in the development environment and then migrate them to production.'
            }
            searchEmptyHeading={getSearchEmptyHeading('notifications')}
            searchEmptyMessage={getSearchEmptyMessage('notifications')}
            hideSecondary={!isDefaultApp}
            Icon={Workflow}
          />
        )}
      />
    </>
  );
}

export default WorkflowTable;
