import { useStore } from '@nanostores/react';
import GreenDoubleTick from 'assets/svg/template/green-double-tick.svg';
import Settings from 'assets/svg/template/settings.svg';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useTemplateStore } from 'store/templateStore';
import {
  $currentTemplate,
  $templateConfig,
  $templateConfigAllStates,
  $templateConfigCurrentState,
  $templateConfigValues,
} from 'templates/store';
import { cn } from 'utils/class-merge';
import { createImagePreviewSrc } from 'utils/functions';
import {
  useInAppTemplatePreviewSetup,
  useMobileWebInAppTemplateDefaultPreviewSetup,
} from '../../hooks/commonTemplatePreviewSetup';
import { useGetFilteredTemplateConfigStates } from '../../hooks/getFilteredTemplateConfigStates';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';
import { InAppNotification } from '../InAppNotification';
import { StateButtons } from '../StateButtons';

const TabSwitchButton = ({
  text,
  noOfNotifications,
  isActive,
  variant,
}: {
  isActive: boolean;
  text: string;
  noOfNotifications: string;
  variant: 'default' | 'primary' | 'secondary';
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={cn(
          'mb-2  flex items-center gap-2',
          isActive && 'text-night-300',
        )}
      >
        <p
          className={cn(
            isActive ? 'text-night-300' : 'text-gray-950',
            'font-semibold',
          )}
        >
          {text}
        </p>

        <div
          className={cn(
            variant === 'default'
              ? 'bg-night-300 text-white'
              : variant === 'primary'
                ? 'bg-blue-350 text-[#006C9C]'
                : variant === 'secondary'
                  ? 'bg-green-350 text-[#118D57]'
                  : 'bg-[#E3E3E3]',
            'rounded-md p-[6px]  text-xs font-bold',
          )}
        >
          <span>{noOfNotifications}</span>
        </div>
      </div>

      {isActive && (
        <div className="h-[2.5px] w-full rounded-lg bg-night-300"></div>
      )}
    </div>
  );
};

const InAppComponent = ({
  isTemplateSelected,
  defaultTemplatePreviewSettingData,
}: {
  isTemplateSelected: boolean;
  defaultTemplatePreviewSettingData: ReturnType<
    typeof useMobileWebInAppTemplateDefaultPreviewSetup
  >;
}) => {
  const { tab } = useTemplateStore(state => state);
  const templateConfigValues = useStore($templateConfigValues);
  const templateConfigCurrentState = useStore($templateConfigCurrentState);
  const templateConfig = useStore($templateConfig);
  const isBatchedTab = tab === 'batched_template';

  let { hasPreviewImage, previewImageSrc, previewMessage, previewTitle } =
    defaultTemplatePreviewSettingData;

  if (isTemplateSelected && tab === 'template') {
    previewTitle =
      templateConfigValues?.[`${templateConfigCurrentState}_title`];

    previewMessage =
      templateConfigValues?.[`${templateConfigCurrentState}_content`] ??
      templateConfigValues?.[`${templateConfigCurrentState}_message`];

    if (Boolean(templateConfigValues?.[`${templateConfigCurrentState}_icon`])) {
      hasPreviewImage = true;

      previewImageSrc = createImagePreviewSrc(
        templateConfigValues?.[`${templateConfigCurrentState}_icon`],
      );
    } else {
      hasPreviewImage = false;
    }
  }

  return (
    <div className="w-[330px] ">
      <div className="rounded-xl bg-[#E9E9EA] p-2 text-night-300">
        {/* Container with divide */}
        <div className="divide-y divide-gray-300/50">
          {/* header */}
          <div className="flex justify-between px-6 py-2">
            <div className="flex items-center gap-4">
              <h4 className=" text-lg font-bold text-night-300">
                Notifications
              </h4>
            </div>

            <div className="relative top-2">
              <img
                className="pointer-events-none"
                src={GreenDoubleTick}
                alt=""
              />
            </div>
          </div>

          {/* Tab Switch */}
          <div className="relative flex gap-4 px-2 py-2 pb-0 pt-3">
            <TabSwitchButton
              isActive={true}
              noOfNotifications={'22'}
              text={'All'}
              variant="default"
            />
            <TabSwitchButton
              isActive={false}
              noOfNotifications={'12'}
              text={'Unread'}
              variant="primary"
            />
            <TabSwitchButton
              isActive={false}
              noOfNotifications={'10'}
              text={'Archived'}
              variant="secondary"
            />

            <img
              className="pointer-events-none relative bottom-1 right-0"
              src={Settings}
              alt=""
            />
          </div>

          {/* Dynamic Content */}
          <InAppNotification
            hasPreviewImage={hasPreviewImage}
            previewImageSrc={previewImageSrc}
            previewMessage={previewMessage}
            previewTitle={previewTitle}
            templateConfig={templateConfig}
            isBatchedTab={isBatchedTab}
          />
        </div>
      </div>
    </div>
  );
};

const InAppPreview = ({ form }: { form: UseFormReturn<FieldValues, any> }) => {
  const currentTemplate = useStore($currentTemplate);
  const [formValues, setFormValues] = React.useState(null);

  React.useEffect(() => {
    // for template switching
    const formData = form.watch();
    setFormValues(formData);

    // for form values changing
    const subscribe = form.watch(data => {
      setFormValues(data);
    });

    return () => {
      subscribe.unsubscribe();
    };
  }, [form, currentTemplate]);

  const { tab } = useTemplateStore(state => state);
  const templateConfigAllStates = useStore($templateConfigAllStates);
  const savedTemplateData = useGetTemplateChannelData();
  const [isPrevSelectedTemplateSet, setIsPrevSelectedTemplateSet] =
    React.useState(false);
  const isTemplateSelected = Boolean(currentTemplate);
  const isBatchingTab = tab === 'batched_template';

  const defaultTemplatePreviewSettingData =
    useMobileWebInAppTemplateDefaultPreviewSetup({
      formValues,
      savedTemplateData,
    });

  useInAppTemplatePreviewSetup({
    currentTemplate,
    formValues,
    isPrevSelectedTemplateSet,
    savedTemplateData,
    setIsPrevSelectedTemplateSet,
  });

  const filteredTemplateConfigAllStates = useGetFilteredTemplateConfigStates();

  return (
    <div
      className={`relative ${
        templateConfigAllStates?.length > 1 ? 'bottom-[9%]' : 'bottom-[0%]'
      }  mx-auto flex w-[36%]  flex-col items-center gap-5 overflow-visible`}
    >
      {templateConfigAllStates?.length > 1 && (
        <>
          <p className="text-sm text-white opacity-[35%]">
            Learn more about states.{' '}
            <a
              rel="noreferrer"
              className="text-decoration-line: underline"
              href="https://docs.engagespot.co/docs/features/in-app-inbox/actionable-notifications#interaction-states"
              target="_blank"
            >
              Read guide
            </a>
          </p>
          <h4 className="font-semibold text-white">States</h4>
        </>
      )}

      {isTemplateSelected &&
        templateConfigAllStates?.length > 1 &&
        !isBatchingTab && (
          // <div className="absolute -top-11 flex gap-2 rounded-[20px] border border-[#525151] bg-night-100 px-2 py-[6px]">
          <div className="flex gap-2 rounded-[20px] border border-[#525151] bg-night-100 px-2 py-[6px]">
            {templateConfigAllStates &&
              templateConfigAllStates?.map(item => {
                return (
                  <StateButtons
                    key={item.key}
                    item={item}
                    filteredTemplateConfigAllStates={
                      filteredTemplateConfigAllStates
                    }
                  ></StateButtons>
                );
              })}
          </div>
        )}

      <InAppComponent
        isTemplateSelected={isTemplateSelected}
        defaultTemplatePreviewSettingData={defaultTemplatePreviewSettingData}
      />
    </div>
  );
};

export default InAppPreview;
