import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type RemoveTopicsUsers = {
  topicId: string;
  userId: number;
};

const remove = async (values: RemoveTopicsUsers) => {
  const { topicId, userId } = values;

  return axiosInstance.delete(`${routes['topics']}/${topicId}/users/${userId}`);
};

const useDeleteTopicsUsers = () => {
  return useMutation({
    mutationFn: remove,
  });
};

export default useDeleteTopicsUsers;
