import * as yup from 'yup';

export const conditionSchema = {
  // general values
  condition_type: yup.string().required('Required Field'),

  // for step
  condition_step_reference: yup
    .string()
    .when('condition_type', (condition_type, schema) => {
      if (condition_type?.[0] === 'step') {
        return schema.required('Required Field');
      }

      return schema;
    }),

  // for step
  condition_step_expression: yup
    .string()
    .when('condition_type', (condition_type, schema) => {
      if (condition_type?.[0] === 'step') {
        return schema.required('Required Field');
      }

      return schema;
    }),

  // for message step
  condition_message_status: yup
    .string()
    .when('condition_step_expression', (condition_step_expression, schema) => {
      if (condition_step_expression?.[0] === 'message') {
        return schema.required('Required Field');
      }

      return schema;
    }),

  // for recipient and data
  condition_property: yup
    .string()
    .when('condition_type', (condition_type, schema) => {
      if (
        condition_type?.[0] === 'recipient' ||
        condition_type?.[0] === 'data'
      ) {
        return schema.required('Required Field');
      }

      return schema;
    }),

  // for fetch - data expression
  condition_data_key: yup
    .string()
    .when(
      ['condition_step_reference', 'condition_step_expression'],
      (values, schema) => {
        const [condition_step_reference, condition_step_expression] = values;
        const isFetchStep = condition_step_reference?.includes('fetch');
        const isDataExpression = condition_step_expression === 'data';

        if (isFetchStep && isDataExpression) {
          return schema.required('Required Field');
        }

        return schema;
      },
    ),

  // general values
  condition_operator: yup.string().required('Required Field'),
  condition_value: yup.string().required('Required Field'),
};

export const workflowConditionSchema = yup.object({
  condition: yup.array().of(yup.object().shape(conditionSchema)),
});
