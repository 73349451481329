import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetChannelRoutes } from './types';
import { routes } from 'api/routes';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number, string]>): Promise<
  GetChannelRoutes[]
> => {
  const [, appId, channel] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/channels/${channel}/routes`,
  );
  return data;
};

const useGetChannelRoutes = ({
  appId,
  channel,
  enabled,
}: {
  appId: number;
  channel: string;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [`${routes['routes']}`, appId, channel],
    queryFn: get,
    enabled: Boolean(appId) && Boolean(channel) && enabled,
    staleTime: 1000 * 5, // 5 seconds
  });
};

export default useGetChannelRoutes;
