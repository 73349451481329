import { Channels } from 'api/channels/get-channels/types';
import useGetTemplate from 'api/templates/get-template';
import { useGetWorkflowJson } from 'api/workflows';
import { useParams } from 'react-router-dom';
import { useGetEditorType } from '../hooks/getEditorType';
import { templateEditorText } from '../variables/text';

const TemplateEditorPreviewHeading = () => {
  const params = useParams();
  const channel = params.channel;
  const text = templateEditorText[channel as Channels];
  const templateId = params.templateId;
  const { editorTypeTemplate, editorTypeWorkflow } = useGetEditorType();

  const getTemplate = useGetTemplate(Number(templateId), editorTypeTemplate);
  const templateName = getTemplate.data?.name;
  const getWorkflow = useGetWorkflowJson(
    Number(templateId),
    editorTypeWorkflow,
  );
  const workflowName = getWorkflow.data?.jsonSpec?.name;
  const name = editorTypeTemplate ? templateName : workflowName;

  return (
    <div>
      <h4 className="mb-1 text-xl text-gray-600">
        Editing {editorTypeTemplate ? 'template' : 'workflow'} &gt; {name} &gt;{' '}
        {channel} channel
      </h4>
      <p
        className="text-sm text-white opacity-[35%]"
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </div>
  );
};

export default TemplateEditorPreviewHeading;
