import * as yup from 'yup';

export const schema = yup.object({
  newPassword: yup
    .string()
    .required('new password is required')
    .min(5, 'must be atleast 5 characters')
    .max(25, 'must be at most 25 characters'),
  confirmPassword: yup
    .string()
    .required('please confirm your password')
    .test('passwords-match', 'Passwords must match', function (value) {
      return this.parent.newPassword === value;
    }),
});
