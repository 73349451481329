import { yupResolver } from '@hookform/resolvers/yup';
import useGetCategories from 'api/categories/get-categories';
import useGetClientApps from 'api/client/get-client-apps';
import usePostTemplates from 'api/templates/post-templates';
import usePutOrDeleteTemplates from 'api/templates/put-delete-templates';
import usePutDuplicateTemplates from 'api/templates/put-duplicate-templates';
import { AxiosResponse } from 'axios';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useTemplateStore } from 'store/templateStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormAddTemplates } from '../types';

function TemplateModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { appId, clientId } = useAuthStore(state => state);
  const postAddTemplates = usePostTemplates();
  const putAddTemplates = usePutOrDeleteTemplates();
  const putDuplicateTemplates = usePutDuplicateTemplates();
  const getCategories = useGetCategories();
  const getClientApps = useGetClientApps(clientId);
  const toast = useCustomToast();
  const navigate = useNavigate();

  const {
    template: selectedTemplate,
    setTemplate,
    setTemplateModalState,
    templateModalState,
  } = useTemplateStore(state => state);
  const defaultValues = React.useMemo(
    () => ({
      category: '',
      name: '',
      appId: '',
      identifier: '',
    }),
    [],
  );

  const schema = yup.object({
    name: yup.string().required('name is required'),
    category: yup.string(),
    ...(templateModalState === 'add' && {
      identifier: yup.string().required('identifier is required'),
    }),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<FormAddTemplates>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (selectedTemplate) {
      reset({
        name: selectedTemplate.name,
        category: selectedTemplate.category?.identifier,
        ...(templateModalState === 'duplicate' && {
          identifier: `${selectedTemplate.identifier}_copy`,
        }),
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, selectedTemplate, templateModalState]);

  const handleClose = () => {
    reset(defaultValues);
    setTemplate(null);
    setTemplateModalState('add');
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: (
        response: AxiosResponse<{
          id: string;
        }>,
      ) => {
        toast.success(message);

        handleClose();

        const templateId = response.data.id;

        if (templateModalState === 'add') {
          navigate(`/admin/template/${templateId}?appId=${appId}`);
        }
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormAddTemplates> = values => {
    if (selectedTemplate) {
      const finalActions = getFinalActions(
        `template ${
          templateModalState === 'edit'
            ? 'updated'
            : templateModalState === 'duplicate'
              ? 'duplicated'
              : 'copied to app'
        }  successfully`,
      );

      if (templateModalState === 'edit') {
        const data = {
          ...values,
          templateId: selectedTemplate.id,
          type: 'put' as const,
        };

        putAddTemplates.mutate(data, finalActions);
      }

      if (
        templateModalState === 'duplicate' ||
        templateModalState === 'app_copy'
      ) {
        const data = {
          ...values,
          templateId: selectedTemplate.id,
        };

        putDuplicateTemplates.mutate(data, finalActions);
      }
    } else {
      // Add template
      const data = {
        ...values,
        appId: String(appId),
      };

      const finalActions = getFinalActions('template added successfully');
      postAddTemplates.mutate(data, finalActions);
    }
  };

  const categoryOptions = getCategories.data?.reduce(
    (acc, curr) => [
      ...acc,
      {
        label: curr.name,
        value: curr.identifier,
      },
    ],
    [
      {
        label: 'Select Category',
        value: '',
      },
    ],
  );

  const appOptions = getClientApps.data?.reduce(
    (acc, curr: any) => {
      if (curr.id === appId) return acc;

      return [
        ...acc,
        {
          label: curr.name,
          value: String(curr.id),
        },
      ];
    },
    [
      {
        label: 'Select App',
        value: '',
      },
    ],
  );

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold">
        {templateModalState === 'add'
          ? 'Add'
          : templateModalState === 'duplicate'
            ? 'Duplicate'
            : templateModalState === 'app_copy'
              ? 'App Copy'
              : 'Edit'}{' '}
        Template
      </h1>

      <p className="mb-6 text-sm text-[#BFBFBF]">
        Read
        <a
          href={
            'https://docs.engagespot.co/docs/features/templates/introduction'
          }
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          docs
        </a>
        to learn more about Templates
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField<FormAddTemplates>
          variant="modal"
          extra="mb-3"
          label={'Template Name'}
          placeholder={'Enter name'}
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'name'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('name', e.target.value);

            if (
              templateModalState === 'add' ||
              templateModalState === 'duplicate'
            ) {
              setValue(
                'identifier',
                e.target.value.toLowerCase().replace(/ /g, '-'),
              );
            }
          }}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        {/* Identifier */}
        {(templateModalState === 'add' ||
          templateModalState === 'duplicate' ||
          (templateModalState === 'edit' && !selectedTemplate?.identifier)) && (
          <>
            <InputField<FormAddTemplates>
              variant="modal"
              extra="mb-3"
              label={'Identifier'}
              placeholder="Enter identifier"
              showIsRequiredAsterisk={true}
              type="text"
              register={register}
              name={'identifier'}
            />

            {errors.identifier && <ErrorBox error={errors.identifier} />}
          </>
        )}

        {(templateModalState === 'add' || templateModalState === 'edit') && (
          <>
            <SelectField<FormAddTemplates>
              variant="styled"
              styleVariant="modal"
              extra="mb-3"
              label={'Category'}
              placeholder="Select Category"
              showIsRequiredAsterisk={false}
              control={control}
              name={'category'}
              options={categoryOptions}
            />

            {errors.category && <ErrorBox error={errors.category} />}
          </>
        )}

        {templateModalState === 'app_copy' && (
          <>
            <SelectField<FormAddTemplates>
              variant="styled"
              styleVariant="modal"
              extra="mb-3"
              label={'Apps'}
              placeholder="Enter app name"
              showIsRequiredAsterisk={false}
              control={control}
              name={'appId'}
              options={appOptions}
            />

            {errors.appId && <ErrorBox error={errors.appId} />}
          </>
        )}

        <CustomModalButtons
          handleClose={handleClose}
          loading={
            postAddTemplates.isLoading ||
            putAddTemplates.isLoading ||
            putDuplicateTemplates.isLoading
          }
        />
      </form>
    </CustomModal>
  );
}

export default TemplateModal;
