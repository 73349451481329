import { yupResolver } from '@hookform/resolvers/yup';
import useGetMe from 'api/profile/get-me';
import usePatchUserDetails from 'api/profile/patch-user-details';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { profileInformationSchema } from '../schema';
import { SubmitHandler, useForm } from 'react-hook-form';
import ErrorBox from 'components/error/ErrorBox';
import useCustomToast from 'utils/use-toast';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';

type FormChangeInformation = {
  firstName: string;
};

const Information = () => {
  const patchUserDetails = usePatchUserDetails();
  const getMe = useGetMe();
  const clientId = getMe.data?.clientUser?.id;
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const defaultValues = {
    firstName: getMe.data?.clientUser.firstName,
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormChangeInformation>({
    resolver: yupResolver(profileInformationSchema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormChangeInformation> = values => {
    const data = {
      ...values,
      clientId,
    };

    patchUserDetails.mutate(data, {
      onSuccess: () => {
        toast.success('profile details changed successfully');
        queryClient.invalidateQueries([routes['me']]);
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  return (
    <Card extra={'w-full !p-5'}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Account Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change user account information
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* inputs */}
        <div className="mb-2 mt-7 grid grid-cols-2 gap-3">
          <InputField<FormChangeInformation>
            variant="auth"
            extra=""
            label="First Name"
            placeholder="Enter first name"
            showIsRequiredAsterisk={true}
            register={register}
            name={'firstName'}
          />
          {errors.firstName && <ErrorBox error={errors.firstName} />}
        </div>

        <div className="mt-12 flex w-full justify-end">
          <button
            disabled={patchUserDetails.isLoading}
            className={cn(
              buttonVariants({
                className: 'rounded-md text-base disabled:opacity-75',
              }),
            )}
          >
            Save Change
            {patchUserDetails.isLoading && (
              <LoaderSpinner
                strokeColor="black"
                parentClass="relative left-2"
              />
            )}
          </button>
        </div>
      </form>
    </Card>
  );
};

export default Information;
