import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type DeleteTopicUserChannelSubscription = {
  subscriptionId: number;
  topicId: string;
};

const remove = async (values: DeleteTopicUserChannelSubscription) => {
  const { topicId, subscriptionId } = values;
  return axiosInstance.delete(
    `${routes['topics']}/${topicId}/subscriptions/${subscriptionId}`,
  );
};

const useDeleteTopicUserChannelSubscription = () => {
  return useMutation({
    mutationFn: remove,
  });
};

export default useDeleteTopicUserChannelSubscription;
