import diff from 'fast-diff';

export const stringifyJson = ({
  oldValue,
  newValue,
  jsonSpacing = 2,
}: {
  oldValue?: Object;
  newValue?: Object;
  jsonSpacing?: number;
}) => {
  const oldJson = JSON.stringify(oldValue, undefined, jsonSpacing);
  const newJson = JSON.stringify(newValue, undefined, jsonSpacing);

  return {
    oldJson,
    newJson,
  };
};

export const checkDiff = ({
  oldValue,
  newValue,
}: {
  oldValue: string;
  newValue: string;
}) => {
  const jsonChanges = diff(oldValue, newValue);
  const changes = jsonChanges.flatMap?.(item => item[0]);
  const hasChange = changes.find(
    change => change === diff.INSERT || change === diff.DELETE,
  );

  return hasChange;
};

export const checkJsonDiff = ({
  oldValue,
  newValue,
}: {
  oldValue?: Object;
  newValue?: Object;
}) => {
  const { oldJson, newJson } = stringifyJson({
    oldValue,
    newValue,
  });

  const hasChanges = checkDiff({
    oldValue: oldJson,
    newValue: newJson,
  });

  return hasChanges;
};
