import { useEditWorkflowJson, useSaveEmailWorkflow } from 'api/workflows';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';
import { setUpdatedWorkflowJson } from '../../workflowEditor/functions';
import { useGetTemplateChannelData } from '../hooks/getTemplateChannelData';

function AttachmentModal({
  isOpen,
  onClose,
  form,
}: {
  onClose: () => void;
  isOpen: boolean;
  form: UseFormReturn<FieldValues, any>;
}) {
  const toast = useCustomToast();
  const params = useParams();
  const templateId = params.templateId;
  const templateIdentifier = params.templateIdentifier;
  const saveEmailWorkflow = useSaveEmailWorkflow();
  const getTemplateBatchingContent = useGetTemplateChannelData();
  const savedTemplateData = React.useMemo(
    () => getTemplateBatchingContent,
    [getTemplateBatchingContent],
  );
  const selectedAttachmentKey =
    getTemplateBatchingContent?.content?.attachmentKey;
  const editWorkflowJson = useEditWorkflowJson();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const handleClose = () => {
    onClose();
  };

  const onSubmit = async (values: any) => {
    const data = {
      workflowId: Number(templateId),
      data: {
        ...savedTemplateData.content,
        attachmentKey: values.attachmentKey ?? '',
      },
    };

    try {
      const response = await saveEmailWorkflow.mutateAsync(data);

      await setUpdatedWorkflowJson({
        templateIdentifier,
        template: response.data,
        editWorkflowJson,
        templateId,
      });

      toast.success('Attachment Key Updated Successfully');
      handleClose();
    } catch (e) {
      toast.showError(e);
    }
  };

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold">
        {selectedAttachmentKey ? 'Edit' : 'Add'} Attachment Key
      </h1>

      {/* <p className="mb-6 text-sm text-[#BFBFBF]">
        Read
        <a
          href={
            'https://docs.engagespot.co/docs/features/category/what-are-categories'
          }
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          docs
        </a>
        to learn more about Categories
      </p> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Name */}
        <InputField
          variant="modal"
          extra="mb-3"
          label={'Attachment Key'}
          placeholder="Enter attachment key"
          type="text"
          register={register}
          name={'attachmentKey'}
        />
        {errors.attachmentKey && <ErrorBox error={errors.attachmentKey} />}

        <CustomModalButtons
          handleClose={handleClose}
          loading={saveEmailWorkflow.isLoading || editWorkflowJson.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default AttachmentModal;
