import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetLogs } from './types';
import { PaginationState } from '@tanstack/react-table';

const get = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, number, number]
>): Promise<GetLogs> => {
  const [, appId, pageNo, limit] = queryKey;
  const { data } = await axiosInstance.get(`${routes['apps']}/${appId}/logs`, {
    params: {
      page: pageNo,
      limit,
    },
  });

  return data;
};

const useGetLogs = ({ pageIndex, pageSize }: PaginationState) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/logs`, appId, pageIndex, pageSize],
    queryFn: get,
    enabled: Boolean(appId),
  });
};

export default useGetLogs;
