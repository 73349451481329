import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import {
  AllWorkflowRunSteps,
  CancelWorkflow,
  WorkflowRun,
  WorkflowRunSteps,
} from './type';
import { PaginationState } from '@tanstack/react-table';

const getWorkflowRuns = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<WorkflowRun> => {
  const [, workflowId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['workflows']}/${workflowId}/runs`,
  );
  return data;
};

export const useGetWorkflowRuns = (workflowId: number, enabled = true) => {
  return useQuery({
    queryKey: [`${routes['workflows']}/runs`, workflowId],
    queryFn: getWorkflowRuns,
    enabled: Boolean(workflowId && enabled),
  });
};

const getAllWorkflowRuns = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, number, number, boolean]
>): Promise<AllWorkflowRunSteps> => {
  const [, appId, pageNo, limit] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/workflows/logs`,
    {
      params: {
        page: pageNo,
        limit,
      },
    },
  );

  return data;
};

export const useGetAllWorkflowRuns = ({
  pageIndex,
  pageSize,
  enabled,
}: {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  enabled: boolean;
}) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [
      `${routes['workflows']}/runs-all`,
      appId,
      pageIndex,
      pageSize,
      enabled,
    ],
    queryFn: getAllWorkflowRuns,
    enabled: Boolean(appId) && enabled,
  });
};

const getWorkflowRunsDetails = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, number]
>): Promise<WorkflowRunSteps> => {
  const [, workflowId, runId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['workflows']}/${workflowId}/runs/${runId}`,
  );
  return data;
};

export const useGetWorkflowRunsDetails = (
  workflowId: number,
  runId: number,
  enabled = true,
) => {
  return useQuery({
    queryKey: [`${routes['workflows']}/details`, workflowId, runId],
    queryFn: getWorkflowRunsDetails,
    enabled: Boolean(workflowId && runId && enabled),
  });
};

const cancelWorkflow = async (values: CancelWorkflow) => {
  const { workflowId, ...data } = values;
  return axiosInstance.patch(
    `${routes['workflows']}/${workflowId}/cancel-run`,
    data,
  );
};

export const useCancelWorkflow = (
  {
    shouldRevalidate,
  }: {
    shouldRevalidate?: boolean;
  } = {
    shouldRevalidate: true,
  },
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: cancelWorkflow,
    onSuccess: () => {
      if (shouldRevalidate) {
        queryClient.invalidateQueries([`${routes['workflows']}/runs`]);
      }
    },
  });
};
