import { yupResolver } from '@hookform/resolvers/yup';
import { useEditWorkflowJson } from 'api/workflows';
import Plus from 'assets/svg/integrations/plus.svg';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { produce } from 'immer';
import React from 'react';
import {
  FieldErrors,
  FieldValues,
  FormProvider,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import useWorkflowStore from 'store/workflowStore';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import {
  findSelectedNodeFromJson,
  handleAddNewNodestoBranch,
  handleDeleteSelectedNode,
  handleJsonDiffCheck,
} from '../../functions';
import {
  useFormValidateOnLoad,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { BlockInput } from './BlockInput';
import { WorkflowFormHeader } from './WorkflowFormHeader';

type FormWorkflowBranch = {
  condition?: string;
  branchCondition?: {
    name: string;
    triggerCondition: string;
  }[];
};

const branchRequiredErrorMessage =
  'Non default branch must have at least one condition';

const branchConditionSchema = {
  triggerCondition: yup.string().required(branchRequiredErrorMessage),
  label: yup.string().required('Branch name is required'),
};

const schema = yup.object({
  branchCondition: yup.array().of(yup.object().shape(branchConditionSchema)),
});

const WorkflowBranchForm = () => {
  const reactFlowInstance = useReactFlow();
  const params = useParams();
  const workflowId = params.workflowId;
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );
  const editWorkflowJson = useEditWorkflowJson();
  const branchAddLoadingRef = React.useRef(false);

  const { dataLoaded, setDataLoaded } = useIsDataLoaded();

  const selectedNodeJson = findSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId: selectedNode.id,
  });

  const formMethods = useForm<FormWorkflowBranch>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    register,
    reset,
    control,
    formState: { errors },
    trigger,
    getValues,
  } = formMethods;

  const { fields, insert, remove } = useFieldArray({
    control,
    name: 'branchCondition',
  });

  const handleChange = () => {
    const selectedNodeId = selectedNode?.id;
    const dataValues = getValues();
    const { branchCondition } = dataValues;

    const jsonResult = produce(workflowJson, draft => {
      const selectedStep = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      selectedStep.branches.forEach((branch, index) => {
        branch.name =
          branchCondition?.[index]?.name === ''
            ? `Branch ${index + 1}`
            : branchCondition?.[index]?.name;
        branch.triggerCondition = branchCondition?.[index]?.triggerCondition;
      });
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });
    const selectedBranches = selectedNodeJson?.branches;

    const branchCondition = selectedBranches?.map((branch, index) => {
      return {
        triggerCondition: branch.triggerCondition,
        name: Boolean(branch.name) ? branch.name : `Branch ${index + 1}`,
      };
    });

    reset({
      branchCondition,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useFormValidateOnLoad({
    dataLoaded,
    trigger,
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  useWatchWorkflowFormErrors({
    control,
    handleErrors: ({ setErrors, errors }) => {
      // add branch loading case
      const branchAddLoading = branchAddLoadingRef.current;

      if (branchAddLoading) return;

      const allSubBranches = selectedNodeJson.branches.map(
        subBranch => subBranch,
      );

      /**
       * We are only interested in triggerCondition errors
       * label errors are ignored as we can always add generic name
       * But error in form is shown for ux sake
       */
      const allTriggerConditionErrors = (
        errors.branchCondition as unknown as FieldErrors<FieldValues>[]
      )?.map(error => ({
        triggerCondition: error?.triggerCondition,
      }));

      const updatedErrors = allTriggerConditionErrors?.reduce(
        (acc, error) => {
          // default branch case where triggerCondition is null
          if (error?.triggerCondition?.type === 'nullable') return acc;

          const index = (error?.triggerCondition?.ref as any)?.name?.split?.(
            '.',
          )?.[1];

          const ref = allSubBranches?.[index]?.ref;

          if (!ref) return acc;

          return {
            ...acc,
            [ref]: {
              type: error?.triggerCondition?.type as string,
              message: error?.triggerCondition?.message as string,
            },
          };
        },
        {} as {
          [x: string]: {
            type: string;
            message: string;
          };
        },
      );

      setErrors({
        errors: updatedErrors,
        stepRef: selectedNode.id,
      });
    },
  });

  return (
    <div>
      <WorkflowFormHeader heading="Branch" docKey="branch" />

      <div className="text-[#ABB0B8] font-medium mb-3">
        Execute the first branch that evaluates to true.
      </div>

      <FormProvider {...formMethods}>
        <form className="mt-6 flex flex-col gap-6">
          {fields.map((field, index) => (
            <div className="" key={field.id}>
              {field.triggerCondition === null ? (
                <div className="flex flex-col gap-4">
                  <div>
                    <button
                      type="button"
                      onClick={() => {
                        branchAddLoadingRef.current = true;

                        handleAddNewNodestoBranch({
                          index: fields.length - 1,
                          selectedNodeId: selectedNode.id,
                          editWorkflowJson,
                          workflowId: Number(workflowId),
                          reactFlowInstance,
                        });

                        insert(fields.length - 1, {
                          triggerCondition: '',
                          name: `Branch ${fields.length}`,
                        });

                        setTimeout(() => {
                          trigger();
                        }, 100);

                        setTimeout(() => {
                          branchAddLoadingRef.current = false;
                        }, 200);
                      }}
                      // className="border items-center px-3 py-2 text-sm rounded-md flex gap-2 bg-night-400 border-gray-930"
                      className="border text-yellow-300 items-center px-3 py-2 text-sm rounded-md flex gap-2 bg-night-400 border-gray-930"
                    >
                      <img src={Plus} alt="" width={10} />
                      Add branch
                    </button>
                  </div>

                  <div className="mt-3">
                    <BlockInput heading="Default Branch" text="Default" />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="relative">
                    {fields.length > 2 && (
                      <button
                        type="button"
                        className="bg-night-400 border-2 text-sm border-red-50 text-[#DD514C] py-1 px-2 rounded-md absolute right-1 top-0"
                        onClick={() => {
                          remove(index);

                          const selectedSubBranchRef =
                            selectedNodeJson.branches[index].ref;

                          handleDeleteSelectedNode({
                            workflowJson,
                            selectedNodeId: selectedSubBranchRef,
                            workflowId: Number(workflowId),
                            editWorkflowJson,
                            reactFlowInstance,
                            deSelectNode: false,
                          });
                        }}
                      >
                        Remove
                      </button>
                    )}
                  </div>

                  <div>
                    <InputField<any>
                      extraInputClass={
                        'border dark:bg-night-100 focus:border border-[#525151]'
                      }
                      label={`Branch ${index + 1} Name`}
                      placeholder={'Enter Branch Name'}
                      register={register}
                      name={`branchCondition.${index}.name`}
                      extraLabelClass={'font-medium'}
                      variant="workflow"
                    />

                    {errors?.['branchCondition']?.[index]?.['name'] && (
                      <ErrorBox
                        error={errors['branchCondition'][index]['name']}
                      />
                    )}
                  </div>

                  <div>
                    {/* hidden from ui */}
                    <TextField<any>
                      extraInputClass={
                        'border dark:bg-night-100 focus:border border-[#525151]'
                      }
                      label={'Condition'}
                      placeholder={'Sample javascript expression'}
                      register={register}
                      name={`branchCondition.${index}.triggerCondition`}
                      extraLabelClass={'font-medium'}
                      variant="workflow"
                      extra="hidden"
                    />

                    {/* Condition Error */}
                    {errors?.['branchCondition']?.[index]?.[
                      'triggerCondition'
                    ] && (
                      <ErrorBox
                        error={
                          errors['branchCondition'][index]['triggerCondition']
                        }
                      />
                    )}

                    {/* Condition Form */}
                    <WorkflowConditionForm branchIndex={`${index}`} />
                  </div>
                </div>
              )}
            </div>
          ))}
        </form>
      </FormProvider>
    </div>
  );
};

export default WorkflowBranchForm;
