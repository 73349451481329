import { useDisclosure } from '@chakra-ui/hooks';
import { useEditWorkflowJson } from 'api/workflows';
import { CustomHoverCard } from 'components/shadcn/hover-card';
import { CircleCheck, CircleX, LoaderPinwheel, RefreshCw } from 'lucide-react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import { checkJsonHasErrors, handleLayoutNodes } from '../../functions';
import WorkflowDiffModal from '../modals/WorkflowDiffModal';
import WorkflowBackButton from '../sidebar/WorkflowBackButton';
import WorkflowPublishButton from './WorkflowPublishButton';
import { WorkflowToggleButtons } from './WorkflowToggleButtons';

const WorkflowHeader = ({ isLoading }: { isLoading: boolean }) => {
  const navigate = useNavigate();
  const {
    layoutMode,
    setLayoutMode,
    showAllNodeRefs,
    setShowAllNodeRefs,
    nodes,
    edges,
    setNodes,
    setEdges,
    setSelectedNode,
  } = useWorkflowStore(store => store);
  const workflowDiffModalActions = useDisclosure();
  const { hasJsonChanges, workflowJson } = useWorkflowStore(state => state);
  const params = useParams();
  const workflowId = Number(params.workflowId);
  const editWorkflowJson = useEditWorkflowJson();
  const hasErrors = checkJsonHasErrors();

  const handleGoBack = () => {
    editWorkflowJson.mutate({
      workflowId,
      jsonSpec: workflowJson,
    });
    setSelectedNode(null);
    navigate(-1);
  };

  const [loading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    setIsLoading(true);
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, [isLoading]);

  const [showText, setSetshowText] = React.useState(false);

  React.useEffect(() => {
    if (hasErrors || loading) {
      setSetshowText(true);
    }

    setTimeout(() => {
      setSetshowText(false);
    }, 3000);
  }, [hasErrors, loading]);

  return (
    <>
      <WorkflowDiffModal
        onClose={workflowDiffModalActions.onClose}
        isOpen={workflowDiffModalActions.isOpen}
      />

      <div className="border-b flex justify-between border-[#ABB0B84D] px-7 h-16 items-center">
        <div className="flex items-center gap-2">
          <WorkflowBackButton className="mb-0" onClick={handleGoBack} />
          {workflowJson?.name && (
            <h4 className="text-gray-600">
              Editing workflow &gt; {workflowJson?.name}
            </h4>
          )}
        </div>

        <div className="flex gap-4 items-center">
          {hasJsonChanges && (
            <div className="text-gray-600 flex gap-2 items-center text-xs flex-nowrap">
              <CustomHoverCard
                children={
                  <div className="flex gap-2 items-center cursor-default">
                    Unpublished changes
                    <LoaderPinwheel className="w-4 h-4 cursor-pointer" />
                  </div>
                }
                content={
                  <span className="text-gray-600 w-fit h-2 flex items-center text-sm">
                    Workflow has unpublished changes
                  </span>
                }
              />
            </div>
          )}

          {hasErrors && (
            <div className="text-red-500 flex gap-2 items-center text-sm">
              {showText && <span>Error in workflow</span>}

              <CustomHoverCard
                children={<CircleX className="w-4 h-4 cursor-pointer" />}
                content={
                  <span className="text-red-500 w-fit h-2 flex items-center text-sm">
                    Errors in workflow
                  </span>
                }
              />
            </div>
          )}

          {loading && !hasErrors && (
            <div className="text-yellow-500 flex gap-2 items-center text-sm">
              {showText && <span>Saving...</span>}
              <CustomHoverCard
                children={<RefreshCw className="w-4 h-4 cursor-pointer" />}
                content={
                  <span className="text-yellow-500 w-fit h-2 flex items-center text-sm">
                    See workflow status
                  </span>
                }
              />
            </div>
          )}

          {!loading && !hasErrors && (
            <div className="text-green-500 flex gap-2 items-center text-sm">
              {showText && <span>Saved</span>}
              <CustomHoverCard
                cardContentClassName="relative top-2"
                children={<CircleCheck className="w-4 h-4 cursor-pointer" />}
                content={
                  <span className="text-green-500 w-fit h-2 flex items-center text-sm">
                    All changes are saved
                  </span>
                }
              />
            </div>
          )}

          {/* Unique ids */}
          <WorkflowToggleButtons
            checked={showAllNodeRefs}
            onCheckedChange={() => {
              setShowAllNodeRefs(!showAllNodeRefs);
              const { layoutedNodes, layoutedEdges } = handleLayoutNodes(
                nodes,
                edges,
              );
              setNodes([...layoutedNodes]);
              setEdges([...layoutedEdges]);
            }}
            leftTriggerText={'Show ids'}
            leftContentText={
              'Displays the identifiers associated with the individual nodes in your workflow'
            }
            rightTriggerText={'hide ids'}
            rightContentText={
              'Conceals the identifiers associated with the individual nodes in your workflow'
            }
          />

          {/* Layout */}
          <WorkflowToggleButtons
            checked={layoutMode === 'automatic' ? true : false}
            onCheckedChange={() => {
              if (layoutMode === 'automatic') setLayoutMode('manual');
              else setLayoutMode('automatic');
            }}
            leftTriggerText={'Auto layout'}
            leftContentText={
              'Adjusts the viewport to fit your view based on the added nodes'
            }
            rightTriggerText={'manual layout'}
            rightContentText={
              "Requires manual zoom adjustments, the viewport doesn't change automatically."
            }
          />

          <WorkflowPublishButton
            onClick={() => workflowDiffModalActions.onOpen()}
          />
        </div>
      </div>
    </>
  );
};

export default WorkflowHeader;
