import { getTemplateDataFromJson } from 'api/templates/get-template-json';
import CustomEmailEditor from 'email-editor';
import { resetDocument } from 'email-editor/documents/editor/EditorContext';
import EMPTY_EMAIL_MESSAGE from 'email-editor/getConfiguration/sample/empty-email-message';
import React from 'react';

const CustomEmailPreview = ({
  bodyJsonTemplate,
}: {
  bodyJsonTemplate: string;
}) => {
  React.useEffect(() => {
    if (bodyJsonTemplate) {
      getTemplateDataFromJson(bodyJsonTemplate)
        .then(r => {
          console.log({ r });
          resetDocument(r);
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      resetDocument(EMPTY_EMAIL_MESSAGE);
    }

    return () => {};
  }, [bodyJsonTemplate]);

  return (
    <div className="h-full overflow-auto">
      <CustomEmailEditor preview={true} />
    </div>
  );
};

export default CustomEmailPreview;
