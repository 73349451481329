import { useDisclosure } from '@chakra-ui/hooks';
import useGetUsers from 'api/users/get-users';
import { GetUserResult } from 'api/users/get-users/types';
import HeaderDoc from 'components/header/HeaderDoc';
import PageHeader from 'components/header/PageHeader';
import { usePaginationApiState } from 'components/table/pagination-state';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import AddUserModal from './components/AddUserModal';
import DetailsModal from './components/DetailsModal';
import PreferenceModal from './components/PreferenceModal';
import UsersTable from './components/UsersTable';

const Users = () => {
  const [searchParams] = useSearchParams();
  const search = new URLSearchParams(searchParams).get('search');
  const initialValue = search ? search : '';
  const { pagination, setPagination } = usePaginationApiState();
  const [filter, setFilter] = React.useState(initialValue);
  const getUsers = useGetUsers({ ...pagination, filter });
  const detailsModalActions = useDisclosure();
  const preferenceModalActions = useDisclosure();
  const addUserModalActions = useDisclosure();
  const [selectedUser, setSelectedUser] = React.useState<GetUserResult>();

  React.useEffect(() => {
    setFilter(search);
  }, [search]);

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <DetailsModal
        isOpen={detailsModalActions.isOpen}
        onClose={() => {
          detailsModalActions.onClose();
          setSelectedUser(null);
        }}
        selectedUser={selectedUser}
      />
      <PreferenceModal
        isOpen={preferenceModalActions.isOpen}
        onClose={() => {
          preferenceModalActions.onClose();
          setSelectedUser(null);
        }}
        selectedUser={selectedUser}
      />
      <AddUserModal
        isOpen={addUserModalActions.isOpen}
        onClose={addUserModalActions.onClose}
        selectedUser={selectedUser}
      />

      {/* Header */}
      <PageHeader
        setFilter={setFilter}
        setPagination={setPagination}
        filter={filter}
        isFetching={getUsers.isFetching}
        paginationType={'api'}
        handleCreate={() => {
          setSelectedUser(null);
          addUserModalActions.onOpen();
        }}
        buttonText={'Create User'}
        heading={'Users'}
        text={
          <>
            User is the recipient who can receive notifications from your app.
            <HeaderDoc
              link="https://docs.engagespot.com/docs/features/user/who-is-user"
              text="Learn more"
            />
          </>
        }
      />

      {/* Tables */}
      <UsersTable
        tableData={getUsers?.data?.results ?? []}
        detailsModalOpen={detailsModalActions.onOpen}
        preferenceModalOpen={preferenceModalActions.onOpen}
        setSelectedUser={setSelectedUser}
        pagination={pagination}
        setPagination={setPagination}
        editModalOpen={addUserModalActions.onOpen}
        loading={getUsers.isLoading}
        searchInput={filter}
        totalPages={getUsers.data?.totalPages}
      />
    </div>
  );
};

export default Users;
