import axios from 'axios';
import {
  axiosRequestInterceptor,
  axiosResponseErrorInterceptor,
} from './common';
import { DEFAULT_API_BASE_URL } from 'variables/apiBaseUrls';

export const axiosGlobalInstance = axios.create({
  baseURL: DEFAULT_API_BASE_URL,
});

axiosGlobalInstance.interceptors.request.use(axiosRequestInterceptor, err =>
  Promise.reject(err),
);

axiosGlobalInstance.interceptors.response.use(
  response => response,
  axiosResponseErrorInterceptor,
);
