import Visa from '@assets/svg/credit-cards/visa.svg';
import MasterCard from '@assets/svg/credit-cards/mastercard.svg';
import Rupay from '@assets/svg/credit-cards/rupay.svg';

export const registrationLink = 'https://portal.engagespot.co/auth/register';

// export const pricingDetails = {
//   free: {
//     monthly: 0,
//     yearly: 0,
//   },

//   growth: {
//     monthly: 89,
//     yearly: 79,
//   },

//   pro: {
//     monthly: 179,
//     yearly: 159,
//   },

//   business: {
//     monthly: 559,
//     yearly: 468,
//   },

//   standard: {
//     notification: {
//       0: {
//         limitText: 'Up to 10k notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 10K',
//         price: 20,
//         overageText: '$2 for every additional 1K notifications',
//       },

//       1: {
//         limitText: 'Up to 50k notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 50K',
//         price: 79,
//         overageText: '$2 for every additional 1K notifications',
//       },

//       2: {
//         limitText: 'Up to 100k notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 100K',
//         price: 149,
//         overageText: '$2 for every additional 1K notifications',
//       },
//       3: {
//         limitText: 'Up to 500K notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 500K',
//         price: 599,
//         overageText: '$2 for every additional 1K notifications',
//       },
//       4: {
//         limitText: 'Up to 1M notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 1M',
//         price: 1099,
//         overageText: '$2 for every additional 1K notifications',
//       },
//       5: {
//         limitText: 'Up to 2M notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 2M',
//         price: 2099,
//         overageText: '$2 for every additional 1K notifications',
//       },
//       6: {
//         limitText: 'Up to 3M notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 3M',
//         price: 2999,
//         overageText: '$2 for every additional 1K notifications',
//       },
//       7: {
//         limitText: 'Up to 5M notifications/mo<br/>Unlimited MAU',
//         name: 'Standard 5M',
//         price: 4559,
//         overageText: '$2 for every additional 1K notifications',
//       },
//     },

//     mau: {
//       0: {
//         limitText: 'Up to 200 MAU<br/>Unlimited notifications',
//         name: 'Standard 200 MAU',
//         price: 20,
//         overageText: '$1 for every 10 MAU',
//       },
//       1: {
//         limitText: 'Up to 1000 MAU<br/>Unlimited notifications',
//         name: 'Standard 1000 MAU',
//         price: 99,
//         overageText: '$1 for every 10 MAU',
//       },
//       2: {
//         limitText: 'Up to 2K MAU<br/>Unlimited notifications',
//         name: 'Standard 2K MAU',
//         price: 170,
//         overageText: '$1 for every 10 MAU',
//       },
//       3: {
//         limitText: 'Up to 5K MAU<br/>Unlimited notifications',
//         name: 'Standard 5K MAU',
//         price: 389,
//         overageText: '$1 for every 10 MAU',
//       },
//       4: {
//         limitText: 'Up to 10K MAU<br/>Unlimited notifications',
//         name: 'Standard 10K MAU',
//         price: 559,
//         overageText: '$1 for every 10 MAU',
//       },
//       5: {
//         limitText: 'Up to 25K MAU<br/>Unlimited notifications',
//         name: 'Standard 25K MAU',
//         price: 1199,
//         overageText: '$1 for every 10 MAU',
//       },
//       6: {
//         limitText: 'Up to 50K MAU<br/>Unlimited notifications',
//         name: 'Standard 50K MAU',
//         price: 2299,
//         overageText: '$1 for every 10 MAU',
//       },
//       7: {
//         limitText: 'Up to 100K MAU<br/>Unlimited notifications',
//         name: 'Standard 100K MAU',
//         price: 4199,
//         overageText: '$1 for every 10 MAU',
//       },
//     },
//   },

//   premium: {
//     notification: {
//       0: {
//         limitText: 'Up to 10k notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 10K',
//         price: 25,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//       1: {
//         limitText: 'Up to 50k notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 50K',
//         price: 99,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//       2: {
//         limitText: 'Up to 100k notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 100K',
//         price: 189,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//       3: {
//         limitText: 'Up to 500K notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 500K',
//         price: 749,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//       4: {
//         limitText: 'Up to 1M notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 1M',
//         price: 1374,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//       5: {
//         limitText: 'Up to 2M notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 2M',
//         price: 2624,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//       6: {
//         limitText: 'Up to 3M notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 3M',
//         price: 3749,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//       7: {
//         limitText: 'Up to 5M notifications/mo<br/>Unlimited MAU',
//         name: 'Premium 5M',
//         price: 5699,
//         overageText: '$2.5 for every additional 1K notifications',
//       },
//     },

//     mau: {
//       0: {
//         limitText: 'Up to 200 MAU<br/>Unlimited notifications',
//         name: 'Premium 200 MAU',
//         price: 25,
//         overageText: '$1.25 for every 10 MAU',
//       },
//       1: {
//         limitText: 'Up to 1000 MAU<br/>Unlimited notifications',
//         name: 'Premium 1000 MAU',
//         price: 119,
//         overageText: '$1.25 for every 10 MAU',
//       },
//       2: {
//         limitText: 'Up to 2K MAU<br/>Unlimited notifications',
//         name: 'Premium 2K MAU',
//         price: 219,
//         overageText: '$1.25 for every 10 MAU',
//       },
//       3: {
//         limitText: 'Up to 5K MAU<br/>Unlimited notifications',
//         name: 'Premium 5K MAU',
//         price: 489,
//         overageText: '$1.25 for every 10 MAU',
//       },
//       4: {
//         limitText: 'Up to 10K MAU<br/>Unlimited notifications',
//         name: 'Premium 10K MAU',
//         price: 699,
//         overageText: '$1.25 for every 10 MAU',
//       },
//       5: {
//         limitText: 'Up to 25K MAU<br/>Unlimited notifications',
//         name: 'Premium 25K MAU',
//         price: 1499,
//         overageText: '$1.25 for every 10 MAU',
//       },
//       6: {
//         limitText: 'Up to 50K MAU<br/>Unlimited notifications',
//         name: 'Premium 50K MAU',
//         price: 2879,
//         overageText: '$1.25 for every 10 MAU',
//       },
//       7: {
//         limitText: 'Up to 100K MAU<br/>Unlimited notifications',
//         name: 'Premium 100K MAU',
//         price: 5249,
//         overageText: '$1.25 for every 10 MAU',
//       },
//     },
//   },
// };

export const planFeatures = [
  {
    name: 'Preference manager',
    free: true,
    starter: true,
    premium: true,
  },
  {
    name: 'Visual template editor',
    free: true,
    starter: true,
    premium: true,
  },
  {
    name: 'Prebuilt UI-Kit',
    free: true,
    starter: true,
    premium: true,
  },
  {
    name: 'Conditional Provider Routing',
    free: false,
    starter: false,
    premium: true,
  },
  {
    name: 'Remove Engagespot branding',
    free: false,
    starter: false,
    premium: true,
  },
  {
    name: 'Template Analytics (coming soon)',
    free: false,
    starter: false,
    premium: true,
  },
  {
    name: 'Internationalization (coming soon)',
    free: false,
    starter: false,
    premium: true,
  },
  {
    name: 'Observability integrations',
    free: false,
    starter: false,
    premium: true,
  },
  {
    name: 'Email + chat support',
    free: true,
    starter: true,
    premium: true,
  },
];

export const creditCards = {
  visa: Visa,
  mastercard: MasterCard,
  rupay: Rupay,
};
