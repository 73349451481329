import { useDisclosure } from '@chakra-ui/hooks';
import { GetTopics } from 'api/topics/get-topics/types';
import CopyCell from 'components/copy/CopyCell';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { Users } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteTopicsModal from './DeleteModal';

function TopicsTable(props: {
  tableData: any;
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTopic: React.Dispatch<React.SetStateAction<GetTopics>>;
  selectedTopic: GetTopics;
  loading: boolean;
}) {
  const { tableData, editOpen: onOpen, globalFilter, loading } = props;
  const deleteTemplateModalActions = useDisclosure();

  const navigate = useNavigate();

  type TopicsAction = 'edit_topic' | 'delete_topic' | 'view_topic';

  const handleMenuClick = (selected: GetTopics, actionType: TopicsAction) => {
    props.setSelectedTopic(null);
    props.setSelectedTopic(selected);

    if (actionType === 'delete_topic') {
      return deleteTemplateModalActions.onOpen();
    }

    if (actionType === 'view_topic') {
      return navigate(`/admin/topics?topicId=${selected.id}`);
    }

    onOpen();
  };

  const columnConfig: ColumnConfig<GetTopics> = [
    {
      accessorKey: 'identifier',
      header: 'Identifier',
      cellFunction: info => <CopyCell value={info.getValue()} />,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => (
        <p className="  text-base text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    },
    {
      accessorKey: 'uniqueSubscribersCount',
      header: 'Subscribers',
      cellFunction: info => (
        <p className="  text-base text-yellow-400">{info.getValue()}</p>
      ),
    },
    {
      accessorKey: 'id',
      header: 'Action',
      cellFunction: info => {
        return (
          <TableMenu
            config={[
              {
                text: 'View',
                action: () => handleMenuClick(info.row.original, 'view_topic'),
              },
              {
                text: 'Edit',
                action: () => handleMenuClick(info.row.original, 'edit_topic'),
              },
              {
                text: 'Delete',
                action: () =>
                  handleMenuClick(info.row.original, 'delete_topic'),
              },
            ]}
          />
        );
      },
    },
  ];

  const columns = columnGenerator<GetTopics>({ columnConfig });

  return (
    <>
      <DeleteTopicsModal
        isOpen={deleteTemplateModalActions.isOpen}
        onClose={deleteTemplateModalActions.onClose}
        selectedTopic={props.selectedTopic}
      />

      {/* table */}
      <CustomTable
        loading={loading}
        emptyTableMessage="You haven't created any topics"
        rowClick={row => {
          const selected = row.original;
          handleMenuClick(selected, 'view_topic');
        }}
        extraTdClass={'hover:cursor-pointer'}
        tableData={tableData}
        columns={columns}
        paginationType="table"
        globalFilter={globalFilter}
        emptyAdditionalJsx={() => (
          <EmptyTableMessage
            handleCreate={() => {
              onOpen();
            }}
            searchInput={globalFilter}
            docsLink={
              'https://docs.engagespot.co/docs/features/category/what-are-categories'
            }
            heading={"You  haven't created any topics yet"}
            message={'To notify users subscribed to a specific topic.'}
            searchEmptyHeading={getSearchEmptyHeading('topics')}
            searchEmptyMessage={getSearchEmptyMessage('topics')}
            Icon={Users}
          />
        )}
      />
    </>
  );
}

export default TopicsTable;
