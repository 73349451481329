import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { DateRange } from 'react-day-picker';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { getAnalyticsDateParams } from '../functions';
import { TemplateAnalytics } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number, DateRange]>) => {
  const [, appId, date] = queryKey;
  const params = getAnalyticsDateParams(date);
  if (!params) return null;

  const { data } = await axiosInstance.get<TemplateAnalytics[]>(
    `${routes['analytics']}/apps/${appId}/templates`,
    {
      params,
    },
  );

  return data;
};

const useGetTemplateAnalytics = (appId: number, date: DateRange) => {
  return useQuery({
    queryKey: [`${routes['analytics']}/templates`, appId, date],
    queryFn: get,
    enabled: Boolean(appId),
  });
};

export default useGetTemplateAnalytics;
