import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetRsaKey } from './types';
import { routes } from 'api/routes';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetRsaKey> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/rsa-key`,
  );
  return data;
};

const useGetRsaKey = (appId: number) => {
  return useQuery({
    queryKey: [`${routes['apps']}/rsa-key`, appId],
    queryFn: get,
    enabled: Boolean(appId),
  });
};

export default useGetRsaKey;
