import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';

const post = async <T>(values: T) => {
  return axiosGlobalInstance.post(routes['resendVerificationEmail'], values);
};

const usePostResendVerificationEmail = <T>() => {
  return useMutation({
    mutationFn: post<T>,
  });
};

export default usePostResendVerificationEmail;
