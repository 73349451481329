import { DateRange } from 'react-day-picker';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { AppAnalytics } from './types';
import { getAnalyticsDateParams } from '../functions';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number, DateRange]>) => {
  const [, appId, date] = queryKey;
  const params = getAnalyticsDateParams(date);
  if (!params) return null;

  const { data } = await axiosInstance.get<AppAnalytics[]>(
    `${routes['analytics']}/apps/${appId}`,
    {
      params,
    },
  );

  return data;
};

const useGetAppAnalytics = (appId: number, date: DateRange) => {
  return useQuery({
    queryKey: [`${routes['analytics']}/apps`, appId, date],
    queryFn: get,
    enabled: Boolean(appId),
  });
};

export default useGetAppAnalytics;
