import { useDisclosure } from '@chakra-ui/hooks';
import { Row } from '@tanstack/react-table';
import useGetClientApps from 'api/client/get-client-apps';
import { usePromotedCommits, useUnpromotedCommits } from 'api/commits';
import { PromotedCommits, UnCommitedChanges } from 'api/commits/types';
import SelectField from 'components/fields/SelectField';
import HeaderDoc from 'components/header/HeaderDoc';
import PageHeader from 'components/header/PageHeader';
import { Button } from 'components/shadcn/button';
import { Tabs, TabsList, TabsTrigger } from 'components/shadcn/tabs';
import { usePaginationApiState } from 'components/table/pagination-state';
import { CirclePlus } from 'lucide-react';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { useAppLevel } from 'utils/use-app-level';
import ChangesDiffModal from './components/ChangesDiffModal';
import ChangesTable from './components/ChangesTable';
import MigrateModal from './components/MigrateModal';
import PublishModal from './components/PublishModal';
import PublishedDiffModal from './components/PublishedDiffModal';
import PublishedTable from './components/PublishedTable';
import RevertModal from './components/RevertModal';
import { useGetAppOptions } from './hooks';
import { useIsOldVersion } from 'utils/use-old-version';
import { useNavigate } from 'react-router-dom';

const DashboardCommits = () => {
  const { clientId, appId } = useAuthStore(state => state);
  const [tableView, setTableView] = React.useState(1);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const publishModalActions = useDisclosure();
  const migrateModalActions = useDisclosure();
  const changesDiffModalActions = useDisclosure();
  const publishDiffModalActions = useDisclosure();
  const reverModalActions = useDisclosure();
  const { pagination, setPagination } = usePaginationApiState();
  const { appsOptionsWithHigherOrderThanCurrentApp } = useGetAppOptions();
  const [selectedUnPublishedRows, setSelectedUnPublishedRows] = React.useState<
    Row<UnCommitedChanges>[]
  >([]);
  const [selectedPublished, setSelectedPublished] =
    React.useState<PromotedCommits['results'][0]>();
  const getClientApps = useGetClientApps(clientId);
  const { isDefaultApp, isProductionApp } = useAppLevel();
  const unpromotedCommits = useUnpromotedCommits({
    appId,
    enabled: isDefaultApp,
  });
  const { isOldVersion, isLoading: isOldVersionLoading } = useIsOldVersion();
  const navigate = useNavigate();

  const handleSwitch = (value: number) => {
    setGlobalFilter('');
    setTableView(value);
  };

  const { control, setValue } = useForm<{
    env: string;
  }>();

  const environment = useWatch({
    name: 'env',
    control,
  });

  // handling app switching
  React.useEffect(() => {
    setValue('env', '');
  }, [setValue, appId]);

  // initial env value setting
  React.useEffect(() => {
    if (appsOptionsWithHigherOrderThanCurrentApp?.length > 0 && !environment) {
      setValue(
        'env',
        String(appsOptionsWithHigherOrderThanCurrentApp?.[0]?.value),
      );
    }
  }, [appsOptionsWithHigherOrderThanCurrentApp, setValue, environment]);

  const promotedCommits = usePromotedCommits({
    appId,
    params: {
      page: pagination.pageIndex,
      limit: pagination.pageSize,
      ...(!isProductionApp && { publishTo: Number(environment) }),
    },
    // since production app has no next publishTo environment
    enabled: isProductionApp ? true : Boolean(environment),
  });

  React.useEffect(() => {
    if (getClientApps.isLoading) return;

    setTableView(isDefaultApp ? 1 : 2);
  }, [getClientApps.isLoading, isDefaultApp]);

  // If Old version, redirect to API Credentials page
  React.useEffect(() => {
    if (isOldVersionLoading) return;

    if (isOldVersion) {
      navigate('/admin/api-credentials');
    }
  }, [isOldVersionLoading, isOldVersion, navigate]);

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <PublishModal
        isOpen={publishModalActions.isOpen}
        onClose={publishModalActions.onClose}
        selectedRows={selectedUnPublishedRows}
        setSelectedRows={setSelectedUnPublishedRows}
      />

      <MigrateModal
        isOpen={migrateModalActions.isOpen}
        onClose={migrateModalActions.onClose}
      />

      <ChangesDiffModal
        isOpen={changesDiffModalActions.isOpen}
        onClose={changesDiffModalActions.onClose}
        selectedRows={selectedUnPublishedRows}
      />

      <PublishedDiffModal
        isOpen={publishDiffModalActions.isOpen}
        onClose={publishDiffModalActions.onClose}
        selected={selectedPublished}
      />

      <RevertModal
        isOpen={reverModalActions.isOpen}
        onClose={reverModalActions.onClose}
        selected={selectedPublished}
      />

      {/* Header */}
      <PageHeader
        showSearchBar={false}
        paginationType="table"
        heading={'Version Control'}
        text={
          <>
            Keep track of your workflow changes, publish and migrate them to
            another environment.
            <HeaderDoc
              link="https://docs.engagespot.co/docs/concepts/version-control"
              text="Learn more"
            />
          </>
        }
        setFilter={setGlobalFilter}
        filter={globalFilter}
        parentClass="mb-6"
      />

      {/* Switch Buttons */}
      <div className="col-span-12 flex justify-between">
        <Tabs
          value={String(tableView)}
          defaultValue={String(tableView)}
          className=""
        >
          <TabsList>
            {isDefaultApp && (
              <TabsTrigger onClick={() => handleSwitch(1)} value="1">
                Unpublished changes
              </TabsTrigger>
            )}

            <TabsTrigger onClick={() => handleSwitch(2)} value="2">
              Published changes
            </TabsTrigger>
          </TabsList>
        </Tabs>

        <div className="flex gap-3 relative">
          {!isProductionApp && tableView === 2 && (
            <div className="absolute -top-[25px] right-[108px]">
              <SelectField
                variant="styled"
                styleVariant="modal"
                label={'Migrated To'}
                placeholder="Migrated To"
                showIsRequiredAsterisk={false}
                control={control}
                name={'env'}
                options={appsOptionsWithHigherOrderThanCurrentApp}
                extraInputClass="dark:disabled:!bg-night-100 disabled:text-white/70 h-10 text-white min-w-[150px]"
                extraLabelClass="!text-gray-820"
              />
            </div>
          )}

          {isDefaultApp && tableView === 1 && (
            <Button
              className="font-bold min-w-[100px]"
              disabled={unpromotedCommits?.data?.length === 0}
              onClick={() => {
                publishModalActions.onOpen();
              }}
            >
              <CirclePlus className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
              Publish
            </Button>
          )}

          {!isProductionApp && tableView === 2 && (
            <Button
              className="font-bold min-w-[100px] disabled:cursor-not-allowed"
              disabled={
                promotedCommits?.data?.results?.length === 0 ||
                promotedCommits?.data?.results?.[0]?.isPromoted === true
              }
              onClick={() => {
                migrateModalActions.onOpen();
              }}
            >
              <CirclePlus className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
              Migrate
            </Button>
          )}
        </div>
      </div>

      {/* Tab Content are not used due to css issues here */}
      <>
        {tableView === 1 ? (
          <ChangesTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            tableData={unpromotedCommits?.data ?? []}
            publishModalOpen={publishModalActions.onOpen}
            changesDiffModalOpen={changesDiffModalActions.onOpen}
            loading={unpromotedCommits.isLoading}
            setSelectedRows={setSelectedUnPublishedRows}
          />
        ) : (
          <PublishedTable
            isProductionApp={isProductionApp}
            selectedEnvironment={environment}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            tableData={promotedCommits.data?.results ?? []}
            publishDiffModalOpen={publishDiffModalActions.onOpen}
            loading={promotedCommits.isLoading}
            setSelectedRows={setSelectedUnPublishedRows}
            pagination={pagination}
            setPagination={setPagination}
            totalPages={promotedCommits.data?.totalPages}
            reverModalOpen={reverModalActions.onOpen}
            setSelectedPublished={setSelectedPublished}
          />
        )}
      </>
    </div>
  );
};

export default DashboardCommits;
