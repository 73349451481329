import { useDeleteScheduled } from 'api/scheduled';
import { CustomDeleteModal } from 'components/modal';
import { useAuthStore } from 'store/authStore';
import { useScheduledStore } from 'store/scheduledStore';
import useCustomToast from 'utils/use-toast';

function DeleteScheduledModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { scheduled } = useScheduledStore(state => state);
  const deleteScheduled = useDeleteScheduled();
  const toast = useCustomToast();
  const { appId } = useAuthStore(state => state);

  const handleSubmit = () => {
    deleteScheduled.mutate(
      {
        appId,
        scheduledId: scheduled.id,
      },
      {
        onSuccess: () => {
          toast.success('schedule removed successfully');
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteScheduled.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove scheduled notification"
      text={'Are you sure to remove this scheduled notification?'}
    />
  );
}

export default DeleteScheduledModal;
