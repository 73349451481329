import {
  GetMinimalTemplateContents,
  GetTemplateContent,
} from 'api/templates/get-template/types';
import React from 'react';
import { useTemplateStore } from 'store/templateStore';
import {
  DEFAULT_TEMPLATE_ID,
  allTemplateDetails,
  inAppTemplateIds,
} from 'templates';
import { $currentTemplate, setTemplateConfigValues } from 'templates/store';
import { DEFAULT_TEMPLATE_STATE } from 'templates/utils';
import { createImagePreviewSrc, isEmptyObj } from 'utils/functions';
import { R } from 'utils/remeda-utils';

export const useMobileWebInAppTemplateDefaultPreviewSetup = ({
  formValues,
  savedTemplateData,
}: {
  formValues: {
    [x: string]: any;
  };
  savedTemplateData: GetMinimalTemplateContents;
}) => {
  const { tab } = useTemplateStore(state => state);

  // defaults
  const defaultTitleText = 'Notification Title';
  const defaultContentText = 'Notification Content';

  // states
  const [previewContent, setPreviewContent] = React.useState({
    title: '',
    message: '',
    batchingTitle: '',
    batchingMessage: '',
    icon: [] as File[] | string,
    batchingIcon: [] as File[] | string,
  });

  // Icon Preview
  const iconSelector = tab === 'template' ? 'icon' : 'batchingIcon';
  const hasPreviewImage = Boolean(previewContent[iconSelector]);
  const previewImageContent = previewContent[iconSelector];
  const previewImageSrc = createImagePreviewSrc(previewImageContent);

  // Title Preview
  const titleSelector = tab === 'template' ? 'title' : 'batchingTitle';
  const previewTitle = Boolean(previewContent[titleSelector])
    ? previewContent[titleSelector]
    : defaultTitleText;

  // Message Preview
  const messageSelector = tab === 'template' ? 'message' : 'batchingMessage';
  const previewMessage = Boolean(previewContent[messageSelector])
    ? previewContent[messageSelector]
    : '';

  // Form Values being updated
  React.useEffect(() => {
    setPreviewContent({
      title: formValues?.['title'],
      message: formValues?.['message'],
      batchingTitle: formValues?.['batchingTitle'],
      batchingMessage: formValues?.['batchingContent'],
      icon: formValues?.icon,
      batchingIcon: formValues?.batchingIcon,
    });
  }, [formValues]);

  // Initial Api Data
  React.useEffect(() => {
    const savedTemplateDataContent =
      savedTemplateData?.content as GetTemplateContent;
    const savedBatchedTemplateDataContent =
      savedTemplateData?.batchingContent as GetTemplateContent;

    setPreviewContent({
      title: savedTemplateDataContent?.title,
      message: savedTemplateDataContent?.message,
      icon: savedTemplateDataContent?.icon,
      batchingTitle: savedBatchedTemplateDataContent?.title,
      batchingMessage: savedBatchedTemplateDataContent?.content,
      batchingIcon: savedBatchedTemplateDataContent?.icon,
    });
  }, [savedTemplateData?.batchingContent, savedTemplateData?.content]);

  return {
    previewContent,
    setPreviewContent,
    defaultContentText,
    defaultTitleText,
    hasPreviewImage,
    previewImageSrc,
    previewTitle,
    previewMessage,
  };
};

export const useInAppTemplatePreviewSetup = ({
  formValues,
  savedTemplateData,
  currentTemplate,
  isPrevSelectedTemplateSet,
  setIsPrevSelectedTemplateSet,
}: {
  formValues: {
    [x: string]: any;
  };
  savedTemplateData: GetMinimalTemplateContents;
  currentTemplate: inAppTemplateIds;
  isPrevSelectedTemplateSet: boolean;
  setIsPrevSelectedTemplateSet: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [savedValuesLoaded, setSavedValuesLoaded] = React.useState(false);

  // to update ui when typing in form
  React.useEffect(() => {
    const getValueOrDefault = (key: string, defaultValue: string | boolean) => {
      /**
       * This check is here because user has the option to give false to some inputs,
       * so if boolean we can directly return the value
       */
      if (R.isBoolean(formValues?.[key])) {
        return formValues?.[key];
      }

      /**
       * for null and undefined, and also if the user enters a empty string,
       * we need to show the default value
       */
      return Boolean(formValues?.[key]) ? formValues?.[key] : defaultValue;
    };

    const currentTemplateDefault =
      allTemplateDetails?.[currentTemplate]?.['defaultValues'];

    /**
     * We just need to update the key value pairs of the default value object
     * as the name of the form inputs will be of the same name.
     * So simply map throught the keys and take the value from the object
     */
    const getKeysForSaving = () => {
      return Object.keys(currentTemplateDefault ?? {}).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: getValueOrDefault(`${curr}`, currentTemplateDefault[curr]),
        };
      }, {});
    };

    const formKeyValuePairsForWatching = getKeysForSaving();

    setTemplateConfigValues({
      ...formKeyValuePairsForWatching,
    });
  }, [currentTemplate, formValues]);

  // to set ui to saved template data
  React.useEffect(() => {
    if (!savedTemplateData) return;

    if (savedValuesLoaded) return;

    const currentTemplate = $currentTemplate.get();
    const stateSpec = savedTemplateData?.states?.[0]?.stateSpec;
    const { [DEFAULT_TEMPLATE_STATE]: savedTemplateDataContent, templateId } =
      stateSpec || {};
    const currentTemplateDefault =
      allTemplateDetails?.[currentTemplate]?.['defaultValues'];
    const templateWithoutStates = savedTemplateData?.states?.length === 0;

    if (
      templateId &&
      templateId !== DEFAULT_TEMPLATE_ID &&
      !isPrevSelectedTemplateSet
    ) {
      $currentTemplate.set(templateId);
      setIsPrevSelectedTemplateSet(true);
    }

    /**
     * We just need to update the key value pairs of the default value object
     * as the data saved in the backend will be of the same name.
     * So simply map throught the keys and take the value from the object
     *
     * @param value the object to take key value
     */
    const getKeysForSaving = (value: any) => {
      return Object.keys(currentTemplateDefault ?? {}).reduce((acc, curr) => {
        return {
          ...acc,
          [curr]: value?.[curr] ?? currentTemplateDefault?.[curr],
        };
      }, {});
    };

    const configValues = getKeysForSaving(savedTemplateDataContent);

    if (savedTemplateDataContent && configValues && !isEmptyObj(configValues)) {
      setTemplateConfigValues(configValues);
    }

    if (templateWithoutStates) {
      const savedTemplateDataContentWithoutStates = Object.fromEntries(
        Object.entries(savedTemplateData.content ?? {})?.map(([key, value]) => {
          return [`${DEFAULT_TEMPLATE_STATE}_${key}`, value];
        }),
      );

      if (
        savedTemplateDataContentWithoutStates &&
        !isEmptyObj(savedTemplateDataContentWithoutStates)
      ) {
        setTemplateConfigValues({
          ...configValues,
          ...savedTemplateDataContentWithoutStates,
        });
      }
    }

    setSavedValuesLoaded(true);
  }, [
    currentTemplate,
    isPrevSelectedTemplateSet,
    savedTemplateData?.states,
    setIsPrevSelectedTemplateSet,
    savedTemplateData?.content,
    savedValuesLoaded,
    savedTemplateData,
  ]);
};
