import Copy from 'assets/svg/template/copy.svg';
import { useDisclosure } from '@chakra-ui/hooks';
import Popover from 'components/popover';
import { copyToClipboard } from '../../views/admin/dashboards/apiCredentials/functions';

const CopyPopOver = ({ value }: { value: string }) => {
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <Popover
      trigger={
        <button
          type="button"
          onClick={() => {
            onToggle();
            copyToClipboard(value);
            setTimeout(() => {
              onClose();
            }, 1500);
          }}
        >
          <img width={18} className="scale-[85%]" src={Copy} alt="copy" />
        </button>
      }
      content={<p className="dark:text-white">copied</p>}
      placement="right"
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};

export default CopyPopOver;
