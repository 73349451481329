import CheckMark from '@assets/svg/billing/check-mark-green.svg';
import { useQueryClient } from '@tanstack/react-query';
import useStopSubscriptionCancellation from 'api/billing/delete-stop-plan-cancellation';
import useGetLicense from 'api/billing/get-license';
import { routes } from 'api/routes';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';

function StopCancelationModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const clientId = license.data?.InstanceClientIdentifer;
  const stopCancelSubscription = useStopSubscriptionCancellation();
  const queryClient = useQueryClient();
  const toast = useCustomToast();

  const handleClose = () => {
    onClose();
  };

  const handleResubscribe = () => {
    const data = {
      clientId,
    };

    stopCancelSubscription.mutate(data, {
      onSuccess: () => {
        toast.success('resubscription successfull');
        queryClient.invalidateQueries([`${routes['client']}/subscription`]);
        handleClose();
      },
      onError: err => {
        toast.showError(err);
      },
    });
  };

  return (
    <CustomModal
      modalCardClassName="!px-10 !py-8 !pt-0 !pb-0"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="mb-5 flex justify-center">
        <img src={CheckMark} width={145} alt="" />
      </div>

      <h1 className="mb-4 text-center text-2xl font-bold">
        We're happy to see you stay!
      </h1>

      <p className="mb-5 text-center font-medium text-white/50">
        Resubscribe to enjoy engagespot features.
      </p>

      <div className="mt-6 flex gap-4">
        <button
          className="linear flex w-full justify-center rounded-xl border border-[white] bg-[#26292F] py-3 text-base font-medium text-white transition duration-200 "
          type="button"
          onClick={handleClose}
        >
          Cancel
        </button>
        <button
          className="linear flex w-full items-center justify-center rounded-xl border border-[#23BF6E] bg-[#243323] py-3 text-base font-medium text-[#23BF6E]  transition duration-200 hover:text-[#23BF6E]/80 disabled:text-[#23BF6E]/40"
          onClick={handleResubscribe}
          disabled={stopCancelSubscription.isLoading}
        >
          Resubscribe
          {stopCancelSubscription.isLoading && (
            <LoaderSpinner
              strokeColor={'#23BF6E'}
              parentClass="relative left-3"
              width="18"
            />
          )}
        </button>
      </div>
    </CustomModal>
  );
}

export default StopCancelationModal;
