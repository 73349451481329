import {
  useGetTranslationDetails,
  usePatchUpdateTranslationJson,
} from 'api/translations';
import { CustomDropZone } from 'components/fields/FileField/dropzone';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { Button } from 'components/shadcn/button';
import { CustomHoverCard } from 'components/shadcn/hover-card';
import { TextEditor } from 'components/text-editor';
import {
  ChevronLeft,
  CirclePlus,
  LoaderPinwheel,
  Save,
  Upload,
} from 'lucide-react';
import React from 'react';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { R } from 'utils/remeda-utils';
import useCustomToast from 'utils/use-toast';
import TranslationDiffModal from './TranslationDiffModal';
import { useDisclosure } from '@chakra-ui/hooks';
import { cn } from 'utils/class-merge';
import { checkDiff, stringifyJson } from 'utils/check-diff';

type FormTranslationEditor = {
  jsonSpec: string;
  upload: File;
};

const TranslationEditor = () => {
  const toast = useCustomToast();
  const params = useParams();
  const translationId = params.translationId;
  const getTranslationDetails = useGetTranslationDetails(Number(translationId));
  const navigate = useNavigate();
  const {
    control,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormTranslationEditor>({
    mode: 'onChange',
  });
  const jsonSpec = useWatch({
    control,
    name: 'jsonSpec',
  });
  const patchUpdateTranslationJson = usePatchUpdateTranslationJson();
  const hasPublishChanges = getTranslationDetails.data?.hasUnpublishedChanges;
  const { oldJson } = stringifyJson({
    oldValue: getTranslationDetails.data?.jsonSpec ?? {},
    jsonSpacing: 4,
  });
  const hasSavedChanges = checkDiff({
    oldValue: oldJson,
    newValue: jsonSpec ?? '',
  });
  const translationDiffModalActions = useDisclosure();

  const handleGoBack = () => {
    navigate(`/admin/translation`);
  };

  const onSubmit: SubmitHandler<FormTranslationEditor> = async values => {
    try {
      await patchUpdateTranslationJson.mutateAsync({
        translationId: Number(translationId),
        jsonSpec: JSON.parse(values.jsonSpec),
      });
      toast.success('translation saved successfully');
      navigate(`/admin/translation-editor/${translationId}`);
    } catch (error: any) {
      toast.showError(error);
    }
  };

  React.useEffect(() => {
    if (getTranslationDetails.isLoading) return;
    setValue(
      'jsonSpec',
      JSON.stringify(getTranslationDetails?.data?.jsonSpec ?? {}, null, 4),
    );
  }, [
    getTranslationDetails?.data?.jsonSpec,
    getTranslationDetails.isLoading,
    setValue,
  ]);

  const handleSubmitErrors = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!R.isEmpty(errors)) {
      R.pipe(
        errors,
        R.forEachObj((val, key) => {
          toast.showError(`Invalid JSON - ${val.message}`);
        }),
      );
      return;
    }

    handleSubmit(onSubmit)(event);
  };

  return (
    <>
      <TranslationDiffModal
        isOpen={translationDiffModalActions.isOpen}
        onClose={translationDiffModalActions.onClose}
        translation={getTranslationDetails.data}
      />

      <form onSubmit={handleSubmitErrors}>
        {/* Header */}
        <div className="border-b flex justify-between border-[#ABB0B84D] px-7 h-16 items-center">
          <div className="flex items-center gap-4">
            <button
              type="button"
              className="outline outline-input"
              onClick={handleGoBack}
            >
              <ChevronLeft className="text-white" />
            </button>

            {getTranslationDetails?.data?.name && (
              <>
                <h4 className="text-gray-600">
                  Editing translation &gt; {getTranslationDetails?.data?.name}
                </h4>
                <span style={{ color: '#fff', fontSize: '10px' }}>
                  Translation file should be in JSON format &gt;
                </span>{' '}
                <a
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: '#fff',
                    fontSize: '10px',
                    textDecoration: 'underline',
                    lineHeight: '13px',
                  }}
                  href="https://docs.engagespot.co/docs/features/translations/overview"
                >
                  Learn more
                </a>
              </>
            )}
          </div>

          <div className="flex gap-2 items-center flex-nowrap">
            {hasPublishChanges && (
              <div className="text-gray-600 flex gap-2 items-center text-xs w-[145%] flex-nowrap">
                <CustomHoverCard
                  children={
                    <div className="flex gap-2 items-center cursor-default">
                      Unpublished changes
                      <LoaderPinwheel className="w-4 h-4 cursor-pointer" />
                    </div>
                  }
                  content={
                    <span className="text-gray-600 w-fit h-2 flex items-center text-sm">
                      Translation has unpublished changes
                    </span>
                  }
                />
              </div>
            )}

            <CustomDropZone
              name="upload"
              control={control}
              handleOnDrop={(acceptedFiles: File[]) => {
                acceptedFiles.forEach((file: File) => {
                  const reader = new FileReader();

                  reader.onload = () => {
                    const fileContent = reader.result;

                    try {
                      const jsonSpec = JSON.stringify(
                        JSON.parse(fileContent as string),
                        null,
                        4,
                      );

                      setValue('jsonSpec', jsonSpec);
                    } catch (e) {
                      toast.showError(
                        `The file content is not valid JSON. ${e}`,
                        '',
                        {
                          duration: 5000,
                        },
                      );
                    }
                  };

                  reader.onerror = e => {
                    toast.showError(
                      e ??
                        'The file content is not valid JSON. Please upload a correctly formatted JSON file.',
                    );
                  };

                  reader.readAsText(file);
                });
              }}
              required={false}
              showAcceptedFiles={false}
              accept={{
                'text/plain': ['.txt', '.json'],
              }}
              content={(open, isDragActive) => (
                <Button
                  className="flex items-center py-1 bg-night text-white hover:bg-background/80 border border-muted"
                  disabled={patchUpdateTranslationJson.isLoading}
                  type="button"
                  onClick={open}
                >
                  <Upload className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
                  Upload
                </Button>
              )}
            />

            <CustomHoverCard
              children={
                <Button
                  className="flex items-center py-1 border border-muted w-max"
                  disabled={
                    patchUpdateTranslationJson.isLoading || !hasSavedChanges
                  }
                  type="submit"
                >
                  <Save className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
                  Save As Draft
                  {patchUpdateTranslationJson.isLoading && (
                    <LoaderSpinner
                      strokeColor="black"
                      parentClass="relative left-2"
                    />
                  )}
                </Button>
              }
              content={
                !hasSavedChanges
                  ? 'No changes to save'
                  : 'Save changes to draft'
              }
            />

            <CustomHoverCard
              children={
                <Button
                  className={cn(
                    'flex items-center py-1 border border-muted disabled:cursor-not-allowed',
                  )}
                  onClick={translationDiffModalActions.onOpen}
                  disabled={!hasPublishChanges}
                >
                  <CirclePlus className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
                  Publish
                </Button>
              }
              content={
                !hasPublishChanges ? 'No changes to publish' : 'Publish changes'
              }
            />
          </div>
        </div>

        <div className="w-full h-full text-white">
          <Controller
            name={'jsonSpec'}
            control={control}
            render={({ field: { ref, ...field }, fieldState: { error } }) => {
              return (
                <TextEditor
                  {...field}
                  label={''}
                  setError={setError}
                  clearErrors={clearErrors}
                  error={error}
                  className="border border-input dark:bg-night-100 mt-0"
                  height={`${window?.innerHeight - 60}px`}
                  showLineNumbers={true}
                />
              );
            }}
          />
        </div>
      </form>
    </>
  );
};

export default TranslationEditor;
