import { Modal, ModalBody, ModalContent } from '@chakra-ui/modal';
import useGet2Fa from 'api/2fa/get-2fa';
import { CustomModalOverlay } from 'components/modal';
import React from 'react';
import { QrCodeModalType } from '../types';
import RecoveryQrCode from './RecoveryQrCode';
import ScanQrCode from './ScanQrCode';

function QrCodeModal({
  isOpen,
  onClose,
  modalType,
  recoveryCodes,
  setModalType,
  setRecoveryCodes,
}: {
  onClose: () => void;
  isOpen: boolean;
  modalType: QrCodeModalType;
  setModalType: React.Dispatch<React.SetStateAction<QrCodeModalType>>;
  recoveryCodes: string[];
  setRecoveryCodes: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const get2Fa = useGet2Fa(isOpen && modalType === 'scan');
  const data = get2Fa.data;

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <CustomModalOverlay />
      <ModalContent className="!z-[1002] !m-auto !w-max min-w-[300px] !max-w-[85%] top-[32%] ">
        <ModalBody>
          {modalType === 'scan' && (
            <ScanQrCode
              setModalType={setModalType}
              onClose={onClose}
              data={data}
              setRecoveryCodes={setRecoveryCodes}
            />
          )}

          {modalType === 'recovery' && (
            <RecoveryQrCode
              setModalType={setModalType}
              setRecoveryCodes={setRecoveryCodes}
              onClose={onClose}
              recoveryCodes={recoveryCodes}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default QrCodeModal;
