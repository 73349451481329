import { GetTemplate } from 'api/templates/get-template/types';
import { create } from 'zustand';

export type TemplateTabs = 'template' | 'batched_template';
export type TemplateModalState = 'add' | 'edit' | 'duplicate' | 'app_copy';

interface State {
  template: GetTemplate;
  loading: boolean;
  tab: TemplateTabs;
  templateModalState: TemplateModalState;
}

interface Action {
  setTemplate: (template: GetTemplate) => void;
  setLoading: (loading: boolean) => void;
  setTab: (tab: TemplateTabs) => void;
  setTemplateModalState: (templateModalState: TemplateModalState) => void;
  reset: () => void;
}

const initialState: State = {
  template: null,
  loading: false,
  tab: 'template',
  templateModalState: 'add',
};

export const useTemplateStore = create<State & Action>()(set => ({
  ...initialState,
  setTemplate: template => set(() => ({ template })),
  setLoading: loading => set(() => ({ loading })),
  setTab: tab => set(() => ({ tab })),
  setTemplateModalState: templateModalState =>
    set(() => ({
      templateModalState,
    })),
  reset: () => {
    set(initialState);
  },
}));
