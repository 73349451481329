import Dropdown from 'components/dropdown';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import React from 'react';
import {
  Control,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { IoCloseOutline } from 'react-icons/io5';
import { MdSubdirectoryArrowRight } from 'react-icons/md';
import { FormChannelRoutes } from './ChannelRoutesModal';

export const ChannelRouteDelay = ({
  control,
  register,
  index,
  setValue,
  getValues,
}: {
  control: Control<FormChannelRoutes, any>;
  register: UseFormRegister<FormChannelRoutes>;
  index: number;
  setValue: UseFormSetValue<FormChannelRoutes>;
  getValues: UseFormGetValues<FormChannelRoutes>;
}) => {
  const [openWrapper, setOpenWrapper] = React.useState(false);
  const currentValue = getValues(`providerInstances.${index}.delay`);
  const initialValueRef = React.useRef(currentValue);

  return (
    <Dropdown
      classNames={'py-2 top-4 -left-[0px] w-max'}
      openWrapper={openWrapper}
      setOpenWrapper={setOpenWrapper}
      useOutsideAlerterEnabled={false}
      animation="origin-top-left transition-all duration-300 ease-in-out"
      buttonClassNames={`${
        index !== 0 ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
      button={
        <button
          type="button"
          className={`relative top-2 flex h-10 w-10 items-center justify-center self-center rounded-full border-2 border-gray-850 bg-night-100 text-white ${
            index !== 0 ? 'opacity-100' : 'pointer-events-none opacity-0'
          }`}
        >
          <MdSubdirectoryArrowRight />
        </button>
      }
      children={
        <div className="flex h-max w-[372px] flex-col justify-start rounded-xl bg-white bg-cover bg-no-repeat pb-4 shadow-xl shadow-shadow-500 dark:!border dark:!border-white/40 dark:!bg-gray-850 dark:text-white dark:shadow-none ">
          <div className="ml-4 mt-3">
            <div className="flex items-center justify-between gap-2 pr-4">
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                Use Priority #{index + 1} when Email
              </p>{' '}
              <IoCloseOutline
                onClick={() => {
                  setValue(
                    `providerInstances.${index}.delay`,
                    Number(initialValueRef.current),
                  );

                  setOpenWrapper(false);
                }}
                className="cursor-pointer text-lg"
              />
            </div>
          </div>
          <div className="mt-3 h-px w-full bg-gray-200 dark:bg-white/20 " />

          <div className="ml-4 mt-3 flex gap-3">
            <SelectField
              variant="styled"
              control={control}
              extra="mb-0 w-[40%]"
              label={``}
              placeholder={``}
              showIsRequiredAsterisk={false}
              required={false}
              name={`delay_reason`}
              extraInputClass="dark:!bg-night-100 border-0  pointer-events-none"
              options={[
                {
                  label: 'Failed',
                  value: 'failed',
                },
              ]}
            />

            <span className="self-center">within</span>

            <div className="flex w-[40%] items-center rounded-xl px-2 pr-3 dark:!bg-night-100">
              <InputField<FormChannelRoutes>
                variant="auth"
                extra=""
                label={''}
                placeholder="delay in sec"
                showIsRequiredAsterisk={true}
                type="number"
                register={register}
                name={`providerInstances.${index}.delay`}
                extraInputClass="dark:!bg-night-100 border-0 !mt-0 !h-11"
                onChange={(e: any) => {
                  const output = parseInt(e.target.value, 10);
                  const value = isNaN(output) ? 0 : output;
                  setValue(`providerInstances.${index}.delay`, value);
                }}
              />
              <span>s</span>
            </div>
          </div>

          <button
            onClick={() => {
              initialValueRef.current = currentValue;
              setOpenWrapper(false);
            }}
            type="button"
            className="linear relative right-4 ml-auto mt-4 flex w-auto justify-center rounded-xl bg-brand-500 px-8 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
          >
            Set
          </button>
        </div>
      }
    />
  );
};
