import ReactDiffViewer, {
  ReactDiffViewerStylesOverride,
} from 'react-diff-viewer-continued';

enum DiffMethod {
  CHARS = 'diffChars',
  WORDS = 'diffWords',
  WORDS_WITH_SPACE = 'diffWordsWithSpace',
  LINES = 'diffLines',
  TRIMMED_LINES = 'diffTrimmedLines',
  SENTENCES = 'diffSentences',
  CSS = 'diffCss',
}

const CustomDiffViewer = ({
  oldValue,
  newValue,
  styles = {},
  splitView = true,
}: {
  oldValue?: string;
  newValue?: string;
  styles?: ReactDiffViewerStylesOverride;
  splitView?: boolean;
}) => {
  return (
    <ReactDiffViewer
      useDarkTheme={true}
      {...(oldValue && { oldValue })}
      {...(newValue && { newValue })}
      splitView={splitView}
      compareMethod={DiffMethod.WORDS}
      styles={{
        line: {
          fontSize: '12px',
        },
        wordDiff: {
          padding: '0px',
        },
        ...styles,
      }}
    />
  );
};

export default CustomDiffViewer;
