import { saveAs } from 'file-saver';

export const getFirstWordAndLetter = (content: string) => {
  const firstName = content?.split(' ')?.[0] ?? '';
  const firstLetter = content?.charAt(0).toUpperCase();
  const displayName =
    firstName?.length > 10
      ? firstName?.split(' ')?.[0].slice(0, 8) + '..'
      : firstName?.split(' ')?.[0];

  return {
    firstLetter,
    displayName,
  };
};

export function triggerMouseEvent(mouseEvent = 'click') {
  const event = new MouseEvent(mouseEvent, {
    view: window,
    bubbles: true,
    cancelable: true,
  });

  document.body.dispatchEvent(event);
}

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function isObject(o: any) {
  return o instanceof Object && o.constructor === Object;
}

export const getFirstLetter = (content: string) => {
  const firstLetter = content?.charAt(0).toUpperCase();
  return firstLetter;
};

export const formatNumber = (number: number | bigint) => {
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(number ?? 0);
};

export const getPercentage = (
  dividend: string | number,
  divisor: string | number,
) => {
  if (Number(dividend) || Number(divisor) === 0) return '0';
  if (isNaN(Number(dividend)) || isNaN(Number(divisor))) return '0';
  const result = Number(Number(dividend) / Number(divisor)) * 100;
  const formatter = Intl.NumberFormat('en', { notation: 'compact' });
  return formatter.format(result ?? 0);
};

export const getSum = (
  addend1: string | number = 0,
  addend2: string | number = 0,
) => {
  return Number(addend1) + Number(addend2);
};

export function isEmptyObj(value: Object = {}) {
  return Object.keys(value).length === 0;
}

export const saveAsFile = ({
  fileContent,
  fileName,
  returnFileContentWithoutSaving = false,
}: {
  fileContent: string;
  fileName: string;
  returnFileContentWithoutSaving?: boolean;
}) => {
  const fileContentAsString = `${fileContent}`;

  const blob = new Blob([fileContentAsString], {
    type: 'text/plain;charset=utf-8',
  });

  if (returnFileContentWithoutSaving) {
    return blob;
  }

  saveAs(blob, fileName);
};

export const createImagePreviewSrc = (fileContent: any) => {
  const previewImageSrc =
    typeof fileContent === 'string' && Boolean(fileContent)
      ? fileContent
      : Array.isArray(fileContent) && fileContent.length > 0
        ? URL.createObjectURL(fileContent[0] as File)
        : '';

  return previewImageSrc;
};

export async function asyncReduce<T, U>(
  array: T[],
  reducer: (acc: U, cur: T, idx: number) => Promise<U>,
  initialValue: U,
): Promise<U> {
  let result = initialValue;
  for (let i = 0; i < array.length; i++) {
    result = await reducer(result, array[i], i);
  }
  return result;
}

export function getDataFromUrlString(url: string, param: string) {
  const urlObject = new URL(url);
  const searchParams = urlObject.searchParams;
  const invitationCode = searchParams.get(param);
  return invitationCode;
}

export const getMilliSecondInMinutes = (minute: number) => {
  return minute * 60 * 1000;
};

export function isValidPhoneNumber(phone: string): boolean {
  const phoneRegex = /^\+?[1-9]\d{1,14}$/;
  return phoneRegex.test(phone);
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
}
