import { PaginationState } from '@tanstack/react-table';
import React from 'react';

export const PAGE_SIZE = 10;
export const LOADING_PAGE_SIZE = 12;
export const PAGE_SIZES_ARRRAY = [10, 20, 30, 40, 50];

export const usePaginationApiState = (
  {
    pageIndex,
    pageSize,
  }: {
    pageIndex: number;
    pageSize: number;
  } = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
  },
) => {
  const [pagination, setPagination] = React.useState({
    pageIndex,
    pageSize,
  });

  return {
    pagination,
    setPagination,
  };
};

export const usePaginationState = () => {
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: PAGE_SIZE,
    });

  return {
    pageIndex,
    pageSize,
    setPagination,
  };
};
