import { useParams, useSearchParams } from 'react-router-dom';

export const useGetWorkflowOverviewPageParams = () => {
  const params = useParams();
  const workflowId = params.workflowId;

  const [searchParams] = useSearchParams();
  const currentView = new URLSearchParams(searchParams).get('view');
  const workflowRunId = new URLSearchParams(searchParams).get('workflowRunId');

  return {
    workflowId: Number(workflowId),
    currentView,
    workflowRunId: Number(workflowRunId),
  };
};
