import { Channels } from 'api/channels/get-channels/types';

export let engagespotChannels: { id: Channels }[] = [
  {
    id: 'webPush',
  },
  {
    id: 'webhook',
  },
  {
    id: 'discord',
  },
  {
    id: 'email',
  },
  {
    id: 'slack',
  },
  {
    id: 'whatsapp',
  },
  {
    id: 'sms',
  },
  {
    id: 'inApp',
  },
  {
    id: 'mobilePush',
  },
];
