import { useDisclosure } from '@chakra-ui/hooks';
import { useQueryClient } from '@tanstack/react-query';
import useGetLicense from 'api/billing/get-license';
import usePostDefaultPaymentMethod from 'api/billing/post-default-payment-method';
import { routes } from 'api/routes';
import Dropdown from 'components/dropdown';
import React from 'react';
import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import DeleteCreditCardModal from './DeleteCreditCardModal';

type CategoryAction = 'make_default' | 'remove';

function CreditCardMenu(props: {
  transparent?: boolean;
  vertical?: boolean;
  paymentMethodId: string;
  isDefault: boolean;
}) {
  const deleteCreditCardModalActions = useDisclosure();
  const { transparent, vertical, paymentMethodId, isDefault } = props;
  const [openWrapper, setOpenWrapper] = React.useState(false);
  const postDefaultPaymentMethod = usePostDefaultPaymentMethod();
  const { clientId } = useAuthStore(state => state);
  const license = useGetLicense(clientId);
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const handleMenuClick = (actionType: CategoryAction) => {
    if (isDefault) {
      if (actionType === 'remove') {
        toast.error('you cannot remove default payment method');
      }

      return setOpenWrapper(false);
    }

    if (actionType === 'make_default') {
      const data = {
        clientId: license.data?.InstanceClientIdentifer,
        paymentMethodId,
      };

      postDefaultPaymentMethod.mutate(data, {
        onSuccess: () => {
          toast.success('default payment method changed successfully');
          queryClient.invalidateQueries([
            `${routes['clients']}/paymentMethods`,
          ]);
        },
      });
    }

    if (actionType === 'remove') {
      deleteCreditCardModalActions.onOpen();
    }

    setOpenWrapper(false);
  };

  return (
    <>
      <DeleteCreditCardModal
        isOpen={deleteCreditCardModalActions.isOpen}
        onClose={deleteCreditCardModalActions.onClose}
        paymentMethodId={paymentMethodId}
      />

      <Dropdown
        openWrapper={openWrapper}
        setOpenWrapper={setOpenWrapper}
        button={
          <button
            className={`flex items-center text-sm hover:cursor-pointer ${
              transparent
                ? 'bg-none text-white hover:bg-none active:bg-none'
                : vertical
                  ? 'bg-white text-gray-450'
                  : 'bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-gray-450 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10'
            } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            {vertical ? (
              <p className="text-[20px] hover:cursor-pointer">
                <BsThreeDotsVertical className="h-4" />
              </p>
            ) : (
              <BsThreeDots className="h-6 w-6" />
            )}
          </button>
        }
        animation={'origin-[10%_-15%] transition-all duration-300 ease-in-out'}
        classNames={`${transparent ? 'top-8' : 'top-9'}  w-max`}
        children={
          <div className="z-50 w-max rounded-xl border border-[#434343] bg-white px-3 py-2 text-sm shadow-xl shadow-shadow-500 dark:!bg-white dark:shadow-none">
            <button
              onClick={() => handleMenuClick('make_default')}
              className="flex w-full cursor-pointer items-center gap-2 rounded-xl p-2 px-4 font-medium text-black  hover:bg-gray-300"
            >
              Make Default
            </button>
            <button
              onClick={() => handleMenuClick('remove')}
              className="mt-2 flex w-full cursor-pointer items-center gap-2 rounded-xl p-2 px-4 pt-1 font-medium text-black  hover:bg-gray-300"
            >
              Remove
            </button>
          </div>
        }
      />
    </>
  );
}

export default CreditCardMenu;
