import { create } from 'zustand';

import getConfiguration from '../../getConfiguration';

import { TEditorConfiguration } from './core';

type TValue = {
  document: TEditorConfiguration;
  selectedBlockId: string | null;
  selectedSidebarTab: 'block-configuration' | 'styles';
  selectedMainTab: 'editor' | 'preview' | 'json' | 'html';
  selectedScreenSize: 'desktop' | 'mobile';
  inspectorDrawerOpen: boolean;
  samplesDrawerOpen: boolean;
  filesToUpload: { id: string; file: File }[];
  filesToDelete: { id: string }[];
};

const defaultValue: TValue = {
  document: getConfiguration(window.location.hash),
  selectedBlockId: null as string | null,
  selectedSidebarTab: 'styles' as const,
  selectedMainTab: 'editor' as const,
  selectedScreenSize: 'desktop' as const,
  inspectorDrawerOpen: true,
  samplesDrawerOpen: true,
  filesToUpload: [],
  filesToDelete: [],
};

export const editorStateStore = create<TValue>(() => ({
  ...defaultValue,
}));

export function useDocument() {
  return editorStateStore(s => s.document);
}

export function useSelectedBlockId() {
  return editorStateStore(s => s.selectedBlockId);
}

export function useSelectedScreenSize() {
  return editorStateStore(s => s.selectedScreenSize);
}

export function useSelectedMainTab() {
  return editorStateStore(s => s.selectedMainTab);
}

export function setSelectedMainTab(selectedMainTab: TValue['selectedMainTab']) {
  return editorStateStore.setState({ selectedMainTab });
}

export function useSelectedSidebarTab() {
  return editorStateStore(s => s.selectedSidebarTab);
}

export function useInspectorDrawerOpen() {
  return editorStateStore(s => s.inspectorDrawerOpen);
}

export function useSamplesDrawerOpen() {
  return editorStateStore(s => s.samplesDrawerOpen);
}

export function useFilesToUpload() {
  return editorStateStore(s => s.filesToUpload);
}

export function useFilesToDelete() {
  return editorStateStore(s => s.filesToDelete);
}

export function setSelectedBlockId(selectedBlockId: TValue['selectedBlockId']) {
  const selectedSidebarTab =
    selectedBlockId === null ? 'styles' : 'block-configuration';
  const options: Partial<TValue> = {};
  if (selectedBlockId !== null) {
    options.inspectorDrawerOpen = true;
  }
  return editorStateStore.setState({
    selectedBlockId,
    selectedSidebarTab,
    ...options,
  });
}

export function setSidebarTab(
  selectedSidebarTab: TValue['selectedSidebarTab'],
) {
  return editorStateStore.setState({ selectedSidebarTab });
}

export function resetDocument(document: TValue['document']) {
  return editorStateStore.setState({
    document,
    selectedSidebarTab: 'styles',
    selectedBlockId: null,
  });
}

export function resetDocumentToDefault() {
  return editorStateStore.setState({
    ...defaultValue,
  });
}

export function setFileToUpload(filesToUpload: TValue['filesToUpload']) {
  return editorStateStore.setState({
    filesToUpload,
  });
}

export function setFileToDelete(filesToDelete: TValue['filesToDelete']) {
  return editorStateStore.setState({
    filesToDelete,
  });
}

export function setDocument(document: TValue['document']) {
  const originalDocument = editorStateStore.getState().document;
  return editorStateStore.setState({
    document: {
      ...originalDocument,
      ...document,
    },
  });
}

export function toggleInspectorDrawerOpen() {
  const inspectorDrawerOpen = !editorStateStore.getState().inspectorDrawerOpen;
  return editorStateStore.setState({ inspectorDrawerOpen });
}

export function toggleSamplesDrawerOpen() {
  const samplesDrawerOpen = !editorStateStore.getState().samplesDrawerOpen;
  return editorStateStore.setState({ samplesDrawerOpen });
}

export function setSelectedScreenSize(
  selectedScreenSize: TValue['selectedScreenSize'],
) {
  return editorStateStore.setState({ selectedScreenSize });
}
