import { GetChannelRoutes } from 'api/channel-routes/get-channel-routes/types';
import Chip, { chipBgColors, chipTextColors } from 'components/chip';
import { format, parseISO } from 'date-fns';
import { useChannelRouteStore } from 'store/channelRouteStore';
import { getFirstLetter } from 'utils/functions';
import ChannelRouteActionMenu from './ChannelRouteActionMenu';

function ChannelRouteCard({
  route,
  handleDeleteModalOpen,
  handleEditModalOpen,
}: {
  route: GetChannelRoutes;
  handleDeleteModalOpen: () => void;
  handleEditModalOpen: () => void;
}) {
  const updatedDate = format(parseISO(route.updatedAt), 'dd-MM-yyyy p');
  const { setChannelRoute } = useChannelRouteStore(state => state);

  return (
    <button
      onClick={() => {
        setChannelRoute(route);
        handleEditModalOpen();
      }}
      className="group border border-input relative flex items-center gap-4 rounded-[20px] bg-night-100 px-4 py-4 pl-6 hover:bg-night-100/75"
    >
      <div className="absolute right-1 top-1">
        <ChannelRouteActionMenu
          buttonClass={'group-hover:bg-night-100/75'}
          handleDeleteModalOpen={handleDeleteModalOpen}
          route={route}
          handleEditModalOpen={handleEditModalOpen}
        />
      </div>

      {/* Image */}
      <div
        className={`h-12 w-12 self-start  rounded-full bg-white p-3 text-lg uppercase text-night-100`}
      >
        {getFirstLetter(route.name ?? '')}
      </div>

      <div className="flex flex-col items-start justify-start">
        <p className="mb-1 text-left  text-base font-bold">
          {route.name ?? ''}
        </p>

        <p className="mb-1 text-left  text-xs font-bold text-gray-600">
          {updatedDate}
        </p>

        <div className="flex items-center  gap-3">
          {/* Chip */}
          <div className="w-fit">
            <Chip
              text={`${route.routeProviders.length} ${
                route.routeProviders.length === 1 ? 'Provider' : 'Providers'
              }  `}
              className={`${chipBgColors['green']} ${chipTextColors['green']}  `}
            />
          </div>
        </div>
      </div>
    </button>
  );
}

export default ChannelRouteCard;
