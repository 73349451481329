import {
  useQuery,
  QueryFunctionContext,
  useQueryClient,
  useMutation,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetCliTokens, PostCliToken, PostCliTokenResponse } from './types';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetCliTokens> => {
  const [, clientId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['clients']}/${clientId}/cli-token`,
  );
  return data;
};

export const useGetCliTokens = () => {
  const { clientId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['client']}/cli-token`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
  });
};

const post = async (values: PostCliToken) => {
  const { clientId, name } = values;
  const data = {
    name,
  };

  return axiosInstance.post<PostCliTokenResponse>(
    `${routes['clients']}/${clientId}/cli-token`,
    data,
  );
};

export const usePostCliToken = () => {
  const { clientId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: post,
    onSuccess: () => {
      queryClient.invalidateQueries([
        `${routes['client']}/cli-token`,
        clientId,
      ]);
    },
  });
};
