import { CustomModal } from 'components/modal';
import { buttonVariants } from 'components/shadcn/button';
import { cn } from 'utils/class-merge';

function UnsavedChangesModal({
  isOpen,
  onClose,
  proceed,
  reset,
  setIsEmailDirty,
}: {
  proceed: () => void;
  reset: () => void;
  onClose: () => void;
  isOpen: boolean;
  setIsEmailDirty?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const handleClose = () => {
    reset();
    onClose();
  };

  return (
    <CustomModal
      modalContentClassName="!min-w-[350px]"
      modalCardClassName="!max-w-[450px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mx-auto mb-[20px] text-2xl font-bold">Unsaved Changes</h1>
      <p className="mx-auto mb-[20px]">
        Are you sure you want to leave this page ?{' '}
        <span className="text-red-20">
          Warning: All your unsaved data will be lost.
        </span>
      </p>
      <div className="flex gap-2">
        <button
          onClick={() => {
            setIsEmailDirty?.(false);
            proceed();
          }}
          className={cn(
            buttonVariants(),
            'w-full bg-red-20 hover:bg-red-20/90',
          )}
          type="button"
        >
          Leave
        </button>

        <button
          className={cn(buttonVariants(), 'w-full')}
          type="button"
          onClick={handleClose}
        >
          Stay
        </button>
      </div>
    </CustomModal>
  );
}

export default UnsavedChangesModal;
