import Copy from '@assets/svg/common/copy.svg';
import { useDisclosure } from '@chakra-ui/hooks';
import { Channels } from 'api/channels/get-channels/types';
import Popover from 'components/popover';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
import atomOneDark from 'react-syntax-highlighter/dist/esm/styles/hljs/atom-one-dark';
import { capitalizeFirstLetter } from 'utils/functions';
import { copyToClipboard } from '../../apiCredentials/functions';
SyntaxHighlighter.registerLanguage('javascript', js);

const customCodeStyle = {
  fontFamily: 'Source Code Pro',
  fontSize: '13px',
  padding: '18px',
  borderRadius: '20px',
  background: '#26292f',
  width: '100%',
};

const userProfileUrl = `https://docs.engagespot.co/docs/features/user/who-is-user/`;

const ChannelNote = ({
  channel,
  providerIdentifier,
}: {
  channel: Channels;
  providerIdentifier?: string;
}) => {
  return (
    <>
      {channel === 'webPush' && providerIdentifier === 'default_webpush' && (
        <WebPushNote />
      )}

      {channel === 'webPush' && providerIdentifier === 'verifyIntegration' && (
        <WebPushNote headingType="secondary" />
      )}

      {channel === 'email' && <EmailNote />}

      {(channel === 'sms' || channel === 'whatsapp') && (
        <PhoneNumberNote channel={capitalizeFirstLetter(channel)} />
      )}

      {channel === 'inApp' && <InAppNote />}

      {channel === 'mobilePush' && providerIdentifier === 'fcm' && (
        <MobilePushNote />
      )}

      {channel === 'slack' && <SlackNote />}

      {channel === 'webhook' && <WebhookNote />}

      {channel === 'discord' && <DiscordNote />}
    </>
  );
};

const WebPushNote = ({
  headingType = 'primary',
}: {
  headingType?: 'primary' | 'secondary';
}) => {
  const copyActions = useDisclosure();

  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      {headingType === 'primary' ? (
        <h4 className="">
          Note <span className="text-brand-400">:</span>
        </h4>
      ) : (
        <h4 className="text-base  font-semibold text-white">
          Make sure you have added the service worker{' '}
          <span className="text-brand-400">:</span>
        </h4>
      )}

      <p>
        ⚠️{' '}
        <b>
          Webpush will NOT work until you setup In-App component and service
          worker on your web-app!
        </b>
      </p>

      <p>
        1. Setup In-App component in your app by reading{' '}
        <a
          style={{ color: '#fff', textDecoration: 'underline' }}
          target="blank"
          href="https://docs.engagespot.co/docs/features/in-app-inbox/introduction"
        >
          this guide
        </a>
        .
      </p>
      <p>
        2. Now, add a service worker to your web app by creating a new file -
        service-worker.js in your app's public folder, then copy the following
        line to the file, and save it. (If you're using Bubble.io, setup
        service-worker by reading{' '}
        <a
          style={{ color: '#fff', textDecoration: 'underline' }}
          target="blank"
          href="https://docs.engagespot.co/docs/plugins/bubble-io-plugin/enable-web-push#adding-the-service-worker-file"
        >
          this guide
        </a>
        )
      </p>

      <div className="relative">
        <SyntaxHighlighter
          customStyle={customCodeStyle}
          language={'javascript'}
          style={atomOneDark}
          showLineNumbers={false}
        >
          {`importScripts('https://cdn.engagespot.co/serviceWorkerv2.js');`}
        </SyntaxHighlighter>

        <Popover
          trigger={
            <button
              className="absolute right-2 top-3 bg-gray-850"
              type="button"
              onClick={() => {
                copyActions.onToggle();
                copyToClipboard(
                  `importScripts('https://cdn.engagespot.co/serviceWorkerv2.js');`,
                );
                setTimeout(() => {
                  copyActions.onClose();
                }, 1500);
              }}
            >
              <img className="scale-[85%]" src={Copy} alt="copy" />
            </button>
          }
          content={<p className="dark:text-white">copied</p>}
          placement="right"
          isOpen={copyActions.isOpen}
          onClose={copyActions.onClose}
        />
      </div>

      <p>
        If you're still confused, read{' '}
        <a
          style={{ color: '#fff', textDecoration: 'underline' }}
          target="blank"
          href="https://docs.engagespot.co/docs/features/channels/web-push/default-web-push-provider"
        >
          this guide
        </a>
      </p>
    </div>
  );
};

const EmailNote = () => {
  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      <h4 className="">
        Note <span className="text-brand-400">:</span>
      </h4>

      <p>
        Email providers uses <b>email attribute</b> in your
        <a
          href={userProfileUrl}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          user's profile
        </a>
        as the primary address to deliver the notifications. So make sure your
        user's profile has the <b>email</b> attribute set.
      </p>
    </div>
  );
};

const PhoneNumberNote = ({ channel }: { channel: string }) => {
  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      <h4 className="">
        Note <span className="text-brand-400">:</span>
      </h4>

      <p>
        {channel} providers uses <b>phoneNumber</b> property in your
        <a
          href={userProfileUrl}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          user's profile.
        </a>
        That means, you should update the phoneNumber property in your user's
        profile. The phoneNumber should have country code without any spaces or
        special characters. Example +919988776655 is a valid number whereas
        (457)-746-846 is an invalid number.
      </p>

      <p>
        To learn how to update your user's phone number in Engagespot, read our
        <a
          href={`https://docs.engagespot.co/docs/rest-api/#tag/Users/paths/~1v3~1users~1%7Bidentifier%7D/put`}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          REST API
        </a>
        or SDK documentation of your language of choice.
      </p>
    </div>
  );
};

const InAppNote = () => {
  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      <h4 className="">
        Note <span className="text-brand-400">:</span>
      </h4>

      <p>
        Every app must enable <b>secure mode</b> for production usage to prevent
        unauthorised access to the In-App Inbox on your application.
      </p>
    </div>
  );
};

const MobilePushNote = () => {
  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      <h4 className="">
        Note <span className="text-brand-400">:</span>
      </h4>

      <p>
        FCM Provider uses the fcm.tokens array in your
        <a
          href={userProfileUrl}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          user's profile.
        </a>
        to send notifications to them. That means, you should update the
        fcm.tokens array in your user's profile whenever you generate a Firebase
        Registration Token in your mobile device.
      </p>

      <p>
        Please also note that, when you create a new user, they won't have the{' '}
        <b>fcm</b> object or <b>fcm.tokens</b> array. You can pass your user's
        FCM Token via <b>fcm.tokens</b> property via <b>PUT</b>
        <a
          href={`/docs/rest-api#tag/Profile/paths/~1v3~1profile/put`}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          request
        </a>
        . When you add more tokens, we'll keep adding them to <b>fcm.tokens</b>{' '}
        array.
      </p>
    </div>
  );
};

const SlackNote = () => {
  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      <h4 className="">
        Note <span className="text-brand-400">:</span>
      </h4>

      <p>
        Slack uses <b>slack</b> attribute in your
        <a
          href={userProfileUrl}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          user's profile
        </a>
        as the primary address to deliver the notifications. So make sure your
        user's profile has the <b>slack</b> attribute set.
      </p>
    </div>
  );
};

const WebhookNote = () => {
  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      <h4 className="">
        Note <span className="text-brand-400">:</span>
      </h4>

      <p>
        Webhook providers looks for <b>webhookUrl</b> property in your recipient
        <a
          href={userProfileUrl}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          user's profile
        </a>
        So make sure you have attached <b>webhookUrl</b> for each user who wants
        to receive a webhook from your app.
      </p>
    </div>
  );
};

const DiscordNote = () => {
  return (
    <div className="mt-6 flex flex-col gap-6 text-sm text-[#BFBFBF]">
      <h4 className="">
        Note <span className="text-brand-400">:</span>
      </h4>

      <p>
        Discord uses <b>discord</b> attribute in your
        <a
          href={userProfileUrl}
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          user's profile
        </a>
        as the primary address to deliver the notifications. So make sure your
        user's profile has the <b>discord</b> attribute set.
      </p>
    </div>
  );
};

export default ChannelNote;
