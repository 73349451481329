export const getIdInSpecificFormat = (message: string) => {
  return message?.slice(0, 8);
};

export const getRevertMessage = (message: string) => {
  const uuidPattern =
    '[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}';
  const revertRegex = new RegExp(`^(revert-changes-)+${uuidPattern}.*$`);
  const revertMessageRegex = new RegExp(`revert-changes-(${uuidPattern})(.*)$`);
  const revertedId = String(message).match(revertMessageRegex)?.[1];
  return revertRegex ? getIdInSpecificFormat(revertedId) : null;
};
