import Card from 'components/card';
import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { AiTwotoneDelete } from 'react-icons/ai';
import { MdOutlineCloudUpload } from 'react-icons/md';
import { FileFieldProps } from '../types';
import { disabledFileStyles } from '..';

const AcceptedFiles = <T,>({
  file,
  setValue,
  name,
  disabled,
}: {
  file: File | string;
  setValue: UseFormSetValue<T>;
  name?: Path<T>;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`flex items-center justify-between rounded-lg bg-night-100 px-3 py-3 ${
        disabled ? disabledFileStyles : ''
      }`}
    >
      <div className="flex items-center gap-3">
        <img
          className="mx-auto h-10 w-10 cursor-pointer rounded-lg"
          src={typeof file === 'string' ? file : URL.createObjectURL(file)}
          alt=""
        />

        <p className="overflow-clip">
          {typeof file === 'string' ? 'Uploaded File' : file?.name}
        </p>
      </div>

      <button
        disabled={disabled}
        onClick={() => setValue(name, null)}
        type="button"
      >
        <AiTwotoneDelete className="h-6 w-6 text-red-500" />
      </button>
    </div>
  );
};

const DropZonefile = <T,>({
  content,
  control,
  name,
  required,
  setValue,
  accept = {
    'image/png': ['.png', '.jpeg', '.jpg'],
  },
  disabled,
}: {
  content: (open: () => void, isDragActive: boolean) => JSX.Element;
  control: Control<T, FieldValues>;
  name?: Path<T>;
  required?: boolean;
  setValue: UseFormSetValue<T>;
  accept?: Accept;
  disabled?: boolean;
}) => {
  const selectedFiles = useWatch({
    control,
    name: name,
  });

  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      setValue(name, acceptedFiles, {
        shouldValidate: true,
      });
    },
    [name, setValue],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    accept,
  });

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <div
          className={`flex h-full w-full cursor-pointer items-center justify-center rounded-xl`}
          {...getRootProps({ className: 'dropzone w-full' })}
        >
          <input
            {...getInputProps({
              id: name,
              onChange: field.onChange,
              onBlur: field.onBlur,
            })}
          />
          <button type="button" className="h-full !w-full">
            {' '}
            {content(open, isDragActive)}{' '}
          </button>

          {/* Accepted Files Preview Array of Elements */}
          {Array.isArray(selectedFiles) &&
            (selectedFiles as File[])?.length && (
              <div className="mt-4">
                {(selectedFiles as File[])?.map((file, idx) => (
                  <AcceptedFiles
                    setValue={setValue}
                    name={name}
                    key={idx}
                    file={file}
                    disabled={disabled}
                  />
                ))}
              </div>
            )}

          {/* Accepted Files Preview String */}
          {typeof selectedFiles === 'string' && Boolean(selectedFiles) && (
            <div className="mt-4">
              <AcceptedFiles
                setValue={setValue}
                name={name}
                file={selectedFiles}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      )}
    />
  );
};

const DefaultFileVariant = <T,>({
  label,
  showIsRequiredAsterisk = false,
  control,
  required,
  name,
  extraInputClass,
  extraLabelClass,
  extra,
  setValue,
  accept,
  disabled,
}: FileFieldProps<T>) => {
  return (
    <Card extra={`w-full my-3 border-none  ${extra}`}>
      {label && (
        <label
          className={`ml-1.5 text-sm  font-medium text-navy-700 dark:text-white ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}
      <div
        className={`mt-2 flex w-full items-center justify-center rounded-[20px]`}
      >
        <DropZonefile
          control={control}
          name={name}
          setValue={setValue}
          required={required}
          accept={accept}
          disabled={disabled}
          content={(open, isDragActive) => (
            <button
              type="button"
              onClick={open}
              className={`flex h-[205px] w-full flex-col items-center justify-center rounded-xl  bg-gray-100 px-[5px]   ${
                isDragActive ? 'dark:!bg-night-100/60' : 'dark:!bg-night-100'
              } ${disabled ? disabledFileStyles : ''}   ${extraInputClass}`}
              disabled={disabled}
            >
              <p className="text-[80px] text-navy-700 dark:text-white">
                <MdOutlineCloudUpload />
              </p>
              <p className="text-lg font-bold text-navy-700 dark:text-white">
                Drop your Image here, or
                <span className="pl-2 font-bold text-brand-500 dark:text-brand-400">
                  Click to browse
                </span>
              </p>
              <p className="text-lightFourth pt-2 text-sm font-medium">
                1600 x 1200 (4:3) recommended. PNG, JPG and GIF files are
                allowed
              </p>
            </button>
          )}
        />
      </div>
    </Card>
  );
};

export default DefaultFileVariant;
