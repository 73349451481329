import { componentBlockConfig, templateInfo } from 'templates/types';
import {
  DEFAULT_TEMPLATE_STATE,
  createDefaultInAppConfig,
} from 'templates/utils';
import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';
import EngageSpotIcon from 'assets/img/others/engagespot-icon.png';

/**
 * used in setting initial inapp api data
 * and also when watching form value changes
 */
const defaultValues = {
  state_1_icon: '',
  state_1_icon_select: 'file',
  state_1_title: 'Notification Title',
  state_1_message: '',
  state_1_url: '',
  state_1_banner_image: '',
  state_1_banner_image_select: 'file',

  // preview
  preview_title: '<b>John Doe</b> uploaded new post',
  preview_banner_image: EngageSpotIcon,
};

const info: templateInfo = {
  template: 'banner_image',
  category: 'default',
  tags: ['content'],
};

const stateConfig = [
  {
    key: DEFAULT_TEMPLATE_STATE,
    label: 'State 1',
  },
];

const getBlockConfig = (values = defaultValues): componentBlockConfig => {
  return {
    state_1: [
      {
        id: 'state_1_banner_image',
        type: 'image' as const,
        src: values.state_1_banner_image,
        variant: 'primary',
      },
    ],
  };
};

const state_1Config = createDefaultInAppConfig();
const { template } = state_1Config;

export const consoleConfig = {
  state_1: {
    template: [
      template[0],
      template[1],
      template[2],
      {
        label: 'Banner Image',
        required: false,
        placeholder: 'https://example.com',
        name: 'state_1_banner_image',
        type: 'input|file',
      },
      template[3],
    ],
    batched_template: {},
  },
};

export const bannerImageTemplate = {
  blockConfig: (values: any) => getBlockConfig(values),
  states: stateConfig,
  consoleInputs: consoleConfig as inAppTemplate,
  defaultValues,
  info,
};
