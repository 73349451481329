import { useQueryClient } from '@tanstack/react-query';
import useDeleteApp from 'api/app/delete-app';
import { GetClientApps } from 'api/client/get-client-apps/types';
import { routes } from 'api/routes';
import InputField from 'components/fields/InputField';
import {
  CustomDeleteModalContent,
  CustomModal,
  CustomModalPrimaryButton,
} from 'components/modal';
import { useForm, useWatch } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';

function DeleteAppModal({
  isOpen,
  onClose,
  selected,
}: {
  onClose: () => void;
  isOpen: boolean;
  selected: GetClientApps;
}) {
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const deleteApp = useDeleteApp();
  const { clientId, setAppId, appId } = useAuthStore(state => state);
  const { register, handleSubmit, reset, control } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      appName: '',
    },
  });

  const appName = useWatch({
    name: 'appName',
    control,
  });

  const handleClose = () => {
    reset({ appName: '' });
    onClose();
  };

  const onSubmit = () => {
    const data = {
      appId: selected?.id,
    };

    deleteApp.mutate(data, {
      onSuccess: () => {
        if (appId === selected.id) {
          setAppId(null);
        }

        toast.success('app deleted successfully');
        queryClient.invalidateQueries([`${routes['clients']}/apps`, clientId]);
        handleClose();
      },
      onError: err => toast.showError(err),
    });
  };

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomDeleteModalContent
          content={'Remove Environment'}
          type="heading"
        />

        <CustomDeleteModalContent
          content={'Are you sure to remove this environment?'}
          type="text"
        />

        <p className="mb-1 text-left font-medium text-white/80">
          To confirm, type{' '}
          <span className="font-bold text-white">{selected?.name}</span> in the
          box below
        </p>

        <InputField
          register={register}
          placeholder="Type the name of the app you want to remove"
          name="appName"
          type="text"
          extraLabelClass={'font-medium'}
          variant="modal"
          extraInputClass={
            'border dark:bg-night-100 focus:border border-[#525151]'
          }
        />

        <div className="mt-4 flex gap-2">
          <CustomModalPrimaryButton
            className={
              appName !== selected?.name
                ? 'disabled:cursor-not-allowed disabled:opacity-70'
                : ''
            }
            text="Remove"
            loading={deleteApp.isLoading}
            disabled={deleteApp.isLoading || appName !== selected?.name}
            danger={true}
          />
        </div>
      </form>
    </CustomModal>
  );
}

export default DeleteAppModal;
