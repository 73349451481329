import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PostOnboarding = {
  step: number;
};

const post = async (values: PostOnboarding) => {
  return axiosInstance.post(`${routes['onboarding']}`, values);
};

const usePostOnboarding = () => {
  return useMutation({
    mutationFn: (values: PostOnboarding) => post(values),
  });
};

export default usePostOnboarding;
