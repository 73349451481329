import React from 'react';
import PinInput from 'react-pin-input';

const otpInputStyle = {
  borderWidth: '1px',
  width: '50px',
  height: '50px',
  borderColor: '#434750',
  borderRadius: '16px',
  color: 'white',
  fontSize: '22px',
  background: '#26292F',
  fontWeight: 'bold',
};

const otpStyle = {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
};

const otpInputFocusStyle = {
  borderColor: 'white',
  borderWidth: '1px',
};

type PinInputProps = JSX.LibraryManagedAttributes<
  typeof PinInput,
  PinInput['props']
>;

type CustomPinInputProps = PinInputProps & {
  setValue?: React.Dispatch<React.SetStateAction<string>>;
};

const Otp = (props: CustomPinInputProps) => {
  const {
    length,
    style,
    inputStyle,
    inputFocusStyle,
    initialValue = '',
    type = 'numeric',
    inputMode = 'number',
    autoSelect = true,
    setValue,
  } = props;

  return (
    <PinInput
      length={length}
      initialValue={initialValue}
      onChange={(value, index) => {
        setValue?.(value);
      }}
      type={type}
      inputMode={inputMode}
      style={style ? style : otpStyle}
      inputStyle={inputStyle ? inputStyle : otpInputStyle}
      inputFocusStyle={inputFocusStyle ? inputFocusStyle : otpInputFocusStyle}
      onComplete={(value, index) => {}}
      autoSelect={autoSelect}
      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
    />
  );
};

export { Otp, otpInputFocusStyle, otpInputStyle, otpStyle, type PinInputProps };
