import { ModalCloseButton } from '@chakra-ui/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { GetTopics } from 'api/topics/get-topics/types';
import usePatchOrDeleteTopics from 'api/topics/patch-delete-topics';
import usePostTopics from 'api/topics/post-topics';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormAddTopic } from '../types';

export const schema = yup.object({
  name: yup.string().required('name is required'),
  identifier: yup
    .string()
    .matches(
      /^[a-zA-Z0-9_]*$/,
      'Identifier can only contain letters, numbers, and underscores',
    ),
});

function TopicsModal({
  isOpen,
  onClose,
  selectedTopic,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedTopic: GetTopics;
}) {
  const { appId } = useAuthStore(state => state);
  const postTopics = usePostTopics();
  const patchOrDeleteTopics = usePatchOrDeleteTopics();
  const toast = useCustomToast();
  const editMode = Boolean(selectedTopic?.id);

  const defaultValues = React.useMemo(
    () => ({
      name: '',
      identifier: '',
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormAddTopic>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (editMode) {
      reset({
        name: selectedTopic.name,
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, editMode, reset, selectedTopic?.name, isOpen]);

  const handleClose = () => {
    reset(defaultValues);
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormAddTopic> = values => {
    if (editMode) {
      const finalActions = getFinalActions(
        `topic ${editMode ? 'edited' : 'added'}  successfully`,
      );

      const data = {
        ...values,
        topicId: selectedTopic.id,
        type: 'patch' as const,
      };

      patchOrDeleteTopics.mutate(data, finalActions);
    } else {
      const data = {
        ...values,
        appId: appId,
      };

      const finalActions = getFinalActions('topic added successfully');
      postTopics.mutate(data, finalActions);
    }
  };

  return (
    <CustomModal
      modalContentClassName="!min-w-[485px]"
      modalCardClassName="!min-w-[485px] !max-w-[450px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalCloseButton className="absolute right-[24px] top-[24px] z-10 text-white" />
      <h1 className="mb-3 text-2xl font-bold">
        {editMode ? 'Edit' : 'Add'} Topic
      </h1>
      {/* <p className="mb-6 text-sm text-[#BFBFBF]">
              Read docs to learn more about Topic
            </p> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField<FormAddTopic>
          variant="modal"
          extra="mb-3"
          label={'Topic Name'}
          placeholder={'Enter name'}
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'name'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('name', e.target.value);

            if (!editMode) {
              setValue(
                'identifier',
                e.target.value.toLowerCase().replace(/ /g, '_'),
              );
            }
          }}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        {/* Identifier */}
        {!editMode && (
          <>
            <InputField<FormAddTopic>
              variant="modal"
              extra="mb-3"
              label={'Identifier'}
              placeholder="Enter identifier"
              showIsRequiredAsterisk={false}
              type="text"
              register={register}
              name={'identifier'}
            />

            {errors.identifier && <ErrorBox error={errors.identifier} />}
          </>
        )}

        <CustomModalButtons
          handleClose={handleClose}
          loading={postTopics.isLoading || patchOrDeleteTopics.isLoading}
          primaryButtonText="Save"
        />
      </form>
    </CustomModal>
  );
}

export default TopicsModal;
