import Card from 'components/card';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/modal';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { cn } from 'utils/class-merge';

export const CustomModal = ({
  onClose,
  isOpen,
  children,
  showCloseButton = true,
  overlayClassName,
  modalContentClassName,
  modalCardClassName,
  modalCloseButtonClassName,
  modalOverlayClick,
  disabledModalCloseButton = false,
}: {
  onClose: () => void;
  modalOverlayClick?: () => void;
  isOpen: boolean;
  children: React.ReactNode;
  showCloseButton?: boolean;
  overlayClassName?: string;
  modalContentClassName?: string;
  modalCardClassName?: string;
  modalCloseButtonClassName?: string;
  disabledModalCloseButton?: boolean;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <CustomModalOverlay
        onClick={modalOverlayClick}
        overlayClassName={overlayClassName}
      />
      <ModalContent
        className={cn(
          `!z-[1002] !m-auto !w-max min-w-[485px] !max-w-[85%] top-[50%]`,
          modalContentClassName,
        )}
      >
        <ModalBody>
          <Card
            extra={cn(
              `px-[30px] relative pb-7 pt-[35px] max-h-[600px] overflow-auto flex flex-col !z-[1004] dark:!bg-night-50  border border-gray-880`,
              modalCardClassName,
            )}
          >
            {showCloseButton && (
              <ModalCloseButton
                disabled={disabledModalCloseButton}
                className={cn(
                  `absolute right-[24px] top-[24px] z-10 text-white disabled:text-white/60`,
                  modalCloseButtonClassName,
                )}
              />
            )}

            {children}
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export const CustomDeleteModalContent = ({
  content,
  type,
  headingClassName,
  textClassName,
}: {
  content: string;
  type: 'heading' | 'text';
  headingClassName?: string;
  textClassName?: string;
}) => {
  if (type === 'heading') {
    return (
      <h1 className={cn('mb-4 text-2xl font-bold', headingClassName)}>
        {content}
      </h1>
    );
  }

  if (type === 'text') {
    return (
      <p className={cn('mb-[20px] text-red-350', textClassName)}>{content}</p>
    );
  }

  return null;
};

export const CustomDeleteModal = ({
  onClose,
  isOpen,
  showCloseButton = true,
  overlayClassName,
  modalContentClassName,
  modalCardClassName,
  loading,
  handleSubmit,
  heading,
  text,
  primaryButtonText = 'Remove',
  danger = true,
  primaryButtonClassName,
  textClassName,
  loadingStrokeColor,
}: {
  heading: string;
  text: string;
  onClose: () => void;
  isOpen: boolean;
  showCloseButton?: boolean;
  overlayClassName?: string;
  modalContentClassName?: string;
  modalCardClassName?: string;
  handleSubmit: () => void;
  loading: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  danger?: boolean;
  primaryButtonClassName?: string;
  textClassName?: string;
  loadingStrokeColor?: string;
}) => {
  return (
    <CustomModal
      isOpen={isOpen}
      showCloseButton={showCloseButton}
      overlayClassName={overlayClassName}
      modalContentClassName={modalContentClassName}
      modalCardClassName={modalCardClassName}
      onClose={onClose}
    >
      <CustomDeleteModalContent content={heading} type="heading" />

      <CustomDeleteModalContent
        textClassName={textClassName}
        content={text}
        type="text"
      />

      <form
        onSubmit={e => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <CustomModalButtons
          primaryButtonText={primaryButtonText}
          handleClose={onClose}
          loading={loading}
          danger={danger}
          primaryButtonClassName={primaryButtonClassName}
          loadingStrokeColor={loadingStrokeColor}
        />
      </form>
    </CustomModal>
  );
};

export const CustomModalButtons = (props: {
  handleClose: () => void;
  loading: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  containerClassName?: string;
  danger?: boolean;
  primaryButtonOnClick?: () => void;
  primaryButtonDisabled?: boolean;
  primaryButtonClassName?: string;
  loadingStrokeColor?: string;
}) => {
  return (
    <div className={`mt-6 flex gap-4 ${props.containerClassName ?? ''}`}>
      <CustomModalSecondaryButton
        text={props.secondaryButtonText}
        handleClose={props.handleClose}
      />

      <CustomModalPrimaryButton
        text={props.primaryButtonText}
        loading={props.loading}
        danger={props.danger}
        disabled={props.primaryButtonDisabled}
        className={props.primaryButtonClassName}
        loadingStrokeColor={props.loadingStrokeColor}
        {...(props.primaryButtonOnClick
          ? { onClick: props.primaryButtonOnClick }
          : {})}
      />
    </div>
  );
};

export const CustomModalSecondaryButton = ({
  handleClose,
  text = 'Cancel',
}: {
  handleClose: () => void;
  text?: string;
}) => {
  return (
    <button
      className="linear hover:bg-gray-350 flex w-full justify-center rounded-xl bg-transparent py-3 text-base font-medium text-white transition duration-200"
      type="button"
      onClick={handleClose}
    >
      {text}
    </button>
  );
};

export const CustomModalPrimaryButton = ({
  loading,
  text = 'Save',
  danger = false,
  disabled,
  className,
  onClick,
  loadingStrokeColor,
}: {
  disabled?: boolean;
  loading: boolean;
  text?: string;
  danger?: boolean;
  className?: string;
  onClick?: () => void;
  loadingStrokeColor?: string;
}) => {
  return (
    <button
      className={cn(
        `linear flex w-full justify-center ${danger ? 'bg-red-750 text-red-20 disabled:bg-red-750/60' : 'bg-white text-black disabled:bg-white/60'} rounded-xl  py-3 text-base font-medium  transition duration-200 hover:opacity-85`,
        className,
      )}
      type="submit"
      disabled={disabled ? disabled : loading}
      {...(onClick ? { onClick } : {})}
    >
      {text}
      {loading && (
        <LoaderSpinner
          strokeColor={
            loadingStrokeColor
              ? loadingStrokeColor
              : danger
                ? '#c06262'
                : 'white'
          }
        />
      )}
    </button>
  );
};

export const CustomModalOverlay = ({
  overlayClassName,
  onClick,
}: {
  overlayClassName?: string;
  onClick?: () => void;
}) => {
  return (
    <ModalOverlay
      onClick={onClick}
      className={`bg-night-10/95 ${overlayClassName}`}
    />
  );
};
