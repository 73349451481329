import EngageSpotSquare from '@assets/img/others/engagespot-square.png';
import { yupResolver } from '@hookform/resolvers/yup';
import usePost2FaRecoveryCode, {
  Post2FaRecoveryCode,
} from 'api/2fa/post-2fa-recovery';
import usePostSignInEmail from 'api/signin/post-sigin-email';
import {
  AuthHeading,
  AuthSubmitButton,
  AuthText,
  AuthTextWithLink,
} from 'components/auth/AuthComponents';
import ErrorBox from 'components/error/ErrorBox';
import Default from 'layouts/auth/types/Default';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import PinInput from 'react-pin-input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import * as yup from 'yup';

export interface FormVerify2Auth {
  code?: string;
  email: string;
  password: string;
}

function TwoFactorAuth() {
  const postSignInEmail = usePostSignInEmail<FormVerify2Auth>();
  const post2FaRecoveryCode = usePost2FaRecoveryCode();
  const navigate = useNavigate();
  const { email, password } = useAuthStore(state => state);
  const [searchParams] = useSearchParams();
  const useRecoveryCode = new URLSearchParams(searchParams).get(
    'useRecoveryCode',
  );
  const redirect = new URLSearchParams(searchParams).get('redirect');

  const schema = yup.object({
    code: yup
      .string()
      .required('auth code is required')
      .min(
        useRecoveryCode ? 8 : 6,
        `${useRecoveryCode ? 8 : 6} digits is required`,
      ),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormVerify2Auth>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const finalActions = {
    onSuccess: () => {
      if (redirect) {
        navigate(redirect);
      } else {
        navigate('/admin/api-credentials');
      }
    },
  };

  const onSubmit: SubmitHandler<FormVerify2Auth> = values => {
    const data: Post2FaRecoveryCode = {
      email,
      password,
      [useRecoveryCode ? 'recoveryCode' : 'code']: useRecoveryCode
        ? String(values.code)
        : Number(values.code),
    };

    if (useRecoveryCode) {
      post2FaRecoveryCode.mutate(data, finalActions);
    } else {
      postSignInEmail.mutate(data, finalActions);
    }
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center dark:!bg-navy-900 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-[16vh] flex flex-col rounded-[20px] bg-white pb-16 pt-12 dark:!bg-navy-900 md:pr-6 lg:pr-0 xl:pr-24"
          >
            <img
              width={100}
              className="relative bottom-14"
              src={EngageSpotSquare}
              alt=""
            />

            <AuthHeading
              text={
                useRecoveryCode ? 'Recovery code' : '2 Factor Authentication'
              }
            />

            <AuthText
              text={
                useRecoveryCode
                  ? 'Enter below the recovery code you have saved'
                  : 'Enter below the 6 digit authentication code generated by your app '
              }
              className="mt-1"
            />
            {/* verification */}
            <div
              key={`${useRecoveryCode ? 'recovery' : 'auth'}_code`}
              className="mt-8 flex w-full flex-col items-start justify-start"
            >
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <PinInput
                    {...field}
                    length={useRecoveryCode ? 8 : 6}
                    initialValue=""
                    type={useRecoveryCode ? 'custom' : 'numeric'}
                    style={{
                      display: 'flex',
                      gap: '10px',
                    }}
                    inputStyle={{
                      borderWidth: '2px',
                      width: '50px',
                      height: '50px',
                      borderColor: '#3A3E48',
                      borderRadius: '5px',
                      color: 'white',
                      fontSize: '18px',
                      background: '#16171C',
                    }}
                    inputFocusStyle={{
                      borderColor: 'white',
                      borderWidth: '2px',
                    }}
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                  />
                )}
              />
              {errors.code && <ErrorBox error={errors.code} />}
              {!errors.code && !useRecoveryCode && postSignInEmail.error && (
                <ErrorBox error={postSignInEmail.error} />
              )}
              {!errors.code && useRecoveryCode && post2FaRecoveryCode.error && (
                <ErrorBox error={post2FaRecoveryCode.error} />
              )}
            </div>

            {/* button */}
            <AuthSubmitButton
              isLoading={
                postSignInEmail.isLoading || post2FaRecoveryCode.isLoading
              }
              disabled={
                postSignInEmail.isLoading || post2FaRecoveryCode.isLoading
              }
              text="Verify"
            />

            <div className="mt-4">
              <AuthTextWithLink
                text={
                  useRecoveryCode
                    ? 'Able to access your mobile device?'
                    : 'Unable to access your mobile device?'
                }
                linkText={
                  useRecoveryCode
                    ? 'Use Two-factor authentication code'
                    : 'Use a recovery code'
                }
                linkTo={
                  useRecoveryCode
                    ? '/auth/two-factor'
                    : '/auth/two-factor?useRecoveryCode=true'
                }
                parentClass="mt-2"
              />
            </div>

            <AuthTextWithLink
              text={'Need to return?'}
              linkText="Login"
              linkTo="/auth/login"
              parentClass="mb-7 mt-2"
            />
          </form>
        </div>
      }
    />
  );
}

export default TwoFactorAuth;
