import React from 'react';

import { Button } from '@mui/material';

import { resetDocument } from '../../documents/editor/EditorContext';
import getConfiguration from '../../getConfiguration';
import { getTemplateDataFromJson } from 'api/templates/get-template-json';
import { useLayoutStore } from 'store/layoutStore';
import clsx from 'clsx';

export default function SidebarButton({
  href,
  children,
  type = 'link',
  id,
}: {
  href: string;
  children: JSX.Element | string;
  type?: 'template' | 'layout' | 'link';
  id: string | number;
}) {
  const { setLayoutIdentifier } = useLayoutStore(state => state);
  const { layoutIdentifier } = useLayoutStore(state => state);

  const handleClick = async () => {
    try {
      if (type === 'link') {
        resetDocument(getConfiguration(href));
        return;
      }

      if (type === 'template') {
        const templateData = await getTemplateDataFromJson(href);
        resetDocument(templateData);
        return;
      }

      if (type === 'layout') {
        setLayoutIdentifier(href);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const selectedColor = '#A3A3A3';
  const selectedColor = '#E4E4E4';

  return (
    <Button
      className={clsx('text-left')}
      size="medium"
      onClick={handleClick}
      sx={{
        background:
          type === 'layout' && String(id) === layoutIdentifier
            ? selectedColor
            : '',
        '&:hover': {
          background:
            type === 'layout' && String(id) === layoutIdentifier
              ? selectedColor
              : '',
        },
        fontSize: '.80rem',
      }}
    >
      {children}
    </Button>
  );
}
