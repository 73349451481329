import {
  API_BASE_URL,
  EU_CENTRAL_1_API_BASE_URL,
  US_WEST_2_API_BASE_URL,
} from 'config';
import { REGIONS } from './regions';

export const DEFAULT_API_BASE_URL = API_BASE_URL;

export const API_BASE_URLS = {
  [REGIONS['US_WEST_2']]: US_WEST_2_API_BASE_URL,
  [REGIONS['EU_CENTRAL_1']]: EU_CENTRAL_1_API_BASE_URL,
};
