import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import {
  PublishChanges,
  PromotedCommits,
  UnCommitedChanges,
  MigrateChanges,
  CommitDetails,
  RevertChanges,
} from './types';
import { useAuthStore } from 'store/authStore';

const getUnPromotedCommits = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<UnCommitedChanges[]> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/uncommited-changes`,
  );
  return data;
};

export const useUnpromotedCommits = ({
  appId,
  enabled,
}: {
  appId: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: [`${routes['apps']}/unpromoted-commits`, appId],
    queryFn: getUnPromotedCommits,
    enabled: Boolean(appId && enabled),
  });
};

type PromotedCommitsParams = {
  page: number;
  limit: number;
  publishTo?: number;
};

const getPromotedCommits = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, PromotedCommitsParams]
>): Promise<PromotedCommits> => {
  const [, appId, params] = queryKey;

  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/commits`,
    {
      params,
    },
  );
  return data;
};

export const usePromotedCommits = ({
  appId,
  params,
  enabled,
}: {
  appId: number;
  params: PromotedCommitsParams;
  enabled?: boolean;
}) => {
  return useQuery({
    queryKey: [`${routes['apps']}/promoted-commits`, appId, params],
    queryFn: getPromotedCommits,
    enabled: Boolean(appId) && enabled,
  });
};

const publishChanges = async (values: PublishChanges) => {
  const { appId, ...data } = values;
  return axiosInstance.post(`${routes['apps']}/${appId}/commits`, data);
};

export const usePublishChanges = () => {
  const { appId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: publishChanges,
    onSuccess: response => {
      queryClient.invalidateQueries([
        `${routes['apps']}/unpromoted-commits`,
        appId,
      ]);

      queryClient.invalidateQueries([
        `${routes['apps']}/promoted-commits`,
        appId,
      ]);
    },
  });
};

const migrateChanges = async (values: MigrateChanges) => {
  const { appId, ...data } = values;
  return axiosInstance.post(`${routes['apps']}/${appId}/publish`, data);
};

export const useMigrateChanges = () => {
  const { appId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: migrateChanges,
    onSuccess: response => {
      queryClient.invalidateQueries([
        `${routes['apps']}/promoted-commits`,
        appId,
      ]);
    },
  });
};

const getCommit = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<CommitDetails> => {
  const [, commitId] = queryKey;
  const { data } = await axiosInstance.get(`${routes['commits']}/${commitId}`, {
    params: {
      page: 1,
      limit: 50,
    },
  });
  return data;
};

export const useGetCommit = (commitId: number) => {
  return useQuery({
    queryKey: [`${routes['commits']}`, commitId],
    queryFn: getCommit,
    enabled: Boolean(commitId),
    staleTime: 1000 * 5, // 5 seconds
  });
};

const revertChanges = async (values: RevertChanges) => {
  const { commitId } = values;
  return axiosInstance.put(`${routes['commits']}/${commitId}/revert`);
};

export const useRevertChanges = () => {
  const { appId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: revertChanges,
    onSuccess: response => {
      queryClient.invalidateQueries([
        `${routes['apps']}/promoted-commits`,
        appId,
      ]);
    },
  });
};
