import { GetTopics } from 'api/topics/get-topics/types';
import usePatchOrDeleteTopics from 'api/topics/patch-delete-topics';
import { CustomDeleteModal } from 'components/modal';
import useCustomToast from 'utils/use-toast';

function DeleteTopicsModal({
  isOpen,
  onClose,
  selectedTopic,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedTopic: GetTopics;
}) {
  const deleteTopics = usePatchOrDeleteTopics();
  const toast = useCustomToast();

  const handleSubmit = () => {
    deleteTopics.mutate(
      {
        type: 'delete',
        topicId: selectedTopic.id,
      },
      {
        onSuccess: () => {
          toast.success('template removed successfully');
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteTopics.isLoading}
      handleSubmit={handleSubmit}
      heading=" Remove Topic"
      text={'Are you sure to remove this topic?'}
    />
  );
}

export default DeleteTopicsModal;
