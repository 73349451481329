import IphoneBottom from 'assets/svg/template-editor/iphone-bottom.svg';
import Camera from 'assets/svg/template-editor/camera.svg';
import ChevronLeftBlue from 'assets/svg/template-editor/chevron-left-blue.svg';
import ChevronRight from 'assets/svg/template-editor/chevron-right.svg';
import Signal from 'assets/svg/template-editor/signal.svg';
import { Control, FieldValues, useWatch } from 'react-hook-form';
import VideoCall from 'assets/svg/template-editor/video-call.svg';
import { useTemplateStore } from 'store/templateStore';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';
import React from 'react';

const SmsPreview = ({ control }: { control: Control<FieldValues, any> }) => {
  const { tab } = useTemplateStore(state => state);
  const savedTemplateData = useGetTemplateChannelData();
  const defaultContentText = 'Notification Content';
  const formValues = useWatch({
    control,
  });

  const [previewContent, setPreviewContent] = React.useState({
    content: '',
    batchingContent: '',
  });

  // Form Values being updated
  React.useEffect(() => {
    setPreviewContent({
      content: formValues?.content,
      batchingContent: formValues?.['batchingContent'],
    });
  }, [formValues]);

  // Initial Api Data
  React.useEffect(() => {
    setPreviewContent({
      content: savedTemplateData?.content?.message ?? '',
      batchingContent: savedTemplateData?.batchingContent?.message ?? '',
    });
  }, [savedTemplateData?.batchingContent, savedTemplateData?.content, tab]);

  return (
    <div className="relative bottom-2 mx-auto flex min-h-[490px] w-[250px] flex-col overflow-clip rounded-[30px] border-[12px] border-black bg-white">
      {/* header */}
      <div className="rounded-t-[30px] bg-[#E9E9EB] px-3 pb-1 pt-3">
        {/* top 1 */}
        <div className="mb-[0.7rem] flex justify-between">
          <div className="text-xs font-semibold text-black">9:21</div>

          <img
            className="pointer-events-none relative left-3 self-center"
            width={65}
            src={Camera}
            alt=""
          />

          <img
            className="pointer-events-none relative"
            width={50}
            src={Signal}
            alt=""
          />
        </div>

        {/* top 2 */}
        <div className="flex justify-between">
          <img
            className="pointer-events-none relative"
            src={ChevronLeftBlue}
            alt=""
            width={22}
          />

          <div className="flex flex-col items-center gap-1">
            <div className="h-8 w-8 rounded-full bg-white"></div>
            <div className="flex items-center gap-2">
              <div className="text-[0.7rem] font-light text-night-100">
                Templates
              </div>
              <img
                className="pointer-events-none relative"
                src={ChevronRight}
                alt=""
              />
            </div>
          </div>

          <img
            className="pointer-events-none relative"
            src={VideoCall}
            alt=""
            width={20}
          />
        </div>
      </div>

      {/* middle sms content */}
      <div className="relative bg-white pl-3 pt-8">
        <div className="w-4/5 rounded-lg bg-[#D9D9D9] p-2 text-xs text-night-100">
          {tab === 'template'
            ? Boolean(previewContent.content)
              ? previewContent.content
              : defaultContentText
            : Boolean(previewContent.batchingContent)
              ? previewContent.batchingContent
              : defaultContentText}
        </div>
      </div>

      {/* bottom */}
      <div className="mt-auto">
        <img
          className="pointer-events-none relative"
          src={IphoneBottom}
          alt=""
        />
      </div>
    </div>
  );
};

export default SmsPreview;
