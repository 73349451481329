import { cn } from 'utils/class-merge';

function Card(props: {
  variant?: string;
  extra?: string;
  children?: JSX.Element | any[];
  [x: string]: any;
}) {
  const { variant, extra, children, ...rest } = props;
  return (
    <div
      className={cn(
        // `!z-5 flex flex-col overflow-x-auto overflow-y-auto rounded-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:bg-gray-850 dark:text-white dark:shadow-none`,
        `!z-5 flex flex-col overflow-x-auto overflow-y-auto border border-input rounded-md bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:bg-background dark:text-white dark:shadow-none`,
        extra,
      )}
      {...rest}
    >
      {children}
    </div>
  );
}

export default Card;
