import { useMutation } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { routes } from 'api/routes';

type StopCancelPlan = {
  clientId: string;
};

const remove = (values: StopCancelPlan) => {
  const { clientId } = values;
  return axiosLicensingInstance.delete(
    `${routes['client']}/${clientId}/subscription/cancellationRequest`,
  );
};

const useStopSubscriptionCancellation = () => {
  return useMutation({
    mutationFn: remove,
  });
};

export default useStopSubscriptionCancellation;
