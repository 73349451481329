import { useEditWorkflowJson, useSaveWorkflowStateSpec } from 'api/workflows';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import {
  handleDeleteSelectedNode,
  handleDeselectAndSave,
} from '../../functions';
import { WorkflowNodeTypes } from '../../types';
import WorkflowBackButton from '../sidebar/WorkflowBackButton';
import WorkflowBatchForm from './WorkflowBatchForm';
import WorkflowBranchForm from './WorkflowBranchForm';
import WorkflowChannelForm from './WorkflowChannelForm';
import WorkflowDelayForm from './WorkflowDelayForm';
import WorkflowPreferencesForm from './WorkflowPreferencesForm';
import { useParams } from 'react-router-dom';
import WorkflowTriggerForm from './WorkflowTriggerForm';
import WorkflowWaitForInputForm from './WorkflowWaitForInputForm';
import WorkflowFetchForm from './WorkflowFetchForm';
import { useReactFlow } from 'reactflow';
import WorkflowVariableForm from './WorkflowVariableForm';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';

const WorkflowSidebarForms = ({ type }: { type: WorkflowNodeTypes }) => {
  const reactFlowInstance = useReactFlow();
  const params = useParams();
  const workflowId = params.workflowId;
  const { workflowJson, selectedNode } = useWorkflowStore(
    useShallow(state => state),
  );
  const editWorkflowJson = useEditWorkflowJson();
  const saveWorkflowStateSpec = useSaveWorkflowStateSpec();

  const handleDeselect = () => {
    handleDeselectAndSave({
      workflowId: Number(workflowId),
      editWorkflowJson,
      workflowJson,
    });
  };

  return (
    <>
      <div className="flex justify-between">
        <WorkflowBackButton onClick={handleDeselect} />

        <button
          onClick={() => {
            handleDeleteSelectedNode({
              workflowJson,
              selectedNodeId: selectedNode.id,
              workflowId: Number(workflowId),
              editWorkflowJson,
              reactFlowInstance,
              saveWorkflowStateSpec,
            });
          }}
          className={cn(
            buttonVariants({ variant: 'outline', size: 'sm' }),
            'bg-red-500/85 hover:bg-red-500/75',
          )}
        >
          Delete
        </button>
      </div>

      {type === 'branch' ? (
        <WorkflowBranchForm />
      ) : type === 'delay' ? (
        <WorkflowDelayForm />
      ) : type === 'batch' ? (
        <WorkflowBatchForm />
      ) : type === 'updatePreference' ? (
        <WorkflowPreferencesForm />
      ) : type === 'triggerWorkflow' ? (
        <WorkflowTriggerForm />
      ) : type === 'waitForInput' ? (
        <WorkflowWaitForInputForm />
      ) : type === 'fetch' ? (
        <WorkflowFetchForm />
      ) : type === 'channel' ? (
        <WorkflowChannelForm />
      ) : type === 'defineVariable' ? (
        <WorkflowVariableForm />
      ) : type === 'editVariable' ? (
        <WorkflowVariableForm edit={true} />
      ) : null}
    </>
  );
};

export default WorkflowSidebarForms;
