import { useDisclosure } from '@chakra-ui/hooks';
import { useGetWorkflowJson } from 'api/workflows';
import {
  useGetWorkflowRuns,
  useGetWorkflowRunsDetails,
} from 'api/workflows-run';
import { Tabs, TabsList, TabsTrigger } from 'components/shadcn/tabs';
import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { TbRotateRectangle } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import { cn } from 'utils/class-merge';
import useCustomToast from 'utils/use-toast';
import { WorkflowOverViewDetails } from './components/Details';
import WorkflowCancelModal from './components/WorkflowCancelModal';
import { WorkflowRuns } from './components/WorkflowRun';
import { useGetWorkflowOverviewPageParams } from './hooks';

const WorkflowOverView = () => {
  const [workflowRunId, setWorkflowRunId] = React.useState<number>(null);
  const navigate = useNavigate();
  const { reset: resetWorkflowStates } = useWorkflowStore(state => state);
  const workflowCancelModalActions = useDisclosure();

  const {
    workflowId,
    currentView,
    workflowRunId: selectedWorkflowRunId,
  } = useGetWorkflowOverviewPageParams();

  const [view, setView] = React.useState(
    Number(currentView) === 0 ? 1 : Number(currentView),
  );
  const getWorkflowJson = useGetWorkflowJson(Number(workflowId));

  // used just for refetching
  const getWorkflowRuns = useGetWorkflowRuns(workflowId, false);
  // used just for refetching
  const getWorkflowRunsDetails = useGetWorkflowRunsDetails(
    workflowId,
    workflowRunId,
    false,
  );
  const [refetchLoading, setRefetchLoading] = React.useState(false);
  const toast = useCustomToast();

  const handleSwitch = (value: number) => {
    setView(value);
    navigate(`/admin/workflow-overview/${workflowId}?view=${value}`);
  };

  const handleRefetch = () => {
    setRefetchLoading(true);

    if (workflowRunId) {
      getWorkflowRunsDetails.refetch();
    } else {
      getWorkflowRuns.refetch();
    }

    setTimeout(() => {
      setRefetchLoading(false);
      toast.success('Latest Data Fetched Successfully');
    }, 1000);
  };

  React.useEffect(() => {
    if (currentView) {
      setView(Number(currentView));
    }
  }, [currentView]);

  React.useEffect(() => {
    if (selectedWorkflowRunId) {
      setWorkflowRunId(selectedWorkflowRunId);
    }
  }, [selectedWorkflowRunId]);

  // on leaving this page reset workflowStates
  React.useEffect(() => {
    return () => {
      resetWorkflowStates();
    };
  }, [resetWorkflowStates]);

  return (
    <>
      <WorkflowCancelModal
        isOpen={workflowCancelModalActions.isOpen}
        onClose={workflowCancelModalActions.onClose}
      />

      <Tabs defaultValue={String(view)} className="">
        <div className="font-semibold text-2xl mb-2 ml-2">
          {getWorkflowJson.data?.name}
        </div>

        <div className="my-4 flex w-full justify-between">
          <div className="relative flex gap-2 rounded-t-[20px] bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:!bg-gray-850 dark:text-white dark:shadow-none">
            <TabsList>
              <TabsTrigger onClick={() => handleSwitch(1)} value="1">
                Overview
              </TabsTrigger>
              <TabsTrigger onClick={() => handleSwitch(2)} value="2">
                Workflow Runs{' '}
              </TabsTrigger>
            </TabsList>
          </div>

          {view === 2 && (
            <div className="flex gap-2">
              <button
                disabled={refetchLoading}
                className={cn(
                  'bg-night-400 relative text-white border border-red-750 right-8 px-4 py-1 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed',
                  'disabled:opacity-50',
                  'bg-red-750 text-red-20 disabled:bg-red-750/60',
                )}
                onClick={() => {
                  workflowCancelModalActions.onOpen();
                }}
              >
                <IoMdClose />
                Cancel
              </button>

              <button
                disabled={refetchLoading}
                className={cn(
                  'bg-white relative text-night-400 border right-8 border-gray-440 px-4 py-1 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed',
                  'disabled:opacity-50',
                )}
                onClick={handleRefetch}
              >
                <TbRotateRectangle
                  className={cn(refetchLoading ? 'csm-spin' : '')}
                />
                Refresh
              </button>
            </div>
          )}
        </div>

        <div>
          {view === 1 && (
            <WorkflowOverViewDetails
              loading={getWorkflowJson.isLoading}
              data={getWorkflowJson.data}
            />
          )}

          {view === 2 && (
            <WorkflowRuns
              workflowRunId={workflowRunId}
              setWorkflowRunId={setWorkflowRunId}
              workflowId={workflowId}
            />
          )}
        </div>
      </Tabs>
    </>
  );
};

export default WorkflowOverView;
