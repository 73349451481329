import { GetTranslations } from 'api/translations/types';
import { create } from 'zustand';

interface State {
  translation: GetTranslations['results'][0];
  translationModalState: 'add' | 'edit';
}

interface Action {
  setTranslation: (translation: GetTranslations['results'][0]) => void;
  setTranslationModalState: (translationModalState: 'add' | 'edit') => void;
  reset: () => void;
}

const initialState: State = {
  translation: null,
  translationModalState: 'add',
};

export const useTranslationStore = create<State & Action>()(set => ({
  ...initialState,
  setTranslation: translation => set(() => ({ translation: translation })),
  setTranslationModalState: translationModalState =>
    set(() => ({
      translationModalState,
    })),
  reset: () => {
    set(initialState);
  },
}));
