import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

export type SaveProvider = {
  appId: number;
  data: {
    instanceName: string;
    providerIdentifier: string;
    config: {};
  };
  channel: string;
  type: 'post' | 'patch';
};

const action = async (values: SaveProvider) => {
  const { appId, channel, data, type } = values;

  const providerIdentifier = data.providerIdentifier;
  const createUrl = `${routes['apps']}/${appId}/channels/${channel}/providerInstances`;
  const editUrl = `${routes['apps']}/${appId}/channels/${channel}/providerInstances/${providerIdentifier}`;

  return axiosInstance({
    method: type,
    url: type === 'post' ? createUrl : editUrl,
    data: type === 'post' ? data : data['config'],
  });
};

const useSaveProvider = () => {
  return useMutation({
    mutationFn: (values: SaveProvider) => action(values),
  });
};

export default useSaveProvider;
