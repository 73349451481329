import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type TopicUser = {
  user_id: number;
  channels: string[];
};

type PutAddTopicsUsers = { users: TopicUser[] } & {
  topicId: string;
};

const post = async (values: PutAddTopicsUsers) => {
  const { topicId, ...data } = values;

  return axiosInstance.post(`${routes['topics']}/${topicId}/users`, data);
};

const usePostTopicsUsers = () => {
  return useMutation({
    mutationFn: post,
  });
};

export default usePostTopicsUsers;
