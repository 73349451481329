import toast, { ToastOptions } from 'react-hot-toast';
import { errorMessageParser } from './get-error';
import { ToastErrorIcon, ToastSuccessIcon } from 'components/toast';
import { CSSProperties } from 'react';

export type customToast = ReturnType<typeof useCustomToast>;

const commonStyles: CSSProperties = {
  color: '#fff',
  textTransform: 'capitalize',
  border: '1px solid #156347',
  padding: '12px 12px',
  minWidth: '288px',
  fontFamily: 'Inter',
  fontSize: '13px',
  display: 'flex',
  fontWeight: '500',
};

const useCustomToast = () => {
  const toastSuccess = (message: string, opts?: ToastOptions) => {
    return toast.success(message, {
      className: 'csm-toast',
      icon: ToastSuccessIcon,
      style: {
        ...commonStyles,
        border: '1px solid #156347',
        background:
          'linear-gradient(90deg, rgba(8,34,28,1) 10%, rgba(0,0,0,1) 100%)',
      },
      ...(opts && { ...opts }),
    });
  };

  const toastError = (message: string, opts?: ToastOptions) => {
    return toast.error(message, {
      className: 'csm-toast',
      icon: ToastErrorIcon,
      style: {
        ...commonStyles,
        border: '1px solid #9A142B',
        background:
          'linear-gradient(90deg, rgba(48,8,19,1) 10%, rgba(0,0,0,1) 100%)',
      },
      ...(opts && { ...opts }),
    });
  };

  const showToastWithError = (
    error: any,
    errorKey?: string,
    opts?: ToastOptions,
  ) => {
    const errorMessage = errorMessageParser(error, errorKey);
    return toastError(errorMessage, opts);
  };

  return {
    success: (message: string, opts?: ToastOptions) =>
      toastSuccess(message, opts),
    error: (message: string, opts?: ToastOptions) => toastError(message, opts),
    showError: (error: any, errorKey?: string, opts?: ToastOptions) =>
      showToastWithError(error, errorKey, opts),
  };
};

export default useCustomToast;
