import { copyToClipboard } from 'views/admin/dashboards/apiCredentials/functions';
import { TbClipboardText } from 'react-icons/tb';
import { IoMdCheckmark } from 'react-icons/io';
import { getNodeDimensions } from '../../layout';
import { WorkflowNodeTypes } from '../../types';
import React from 'react';
import { cn } from 'utils/class-merge';

export const WorkflowUniqueId = ({
  type,
  id,
  className,
  showCopyButton = true,
  idClassName,
}: {
  type: WorkflowNodeTypes;
  id: string;
  className?: string;
  idClassName?: string;
  showCopyButton?: boolean;
}) => {
  const [copied, setCopied] = React.useState(false);

  return (
    <div
      style={{
        width: getNodeDimensions(type).width,
      }}
      className={cn(
        `absolute text-[9px] top-[48px]  py-1 px-2 bg-[#16171C] -left-[1px] w-full gap-3 flex justify-between items-center  rounded-b border border-[#3A3F47]`,
        className,
      )}
    >
      <span className={cn(' text-[#48FFA7]', idClassName)}>{id}</span>

      {showCopyButton && (
        <button
          onClick={e => {
            e.stopPropagation();
            setCopied(true);
            copyToClipboard(id);
            setTimeout(() => {
              setCopied(false);
            }, 1000);
          }}
          className={`border flex gap-1 w-fit rounded px-1  border-[#3A3F47] items-center`}
          style={{
            color: copied ? '#48FFA7' : 'rgb(255 255 255 / 0.8)',
          }}
        >
          {copied ? <IoMdCheckmark /> : <TbClipboardText />}

          <span>{copied ? 'Copied' : 'Copy'}</span>
        </button>
      )}
    </div>
  );
};
