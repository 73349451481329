import { PlusCircledIcon } from '@radix-ui/react-icons';
import { Button } from 'components/shadcn/button';
import { ArrowUpRight } from 'lucide-react';
import React from 'react';
import { cn } from 'utils/class-merge';

export const getSearchEmptyHeading = (replaceWith: string) => {
  return `No ${replaceWith} match your search`;
};

export const getSearchEmptyMessage = (replaceWith: string) => {
  return `It appears we don't have ${replaceWith} matching your search term. Please try using different keywords or criteria.`;
};

const EmptyTableMessage = ({
  handleCreate,
  searchInput,
  docsLink,
  heading,
  message,
  searchEmptyHeading,
  searchEmptyMessage,
  Icon,
  primaryButtonText = 'Docs',
  secondaryButtonText = 'Create',
  hidePrimary = false,
  hideSecondary = false,
  messsageClassName,
  headingClassName,
}: {
  searchInput?: string;
  handleCreate?: () => void;
  docsLink?: string;
  heading: string;
  message: string;
  searchEmptyHeading?: string;
  searchEmptyMessage?: string;
  Icon: React.ComponentType<{ className: string }>;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  hidePrimary?: boolean;
  hideSecondary?: boolean;
  messsageClassName?: string;
  headingClassName?: string;
}) => {
  return (
    <>
      <div className="flex flex-col gap-4 justify-center">
        <Icon className={'w-8 h-8 mx-auto text-[#A1A1AA]'} />

        <h2
          className={cn(
            'text-2xl mb-1 font-semibold text-[#FAFAFA] tracking-tight',
            headingClassName,
          )}
        >
          {searchInput ? searchEmptyHeading ?? '' : heading}
        </h2>

        <p
          className={cn(
            'text-muted-foreground text-base max-w-[550px] mx-auto',
            messsageClassName,
          )}
        >
          {searchInput ? searchEmptyMessage ?? '' : message}
        </p>

        <div className="flex gap-4 justify-center mx-auto text-base">
          {!hidePrimary && (
            <Button className="border border-muted bg-background text-white hover:opacity-80 hover:bg-background">
              <a className="flex items-center" href={docsLink} target="blank">
                {primaryButtonText}
                <ArrowUpRight className="ml-2 h-4 w-4 text-[#A1A1AA]" />
              </a>
            </Button>
          )}

          {!hideSecondary && (
            <Button className="border border-muted" onClick={handleCreate}>
              <PlusCircledIcon className="mr-2 h-4 w-4" />
              {secondaryButtonText}
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default EmptyTableMessage;
