import { format } from 'date-fns';
import * as React from 'react';
import { DateRange, Matcher } from 'react-day-picker';
import { LuCalendar as CalendarIcon } from 'react-icons/lu';

import { Button } from 'components/shadcn/button';
import { Calendar } from 'components/shadcn/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';
import { cn } from 'utils/class-merge';

export function DatePickerWithRange({
  className,
  date,
  setDate,
  disabled,
}: React.HTMLAttributes<HTMLDivElement> & {
  date: DateRange;
  setDate: React.Dispatch<React.SetStateAction<DateRange>>;
  disabled?: Matcher | Matcher[];
}) {
  return (
    <div className={cn('grid gap-2', className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={'outline_hard'}
            className={cn(
              'w-[300px] justify-start text-left font-normal',
              !date && 'text-muted-foreground',
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, 'LLL dd, y')} -{' '}
                  {format(date.to, 'LLL dd, y')}
                </>
              ) : (
                format(date.from, 'LLL dd, y')
              )
            ) : (
              <span>Pick a date</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0 !bg-night-100" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={range => {
              setDate(range);
            }}
            numberOfMonths={2}
            disabled={disabled}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
