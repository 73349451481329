import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetAppSecret } from './types';
import { routes } from 'api/routes';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetAppSecret> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(`${routes['apps']}/${appId}/secret`);
  return data;
};

const useGetAppSecret = (
  appId: number,
  enabled = false,
  staleTime?: number,
) => {
  return useQuery({
    queryKey: [`${routes['apps']}/secret`, appId],
    queryFn: get,
    enabled,
    ...(staleTime && { staleTime }),
  });
};

export default useGetAppSecret;
