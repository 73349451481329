import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

export type SetProviderStatus = {
  appId: number;
  channel: string;
  providerIdentifier: string;
  type: 'enable' | 'disable';
};

const action = async (values: SetProviderStatus) => {
  const { appId, channel, providerIdentifier, type } = values;

  return axiosInstance({
    method: 'put',
    url: `${routes['apps']}/${appId}/channels/${channel}/providerInstances/${providerIdentifier}/${type}`,
  });
};

const useSetProviderStatus = () => {
  return useMutation({
    mutationFn: (values: SetProviderStatus) => action(values),
  });
};

export default useSetProviderStatus;
