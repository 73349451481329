import { useQueryClient } from '@tanstack/react-query';
import { GetClients } from 'api/client/get-client/types';
import usePatchClientUser from 'api/client/patch-client-user';
import { routes } from 'api/routes';
// import Edit from 'assets/svg/profile/edit.svg';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getFirstWordAndLetter } from 'utils/functions';
import useCustomToast from 'utils/use-toast';

export interface FormEditWorkspace {
  name: string;
}

function OrganisationModal({
  isOpen,
  onClose,
  selectedClient,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedClient: GetClients;
}) {
  const patchClientUser = usePatchClientUser();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { firstLetter } = getFirstWordAndLetter(selectedClient?.client?.name);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormEditWorkspace>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  React.useEffect(() => {
    reset({
      name: selectedClient?.client.name,
    });
  }, [selectedClient, reset, isOpen]);

  const handleClose = () => {
    reset({});
    onClose();
  };

  const onSubmit: SubmitHandler<FormEditWorkspace> = values => {
    const data = {
      clientId: selectedClient.client?.id,
      name: values.name,
    };

    patchClientUser.mutate(data, {
      onSuccess: () => {
        toast.success('workspace edited successfully');
        queryClient.invalidateQueries([routes['clients']]);
        handleClose();
      },
      onError: err => toast.showError(err),
    });
  };

  return (
    <CustomModal
      showCloseButton={false}
      modalContentClassName="!min-w-[345px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mx-auto mb-3 text-2xl font-bold">Edit Organisation</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="relative mx-auto my-4 mb-7 flex h-24 w-24 items-center justify-center rounded-full bg-white p-4 text-4xl font-bold text-night-100">
            {/* Image Upload code */}
            {/* <div className="absolute -bottom-1 -right-1">
                    <img width={30} src={Edit} alt="" />
                  </div> */}
            {/* <img width={50} src={dummyImg} alt="" /> */}

            {/* First Letter Display code */}
            {firstLetter}
          </div>

          <InputField<FormEditWorkspace>
            variant="modal"
            extra="mb-3"
            label={''}
            placeholder=""
            type="text"
            showIsRequiredAsterisk={false}
            register={register}
            name={'name'}
            extraLabelClass="!uppercase"
          />
          {errors.name && <ErrorBox error={errors.name} />}
        </>

        <CustomModalButtons
          handleClose={handleClose}
          loading={patchClientUser.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default OrganisationModal;
