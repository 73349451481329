import {
  BookOpen,
  Boxes,
  Cog,
  Edit,
  FileText,
  Flower,
  Folder,
  GitCompare,
  Key,
  Languages,
  LayoutPanelTop,
  LayoutTemplate,
  MessageCircle,
  Receipt,
  Send,
  SendToBack,
  Settings,
  SquareTerminal,
  Tag,
  UserCheck,
  Rocket,
} from 'lucide-react';
import { RxDiscordLogo } from 'react-icons/rx';

import { SidebarProps } from 'components/sidebar/components/SideBarNavigation';
import { isEnterpriseEdition } from 'utils/get-editions';

const sidebarWorkSpaceRoutes = [
  {
    name: 'My Team',
    path: '/admin/settings/teams',
    icon: Boxes,
  },
  {
    name: isEnterpriseEdition() ? 'Usage & Billing' : 'Usage',
    path: '/admin/settings/billing',
    icon: Receipt,
  },
  {
    name: 'Environments',
    path: 'settings/app',
    icon: Cog,
  },
  {
    name: 'CLI Tokens',
    path: 'settings/cli-tokens',
    icon: SquareTerminal,
  },
];

const composeRoutes = [
  {
    name: 'Send',
    path: '/admin/compose/send',
    icon: Edit,
  },
  {
    name: 'Scheduled',
    path: '/admin/compose/scheduled',
    icon: SendToBack,
  },
];

export const sidebarRoutes: SidebarProps['links'] = [
  {
    name: 'Quick Start',
    path: '/admin/get-started',
    icon: Rocket,
  },
  {
    name: 'API Credentials',
    path: '/admin/api-credentials',
    icon: Key,
  },
  {
    name: 'Users (Recipients)',
    path: '/admin/users',
    icon: UserCheck,
  },
  {
    name: 'Notifications',
    path: '/admin/compose',
    icon: Send,
    children: composeRoutes,
  },
  {
    name: 'Workflows',
    path: '/admin/workflow',
    icon: Flower,
    isBeta: true,
  },
  {
    name: 'Email Layouts',
    path: '/admin/layout',
    icon: LayoutTemplate,
  },
  {
    name: 'Version Control',
    path: '/admin/version-control',
    icon: GitCompare,
  },
  {
    name: 'Templates',
    path: '/admin/template',
    icon: LayoutPanelTop,
  },
  {
    name: 'Logs',
    path: '/admin/logs',
    icon: FileText,
  },
  // {
  //   name: 'Analytics',
  //   path: '/admin/analytics',
  //   icon: BarChart2,
  // },
  {
    name: 'Channels',
    path: '/admin/channels',
    icon: MessageCircle,
  },
  {
    name: 'Categories',
    path: '/admin/categories',
    icon: Folder,
  },
  {
    name: 'Topics',
    path: '/admin/topics',
    icon: Tag,
  },
  {
    name: 'Translations',
    path: '/admin/translation',
    icon: Languages,
  },
  {
    name: 'Workspace Settings',
    path: '/admin/settings',
    children: sidebarWorkSpaceRoutes,
    icon: Settings,
  },
  {
    name: 'Docs',
    path: 'https://docs.engagespot.com',
    externalLink: true,
    icon: BookOpen,
  },
  {
    name: 'Get Support',
    path: 'https://docs.engagespot.co/docs/get-support',
    externalLink: true,
    icon: RxDiscordLogo as any,
  },
];

export { sidebarWorkSpaceRoutes };
