import { getDraggableNodeDimensions } from 'views/admin/dashboards/workflowEditor/layout';
import { useState } from 'react';
import { EdgeProps, getSmoothStepPath } from 'reactflow';

import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import { EdgeData } from '../../types';

export default function WorkflowEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  target,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
  data,
}: EdgeProps<EdgeData>) {
  const { draggedType, reOrderedNode, showAllNodeRefs } = useWorkflowStore(
    useShallow(state => state),
  );
  const isDragging =
    !!draggedType &&
    // ignore current source and target edges
    !(reOrderedNode?.id === source || reOrderedNode?.id === target) &&
    // for branches, ignore all child edges
    !(reOrderedNode?.id && reOrderedNode?.id === data?.parentId);

  const [isDropzoneActive, setDropzoneActive] = useState<boolean>(false);

  const onDrop = () => {
    setDropzoneActive(false);
  };

  const onDragEnter = () => {
    isDragging && setDropzoneActive(true);
  };

  const onDragLeave = () => {
    setDropzoneActive(false);
  };

  const [edgePath, edgeCenterX, edgeCenterY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const isPlusVisible = isDragging && !isDropzoneActive;
  const { width, height } = getDraggableNodeDimensions();
  /**
   * since showing unique node refs, node height increaes
   * and + plus icon will not be visible,
   * so we need to update position manually
   */
  const ignoreEdgeHeightUpdate =
    !showAllNodeRefs ||
    source.includes('subBranch') ||
    source.includes('end') ||
    source.includes('empty');
  const edgeHeightUpdate = ignoreEdgeHeightUpdate ? 0 : 10;

  return (
    <>
      <path
        id={id}
        style={style}
        className={'workflow_edge-path'}
        d={edgePath}
        markerEnd={markerEnd}
      />
      <g
        transform={`translate(${edgeCenterX}, ${edgeCenterY + edgeHeightUpdate})`}
      >
        <line
          x1={-width / 2}
          y1={-height / 2}
          x2={width - width / 2}
          y2={height / 2}
          strokeWidth={width / 10}
          onDrop={onDrop}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          data-source={source}
          data-target={target}
        />
        <rect
          x={-width / 2}
          y={-height / 2}
          width={width}
          ry={4}
          rx={4}
          height={height}
          className={'workflow_dropzone'}
          opacity={isDropzoneActive ? 1 : 0}
        />
        <g
          transform={`${source.startsWith('sudbBranch') ? 'translate(50, -20)' : ''}`}
        >
          <rect
            x={-7.5}
            y={-7.5}
            width={15}
            ry={999}
            rx={999}
            height={15}
            className={'workflow_add-plus-container'}
            display={isPlusVisible ? undefined : 'none'}
          />
          <text
            className={'workflow_add-plus'}
            y={5}
            x={-4}
            display={isPlusVisible ? undefined : 'none'}
          >
            +
          </text>
        </g>
      </g>
    </>
  );
}
