import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PutDuplicateTemplates = {
  templateId: number;
  appId?: string;
  name: string;
  identifier?: string;
};

const put = async (values: PutDuplicateTemplates) => {
  const { templateId, appId, name, identifier } = values;

  const data = {
    ...(appId && { appId: Number(appId) }),
    name,
    identifier,
  };

  return axiosInstance.put(
    `${routes['templates']}/${templateId}/duplicate`,
    data,
  );
};

const usePutDuplicateTemplates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: put,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/templates`]);
    },
  });
};

export default usePutDuplicateTemplates;
