import { Tabs, TabsList, TabsTrigger } from 'components/shadcn/tabs';
import { Row } from '@tanstack/react-table';
import { UnCommitedChanges } from 'api/commits/types';
import { useGetTranslationDetails } from 'api/translations';
import { useGetVersionedWorkflowJson, useGetWorkflowJson } from 'api/workflows';
import CustomDiffViewer from 'components/diff-viewer';
import { CustomModal } from 'components/modal';
import { buttonVariants } from 'components/shadcn/button';
import { Pencil, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils/class-merge';
import { stringifyJson } from 'utils/check-diff';
import { useGetLayoutDetails } from 'api/layouts';
import CustomEmailPreview from 'components/email-preview';
import React from 'react';

function ChangesDiffModal({
  isOpen,
  onClose,
  selectedRows,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedRows: Row<UnCommitedChanges>[];
}) {
  const navigate = useNavigate();
  const selectedData = selectedRows?.[0]?.original;
  const selectedEntityType = selectedData?.entityType;

  // layout json
  const getLayoutDetails = useGetLayoutDetails(
    Number(selectedData?.entityId),
    selectedEntityType === 'layout' ? true : false,
  );

  // translation json
  const getTranslationDetails = useGetTranslationDetails(
    Number(selectedData?.entityId),
    selectedEntityType === 'translation' ? true : false,
  );

  // workflow json
  const getWorkflowJson = useGetWorkflowJson(
    Number(selectedData?.entityId),
    selectedEntityType === 'workflow' ? true : false,
  );
  const versions = getWorkflowJson.data?.versions;
  const versionLength = versions?.length;
  const lastPublishedVersionId = versions?.[versionLength - 1]?.id;
  const getVersionedWorkflowJson = useGetVersionedWorkflowJson(
    Number(selectedData?.entityId),
    lastPublishedVersionId,
    selectedEntityType === 'workflow' ? true : false,
  );
  const isFirstVersion = versionLength === 0;

  const { oldJson, newJson } = stringifyJson({
    oldValue:
      selectedEntityType === 'workflow'
        ? isFirstVersion
          ? []
          : getVersionedWorkflowJson.data
        : getTranslationDetails.data?.publishedJsonSpec,
    newValue:
      selectedEntityType === 'workflow'
        ? getWorkflowJson.data?.jsonSpec
        : getTranslationDetails.data?.jsonSpec,
  });

  const handleEdit = () => {
    const editorRoutes = {
      workflow: `/admin/workflow-editor/${selectedData?.entityId}`,
      translation: `/admin/translation-editor/${selectedData?.entityId}`,
      layout: `/admin/layout-editor/${selectedData?.entityId}`,
    };

    const route = editorRoutes[selectedData?.entityType] || '';

    if (route) {
      navigate(route);
    }

    onClose();
  };

  const [tableView, setTableView] = React.useState(1);

  const handleSwitch = (value: number) => {
    setTableView(value);
  };

  return (
    <CustomModal
      modalContentClassName="min-w-[92vw] h-[92vh] top-[8%] outline-none"
      modalCardClassName={'max-h-[92vh] h-[92vh] px-0 py-0'}
      overlayClassName="outline-none"
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={false}
      modalOverlayClick={onClose}
    >
      <div className="flex flex-col border-b mb-6  border-gray-880 px-8 py-6">
        <div className="flex justify-between  items-center ">
          <h1 className="text-lg">
            Showing <span className="font-semibold">{selectedData?.name}</span>{' '}
            Changes
          </h1>

          <div className="flex gap-2">
            <button
              onClick={handleEdit}
              className={cn(
                buttonVariants({
                  variant: 'default',
                }),
                'bg-background hover:bg-background/90 text-white outline outline-input',
              )}
            >
              Edit
              <Pencil className="w-4 h-4 ml-2 relative bottom-[1px] text-white" />
            </button>

            <button
              onClick={onClose}
              className={buttonVariants({
                variant: 'default',
              })}
            >
              Close
              <X onClick={onClose} className="w-4 h-4 ml-2" />
            </button>
          </div>
        </div>
      </div>

      <div className="h-[83%] csm-react-diff overflow-auto rounded-lg mx-8 border border-gray-880">
        {selectedEntityType === 'layout' && (
          <div className="flex flex-col h-full">
            <Tabs
              value={String(tableView)}
              defaultValue={String(tableView)}
              className=""
            >
              <TabsList>
                <TabsTrigger onClick={() => handleSwitch(1)} value="1">
                  Current Change
                </TabsTrigger>

                <TabsTrigger onClick={() => handleSwitch(2)} value="2">
                  Published Change
                </TabsTrigger>
              </TabsList>
            </Tabs>

            <CustomEmailPreview
              bodyJsonTemplate={
                tableView === 1
                  ? getLayoutDetails.data?.jsonSpec?.bodyJsonTemplate
                  : getLayoutDetails.data?.publishedJsonSpec?.bodyJsonTemplate
              }
            />
          </div>
        )}

        {selectedEntityType !== 'layout' && (
          <CustomDiffViewer oldValue={oldJson} newValue={newJson} />
        )}
      </div>
    </CustomModal>
  );
}

export default ChangesDiffModal;
