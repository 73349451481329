import React from 'react';
import { useOutsideAlerter } from 'utils/use-outside-alerter';

const Dropdown = (props: {
  button: JSX.Element;
  children: JSX.Element;
  classNames: string;
  animation?: string;
  buttonClassNames?: string;
  openWrapper: boolean;
  setOpenWrapper: React.Dispatch<React.SetStateAction<boolean>>;
  useOutsideAlerterEnabled?: boolean;
}) => {
  const {
    button,
    children,
    classNames,
    animation,
    buttonClassNames,
    openWrapper,
    setOpenWrapper,
    useOutsideAlerterEnabled = true,
  } = props;
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, setOpenWrapper, useOutsideAlerterEnabled);

  return (
    <div ref={wrapperRef} className="relative flex">
      <div
        className={`flex ${buttonClassNames}`}
        onMouseDown={() => setOpenWrapper(!openWrapper)}
      >
        {button}
      </div>
      <div
        className={`${classNames} absolute z-10 ${
          animation
            ? animation
            : 'origin-top-right transition-all duration-300 ease-in-out'
        } ${openWrapper ? 'scale-100' : 'scale-0'}`}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
