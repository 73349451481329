import { useMutation } from '@tanstack/react-query';
import { routes } from 'api/routes';
import Cookies from 'js-cookie';
import { useAuthStore } from '../../store/authStore';
import { axiosGlobalInstance } from 'api/axios/global';

const post = async <T>(values: T) => {
  return axiosGlobalInstance.post(routes['signin-email'], values);
};

const usePostSignInEmail = <T>() => {
  const { reset } = useAuthStore(state => state);

  return useMutation({
    mutationFn: post<T>,
    onSuccess: response => {
      reset();
      const token = response.data.token;
      Cookies.set('access_token', token);
    },
  });
};

export default usePostSignInEmail;
