import { useDisclosure } from '@chakra-ui/hooks';
import { GetLayouts } from 'api/layouts/types';
import CopyCell from 'components/copy/CopyCell';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { Languages } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useLayoutStore } from 'store/layoutStore';
import { cn } from 'utils/class-merge';
import { useAppLevel } from 'utils/use-app-level';
import {
  findPublishStatus,
  PublishStatusBadge,
} from '../../commits/components/PublishStatusBadge';
import DefaultModal from './DefaultModal';
import DeleteModal from './DeleteModal';

type Actions =
  | 'edit_workflows'
  | 'delete_workflows'
  | 'view_workflows'
  | 'make_default';

function LayoutTable(props: {
  tableData: any;
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  const { tableData, editOpen: onOpen, globalFilter, loading } = props;
  const deleteModalActions = useDisclosure();
  const toggleModalActions = useDisclosure();
  const { setLayout, setLayoutModalState } = useLayoutStore(state => state);
  const navigate = useNavigate();
  const { isDefaultApp } = useAppLevel();

  const handleCreate = () => {
    setLayout(null);
    setLayoutModalState('add');
    onOpen();
  };

  const handleMenuClick = (selected: GetLayouts[0], actionType: Actions) => {
    setLayout(selected);

    if (actionType === 'view_workflows') {
      return navigate(`/admin/layout-editor/${selected.id}`);
    }

    if (actionType === 'delete_workflows') {
      return deleteModalActions.onOpen();
    }

    if (actionType === 'edit_workflows') {
      setLayoutModalState('edit');
    }

    if (actionType === 'make_default') {
      return toggleModalActions.onOpen();
    }

    onOpen();
  };

  const columnConfig: ColumnConfig<GetLayouts[0]> = [
    {
      accessorKey: 'identifier',
      header: 'Identifier',
      cellFunction: info => {
        const identifier = info.getValue();
        return <CopyCell value={identifier} />;
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => {
        const isDefault = Boolean(info.row.original?.isDefault);

        return (
          <div className="flex gap-2 items-center">
            {info.getValue()}

            {isDefault && (
              <p
                className={`w-min text-xs rounded-lg bg-green-750 text-green-20 px-2 py-1 flex justify-center items-center`}
              >
                Default
              </p>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'clientUser',
      header: 'Saved By',
      cellFunction: info => {
        const clientUser = info.getValue();
        const firstName = clientUser?.firstName;
        const profilePicture = clientUser?.profilePicture;

        return (
          <div className={cn('flex gap-2 items-center')}>
            <span>
              <Avatar className="h-6 w-6">
                <AvatarImage src={profilePicture} alt={firstName} />
                <AvatarFallback className="text-white text-xs">
                  {firstName?.charAt(0).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </span>
            <span>{firstName}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'isDefault',
      header: 'Publish Status',
      cellFunction: info => {
        const item = info.row.original;
        const publishStatus = findPublishStatus({
          hasPublishedJsonSpec: Boolean(item?.publishedJsonSpec),
          hasUnpublishedChanges: Boolean(item.hasUnpublishedChanges),
        });

        return <PublishStatusBadge publishStatus={publishStatus} />;
      },
    },
    {
      accessorKey: 'clientId',
      cellFunction: info => {
        const item = info.row.original;
        const isDefault = Boolean(info.row.original?.isDefault);
        const publishStatus = findPublishStatus({
          hasPublishedJsonSpec: Boolean(item?.publishedJsonSpec),
          hasUnpublishedChanges: Boolean(item.hasUnpublishedChanges),
        });

        return (
          <TableMenu
            config={[
              ...(isDefaultApp
                ? [
                    {
                      text: 'View',
                      action: () =>
                        handleMenuClick(info.row.original, 'view_workflows'),
                    },
                    {
                      text: 'Edit',
                      action: () =>
                        handleMenuClick(info.row.original, 'edit_workflows'),
                    },
                    {
                      text: 'Delete',
                      action: () =>
                        handleMenuClick(info.row.original, 'delete_workflows'),
                    },
                    ...(!isDefault && publishStatus !== 0
                      ? [
                          {
                            text: 'Make Default',
                            action: () =>
                              handleMenuClick(
                                info.row.original,
                                'make_default',
                              ),
                          },
                        ]
                      : []),
                  ]
                : [
                    {
                      text: 'View',
                      action: () =>
                        handleMenuClick(info.row.original, 'view_workflows'),
                    },
                  ]),
            ]}
          />
        );
      },
    },
  ];

  const columns = columnGenerator({ columnConfig });

  return (
    <>
      <DeleteModal
        isOpen={deleteModalActions.isOpen}
        onClose={deleteModalActions.onClose}
      />

      <DefaultModal
        isOpen={toggleModalActions.isOpen}
        onClose={toggleModalActions.onClose}
      />

      {/* table */}
      <CustomTable
        loading={loading}
        globalFilter={globalFilter}
        columns={columns}
        tableData={tableData}
        rowClick={row => {
          const selected = row.original;
          setLayout(null);
          handleMenuClick(selected, 'view_workflows');
        }}
        extraTdClass={'hover:cursor-pointer'}
        emptyTableMessage="It looks like you haven't set up any workflow yet. Kickstart your automation journey by creating your first workflow now!"
        paginationType="table"
        emptyAdditionalJsx={() => (
          <EmptyTableMessage
            handleCreate={() => {
              handleCreate();
            }}
            searchInput={''}
            docsLink={
              'https://docs.engagespot.co/docs/features/layouts/overview'
            }
            heading={"You haven't created any layouts yet"}
            message={
              'Layouts are key to global reach. Support multiple languages and ensure your content connects. Create your first layout now.'
            }
            searchEmptyHeading={getSearchEmptyHeading('notifications')}
            searchEmptyMessage={getSearchEmptyMessage('notifications')}
            hideSecondary={!isDefaultApp}
            Icon={Languages}
          />
        )}
      />
    </>
  );
}

export default LayoutTable;
