import {
  CodeOutlined,
  DataObjectOutlined,
  MonitorOutlined,
  PhoneIphoneOutlined,
} from '@mui/icons-material';
import {
  Box,
  Stack,
  SxProps,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import { Reader } from '@usewaypoint/email-builder';

import EditorBlock from '../../documents/editor/EditorBlock';
import {
  setSelectedMainTab,
  setSelectedScreenSize,
  useDocument,
  useSelectedMainTab,
  useSelectedScreenSize,
} from '../../documents/editor/EditorContext';
import ToggleInspectorPanelButton from '../InspectorDrawer/ToggleInspectorPanelButton';
import ToggleSamplesPanelButton from '../SamplesDrawer/ToggleSamplesPanelButton';

import { TEditorConfiguration } from 'email-editor/documents/editor/core';
import { useLocation } from 'react-router-dom';
import { getDocumentWithLayout } from 'views/admin/dashboards/templateEditor/functions';
import { useExtractEmailLayouts } from 'views/admin/dashboards/templateEditor/hooks/getEmailLayout';
import HtmlPanel from './HtmlPanel';
import JsonPanel from './JsonPanel';

export default function TemplatePanel({ preview }: { preview: boolean }) {
  const location = useLocation();
  const document = useDocument();
  const selectedDocumentLayout = useExtractEmailLayouts();
  const selectedMainTab = useSelectedMainTab();
  const selectedScreenSize = useSelectedScreenSize();
  const isLayoutEditorPage = location.pathname.includes('layout-editor');

  let mainBoxSx: SxProps = {
    height: '100%',
  };
  if (selectedScreenSize === 'mobile') {
    mainBoxSx = {
      ...mainBoxSx,
      margin: '32px auto',
      width: 370,
      height: 800,
      boxShadow:
        'rgba(33, 36, 67, 0.04) 0px 10px 20px, rgba(33, 36, 67, 0.04) 0px 2px 6px, rgba(33, 36, 67, 0.04) 0px 0px 1px',
    };
  }

  const handleScreenSizeChange = (_: unknown, value: unknown) => {
    switch (value) {
      case 'mobile':
      case 'desktop':
        setSelectedScreenSize(value);
        return;
      default:
        setSelectedScreenSize('desktop');
    }
  };

  const renderMainPanel = () => {
    switch (selectedMainTab) {
      case 'editor':
        return (
          <Box sx={mainBoxSx}>
            <EditorBlock id="root" />
          </Box>
        );
      case 'preview':
        const documentLayout = selectedDocumentLayout as TEditorConfiguration;

        // fallback
        if (!documentLayout || isLayoutEditorPage) {
          return (
            <Box sx={mainBoxSx}>
              <Reader document={document as any} rootBlockId="root" />
            </Box>
          );
        }

        const documentWithLayout = getDocumentWithLayout({
          document,
          documentLayout,
        });

        return (
          <Box sx={mainBoxSx}>
            <Reader document={documentWithLayout as any} rootBlockId="root" />
          </Box>
        );
      case 'html':
        return <HtmlPanel />;
      case 'json':
        return <JsonPanel />;
    }
  };

  const handleChange = (_: unknown, v: unknown) => {
    switch (v) {
      case 'json':
      case 'preview':
      case 'editor':
      case 'html':
        setSelectedMainTab(v);
        return;
      default:
        setSelectedMainTab('editor');
    }
  };

  return (
    <>
      {!preview && (
        <Stack
          sx={{
            height: 49,
            borderBottom: 1,
            borderColor: 'divider',
            backgroundColor: 'white',
            position: 'sticky',
            top: 0,
            zIndex: 'appBar',
            px: 1,
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <ToggleSamplesPanelButton />
          <Stack
            px={2}
            direction="row"
            gap={2}
            width="100%"
            justifyContent="space-between"
            alignItems="center"
          >
            {/* <Stack direction="row" spacing={2}>
            <MainTabsGroup />
          </Stack> */}
            <Stack direction="row" spacing={2}>
              <ToggleButtonGroup
                value={selectedMainTab}
                exclusive
                size="small"
                onChange={handleChange}
              >
                <ToggleButton value="html">
                  <Tooltip title="HTML output">
                    <CodeOutlined fontSize="small" />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="json">
                  <Tooltip title="JSON output">
                    <DataObjectOutlined fontSize="small" />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>

            <Stack direction="row" spacing={2}>
              <ToggleButtonGroup
                value={selectedMainTab}
                exclusive
                size="small"
                onChange={handleChange}
              >
                <ToggleButton value="editor">
                  <Tooltip title="Editor">
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {/* Editor <EditOutlined fontSize="small" /> */}
                      Editor
                    </Box>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="preview">
                  <Tooltip
                    title={
                      isLayoutEditorPage ? 'Preview' : 'Preview with layout'
                    }
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      {/* Preview <PreviewOutlined fontSize="small" /> */}
                      Preview
                    </Box>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>

            <Stack direction="row" spacing={2}>
              {/* <DownloadJson /> */}
              {/* <ImportJson /> */}
              <ToggleButtonGroup
                value={selectedScreenSize}
                exclusive
                size="small"
                onChange={handleScreenSizeChange}
              >
                <ToggleButton value="desktop">
                  <Tooltip title="Desktop view">
                    <MonitorOutlined fontSize="small" />
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value="mobile">
                  <Tooltip title="Mobile view">
                    <PhoneIphoneOutlined fontSize="small" />
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              {/* <ShareButton /> */}
            </Stack>
          </Stack>
          <ToggleInspectorPanelButton />
        </Stack>
      )}

      <Box
        sx={{
          // height: preview
          //   ? isLayoutEditorPage
          //     ? 'calc(66.4vh)' // since commit message input, height is reduced
          //     : 'calc(71.4vh)'
          //   : isLayoutEditorPage
          //     ? 'calc(87vh)'
          //     : // : 'calc(92vh - 150px)',
          //       '100%',
          height: '100%',
          overflow: 'auto',
          minWidth: 370,
        }}
      >
        {renderMainPanel()}
      </Box>
    </>
  );
}
