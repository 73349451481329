import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { SubscriptionDetails } from './types';
import { routes } from 'api/routes';

export type GetPlansParams = {
  billableComponent: 'notification';
};

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<SubscriptionDetails> => {
  const [, clientId] = queryKey;

  const { data } = await axiosLicensingInstance.get(
    `${routes['client']}/${clientId}/subscription`,
  );

  return data;
};

const useGetSubscriptionDetails = (clientId: string, enabled: boolean) => {
  return useQuery({
    queryKey: [`${routes['client']}/subscription`, clientId],
    queryFn: get,
    enabled: Boolean(clientId) && enabled,
    staleTime: 5 * 1000, // 5 seconds,
  });
};

export default useGetSubscriptionDetails;
