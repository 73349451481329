import { TooltipProvider } from '@radix-ui/react-tooltip';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/shadcn/accordian';
import { buttonVariants } from 'components/shadcn/button';
import { LucideIcon } from 'lucide-react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { cn } from 'utils/class-merge';
import { useIsOldVersion } from 'utils/use-old-version';

export interface SidebarProps {
  links: {
    name: string;
    icon: LucideIcon;
    path: string;
    label?: string;
    variant?: 'default' | 'ghost';
    children?: SidebarProps['links'][number][];
    isBeta?: boolean;
    externalLink?: boolean;
  }[];
}

export function SideBarNavigation({ links }: SidebarProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { isOldVersion, isLoading } = useIsOldVersion();

  const oldVersionRoutes = links
    .filter(link => link.path !== '/admin/version-control')
    .map(link => {
      const linkChildren = link.children?.map(linkChild => {
        if (linkChild.path === 'settings/app')
          return { ...linkChild, name: 'Apps' };
        return linkChild;
      });

      return { ...link, children: linkChildren ?? [] };
    });
  const newVersionRoutes = links.filter(
    link => link.path !== '/admin/template',
  );

  // for newer versions template page is not needed
  const filteredLinks = isOldVersion ? oldVersionRoutes : newVersionRoutes;

  const handleNavigate = (path: string, external?: boolean) => {
    if (external) {
      return window.open(path, '_blank').focus();
    }

    navigate(path);
  };

  // verifies if routeName is the one active (in browser input)
  const isActiveRoute = (routeName: string) => {
    return location.pathname.includes(routeName);
  };

  return (
    <TooltipProvider delayDuration={0}>
      <div className="group flex flex-col gap-4 py-[29px] data-[collapsed=true]:py-2">
        <nav className="grid gap-1 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
          {isLoading && (
            <div className="flex flex-col gap-3 opacity-55">
              {[...Array(12).keys()]?.map((link, index) => {
                return (
                  <div key={link} className="h-10 csm-pulse bg-muted/65"></div>
                );
              })}
            </div>
          )}

          {!isLoading &&
            filteredLinks?.map((link, index) =>
              link.children?.length > 0 ? (
                <React.Fragment key={link.name}>
                  <Accordion
                    type="single"
                    collapsible
                    className="space-y-2"
                    key={link.name}
                  >
                    <AccordionItem value={link.path} className="border-none">
                      <AccordionTrigger
                        className={cn(
                          buttonVariants({ variant: 'ghost' }),
                          'group relative flex h-12 justify-between px-4 py-2 text-sm duration-200 hover:bg-muted hover:no-underline',
                          'text-gray-10',
                          isActiveRoute(link.path) &&
                            'bg-muted/65 text-white font-bold hover:bg-muted',
                        )}
                      >
                        <div>
                          <link.icon className={cn('h-5 w-5')} />
                        </div>
                        <div
                          className={cn(
                            'absolute left-14 text-sm duration-200 ',
                          )}
                        >
                          {link.name}
                        </div>
                      </AccordionTrigger>

                      <AccordionContent className="mt-2 space-y-1 pb-1">
                        {link.children?.map(child => (
                          <button
                            key={child.name}
                            onClick={() => {
                              handleNavigate(child.path, child?.externalLink);
                            }}
                            className={cn(
                              buttonVariants({ variant: 'ghost', size: 'lg' }),
                              'group relative left-5 flex  justify-start gap-x-3 w-full',
                              'text-gray-10',
                              isActiveRoute(child.path) &&
                                'bg-muted font-bold hover:bg-muted text-white',
                              'csm-shake',
                            )}
                          >
                            <child.icon className={cn('h-5 w-5 mr-1')} />
                            <div className={cn('text-sm duration-200')}>
                              {child.name}
                            </div>
                          </button>
                        ))}
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                </React.Fragment>
              ) : (
                <button
                  key={index}
                  className={cn(
                    buttonVariants({ variant: 'ghost', size: 'lg' }),
                    'justify-start px-4 py-2 group',
                    'csm-shake',
                    'text-gray-10',
                    isActiveRoute(link.path) &&
                      'bg-muted font-bold text-white hover:bg-muted',
                  )}
                  onClick={() => {
                    handleNavigate(link.path, link?.externalLink);
                  }}
                >
                  <link.icon className="mr-5 h-5 w-5 csm-shake" />
                  {link.name}
                  {link.label && (
                    <span
                      className={cn(
                        'ml-auto',
                        link.variant === 'default' && 'text-gray-10',
                      )}
                    >
                      {link.label}
                    </span>
                  )}
                </button>
              ),
            )}
        </nav>
      </div>
    </TooltipProvider>
  );
}
