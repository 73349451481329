import { useQueryClient } from '@tanstack/react-query';
import { Get2Fa } from 'api/2fa/get-2fa/types';
import usePatch2Fa from 'api/2fa/patch-2fa';
import { routes } from 'api/routes';
import Card from 'components/card';
import Dash from 'components/dash';
import ErrorBox from 'components/error/ErrorBox';
import { CustomModalButtons } from 'components/modal';
import { Otp } from 'components/otp/Otp';
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { errorMessageParser } from 'utils/get-error';
import useCustomToast from 'utils/use-toast';
import { QrCodeModalType } from '../types';
import { authenticatorLinks } from '../variables';

const ScanQrCode = ({
  onClose,
  data,
  setModalType,
  setRecoveryCodes,
}: {
  onClose: () => void;
  data: Get2Fa;
  setModalType: React.Dispatch<React.SetStateAction<QrCodeModalType>>;
  setRecoveryCodes: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const toast = useCustomToast();
  const qrCodeValue = data?.freeText;
  const [oneTimePassword, setOneTimePassword] = React.useState('');
  const [error, setError] = React.useState('');
  const patch2Fa = usePatch2Fa();
  const queryClient = useQueryClient();

  const { handleSubmit, reset } = useForm({
    reValidateMode: 'onChange',
  });

  const handleClose = () => {
    reset({});
    onClose();
  };

  const onSubmit = () => {
    if (oneTimePassword.length !== 6) {
      setError('6 digits is required');
      return;
    }

    const data = {
      code: Number(oneTimePassword),
      type: 'enable' as const,
    };

    patch2Fa.mutate(data, {
      onSuccess: response => {
        toast.success(response.data.message);
        queryClient.invalidateQueries([routes['me']]);
        setRecoveryCodes(response.data.recoveryCodes);
        setModalType('recovery');
      },
      onError: err => {
        const errMsg = errorMessageParser(err);
        setError(errMsg);
      },
    });
  };

  return (
    <Card extra="py-6 px-6 max-w-[650px] max-h-[600px] overflow-scroll flex flex-col !z-[1004] dark:!bg-night-100  border-2 border-[#26292F]">
      <div className="mb-4 w-full px-0">
        <h1 className="mb-2 text-center text-2xl font-semibold">
          Scan QR code
        </h1>
        <p className="mb-4 text-center text-xs font-semibold text-[#BFBFBF]">
          You need{' '}
          <a
            className="font-bold underline"
            target="_blank"
            rel="noreferrer"
            href={authenticatorLinks['google']}
          >
            google authenticator,
          </a>{' '}
          <a
            className="font-bold underline"
            target="_blank"
            rel="noreferrer"
            href={authenticatorLinks['microsoft']}
          >
            microsoft authenticator,
          </a>{' '}
          etc to complete the process
        </p>
        <p className="mb-6 text-center font-medium">
          Scan the Qr Code into your app
        </p>

        <div className="flex  w-full justify-center">
          <div className="flex items-center justify-center rounded-lg bg-white p-2">
            <QRCodeSVG value={qrCodeValue} />
          </div>
        </div>
      </div>

      <div className="mb-3">
        <Dash />
      </div>

      <form className="px-0" onSubmit={handleSubmit(onSubmit)}>
        <p className="text-center font-medium">
          Enter the 6-digit authenticator code generated by the app:
        </p>

        {/* verification */}
        <div className="mb-6 mt-6 flex w-full items-center justify-center">
          <Otp setValue={setOneTimePassword} length={6} />
        </div>

        {error && <ErrorBox error={error} />}

        <CustomModalButtons
          handleClose={handleClose}
          loading={patch2Fa.isLoading}
          primaryButtonText="Done"
          containerClassName="!mt-5"
        />
      </form>
    </Card>
  );
};

export default ScanQrCode;
