import { yupResolver } from '@hookform/resolvers/yup';
import { useGetWorkflows } from 'api/workflows';
import ErrorBox from 'components/error/ErrorBox';
import SelectField from 'components/fields/SelectField';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import { findSelectedNodeFromJson, handleJsonDiffCheck } from '../../functions';
import {
  useFormValidateOnLoad,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { WorkflowFormHeader } from './WorkflowFormHeader';

type FormWorkflowTrigger = {
  workflowIdentifier?: string;
  triggerCondition?: string;
};

const schema = yup.object({
  workflowIdentifier: yup.string().required('Selecting a workflow is required'),
});

const WorkflowTriggerForm = () => {
  const params = useParams();
  const workflowId = params.workflowId;
  const getWorkflows = useGetWorkflows();
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const workflowOptions = getWorkflows?.data
    ?.filter(workflow => workflow.id !== Number(workflowId))
    ?.map(item => ({
      label: item.name,
      value: item.identifier,
    }));

  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );

  const formMethods = useForm<FormWorkflowTrigger>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const {
    reset,
    getValues,
    control,
    formState: { errors },
    trigger,
  } = formMethods;

  const handleChange = () => {
    const { triggerCondition, workflowIdentifier } = getValues();
    const selectedNodeId = selectedNode?.id;
    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      selected.triggerCondition = triggerCondition;
      selected.config = {
        ...selected.config,
        workflowIdentifier,
      };
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode?.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });
    const selectedConfig = selectedNodeJson?.config;
    const workflowIdentifier = selectedConfig?.workflowIdentifier;

    reset({
      ...selectedConfig,
      workflowIdentifier: Boolean(workflowIdentifier)
        ? workflowIdentifier
        : undefined,
      triggerCondition: selectedNodeJson?.triggerCondition,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useFormValidateOnLoad({
    dataLoaded,
    trigger,
  });

  useWatchWorkflowFormErrors({
    control,
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });
  return (
    <div>
      <WorkflowFormHeader
        heading="Trigger Workflow"
        docKey="trigger_workflow"
      />

      <div className="text-[#ABB0B8] font-medium mb-3">
        Trigger a previously created workflow
      </div>

      <FormProvider {...formMethods}>
        <form className="mt-6 flex flex-col gap-6">
          <div>
            <SelectField<FormWorkflowTrigger>
              variant="styled"
              control={control}
              extra="w-full"
              label={'Trigger Workflow'}
              placeholder={'Select Workflow'}
              extraInputClass="capitalize"
              name={'workflowIdentifier'}
              styleVariant="workflow"
              extraItemClass="capitalize"
              options={workflowOptions ?? []}
            />
            {errors.workflowIdentifier && (
              <ErrorBox error={errors.workflowIdentifier} />
            )}
          </div>

          <WorkflowConditionForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default WorkflowTriggerForm;
