import { Elements, useStripe } from '@stripe/react-stripe-js';
import { useEffect } from 'react';
import { isEnterpriseEdition } from 'utils/get-editions';
import useCustomToast from 'utils/use-toast';
import { StripePromise } from '../types';

const PaymentCheck = (): null => {
  const stripe = useStripe();
  const toast = useCustomToast();

  useEffect(() => {
    const retrieveSetupIntent = () => {
      if (!stripe) {
        return;
      }

      // Retrieve the "setup_intent_client_secret" query parameter appended to
      // your return_url by Stripe.js
      const clientSecret = new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret',
      );

      if (!clientSecret) return;

      // Retrieve the SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification

        switch (setupIntent.status) {
          case 'succeeded':
            toast.success('Success! Your payment method has been saved.', {
              duration: 4000,
            });
            break;

          case 'processing':
            toast.success(
              "Processing payment details. We'll update you when processing is complete.",
              {
                duration: 4000,
              },
            );
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            toast.error(
              'Failed to process payment details. Please try another payment method.',
            );
            break;
        }
      });
    };

    const timer = setTimeout(() => {
      retrieveSetupIntent();
    }, 2500);

    return () => clearTimeout(timer);
  }, [stripe, toast]);

  return null;
};

const PaymentStatus = ({ promise }: { promise: StripePromise }) => {
  const enterpriseEdition = isEnterpriseEdition();
  return (
    <>
      {enterpriseEdition && promise ? (
        <Elements stripe={promise} options={{}}>
          <PaymentCheck />
        </Elements>
      ) : null}
    </>
  );
};

export default PaymentStatus;
