import MagicWand from '@assets/img/3d/color/magic-wand.png';
import { useDisclosure } from '@chakra-ui/hooks';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { buttonVariants } from 'components/shadcn/button';
import { Save } from 'lucide-react';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useTemplateStore } from 'store/templateStore';
import useWorkflowStore from 'store/workflowStore';
import { setToDefaultTemplate, templateDataReset } from 'templates/store';
import { cn } from 'utils/class-merge';
import WorkflowBackButton from '../../workflowEditor/components/sidebar/WorkflowBackButton';
import AiModal from './AiModal';
import { TestNotification } from './TestNotification';

const TemplateEditorHeader = ({
  saveRef,
  isDirty,
  form,
  emailEditorRef,
}: {
  saveRef: React.MutableRefObject<HTMLButtonElement>;
  isDirty: boolean;
  form: UseFormReturn<any, any>;
  emailEditorRef: React.MutableRefObject<any>;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const channel = params.channel;
  const { loading, reset, tab } = useTemplateStore(state => state);
  const isBatchingEnabled = useWatch({
    control: form.control,
    name: 'batchingEnabled',
  });

  const handleGoBack = () => {
    if (loading) return;

    navigate(-1);

    // setting template details to default
    // if form isDirty it will be blocked, no need to reset
    if (!isDirty) {
      reset();
      templateDataReset();
      setToDefaultTemplate();
    }
  };

  const aiModalActions = useDisclosure();
  const { workflowJson } = useWorkflowStore(state => state);

  return (
    <>
      <AiModal
        onClose={aiModalActions.onClose}
        isOpen={aiModalActions.isOpen}
        form={form}
      />

      <div className="col-span-12">
        <div
          className={cn(
            'border-b flex justify-between border-[#ABB0B84D] px-7 h-16 items-center mb-4',
            channel === 'email' && 'mb-0',
          )}
        >
          {/* First Section */}
          <div className="flex items-center gap-2">
            <WorkflowBackButton
              disabled={loading}
              className={cn('mb-0', loading && 'opacity-50')}
              onClick={handleGoBack}
            />
            {workflowJson?.name && (
              <h4 className="text-gray-600">
                Editing workflow &gt; {workflowJson?.name}
              </h4>
            )}
          </div>

          {/* Second section */}
          <div className="flex items-center gap-4">
            <div className="flex gap-4">
              {/* ai button */}
              {channel !== 'webhook' && channel !== 'email' && (
                <button
                  onClick={() => aiModalActions.onOpen()}
                  className={cn(
                    buttonVariants({
                      size: 'sm',
                    }),
                    'bg-night-100 border border-brand-500 flex gap-2 hover:bg-night-100/20 disabled:opacity-60',
                  )}
                  disabled={tab === 'batched_template' && !isBatchingEnabled}
                >
                  <img src={MagicWand} width={16} alt="" />
                  <span className="gradient-text z-10 animate-gradient text-red-500/0">
                    Generate With AI
                  </span>
                </button>
              )}

              <TestNotification emailEditorRef={emailEditorRef} form={form} />

              <button
                disabled={loading}
                onClick={() => saveRef.current?.click()}
                className={cn(
                  buttonVariants({
                    size: 'sm',
                  }),
                  'flex gap-2',
                )}
              >
                <Save className="w-5 h-5" />
                Save As Draft
                {loading && (
                  <LoaderSpinner
                    strokeColor="black"
                    parentClass={'relative left-2'}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateEditorHeader;
