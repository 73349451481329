import {
  Connection,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  OnConnect,
  OnEdgesChange,
  OnNodesChange,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
} from 'reactflow';
import { create } from 'zustand';

import { GetChannels } from 'api/channels/get-channels/types';
import { GetWorkflows } from 'api/workflows/types';
import { layoutNodes } from 'views/admin/dashboards/workflowEditor/layout';
import {
  NodeData,
  WorkflowDraggableNodeTypes,
  WorkflowIdKeys,
  WorkflowJson,
} from 'views/admin/dashboards/workflowEditor/types';
import {
  DEFAULT_WORKFLOW_JSON,
  WORKFLOW_DEFAULT_EDGES,
  WORKFLOW_DEFAULT_ID_KEY_MAP,
  WORKFLOW_DEFAULT_NODES,
} from 'views/admin/dashboards/workflowEditor/variables';

export type WorkflowModalState = 'add' | 'edit' | 'duplicate' | 'app_copy';
type LayoutMode = 'automatic' | 'manual';
type WorkFlowState = {
  layoutMode: LayoutMode;
  idKeyMap: Record<WorkflowIdKeys, number>;
  nodes: Node<NodeData>[];
  edges: Edge[];
  draggedType: WorkflowDraggableNodeTypes;
  draggedChannel: GetChannels;
  reOrderedNode: Node<NodeData>;
  selectedNode: Node<NodeData>;
  workflow: GetWorkflows;
  workflowModalState: WorkflowModalState;
  workflowJson: WorkflowJson;
  hasJsonChanges: boolean;
  showAllNodeRefs: boolean;
  publishedWorkflowJson: WorkflowJson;
  cancelWorkflowFor: string;
};

type WorkflowActions = {
  setNodes: (nodes: Node<NodeData>[]) => void;
  setEdges: (edges: Edge[]) => void;
  onNodesChange: OnNodesChange;
  onEdgesChange: OnEdgesChange;
  onConnect: OnConnect;
  setHasJsonChanges: (hasJsonChanges: boolean) => void;
  setShowAllNodeRefs: (showAllNodeRefs: boolean) => void;
  setDraggedType: (type: WorkflowDraggableNodeTypes) => void;
  setReOrderedNode: (type: Node<NodeData>) => void;
  setSelectedNode: (type: Node<NodeData>) => void;
  setWorkflowJson: (json: WorkflowJson) => void;
  setPublishedWorkflowJson: (publishedWorkflow: WorkflowJson) => void;
  setDraggedChannel: (type: GetChannels) => void;
  setIdKeyMap: (idKeyMap: Record<WorkflowIdKeys, number>) => void;
  setLayoutMode: (layoutMode: LayoutMode) => void;
  setWorkflow: (workflow: GetWorkflows) => void;
  setWorkflowModalState: (modalState: WorkflowModalState) => void;
  // used for cancelling workflows
  setCancelWorkflowFor: (cancelWorkflowFor: string) => void;
  reset: () => void;

  // not currently using anywhere
  // selectedNodeError: SelecteNodedErrors;
  // setSelectedNodeError: (selectedNodeError: SelecteNodedErrors) => void;
};

const initialState: WorkFlowState = {
  layoutMode: 'automatic',
  idKeyMap: WORKFLOW_DEFAULT_ID_KEY_MAP,
  nodes: layoutNodes(WORKFLOW_DEFAULT_NODES, WORKFLOW_DEFAULT_EDGES)
    .layoutedNodes,
  edges: WORKFLOW_DEFAULT_EDGES,
  draggedType: null,
  selectedNode: null,
  reOrderedNode: null,
  draggedChannel: null,
  workflow: null,
  showAllNodeRefs: false,
  hasJsonChanges: false,
  workflowModalState: null,
  publishedWorkflowJson: DEFAULT_WORKFLOW_JSON,
  workflowJson: DEFAULT_WORKFLOW_JSON,
  cancelWorkflowFor: null,
};

const useWorkflowStore = create<WorkFlowState & WorkflowActions>(
  (set, get) => ({
    ...initialState,
    onNodesChange: (changes: NodeChange[]) => {
      set({
        nodes: applyNodeChanges(changes, get().nodes),
      });
    },
    onEdgesChange: (changes: EdgeChange[]) => {
      set({
        edges: applyEdgeChanges(changes, get().edges),
      });
    },
    onConnect: (connection: Connection) => {
      set({
        edges: addEdge(connection, get().edges),
      });
    },
    setNodes: (nodes: Node[]) => {
      set({ nodes });
    },
    setEdges: (edges: Edge[]) => {
      set({ edges });
    },
    setDraggedType: draggedType => {
      set({ draggedType });
    },
    setHasJsonChanges: hasJsonChanges => {
      set({ hasJsonChanges });
    },
    setReOrderedNode: reOrderedNode => {
      set({ reOrderedNode });
    },
    setSelectedNode: selectedNode => {
      set({ selectedNode });
    },
    setWorkflowJson: workflowJson => {
      set({ workflowJson });
    },
    setPublishedWorkflowJson: publishedWorkflowJson => {
      set({ publishedWorkflowJson });
    },
    setDraggedChannel: draggedChannel => {
      set({ draggedChannel });
    },
    setWorkflow: workflow => {
      set({ workflow });
    },
    setWorkflowModalState: workflowModalState => {
      set({ workflowModalState });
    },
    setIdKeyMap: idKeyMap => {
      set({ idKeyMap });
    },
    setShowAllNodeRefs: showAllNodeRefs => {
      set({ showAllNodeRefs });
    },
    setLayoutMode: layoutMode => {
      set({ layoutMode });
    },
    setCancelWorkflowFor: cancelWorkflowFor => {
      set({ cancelWorkflowFor });
    },
    reset: () => {
      set({ ...initialState });
    },
    // not currently using anywhere
    // selectedNodeError: null,
    // setSelectedNodeError: (selectedNodeError) => {
    //   set({ selectedNodeError });
    // },
  }),
);

export default useWorkflowStore;
