import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type TPostAppVerification = {
  enableUserSignatureVerification: boolean;
  appId: number;
};

const put = async (values: TPostAppVerification) => {
  const { appId, enableUserSignatureVerification } = values;
  const data = {
    enableUserSignatureVerification,
  };

  return axiosInstance.put(`${routes['apps']}/${appId}`, data);
};

const usePutAppVerification = () => {
  return useMutation({
    mutationFn: (values: TPostAppVerification) => put(values),
  });
};

export default usePutAppVerification;
