import { useDisclosure } from '@chakra-ui/hooks';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@radix-ui/react-hover-card';
import { WorkflowRunSteps } from 'api/workflows-run/type';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { format, parseISO } from 'date-fns';
import { Workflow } from 'lucide-react';
import React from 'react';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { workflowRunChipBg, workflowRunChipColor } from '../variables';
import WorkflowStepsInfoModal from './WorkflowStepsInfoModal';

function WorkflowRunStepsTable(props: {
  tableData: any;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  const [selected, setSelected] = React.useState<WorkflowRunSteps>(null);
  const { tableData, loading } = props;
  const workflowStepsInfoModalActions = useDisclosure();
  const navigate = useNavigate();

  const columnConfig: ColumnConfig<WorkflowRunSteps> = [
    {
      accessorKey: 'step.ref',
      header: () => (
        <p className="text-base font-semibold text-gray-600 dark:text-white">
          Step Ref
        </p>
      ),
      cellFunction: info => <>{info.getValue()}</>,
    },
    {
      accessorKey: 'state',
      header: () => (
        <p className="text-sm font-medium text-gray-600 dark:text-white">
          State
        </p>
      ),
      cellFunction: info => {
        const state = info.getValue();
        const error =
          state === 'failed'
            ? info?.row?.original?.runtimeData?.fetchResult?.error ?? null
            : null;

        return (
          <>
            <HoverCard>
              <HoverCardTrigger>
                <div className="flex gap-2">
                  <div
                    style={{
                      background: (workflowRunChipBg as any)[state],
                      color: (workflowRunChipColor as any)[state],
                    }}
                    className={`relative  flex gap-1 select-none items-center whitespace-nowrap rounded-lg  px-[10px] py-[5px]  text-sm font-medium capitalize text-white`}
                  >
                    {info.getValue().replace('_', ' ')}

                    {state === 'failed' && <IoMdInformationCircleOutline />}
                  </div>
                </div>
              </HoverCardTrigger>

              {error && (
                <HoverCardContent className="break-all bg-night-100 p-3 rounded-md">
                  <h4 className="mb-1">Reason</h4>
                  <p className="text-sm">{error}</p>
                </HoverCardContent>
              )}
            </HoverCard>
          </>
        );
      },
    },
    {
      accessorKey: 'createdAt',
      header: () => (
        <p className="text-sm font-medium text-gray-600 dark:text-white">
          Started At
        </p>
      ),
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMM yyyy, h:mm aaa');
        return <p className=" text-sm text-navy-700 dark:text-white">{date}</p>;
      },
    },
    {
      accessorKey: 'updatedAt',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cellFunction: info => {
        return (
          <TableMenu
            config={[
              {
                text: 'View Info',
                action: () => {
                  setSelected(info.row.original);
                  workflowStepsInfoModalActions.onOpen();
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const columns = columnGenerator<WorkflowRunSteps>({ columnConfig });

  return (
    <>
      <WorkflowStepsInfoModal
        isOpen={workflowStepsInfoModalActions.isOpen}
        onClose={workflowStepsInfoModalActions.onClose}
        selected={selected}
      />

      <div className="mt-4 overflow-visible">
        {/* table */}
        <CustomTable
          loading={loading}
          tableData={tableData}
          columns={columns}
          paginationType="table"
          extraTdClass={'hover:cursor-pointer'}
          emptyAdditionalJsx={() => (
            <EmptyTableMessage
              handleCreate={() => {
                navigate('/admin/compose/send');
              }}
              docsLink={
                'https://docs.engagespot.com/docs/features/workflow/workflow-steps'
              }
              heading={'No steps have been executed in this workflow run'}
              message={
                'Workflow steps represent the individual tasks executed in a workflow run. Monitor and manage each step to ensure successful execution of your workflows.'
              }
              Icon={Workflow}
            />
          )}
        />
      </div>
    </>
  );
}

export default WorkflowRunStepsTable;
