import { Path, UseFormRegister } from 'react-hook-form';

// Custom components
function TextField<T>(props: {
  id?: string;
  label?: string;
  extra?: string;
  placeholder: string;
  variant?: 'dark' | 'modal' | 'auth' | 'workflow';
  state?: string;
  disabled?: boolean;
  cols?: number;
  rows?: number;
  onChange?: any;
  showIsRequiredAsterisk?: boolean;
  register?: UseFormRegister<T>;
  required?: boolean;
  extraInputClass?: string;
  name?: Path<T>;
  extraLabelClass?: string;
  resize?: boolean;
  helperText?: string;
}) {
  const {
    helperText,
    label,
    id,
    extra,
    placeholder,
    variant,
    state,
    disabled,
    onChange,
    showIsRequiredAsterisk = false,
    register,
    required,
    name,
    extraInputClass,
    extraLabelClass,
    cols = 1,
    rows = variant === 'workflow' ? 4 : 8,
    resize = false,
  } = props;

  const inputClass = `mt-2 flex w-full items-center justify-center rounded-md border bg-white/0 p-3 text-sm outline-none ${
    disabled === true
      ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)] text-[rgba(255,255,255,0.15)] disabled:cursor-not-allowed'
      : state === 'error'
        ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
        : state === 'success'
          ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
          : ''
  } ${
    variant === 'modal'
      ? 'dark:text-white border border-gray-880 !bg-gray-150/100 border-0 dark:!placeholder:text-slate-400'
      : variant === 'workflow'
        ? 'dark:!bg-night-400 disabled:opacity-60 border border-gray-930 dark:text-white dark:placeholder:!text-[#ABB0B8]'
        : 'border border-gray-440 dark:text-white'
  } ${resize ? '' : 'resize-none'} ${extraInputClass}`;

  return (
    <div className={`${extra}`}>
      {label && (
        <label
          htmlFor={id}
          className={`text-sm text-navy-700 dark:text-white ${
            variant === 'auth' || variant === 'modal'
              ? 'ml-1.5 font-medium'
              : variant === 'workflow'
                ? 'ml-1.5  !text-base font-medium dark:!text-gray-10'
                : 'ml-3 font-bold'
          } ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}

      {helperText && (
        <p
          className="text-sm ml-3 text-white opacity-[35%]"
          dangerouslySetInnerHTML={{
            __html: helperText,
          }}
        ></p>
      )}

      {register && name ? (
        <textarea
          cols={cols}
          rows={rows}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          className={inputClass}
          {...register(name, { required })}
          {...(onChange ? { onChange } : {})}
        />
      ) : (
        <textarea
          onChange={onChange}
          cols={cols}
          rows={rows}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
          className={inputClass}
        />
      )}
    </div>
  );
}

export default TextField;
