import useGetLogs from 'api/logs/get-logs';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from 'components/shadcn/tabs';
import { usePaginationApiState } from 'components/table/pagination-state';
import React from 'react';
import { WorkflowRuns } from '../workflow-overview/components/WorkflowRun';
import LogsTable from './components/LogsTable';
import PageHeader from 'components/header/PageHeader';
import HeaderDoc from 'components/header/HeaderDoc';

const Logs = () => {
  const { pagination, setPagination } = usePaginationApiState();
  const getLogs = useGetLogs(pagination);
  const [workflowRunId, setWorkflowRunId] = React.useState<number>(null);
  const [workflowId, setWorkflowId] = React.useState<number>(null);

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      {/* Header */}
      <PageHeader
        heading={'Message Delivery Logs'}
        text={
          <>
            <p className="mb-2">Unified logs from all provider services</p>
          </>
        }
      />

      {/* Tables */}
      <div className="col-span-12">
        <Tabs defaultValue="1" className="">
          <TabsList>
            <TabsTrigger value="1">Delivery Logs</TabsTrigger>
            <TabsTrigger value="2">Workflow Runs</TabsTrigger>
          </TabsList>

          <TabsContent value="1">
            <LogsTable
              tableData={getLogs?.data?.results ?? []}
              totalPages={getLogs?.data?.totalPages}
              loading={getLogs.isLoading}
              pagination={pagination}
              setPagination={setPagination}
            />
          </TabsContent>

          <TabsContent value="2">
            <WorkflowRuns
              workflowRunId={workflowRunId}
              setWorkflowRunId={setWorkflowRunId}
              inLogsPage={true}
              workflowId={workflowId}
              setWorkflowId={setWorkflowId}
            />
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default Logs;
