import { PaginationState, Row } from '@tanstack/react-table';
import { PromotedCommits } from 'api/commits/types';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { BellDot, Info } from 'lucide-react';
import React from 'react';
import { cn } from 'utils/class-merge';
import { getIdInSpecificFormat, getRevertMessage } from '../functions';
import { CustomTooltip } from 'components/shadcn/tooltip';
import { useAppLevel } from 'utils/use-app-level';

function PublishedTable(props: {
  tableData: any;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  reverModalOpen: () => void;
  loading: boolean;
  setSelectedRows: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  totalPages: number;
  selectedEnvironment: string;
  setSelectedPublished: React.Dispatch<
    React.SetStateAction<PromotedCommits['results'][0]>
  >;
  publishDiffModalOpen: () => void;
  isProductionApp: boolean;
}) {
  const {
    tableData,
    globalFilter,
    loading,
    setSelectedRows,
    pagination,
    setPagination,
    totalPages,
    setSelectedPublished,
    selectedEnvironment,
    publishDiffModalOpen,
    reverModalOpen,
    isProductionApp,
  } = props;
  const { isDefaultApp } = useAppLevel();

  const columnConfig: ColumnConfig<PromotedCommits['results'][0]> = [
    {
      accessorKey: 'commit.uuid',
      header: 'ID',
      cellFunction: info => {
        const message = info.getValue();
        const displayMessage = getIdInSpecificFormat(message);
        return <CommitId message={displayMessage} />;
      },
    },
    {
      accessorKey: 'commit.message',
      header: 'Message',
      cellFunction: info => {
        const message = info.getValue();
        const revertMessage = getRevertMessage(message);
        const displayMessage =
          message.length > 75 ? `${message?.slice(0, 50)}...` : message;
        const revertCommit = message.split('_')?.[1];
        const revertCommitMessage = getRevertMessage(revertCommit);
        const noOfChanges = info.row.original?.commit?.changes?.length;

        return revertMessage ? (
          <div className="flex items-center">
            revert-changes-
            <span className="">
              <CommitId
                className="py-1 px-2 text-blue-450"
                message={revertMessage}
                revertMessage={
                  revertCommitMessage
                    ? `revert-changes-${revertCommitMessage}`
                    : revertCommit
                }
              />
            </span>
            {noOfChanges && noOfChanges > 1 && (
              <NoOfChanges changes={`+${noOfChanges}`} />
            )}
          </div>
        ) : (
          <div className="flex items-center">
            {displayMessage}

            {noOfChanges && noOfChanges > 1 && (
              <NoOfChanges
                className="relative top-[1px]"
                changes={`+${noOfChanges}`}
              />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'promotedTo',
      header: 'Migration Status',
      cellFunction: info => {
        const isMigrated = info.getValue() === Number(selectedEnvironment);

        return (
          <>
            <span
              className={cn(
                'border border-input py-1 px-2 rounded text-xs',
                isMigrated ? 'text-green-920 ' : 'text-yellow-450',
              )}
            >
              {isMigrated ? 'Migrated' : 'Not Migrated'}
            </span>
          </>
        );
      },
    },
    {
      accessorKey: 'commit.clientUser',
      header: 'Published by',
      cellFunction: info => {
        const clientUser = info.getValue();
        const client = info.row.original?.client;
        const clientName = client?.name;

        return (
          <div className="flex gap-2 items-center">
            <span>
              <Avatar className="h-6 w-6">
                <AvatarImage
                  src={clientUser?.profilePicture}
                  alt={clientUser?.firstName}
                />
                <AvatarFallback
                  className={cn(
                    'text-white text-xs',
                    clientUser ? '' : 'text-[9px]',
                  )}
                >
                  {clientUser
                    ? clientUser?.firstName?.charAt(0).toUpperCase()
                    : 'CLI'}
                </AvatarFallback>
              </Avatar>
            </span>
            <span>{clientUser ? clientUser?.firstName : clientName}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'commit.createdAt',
      header: 'Published at',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMMM yyyy, h:mm aaa');
        return date;
      },
    },
    {
      accessorKey: 'commit.changes.id',
      header: () => '',
      cellFunction: info => {
        const config = [
          {
            text: 'View Changes',
            action: () => {
              setSelectedPublished(info.row.original);
              publishDiffModalOpen();
            },
          },
          ...(isDefaultApp
            ? [
                {
                  text: 'Revert Changes',
                  action: () => {
                    setSelectedPublished(info.row.original);
                    reverModalOpen();
                  },
                },
              ]
            : []),
        ];

        return (
          <div className="flex gap-0">{<TableMenu config={config} />}</div>
        );
      },
    },
  ];

  const columns = columnGenerator({ columnConfig });

  return (
    <CustomTable
      rowClick={info => {
        setSelectedPublished(info.original);
        publishDiffModalOpen();
      }}
      tableData={tableData}
      columnVisibility={{
        promotedTo: isProductionApp ? false : true,
      }}
      columns={columns}
      paginationType="api"
      pagination={pagination}
      setPagination={setPagination}
      globalFilter={globalFilter}
      loading={loading}
      totalPages={totalPages}
      setSelectedRows={setSelectedRows}
      emptyAdditionalJsx={() => (
        <EmptyTableMessage
          searchInput={globalFilter}
          docsLink={
            'https://docs.engagespot.co/docs/features/workflows/publish'
          }
          heading={'No published changes 🗂️'}
          message={
            'You don’t have any published workflows in this environment. Publish workflows (or migrate from development environment) to see them here.'
          }
          searchEmptyHeading={getSearchEmptyHeading('published changes')}
          searchEmptyMessage={getSearchEmptyMessage('published changes')}
          Icon={BellDot}
          hideSecondary={true}
          messsageClassName="max-w-[350px]"
        />
      )}
    />
  );
}

export default PublishedTable;

export const NoOfChanges = ({
  changes,
  className,
}: {
  changes: string;
  className?: string;
}) => {
  return (
    <span
      className={cn(
        'outline h-[14px] outline-input flex items-center w-min justify-center text-[10px] rounded-[20px] pl-1 px-1 py-0 ml-2 text-yellow-500',
        className,
      )}
    >
      {changes}
    </span>
  );
};

const CommitId = ({
  message,
  className,
  revertMessage,
}: {
  message: string;
  className?: string;
  revertMessage?: string;
}) => {
  return (
    <div
      className={cn(
        'outline outline-input flex justify-center items-center rounded-sm text-xs py-1 gap-1 px-1 w-fit',
        className,
      )}
    >
      {message}

      {revertMessage && (
        <CustomTooltip
          trigger={<Info className="w-4 h-4 " />}
          content={
            <div className="flex flex-col gap-1 p-2 text-sm rounded-md">
              <p className="">
                ID - <span className="font-bold">{message}</span>
              </p>
              <p className="">Message - {revertMessage}</p>
            </div>
          }
        />
      )}
    </div>
  );
};
