import { InternalAxiosRequestConfig } from 'axios';
import Cookies from 'js-cookie';

export const axiosRequestInterceptor = (
  config: InternalAxiosRequestConfig<any>,
) => {
  const authToken = Cookies.get('access_token');

  if (authToken) {
    config.headers.Authorization = `bearer ${authToken}`;
  }
  return config;
};

export const axiosLicenseRequestInterceptor = (
  config: InternalAxiosRequestConfig<any>,
) => {
  const authToken = Cookies.get('license_access_token');

  if (authToken) {
    config.headers.Authorization = `bearer ${authToken}`;
  }
  return config;
};

export const axiosResponseErrorInterceptor = (err: any) => {
  if (err.response) {
    const authToken = Cookies.get('access_token');

    // Unauthorized
    if (
      authToken &&
      err.response.status === 401 &&
      err.response.data?.message === 'Unauthorized' &&
      window.location.pathname !== '/auth/login' &&
      // Because the staging server lags sometimes and cannot query
      // the necessary user details and 401 is thrown
      process.env.NODE_ENV === 'production'
    ) {
      Cookies.remove('access_token');
      window.location.href = '/auth/login';
    }
  }

  return Promise.reject(err);
};
