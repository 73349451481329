import { format, parseISO } from 'date-fns';
import useGetPlanUsage from 'api/usuage/get-plan-usage';
import Card from 'components/card';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import Header from './Header';
import { useAuthStore } from 'store/authStore';
import { isEnterpriseEdition } from 'utils/get-editions';
import useGetLicense from 'api/billing/get-license';
import useGetSubscriptionDetails from 'api/billing/get-subscription-details';

const UsuageBar = ({
  widthPercentage,
  className,
  startValue,
  endValue,
  currentValue,
  exceededPlanLimits,
}: {
  startValue: string;
  currentValue: string;
  endValue: string;
  widthPercentage: number | string;
  className?: string;
  exceededPlanLimits: boolean;
}) => {
  const maxContainerWidthPercentage = 198;
  const width =
    Number(widthPercentage) < maxContainerWidthPercentage
      ? widthPercentage
      : maxContainerWidthPercentage;

  return (
    <>
      <div
        className={`flex flex-col items-center gap-3 rounded-full ${className} w-1/2 `}
      >
        <div className="h-3 w-full rounded-full  bg-white">
          <div
            style={{
              width: `${width}%`,
            }}
            className={`flex h-full items-center justify-center rounded-full bg-brand-500 ${
              exceededPlanLimits ? 'dark:bg-red-500' : ' dark:bg-[#a5a3e9]'
            }  w-${width}p`}
          />
        </div>

        <div className="relative flex w-full justify-between">
          <span>{startValue}</span>
          <span
            style={{
              left: `${
                width === '0'
                  ? '0%'
                  : width === '100'
                    ? Number(width) - 3
                    : Number(width) - 2
              }%`,
            }}
            className={`absolute bottom-14 ${
              exceededPlanLimits ? 'text-orange-600' : 'text-brand-500'
            } `}
          >
            {currentValue}
          </span>
          <span>{endValue}</span>
        </div>
      </div>
    </>
  );
};

function Usuage() {
  const planUsage = useGetPlanUsage();
  const usuage = String(planUsage.data?.usageSummary?.triggerCount);
  const planLimit = planUsage.data?.licenseSummary?.limits?.deliveries;
  const abbreviateUsuage = getAbbreviateNumber(usuage);
  const abbreviatePlanLimit = getAbbreviateNumber(planLimit);
  const usuagePercentage = (Number(usuage) / planLimit) * 100;
  const { clientId: clientUserId } = useAuthStore(state => state);
  const enterpriseEdition = isEnterpriseEdition();
  const license = useGetLicense(clientUserId);
  const getSubscriptionDetails = useGetSubscriptionDetails(
    license.data?.InstanceClientIdentifer,
    enterpriseEdition,
  );
  const exceededPlanLimits = Number(planLimit) < Number(usuage);

  const currentPeriodStart = getSubscriptionDetails?.data?.currentPeriodStart
    ? format(
        parseISO(getSubscriptionDetails?.data?.currentPeriodStart),
        'MMMM d, yyyy',
      )
    : '';

  const currentPeriodEnd = getSubscriptionDetails?.data?.currentPeriodEnd
    ? format(
        parseISO(getSubscriptionDetails?.data?.currentPeriodEnd),
        'MMMM d, yyyy',
      )
    : '';

  return (
    <Card extra={'pb-8 px-6 pt-6'}>
      <Header>Usage</Header>

      <div
        className={`relative justify-between rounded-[20px] bg-night-100 p-9 pb-8 border border-input`}
      >
        <div className="flex w-full justify-between">
          <h4 className="mb-2 text-xl text-[#8A91A0]">
            <span className="capitalize">{'Notifications triggered'}</span>{' '}
            <span className="mb-2 text-sm font-medium text-white">
              ( {currentPeriodStart} to {currentPeriodEnd} )
            </span>
          </h4>
        </div>

        {exceededPlanLimits && (
          <p className=" text-lg font-bold text-[#39da27]">
            Plan Limit - {abbreviatePlanLimit ?? 0} Notifications
          </p>
        )}

        <p className="mt-2 text-lg  font-bold text-[#DA9227]">
          {abbreviateUsuage ?? 0} Notifications triggered
        </p>

        {exceededPlanLimits && (
          <p className="mt-2 text-lg font-bold text-red-500">
            You have exceeded your plan limits
          </p>
        )}

        <div className="mb-12"></div>

        <UsuageBar
          startValue={'0'}
          endValue={abbreviatePlanLimit}
          widthPercentage={String(usuagePercentage)}
          currentValue={abbreviateUsuage}
          exceededPlanLimits={exceededPlanLimits}
        />
      </div>
    </Card>
  );
}

export default Usuage;
