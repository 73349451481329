import Download from '@assets/svg/billing/download.svg';
import { GetInvoices } from 'api/billing/get-invoices/types';
import Card from 'components/card';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import { format, fromUnixTime } from 'date-fns';
import { getCurrencySymbol } from 'utils/get-currency';
import Header from './Header';

function InvoiceTable(props: {
  tableData: any;
  // globalFilter: string;
  // setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  // const { tableData, globalFilter, setGlobalFilter } = props;
  const currencySymbol = getCurrencySymbol();
  const { tableData, loading } = props;

  const downloadPdf = (url: string) => {
    return window.open(url, '_blank').focus();
  };

  const columnConfig: ColumnConfig<GetInvoices['data'][0]> = [
    {
      accessorKey: 'id',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Identifier
        </p>
      ),
      cellFunction: info => (
        <p className="py-[10px]  text-sm text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    },
    {
      accessorKey: 'status_transitions',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Bill Date
        </p>
      ),
      cellFunction: info => {
        const finalizedDateUnix = info.getValue().finalized_at;
        let finalizedDateInString = '';

        if (finalizedDateUnix) {
          const finalizedDate = fromUnixTime(finalizedDateUnix);

          if (finalizedDate) {
            finalizedDateInString = format(finalizedDate, 'd MMMM yyyy');
          }
        }

        return (
          <p className="py-[10px]  text-sm text-navy-700 dark:text-white">
            {finalizedDateInString}
          </p>
        );
      },
    },
    {
      accessorKey: 'amount_due',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Amount
        </p>
      ),
      cellFunction: info => (
        <p className="py-[10px]  text-sm text-navy-700 dark:text-white">
          {`${currencySymbol} ${Number(info.getValue()) / 100}`}
        </p>
      ),
    },
    {
      accessorKey: 'status',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Payment Status
        </p>
      ),
      cellFunction: info => {
        const isPaid = info.getValue() === 'paid';

        return (
          <p
            className={`relative left-4 flex w-min items-center justify-center rounded-md tracking-wider ${
              isPaid ? 'bg-green-50 text-[#7DD155]' : 'bg-red-50 text-[#d16255]'
            } px-3 py-2 text-sm capitalize`}
          >
            {info.getValue()}
          </p>
        );
      },
    },
    {
      accessorKey: 'invoice_pdf',
      header: () => null,
      cellFunction: info => (
        <div className="flex w-full justify-center">
          <button
            className="flex gap-2 rounded-lg border border-[#E1E1E133] px-3 py-2 font-medium text-black"
            onClick={() => downloadPdf(info.getValue())}
          >
            <img src={Download} width={17} alt="" />
          </button>
        </div>
      ),
    },
  ];

  const columns = columnGenerator({
    columnConfig,
  });

  return (
    <div className="csm_invoice_table mt-8 overflow-x-scroll xl:overflow-x-hidden">
      <Header>Invoice history</Header>

      {/* table */}
      <CustomTable
        loading={loading}
        emptyTableMessage="No worries! Buy a subscription plan"
        paginationType="table"
        tableData={tableData ?? []}
        columns={columns}
      />
    </div>
  );
}

export default InvoiceTable;
