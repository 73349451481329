import { Channels } from 'api/channels/get-channels/types';

type templateEditorHeadingsObj = { [actions in Channels]?: string };

const templateEditorBaseHeading = 'channel Notification Template';

export const templateEditorHeadings: templateEditorHeadingsObj = {
  inApp: templateEditorBaseHeading.replace('channel', 'In-App'),
  sms: templateEditorBaseHeading.replace('channel', 'Sms'),
  chat: templateEditorBaseHeading.replace('channel', 'Chat'),
  email: templateEditorBaseHeading.replace('channel', 'Email'),
  mobilePush: templateEditorBaseHeading.replace('channel', 'Mobile Push'),
  slack: templateEditorBaseHeading.replace('channel', 'Slack'),
  webPush: templateEditorBaseHeading.replace('channel', 'Web Push'),
  whatsapp: templateEditorBaseHeading.replace('channel', 'Whatsapp'),
  discord: templateEditorBaseHeading.replace('channel', 'Discord'),
  webhook: templateEditorBaseHeading.replace('channel', 'Webhook'),
};

const templateEditorBaseText =
  'This template will be used for sending channel notifications.';

export const templateEditorText: templateEditorHeadingsObj = {
  inApp:
    "You can create rich, actionable in-app messages. <a class='text-decoration-line: underline' href='https://docs.engagespot.com/docs/features/in-app-inbox/actionable-notifications' target='_blank'>Read guide</a>",
  sms: templateEditorBaseText.replace('channel', 'Sms'),
  chat: templateEditorBaseText.replace('channel', 'Chat'),
  email: templateEditorBaseText.replace('channel', 'Email'),
  mobilePush: templateEditorBaseText.replace('channel', 'Mobile Push'),
  slack: `${templateEditorBaseText.replace('channel', 'Slack')}. <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/channels/slack/slack-provider' target='_blank'>Read guide</a>`,
  webPush: `${templateEditorBaseText.replace('channel', 'Web Push')}. <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/channels/web-push/default-web-push-provider' target='_blank'>Read guide</a>`,
  discord: `${templateEditorBaseText.replace('channel', 'Discord')}. <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/channels/discord/discord-provider' target='_blank'>Read guide</a>`,
  webhook: `${templateEditorBaseText.replace('channel', 'Webhook')}. <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/channels/webhook/default-webhook-provider' target='_blank'>Read guide</a>`,
  whatsapp: templateEditorBaseText.replace('channel', 'Whatsapp'),
};
