import { isEnterpriseEdition } from 'utils/get-editions';
import Information from './components/Information';
import Organisation from './components/Organisation';
import Password from './components/Password';
import TwoFactor from './components/TwoFactor';
import UserDetails from './components/UserDetails';

const Profile = () => {
  const enterpriseEdition = isEnterpriseEdition();

  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
      <div className="col-span-2 flex flex-col gap-5">
        <UserDetails />
      </div>

      <div className="flex flex-col gap-5">
        <Information />
        <Password />
      </div>

      <div className="flex flex-col gap-5">
        {enterpriseEdition && <TwoFactor />}

        <Organisation />
      </div>
    </div>
  );
};

export default Profile;
