import { useDisclosure } from '@chakra-ui/hooks';
import useGetClientInvitations from 'api/client/get-client-invitations.ts';
import useGetClientUsers from 'api/client/get-client-users.ts';
import PageHeader from 'components/header/PageHeader';
import { Tabs, TabsList, TabsTrigger } from 'components/shadcn/tabs';
import React from 'react';
import { useAuthStore } from 'store/authStore';
import ActionModal from './components/ActionModal';
import AddMembersModal from './components/AddMembersModal';
import InvitationTable from './components/InvitationTable';
import MembersTable from './components/MembersTable';

const Teams = () => {
  const { clientId } = useAuthStore(state => state);
  const getClientUsers = useGetClientUsers(clientId);
  const getClientInvitations = useGetClientInvitations(clientId);
  const [tableView, setTableView] = React.useState(1);
  const [globalFilter, setGlobalFilter] = React.useState('');
  const actionModalActions = useDisclosure();
  const addUserModalActions = useDisclosure();

  const handleSwitch = (value: number) => {
    setGlobalFilter('');
    setTableView(value);
  };

  return (
    <div className="mt-5 grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <ActionModal
        isOpen={actionModalActions.isOpen}
        onClose={actionModalActions.onClose}
      />
      <AddMembersModal
        isOpen={addUserModalActions.isOpen}
        onClose={addUserModalActions.onClose}
      />

      {/* Header */}
      <PageHeader
        handleCreate={() => {
          addUserModalActions.onOpen();
        }}
        paginationType="table"
        heading={'Teams'}
        text={'Invite team members to your workspace'}
        setFilter={setGlobalFilter}
        filter={globalFilter}
        buttonText={'Invite Team Member'}
        parentClass="mb-6"
      />

      {/* Switch Buttons */}
      <div className="col-span-12">
        <Tabs defaultValue="1" className="">
          <TabsList>
            <TabsTrigger onClick={() => handleSwitch(1)} value="1">
              Members
            </TabsTrigger>
            <TabsTrigger onClick={() => handleSwitch(2)} value="2">
              Pending Invitations
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>

      {/* Tab Content are not used due to css issues here */}
      <>
        {tableView === 1 ? (
          <MembersTable
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            tableData={getClientUsers?.data ?? []}
            onOpen={actionModalActions.onOpen}
            loading={getClientUsers.isLoading}
          />
        ) : (
          <InvitationTable
            onCreateOpen={addUserModalActions.onOpen}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            tableData={getClientInvitations?.data ?? []}
            onOpen={actionModalActions.onOpen}
            loading={getClientUsers.isLoading}
          />
        )}
      </>
    </div>
  );
};

export default Teams;
