const startUrl = `/v1`;
const startUrlV3 = `/v3`;
/**
 * Some routes are available only on the enterprise edition. Such routes should have `ee` prefix.
 * Some routes like `auth` exists in both community edition and enterprise edition. But,
 * their functionalities might be different. So make sure to use the proper route based on the edition.
 * Edition is avaialble in the `__EDITION__` constant. (From .env file)
 */
export const routes = {
  ...(__EDITION__ === 'enterprise'
    ? { 'signin-email': `${startUrl}/ee/auth` }
    : { 'signin-email': `${startUrl}/auth` }),
  'signin-github': `${startUrl}/ee/auth/github`,
  signup: `${startUrl}/auth/signup`,
  invitations: `${startUrl}/invitations`,
  resendVerificationEmail: `${startUrl}/auth/resendVerificationEmail`,
  forgotPassword: `${startUrl}/auth/passwordResetRequests`,
  resetPassword: `${startUrl}/auth/resetPassword`,
  verifyEmail: `${startUrl}/auth/verify`,
  clients: `${startUrl}/clients`,
  client: `/clients`,
  clientUsers: `${startUrl}/clientUsers`,
  apps: `${startUrl}/apps`,
  analytics: `${startUrl}/analytics`,
  me: `${startUrl}/auth/me`,
  categories: `${startUrl}/categories`,
  topics: `${startUrl}/topics`,
  templates: `${startUrl}/templates`,
  workflows: `${startUrl}/workflows`,
  commits: `${startUrl}/commits`,
  files: `${startUrl}/files`,
  locales: `${startUrl}/locales`,
  translations: `${startUrl}/translations`,
  layouts: `${startUrl}/layouts`,
  '2fa': `${startUrl}/ee/auth/2fa`,
  plans: `/plans`,
  coupons: `clients/coupons`,
  license: `license`,
  'content-state': `notification-content-state`,
  notification: `${startUrlV3}/notifications`,
  routes: `${startUrl}/routes`,
  onboarding: `${startUrl}/auth/onboarding`,
};
