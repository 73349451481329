import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { usePublishChanges } from 'api/commits';
import { routes } from 'api/routes';
import { TranslationDetails } from 'api/translations/types';
import CustomDiffViewer from 'components/diff-viewer';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { stringifyJson } from 'utils/check-diff';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type FormChanges = {
  message: string;
};

const schema = yup.object({
  message: yup.string().required('Commit Message is required'),
});

function TranslationDiffModal({
  isOpen,
  onClose,
  translation,
}: {
  onClose: () => void;
  isOpen: boolean;
  translation: TranslationDetails;
}) {
  const { appId } = useAuthStore(state => state);
  const toast = useCustomToast();
  const publishChanges = usePublishChanges();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormChanges>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
    reset({ message: '' });
  };

  const { oldJson, newJson } = stringifyJson({
    oldValue: translation?.publishedJsonSpec,
    newValue: translation?.jsonSpec,
  });

  const onSubmit: SubmitHandler<FormChanges> = values => {
    const data = {
      message: values.message,
      appId,
      changes: [
        {
          entityType: 'translation' as const,
          entityId: Number(translation?.id),
        },
      ],
    };

    publishChanges.mutate(data, {
      onSuccess: () => {
        toast.success('Translation Published Successfully');
        queryClient.invalidateQueries([`${routes['translations']}/details`]);
        handleClose();
      },
      onError: error => {
        toast.showError(error);
        handleClose();
      },
    });
  };

  React.useEffect(() => {
    setValue('message', `Edited ${translation?.name} `);
  }, [isOpen, setValue, translation?.name]);

  return (
    <CustomModal
      modalContentClassName="min-w-[92vw] h-[92vh] top-[8%]"
      modalCardClassName={'max-h-[92vh] h-[92vh] px-0 py-0'}
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton={false}
      modalOverlayClick={handleClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col border-b mb-6  border-gray-880 px-8 py-6"
      >
        <div className="flex justify-between  items-center ">
          <h1 className="text-lg">Preview Changes</h1>

          <div className="flex gap-3">
            <button
              type="button"
              onClick={handleClose}
              className="bg-night-400 text-white border border-gray-440 px-4 py-2 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              cancel
            </button>

            <button
              type="submit"
              className="bg-white text-night-400 border border-gray-440 px-4 py-2 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={publishChanges.isLoading}
            >
              Publish
              {publishChanges.isLoading && (
                <LoaderSpinner
                  strokeColor="black"
                  parentClass="relative left-1"
                />
              )}
            </button>
          </div>
        </div>

        <div className="mt-2">
          <InputField<FormChanges>
            variant="modal"
            extra=""
            label={''}
            placeholder="Enter commit message"
            showIsRequiredAsterisk={true}
            type="text"
            register={register}
            name={'message'}
          />

          {errors.message && (
            <ErrorBox className="my-0 mt-3" error={errors.message} />
          )}
        </div>
      </form>

      <div className="h-[76%] csm-react-diff overflow-auto rounded-lg mx-8 border border-gray-880">
        <CustomDiffViewer oldValue={oldJson} newValue={newJson} />
      </div>
    </CustomModal>
  );
}

export default TranslationDiffModal;
