import { yupResolver } from '@hookform/resolvers/yup';
import usePostCategories from 'api/categories/post-categories';
import usePutOrDeleteCategories from 'api/categories/put-delete-categories';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { useCategoryStore } from 'store/categoryStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormAddCategories } from '../types';

function CategoryModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const postAddCategories = usePostCategories();
  const putAddCategories = usePutOrDeleteCategories();
  const toast = useCustomToast();
  const { appId } = useAuthStore(state => state);
  const { category: selectedCategory, setCategory } = useCategoryStore(
    state => state,
  );

  const schema = yup.object({
    name: yup.string().required('name is required'),
    ...(!selectedCategory && {
      identifier: yup
        .string()
        .matches(
          /^[a-zA-Z0-9_]*$/,
          'Identifier can only contain letters, numbers, and underscores',
        )
        .required('identifier is required'),
    }),
  });

  const defaultValues = React.useMemo(
    () => ({
      name: '',
      identifier: '',
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormAddCategories>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  React.useEffect(() => {
    if (selectedCategory) {
      reset({
        name: selectedCategory.name,
      });
    } else {
      reset(defaultValues);
    }
  }, [defaultValues, reset, selectedCategory, isOpen]);

  const handleClose = () => {
    reset(defaultValues);
    setCategory(null);
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormAddCategories> = values => {
    if (selectedCategory) {
      const data = {
        ...values,
        categoryId: selectedCategory.id,
        type: 'put' as const,
      };

      const finalActions = getFinalActions('category updated successfully');
      putAddCategories.mutate(data, finalActions);
      return;
    }

    const data = {
      ...values,
      identifier: values.identifier,
      appId,
    };

    const finalActions = getFinalActions('category added successfully');
    postAddCategories.mutate(data, finalActions);
  };

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold">
        {selectedCategory ? 'Edit' : 'Add'} Category
      </h1>

      <p className="mb-6 text-sm text-[#BFBFBF]">
        Read
        <a
          href={
            'https://docs.engagespot.co/docs/features/category/what-are-categories'
          }
          className="mx-1 font-bold text-brand-500 hover:text-brand-500"
          target="_blank"
          rel="noreferrer"
        >
          docs
        </a>
        to learn more about Categories
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Name */}
        <InputField<FormAddCategories>
          variant="modal"
          extra="mb-3"
          label={'Category Name'}
          placeholder="Enter name"
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'name'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('name', e.target.value, {
              shouldValidate: true,
            });

            if (!selectedCategory) {
              setValue(
                'identifier',
                e.target.value.toLowerCase().replace(/ /g, '_'),
                {
                  shouldValidate: true,
                },
              );
            }
          }}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        {/* Identifier */}
        {!selectedCategory && (
          <>
            <InputField<FormAddCategories>
              variant="modal"
              extra="mb-3"
              label={'Identifier'}
              placeholder="Enter Identifier"
              showIsRequiredAsterisk={true}
              type="text"
              register={register}
              name={'identifier'}
            />
            {errors.identifier && <ErrorBox error={errors.identifier} />}
          </>
        )}

        <CustomModalButtons
          handleClose={handleClose}
          loading={postAddCategories.isLoading || putAddCategories.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default CategoryModal;
