import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';

type DeleteInvitation = {
  invitationId: number;
  clientId: number;
};

const remove = async (values: DeleteInvitation) => {
  const { invitationId, clientId } = values;
  return axiosGlobalInstance.delete(
    `${routes['clients']}/${clientId}/clientUserInvitations/${invitationId}`,
  );
};

const useDeleteInvitation = () => {
  return useMutation({
    mutationFn: (values: DeleteInvitation) => remove(values),
  });
};

export default useDeleteInvitation;
