import { GetCliTokens } from 'api/cli/types';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { Users } from 'lucide-react';
import React from 'react';
import { cn } from 'utils/class-merge';

function CliTable(props: {
  tableData: any;
  editOpen: () => void;
  deleteOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  const { tableData, editOpen, globalFilter, loading } = props;

  const columnConfig: ColumnConfig<GetCliTokens> = [
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'clientUser',
      header: 'Generated by',
      cellFunction: info => {
        const clientUser = info.getValue();
        const firstName = clientUser?.firstName;
        const profilePicture = clientUser?.profilePicture;

        return (
          <div className={cn('flex gap-2 items-center')}>
            <span>
              <Avatar className="h-6 w-6">
                <AvatarImage src={profilePicture} alt={firstName} />
                <AvatarFallback className="text-white text-xs">
                  {firstName?.charAt(0).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </span>
            <span>{firstName}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Generated at',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMMM yyyy, h:mm aaa');
        return date;
      },
    },
  ];

  const columns = columnGenerator({ columnConfig });

  return (
    <CustomTable
      loading={loading}
      emptyTableMessage="No worries! Create your first category"
      tableData={tableData}
      columns={columns}
      paginationType="table"
      globalFilter={globalFilter}
      emptyAdditionalJsx={() => (
        <EmptyTableMessage
          handleCreate={() => {
            editOpen();
          }}
          searchInput={globalFilter}
          docsLink={'https://docs.engagespot.co/docs/engagespot-cli/overview'}
          heading={"You haven't created any CLI Tokens yet"}
          message={
            'Create CLI tokens to publish or migrate your workflows using Engagespot CLI'
          }
          searchEmptyHeading={getSearchEmptyHeading('CLI Tokens')}
          searchEmptyMessage={getSearchEmptyMessage('CLI Tokens')}
          Icon={Users}
        />
      )}
    />
  );
}

export default CliTable;
