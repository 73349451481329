import { EngagespotEvent } from 'api/logs/get-logs/types';
import {
  LuArrowUpCircle,
  LuCheckCircle2,
  LuCircle,
  LuHelpCircle,
  LuXCircle,
} from 'react-icons/lu';
import { logChipColors, logColors } from 'variables/chipColors';

export const legacyEventChipColor = {
  in_process: 'blue-500',
  queued: 'blue-500',
  request_received: 'gray-500',
  discarded: 'red-500',
  provider_rejected: 'red-500',
  failed: 'red-500',
  fallback_attempt_awaited: 'amber-500',
  delivered: 'green-500',
  sent: 'green-500',
  seen: 'teal-500',
  interacted: 'teal-500',
  read: 'teal-500',
};

export const eventChipBg = {
  in_process: logChipColors.yellow,
  queued: logChipColors.yellow,
  request_received: logChipColors.yellow,
  discarded: logChipColors.red,
  provider_rejected: logChipColors.red,
  failed: logChipColors.red,
  fallback_attempt_awaited: logChipColors.yellow,
  delivered: logChipColors.green,
  sent: logChipColors.green,
  seen: logChipColors.blue,
  interacted: logChipColors.violet,
  read: logChipColors.violet,
};

export const eventChipColor = {
  in_process: logChipColors.light_yellow,
  queued: logChipColors.light_yellow,
  request_received: logChipColors.light_yellow,
  discarded: logChipColors.light_red,
  provider_rejected: logChipColors.light_red,
  failed: logChipColors.light_red,
  fallback_attempt_awaited: logChipColors.light_yellow,
  delivered: logChipColors.light_green,
  sent: logChipColors.light_green,
  seen: logChipColors.light_blue,
  interacted: logChipColors.light_violet,
  read: logChipColors.light_violet,
};

export const engageSpotEventColorMapping: {
  [x in keyof EngagespotEvent]: string;
} = {
  // orange
  in_process: logColors['orange'],
  fallback_attempt_awaited: logColors['orange'],

  // light green
  sent: logColors['light_green'],

  // medium green
  seen: logColors['medium_green'],

  // light red
  discarded: logColors['light_red'],
  provider_rejected: logColors['light_red'],
  failed: logColors['light_red'],

  // light blue
  delivered: logColors['light_blue'],

  // light violet
  read: logColors['light_violet'],

  // medium blue
  interacted: logColors['medium_blue'],

  // gray
  request_received: logColors['gray'],
};

export const statusOptions = [
  {
    value: 'backlog',
    label: 'Backlog',
    icon: LuHelpCircle,
  },
  {
    value: 'todo',
    label: 'Todo',
    icon: LuCircle,
  },
  {
    value: 'in progress',
    label: 'In Progress',
    icon: LuArrowUpCircle,
  },
  {
    value: 'done',
    label: 'Done',
    icon: LuCheckCircle2,
  },
  {
    value: 'canceled',
    label: 'Canceled',
    icon: LuXCircle,
  },
];

export const channelOptions = [
  {
    value: 'sms',
    label: 'SMS',
    icon: LuHelpCircle,
  },
  {
    value: 'mail',
    label: 'Mail',
    icon: LuCircle,
  },
  {
    value: 'in-app',
    label: 'In-app',
    icon: LuArrowUpCircle,
  },
];

export const productLogos = {
  img1: 'https://cdn.engagespot.co/misc/gshplogo.webp',
};
