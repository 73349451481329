import { yupResolver } from '@hookform/resolvers/yup';
import { Row } from '@tanstack/react-table';
import { usePublishChanges } from 'api/commits';
import { UnCommitedChanges } from 'api/commits/types';
import useGetMe from 'api/profile/get-me';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { ScrollArea } from 'components/shadcn/scroll-area';
import { CircleChevronDown, CornerDownRight } from 'lucide-react';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type FormChanges = {
  message: string;
};

function PublishModal({
  isOpen,
  onClose,
  selectedRows,
  setSelectedRows,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedRows: Row<UnCommitedChanges>[];
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Row<UnCommitedChanges>[]>
  >;
}) {
  const toast = useCustomToast();
  const { appId } = useAuthStore(state => state);
  const selectedChanges = selectedRows.map?.(row => row.original);
  const getMe = useGetMe();
  const clientName = getMe.data?.clientUser?.firstName;
  const postCommits = usePublishChanges();

  const schema = yup.object({
    message: yup.string().required('message is required'),
  });

  const defaultValues = React.useMemo(
    () => ({
      message: '',
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormChanges>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleClose = () => {
    reset(defaultValues);
    setSelectedRows([]);
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormChanges> = values => {
    const data = {
      message: values.message,
      appId,
      changes: selectedChanges.map(selected => ({
        entityType: selected.entityType,
        entityId: Number(selected.entityId),
      })),
    };

    const finalActions = getFinalActions('changes published successfully');
    postCommits.mutate(data, finalActions);
    return;
  };

  return (
    <CustomModal
      modalContentClassName="!max-w-[516px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-3 text-2xl font-bold">Publish Your Changes</h1>

      <p className="mb-6 text-medium text-[#BFBFBF]">
        You're about to publish the selected workflow(s). Once published, you
        can test them in the development environment
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ScrollArea type="auto" className="h-24 w-full rounded-md border mb-4">
          <div className="p-4">
            <div className="flex gap-2">
              <CircleChevronDown className="w-4 h-4" />

              <h4 className="mb-4 text-base leading-none text-gray-970 font-semibold">
                Changes
              </h4>
            </div>

            <div className="flex gap-4 relative bottom-1 items-start">
              <CornerDownRight className="w-4 h-4 text-gray-820 relative left-1" />

              {selectedChanges?.length > 0 && (
                <div className="flex flex-col relative bottom-[1px]">
                  {selectedChanges?.map(changes => (
                    <div
                      key={changes?.entityId}
                      className="text-sm text-gray-820 font-semibold mb-1"
                    >
                      <span className="text-gray-970">
                        {changes?.clientUser?.firstName ?? clientName} edited{' '}
                      </span>

                      {changes?.name}
                    </div>
                  ))}
                </div>
              )}

              {selectedChanges?.length === 0 && (
                <div className="text-sm text-gray-970 font-semibold mb-1 relative bottom-[1px]">
                  All Unpublished Changes
                </div>
              )}
            </div>
          </div>
        </ScrollArea>

        {/* Name */}
        <InputField<FormChanges>
          variant="modal"
          extra="mb-3"
          label={'Message'}
          placeholder="Enter message"
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'message'}
        />
        {errors.message && <ErrorBox error={errors.message} />}

        <CustomModalButtons
          handleClose={handleClose}
          loading={postCommits.isLoading}
          primaryButtonText="Publish"
        />
      </form>
    </CustomModal>
  );
}

export default PublishModal;
