import Bell from '@assets/img/3d/color/bell.png';
import { cn } from 'utils/class-merge';

export const NoData = ({
  className = '',
  emptyMessage,
  emptyMessageClassName = '',
}: {
  className?: string;
  emptyMessage?: string;
  emptyMessageClassName?: string;
}) => {
  return (
    <div
      className={`flex flex-col items-center justify-center py-14 relative top-5 ${className}`}
    >
      <div className="mb-2.5 flex items-center justify-center">
        <img
          src={Bell}
          className="text-inherit csm-filter-gray-400"
          width="120px"
          alt=""
        />
      </div>
      <div className="flex items-center justify-center">
        <p
          className={cn(
            'relative bottom-1 mt-2 text-xl font-semibold text-white/70',
            emptyMessageClassName,
          )}
          dangerouslySetInnerHTML={{
            __html: emptyMessage ?? 'No data',
          }}
        ></p>
      </div>
    </div>
  );
};
