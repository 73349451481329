import { DateRange } from 'react-day-picker';
import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { getAnalyticsDateParams } from '../functions';
import { ChannelAnalytics } from '../get-channel-analytics/types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number, string, DateRange]>) => {
  const [, appId, templateId, date] = queryKey;
  const params = getAnalyticsDateParams(date);
  if (!params) return null;

  const { data } = await axiosInstance.get<ChannelAnalytics[]>(
    `${routes['analytics']}/apps/${appId}/templates/${templateId}/channels`,
    {
      params,
    },
  );

  return data;
};

const useGetTemplateChannelDetailsAnalytics = (
  appId: number,
  templateId: string,
  date: DateRange,
) => {
  return useQuery({
    queryKey: [
      `${routes['analytics']}/templates/channels`,
      appId,
      templateId,
      date,
    ],
    queryFn: get,
    enabled: Boolean(appId) && Boolean(templateId),
  });
};

export default useGetTemplateChannelDetailsAnalytics;
