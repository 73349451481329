import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { Path, UseFormSetValue, useFormContext } from 'react-hook-form';
import { BlockInput } from '../form/BlockInput';

const WorkflowIntervalInputs = <T,>({
  name,
  inputLabel,
  blockHeading,
  blockText,
}: {
  name: Path<T>;
  handleChange: () => void;
  setValue: UseFormSetValue<T>;
  inputLabel?: string;
  blockHeading?: string;
  blockText: string;
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext();

  const fieldError = (errors as any)[name];

  return (
    <div className="flex flex-col gap-1">
      <div className="grid grid-cols-2 gap-2">
        <InputField
          variant="workflow"
          extra="mb-0"
          label={inputLabel}
          placeholder="Enter interval"
          type="text"
          register={register}
          name={name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const numberValue: any = value.replace(/\D/, '');
            setValue(name, numberValue, {
              shouldValidate: true,
            });
          }}
        />

        <BlockInput heading={blockHeading} text={blockText} />
      </div>

      {fieldError && <ErrorBox className="mb-1" error={fieldError} />}
    </div>
  );
};

export default WorkflowIntervalInputs;
