import * as yup from 'yup';

export const passwordSchema = yup.object({
  currentPassword: yup.string().required('Current Password is required'),
  newPassword: yup.string().required('New Password is required'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});

export const profileInformationSchema = yup.object({
  firstName: yup.string().required('Username is required'),
});

const FILE_SIZE = 1024 * 1024 * 8; // 8mb

export const profilePicSchema = yup.object().shape({
  file: yup
    .mixed()
    .required('profile picture is required')
    .test('required', 'profile picture is required', (value: any) => {
      if (Boolean(value.length)) {
        return true; // not blocked
      } else {
        return false; // blocked
      }
    })
    .test('fileSize', 'File is too large, max size is 8MB', (value: any) => {
      const fileSize = value?.[0]?.size;

      if (fileSize && fileSize >= FILE_SIZE) {
        return false; // blocked
      } else {
        return true; // not blocked
      }
    }),
});
