import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number, string]>): Promise<any> => {
  const [, appId, identifier] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/users/${identifier}/preferences`,
  );

  return data;
};

const useGetUserPreference = ({ identifier }: { identifier: string }) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/user-preference`, appId, identifier],
    queryFn: get,
    enabled: Boolean(appId && identifier),
  });
};

export default useGetUserPreference;
