import { useParams } from 'react-router-dom';
import { TEMPLATE_EDITOR_TYPES } from '../variables/constants';

export const useGetEditorType = () => {
  const params = useParams();
  const templateEditorType = params.type;
  const editorTypeTemplate =
    templateEditorType === TEMPLATE_EDITOR_TYPES.TEMPLATE;
  const editorTypeWorkflow =
    templateEditorType === TEMPLATE_EDITOR_TYPES.WORKFLOW;

  return {
    editorTypeTemplate,
    editorTypeWorkflow,
  };
};
