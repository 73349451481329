import clsx from 'clsx';
import React from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import {
  Control,
  Controller,
  FieldValues,
  Path,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { AiTwotoneDelete } from 'react-icons/ai';

export const CustomDropZone = <T,>({
  content,
  control,
  name,
  required,
  setValue,
  accept = {
    'image/png': ['.png', '.jpeg', '.jpg'],
  },
  disabled,
  className,
  showAcceptedFiles = true,
  handleOnDrop,
}: {
  content: (open: () => void, isDragActive: boolean) => JSX.Element;
  control: Control<T, FieldValues>;
  name: Path<T>;
  required?: boolean;
  setValue?: UseFormSetValue<T>;
  accept?: Accept;
  disabled?: boolean;
  className?: string;
  showAcceptedFiles?: boolean;
  handleOnDrop?: (acceptedFiles: any) => void;
}) => {
  const selectedFiles = useWatch({
    control,
    name: name,
  });
  const onDrop = React.useCallback(
    (acceptedFiles: any) => {
      setValue(name, acceptedFiles, {
        shouldValidate: true,
      });
    },
    [name, setValue],
  );

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: handleOnDrop ? handleOnDrop : onDrop,
    noClick: true,
    accept,
  });

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required }}
      render={({ field }) => (
        <div
          className={`relative flex w-full cursor-pointer items-end justify-center rounded-xl`}
          {...getRootProps({
            className: clsx('dropzone w-full flex relative', className),
          })}
        >
          <input
            {...getInputProps({
              id: name,
              onChange: field.onChange,
              onBlur: field.onBlur,
            })}
          />
          <button
            type="button"
            className={`!w-full ${disabled ? 'cursor-not-allowed' : ''} `}
          >
            {' '}
            {content(open, isDragActive)}{' '}
          </button>

          {/* Accepted Files Preview Array of Elements */}
          {showAcceptedFiles &&
            Array.isArray(selectedFiles) &&
            (selectedFiles as File[])?.length && (
              <div className="absolute bottom-0 w-full">
                {(selectedFiles as File[])?.map((file, idx) => (
                  <AcceptedFiles
                    setValue={setValue}
                    name={name}
                    key={idx}
                    file={file}
                    open={open}
                    disabled={disabled}
                  />
                ))}
              </div>
            )}

          {/* Accepted Files Preview String */}
          {showAcceptedFiles &&
            typeof selectedFiles === 'string' &&
            Boolean(selectedFiles) && (
              <div className="absolute bottom-0 w-full">
                <AcceptedFiles
                  setValue={setValue}
                  name={name}
                  file={selectedFiles}
                  open={open}
                  disabled={disabled}
                />
              </div>
            )}
        </div>
      )}
    />
  );
};

const AcceptedFiles = <T,>({
  file,
  setValue,
  name,
  open,
  disabled,
}: {
  file: File | string;
  setValue: UseFormSetValue<T>;
  name?: Path<T>;
  open: () => void;
  disabled?: boolean;
}) => {
  return (
    <div
      className={`flex items-center justify-between rounded-lg bg-night-100 px-4 py-2 ${
        disabled ? 'cursor-not-allowed bg-[#31343a] text-white/[0.15]' : ''
      }`}
    >
      <button
        type="button"
        disabled={disabled}
        onClick={open}
        className={`flex w-full ${disabled ? 'cursor-not-allowed' : ''}`}
      >
        <div className="flex items-center gap-3">
          <img
            className="mx-auto h-5 w-5 cursor-pointer rounded-lg"
            src={typeof file === 'string' ? file : URL.createObjectURL(file)}
            alt=""
          />

          <p className="text-sm">
            {typeof file === 'string' ? 'Uploaded File' : file?.name}
          </p>
        </div>
      </button>

      <button
        disabled={disabled}
        onClick={() => setValue(name, null)}
        type="button"
        className={disabled ? 'cursor-not-allowed' : ''}
      >
        <AiTwotoneDelete className="h-6 w-6 text-red-500" />
      </button>
    </div>
  );
};
