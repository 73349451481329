import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetScheduledNotifications } from './types';

// get
const getScheduled = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<
  GetScheduledNotifications[]
> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/scheduled-notifications`,
  );
  return data;
};

export const useGetScheduled = () => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/scheduled-notifications`, appId],
    queryFn: getScheduled,
    enabled: Boolean(appId),
  });
};

// delete
const deleteScheduled = async (values: {
  appId: number;
  scheduledId: number;
}) => {
  const { appId, scheduledId } = values;
  const url = `${routes['apps']}/${appId}/scheduled-notifications/${scheduledId}`;
  return axiosInstance.delete(url);
};

export const useDeleteScheduled = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteScheduled,
    onSuccess: () => {
      queryClient.invalidateQueries([
        `${routes['apps']}/scheduled-notifications`,
      ]);
    },
  });
};
