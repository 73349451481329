import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';

type TPostAddApps = {
  clientId: number;
  name: string;
};

interface PostAddAppsResponse {
  clientId: number;
  apiKey: string;
  id: number;
  name: string;
}

const post = async (values: TPostAddApps) => {
  const { clientId, name } = values;
  const data = {
    name,
  };

  return axiosInstance.post<PostAddAppsResponse>(
    `${routes['clients']}/${clientId}/apps`,
    data,
  );
};

const usePostAddApps = () => {
  const { setAppId, clientId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: post,
    onSuccess: response => {
      setAppId(response.data.id);
      queryClient.invalidateQueries([`${routes['clients']}/apps`, clientId]);
    },
  });
};

export default usePostAddApps;
