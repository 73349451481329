// import { PricingTableLegacy } from "./PricingTableLeacy";
import { CustomModal } from 'components/modal';
import PricingTable from './PricingTable';

function PricingModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <CustomModal
      modalCardClassName="px-[30px] !max-h-[82vh] !w-full !max-w-[80vw] pt-[35px] mx-auto !w-max-[calc(80vw)] overflow-auto pb-[40px] relative"
      modalContentClassName="!top-[35%] !max-w-[100%] !w-[100%]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h3 className="mb-2 text-xl font-medium">Upgrade Subscription</h3>
      <p className="mb-2 text-sm text-[#BFBFBF]">
        Visit our{' '}
        <a
          href="https://engagespot.co/pricing/"
          target="_blank"
          className="text-brand-500"
          rel="noreferrer"
        >
          pricing page
        </a>{' '}
        for detailed comparisons
      </p>
      {/* Stripe Payment */}
      <PricingTable handleCloseModal={handleClose} />

      {/* <PricingTableLegacy
          paymentModalOpen={paymentModalOpen}
          setPaymentDetails={setPaymentDetails}
          handleCloseModal={handleClose}
          /> */}
    </CustomModal>
  );
}

export default PricingModal;
