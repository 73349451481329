import { useDisclosure } from '@chakra-ui/hooks';
import useGetLicense from 'api/billing/get-license';
import useGetSetupIntent from 'api/billing/get-setup-intent';
import Plus from 'assets/svg/billing/plus.svg';
import { useAuthStore } from 'store/authStore';
import StripePaymentModal from 'views/admin/dashboards/billing/components/StripePaymentModal';
import { StripePromise } from 'views/admin/dashboards/billing/types';

function NewCard({ promise }: { promise: StripePromise }) {
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const getSetupIntent = useGetSetupIntent(
    license.data.InstanceClientIdentifer,
  );
  const paymentModalActions = useDisclosure();

  return (
    <>
      <StripePaymentModal
        isOpen={paymentModalActions.isOpen}
        onClose={paymentModalActions.onClose}
        paymentDetails={{
          mode: 'setup',
          paymentIntent: getSetupIntent.data,
          newSubscription: null,
        }}
        promise={promise}
      />

      <button
        onClick={paymentModalActions.onOpen}
        className="grid h-32 w-full place-content-center rounded-xl bg-gray-850 bg-cover bg-no-repeat px-[20px] py-2 hover:bg-gray-850/60"
      >
        <div
          style={{
            boxShadow: '0px 8px 7px rgba(0, 0, 0, 0.16)',
          }}
          className="grid h-16 w-16 place-content-center rounded-full bg-[#3C414B]"
        >
          <img src={Plus} width={18} alt="" />
        </div>
      </button>
    </>
  );
}

export default NewCard;
