import { TEditorConfiguration } from 'email-editor/documents/editor/core';

export const DEFAULT_EMAIL_LAYOUT_JSON: TEditorConfiguration = {
  root: {
    type: 'EmailLayout',
    data: {
      backdropColor: '#F2F5F7',
      borderRadius: 0,
      canvasColor: '#F2F5F7',
      textColor: '#242424',
      fontFamily: 'MODERN_SANS',
      childrenIds: ['block-1727954772985'],
    },
  },
  'block-1727954772985': {
    type: 'Container',
    data: {
      style: {
        backgroundColor: '#ffffff',
        borderRadius: 8,
        padding: {
          top: 16,
          bottom: 16,
          right: 24,
          left: 24,
        },
      },
      props: {
        childrenIds: [
          'block-1727954781118',
          'block-1727954805349',
          'block-1727954817136',
          'block-1727954813399',
        ],
      },
    },
  },
  'block-1727954781118': {
    type: 'Heading',
    data: {
      props: {
        text: 'Header',
      },
      style: {
        padding: {
          top: 16,
          bottom: 16,
          right: 24,
          left: 24,
        },
      },
    },
  },
  'block-1727954805349': {
    type: 'Text',
    data: {
      style: {
        fontWeight: 'normal',
        padding: {
          top: 16,
          bottom: 16,
          right: 24,
          left: 24,
        },
      },
      props: {
        text: '{{body}}',
      },
    },
  },
  'block-1727954813399': {
    type: 'Text',
    data: {
      style: {
        fontSize: 10,
        fontWeight: 'normal',
        textAlign: 'center',
        padding: {
          top: 16,
          bottom: 16,
          right: 24,
          left: 24,
        },
      },
      props: {
        text: '(c) My Company 2024',
      },
    },
  },
  'block-1727954817136': {
    type: 'Divider',
    data: {
      style: {
        padding: {
          top: 16,
          right: 0,
          bottom: 16,
          left: 0,
        },
      },
      props: {
        lineColor: '#CCCCCC',
      },
    },
  },
};

export const DEFAULT_EMAIL_LAYOUT_HTML = {
  html: `<!doctype html>
<html>
  <body>
    <div
      style='background-color:#F2F5F7;color:#242424;font-family:"Helvetica Neue", "Arial Nova", "Nimbus Sans", Arial, sans-serif;font-size:16px;font-weight:400;letter-spacing:0.15008px;line-height:1.5;margin:0;padding:32px 0;min-height:100%;width:100%'
    >
      <table
        align="center"
        width="100%"
        style="margin:0 auto;max-width:600px;background-color:#F2F5F7;border-radius:0"
        role="presentation"
        cellspacing="0"
        cellpadding="0"
        border="0"
      >
        <tbody>
          <tr style="width:100%">
            <td>
              <div
                style="background-color:#ffffff;border-radius:8px;padding:16px 24px 16px 24px"
              >
                <h2
                  style="font-weight:bold;margin:0;font-size:24px;padding:16px 24px 16px 24px"
                >
                  Header
                </h2>
                <div style="font-weight:normal;padding:16px 24px 16px 24px">
                  {{body}}
                </div>
                <div style="padding:16px 0px 16px 0px">
                  <hr
                    style="width:100%;border:none;border-top:1px solid #CCCCCC;margin:0"
                  />
                </div>
                <div
                  style="font-size:10px;font-weight:normal;text-align:center;padding:16px 24px 16px 24px"
                >
                  (c) My Company 2024
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </body>
</html>`,
};
