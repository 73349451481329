import { useDisclosure } from '@chakra-ui/hooks';
import CustomSetUp from 'components/actions/CustomSetUp';
import Card from 'components/card';
import DisableTwoFactorModal from './DisableTwoFactorModal';
import MobileNumberModal from './MobileNumberModal';
import QrCodeModal from './QrCodeModal';
import useGetMe from 'api/profile/get-me';
import React from 'react';
import { QrCodeModalType } from '../types';
import GenerateTwoFactorRecoveryModal from './GenerateTwoFactorRecoveryModal';
import { usePlanLimit } from 'utils/use-plan-limit';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';

type ActionType =
  | 'qrcode'
  | 'mobile'
  | 'disable-2fa'
  | 'generate-recovery-codes';
type ActionButtonType = { type: ActionType };

const TwoFactor = () => {
  const qrcodeModalActions = useDisclosure();
  const mobileModalActions = useDisclosure();
  const disableTwoFactorModalActions = useDisclosure();
  const generateTwoFactorRecoveryModalActions = useDisclosure();
  const getMe = useGetMe();
  const isTwoFactorEnabled = getMe.data?.clientUser?.isTwoFactorEnabled;
  const [modalType, setModalType] = React.useState<QrCodeModalType>('scan');
  const [recoveryCodes, setRecoveryCodes] = React.useState<string[]>([]);
  const planLimit = usePlanLimit();

  const ActionButtons = ({ type }: ActionButtonType) => {
    const handleClick = () => {
      if (!planLimit.limits.twoFactorAuth) {
        planLimit.actions.onOpen();
        return planLimit.actions.setText(planLimit.texts.not_supported);
      }

      if (type === 'qrcode') {
        qrcodeModalActions.onOpen();
      }

      if (type === 'mobile') {
        mobileModalActions.onOpen();
      }

      if (type === 'disable-2fa') {
        disableTwoFactorModalActions.onOpen();
      }

      if (type === 'generate-recovery-codes') {
        generateTwoFactorRecoveryModalActions.onOpen();
      }
    };

    return (
      <>
        <button
          onClick={handleClick}
          className={cn(
            buttonVariants({
              className: 'rounded-md text-base disabled:opacity-75',
            }),
          )}
        >
          {type === 'disable-2fa'
            ? 'Disable'
            : type === 'generate-recovery-codes'
              ? 'Generate'
              : 'Set up'}
        </button>
      </>
    );
  };

  return (
    <>
      <QrCodeModal
        isOpen={qrcodeModalActions.isOpen}
        onClose={qrcodeModalActions.onClose}
        modalType={modalType}
        recoveryCodes={recoveryCodes}
        setModalType={setModalType}
        setRecoveryCodes={setRecoveryCodes}
      />

      <MobileNumberModal
        isOpen={mobileModalActions.isOpen}
        onClose={mobileModalActions.onClose}
      />

      <DisableTwoFactorModal
        isOpen={disableTwoFactorModalActions.isOpen}
        onClose={disableTwoFactorModalActions.onClose}
      />

      <GenerateTwoFactorRecoveryModal
        isOpen={generateTwoFactorRecoveryModalActions.isOpen}
        onClose={generateTwoFactorRecoveryModalActions.onClose}
        setModalType={setModalType}
        setRecoveryCodes={setRecoveryCodes}
        qrCodeModalonOpen={() => qrcodeModalActions.onOpen()}
      />

      <Card extra={'w-full  py-8'}>
        {/* Header */}
        <div className="mb-8 flex flex-col items-start justify-between gap-2 px-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            Two-Factor Authentication
          </h4>
          <span className="text-gray-600">
            Two - factor authentication addds an extra layer of security to your
            account by asking for a verification cose when you sign in
          </span>
        </div>

        {/* Two-factor methods */}
        <div className="mb-5">
          <h3 className="bg-night-100/30 px-8 py-5 text-lg font-bold text-navy-700 dark:text-white">
            Two-factor methods
          </h3>

          <CustomSetUp<ActionButtonType>
            name="Authenticator App"
            py="py-5"
            actionButtons={ActionButtons}
            actionProps={
              isTwoFactorEnabled ? { type: 'disable-2fa' } : { type: 'qrcode' }
            }
            chipText={isTwoFactorEnabled ? 'Configured' : ''}
          />
        </div>

        {/* Recovery Methods */}
        {isTwoFactorEnabled && (
          <div className="">
            <h3 className="bg-night-100/30 px-8 py-5 text-lg font-bold text-navy-700 dark:text-white">
              Recovery Methods
            </h3>

            <CustomSetUp<ActionButtonType>
              name="Recovery codes"
              py="py-5"
              actionButtons={ActionButtons}
              actionProps={{ type: 'generate-recovery-codes' }}
            />
          </div>
        )}

        {/* <CustomSetUp<ActionButtonType>
          py="py-5"
          name="Mobile Number"
          actionButtons={ActionButtons}
          actionProps={{ type: 'mobile' }}
        /> */}
      </Card>
    </>
  );
};

export default TwoFactor;
