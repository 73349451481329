import { useQueryClient } from '@tanstack/react-query';
import { GetProviders } from 'api/providers/get-providers/types';
import useSetProviderStatus, {
  SetProviderStatus,
} from 'api/providers/set-provider-status';
import { routes } from 'api/routes';
import Dropdown from 'components/dropdown';
import React from 'react';
import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';

type ProviderActions = 'provider_rule' | 'provider_toggle' | 'provider_edit';

function ProviderActionMenu(props: {
  transparent?: boolean;
  isEditable?: boolean;
  provider: GetProviders['configuredProviders'][0];
  vertical?: boolean;
  buttonClass?: string;
  handleConfigModalOpen: () => void;
}) {
  const setProviderStatus = useSetProviderStatus();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { appId } = useAuthStore(state => state);
  const [openWrapper, setOpenWrapper] = React.useState(false);

  const {
    transparent,
    vertical,
    buttonClass,
    handleConfigModalOpen,
    provider,
  } = props;

  const handleMenuClick = (actionType: ProviderActions) => {
    if (actionType === 'provider_edit') {
      handleConfigModalOpen();
      setOpenWrapper(false);
    }

    if (actionType === 'provider_toggle') {
      const status = provider.enabled ? 'disable' : 'enable';

      const submitData: SetProviderStatus = {
        channel: provider.channel,
        appId,
        providerIdentifier: String(provider.id),
        type: status,
      };

      setOpenWrapper(false); // optimistic

      setProviderStatus.mutate(submitData, {
        onSuccess: () => {
          toast.success(`provider ${status} successful`);
          queryClient.invalidateQueries([`${routes['apps']}/providers`]);
        },
        onError: error => {
          toast.showError(error);
        },
      });
    }
  };

  return (
    <Dropdown
      openWrapper={openWrapper}
      setOpenWrapper={setOpenWrapper}
      button={
        <button
          className={`flex items-center text-xl hover:cursor-pointer ${
            transparent
              ? 'bg-none text-white hover:bg-none active:bg-none'
              : vertical
                ? 'bg-none text-gray-450 dark:text-white'
                : ' p-2 text-brand-500 dark:bg-night-100 dark:text-white'
          } linear justify-center rounded-[20px] font-bold ${buttonClass}`}
          onClick={e => e.stopPropagation()}
        >
          {vertical ? (
            <p className="text-[24px] hover:cursor-pointer">
              <BsThreeDotsVertical />
            </p>
          ) : (
            <BsThreeDots className="h-6 w-6" />
          )}
        </button>
      }
      animation={'origin-[90%_-15%] transition-all duration-300 ease-in-out'}
      classNames={`${transparent ? 'top-8' : 'top-9'} right-0 w-max`}
      children={
        <div
          onClick={e => e.stopPropagation()}
          className="z-50 w-32 items-center rounded-xl border border-[#434343] bg-white px-3 py-2 text-sm shadow-xl shadow-shadow-500 dark:!bg-gray-850 dark:shadow-none"
        >
          {/* <button
            onClick={() => handleMenuClick("provider_rule")}
            className="flex w-full cursor-pointer items-center gap-2 rounded-xl p-2 px-4 font-medium text-white  hover:bg-black"
          >
            Rule
          </button> */}
          <button
            onClick={() => handleMenuClick('provider_edit')}
            className="mt-2 flex w-full cursor-pointer items-center gap-2 rounded-xl p-2 px-4 pt-2 font-medium text-white  hover:bg-black"
          >
            Edit
          </button>
          <button
            onClick={e => {
              e.stopPropagation();
              handleMenuClick('provider_toggle');
            }}
            className="mt-2 flex w-full cursor-pointer items-center gap-2 rounded-xl p-2 px-4 pt-2 font-medium text-white  hover:bg-black"
          >
            {provider.enabled ? 'Disable' : 'Enable'}
          </button>
        </div>
      }
    />
  );
}

export default ProviderActionMenu;
