import Card from 'components/card';
import { saveAs } from 'file-saver';
import { QrCodeModalType } from '../types';
import { CustomModalButtons } from 'components/modal';

const RecoveryQrCode = ({
  onClose,
  recoveryCodes,
  setRecoveryCodes,
  setModalType,
}: {
  onClose: () => void;
  recoveryCodes: string[];
  setRecoveryCodes: React.Dispatch<React.SetStateAction<string[]>>;
  setModalType: React.Dispatch<React.SetStateAction<QrCodeModalType>>;
}) => {
  const handleClose = () => {
    setRecoveryCodes([]);
    setModalType('scan');
    onClose();
  };

  const onSubmit = () => {
    const recoveryCodesAsString = recoveryCodes?.join?.('\n');
    const blob = new Blob([recoveryCodesAsString], {
      type: 'text/plain;charset=utf-8',
    });

    saveAs(blob, 'engagespot-recovery-codes.txt');
  };

  const List = ({ data }: { data: string[] }) => {
    return (
      <ul className="flex list-disc flex-col items-center text-lg font-semibold">
        {data?.map(item => (
          <li key={item} className="mb-1">
            {item}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Card extra="py-6 px-6 max-w-[475px] max-h-[600px] overflow-scroll flex flex-col !z-[1004] dark:!bg-night-100  border-2 border-[#26292F]">
      <div className="mb-4 w-full px-0">
        <h1 className="mb-2 text-center text-2xl font-semibold">
          Download your recovery codes
        </h1>
        <p className="mb-4 text-center text-xs font-semibold text-[#BFBFBF]">
          You can use recovery codes as a second factor to authenticate in case
          you lose access to your device. We recommend saving them somewhere
          secure
        </p>
        <p className="mb-6 text-center font-medium">
          Keep your recovery codes in a safe spot
        </p>

        <div className="grid h-52 grid-cols-2 rounded-lg bg-gray-850 p-7  text-white">
          <List data={recoveryCodes?.slice?.(0, 5)} />
          <List data={recoveryCodes?.slice?.(5, 10)} />
        </div>
      </div>

      <CustomModalButtons
        handleClose={handleClose}
        loading={false}
        primaryButtonText="Download"
        primaryButtonOnClick={onSubmit}
        containerClassName="!mt-5"
      />
    </Card>
  );
};

export default RecoveryQrCode;
