import { yupResolver } from '@hookform/resolvers/yup';
import usePostSendInvitation, {
  PostSendInvitationsResponse,
} from 'api/invitation/post-send-invitation';
import Email from 'assets/svg/common/email.svg';
// import Plus from 'assets/svg/common/plus.svg';
import InviteUser from 'assets/svg/teams/invite-user.svg';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { FormInviteUsers } from '../types';
import InvitationCard from './InvitationCard';
import { buttonVariants } from 'components/shadcn/button';
import { cn } from 'utils/class-merge';
import { Plus } from 'lucide-react';
import { usePlanLimit } from 'utils/use-plan-limit';
import { ERROR_CODES } from 'api/error-codes';

export const schema = yup.object({
  clientUserEmail: yup
    .string()
    .required('email is required')
    .email('must be a valid email'),
  role: yup.string().required('role is required'),
});

function AddMembersModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const planLimit = usePlanLimit();
  const postSendInvitation = usePostSendInvitation();
  const toast = useCustomToast();
  const { clientId } = useAuthStore(state => state);
  const [invitations, setInvitations] = React.useState<FormInviteUsers[]>([]);
  const [sendInviteResponse, setSendInviteResponse] =
    React.useState<PostSendInvitationsResponse>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    setError,
    control,
  } = useForm<FormInviteUsers>({
    resolver: yupResolver(schema),
    defaultValues: {
      role: 'admin',
      clientUserEmail: '',
    },
  });

  const onSubmit: SubmitHandler<FormInviteUsers> = values => {
    const alreadyAddedEmail = invitations.find(
      invite => invite.clientUserEmail === values.clientUserEmail,
    );

    if (alreadyAddedEmail) {
      return setError('clientUserEmail', {
        message: 'email already in invitational list',
      });
    }

    reset({
      clientUserEmail: '',
    });

    setInvitations([...invitations, values]);
  };

  const handleAddClientUser = () => {
    const data = {
      clientId,
      data: invitations,
    };

    postSendInvitation.mutate(data, {
      onSuccess: response => {
        if (response.data?.failedInvitations?.length === 0) {
          toast.success('invitations send');
          handleClose();
        } else {
          if (response.data.succeededInvitations.length > 0) {
            toast.success('some invitations send');
          }

          setSendInviteResponse(response.data);
        }
      },
      onError: (error: any) => {
        // Forbidden for free plan
        if (error?.response?.status === ERROR_CODES.forbidden) {
          handleClose();
          toast.showError(
            'To invite more users, please upgrade your subscription',
          );
          planLimit.actions.setText(planLimit.texts.invite_members);
          return planLimit.actions.onOpen();
        }

        toast.showError(error);
      },
    });
  };

  const handleClose = () => {
    clearErrors('clientUserEmail');
    reset({});
    onClose();
    setInvitations([]);
  };

  return (
    <CustomModal
      modalContentClassName="!min-w-[450px] !top-[32%]"
      modalCardClassName="w-[650px] px-[30px] pt-[35px] pb-[40px] w-[650px] max-h-[600px] overflow-y-auto max-w-[850px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-[20px] text-2xl font-bold">Invite team members</h1>
      <p className="mb-6 text-sm text-[#BFBFBF]">
        Add emails one by one and then click the Send Invite button
      </p>
      {!sendInviteResponse && (
        <form
          className="rounded-xl bg-gray-850 p-4"
          onSubmit={handleSubmit(onSubmit)}
        >
          {/* Parent Wrapper */}
          <div className="flex gap-3">
            {/* Input Wrapper */}
            <div className="flex w-[85%] items-center">
              {/* Email */}
              <div className="relative w-[78%]">
                <div className="absolute left-3 flex h-full w-5 items-center justify-center">
                  <img src={Email} alt="plus" width={16} />
                </div>
                <InputField<FormInviteUsers>
                  variant="auth"
                  extra=""
                  extraInputClass={
                    'pl-12 !border-0 dark:bg-night-100 !rounded-r-[0px] !rounded-l-xl !mt-0'
                  }
                  label=""
                  placeholder="Enter email"
                  type="text"
                  showIsRequiredAsterisk={true}
                  register={register}
                  name={'clientUserEmail'}
                />
              </div>

              {/* Role */}
              <SelectField
                variant="styled"
                name={'role'}
                control={control}
                placeholder=""
                extra={`w-[20%] self-end border-gray-440`}
                extraInputClass="dark:!bg-night-100 border-0 !rounded-l-none  dark:disabled:!bg-white/5 !rounded-r-lg h-[43px]"
                options={[
                  {
                    value: 'admin',
                    label: 'As Admin',
                  },
                  {
                    value: 'user',
                    label: 'As User',
                  },
                ]}
              />
            </div>

            {/* Submit  */}
            <div className="flex w-[15%] gap-2 items-center">
              <button
                // className="linear flex w-full items-center justify-center rounded-xl bg-brand-500 py-1 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
                className={cn(
                  buttonVariants(),
                  'w-full ml-auto py-1 items-center h-[90%] flex gap-2 text-base',
                )}
                type="submit"
                disabled={postSendInvitation.isLoading}
              >
                <Plus className="text-black w-4 h-4" />
                Add
              </button>
            </div>
          </div>

          {errors.clientUserEmail && (
            <ErrorBox error={errors.clientUserEmail} />
          )}
        </form>
      )}

      {/* Failed Invite Members Preview */}
      {sendInviteResponse && (
        <>
          <div>
            <div className="mb-5">
              <h1 className="mb-2 text-xl font-semibold text-red-500">
                Failed invitations
              </h1>

              <h3 className="text-sm font-semibold text-gray-700">
                Reason : {sendInviteResponse.message}
              </h3>
            </div>

            <div>
              {sendInviteResponse.failedInvitations.map((item, i) => (
                <div className="flex items-center gap-3">
                  <img src={InviteUser} alt="invite user" />

                  <div>
                    <h4 className="text-white">{item}</h4>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <button
            // className="linear ml-auto mt-4 flex w-auto justify-center rounded-xl bg-brand-500 px-3 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
            className={cn(buttonVariants(), 'w-fit ml-auto')}
            onClick={() => {
              setSendInviteResponse(null);
              setInvitations([]);
              onClose();
            }}
          >
            Close
          </button>
        </>
      )}

      {/* Invite Members Preview */}
      {!sendInviteResponse && invitations.length > 0 && (
        <>
          <div className="mb-5 mt-8 h-px bg-gray-200 dark:bg-white/10" />

          <div>
            <h1 className="mb-[20px] text-xl font-semibold">
              Emails yet to be invited
            </h1>

            <div>
              {invitations.map((item, i) => (
                <InvitationCard
                  invitations={invitations}
                  setInvitations={setInvitations}
                  item={item}
                  key={`invitation-${i}`}
                />
              ))}
            </div>
          </div>

          <button
            className={cn(buttonVariants(), 'w-fit ml-auto')}
            disabled={postSendInvitation.isLoading}
            onClick={handleAddClientUser}
          >
            Send Invite
            {postSendInvitation.isLoading && (
              <LoaderSpinner strokeColor="black" parentClass={'!left-0 ml-2'} />
            )}
          </button>
        </>
      )}
    </CustomModal>
  );
}

export default AddMembersModal;
