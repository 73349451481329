import AndroidBg from 'assets/svg/template-editor/android-bg.svg';
import AndroidLogoGray from 'assets/svg/template-editor/android-logo-gray.svg';
import AndroidLogoWhite from 'assets/svg/template-editor/android-logo-white.svg';
import AppleLogoGray from 'assets/svg/template-editor/apple-logo-gray.svg';
import AppleLogoWhite from 'assets/svg/template-editor/apple-logo-white.svg';
import Camera from 'assets/svg/template-editor/camera.svg';
import IphoneBg from 'assets/svg/template-editor/iphone-bg.svg';
import Lens from 'assets/svg/template-editor/lens.svg';
import { format } from 'date-fns';
import { motion } from 'framer-motion';
import React from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';
import { useMobileWebInAppTemplateDefaultPreviewSetup } from '../../hooks/commonTemplatePreviewSetup';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';

const MobilePushPreview = ({
  control,
}: {
  control: Control<FieldValues, any>;
}) => {
  const [device, setDevice] = React.useState<'iphone' | 'android'>('iphone');
  const isIphone = device === 'iphone';
  const savedTemplateData = useGetTemplateChannelData();

  const formValues = useWatch({
    control,
  });

  const cardColors = {
    android: 'bg-[#F3F3F3CC]/80',
    iphone: 'bg-[#222122CC]/80',
  };

  const handleSwitch = () => {
    setDevice(isIphone ? 'android' : 'iphone');
  };

  const { hasPreviewImage, previewImageSrc, previewMessage, previewTitle } =
    useMobileWebInAppTemplateDefaultPreviewSetup({
      formValues,
      savedTemplateData,
    });

  return (
    <>
      <div className="relative bottom-[66px] mx-auto flex w-[27%] flex-col items-center gap-6">
        {/* switch */}
        <button
          onClick={() => handleSwitch()}
          className={`rounded-[50px]; relative flex h-[39px] w-[78px] cursor-pointer ${
            isIphone ? 'justify-start' : 'justify-end'
          }  rounded-3xl bg-night-100 p-[3px]`}
        >
          {/* icons */}
          <img
            className={`absolute left-[10px] top-[22%] z-10`}
            src={isIphone ? AppleLogoWhite : AppleLogoGray}
            alt=""
          />
          <img
            className="absolute right-[8px] top-[23%] z-10"
            src={isIphone ? AndroidLogoGray : AndroidLogoWhite}
            alt=""
          />

          {/* handle */}
          <motion.div
            layout
            transition={{
              duration: 0.3,
            }}
            className="z-0 h-[33px] w-[33px] rounded-[40px] bg-[#01B574]"
          ></motion.div>
        </button>

        {/* Phone */}
        <div
          style={{
            background: `center/cover no-repeat url(${
              isIphone ? IphoneBg : AndroidBg
            })`,
          }}
          className="relative bottom-2 flex min-h-[490px] w-[250px] flex-col overflow-clip  rounded-[30px] border-[12px] border-black bg-white"
        >
          {/* header */}
          <div className="flex flex-col items-center justify-between pt-4">
            <div
              className={`relative ${
                isIphone ? 'justify-end' : 'justify-start'
              }`}
            >
              <motion.img
                layout
                transition={{
                  duration: 0.5,
                  ease: 'backOut',
                }}
                className="pointer-events-none relative mb-4 self-center"
                width={isIphone ? 65 : 15}
                src={isIphone ? Camera : Lens}
                alt=""
              />
            </div>

            <div
              className={`flex ${isIphone ? 'flex-col' : 'flex-col-reverse'} `}
            >
              <motion.p layout className="text-sm font-medium">
                {format(new Date(), 'iiii,d LLLL')}
              </motion.p>
              <motion.p
                layout
                className="text-center text-[2.5rem] font-medium"
              >
                {format(new Date(), 'KK:mm')}
              </motion.p>
            </div>
          </div>

          {/* middle content */}
          <div
            className={`relative top-4 flex h-[65%] flex-col ${
              isIphone ? 'justify-end' : 'justify-start'
            }`}
          >
            <motion.div
              layout
              transition={{
                duration: 0.5,
              }}
              className="relative"
            >
              {/* Card stack 1 */}
              <div
                className={`relative z-10 mx-auto w-[90%] rounded-lg ${
                  isIphone ? cardColors['iphone'] : cardColors['android']
                } p-2 py-2  text-[0.75rem] backdrop-blur-sm`}
              >
                {/* Image */}
                {hasPreviewImage ? (
                  <img
                    className="mb-1 h-6 w-6 rounded-full object-contain outline-none"
                    src={previewImageSrc as string}
                    alt=""
                  />
                ) : (
                  <div
                    className={`mb-1 h-6 w-6 rounded-full bg-[#A6BBD4]`}
                  ></div>
                )}

                <h4
                  className={`mb-0 font-semibold ${
                    isIphone ? 'text-white' : 'text-night-100'
                  } `}
                >
                  {previewTitle}
                </h4>
                <span
                  className={`text-[0.7rem] ${
                    isIphone ? 'text-white' : 'text-[#605D5DCC]'
                  } `}
                >
                  {previewMessage}
                </span>
              </div>

              {/* Card stack 2 */}
              <div
                className={`absolute -bottom-2 left-[8%] h-[52%] w-[83%] rounded-lg ${
                  isIphone ? cardColors['iphone'] : cardColors['android']
                } p-2 py-2 backdrop-blur-sm`}
              ></div>

              {/* Card stack 3 */}
              <div
                className={`absolute -bottom-4 left-[11%] h-[40%] w-[76%] rounded-lg ${
                  isIphone ? cardColors['iphone'] : cardColors['android']
                } p-2 py-2 backdrop-blur-sm`}
              ></div>
            </motion.div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobilePushPreview;
