import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetCategories } from './types';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetCategories[]> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/categories`,
  );
  return data;
};

const useGetCategories = () => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/categories`, appId],
    queryFn: get,
    enabled: Boolean(appId),
  });
};

export default useGetCategories;
