import Editor, { loader, OnValidate } from '@monaco-editor/react';
import {
  FieldError,
  Path,
  UseFormClearErrors,
  UseFormSetError,
} from 'react-hook-form';
import { cn } from 'utils/class-merge';

export const TextEditor = <T,>({
  name,
  extraLabelClass,
  label,
  showIsRequiredAsterisk,
  onChange,
  value,
  height = '150px',
  clearErrors,
  setError,
  error,
  editorBackground = '#111315',
  disabled = false,
  className = '',
  helperText,
  showLineNumbers = false,
}: {
  name: Path<T>;
  label?: string;
  showIsRequiredAsterisk?: boolean;
  extraLabelClass?: string;
  value: string;
  onChange(value: string): void;
  height?: string;
  setError: UseFormSetError<T>;
  clearErrors: UseFormClearErrors<T>;
  error: FieldError;
  editorBackground?: string;
  disabled?: boolean;
  className?: string;
  helperText?: string;
  showLineNumbers?: boolean;
}) => {
  const onValidate: OnValidate = markers => {
    if (error && markers.length === 0) {
      return clearErrors(name);
    }

    if (markers.length > 0) {
      const error = markers[0];
      setError(name, {
        message:
          `${error.message} at line ${error.startLineNumber}, column ${error.startColumn}` ??
          'Something went wrong',
      });
    }
  };

  loader.init().then(monaco => {
    monaco.editor.defineTheme('myTheme', {
      base: 'vs-dark',
      inherit: true,
      rules: [],
      colors: {
        'editor.background': disabled ? '#31343a' : editorBackground,
      },
    });
  });

  return (
    <>
      {label && (
        <label
          className={`relative top-[1.5px] ml-1.5 text-sm font-medium text-navy-700 dark:text-white ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}

      {helperText && (
        <p
          className="ml-1 text-sm text-white opacity-[35%]"
          dangerouslySetInnerHTML={{
            __html: helperText,
          }}
        ></p>
      )}

      <Editor
        height={height}
        language="json"
        theme="myTheme"
        value={value}
        options={{
          formatOnType: true,
          minimap: { enabled: false },
          lineNumbers: showLineNumbers ? 'on' : 'off',
          lineDecorationsWidth: 0,
          lineNumbersMinChars: 0,
        }}
        onChange={onChange}
        onValidate={onValidate}
        className={cn(
          'mt-[6px] rounded-md border border-gray-440',
          disabled && 'pointer-events-none disabled:cursor-not-allowed',
          className,
        )}
      />
    </>
  );
};
