import { Path, UseFormRegister } from 'react-hook-form';
import { cn } from 'utils/class-merge';

// Custom components
function InputField<T>(props: {
  id?: string;
  label?: string;
  extra?: string;
  placeholder?: string;
  variant?: 'dark' | 'modal' | 'auth' | 'workflow' | 'default';
  state?: string;
  disabled?: boolean;
  type?: string;
  onChange?: any;
  showIsRequiredAsterisk?: boolean;
  register?: UseFormRegister<T>;
  required?: boolean;
  extraInputClass?: string;
  name?: Path<T>;
  extraLabelClass?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  helperText?: string;
  customLabelContent?: () => JSX.Element;
  helperTextClassName?: string;
  value?: string;
}) {
  const {
    helperTextClassName,
    helperText,
    label,
    id,
    extra = '',
    type,
    placeholder = '',
    variant,
    state,
    disabled,
    onChange,
    showIsRequiredAsterisk = false,
    register,
    required,
    name,
    extraInputClass,
    extraLabelClass,
    onBlur,
    customLabelContent,
    value,
  } = props;

  const inputClass = `mt-2 flex h-12 w-full items-center justify-center rounded-md border bg-white/0 p-3 text-sm outline-none ${
    disabled === true
      ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)] text-[rgba(255,255,255,0.15)] disabled:cursor-not-allowed'
      : state === 'error'
        ? 'border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
        : state === 'success'
          ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
          : ''
  } ${
    variant === 'dark'
      ? 'border border-gray-440 !h-[48px] !rounded-md !bg-night-400 text-white !text-base dark:placeholder:!text-[rgba(255,255,255,0.28)]'
      : variant === 'modal'
        ? 'dark:!bg-gray-150 disabled:opacity-60 border border-gray-880 dark:text-white dark:placeholder:!text-[rgba(255,255,255,0.28)] focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0'
        : variant === 'workflow'
          ? 'dark:!bg-night-400 disabled:opacity-60 border border-gray-930 dark:text-white dark:placeholder:!text-[#ABB0B8] w-full'
          : variant === 'default'
            ? 'flex h-10 !mt-0 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50'
            : 'border dark:!bg-night-100 border-gray-440  dark:text-white dark:placeholder:!text-[rgba(255,255,255,0.28)]'
  } ${extraInputClass}`;

  return (
    <div className={`${extra}`}>
      {customLabelContent && customLabelContent()}

      {label && (
        <label
          htmlFor={id}
          className={`text-sm text-navy-700 dark:text-white ${
            variant === 'auth'
              ? 'ml-1.5 font-medium'
              : variant === 'modal'
                ? 'ml-1.5 font-medium'
                : variant === 'dark'
                  ? 'ml-1.5  !text-base font-normal dark:!text-gray-10'
                  : variant === 'workflow'
                    ? 'ml-1.5  !text-base font-medium dark:!text-gray-10'
                    : 'ml-3 font-bold'
          } ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && (
            <span
              className={
                variant === 'dark'
                  ? 'text-yellow-450 opacity-80 ml-1'
                  : 'text-brand-400'
              }
            >
              *
            </span>
          )}
        </label>
      )}

      {helperText && (
        <p
          className={cn(
            'ml-1 text-sm text-white opacity-[35%]',
            helperTextClassName,
          )}
          dangerouslySetInnerHTML={{
            __html: helperText,
          }}
        ></p>
      )}

      {register && name ? (
        <input
          disabled={disabled}
          type={type}
          id={id}
          placeholder={placeholder}
          className={inputClass}
          {...register(name, { required })}
          {...(onChange ? { onChange } : {})}
          {...(onBlur ? { onBlur } : {})}
        />
      ) : (
        <input
          onChange={onChange}
          disabled={disabled}
          type={type}
          id={id}
          placeholder={placeholder}
          className={inputClass}
          value={value}
        />
      )}
    </div>
  );
}

export default InputField;
