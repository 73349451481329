import { useGetCommit } from 'api/commits';
import { CommitDetails, PromotedCommits } from 'api/commits/types';
import CustomDiffViewer from 'components/diff-viewer';
import { CustomModal } from 'components/modal';
import { buttonVariants } from 'components/shadcn/button';
import { X } from 'lucide-react';
import React from 'react';
import { cn } from 'utils/class-merge';
import { stringifyJson } from 'utils/check-diff';
import { getRevertMessage } from '../functions';
import CustomEmailPreview from 'components/email-preview';

function PublishedDiffModal({
  isOpen,
  onClose,
  selected,
}: {
  onClose: () => void;
  isOpen: boolean;
  selected: PromotedCommits['results'][0];
}) {
  const getCommit = useGetCommit(selected?.commitId);
  const [selectedChange, setSelectedChange] =
    React.useState<CommitDetails['results'][0]>();
  const message = selected?.commit?.message;
  const revertMessage = getRevertMessage(message);

  React.useEffect(() => {
    if (getCommit.isLoading) return;
    setSelectedChange(getCommit.data?.results?.[0]);
  }, [getCommit.data?.results, getCommit.isLoading, isOpen]);

  const { newJson: changesJson } = stringifyJson({
    newValue: selectedChange?.change,
  });

  console.log({ selectedChange });

  const handleClose = () => {
    setSelectedChange(null);
    onClose();
  };

  return (
    <CustomModal
      modalContentClassName="min-w-[92vw] h-[92vh] top-[8%] outline-none"
      modalCardClassName={'max-h-[92vh] h-[92vh] px-0 py-0  overflow-hidden'}
      overlayClassName="outline-none"
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton={false}
      modalOverlayClick={handleClose}
    >
      <div className="flex flex-col border-b border-gray-880 px-8 py-6">
        <div className="flex justify-between  items-center ">
          <h1 className="text-lg">
            Showing{' '}
            <span className="font-semibold">
              {revertMessage
                ? `revert-changes-${revertMessage.slice(0, 8)}`
                : message}
            </span>{' '}
            Changes
          </h1>

          <button
            onClick={handleClose}
            className={buttonVariants({
              variant: 'default',
            })}
          >
            Close
            <X onClick={handleClose} className="w-4 h-4 ml-2" />
          </button>
        </div>
      </div>

      <div className="flex gap-3 rounded-lg mx-4 h-full py-3">
        <div className="flex flex-col gap-3 w-[20%] overflow-auto h-full">
          {getCommit.data?.results?.map((item, index) => (
            <button
              key={index}
              className={cn(
                buttonVariants({ variant: 'ghost', size: 'lg' }),
                'justify-start px-4 py-2 group',
                'csm-shake',
                'text-gray-10',
                selectedChange?.id === item?.id &&
                  'bg-muted font-bold text-white hover:bg-muted',
              )}
              onClick={() => {
                setSelectedChange(item);
              }}
            >
              {`${item.entityType} - ${item?.relatedEntity?.name}`}

              {item?.relatedEntity?.deletedAt && (
                <div className="text-xs text-red-20 bg-red-750 rounded-md px-2 py-1 font-semibold relative left-2">
                  Deleted
                </div>
              )}
            </button>
          ))}
        </div>

        <div className="h-full csm-react-diff overflow-auto w-[80%]  border border-gray-880">
          {changesJson && (
            <>
              {selectedChange.entityType === 'layout' && (
                <CustomEmailPreview
                  bodyJsonTemplate={selectedChange?.change?.bodyJsonTemplate}
                />
              )}

              {selectedChange.entityType !== 'layout' && (
                <CustomDiffViewer
                  splitView={false}
                  oldValue={
                    selectedChange?.relatedEntity?.deletedAt
                      ? changesJson
                      : null
                  }
                  newValue={
                    selectedChange?.relatedEntity?.deletedAt
                      ? null
                      : changesJson
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
    </CustomModal>
  );
}

export default PublishedDiffModal;
