import Bell from '@assets/svg/common/bell.svg';
import usePostVerifyEmail from 'api/verify-email/post-verify-email.';
import { AuthHeading, AuthText } from 'components/auth/AuthComponents';
import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';

function VerificationEmail() {
  const navigate = useNavigate();
  const toast = useCustomToast();
  const postVerifyEmail = usePostVerifyEmail();
  const [searchParams] = useSearchParams();
  const verificationCode = new URLSearchParams(searchParams).get(
    'activationHash',
  );
  const redirect = new URLSearchParams(searchParams).get('redirect');

  React.useEffect(() => {
    const verifyEmail = () => {
      const data = {
        verificationCode,
      };

      postVerifyEmail.mutate(data, {
        onSuccess: () => {
          toast.success('email verification success');

          if (Boolean(redirect) && redirect !== 'null') {
            return navigate(redirect);
          } else {
            return navigate('/admin/get-started');
          }
        },
        onError: error => {
          toast.showError(error);
          return navigate(`/auth/login`);
        },
      });
    };

    if (!verificationCode) {
      toast.error('no verification code found');
      return navigate('/auth/login');
    }

    if (verificationCode && !postVerifyEmail.isLoading) {
      verifyEmail();
    }
  }, [verificationCode, navigate, postVerifyEmail, toast, redirect]);

  return (
    <div className="flex h-[100vh] flex-col items-center justify-center ">
      <img className="relative bottom-7" src={Bell} alt="" />

      <AuthHeading text="Congratulations! 🥳 Your email has been successfully verified 🎉" />

      <AuthText
        className="mt-3 whitespace-pre-wrap text-center text-lg"
        text={`You're now all set to explore our platform \n and enjoy all the amazing features we offer.`}
      />
    </div>
  );
}

export default VerificationEmail;
