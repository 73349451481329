import { cn } from 'utils/class-merge';

const HeaderDoc = ({
  text,
  link,
  className,
  externalLink = true,
}: {
  text: string;
  link: string;
  className?: string;
  externalLink?: boolean;
}) => {
  return (
    <a
      className={cn(
        'border-b border-dashed border-gray-750 text-gray-750 ml-1 hover:border-white hover:text-white',
        className,
      )}
      href={link}
      rel="noreferrer"
      {...(externalLink && {
        target: '_blank',
      })}
    >
      {text}
    </a>
  );
};

export default HeaderDoc;
