import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { AnalyticsGraphData } from '../types';
import { NoData } from './NoData';
import { getPercentage } from 'utils/functions';

const getAnalyticsGraphData = ({ values }: { values: AnalyticsGraphData }) => {
  const categoryLength = values.categories.length;
  const optimalColumnWidthPercent =
    20 + 60 / (1 + 30 * Math.exp(-(0.1 * categoryLength) / 3));

  const totalNotificationSend = values.data.sent.reduce(
    (acc, curr) => acc + curr,
    0,
  );

  const sentPercentage = values.data.sent.map(item =>
    Number(getPercentage(item, totalNotificationSend)),
  );

  const seenPercentage = values.data.seen.map(item =>
    Number(getPercentage(item, totalNotificationSend)),
  );

  const interactedPercentage = values.data.interacted.map(item =>
    Number(getPercentage(item, totalNotificationSend)),
  );

  const data: { series: ApexOptions['series']; options: ApexOptions } = {
    series: [
      {
        name: 'Delivery Rate',
        data: sentPercentage,
        color: '#575181',
      },
      {
        name: 'Seen Rate',
        data: seenPercentage,
        color: '#FFC852',
      },
      {
        name: 'Click Rate',
        data: interactedPercentage,
        color: '#80F68B',
      },
    ],
    options: {
      chart: {
        type: 'bar' as const,
        height: 350,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 4,
          columnWidth: optimalColumnWidthPercent + '%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: values.categories,
        labels: {
          style: {
            colors: '#929497',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        forceNiceScale: false,
        max: 100,
        min: 0,
        tickAmount: 5,
        labels: {
          formatter: value => value.toFixed(0) + '%',
          style: {
            colors: 'white',
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        theme: 'dark',
        marker: {
          show: true,
          fillColors: ['white'],
        },
        y: {
          formatter: function (val: any) {
            return val + '%';
          },
        },
      },
      legend: {
        fontFamily: 'Inter',
        fontWeight: '400',
        position: 'top',
        horizontalAlign: 'right',
        labels: {
          colors: 'white',
        },
        markers: {
          radius: 999,
          offsetY: 2,
        },
      },
      grid: {
        show: false,
      },
    },
  };

  return data;
};

const AnalyticsLineChart = ({ values }: { values: AnalyticsGraphData }) => {
  const data = getAnalyticsGraphData({ values });
  const noData = values.categories.length === 0;

  return (
    <>
      {noData ? (
        <NoData
          emptyMessage={'Insights will appear once notifications are sent.'}
          emptyMessageClassName="text-lg text-center"
          className="top-16 "
        />
      ) : (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="bar"
          height={350}
        />
      )}
    </>
  );
};

export default AnalyticsLineChart;
