export const pieChartColors = [
  '#64CCC5',
  '#FED76A',
  '#2B7F75',
  '#176B87',
  '#571787',
  '#171D87',
  '#17873E',
  '#175A87',
  '#7C8717',
];
