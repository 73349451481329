import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import {
  DeleteTranslation,
  GetLocales,
  GetTranslations,
  PatchUpdateTranslationJson,
  PostCreateTranslation,
  PutEditTranslation,
  TranslationDetails,
} from './types';

type LocalesParams = { search: string };
type TranslationsParams = { search: string; page: number; limit: number };

const getLocales = async ({
  queryKey,
}: QueryFunctionContext<[string, LocalesParams]>): Promise<GetLocales> => {
  const [, params] = queryKey;
  const { data } = await axiosInstance.get(`${routes['locales']}`, {
    params,
  });
  return data;
};

const useGetLocales = (params: LocalesParams) => {
  return useQuery({
    queryKey: [`${routes['locales']}`, params],
    queryFn: getLocales,
  });
};

const getTranslations = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, TranslationsParams]
>): Promise<GetTranslations> => {
  const [, appId, params] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/translations`,
    {
      params,
    },
  );

  return data;
};

const useGetTranslations = (params?: TranslationsParams) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/translations`, appId, params],
    queryFn: getTranslations,
  });
};

const postCreateTranslations = async (values: PostCreateTranslation) => {
  const { appId, ...data } = values;
  return axiosInstance.post(`${routes['apps']}/${appId}/translations`, data);
};

const usePostCreateTranslations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: postCreateTranslations,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/translations`]);
    },
  });
};

const putEditTranslations = async (values: PutEditTranslation) => {
  const { translationId, ...data } = values;
  return axiosInstance.put(`${routes['translations']}/${translationId}`, data);
};

const usePutEditTranslations = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putEditTranslations,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/translations`]);
    },
  });
};

const deleteTranslation = async (values: DeleteTranslation) => {
  const { translationId } = values;
  return axiosInstance.delete(`${routes['translations']}/${translationId}`);
};

const useDeleteTranslation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteTranslation,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/translations`]);
    },
  });
};

const getTranslationDetails = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<TranslationDetails> => {
  const [, translationId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['translations']}/${translationId}`,
  );
  return data;
};

const useGetTranslationDetails = (translationId: number, enabled = true) => {
  return useQuery({
    queryKey: [`${routes['translations']}/details`, translationId],
    queryFn: getTranslationDetails,
    enabled: Boolean(translationId) && enabled,
  });
};

const patchTranslationJson = async (values: PatchUpdateTranslationJson) => {
  const { translationId, ...data } = values;
  return axiosInstance.patch(
    `${routes['translations']}/${translationId}`,
    data,
  );
};

const usePatchUpdateTranslationJson = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchTranslationJson,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['translations']}/details`]);
    },
  });
};

const setDefaultTranslation = async (values: DeleteTranslation) => {
  const { translationId } = values;
  return axiosInstance.put(
    `${routes['translations']}/${translationId}/default`,
  );
};

const useSetDefaultTranslation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setDefaultTranslation,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/translations`]);
    },
  });
};

const getDefaultTranslation = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<
  GetTranslations['results'][0]
> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/translations/default`,
  );
  return data;
};

const useGetDefaultTranslation = () => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['translations']}/default`, appId],
    queryFn: getDefaultTranslation,
  });
};

export {
  useGetLocales,
  useGetTranslations,
  usePostCreateTranslations,
  usePutEditTranslations,
  useDeleteTranslation,
  usePatchUpdateTranslationJson,
  useSetDefaultTranslation,
  useGetTranslationDetails,
  useGetDefaultTranslation,
};
