import Card from 'components/card';
import Dash from 'components/dash';
import ChannelsCard from './components/ChannelsCard';
import useGetChannels from 'api/channels/get-channels';
import LoaderModal from 'components/loader/LoaderModal';
import { useDisclosure } from '@chakra-ui/hooks';
import PageHeader from 'components/header/PageHeader';
import HeaderDoc from 'components/header/HeaderDoc';

const DashboardChannels = () => {
  const getChannels = useGetChannels();
  const loaderModalActions = useDisclosure();
  const configuredChannels = getChannels.data?.filter(
    channel => channel.configuredProviders?.length > 0,
  );
  const availableChannels = getChannels.data?.filter(
    channel => channel.configuredProviders?.length === 0,
  );

  return (
    <>
      {!getChannels.isFetched && (
        <LoaderModal
          isOpen={getChannels.isLoading}
          onClose={loaderModalActions.onClose}
        />
      )}

      {/* Header */}
      <PageHeader
        heading={'Channel'}
        text={
          <>
            Manage messaging channels. Read{' '}
            <HeaderDoc
              link="https://docs.engagespot.com/docs/features/channels/concepts/understanding"
              text="this guide"
              className="mr-1"
            />
            to learn how to configure channels
          </>
        }
      />

      {/* Content */}
      <Card extra={'pb-8 px-6 pt-6 w-full'}>
        {/* Configured */}
        {Boolean(configuredChannels?.length) && (
          <div className="mb-10">
            {/* Heading */}
            <div className="flex items-center gap-2">
              <h4 className="text-lg font-medium">Configured</h4>
              <Dash />
            </div>

            {/* card container */}
            <div className="mt-6 grid grid-cols-3 gap-6">
              {configuredChannels?.map(item => <ChannelsCard channel={item} />)}
            </div>
          </div>
        )}

        {/* Available */}
        {Boolean(availableChannels?.length) && (
          <div>
            {/* Heading */}
            <div className="flex items-center gap-2">
              <h4 className="text-lg font-medium">Available</h4>
              <Dash />
            </div>

            {/* card container */}
            <div className="mt-6 grid grid-cols-3 gap-6">
              {availableChannels?.map(item => <ChannelsCard channel={item} />)}
            </div>
          </div>
        )}
      </Card>
    </>
  );
};

export default DashboardChannels;
