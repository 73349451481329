import Card from 'components/card';
import {
  renderThumb,
  renderTrack,
  renderView,
} from 'components/scrollbar/Scrollbar';
import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { HiX } from 'react-icons/hi';
import { sidebarRoutes } from 'sidebar-routes';
import { SideBarNavigation } from './components/SideBarNavigation';
import SidebarBottomLogo from './components/SidebarBottomLogo';
import WorkSpaceSwitcher from './components/WorkspaceSwitcher';

const SidebarHorizon = (props: {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLSpanElement>;
  variant?: string;
}) => {
  const { open, onClose, variant } = props;

  return (
    <>
      <div
        className={`sm:none duration-175 linear xl:!z-2 fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 ${
          variant === 'auth' ? 'xl:hidden' : 'xl:block'
        } ${open ? '' : '-translate-x-full'}`}
      >
        <Card
          extra={`w-[250px] 3xl:w-[285px]  dark:bg-background sm:mr-4 h-[100vh] border-l-0 border-b-0 !rounded-[0px] border-r pt-1`}
        >
          <Scrollbars
            autoHide
            renderTrackVertical={renderTrack}
            renderThumbVertical={renderThumb}
            renderView={renderView}
          >
            <div className="flex h-full flex-col justify-between gap-0">
              <div>
                <span
                  className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                  onClick={onClose}
                >
                  <HiX />
                </span>

                <div className="flex justify-center items-center py-2 relative top-[2px]">
                  <WorkSpaceSwitcher />
                </div>

                <SideBarNavigation links={sidebarRoutes} />
              </div>

              <div>
                <SidebarBottomLogo />
              </div>
            </div>
          </Scrollbars>
        </Card>
      </div>
    </>
  );
};

export default SidebarHorizon;
