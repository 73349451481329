import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';
import { twoButtonTemplate } from './two_button/config';
import { oneButtonTemplate } from './one_button/config';
import { templateInfo, componentBlockConfig } from './types';
import { defaultTemplate } from './default/config';
import { oneInputTemplate } from './one_input/config';
import { bannerImageTemplate } from './banner_image/config';
import { bannerImageOneButtonTemplate } from './banner_image_one_button/config';
import { bannerImageTwoButtonTemplate } from './banner_image_two_button/config';
import { ratingStarOneInputTemplate } from './rating_star_one_input/config';

export type TemplateStates = { key: string; label: string };
export type TemplateDetails = {
  blockConfig: (values: any) => componentBlockConfig;
  states: TemplateStates[];
  defaultValues: {
    [x: string]: string | boolean;
  };
  consoleInputs: inAppTemplate;
  info: templateInfo;
  stateToSettingsMapping?: {
    [x: string]: string;
  };
};

export type inAppTemplateIds =
  | 'one_button'
  | 'two_button'
  | 'rating_star_one_input'
  | 'default'
  | 'one_input'
  | 'banner_image_one_button'
  | 'banner_image_two_button'
  | 'banner_image';

type AllTemplateDetails = {
  [x in inAppTemplateIds]: TemplateDetails;
};

// *********************************

// * IMPORTANT *
// * DO NOT CHANGE KEYS OF THIS DATA OBJECT
// * IT ACTS AS AN ID OF THE INAPP ACTIONABLE TEMPLATES

export const allTemplateDetails: AllTemplateDetails = {
  one_button: oneButtonTemplate,
  two_button: twoButtonTemplate,
  default: defaultTemplate,
  one_input: oneInputTemplate,
  rating_star_one_input: ratingStarOneInputTemplate,
  banner_image: bannerImageTemplate,
  banner_image_one_button: bannerImageOneButtonTemplate,
  banner_image_two_button: bannerImageTwoButtonTemplate,
};

// *********************************

export const allTemplateCategories = {
  default: 'Default Components',
  button: 'Button Components',
  form: 'Form Components',
};

export const DEFAULT_TEMPLATE_ID = 'default';
