import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import SearchBar from 'components/table/searchbar';
import React from 'react';
import TemplateModal from './components/TemplateModal';
import TemplateTable from './components/TemplateTable';
import useGetAppTemplates from 'api/templates/get-app-templates';
import { logChipColors } from 'variables/chipColors';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { useNavigate } from 'react-router-dom';
import { useIsOldVersion } from 'utils/use-old-version';

const Template = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = React.useState('');
  const getTemplates = useGetAppTemplates();
  const addTemplateModalActions = useDisclosure();
  const { isOldVersion, isLoading: isOldVersionLoading } = useIsOldVersion();

  const handleRedirect = () => {
    navigate('/admin/workflow');
  };

  // If New version, redirect to API Credentials page
  React.useEffect(() => {
    if (isOldVersionLoading) return;

    if (!isOldVersion) {
      navigate('/admin/api-credentials');
    }
  }, [isOldVersionLoading, isOldVersion, navigate]);

  return (
    <div className="mt-5 grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <TemplateModal
        isOpen={addTemplateModalActions.isOpen}
        onClose={addTemplateModalActions.onClose}
      />
      {/* Header */}
      <div className="col-span-12  mb-5">
        <Card extra={'px-6 py-6 flex !flex-row justify-between items-center'}>
          <div>
            <h2 className=" text-2xl font-semibold text-white">Templates</h2>
            <p className="text-sm text-gray-650">
              Use templates to build effective multi-channel communication
              workflows.{' '}
              <a
                className="text-decoration-line: underline"
                href="https://docs.engagespot.com/docs/features/templates/introduction"
                target="blank"
              >
                Read more
              </a>
            </p>
          </div>

          <div className="flex items-center gap-4">
            <SearchBar filter={filter} setFilter={setFilter} />
          </div>
        </Card>
      </div>

      {/* waring */}
      <div
        style={{
          background: logChipColors.yellow,
          color: logChipColors.light_yellow,
        }}
        className="col-span-12 p-3 rounded-lg mb-5"
      >
        <div className="flex gap-2 items-center mb-3">
          <IoMdInformationCircleOutline className="w-5 h-5" />
          Warning
        </div>

        <div className="mb-3">
          Templates will be deprecated in the future, so you will no longer be
          able to create new ones. Instead, you should create workflows. <br />
          However, you can still use the templates that have already been
          created.
        </div>

        <button
          onClick={handleRedirect}
          className="bg-night-400 transition hover:opacity-85 font-medium w-fit text-white  px-4 py-1 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Go to workflows
        </button>
      </div>

      {/* Tables */}
      <div className="col-span-12">
        <TemplateTable
          loading={getTemplates.isLoading}
          tableData={getTemplates?.data ?? []}
          editOpen={addTemplateModalActions.onOpen}
          globalFilter={filter}
          setGlobalFilter={setFilter}
        />
      </div>
    </div>
  );
};

export default Template;
