import useGetUserPreference from 'api/users/get-user-preference';
import { GetUserResult } from 'api/users/get-users/types';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal, CustomModalPrimaryButton } from 'components/modal';
import { TextEditor } from 'components/text-editor';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';

type Preference = {
  preference: string;
};

function PreferenceModal({
  isOpen,
  onClose,
  selectedUser,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedUser: GetUserResult;
}) {
  const getUserPreference = useGetUserPreference({
    identifier: selectedUser?.identifier,
  });

  const { control, setError, clearErrors, reset } = useForm<Preference>({
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  React.useEffect(() => {
    const data = getUserPreference?.data ?? [];

    reset({
      preference: JSON.stringify(data, undefined, 4),
    });
  }, [getUserPreference.data, reset]);

  return (
    <CustomModal
      modalCardClassName="!max-w-[450px] !max-h-[500px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="mb-6 text-2xl  font-bold">User Preference</h1>

      {/* Content */}
      <div
        className={`flex flex-col ${
          getUserPreference.isLoading ? 'items-center' : 'items-start'
        } break-all justify-center rounded-2xl  bg-clip-border px-3 py-4 `}
      >
        {getUserPreference.isLoading && (
          <LoaderSpinner parentClass="!left-0 h-32 flex justify-center items-center" />
        )}

        <Controller
          name="preference"
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => {
            return (
              <TextEditor<Preference>
                {...field}
                label=""
                setError={setError}
                clearErrors={clearErrors}
                error={error}
              />
            );
          }}
        />
      </div>

      <div className="mt-6 flex justify-end gap-4">
        <CustomModalPrimaryButton
          loading={false}
          onClick={onClose}
          text="Close"
          className="w-1/2"
        />
      </div>
    </CustomModal>
  );
}

export default PreferenceModal;
