import Copy from "@assets/svg/common/copy.svg";
import Popover from "components/popover";
import { useDisclosure } from "@chakra-ui/hooks";
import { copyToClipboard } from "views/admin/dashboards/apiCredentials/functions";
import { useStore } from "@nanostores/react";
import { TemplateStates } from "templates";
import {
  $templateConfigCurrentState,
  templateConfigState,
} from "templates/store";

export const StateButtons = ({
  filteredTemplateConfigAllStates,
  item,
}: {
  item: TemplateStates;
  filteredTemplateConfigAllStates: TemplateStates[];
}) => {
  const isDisabled = !filteredTemplateConfigAllStates.includes(item);
  const templateConfigCurrentState = useStore($templateConfigCurrentState);
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <button
      disabled={isDisabled}
      key={item.key}
      onClick={() =>
        $templateConfigCurrentState.set(item.key as templateConfigState)
      }
      className={`flex items-center gap-2 rounded-[20px] ${
        templateConfigCurrentState === item.key
          ? "border border-[#BE185D]"
          : "border border-[#525151] "
      }
      ${isDisabled ? "opacity-20" : ""}
      
      `}
    >
      <div className={`px-4 py-1 text-sm font-medium lowercase `}>
        {item.key}
      </div>

      <Popover
        trigger={
          <button
            type="button"
            className=""
            onClick={(e) => {
              e.stopPropagation();
              onToggle();
              copyToClipboard(item.key);
              setTimeout(() => {
                onClose();
              }, 1500);
            }}
          >
            <img className="min-w-[22px] scale-[65%]" src={Copy} alt="copy" />
          </button>
        }
        content={<p className="dark:text-white">copied</p>}
        placement="right"
        isOpen={isOpen}
        onClose={onClose}
      />
    </button>
  );
};
