import { useToggleWorkflowJson } from 'api/workflows';
import { CustomDeleteModal } from 'components/modal';
import useWorkflowStore from 'store/workflowStore';
import useCustomToast from 'utils/use-toast';

function ToggleWorkflowModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { workflow } = useWorkflowStore(state => state);
  const toggleWorkflow = useToggleWorkflowJson();
  const toast = useCustomToast();
  const isActive = workflow?.isActive;

  const handleSubmit = () => {
    toggleWorkflow.mutate(
      {
        workflowId: workflow.id,
      },
      {
        onSuccess: () => {
          toast.success(
            `workflow ${isActive ? 'disabled' : 'enabled'} successfully`,
          );
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={toggleWorkflow.isLoading}
      handleSubmit={handleSubmit}
      heading={isActive ? 'Disable Workflow' : 'Enable Workflow'}
      text={`Are you sure to ${isActive ? 'disable' : 'enable'} this workflow?`}
      primaryButtonText={isActive ? 'Disable' : 'Enable'}
      textClassName={isActive ? '' : 'text-green-370'}
      primaryButtonClassName={
        isActive ? '' : 'bg-green-750 text-green-20 disabled:bg-green-750/60'
      }
      danger={isActive ? true : false}
    />
  );
}

export default ToggleWorkflowModal;
