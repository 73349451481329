import * as yup from 'yup';

const validateFunction = (value: any[], type: 'key' | 'value') => {
  if (Boolean(value?.[0])) {
    return yup.string().required(`required when ${type} is provided.`);
  }
};

export const webhookArrayFieldSchema = yup.array().of(
  yup
    .object()
    .shape(
      {
        key: yup
          .string()
          .when('value', value => validateFunction(value, 'value')),
        value: yup
          .string()
          .when('key', value => validateFunction(value, 'key')),
      },
      [['key', 'value']],
    )
    .test({
      name: 'required based on index',
      message: { key: 'required', value: 'required' },
      test: (val: any, context: any) => {
        const { key, value } = val;
        const index = parseInt(context.path.split('[')[1].split(']')[0], 10);

        if (index !== 0) {
          if (!Boolean(key) && !Boolean(value)) {
            return context?.createError({
              message: { key: 'required', value: 'required' },
            });
          }
        }

        return true;
      },
    }),
);

export const webhookSchema = yup.object({
  headers: webhookArrayFieldSchema,
  params: webhookArrayFieldSchema,
  batching_headers: webhookArrayFieldSchema,
  batching_params: webhookArrayFieldSchema,
});
