import { subDays, format, addDays } from 'date-fns';
import { DateRange } from 'react-day-picker';

export const getAnalyticsDateParams = (date: DateRange) => {
  if (!date?.from || !date?.to) return;

  const toDate = addDays(date.to, 1);
  const fromDate = subDays(date.from, 1);

  const formattedToDate = format(toDate, 'yyyy-MM-dd');
  const formattedFromDate = format(fromDate, 'yyyy-MM-dd');

  const params = {
    fromDate: formattedFromDate,
    toDate: formattedToDate,
  };

  return params;
};
