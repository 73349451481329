import { PiWarningCircleBold } from 'react-icons/pi';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@radix-ui/react-hover-card';
import { Node, NodeProps, Position } from 'reactflow';
import {
  NodeData,
  WorkflowDraggableNodeTypes,
  WorkflowNodeTypes,
} from '../../types';
import WorkFlowHandles from '../handles/WorkflowHandles';
import { getNodeDimensions } from 'views/admin/dashboards/workflowEditor/layout';
import { useShallow } from 'zustand/react/shallow';
import useWorkflowStore from 'store/workflowStore';
import { DelaySubText } from './DelaySubText';
import { clipOverflowWords, findSelectedNodeFromJson } from '../../functions';
import { cn } from 'utils/class-merge';
import { WorkflowUniqueId } from './UniqueId';
import { labelsMapping } from '../../variables';
import { BaseNodeText } from '../common';

type SelectedNodeTypes = Extract<
  WorkflowNodeTypes,
  'start' | 'channel' | 'updatePreference' | 'branch'
>;

const text: Record<SelectedNodeTypes, string> = {
  start: 'Workflow trigger',
  channel: 'Send to Channel',
  updatePreference: 'Update preference from actions',
  branch: 'Execute matching path',
};

const BaseNode = (node: NodeProps<NodeData>) => {
  const { selectedNode, setSelectedNode, workflowJson, showAllNodeRefs } =
    useWorkflowStore(useShallow(state => state));
  const nodeType = node.type as WorkflowNodeTypes;

  const selectedJson = findSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId: node.id,
  });

  const stepError = workflowJson.errors?.find(
    error => error.stepRef === node?.id,
  );

  return (
    <>
      {!node.data.hideTopHandle && (
        <WorkFlowHandles type="target" position={Position.Top} />
      )}

      <button
        style={{
          ...getNodeDimensions(nodeType as WorkflowNodeTypes),
        }}
        onClick={e => {
          if (nodeType === 'end' || nodeType === 'start') return;
          setSelectedNode(node as unknown as Node<NodeData>);
        }}
        // onDragStart={e => {
        //   setDraggedType(nodeType);
        //   setReOrderedNode(node);
        // }}
        // onDragEnd={() => setDraggedType(null)}
        // draggable={nodeType !== 'start' && nodeType !== 'end'}
        className={cn(
          `bg-[#16171C] relative text-white  flex flex-col gap-1 p-4 justify-center `,
          nodeType === 'end' ? 'items-center' : 'items-start  w-full h-full',
          selectedNode?.id === node.id
            ? stepError
              ? 'border border-red-500'
              : 'border border-[#27F05B]'
            : 'border border-[#3A3F47]',
          showAllNodeRefs ? 'rounded-t' : 'rounded',
        )}
      >
        {/* Label */}
        <span className="text-sm capitalize">
          {nodeType === 'channel'
            ? selectedJson?.channelKey
            : labelsMapping?.[nodeType as WorkflowDraggableNodeTypes]}
        </span>

        {/* Text | SteError */}
        {stepError ? (
          <span className="text-red-500 text-xs">
            {'This step is in an invalid state'}
          </span>
        ) : (
          <>
            {nodeType === 'delay' || nodeType === 'batch' ? (
              <DelaySubText
                delayFor={
                  nodeType === 'batch'
                    ? String(selectedJson?.config?.batchWindow)
                    : selectedJson?.config?.delayFor
                }
              />
            ) : nodeType === 'updatePreference' ? (
              <BaseNodeText>
                {`Update ${selectedJson?.config?.channel} preferences`}
              </BaseNodeText>
            ) : nodeType === 'triggerWorkflow' ? (
              <BaseNodeText>
                {`Trigger ${selectedJson?.config?.workflowIdentifier} workflow`}
              </BaseNodeText>
            ) : nodeType === 'waitForInput' ? (
              <BaseNodeText>
                {`Wait for ${selectedJson?.config?.stepRef} input`}
              </BaseNodeText>
            ) : nodeType === 'fetch' ? (
              <BaseNodeText>
                Do{' '}
                <span className="uppercase">{`${selectedJson?.config?.type}`}</span>{' '}
                {`API request`}
              </BaseNodeText>
            ) : nodeType === 'defineVariable' || nodeType === 'editVariable' ? (
              <BaseNodeText className="lowercase overflow-hiddens whitespace-nowrap text-ellipsis">
                {clipOverflowWords({
                  word: `${selectedJson?.config?.name} = ${selectedJson?.config?.value}`,
                  wordCount: 30,
                })}
              </BaseNodeText>
            ) : (
              <BaseNodeText>
                {text?.[nodeType as SelectedNodeTypes]}
              </BaseNodeText>
            )}
          </>
        )}

        {/* showing ids of nodes */}
        {(showAllNodeRefs ||
          (selectedNode?.type === 'waitForInput' &&
            node?.type === 'channel')) && (
          <WorkflowUniqueId
            type={nodeType as WorkflowNodeTypes}
            id={node.id}
            className={
              selectedNode?.id === node.id
                ? stepError
                  ? 'border border-red-500'
                  : 'border border-[#27F05B]'
                : 'border border-[#3A3F47]'
            }
          />
        )}

        {/* showing data variable */}
        {selectedNode?.type === 'editVariable' &&
          node?.type === 'defineVariable' && (
            <WorkflowUniqueId
              type={nodeType as WorkflowNodeTypes}
              id={`data.variables.${selectedJson?.config?.name}`}
            />
          )}

        {/* absolutely positioned error */}
        {stepError && (
          <div className="absolute top-[30%] right-2">
            <HoverCard>
              <HoverCardTrigger>
                <PiWarningCircleBold size={20} color="red" />
              </HoverCardTrigger>

              <HoverCardContent className="text-red-350  relative top-2 z-[100] w-fit rounded border border-gray-880 bg-gray-850 p-3 text-sm flex flex-col items-start gap-2">
                {stepError?.errors?.map((error, index) => (
                  <span key={index} className="text-xs">
                    {error?.message ?? 'This step is in an invalid state'}
                  </span>
                ))}
              </HoverCardContent>
            </HoverCard>
          </div>
        )}
      </button>

      {!node.data.hideBottomHandle && (
        <WorkFlowHandles type="source" position={Position.Bottom} />
      )}
    </>
  );
};

export default BaseNode;
