import { Path, UseFormRegister } from 'react-hook-form';

type TSwitch<T> = {
  color?:
    | 'red'
    | 'blue'
    | 'green'
    | 'yellow'
    | 'orange'
    | 'teal'
    | 'navy'
    | 'lime'
    | 'cyan'
    | 'pink'
    | 'purple'
    | 'amber'
    | 'indigo'
    | 'gray';
  id?: string;
  extra?: string;
  onChange?: any;
  register?: UseFormRegister<T>;
  required?: boolean;
  disabled?: boolean;
  name?: Path<T>;
  [x: string]: any;
};

const getClassName = (color: TSwitch<any>['color']) => {
  return `duration-[0.5s] relative h-6 w-12 appearance-none rounded-[20px] bg-[#e0e5f2] outline-none transition 
      before:absolute before:top-[50%] before:h-4 before:w-4 before:translate-x-[8px] before:translate-y-[-50%] before:rounded-[20px]
      before:bg-white before:shadow-[0_2px_5px_rgba(0,_0,_0,_.2)] before:transition before:content-[""]
      checked:before:translate-x-[22px] hover:cursor-pointer dark:bg-white/5 xl:!h-6 xl:w-12 xl:before:h-4
      xl:before:w-4 xl:before:translate-x-[6px]
      xl:checked:before:translate-x-[23px] ${
        color === 'red'
          ? 'checked:bg-red-500 dark:checked:bg-red-400'
          : color === 'blue'
            ? 'checked:bg-blue-500 dark:checked:bg-blue-400'
            : color === 'green'
              ? 'checked:bg-green-500 dark:checked:bg-green-400'
              : color === 'yellow'
                ? 'checked:bg-yellow-500 dark:checked:bg-yellow-400'
                : color === 'orange'
                  ? 'checked:bg-orange-500 dark:checked:bg-orange-400'
                  : color === 'teal'
                    ? 'checked:bg-teal-500 dark:checked:bg-teal-400'
                    : color === 'navy'
                      ? 'checked:bg-navy-500 dark:checked:bg-navy-400'
                      : color === 'lime'
                        ? 'checked:bg-lime-500 dark:checked:bg-lime-400'
                        : color === 'cyan'
                          ? 'checked:bg-cyan-500 dark:checked:bg-cyan-400'
                          : color === 'pink'
                            ? 'checked:bg-pink-500 dark:checked:bg-pink-400'
                            : color === 'purple'
                              ? 'checked:bg-purple-500 dark:checked:bg-purple-400'
                              : color === 'amber'
                                ? 'checked:bg-amber-500 dark:checked:bg-amber-400'
                                : color === 'indigo'
                                  ? 'checked:bg-indigo-500 dark:checked:bg-indigo-400'
                                  : color === 'gray'
                                    ? 'checked:bg-gray-500 dark:checked:bg-gray-400'
                                    : 'checked:bg-brand-500 dark:checked:bg-brand-400'
      }`;
};

function Switch<T>(props: TSwitch<T>) {
  const { extra, id, color, disabled, name, onChange, register, required } =
    props;
  const classNames = getClassName(color);

  return (
    <input
      type="checkbox"
      className={`${classNames} ${extra}`}
      id={id}
      disabled={disabled}
      {...register(name, { required })}
      {...(onChange ? { onChange } : {})}
    />
  );
}

function CheckField<T>(props: {
  id?: string;
  label?: string;
  extra?: string;
  placeholder: string;
  state?: string;
  type?: string;
  showIsRequiredAsterisk?: boolean;
  onChange?: any;
  register?: UseFormRegister<T>;
  required?: boolean;
  disabled?: boolean;
  name?: Path<T>;
  extraInputClass?: string;
  extraLabelClass?: string;
}) {
  const {
    label,
    id,
    extra,
    showIsRequiredAsterisk = false,
    register,
    required,
    name,
    disabled,
    onChange,
    extraInputClass,
    extraLabelClass,
  } = props;

  return (
    <div className={`mb-2 flex flex-col gap-3 ${extra}`}>
      {label && (
        <label
          htmlFor={id}
          className={`ml-1.5 text-sm font-medium text-navy-700 dark:text-white ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}
      <Switch
        required={required}
        disabled={disabled}
        name={name}
        register={register}
        extra={extraInputClass}
        onChange={onChange}
      />
    </div>
  );
}

export default CheckField;
