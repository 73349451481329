import { useQuery } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { GetMe } from './types';
import { axiosGlobalInstance } from 'api/axios/global';

export const getMe = async (): Promise<GetMe> => {
  const { data } = await axiosGlobalInstance.get(`${routes['me']}`);
  return data;
};

const useGetMe = () => {
  return useQuery({
    queryKey: [routes['me']],
    queryFn: getMe,
    staleTime: Infinity,
  });
};

export default useGetMe;
