import { useNavigate, useParams } from 'react-router-dom';
import { GetWorkflowJson } from 'api/workflows/types';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { format, parseISO } from 'date-fns';
import WorkflowEditor from '../../workflowEditor';

const DataKey = ({ value }: { value: string }) => {
  return <div className="min-w-[150px]">{value}</div>;
};

export const WorkflowOverViewDetails = ({
  data,
  loading,
}: {
  data: GetWorkflowJson;
  loading: boolean;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const workflowId = params.workflowId;

  const handleViewSteps = () => {
    return navigate(`/admin/workflow-editor/${workflowId}`);
  };

  return (
    <>
      {loading ? (
        <div className="h-[250px] flex items-center justify-center">
          <LoaderSpinner width="40" />
        </div>
      ) : (
        <div className="my-4 flex w-full">
          <div className="w-2/5 pr-2">
            <div className="text-lg font-semibold pb-2 border-gray-930">
              Details
            </div>

            {data && (
              <div className="flex flex-col gap-4 text-white/70">
                <div className="flex">
                  <DataKey value="Name" />
                  <div>{data.name}</div>
                </div>

                <div className="flex">
                  <DataKey value="Identifier" />
                  <div>{data.identifier}</div>
                </div>

                <div className="flex">
                  <DataKey value="Created At" />
                  {data.createdAt && (
                    <div>
                      {format(parseISO(data.createdAt), 'd MMM yyyy, h:mm aaa')}
                    </div>
                  )}
                </div>

                <div className="flex">
                  <DataKey value="Updated At" />
                  {data.updatedAt ? (
                    <div>
                      {format(parseISO(data.updatedAt), 'd MMM yyyy, h:mm aaa')}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="w-3/5 pl-2">
            <div className="font-medium text-xl text-white mb-4">
              Preview Workflow
            </div>

            <button
              onClick={handleViewSteps}
              className="bg-white font-medium w-fit text-night-400 border border-gray-440 px-4 py-1 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed mb-4"
            >
              Edit Workflow
            </button>

            <div
              onClick={handleViewSteps}
              className="border border-gray-440 rounded-md bg-[#101010]"
            >
              <WorkflowEditor preview={true} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
