import { EdgeProps, getSmoothStepPath } from 'reactflow';

// the placeholder edges do not have a special functionality, only used as a visual
export default function PlaceholderEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
}: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    // curvature: 4,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <path id={id} style={style} className={'workflow_edge-path'} d={edgePath} />
  );
}
