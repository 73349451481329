import PremiumCrown from '@assets/svg/premium/premium-crown.svg';
import useGetClientEmailTemplates from 'api/client/get-client-email-templates';
import { GetEmailLayouts } from 'api/client/get-client-email-templates/types';
import { getTemplateDataFromJson } from 'api/templates/get-template-json';
import { CustomModal } from 'components/modal';
import PremiumModal from 'components/modal/premium';
import { LayoutPanelTop } from 'lucide-react';
import React from 'react';
import { usePremiumStore } from 'store/premiumStore';
import { useTemplateStore } from 'store/templateStore';
import useCustomToast from 'utils/use-toast';

export const EmailLayoutModal = ({
  emailEditorRef,
  batchingEmailEditorRef,
  isOpen,
  onClose,
}: {
  emailEditorRef: React.MutableRefObject<any>;
  batchingEmailEditorRef: React.MutableRefObject<any>;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [loadingLayout, setLoadingLayout] = React.useState<number>(null);
  const getClientEmailLayouts = useGetClientEmailTemplates(isOpen);
  const premiumModalActions = usePremiumStore(state => state);
  const [selectedLayout, setSelectedLayoutLayout] =
    React.useState<number>(null);
  const [imageLoaded, setImageLoaded] = React.useState(false);
  const toast = useCustomToast();

  const handleClose = () => {
    onClose();
  };

  React.useEffect(() => {
    const img = new Image();

    img.onload = () => {
      setImageLoaded(true);
    };

    if (getClientEmailLayouts.isFetched) {
      if (getClientEmailLayouts.data?.length === 0) {
        setImageLoaded(true);
      }

      img.src = getClientEmailLayouts.data?.[0]?.coverImg;
    }

    if (getClientEmailLayouts.isError) {
      toast.showError(getClientEmailLayouts.error);
      onClose();
    }
  }, [
    getClientEmailLayouts.data,
    getClientEmailLayouts.error,
    getClientEmailLayouts.isError,
    getClientEmailLayouts.isFetched,
    onClose,
    toast,
  ]);

  return (
    <>
      <PremiumModal
        onClose={premiumModalActions.onClose}
        isOpen={premiumModalActions.isOpen}
      />

      <CustomModal
        modalCardClassName="!px-[30px] !w-full !max-h-[80vh] !pt-[35px] !pb-[40px] !max-w-[80vw]"
        modalContentClassName="!w-[75%] !top-[25%]"
        isOpen={isOpen}
        onClose={handleClose}
      >
        <div className="mb-4 text-left text-2xl font-medium text-white">
          Layout Library
        </div>

        <p className="mb-8 text-white/70">
          We use a layout library that offers a common structure, allowing us to
          seamlessly add content in between.
        </p>

        <div className="z-10 grid w-full grid-cols-4 gap-6">
          {(getClientEmailLayouts.isLoading || !imageLoaded) &&
            [1, 2, 3, 4].map(item => (
              <button key={item} className={`relative h-[450px] rounded-md`}>
                <div className="csm-pulse absolute left-0 top-0 h-full w-full rounded-md bg-white/80"></div>
              </button>
            ))}

          {imageLoaded &&
            getClientEmailLayouts.data &&
            getClientEmailLayouts.data?.map(layout => {
              return (
                <EmailLayouCard
                  key={layout.id}
                  premiumModalOpen={premiumModalActions.onOpen}
                  layout={layout}
                  emailEditorRef={emailEditorRef}
                  batchingEmailEditorRef={batchingEmailEditorRef}
                  loadingLayout={loadingLayout}
                  setLoadingLayout={setLoadingLayout}
                  handleClose={handleClose}
                  selectedLayout={selectedLayout}
                  setSelectedLayoutLayout={setSelectedLayoutLayout}
                />
              );
            })}

          {imageLoaded && getClientEmailLayouts.data?.length === 0 && (
            <div className="col-span-4 flex h-[408px] flex-col justify-center">
              <div className="mb-2.5 flex items-center justify-center">
                <LayoutPanelTop className="h-[200px] w-[200px] text-gray-10" />
              </div>

              <div className="relative top-10 flex items-center justify-center">
                <p className="relative bottom-1 mt-2 text-xl font-semibold text-white/70">
                  No worries! Soon you will have lots of layouts to choose from
                </p>
              </div>
            </div>
          )}
        </div>
      </CustomModal>
    </>
  );
};

const EmailLayouCard = ({
  layout,
  emailEditorRef,
  batchingEmailEditorRef,
  premiumModalOpen,
  setLoadingLayout,
  handleClose,
  loadingLayout,
  selectedLayout,
  setSelectedLayoutLayout,
}: {
  layout: GetEmailLayouts;
  emailEditorRef: React.MutableRefObject<any>;
  batchingEmailEditorRef: React.MutableRefObject<any>;
  premiumModalOpen: () => void;
  handleClose: () => void;
  setLoadingLayout: React.Dispatch<number>;
  setSelectedLayoutLayout: React.Dispatch<number>;
  loadingLayout: number;
  selectedLayout: number;
}) => {
  const [isHovering, setIsHovering] = React.useState(false);
  const { tab } = useTemplateStore(state => state);

  const handleLoadLayout = (data: string, isPaid: boolean, id: number) => {
    if (loadingLayout) return;

    if (isPaid) {
      premiumModalOpen();
    }

    if (!data) return;

    setLoadingLayout(id);
    setSelectedLayoutLayout(id);

    getTemplateDataFromJson(data)
      .then(r => {
        if (tab === 'template') {
          emailEditorRef.current?.editor.loadDesign(r);
        }

        if (tab === 'batched_template') {
          batchingEmailEditorRef.current?.editor.loadDesign(r);
        }

        setLoadingLayout(null);
        handleClose();
      })
      .catch(e => {
        console.log(e);
        setLoadingLayout(null);
        setSelectedLayoutLayout(null);
      });
  };

  return (
    <button
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={() => {
        handleLoadLayout(layout.jsonUrl, layout.isPaid, layout.id);
      }}
      disabled={Boolean(loadingLayout)}
      className={`relative max-h-[450px] rounded-md ${
        loadingLayout ? 'cursor-not-allowed' : ''
      } ${
        selectedLayout === layout.id ? 'border-[3px]  border-[#FF006A]' : ''
      }`}
    >
      {loadingLayout === layout.id && (
        <div className="csm-pulse absolute left-0 top-0 h-full w-full rounded-md bg-white/80"></div>
      )}

      {layout.isPaid && (
        <img
          className="absolute right-2 top-2"
          width={50}
          src={PremiumCrown}
          alt=""
        />
      )}

      {isHovering ? (
        <div className={`h-full rounded-md bg-white p-4 text-left text-black`}>
          <p className="mb-2 text-lg font-semibold text-brand-400">
            Email Layouts
          </p>
          <p className="text-xl font-semibold">{layout.name}</p>
        </div>
      ) : (
        <img
          className="max-h-[450px] w-full rounded-md object-fill"
          src={layout.coverImg}
          alt=""
        />
      )}
    </button>
  );
};
