import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';

type PostSendInvitations = {
  clientId: number;
  data: {
    clientUserEmail: string;
    role: string;
  }[];
};

export interface PostSendInvitationsResponse {
  message: string;
  succeededInvitations: any[];
  failedInvitations: string[];
}

const post = async (values: PostSendInvitations) => {
  const { clientId, data } = values;

  return axiosGlobalInstance.post<PostSendInvitationsResponse>(
    `${routes['clients']}/${clientId}/invite-users`,
    data,
  );
};

const usePostSendInvitation = () => {
  const { clientId } = useAuthStore(state => state);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: post,
    onSuccess: () => {
      queryClient.invalidateQueries([
        `${routes['clients']}/clientUserInvitations`,
        clientId,
      ]);
    },
  });
};

export default usePostSendInvitation;
