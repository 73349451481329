import { useQuery } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { TwoFactorEnableResponse } from '../patch-2fa';
import { axiosGlobalInstance } from 'api/axios/global';

const get = async (): Promise<TwoFactorEnableResponse> => {
  const { data } = await axiosGlobalInstance.get(
    `${routes['2fa']}/recoveryCodes`,
  );

  return data;
};

const useGet2FaRecoveryCodes = (
  onSuccess?: (data: TwoFactorEnableResponse) => void,
) => {
  return useQuery({
    queryKey: [`${routes['2fa']}/recoveryCodes`],
    queryFn: get,
    enabled: false,
    // eslint-disable-next-line @tanstack/query/no-deprecated-options
    onSuccess,
  });
};

export default useGet2FaRecoveryCodes;
