import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetChannels } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetChannels[]> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/channels/v1`,
  );
  return data;
};

const useGetChannels = (
  { staleTime }: { staleTime?: number } = {
    staleTime: 1000 * 5, // 5 seconds
  },
) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/channels`, appId],
    queryFn: get,
    enabled: Boolean(appId),
    staleTime,
  });
};

export default useGetChannels;
