import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

interface ReturnUrl {
  url: string;
}

const uploadFilesToServerAndGetUrl = async (data: FormData) => {
  return axiosInstance.post<ReturnUrl>(`${routes['files']}`, data);
};

const useUploadFilesToServerAndGetUrl = () => {
  return useMutation({
    mutationFn: uploadFilesToServerAndGetUrl,
  });
};

export { uploadFilesToServerAndGetUrl, useUploadFilesToServerAndGetUrl };
