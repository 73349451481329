import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PutAddApps = {
  name?: string;
  topicId: number;
  type: 'delete' | 'patch';
};

const patchOrDelete = async (values: PutAddApps) => {
  const { topicId, name, type } = values;

  const url = `${routes['topics']}/${topicId}`;
  const data = {
    name,
  };

  if (type === 'delete') {
    return axiosInstance.delete(url);
  }

  return axiosInstance.patch(url, data);
};

const usePatchOrDeleteTopics = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: patchOrDelete,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/topics`]);
    },
  });
};

export default usePatchOrDeleteTopics;
