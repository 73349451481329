import { cn } from 'utils/class-merge';
import { capitalizeFirstLetter } from 'utils/functions';
import { errorMessageParser } from 'utils/get-error';

function ErrorBox({
  error,
  errorKey,
  className,
}: {
  error: any;
  errorKey?: string;
  className?: string;
}) {
  const errorMessage = errorMessageParser(error, errorKey);

  return (
    <>
      <p className={cn('my-2 text-sm text-red-500', className)}>
        {capitalizeFirstLetter(errorMessage)}
      </p>
    </>
  );
}

export default ErrorBox;
