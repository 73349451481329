import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/modal';
import LoaderSpinner from 'components/loader/LoaderSpinner';

function LoaderModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <div className="loader_modal_container">
        <ModalOverlay className="bg-[#000] !opacity-70 outline-none blur-md" />
        <ModalContent className="pointer-events-none !z-[1002] !m-auto !w-max min-w-[450px] !max-w-[85%]  outline-none   md:top-[12vh] ">
          <ModalBody>
            <LoaderSpinner
              width="50px"
              parentClass={'ml-2 flex justify-center outline-none'}
            />
          </ModalBody>
        </ModalContent>
      </div>
    </Modal>
  );
}

export default LoaderModal;
