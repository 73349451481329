import { TemplateDetails } from 'templates';
import { templateInfo } from 'templates/types';
import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';
import { createDefaultInAppConfig } from 'templates/utils';

const stateConfig = [
  { key: 'state_1', label: 'State 1' },
  { key: 'state_2', label: 'State 2' },
  { key: 'state_3', label: 'State 3' },
];

const stateToSettingsMapping = {
  state_2: 'state_1_button1_active',
  state_3: 'state_1_button2_active',
};

/**
 * used in setting initial inapp api data
 * and also when watching form value changes
 */
const defaultValues = {
  // state_1
  state_1_title: 'Notification title',
  state_1_icon: '',
  state_1_icon_select: 'file',
  state_1_message: '',
  state_1_url: '',
  state_1_button1_label: 'Button 1',
  state_1_button1_link: '',
  state_1_button1_external_link: true,
  state_1_button2_label: 'Button 2',
  state_1_button2_link: '',
  state_1_button2_external_link: true,

  // preview
  preview_title: '<span><b>John Doe</b> sent you a friend request</span>',
  preview_button1_label: 'Accept',
  preview_button2_label: 'Reject',

  // state_2
  state_2_title: 'Notification title',
  state_2_icon: '',
  state_2_icon_select: 'file',
  state_2_message: '',
  state_2_url: '',

  // state_3
  state_3_title: 'Notification title',
  state_3_icon: '',
  state_3_icon_select: 'file',
  state_3_message: '',
  state_3_url: '',

  // enabled
  state_1_button1_active: false,
  state_1_button2_active: false,
};

const info: templateInfo = {
  template: 'two_button',
  category: 'button',
  tags: ['friend request'],
};

const getBlockConfig: TemplateDetails['blockConfig'] = (
  values = defaultValues,
) => {
  return {
    state_1: [
      {
        id: 'state_1_button1_label',
        type: 'button' as const,
        text: values.state_1_button1_label,
        variant: 'primary',
        link: values.state_1_button1_link,
        externalLink: values.state_1_button1_external_link,
      },
      {
        id: 'state_1_button2_label',
        type: 'button' as const,
        text: values.state_1_button2_label,
        variant: 'secondary',
        link: values.state_1_button2_link,
        externalLink: values.state_1_button2_external_link,
      },
    ],
    state_2: [],
    state_3: [],
  };
};

const state_1Config = createDefaultInAppConfig();
const { template } = state_1Config;

const consoleConfig = {
  state_1: {
    template: [
      template[0],
      template[1],
      template[2],
      {
        type: 'div',
        name: 'parent',
        children: [
          {
            label: 'Button 1',
            required: false,
            placeholder: 'Enter label',
            name: 'state_1_button1_label',
            type: 'input',
            additionalSettings: {
              label: 'Button ID',
              required: false,
              placeholder: '',
              name: 'state_1_button1',
              type: 'button',
            },
          },
          {
            label: 'Button 2',
            required: false,
            placeholder: 'Enter label',
            name: 'state_1_button2_label',
            type: 'input',
            additionalSettings: {
              label: 'Button ID',
              required: false,
              placeholder: '',
              name: 'state_1_button2',
              type: 'button',
            },
          },
        ],
      },
      template[3],
    ],
    batched_template: {},
  },
  state_2: createDefaultInAppConfig({ namePrefix: 'state_2' }),
  state_3: createDefaultInAppConfig({ namePrefix: 'state_3' }),
};

export const twoButtonTemplate: TemplateDetails = {
  blockConfig: (values: any) => getBlockConfig(values),
  states: stateConfig,
  consoleInputs: consoleConfig as inAppTemplate,
  defaultValues,
  info,
  stateToSettingsMapping,
};
