import { SelectFieldProps } from '../../types';

function DefaultSelectVariant<T>(props: SelectFieldProps<T>) {
  const {
    label,
    id,
    extra,
    placeholder,
    disabled,
    onChange,
    showIsRequiredAsterisk = false,
    register,
    required,
    name,
    extraInputClass,
    extraLabelClass,
    options,
    customLabelContent,
  } = props;

  const inputClass = `mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
    disabled === true
      ? '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
      : 'border-gray-200 dark:!border-white/50 dark:text-white'
  } ${extraInputClass}`;

  return (
    <div className={`${extra}`}>
      {customLabelContent && customLabelContent()}

      {label && (
        <label
          htmlFor={id}
          className={`ml-1.5 text-sm font-medium text-navy-700 dark:text-white ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}

      <>
        <select
          onChange={onChange}
          disabled={disabled}
          id={id}
          placeholder={placeholder}
          className={inputClass}
          {...register(name, { required })}
        >
          {options?.map(item => (
            <option key={item['value']} value={item['value']}>
              {item['label']}
            </option>
          ))}
        </select>
      </>
    </div>
  );
}

export default DefaultSelectVariant;
