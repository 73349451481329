import { useQueryClient } from '@tanstack/react-query';
import usePutApp from 'api/app/put-app';
import { GetClientApps } from 'api/client/get-client-apps/types';
import { routes } from 'api/routes';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getFirstWordAndLetter } from 'utils/functions';
import useCustomToast from 'utils/use-toast';

export interface FormEditWorkspace {
  name: string;
}

function AppModal({
  isOpen,
  onClose,
  selected,
}: {
  onClose: () => void;
  isOpen: boolean;
  selected: GetClientApps;
}) {
  const putApp = usePutApp();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { firstLetter } = getFirstWordAndLetter(selected?.name);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormEditWorkspace>({
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
    },
  });

  React.useEffect(() => {
    reset({
      name: selected?.name,
    });
  }, [selected, reset, isOpen]);

  const handleClose = () => {
    reset({});
    onClose();
  };

  const onSubmit: SubmitHandler<FormEditWorkspace> = values => {
    const data = {
      appId: selected?.id,
      name: values.name,
    };

    putApp.mutate(data, {
      onSuccess: () => {
        toast.success('app edited successfully');
        queryClient.invalidateQueries([`${routes['clients']}/apps`]);
        handleClose();
      },
      onError: err => toast.showError(err),
    });
  };

  return (
    <CustomModal
      showCloseButton={false}
      modalContentClassName="!min-w-[345px]"
      modalCardClassName="!max-w-[450px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mx-auto mb-3 text-2xl font-bold">Edit Environment</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <>
          <div className="relative mx-auto my-4 mb-7 flex h-24 w-24 items-center justify-center rounded-full bg-white p-4 text-4xl font-bold text-night-100">
            {/* Image Upload code */}
            {/* <div className="absolute -bottom-1 -right-1">
                    <img width={30} src={Edit} alt="" />
                  </div> */}
            {/* <img width={50} src={dummyImg} alt="" /> */}

            {/* First Letter Display code */}
            {firstLetter}
          </div>

          <InputField<FormEditWorkspace>
            variant="modal"
            extra="mb-3"
            label={''}
            placeholder=""
            type="text"
            showIsRequiredAsterisk={false}
            register={register}
            name={'name'}
            extraLabelClass="!uppercase"
          />
          {errors.name && <ErrorBox error={errors.name} />}
        </>

        <CustomModalButtons
          loading={putApp.isLoading}
          handleClose={handleClose}
        />
      </form>
    </CustomModal>
  );
}

export default AppModal;
