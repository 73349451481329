// @ts-nocheck

const DashboardAccountSettings = () => {
  return (
    <></>
    // <div className="text-white border border-white p-6 rounded-md">
    //   <stykite-widget
    //     company-id="COM-0G1KRJ5SNF8V2"
    //     country-id="CON-0AZZVPQHM475V"
    //     country-i-s-o2="US"
    //     token="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhbmFuZEBlbmdhZ2VzcG90LmNvIiwiaWF0IjoxNzE1NzEwMDQyLCJleHAiOjQ4Mzk3NjEyNDJ9.F8sQZls0MZxyLDrSxfgZ6P3cATyDU9H9qyps7xw8lzrAJrVmX7y1EqwtsbPc4ir5sSMWwil932d2xan6IL8QVg"
    //     customer-identifier="userId"
    //     widget-type="PLAN_PREVIEW"
    //     email=""
    //     mode="LIGHT"
    //   ></stykite-widget>

    //   <stykite-widget
    //     company-id="COM-0G1KRJ5SNF8V2"
    //     country-id="CON-0AZZVPQHM475V"
    //     country-i-s-o2="US"
    //     token="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhbmFuZEBlbmdhZ2VzcG90LmNvIiwiaWF0IjoxNzE1NzEwMDQyLCJleHAiOjQ4Mzk3NjEyNDJ9.F8sQZls0MZxyLDrSxfgZ6P3cATyDU9H9qyps7xw8lzrAJrVmX7y1EqwtsbPc4ir5sSMWwil932d2xan6IL8QVg"
    //     customer-identifier="userId"
    //     widget-type="MY_ACCOUNT"
    //   ></stykite-widget>

    //   <stykite-widget
    //     company-id="COM-0G1KRJ5SNF8V2"
    //     country-id="CON-0AZZVPQHM475V"
    //     country-i-s-o2="US"
    //     token="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhbmFuZEBlbmdhZ2VzcG90LmNvIiwiaWF0IjoxNzE1NzEwMDQyLCJleHAiOjQ4Mzk3NjEyNDJ9.F8sQZls0MZxyLDrSxfgZ6P3cATyDU9H9qyps7xw8lzrAJrVmX7y1EqwtsbPc4ir5sSMWwil932d2xan6IL8QVg"
    //     customer-identifier="userId"
    //     widget-type="ADD_ON"
    //   ></stykite-widget>

    //   <div className="flex justify-center">
    //     <stykite-widget
    //       company-id="COM-0G1KRJ5SNF8V2"
    //       country-id="CON-0AZZVPQHM475V"
    //       country-i-s-o2="US"
    //       token="eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhbmFuZEBlbmdhZ2VzcG90LmNvIiwiaWF0IjoxNzE1NzEwMDQyLCJleHAiOjQ4Mzk3NjEyNDJ9.F8sQZls0MZxyLDrSxfgZ6P3cATyDU9H9qyps7xw8lzrAJrVmX7y1EqwtsbPc4ir5sSMWwil932d2xan6IL8QVg"
    //       customer-identifier="userId"
    //       widget-type="SAVE_CARD"
    //       label="Save Card"
    //       card-button-color="#FFF"
    //       card-button-text-color="#000"
    //     ></stykite-widget>
    //   </div>
    // </div>
  );
};

export default DashboardAccountSettings;
