import { EngBox, EngTypography, EngBlueDot, EngImage, EngCard, EngButton, EngInput, EngTextArea, EngRatingStar } from '@engagespot/web-components';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
import { jsx } from 'react/jsx-runtime';

/**
  * @engagespot/template-blocks v1.3.7
  *
  * @license undefined
  * This source code is licensed under the undefined license found in the
  * LICENSE file in the root directory of this source tree.
  */
 
var y={box:EngBox,typography:EngTypography,"blue-dot":EngBlueDot,image:EngImage,card:EngCard,button:EngButton,input:EngInput,textarea:EngTextArea,"rating-star":EngRatingStar};function g(r,{insertAt:s}={}){if(typeof document>"u")return;let a=document.head||document.getElementsByTagName("head")[0],n=document.createElement("style");n.type="text/css",s==="top"&&a.firstChild?a.insertBefore(n,a.firstChild):a.appendChild(n),n.styleSheet?n.styleSheet.cssText=r:n.appendChild(document.createTextNode(r));}g(`@import"https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800&display=swap";@import"https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.13.1/cdn/themes/light.css";.engagespot_template_blocks_container{.eng_primary-button::part(base){background:#212b36;border:#212b36}.eng_primary-button::part(label){color:#fff}.eng_secondary-button::part(base){background:#fff;border:1px solid #919eab52;color:#000}.eng_primary-button::part(base),.eng_secondary-button::part(base){font-size:13px;border-radius:8px}.eng_primary-button::part(label),.eng_secondary-button::part(label){padding:4px 15px;font-weight:700;font-size:14px;font-family:Public Sans,sans-serif}.eng_primary-input::part(form-control-input){background-color:#fff}.eng_flex{display:flex}.eng_flex-row{flex-direction:row}.eng_flex-col{flex-direction:column}.eng_gap-2{gap:1rem}.eng_mt-2{margin-top:.6rem}.eng_hidden{display:none}.eng_mb-2{margin-bottom:.6rem}}
`);setBasePath("https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.8.0/cdn/");var S=({blocks:r,eventListenersToRun:s,changeNotificationState:a,notification:n})=>{let d=r?.reduce((t,o,e)=>{let l=r?.[e-1];l&&l?.type!==o?.type&&(t={...t,[Object.keys(t).length]:[]});let p=Object.keys(t).length-1;return {...t,[p]:[...t?.[p]??[],o]}},{0:[]}),b={button:"row",tags:"row",input:"column",banner_image:"column","rating-star":"column"},f=t=>document.querySelector(`[data-block-id='${t}']`).value;return jsx("div",{className:"engagespot_template_blocks_container",children:Object.keys(d).map(t=>{let o=d[t]?.[0]?.type;return jsx("div",{className:`eng_flex ${b?.[o]==="row"?"eng_flex-row eng_gap-2":"eng_flex-col"}`,children:d[t].map((e,l)=>{let p=y?.[e.type];if(!p)return null;let i=null;s?.length&&a&&n&&(i=s.find(c=>c.blockId===e.id));let m=e.variant==="primary"?"eng_primary-button eng_mt-2":"eng_secondary-button eng_mt-2";(e.type==="textarea"||e.type==="input")&&(m=e.variant==="primary"?"eng_primary-input":"eng_secondary-button"),e.type==="image"&&(m=e.src?"":"eng_hidden"),e.type==="rating-star"&&(m="eng_mb-2");let _={"data-block-id":e.id,size:"small",...e.type==="image"&&{src:typeof e.src=="string"?e.src:e.src?URL.createObjectURL(e.src?.[0]):""},placeholder:e.text,...e.type==="textarea"&&{rows:"2",resize:"auto"},className:m,variant:e.variant,...e.type==="button"&&!!e.link&&{onClick:()=>{window.open(e.link,e.externalLink?"_blank":"_self");}},...i&&{[i.event]:c=>i.onEvent({event:c,changeNotificationState:a,getValue:f,notification:n})}};return jsx(p,{..._,children:e.text},l)})},t)})})};

export { S as TemplateBlocksRender, y as componentMapping };
