import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { usePublishChanges } from 'api/commits';
import { LayoutDetails } from 'api/layouts/types';
import { routes } from 'api/routes';
import CustomEmailPreview from 'components/email-preview';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import { Tabs, TabsList, TabsTrigger } from 'components/shadcn/tabs';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type FormChanges = {
  message: string;
};

const schema = yup.object({
  message: yup.string().required('Commit Message is required'),
});

function LayoutDiffModal({
  isOpen,
  onClose,
  layout,
}: {
  onClose: () => void;
  isOpen: boolean;
  layout: LayoutDetails;
}) {
  const { appId } = useAuthStore(state => state);
  const toast = useCustomToast();
  const publishChanges = usePublishChanges();
  const queryClient = useQueryClient();
  const [tableView, setTableView] = React.useState(1);

  const handleSwitch = (value: number) => {
    setTableView(value);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<FormChanges>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
    reset({ message: '' });
  };

  const onSubmit: SubmitHandler<FormChanges> = values => {
    const data = {
      message: values.message,
      appId,
      changes: [
        {
          entityType: 'layout' as const,
          entityId: Number(layout?.id),
        },
      ],
    };

    publishChanges.mutate(data, {
      onSuccess: () => {
        toast.success('Layout Published Successfully');
        queryClient.invalidateQueries([`${routes['layouts']}/details`]);
        handleClose();
      },
      onError: error => {
        toast.showError(error);
        handleClose();
      },
    });
  };

  React.useEffect(() => {
    setValue('message', `Edited ${layout?.name} `);
  }, [isOpen, setValue, layout?.name]);

  return (
    <CustomModal
      modalContentClassName="min-w-[92vw] h-[92vh] top-[8%]"
      modalCardClassName={'max-h-[92vh] h-[92vh] px-0 py-0'}
      isOpen={isOpen}
      onClose={handleClose}
      showCloseButton={false}
      modalOverlayClick={handleClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col border-b mb-6  border-gray-880 px-8 py-6"
      >
        <div className="flex justify-between  items-center ">
          <h1 className="text-lg">Preview Changes</h1>

          <div className="flex gap-3">
            <button
              type="button"
              onClick={handleClose}
              className="bg-night-400 text-white border border-gray-440 px-4 py-2 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
            >
              cancel
            </button>

            <button
              type="submit"
              className="bg-white text-night-400 border border-gray-440 px-4 py-2 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed"
              disabled={publishChanges.isLoading}
            >
              Publish
              {publishChanges.isLoading && (
                <LoaderSpinner
                  strokeColor="black"
                  parentClass="relative left-1"
                />
              )}
            </button>
          </div>
        </div>

        <div className="mt-2">
          <InputField<FormChanges>
            variant="modal"
            extra=""
            label={''}
            placeholder="Enter commit message"
            showIsRequiredAsterisk={true}
            type="text"
            register={register}
            name={'message'}
          />

          {errors.message && (
            <ErrorBox className="my-0 mt-3" error={errors.message} />
          )}
        </div>
      </form>

      <div className="overflow-auto rounded-lg mx-8 border border-gray-880 h-full">
        <div className="flex flex-col h-full">
          <Tabs
            value={String(tableView)}
            defaultValue={String(tableView)}
            className=""
          >
            <TabsList>
              <TabsTrigger onClick={() => handleSwitch(1)} value="1">
                Current Change
              </TabsTrigger>

              <TabsTrigger onClick={() => handleSwitch(2)} value="2">
                Published Change
              </TabsTrigger>
            </TabsList>
          </Tabs>

          <CustomEmailPreview
            bodyJsonTemplate={
              tableView === 1
                ? layout?.jsonSpec?.bodyJsonTemplate
                : layout?.publishedJsonSpec?.bodyJsonTemplate
            }
          />
        </div>
      </div>
    </CustomModal>
  );
}

export default LayoutDiffModal;
