import Lock from '@assets/img/3d/color/lock.png';
import Sparkles from '@assets/svg/premium/sparkles.svg';
import { useNavigate } from 'react-router-dom';
import { usePremiumStore } from 'store/premiumStore';
import { CustomModal } from '.';

function PremiumModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const navigate = useNavigate();
  const premiumModalActions = usePremiumStore(state => state);

  const handleClose = () => {
    onClose();
    premiumModalActions.reset();
  };

  const handleClick = () => {
    navigate('/admin/settings/billing');
    handleClose();
  };

  return (
    <CustomModal
      modalContentClassName="!min-w-[350px] !max-w-[480px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="flex items-center justify-center">
        <img width={150} src={Lock} alt="" />
      </div>

      <h1 className="mb-[20px] text-center text-lg font-bold">
        Upgrade Your Account to Unlock Premium Features!
      </h1>

      <p className="text-center text-[14px] font-normal">
        {premiumModalActions.text}
      </p>

      <div className="mt-10  flex justify-center gap-2">
        <button
          className="linear flex w-fit items-center justify-center gap-4  rounded-xl bg-brand-500 px-12 py-3 text-lg font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
          onClick={handleClick}
        >
          Upgrade
          <img src={Sparkles} alt="" />
        </button>
      </div>
    </CustomModal>
  );
}

export default PremiumModal;
