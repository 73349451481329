import { NodeProps, Position } from 'reactflow';
import { NodeData, WorkflowNodeTypes } from '../../types';
import WorkFlowHandles from '../handles/WorkflowHandles';
import { getNodeDimensions } from '../../layout';

const EmptyNode = (node: NodeProps<NodeData>) => {
  return (
    <>
      <WorkFlowHandles type="target" position={Position.Top} />
      <div
        style={{ ...getNodeDimensions(node.type as WorkflowNodeTypes) }}
        className="w-2 h-2 rounded-full bg-gray-400"
      ></div>
      <WorkFlowHandles type="source" position={Position.Bottom} />
    </>
  );
};

export default EmptyNode;
