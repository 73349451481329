import { Channels } from 'api/channels/get-channels/types';

export type ChannelWorkflow = {
  channels: {
    [x: string]: {
      id: number;
      channelIdentifier: Channels | 'event_trigger';
      content: string;
      isDisabled: boolean;
      isConfigured: boolean;
      isActive: boolean;
    };
  };
  columns: {
    ['addedChannels']: {
      id: string;
      channelIds: string[];
    };
  };
  columnOrder: string[];
};

export const allChannelWorkflowData: ChannelWorkflow['channels'] = {
  1: {
    id: 1,
    channelIdentifier: 'inApp',
    content: 'In-app',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  2: {
    id: 2,
    channelIdentifier: 'sms',
    content: 'Sms',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  3: {
    id: 3,
    channelIdentifier: 'email',
    content: 'Email',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  4: {
    id: 4,
    channelIdentifier: 'slack',
    content: 'Slack',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  5: {
    id: 5,
    channelIdentifier: 'whatsapp',
    content: 'Whatsapp',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  6: {
    id: 6,
    channelIdentifier: 'mobilePush',
    content: 'Mobile Push',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  7: {
    id: 7,
    channelIdentifier: 'webPush',
    content: 'Web Push',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  8: {
    id: 8,
    channelIdentifier: 'chat',
    content: 'Chat',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  9: {
    id: 9,
    channelIdentifier: 'event_trigger',
    content: 'Event Triggered',
    isConfigured: false,
    isDisabled: true,
    isActive: false,
  },
  10: {
    id: 10,
    channelIdentifier: 'discord',
    content: 'Discord',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
  11: {
    id: 11,
    channelIdentifier: 'webhook',
    content: 'Webhook',
    isConfigured: false,
    isDisabled: false,
    isActive: false,
  },
};

export const WORKFLOWDATA_EVENT_TRIGGERED_ID = '9';

const channelWorkFlowData: ChannelWorkflow = {
  channels: {
    [WORKFLOWDATA_EVENT_TRIGGERED_ID]:
      allChannelWorkflowData[WORKFLOWDATA_EVENT_TRIGGERED_ID],
  },
  columns: {
    addedChannels: {
      id: 'addedChannels',
      channelIds: [WORKFLOWDATA_EVENT_TRIGGERED_ID],
    },
  },
  // Facilitate reordering of the columns
  columnOrder: ['addedChannels'],
};

export default channelWorkFlowData;
