import { workflowDocLinks } from '../../variables';
import { LearnMore } from '../common';

export const WorkflowFormHeader = ({
  heading,
  docKey,
}: {
  heading: string;
  docKey: keyof typeof workflowDocLinks;
}) => {
  return (
    <div className="flex justify-between items-center mb-2">
      <div className="text-xl font-medium">{heading}</div>
      <LearnMore link={workflowDocLinks[docKey]} />
    </div>
  );
};
