import useGet2FaRecoveryCodes from 'api/2fa/get-2fa-recovery';
import { TwoFactorEnableResponse } from 'api/2fa/patch-2fa';
import { CustomModal, CustomModalPrimaryButton } from 'components/modal';
import React from 'react';
import { QrCodeModalType } from '../types';

function GenerateTwoFactorRecoveryModal({
  isOpen,
  onClose,
  setModalType,
  setRecoveryCodes,
  qrCodeModalonOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
  setModalType: React.Dispatch<React.SetStateAction<QrCodeModalType>>;
  setRecoveryCodes: React.Dispatch<React.SetStateAction<string[]>>;
  qrCodeModalonOpen: () => void;
}) {
  const finalActions = (data: TwoFactorEnableResponse) => {
    const recoveryCodes = data?.recoveryCodes;
    setRecoveryCodes(recoveryCodes);
    setModalType('recovery');
    onClose();
    qrCodeModalonOpen();
  };

  const get2FaRecoveryCodes = useGet2FaRecoveryCodes(finalActions);

  const handleSubmit = () => {
    get2FaRecoveryCodes.refetch();
  };

  return (
    <CustomModal
      modalContentClassName="!min-w-[350px]"
      modalCardClassName="!max-w-[450px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="mx-auto mb-[20px] text-2xl font-bold">
        New Recovery codes
      </h1>
      <p className="mx-auto mb-[20px]">
        Are you sure to generate new recovery codes?
      </p>
      <div className="flex gap-2">
        <CustomModalPrimaryButton
          text="Generate"
          loading={get2FaRecoveryCodes?.isFetching}
          onClick={handleSubmit}
        />
      </div>
    </CustomModal>
  );
}

export default GenerateTwoFactorRecoveryModal;
