import InputField from 'components/fields/InputField';
import { Search } from 'lucide-react';

export type SearchBarProps = {
  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;
};

const SearchBar = ({ filter, setFilter }: SearchBarProps) => {
  return (
    <>
      <div className="relative flex items-center">
        <Search className="absolute left-2.5 top-[30%] h-4 w-4 text-muted-foreground" />

        <InputField
          placeholder="Search..."
          extraInputClass="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px] text-white h-[36px]"
          variant="default"
          onChange={(e: any) => setFilter(e.target.value)}
          value={filter ?? ''}
        />
      </div>
    </>
  );
};

export default SearchBar;
