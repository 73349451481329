import CopyButton from './index';

const CopyCell = ({ value }: { value: string }) => {
  return (
    <button
      onClick={e => e.stopPropagation()}
      className="group flex items-center relative right-3 hover:outline outline-input text-left justify-start px-3 py-0 gap-3 rounded-md   hover:bg-night-100 dark:text-white"
    >
      {value}

      {value && (
        <div className="relative opacity-0 group-hover:opacity-100">
          <CopyButton value={value} />
        </div>
      )}
    </button>
  );
};

export default CopyCell;
