import { useStore } from '@nanostores/react';
import Arrow from 'assets/svg/template/arrow.svg';
import NoComponentImg from 'assets/svg/template/no-components.svg';
import SearchBar from 'components/table/searchbar';
import Fuse from 'fuse.js';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  allTemplateCategories,
  allTemplateDetails,
  inAppTemplateIds,
} from 'templates';
import {
  $currentTemplate,
  $templateConfigCurrentState,
  $templateConfigValues,
} from 'templates/store';
import { DEFAULT_TEMPLATE_STATE } from 'templates/utils';
import { TemplateEditorAvailabeChannels } from '../variables/inputs';
import { InAppNotification } from './InAppNotification';

const NoComponent = () => {
  return (
    <img
      className="pointer-events-none h-full w-full"
      src={NoComponentImg}
      alt="no component"
    />
  );
};

const TemplateComponents = ({
  templateListBasedOnCategory,
  categoryLabel,
  isInitiallyCollapsed,
}: {
  templateListBasedOnCategory: string[];
  categoryLabel: string;
  isInitiallyCollapsed: boolean;
}) => {
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const currentTemplate = useStore($currentTemplate);

  React.useEffect(() => {
    setIsCollapsed(isInitiallyCollapsed ? true : false);
  }, [isInitiallyCollapsed]);

  const replaceObjectsKeysForPreview = (obj: object) => {
    const replaceKeys = {
      [`${DEFAULT_TEMPLATE_STATE}_banner_image`]: 'preview_banner_image',
      [`${DEFAULT_TEMPLATE_STATE}_button1_label`]: 'preview_button1_label',
      [`${DEFAULT_TEMPLATE_STATE}_button2_label`]: 'preview_button2_label',
    };

    const transformedObj = Object.keys(obj).reduce((acc, curr) => {
      let value = (obj as any)[curr];

      if (Object.keys(replaceKeys).includes(curr)) {
        value = (obj as any)[(replaceKeys as any)[curr]];
      }

      return {
        ...acc,
        [curr]: value,
      };
    }, {});

    return transformedObj;
  };

  return (
    <div
      style={{
        paddingBottom: isCollapsed ? '0' : '1rem',
      }}
      className={`flex flex-col gap-4 rounded-lg bg-black border border-input px-4 pt-4 `}
    >
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="flex justify-between items-center text-left"
      >
        <h4 className="">{categoryLabel}</h4>
        <img
          className={`${
            isCollapsed ? 'rotate-180' : 'rotate-0'
          } transition delay-150 ease-linear `}
          src={Arrow}
          alt=""
        />
      </button>

      <div
        style={{
          display: 'grid',
          gridTemplateRows: isCollapsed ? '0fr' : '1fr',
          overflow: 'hidden',
          transition: 'grid-template-rows 500ms',
        }}
      >
        <div className="flex flex-col gap-4 overflow-hidden">
          {templateListBasedOnCategory?.map(item => {
            const templateDefaultValues =
              allTemplateDetails?.[item as inAppTemplateIds]?.['defaultValues'];

            const transformedTemplateDefaultValuesForPreview =
              replaceObjectsKeysForPreview(templateDefaultValues);

            const newTemplateConfig = allTemplateDetails?.[
              item as inAppTemplateIds
            ]?.['blockConfig']?.(transformedTemplateDefaultValuesForPreview);

            const defaultConfigOfTemplate =
              newTemplateConfig[DEFAULT_TEMPLATE_STATE];
            const selectedTemplate = currentTemplate === item;

            return (
              <button
                className={`${
                  selectedTemplate ? 'border-[3px] border-[#FF006A]' : ''
                } rounded-lg bg-white text-night-300`}
                key={item}
                onClick={() => {
                  if (currentTemplate === item) return;

                  // data is also set in the useEffects of inAppPreview
                  // if any data related errors check there also

                  $templateConfigValues.set(templateDefaultValues);
                  $templateConfigCurrentState.set(DEFAULT_TEMPLATE_STATE);
                  $currentTemplate.set(item as inAppTemplateIds);
                }}
              >
                <div className="pointer-events-none">
                  <InAppNotification
                    hasPreviewImage={Boolean(
                      templateDefaultValues?.[`${DEFAULT_TEMPLATE_STATE}_icon`],
                    )}
                    previewImageSrc={
                      templateDefaultValues?.[
                        `${DEFAULT_TEMPLATE_STATE}_icon`
                      ] as string
                    }
                    previewMessage={
                      templateDefaultValues?.[
                        `${DEFAULT_TEMPLATE_STATE}_content`
                      ] as string
                    }
                    previewTitle={
                      templateDefaultValues?.[`${'preview'}_title`] as string
                    }
                    templateConfig={defaultConfigOfTemplate}
                    isPreviewView={true}
                  />
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const TemplateCategories = ({ filter }: { filter: string }) => {
  // No Search
  const templatesBasedOnCateogryMapping = Object.keys(
    allTemplateDetails ?? {},
  ).reduce(
    (acc, curr) => {
      const templateInfo = allTemplateDetails?.[curr as inAppTemplateIds]?.info;
      const category = templateInfo.category;

      return {
        ...acc,
        [category]: [...((acc as any)?.[category] ?? []), curr],
      };
    },
    {} as { [x: string]: string[] },
  );

  const sortedTemplatesBasedOnCateogryMapping = Object.keys(
    allTemplateCategories,
  ).reduce((acc, item) => {
    return {
      ...acc,
      [item]: templatesBasedOnCateogryMapping[item],
    };
  }, {});

  // Search
  const allTemplateInfoList = Object.keys(allTemplateDetails).reduce(
    (acc, curr) => {
      return [...acc, allTemplateDetails?.[curr as inAppTemplateIds]?.info];
    },
    [],
  );

  const options = {
    includeScore: true,
    keys: ['tags'],
  };

  const fuse = new Fuse(allTemplateInfoList, options);
  const result = fuse.search(filter);

  const templateResults = result.map(item => item.item.template);
  const currentTemplate = useStore($currentTemplate);

  return (
    <div className="flex flex-col gap-3">
      {filter ? (
        // Search
        <TemplateComponents
          categoryLabel={'Search Results'}
          isInitiallyCollapsed={false}
          templateListBasedOnCategory={templateResults}
        ></TemplateComponents>
      ) : (
        // No Search
        <>
          {Object.keys(sortedTemplatesBasedOnCateogryMapping ?? {}).map(
            item => {
              const categoryComponents = (
                sortedTemplatesBasedOnCateogryMapping as any
              )[item];
              const label = (allTemplateCategories as any)[item];
              const isSelectedTemplateCategory = categoryComponents?.some(
                (item: string) => item === currentTemplate,
              );

              const isInitiallyCollapsed = isSelectedTemplateCategory
                ? false
                : true;

              return (
                <TemplateComponents
                  key={item}
                  categoryLabel={label}
                  templateListBasedOnCategory={categoryComponents}
                  isInitiallyCollapsed={isInitiallyCollapsed}
                ></TemplateComponents>
              );
            },
          )}
        </>
      )}
    </div>
  );
};

const TemplateEditorComponents = () => {
  const hasComponent = Object.keys(allTemplateDetails ?? {}).length > 0;
  const [filter, setFilter] = React.useState('');
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;

  return (
    <>
      {hasComponent && channel === 'inApp' ? (
        <div>
          {/* search bar */}
          <div className="mb-4 pt-2">
            <h5 className="mb-2 text-[22px]">Choose a Layout</h5>
            <p className="mb-6 text-sm text-white/30">
              This template will be used for sending Inbox notifications.
            </p>
            <SearchBar filter={filter} setFilter={setFilter} />

            <p className="pb-1 pt-4 text-sm text-white/30">
              Components Template
            </p>
          </div>

          <TemplateCategories filter={filter} />
        </div>
      ) : (
        <NoComponent />
      )}
    </>
  );
};

export default TemplateEditorComponents;
