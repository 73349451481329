import {
  Popover,
  PopoverContent,
  PopoverProps,
  PopoverTrigger,
} from '@chakra-ui/popover';
const PopoverHorizon = (props: {
  extra?: string;
  trigger: JSX.Element;
  content: JSX.Element;
  placement?: PopoverProps['placement'];
  isOpen: boolean;
  onClose: () => void;
}) => {
  const {
    extra,
    trigger,
    content,
    placement = 'bottom',
    isOpen,
    onClose,
  } = props;

  return (
    <Popover
      placement={placement}
      returnFocusOnClose={false}
      closeOnBlur={false}
      isOpen={isOpen}
      onClose={onClose}
    >
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent
        className={`w-max rounded-xl bg-white px-4 py-3 text-sm shadow-xl shadow-shadow-500 dark:!bg-gray-450 dark:shadow-none ${extra}`}
      >
        {content}
      </PopoverContent>
    </Popover>
  );
};

export default PopoverHorizon;
