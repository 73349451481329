import { CustomModal, CustomModalPrimaryButton } from 'components/modal';
import { TextEditor } from 'components/text-editor';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useScheduledStore } from 'store/scheduledStore';

type ViewDetails = {
  data: string;
};

function DetailsModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { scheduled } = useScheduledStore(state => state);

  const { control, setError, clearErrors, reset } = useForm<ViewDetails>({
    reValidateMode: 'onBlur',
    mode: 'onChange',
  });

  React.useEffect(() => {
    reset({
      data: JSON.stringify(scheduled, undefined, 4),
    });
  }, [scheduled, reset]);

  return (
    <CustomModal
      modalCardClassName="!max-w-[650px] h-[600px] w-[650px] !max-h-[650px]"
      modalContentClassName="top-[30%]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="mb-6 text-2xl  font-bold">
        Scheduled Notification Details
      </h1>

      {/* Content */}
      <div
        className={`flex flex-col items-start  break-all justify-center rounded-2xl  bg-clip-border px-3 py-4 `}
      >
        <Controller
          name="data"
          control={control}
          render={({ field: { ref, ...field }, fieldState: { error } }) => {
            return (
              <TextEditor<ViewDetails>
                {...field}
                label=""
                setError={setError}
                clearErrors={clearErrors}
                error={error}
                height="385px"
              />
            );
          }}
        />
      </div>

      <div className="mt-6 flex justify-end gap-4">
        <CustomModalPrimaryButton
          loading={false}
          onClick={onClose}
          text="Close"
          className="w-1/3"
        />
      </div>
    </CustomModal>
  );
}

export default DetailsModal;
