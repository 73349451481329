import { useQueryClient } from '@tanstack/react-query';
import useDeleteClientUser from 'api/client/delete-client-user';
import usePutClientUser from 'api/client/put-client-user';
import useDeleteInvitation from 'api/invitation/delete-invitation';
import useResendInvitation from 'api/invitation/post-resend-invitation';
import { routes } from 'api/routes';
import SelectField from 'components/fields/SelectField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { useTeamStore } from 'store/teamStore';
import useCustomToast from 'utils/use-toast';
import { buttonText, contentText, headingText } from '../variables';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';

function ActionModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { typeOfAction, client, invitationId } = useTeamStore(state => state);
  const { clientId } = useAuthStore(state => state);
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const deleteInvitation = useDeleteInvitation();
  const resendInvitation = useResendInvitation();
  const deleteClientUser = useDeleteClientUser();
  const putClientUser = usePutClientUser();
  const isLoading =
    deleteInvitation.isLoading ||
    resendInvitation.isLoading ||
    deleteClientUser.isLoading;

  const roleOptions = [
    {
      label: 'User',
      value: 'user',
    },
    {
      label: 'Admin',
      value: 'admin',
    },
  ];

  const { control, handleSubmit: formSubmit, reset } = useForm({});

  const createFinalActions = (successMessage: string) => {
    return {
      onSuccess: () => {
        if (typeOfAction === 'delete_user_account') {
          queryClient.invalidateQueries([`${routes['clients']}/clientUsers`]);
        }

        if (typeOfAction === 'edit_user_account') {
          queryClient.invalidateQueries([`${routes['clients']}/clientUsers`]);
        }

        if (typeOfAction === 'delete_invitations') {
          queryClient.invalidateQueries([
            `${routes['clients']}/clientUserInvitations`,
          ]);
        }

        toast.success(successMessage);
        onClose();
      },
      onError: (error: any) => {
        toast.showError(error);
        onClose();
      },
    };
  };

  const handleSubmit = (values: any) => {
    if (typeOfAction === 'edit_user_account') {
      const clientUserId = client?.clientUser.id;

      const data = {
        clientUserId,
        role: values.role as string,
      };

      const finalActions = createFinalActions('user role changed successfully');
      putClientUser.mutate(data, finalActions);
    }

    if (typeOfAction === 'delete_user_account') {
      const clientUserId = client?.clientUser.id;

      const data = {
        clientUserId,
        clientId,
      };

      const finalActions = createFinalActions('user removed successfully');
      deleteClientUser.mutate(data, finalActions);
    }

    if (typeOfAction === 'delete_invitations') {
      const data = {
        invitationId,
        clientId,
      };

      const finalActions = createFinalActions(
        'invitation deleted successfully',
      );
      deleteInvitation.mutate(data, finalActions);
    }

    if (typeOfAction === 'resend_invitations') {
      const data = {
        invitationId,
      };

      const finalActions = createFinalActions('invitation resend successfully');
      resendInvitation.mutate(data, finalActions);
    }
  };

  React.useEffect(() => {
    if (client?.role) {
      reset({
        role: client.role,
      });
    }
  }, [client?.role, reset]);

  return (
    <CustomModal
      modalContentClassName="!min-w-[350px]"
      modalCardClassName="!max-w-[450px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      {typeOfAction === 'edit_user_account' ? (
        <>
          <h1 className="mb-3 text-2xl font-bold">Change User Type</h1>
          <p className="mb-6 text-sm text-[#BFBFBF]">
            Read docs to learn more about Categories and their configurations
          </p>

          <form onSubmit={formSubmit(handleSubmit)}>
            <SelectField
              variant="styled"
              extra="mb-3"
              label={'Role'}
              placeholder="Select Role"
              showIsRequiredAsterisk={false}
              control={control}
              name={'role'}
              options={roleOptions}
            />

            <div className="mt-6 flex gap-4">
              <button
                className="linear flex w-full justify-center rounded-xl bg-night-100 py-3 text-base font-medium text-white transition duration-200  dark:text-white "
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>

              <button
                className={cn(buttonVariants(), 'w-full')}
                type="submit"
                disabled={putClientUser.isLoading}
              >
                Save
                {putClientUser.isLoading && (
                  <LoaderSpinner strokeColor="black" />
                )}
              </button>
            </div>
          </form>
        </>
      ) : (
        <>
          <h1 className="mx-auto mb-[20px] text-2xl font-bold">
            {headingText[typeOfAction]}
          </h1>
          <p className="mx-auto mb-[20px]">{contentText[typeOfAction]}</p>
          <div className="flex gap-2">
            <button
              className={cn(buttonVariants(), 'w-full')}
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {buttonText[typeOfAction]}
              {isLoading && <LoaderSpinner strokeColor="black" />}
            </button>
          </div>
        </>
      )}
    </CustomModal>
  );
}

export default ActionModal;
