import Bell from '@assets/img/3d/color/bell.png';
import { Engagespot, EngagespotProps } from '@engagespot/react-component';
import { useQueryClient } from '@tanstack/react-query';
import { getAcceptInvitation } from 'api/invitation/get-accept-invitation';
import useGetMe from 'api/profile/get-me';
import { routes } from 'api/routes';
import React from 'react';
import { useAuthStore } from 'store/authStore';
import { getDataFromUrlString } from 'utils/functions';
import useCustomToast from 'utils/use-toast';

const EngageSpotInAppComponent = ({
  ENGAGESPOT_API_KEY,
  ENGAGESPOT_BASE_URL,
  isGetStarted = false,
}: {
  ENGAGESPOT_API_KEY: string;
  ENGAGESPOT_BASE_URL?: string;
  isGetStarted?: boolean;
}) => {
  const getMe = useGetMe();
  const toast = useCustomToast();
  const [acceptInvitationLoading, setAcceptInvitationLoading] =
    React.useState(false);
  const queryClient = useQueryClient();
  const { setAppId, setClientId, region } = useAuthStore(state => state);

  //Region alias
  const regionAlias: { [key: string]: string } = {
    'us-west-2': 'us',
    'eu-central-1': 'eu',
    us: 'us',
    eu: 'eu',
  };

  return (
    <>
      {ENGAGESPOT_API_KEY &&
        getMe.data?.clientUser &&
        getMe.data?.clientUser?.email && (
          <Engagespot
            apiKey={ENGAGESPOT_API_KEY}
            {...(isGetStarted ? {} : { baseURL: ENGAGESPOT_BASE_URL })}
            userId={getMe.data?.clientUser?.email}
            // @ts-ignore (need to check why it's throwing error)
            disableNotificationChime={false}
            dataRegion={
              isGetStarted && region
                ? (regionAlias[region] as EngagespotProps['dataRegion'])
                : 'us' // default
            }
            renderEmptyPlaceholderImage={() => (
              <>
                <img src={Bell} width={180} alt=""></img>

                <div className="relative !mt-9 !px-2">
                  <p className="!mb-3 !text-center font-bold  !text-black/70">
                    You are all up to date
                  </p>

                  <p className="!text-center !text-[#807b81]">
                    New notification will appear here whenever there is activity
                  </p>
                </div>
              </>
            )}
            theme={{
              notificationButton: {
                iconFill: '#fff',
                hoverBackground: '#26292f',
              },
              panel: {
                width: '30rem',
                height: isGetStarted ? '520px' : '576px',
              },
              colors: {
                brandingPrimary: 'white',
                colorSecondary: 'white',
              },
              feedItem: {
                hoverBackground: '#ecebfa',
              },
              headerDropdown: {
                iconFill: 'black',
              },
              dropdown: {
                hoverBackground: '#ecebfa',
                menuItemHoverBackground: '#f9f9f9',
              },
              header: {
                fontColor: 'black',
                closeButtonColor: 'black',
              },
            }}
            eventListenersToRun={[
              {
                blockId: 'existing_user_invite_b0_state_1_i0',
                event: 'onClick',
                onEvent: async ({
                  notification,
                  changeNotificationState,
                }: any) => {
                  if (
                    notification.data?.invitationUrl &&
                    !acceptInvitationLoading
                  ) {
                    try {
                      setAcceptInvitationLoading(true);

                      const invitationCode = getDataFromUrlString(
                        notification.data.invitationUrl,
                        'invitationCode',
                      );

                      const workSpaceId = getDataFromUrlString(
                        notification.data.invitationUrl,
                        'workSpaceId',
                      );

                      await getAcceptInvitation(invitationCode);
                      changeNotificationState({ state: 'state_2' });
                      setAcceptInvitationLoading(false);

                      // reset app and client
                      await queryClient.invalidateQueries([routes['clients']]);
                      setAppId(0);
                      setClientId(Number(workSpaceId));
                      toast.success('invitation accepted sucessfully');
                    } catch (e) {
                      setAcceptInvitationLoading(false);
                      toast.showError(e);
                    }
                  }
                },
              },
              {
                blockId: 'welcome-message_b0_state_1_i0',
                event: 'onClick',
                onEvent: ({ notification, event }: any) => {
                  window.open(
                    'https://docs.engagespot.co/docs/introduction/quick-start/',
                    '_blank',
                  );
                },
              },
              {
                blockId: 'welcome-message_b0_state_1_i1',
                event: 'onClick',
                onEvent: ({ notification, event }: any) => {
                  window.open(
                    'https://calendly.com/anand-engagespot/engagespot-demo',
                    '_blank',
                  );
                },
              },
            ]}
          ></Engagespot>
        )}
    </>
  );
};

export default EngageSpotInAppComponent;
