import { yupResolver } from '@hookform/resolvers/yup';
import { useMigrateChanges } from 'api/commits';
import ErrorBox from 'components/error/ErrorBox';
import SelectField from 'components/fields/SelectField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { ArrowBigRight } from 'lucide-react';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { cn } from 'utils/class-merge';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { useGetAppOptions } from '../hooks';

type FormMigrate = {
  publishFrom: string;
  publishTo: string;
};

function MigrateModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const toast = useCustomToast();
  const { appId } = useAuthStore(state => state);
  const migrateChanges = useMigrateChanges();
  const { appsOptions, appsOptionsWithHigherOrderThanCurrentApp } =
    useGetAppOptions();

  const schema = yup.object({
    publishTo: yup.string().required('Migrate to is required'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<FormMigrate>({
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    setValue('publishFrom', String(appId));
    setValue(
      'publishTo',
      String(appsOptionsWithHigherOrderThanCurrentApp?.[0]?.value),
    );
  }, [appId, appsOptionsWithHigherOrderThanCurrentApp, setValue]);

  const handleClose = () => {
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormMigrate> = values => {
    const data = {
      appId,
      publishTo: Number(values.publishTo),
    };

    const finalActions = getFinalActions('changes migrated successfully');
    migrateChanges.mutate(data, finalActions);
  };

  return (
    <CustomModal
      modalContentClassName="!max-w-[516px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-3 text-2xl font-bold">Migration</h1>

      <p className="mb-6 text-medium text-[#BFBFBF]">
        Move your tested workflow(s) to the selected environment. Once migrated,
        they’ll be live in that environment.
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={cn('flex gap-2 items-center')}>
          <SelectField<FormMigrate>
            variant="styled"
            styleVariant="modal"
            extra="mb-3 w-[45%]"
            label={'Base environment'}
            placeholder="Select Base environment"
            showIsRequiredAsterisk={false}
            control={control}
            name={'publishFrom'}
            options={appsOptions}
            disabled={true}
            extraInputClass="dark:disabled:!bg-night-100 disabled:text-white/70"
          />

          <ArrowBigRight className="w-8 h-8 mx-2 relative top-2 text-[#B9B9B9]" />

          <div className="w-[45%] relative">
            <SelectField<FormMigrate>
              variant="styled"
              styleVariant="modal"
              extra={cn('mb-3')}
              label={'Migrate to'}
              placeholder="Migrate to"
              showIsRequiredAsterisk={false}
              control={control}
              name={'publishTo'}
              options={appsOptionsWithHigherOrderThanCurrentApp}
            />

            <div className="absolute -bottom-7">
              {errors.publishTo && <ErrorBox error={errors.publishTo} />}
            </div>
          </div>
        </div>

        <CustomModalButtons
          containerClassName={errors.publishTo && 'mt-9'}
          handleClose={handleClose}
          loading={migrateChanges.isLoading}
          primaryButtonText="Migrate"
        />
      </form>
    </CustomModal>
  );
}

export default MigrateModal;
