import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Toaster } from 'react-hot-toast';
import AdminLayout from 'layouts/admin';
import AuthLayout from 'layouts/auth';
import {
  RouterProvider,
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import '@assets/css/Plugins.css';
import '@assets/css/Workflow.css';
import 'reactflow/dist/style.css';
import React from 'react';

declare global {
  interface Window {
    Intercom: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: process.env.NODE_ENV === 'production',
      refetchOnWindowFocus: process.env.NODE_ENV === 'production',
    },
  },
});

const routes = (
  <>
    <Route path="auth/*" element={<AuthLayout />} />
    <Route path="admin/*" element={<AdminLayout />} />
    {/* <Route path="rtl/*" element={<RTLLayout />} /> */}
    <Route path="/" element={<Navigate to="/auth" replace />} />
    <Route path="*" element={<Navigate to="/auth" replace />} />
  </>
);

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(createRoutesFromElements(routes));

if (import.meta.hot) {
  import.meta.hot.dispose(() => router.dispose());
}

const App = () => {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
      </QueryClientProvider>

      <Toaster position="top-center" />
    </>
  );
};

export default App;
