import { GetLogs } from 'api/logs/get-logs/types';
import { CustomModal } from 'components/modal';
import OrderStatusCard from './OrderStatusCard';

// Needed to show log details
function DetailsModal({
  isOpen,
  onClose,
  selectedUser,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedUser: GetLogs;
}) {
  const json = {
    email: 'jayakichan@gmail.com',
    phoneNumber: '+91 81295-54989',
    name: 'JAYAKRISHNAN K',
  };

  return (
    <CustomModal
      modalContentClassName="!w-max min-w-[750px] !max-w-[85%]"
      modalCardClassName="!max-w-[850px] !max-h-[calc(100vh-150px)]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="mb-3">
        <h1 className="text-2xl  font-bold">Logs</h1>
      </div>

      <div className="mb-4 flex gap-7 font-normal text-white">
        <div>Template ID : 235467890876l</div>
        <div>Workflow Name : Welcome Email</div>
      </div>

      {/* Content */}
      <div className="flex justify-between gap-5">
        {/* Order  Status */}
        <div
          className={`h-[calc(80vh-150px)] w-1/2 overflow-scroll rounded-2xl  bg-clip-border px-3 py-4  dark:!bg-gray-850`}
        >
          {/* <h3 className="mb-5 text-lg font-bold">Order Status</h3> */}

          {[1, 2, 3, 4, 5, 6, 7, 8].map(item => (
            <OrderStatusCard />
          ))}
        </div>

        {/* Request Data */}
        <div
          className={`h-[calc(80vh-150px)] w-1/2 rounded-2xl  bg-clip-border px-3 py-4  dark:!bg-gray-850`}
        >
          <h3 className="mb-5 text-lg font-bold">Request Data</h3>
          <div className="h-[calc(100%-55px)] rounded-2xl bg-[#1B1D20] px-6 py-6">
            <pre>{JSON.stringify(json, null, 2)}</pre>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default DetailsModal;
