import { MdHome, MdLock } from 'react-icons/md';

// Admin Imports
import DashboardsApiSetup from 'views/admin/dashboards/apiCredentials';
import DashboardsBilling from 'views/admin/dashboards/billing';
// import DashboardsDefault from "views/admin/dashboards/default";
import DashboardChannels from 'views/admin/dashboards/channels';
import DashboardsLogs from 'views/admin/dashboards/logs';
import DashboardsProfile from 'views/admin/dashboards/profile';
import DashboardsProviders from 'views/admin/dashboards/providers';
import DashboardsTeams from 'views/admin/dashboards/teams';
import DashboardsTemplateChannels from 'views/admin/dashboards/templateChannels';
import DashboardsTemplateEditor from 'views/admin/dashboards/templateEditor';
import DashboardsUsers from 'views/admin/dashboards/users';
import DashboardsAnalytics from 'views/admin/dashboards/analytics';
import DashboardCategories from 'views/admin/dashboards/categories';
import DashboardComposeSend from 'views/admin/dashboards/compose/send';
import DashboardComposeScheduled from 'views/admin/dashboards/compose/scheduled';
import DashboardTemplate from 'views/admin/dashboards/template';
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCenter';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault';
import ResetPasswordDefault from 'views/auth/resetPassword/resetPasswordDefault';
import SignInDefault from 'views/auth/signIn/SignInDefault';
import SignUpDefault from 'views/auth/signUp/SignUpDefault';
import TwoFactorAuth from 'views/auth/verification/2FactorAuth';
import VerificationDefault from 'views/auth/verification/VerificationDefault';
import VerificationEmail from 'views/auth/verificationEmail';
import DashboardSettings from 'views/admin/dashboards/settings';
import DashboardAccountSettings from 'views/admin/dashboards/account-settings';
import DashboardGetStarted from 'views/admin/dashboards/get-started';
import DashboardTopics from 'views/admin/dashboards/topics';
import DashboardWorkflowEditor from 'views/admin/dashboards/workflowEditor';
import DashboardWorkflow from 'views/admin/dashboards/workflow';
import DashboardTranslations from 'views/admin/dashboards/translations';
import DashboardLayouts from 'views/admin/dashboards/layouts';
import DashboardTranslationEditor from 'views/admin/dashboards/translationEditor';
import DashboardLayoutEditor from 'views/admin/dashboards/layoutEditor';
import DashboardWorkflowOverView from 'views/admin/dashboards/workflow-overview';
import DashboardCommits from 'views/admin/dashboards/commits';
import DashboardCliTokens from 'views/admin/dashboards/cli-token';

const routes = [
  {
    name: 'Authentication',
    path: '/auth',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/login',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/login',
            component: <SignInDefault />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/register',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/register',
            component: <SignUpDefault />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification',
            component: <VerificationDefault />,
          },
        ],
      },
      // --- Verification Email ---
      {
        name: 'Verification Email',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verify_email',
            component: <VerificationEmail />,
          },
        ],
      },
      // --- 2Auth ---
      {
        name: 'Two Factor',
        path: '/two-factor',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/two-factor',
            component: <TwoFactorAuth />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: <ForgotPasswordDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },
  {
    name: 'Admin',
    path: '/admin',
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // {
      //   name: "Main Dashboard",
      //   layout: "/admin",
      //   path: "/default",
      //   component: <DashboardsDefault />,
      // },
      {
        name: 'API Credentials',
        layout: '/admin',
        path: '/api-credentials',
        component: <DashboardsApiSetup />,
      },
      {
        name: 'Team',
        layout: '/admin',
        path: '/settings/teams',
        component: <DashboardsTeams />,
      },
      {
        name: 'Commits',
        layout: '/admin',
        path: '/version-control',
        component: <DashboardCommits />,
      },
      {
        name: 'Channels',
        layout: '/admin',
        path: '/channels',
        component: <DashboardChannels />,
      },
      {
        name: 'Topics',
        layout: '/admin',
        path: '/topics',
        component: <DashboardTopics />,
      },
      {
        name: 'Provider',
        layout: '/admin',
        path: '/provider/:channel',
        component: <DashboardsProviders />,
      },
      {
        name: 'Users',
        layout: '/admin',
        path: '/users',
        component: <DashboardsUsers />,
      },
      {
        name: 'Analytics',
        layout: '/admin',
        path: '/analytics',
        component: <DashboardsAnalytics />,
      },
      {
        name: 'Settings',
        layout: '/admin',
        path: '/settings/app',
        component: <DashboardSettings />,
      },
      {
        name: 'Settings',
        layout: '/admin',
        path: '/settings/cli-tokens',
        component: <DashboardCliTokens />,
      },
      {
        name: 'Account Settings 2',
        layout: '/admin',
        path: '/account-settings',
        component: <DashboardAccountSettings />,
      },
      {
        name: 'Logs',
        layout: '/admin',
        path: '/logs',
        component: <DashboardsLogs />,
      },
      {
        name: 'Profile',
        layout: '/admin',
        path: '/profile',
        component: <DashboardsProfile />,
      },
      {
        name: 'Billing Settings',
        layout: '/admin',
        path: '/settings/billing',
        component: <DashboardsBilling />,
      },
      {
        name: 'Categories',
        layout: '/admin',
        path: '/categories',
        component: <DashboardCategories />,
      },
      {
        name: 'Templates',
        layout: '/admin',
        path: '/template',
        component: <DashboardTemplate />,
      },
      {
        name: 'Template Channels',
        layout: '/admin',
        path: '/template/:templateId',
        component: <DashboardsTemplateChannels />,
      },
      {
        name: 'Templates Editor',
        layout: '/admin',
        path: '/template-editor/:templateId/:channel/:templateIdentifier/:type',
        component: <DashboardsTemplateEditor />,
      },
      {
        name: 'Compose',
        layout: '/admin',
        path: '/compose/send',
        component: <DashboardComposeSend />,
      },
      {
        name: 'Compose',
        layout: '/admin',
        path: '/compose/scheduled',
        component: <DashboardComposeScheduled />,
      },
      {
        name: 'Get Started',
        layout: '/admin',
        path: '/get-started',
        component: <DashboardGetStarted />,
      },
      {
        name: 'Workflow',
        layout: '/admin',
        path: '/workflow',
        component: <DashboardWorkflow />,
      },
      {
        name: 'Workflow',
        layout: '/admin',
        path: '/workflow-overview/:workflowId',
        component: <DashboardWorkflowOverView />,
      },
      {
        name: 'Translations',
        layout: '/admin',
        path: '/translation',
        component: <DashboardTranslations />,
      },
      {
        name: 'Layouts',
        layout: '/admin',
        path: '/layout',
        component: <DashboardLayouts />,
      },
      {
        name: 'Translation editor',
        layout: '/admin',
        path: '/translation-editor/:translationId',
        component: <DashboardTranslationEditor />,
      },
      {
        name: 'Layout editor',
        layout: '/admin',
        path: '/layout-editor/:layoutId',
        component: <DashboardLayoutEditor />,
      },
      {
        name: 'Workflow Editor',
        layout: '/admin',
        path: '/workflow-editor/:workflowId',
        component: <DashboardWorkflowEditor />,
      },
    ],
  },
  // --- Forgot Password ---
  {
    name: 'Forgot Password',
    path: '/forgot-password',
    collapse: true,
    items: [
      {
        name: 'Default',
        layout: '/auth',
        path: '/forgot-password',
        component: <ForgotPasswordDefault />,
      },
    ],
  },
  // Reset Password
  {
    name: 'Reset Password',
    path: '/reset_password',
    collapse: true,
    items: [
      {
        name: 'Default',
        layout: '/auth',
        path: '/reset_password',
        component: <ResetPasswordDefault />,
      },
    ],
  },
];

export default routes;
