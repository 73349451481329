import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { PostChannelRoutes } from './post-channel-routes';

const put = async (values: PostChannelRoutes) => {
  const { routeId, ...data } = values;
  return axiosInstance.put(`${routes['routes']}/${routeId}`, data);
};

const usePutChannelRoutes = () => {
  return useMutation({
    mutationFn: put,
  });
};

export default usePutChannelRoutes;
