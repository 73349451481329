import { resetDocument } from 'email-editor/documents/editor/EditorContext';
import EMPTY_EMAIL_MESSAGE from 'email-editor/getConfiguration/sample/empty-email-message';
import { CssBaseline, ThemeProvider } from '@mui/material';
import App from './App';
import theme from './theme';
import CustomErrorBoundary from 'components/error/ErrorBoundary';

const CustomEmailEditor = ({ preview = false }: { preview?: boolean }) => {
  return (
    <CustomErrorBoundary
      onReset={() => {
        resetDocument(EMPTY_EMAIL_MESSAGE);
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App preview={preview} />
      </ThemeProvider>
    </CustomErrorBoundary>
  );
};

export default CustomEmailEditor;
