import FileField from 'components/fields/FileField';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

const fileTypeOptions = [
  {
    label: 'Url',
    value: 'url',
  },
  {
    label: 'File',
    value: 'file',
  },
];

export const FileInputSelect = ({
  register,
  control,
  setValue,
  label,
  name,
  disabled = false,
  placeholder,
  required = false,
  parentClass = '',
}: {
  register: UseFormRegister<any>;
  control: Control<any, any>;
  setValue: UseFormSetValue<any>;
  label: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  name: string;
  parentClass?: string;
}) => {
  const uploadType = useWatch({
    control,
    name: `${name}_select`,
  });

  return (
    <div className={`flex items-center ${parentClass}`}>
      {uploadType === 'url' ? (
        <InputField<any>
          variant="auth"
          label={label}
          placeholder={placeholder ?? 'https://example.com'}
          type="text"
          showIsRequiredAsterisk={Boolean(required)}
          register={register}
          name={name}
          extraLabelClass={'font-medium'}
          extraInputClass={
            'h-[40.5px] dark:bg-night-100 border !border-gray-440 !rounded-l-md !rounded-r-[0px]'
          }
          required={Boolean(required)}
          extra="w-full"
          disabled={disabled}
        />
      ) : (
        <FileField<any>
          control={control}
          setValue={setValue}
          label={label}
          name={name}
          showIsRequiredAsterisk={Boolean(required)}
          required={Boolean(required)}
          extraInputClass={'border border-gray-440 dark:bg-night-100'}
          variant="small"
          disabled={disabled}
        />
      )}

      <SelectField
        variant="styled"
        control={control}
        extra={`w-[20%]  !rounded-r-md self-end border-gray-440 ${disabled ? '' : 'border'}`}
        label={''}
        placeholder=""
        showIsRequiredAsterisk={false}
        name={`${name}_select`}
        extraInputClass="dark:!bg-night-100 border-0  dark:disabled:!bg-white/5 !rounded-r-md h-[39px] "
        options={fileTypeOptions}
        onChange={(value: string) => {
          if (value === '') return;
          setValue(name, '');
          setValue(`${name}_select`, value);
        }}
        disabled={disabled}
        defaultValue="file"
      />
    </div>
  );
};
