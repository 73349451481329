import { Channels } from 'api/channels/get-channels/types';
import useGetTemplateContent from 'api/templates/get-template-content';
import { GetTemplateContent } from 'api/templates/get-template/types';
import { useGetWorkflowJson, useGetWorkflowStateSpec } from 'api/workflows';
import React from 'react';
import { useParams } from 'react-router-dom';
import { findSelectedNodeFromJson } from '../../workflowEditor/functions';
import { useGetEditorType } from './getEditorType';

export const useGetTemplateChannelData = () => {
  const params = useParams();
  const channel = params.channel as Channels;
  const templateId = params.templateId;
  const templateIdentifier = params.templateIdentifier;
  const { editorTypeTemplate, editorTypeWorkflow } = useGetEditorType();

  const getWorkflowStateSpec = useGetWorkflowStateSpec(
    Number(templateId),
    templateIdentifier,
    editorTypeWorkflow,
  );

  const getWorkflowJson = useGetWorkflowJson(
    Number(templateId),
    editorTypeWorkflow,
  );

  const getTemplateContent = useGetTemplateContent(
    Number(templateId),
    channel,
    editorTypeTemplate,
  );

  const workflowChannelData = React.useMemo(() => {
    if (
      editorTypeWorkflow &&
      getWorkflowJson.data &&
      !getWorkflowStateSpec.isLoading &&
      !getWorkflowStateSpec.isFetching &&
      !getWorkflowJson.isLoading
    ) {
      const workflowJson = getWorkflowJson?.data;
      const selectedNodeJson = findSelectedNodeFromJson({
        steps: workflowJson?.jsonSpec?.steps,
        selectedNodeId: templateIdentifier,
      });
      const workflowStateSpec = getWorkflowStateSpec?.data;
      const template = selectedNodeJson?.template;
      const config = selectedNodeJson?.config as GetTemplateContent;
      const type = selectedNodeJson?.type;

      // fetch function same as webhook channel
      return {
        content: type === 'fetch' ? config : template,
        batchingContent: {} as GetTemplateContent,
        ...(Boolean(workflowStateSpec)
          ? {
              states: [
                {
                  id: workflowStateSpec?.id,
                  state: workflowStateSpec?.state,
                  stateSpec: workflowStateSpec?.stateSpec,
                },
              ],
            }
          : {
              states: [],
            }),
        batchingEnabled: selectedNodeJson?.batchingEnabled,
        batchingWindow: selectedNodeJson?.batchingWindow,
      };
    }
  }, [
    editorTypeWorkflow,
    getWorkflowJson.data,
    getWorkflowJson.isLoading,
    getWorkflowStateSpec.isLoading,
    getWorkflowStateSpec.isFetching,
    getWorkflowStateSpec?.data,
    templateIdentifier,
  ]);

  if (editorTypeWorkflow) {
    return workflowChannelData;
  } else {
    const getTemplateChannelData = getTemplateContent.data?.contents?.find(
      content => content.channel === channel,
    );

    return getTemplateChannelData;
  }
};
