export const BlockInput = ({
  heading,
  text,
  className,
}: {
  heading?: string;
  text: string;
  className?: string;
}) => {
  return (
    <div className={`w-full ${className}`}>
      {heading && (
        <span className="text-white ml-1.5  !text-base font-medium dark:!text-gray-10">
          {heading}
        </span>
      )}

      <div className="border border-gray-440 w-full h-12 mt-2 rounded-md p-3 text-[#ABB0B8]">
        {text}
      </div>
    </div>
  );
};
