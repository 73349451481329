import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import Card from 'components/card';
import Switch from 'components/switch';
import useFunctionDebounce from 'utils/use-debounce';
import Header from './Header';
import TextContent from './TextContent';
import React from 'react';
import usePutSecureAuth from 'api/app/put-secure-auth';
import useCustomToast from 'utils/use-toast';

function SecureAuthMode({
  appId,
  isEnabled,
}: {
  appId: number;
  isEnabled: boolean;
}) {
  const putSecureAuth = usePutSecureAuth();
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = React.useState(isEnabled);
  const toast = useCustomToast();

  React.useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled]);

  const handleVerification = (checked: boolean) => {
    if (!appId) return;

    const data = {
      appId,
    };

    if (checked === isEnabled) return;

    putSecureAuth.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries([routes['apps'], appId]);
        // !, because enabled is asynchronous and at this stage value is not updated
        toast.success(`secure auth ${!enabled ? 'activated' : 'disabled'} `);
      },
    });
  };

  const optimisedHandleVerfication = useFunctionDebounce(handleVerification);

  return (
    <Card extra={'pb-8 px-6 pt-6'}>
      <Header>Secure Auth Mode for In-App Inbox</Header>

      <TextContent>
        <p>
          Enabling secure mode enforces token based authentication for the
          front-end in-app inbox. Production apps <b>must</b> enable secure mode
          to prevent unauthorised access to your users in-app inbox. <br />
          <br />
          ⚠️ Make sure you have configured the front-end in-app library before
          enabling secure mode. Read{' '}
          <a
            rel="noreferrer"
            className="text-decoration-line: underline"
            href="https://docs.engagespot.com/docs/features/in-app-inbox/authentication#enabling-the-secure-mode"
            target="_blank"
          >
            this guide
          </a>
        </p>
      </TextContent>

      <div className="mt-4 flex items-center gap-3 ">
        <Switch
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const checked = e.target.checked;
            setEnabled(checked);
            optimisedHandleVerfication(checked);
          }}
          checked={enabled}
        />
      </div>
    </Card>
  );
}

export default SecureAuthMode;
