import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { GetTemplateContents } from '../get-template/types';

const get = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, string]
>): Promise<GetTemplateContents> => {
  const [, templateId, channel] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['templates']}/${templateId}/contents/${channel}`,
  );
  return data;
};

const useGetTemplateContent = (
  templateId: number,
  channel: string,
  enabled: boolean = true,
) => {
  return useQuery({
    queryKey: [`${routes['templates']}`, templateId, channel],
    queryFn: get,
    enabled: Boolean(templateId && channel && enabled),
    staleTime: 1000 * 5, // 5 seconds
    refetchOnWindowFocus: false,
  });
};

export default useGetTemplateContent;
