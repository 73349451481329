import { format, parseISO } from 'date-fns';
import React from 'react';

import { GetClientInvitations } from 'api/client/get-client-invitations.ts/types';
import Chip, { chipBgColors } from 'components/chip';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import { Mail } from 'lucide-react';
import { useGetSelectedWorkspace } from 'utils/get-workspace';
import CardMenu from './CardMenu';

function InvitationTable(props: {
  tableData: any;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  onOpen: () => void;
  loading: boolean;
  onCreateOpen: () => void;
}) {
  const selectedWorkspace = useGetSelectedWorkspace();
  const { tableData, globalFilter, onOpen, loading, onCreateOpen } = props;

  const columnConfig: ColumnConfig<GetClientInvitations> = [
    {
      accessorKey: 'email',
      header: () => 'Email',
      cellFunction: (info: any) => (
        <p className="py-2 font-medium text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    },
    {
      accessorKey: 'updatedAt',
      header: () => 'Invite Date',
      cellFunction: info => (
        <p className="text-sm font-bold text-navy-700 dark:text-white">
          {info.getValue()
            ? format(parseISO(info.getValue()), 'd MMMM yyyy, h:mm aaa')
            : ''}
        </p>
      ),
    },
    {
      accessorKey: 'invitedBy.source',
      header: () => 'Status',
      cellFunction: info => (
        <div className="w-fit">
          <Chip text="Pending" className={`${chipBgColors['red']}`} />
        </div>
      ),
    },
    {
      accessorKey: 'role',
      header: () => 'Role',
      cellFunction: info => (
        <div className="w-fit">
          <Chip
            text={info.getValue()}
            className="bg-green-50 capitalize text-white dark:!bg-gray-450 dark:hover:opacity-90 dark:active:opacity-80"
          />
        </div>
      ),
    },
    {
      accessorKey: 'id',
      header: () => 'Actions',
      cellFunction: info => (
        <>
          {(selectedWorkspace?.role === 'owner' ||
            selectedWorkspace?.role === 'admin') && (
            <CardMenu invitationId={info.row.original.id} onOpen={onOpen} />
          )}
        </>
      ),
    },
  ];

  const columns = columnGenerator<GetClientInvitations>({ columnConfig });

  return (
    <CustomTable
      emptyTableMessage="No worries! Send an invitation"
      tableData={tableData}
      columns={columns}
      paginationType="table"
      globalFilter={globalFilter}
      loading={loading}
      emptyAdditionalJsx={() => (
        <EmptyTableMessage
          secondaryButtonText="Invite"
          handleCreate={() => {
            onCreateOpen();
          }}
          searchInput={globalFilter}
          heading={"You don't have any pending invitations!"}
          message={
            'Invite your team to join your workspace, where you can connect, collaborate, and explore all its features.'
          }
          searchEmptyHeading={getSearchEmptyHeading('invitations')}
          searchEmptyMessage={getSearchEmptyMessage('invitations')}
          hidePrimary={true}
          Icon={Mail}
        />
      )}
    />
  );
}

export default InvitationTable;
