import { useQueryClient } from '@tanstack/react-query';
import usePatch2Fa from 'api/2fa/patch-2fa';
import { routes } from 'api/routes';
import { CustomModal, CustomModalPrimaryButton } from 'components/modal';
import useCustomToast from 'utils/use-toast';

function DisableTwoFactorModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const patch2Fa = usePatch2Fa();
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    patch2Fa.mutate(
      {
        type: 'disable',
      },
      {
        onSuccess: response => {
          toast.success(response.data.message);
          queryClient.invalidateQueries([routes['me']]);
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomModal
      modalContentClassName="!min-w-[350px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="mx-auto mb-[20px] text-2xl font-bold">
        Disable Two Factor
      </h1>

      <p className="mx-auto mb-[20px]">
        Are you sure to disable two factor authentication?
      </p>

      <div className="flex gap-2">
        <CustomModalPrimaryButton
          loading={patch2Fa.isLoading}
          text="Disable"
          onClick={handleSubmit}
        />
      </div>
    </CustomModal>
  );
}

export default DisableTwoFactorModal;
