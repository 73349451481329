import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import ErrorBox from 'components/error/ErrorBox';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { STRIPE_RETURN_URL } from 'config';
import React from 'react';
import { isEnterpriseEdition } from 'utils/get-editions';
import { StripePayment, StripePaymentWithPromise } from '../types';
import useCustomToast from 'utils/use-toast';
import { useBillingStore } from 'store/billingStore';
import { centsToDollars, getCurrencySymbol } from 'utils/get-currency';

const StripePaymentForm = ({
  paymentIntent,
  mode,
  promise,
}: StripePaymentWithPromise) => {
  const enterpriseEdition = isEnterpriseEdition();

  return (
    <>
      {enterpriseEdition && promise && (
        <Elements
          stripe={promise}
          options={{
            clientSecret: paymentIntent.client_secret,
            appearance: {
              rules: {
                '.Label': {
                  color: 'white',
                  marginBottom: '8px',
                },
              },
            },
          }}
        >
          <CheckoutForm paymentIntent={paymentIntent} mode={mode} />
        </Elements>
      )}
    </>
  );
};

const CheckoutForm = ({
  mode,
  paymentIntent,
}: {
  mode: StripePayment['mode'];
  paymentIntent: StripePaymentWithPromise['paymentIntent'];
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const toast = useCustomToast();
  const { plan: selectedPlan } = useBillingStore(state => state);
  const currencySymbol = getCurrencySymbol();

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!STRIPE_RETURN_URL) {
      toast.error('Please add stripe return url');
    }

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    if (mode === 'setup') {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: STRIPE_RETURN_URL,
        },
      });

      if (result.error) {
        setErrorMessage(
          result.error.message ?? 'An unexpected error occurred.',
        );
      }
    }

    if (mode === 'payment') {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: STRIPE_RETURN_URL,
        },
      });

      if (result.error) {
        setErrorMessage(
          result.error.message ?? 'An unexpected error occurred.',
        );
      }
    }

    setLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      {selectedPlan && (
        <div className="mb-3 flex flex-col">
          <h5 className="text-[1.35rem] font-normal  text-white/70">
            {selectedPlan.name}
          </h5>
          <p className="text-[2.8rem] font-medium">
            {currencySymbol}
            {centsToDollars(paymentIntent.amount)}{' '}
            <span className="relative right-2 text-base text-[#b6b0a6]">
              /month
            </span>
          </p>
          <p className="text-lg text-[#e29e37]">{selectedPlan.plan_tagline}</p>
        </div>
      )}

      <PaymentElement />

      {errorMessage && <ErrorBox error={errorMessage} />}
      <button
        disabled={loading}
        className="linear mt-4 flex w-full justify-center rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
        type="submit"
      >
        Pay
        {loading && <LoaderSpinner />}
      </button>
    </form>
  );
};

export default StripePaymentForm;
