import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { DateRange } from 'react-day-picker';
import { getAnalyticsDateParams } from '../functions';
import { TemplateNotificationAnalytics } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, string, DateRange, number, number]
>) => {
  const [, appId, templateId, date, pageNo, limit] = queryKey;
  const params = getAnalyticsDateParams(date);
  if (!params) return null;

  const { data } = await axiosInstance.get<TemplateNotificationAnalytics>(
    `${routes['analytics']}/apps/${appId}/templates/${templateId}/notifications`,
    {
      params: {
        ...params,
        page: pageNo,
        limit,
      },
    },
  );

  return data;
};

const useGetTemplateNotificationAnalytics = ({
  appId,
  templateId,
  date,
  pageIndex,
  pageSize,
}: {
  appId: number;
  templateId: string;
  date: DateRange;
  pageIndex: number;
  pageSize: number;
}) => {
  return useQuery({
    queryKey: [
      `${routes['analytics']}/templates/notifications`,
      appId,
      templateId,
      date,
      pageIndex,
      pageSize,
    ],
    queryFn: get,
    enabled: Boolean(appId) && Boolean(templateId),
  });
};

export default useGetTemplateNotificationAnalytics;
