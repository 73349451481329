import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/select';
import { Controller } from 'react-hook-form';
import { SelectFieldProps } from '../../types';
import { cn } from 'utils/class-merge';

const StyledSelectVariant = <T,>({
  label,
  id,
  extra,
  placeholder,
  disabled,
  onChange,
  showIsRequiredAsterisk = false,
  required,
  name,
  extraInputClass,
  extraLabelClass,
  options,
  control,
  defaultValue,
  extraItemClass,
  styleVariant,
  customLabelContent,
}: SelectFieldProps<T>) => {
  return (
    <div className={`flex flex-col ${extra}`}>
      {customLabelContent && customLabelContent()}

      {label && (
        <label
          htmlFor={id}
          className={`mb-2 ml-1.5 text-sm font-medium text-navy-700 dark:text-white ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}

      <>
        <Controller
          name={name}
          control={control}
          rules={{ required }}
          render={({ field }) => (
            <Select
              disabled={disabled}
              onValueChange={value => {
                if (onChange) {
                  onChange(value);
                } else {
                  if (value === '') return;
                  field.onChange(value);
                }
              }}
              value={field.value as string}
              defaultValue={defaultValue}
            >
              <SelectTrigger
                className={cn(
                  'h-[43px] w-full border border-gray-440',
                  styleVariant === 'modal'
                    ? 'dark:!bg-gray-150 border border-gray-880'
                    : styleVariant === 'workflow'
                      ? 'dark:!bg-night-400 border border-gray-930 data-[placeholder]:!text-[#ABB0B8]'
                      : '',
                  extraInputClass,
                  '',
                )}
              >
                <SelectValue placeholder={placeholder} />
              </SelectTrigger>
              <SelectContent className="!z-[1003] max-h-56">
                {options?.map(option => (
                  <SelectItem
                    key={option.value}
                    className={`${extraItemClass ?? ''}`}
                    value={option.value}
                  >
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          )}
        />
      </>
    </div>
  );
};

export default StyledSelectVariant;
