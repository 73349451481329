import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';

const post = async <T>(values: T & { redirect?: string }) => {
  const { redirect = '', ...data } = values;

  return axiosGlobalInstance.post(
    `${routes['forgotPassword']}${redirect}`,
    data,
  );
};

const usePostForgotPassword = <T>() => {
  return useMutation({
    mutationFn: post<T>,
  });
};

export default usePostForgotPassword;
