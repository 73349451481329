import { GetClientUsers } from 'api/client/get-client-users.ts/types';
import Chip from 'components/chip';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import TableMenu from 'components/table/table-menu';
import React from 'react';
import { TeamAction, useTeamStore } from 'store/teamStore';
import { useGetSelectedWorkspace } from 'utils/get-workspace';

function MembersTable(props: {
  tableData: any;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  onOpen: () => void;
  loading: boolean;
}) {
  const selectedWorkspace = useGetSelectedWorkspace();
  const { setTypeOfAction, setClient } = useTeamStore(state => state);
  const { tableData, globalFilter, onOpen, loading } = props;

  const handleClientUserDeleteOrEdit = (
    client: GetClientUsers,
    action: TeamAction,
  ) => {
    setTypeOfAction(action);
    setClient(client);
    onOpen();
  };

  const columnConfig: ColumnConfig<GetClientUsers> = [
    {
      accessorKey: 'clientUser.firstName',
      header: () => 'Name',
      cellFunction: info => (
        <div className="flex w-full items-center gap-[12px]">
          {info.row.original.clientUser?.profilePicture ? (
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-300">
              <img
                className="h-full w-full rounded-full bg-cover bg-no-repeat"
                src={info.row.original.clientUser?.profilePicture}
                alt=""
              />
            </div>
          ) : (
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-night-100 text-white">
              {info.row.original.clientUser.firstName?.charAt(0).toUpperCase()}
            </div>
          )}

          <p className="font-medium text-navy-700 dark:text-white">
            {info.getValue()}
          </p>
        </div>
      ),
    },
    {
      accessorKey: 'clientUser.email',
      header: () => 'Email',
      cellFunction: info => (
        <p className="font-medium text-navy-700 dark:text-white">
          {info.getValue()}
        </p>
      ),
    },
    {
      accessorKey: 'clientUser.emailVerified',
      header: () => 'Status',
      cellFunction: info => (
        <div className="w-fit">
          <Chip text="Active" />
        </div>
      ),
    },
    {
      accessorKey: 'role',
      header: () => 'Role',
      cellFunction: info => (
        <div className="w-fit">
          <Chip
            text={info.getValue()}
            className="bg-green-50 capitalize text-white dark:!bg-gray-450 dark:hover:opacity-90 dark:active:opacity-80"
          />
        </div>
      ),
    },
    {
      accessorKey: 'clientUser.timezone',
      header: () => 'Actions',
      cellFunction: info => (
        <div className="flex gap-0">
          {info.row.original.role !== 'owner' &&
            selectedWorkspace?.role === 'owner' && (
              <TableMenu
                config={[
                  {
                    text: 'Edit',
                    action: () =>
                      handleClientUserDeleteOrEdit(
                        info.row.original,
                        'edit_user_account',
                      ),
                  },
                  {
                    text: 'Delete',
                    action: () =>
                      handleClientUserDeleteOrEdit(
                        info.row.original,
                        'delete_user_account',
                      ),
                  },
                ]}
              />
            )}
        </div>
      ),
    },
  ];

  const columns = columnGenerator<GetClientUsers>({ columnConfig });

  return (
    <CustomTable
      emptyTableMessage="No worries! Add a member"
      extraTdClass="!pl-2"
      tableData={tableData}
      columns={columns}
      paginationType="table"
      globalFilter={globalFilter}
      loading={loading}
    />
  );
}

export default MembersTable;
