import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type DeleteClientUser = {
  appId: number;
};

const remove = async (values: DeleteClientUser) => {
  const { appId } = values;
  return axiosInstance.delete(`${routes['apps']}/${appId}`);
};

const useDeleteApp = () => {
  return useMutation({
    mutationFn: (values: DeleteClientUser) => remove(values),
  });
};

export default useDeleteApp;
