import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetPlanUsage } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetPlanUsage> => {
  const [, clientId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['clients']}/${clientId}/usage`,
  );

  return data;
};

const useGetPlanUsage = () => {
  const { clientId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/usage`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
    staleTime: 5 * 1000, // 5 seconds
  });
};

export default useGetPlanUsage;
