import * as yup from 'yup';

const MAX_TIME_VALUE = 999;
const TIME_VALUE_EXCEED_MESSAGE = 'value must be less than or equal to 999';

const variableDelayForSchema =
  /^\s*(?:(?:\d+|\$dateTime\(\s*(?:data\.[\w.]+|'\d{4}(?:-\d{2}){0,2}(?:'T'\d{2}(?::\d{2}){0,2}(?:\.\d{3})?(?:[+-]\d{2}:\d{2})?)?')\s*\))\s*([+\-*/]\s*(?:\d+|\$dateTime\(\s*(?:data\.[\w.]+|'\d{4}(?:-\d{2}){0,2}(?:'T'\d{2}(?::\d{2}){0,2}(?:\.\d{3})?(?:[+-]\d{2}:\d{2})?)?')\s*\))\s*)*)$/;

const comprehensiveErrorMessage =
  'Invalid input: Use numeric delay (6000) or $dateTime function with data expression or ISO date (YYYY-MM-DD in single quotes)';

export const timeSchema = yup.object({
  days: yup
    .number()
    .required()
    .max(MAX_TIME_VALUE, TIME_VALUE_EXCEED_MESSAGE)
    .transform(value => (isNaN(value) ? 0 : value)),
  hours: yup
    .number()
    .required()
    .max(MAX_TIME_VALUE, TIME_VALUE_EXCEED_MESSAGE)
    .nullable()
    .transform(value => (isNaN(value) ? 0 : value)),
  mins: yup
    .number()
    .required()
    .max(MAX_TIME_VALUE, TIME_VALUE_EXCEED_MESSAGE)
    .transform(value => (isNaN(value) ? 0 : value)),
  secs: yup
    .number()
    .required()
    .max(MAX_TIME_VALUE, TIME_VALUE_EXCEED_MESSAGE)
    .transform(value => (isNaN(value) ? 0 : value)),
  delayFor: yup
    .string()
    .matches(variableDelayForSchema, comprehensiveErrorMessage),
});
