// import EngageSpotIcon from "assets/img/others/engagespot-icon.png";
import { componentBlockConfig } from 'templates/types';
import { createDefaultInAppConfig } from 'templates/utils';
import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';
import { oneButtonTemplate } from '../one_button/config';
import { bannerImageTemplate } from 'templates/banner_image/config';

/**
 * used in setting initial inapp api data
 * and also when watching form value changes
 */
const defaultValues = {
  // state_1
  ...bannerImageTemplate.defaultValues,
  ...oneButtonTemplate.defaultValues,

  // preview
  preview_title:
    '<span><b>John Doe</b> invited you to Engagespot workspace</span>',
  preview_button1_label: 'Accept',
};

const getBlockConfig = (values = defaultValues): componentBlockConfig => {
  const bannerConfig = bannerImageTemplate.blockConfig(values);
  const oneButtonConfig = oneButtonTemplate.blockConfig(values);

  return {
    state_1: [...bannerConfig.state_1, ...oneButtonConfig.state_1],
    state_2: [],
  };
};

const state_1Config = createDefaultInAppConfig();
const { template } = state_1Config;

const bannerImageConsoleConfig =
  bannerImageTemplate.consoleInputs['state_1'].template[3];
const oneButtonConsoleConfig =
  oneButtonTemplate.consoleInputs['state_1'].template[3];

const consoleConfig = {
  state_1: {
    template: [
      template[0],
      template[1],
      template[2],
      bannerImageConsoleConfig,
      oneButtonConsoleConfig,
      template[3],
    ],
    batched_template: {},
  },
  state_2: createDefaultInAppConfig({ namePrefix: 'state_2' }),
};

export const bannerImageOneButtonTemplate = {
  blockConfig: (values: any) => getBlockConfig(values),
  states: oneButtonTemplate.states,
  consoleInputs: consoleConfig as inAppTemplate,
  defaultValues,
  info: oneButtonTemplate.info,
  stateToSettingsMapping: oneButtonTemplate.stateToSettingsMapping,
};
