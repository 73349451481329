import { TeamAction } from 'store/teamStore';

type TeamContentObj = {
  [actions in Exclude<TeamAction, 'edit_user_account'>]: string;
};

export let headingText: TeamContentObj = {
  delete_user_account: 'Remove User Account',
  delete_invitations: 'Cancel Invitation',
  resend_invitations: 'Resend Invitation',
};

export let contentText: TeamContentObj = {
  delete_user_account: 'Are you sure to remove this user?',
  delete_invitations: 'Are you sure to cancel this invitation?',
  resend_invitations: 'Are you sure to resend this invitation?',
};

export let buttonText: TeamContentObj = {
  delete_user_account: 'remove',
  delete_invitations: 'cancel',
  resend_invitations: 'resend',
};
