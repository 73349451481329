import { GetScheduledNotifications } from 'api/scheduled/types';
import { create } from 'zustand';

interface State {
  scheduled: GetScheduledNotifications;
}

interface Action {
  setScheduled: (scheduledNotification: GetScheduledNotifications) => void;
  reset: () => void;
}

const initialState: State = {
  scheduled: null,
};

export const useScheduledStore = create<State & Action>()(set => ({
  ...initialState,
  setScheduled: scheduled => set(() => ({ scheduled })),
  reset: () => {
    set(initialState);
  },
}));
