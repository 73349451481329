import { yupResolver } from '@hookform/resolvers/yup';
import { usePostCliToken } from 'api/cli';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { buttonVariants } from 'components/shadcn/button';
import { Check, ClipboardList } from 'lucide-react';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { cn } from 'utils/class-merge';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { copyToClipboard } from '../../apiCredentials/functions';
import { saveAsFile } from 'utils/functions';

type FormCliToken = {
  name: string;
};

function CliTokenModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const postCliToken = usePostCliToken();
  const toast = useCustomToast();
  const { clientId } = useAuthStore(state => state);
  const [token, setToken] = React.useState<string>();
  const [copied, setCopied] = React.useState(false);

  const schema = yup.object({
    name: yup.string().required('name is required'),
  });

  const defaultValues = React.useMemo(
    () => ({
      name: '',
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormCliToken>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleClose = () => {
    reset(defaultValues);
    setToken(null);
    onClose();
  };

  const onSubmit: SubmitHandler<FormCliToken> = values => {
    if (token) {
      return saveAsFile({
        fileContent: JSON.stringify({
          config: {
            name: values.name,
            token,
          },
        }),
        fileName: 'engagespot_cli_token.txt',
      });
    }

    const data = {
      ...values,
      clientId,
    };

    postCliToken.mutate(data, {
      onSuccess: response => {
        setToken(response.data?.token);
        toast.success('CLI token added successfully');
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-3 text-2xl font-bold flex items-center gap-2">
        {token ? 'Successfully created CLI Token' : 'Create CLI Token'}

        {token && (
          <span className="bg-green-500 rounded-full px-1 py-1 text-sm text-white">
            <Check className="h-4 w-4" />
          </span>
        )}
      </h1>

      <p className="mb-6 text-sm text-[#BFBFBF]">
        To Authenticate with Engagespot CLI. Learn
        <a
          href="https://docs.engagespot.com/docs/engagespot-cli/authentication"
          className="text-decoration-line: underline ml-1"
          target="blank"
        >
          more
        </a>
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        {token ? (
          <div className="w-[485px] rounded-md relative border mb-4 p-3 bg-input break-words">
            {token}

            <button
              type="button"
              className={cn(
                buttonVariants({
                  variant: 'default',
                  size: 'sm',
                }),
                'absolute flex items-center gap-2 h-fit py-1 right-0 -top-10',
                copied && 'text-green-500',
              )}
              onClick={e => {
                e.stopPropagation();
                setCopied(true);
                copyToClipboard(token);
                setTimeout(() => {
                  setCopied(false);
                }, 1000);
              }}
            >
              {copied ? (
                <Check className="text-green-500 w-4 h-4" />
              ) : (
                <ClipboardList className="text-background w-4 h-4" />
              )}

              {copied ? 'Copied' : 'Copy'}
            </button>
          </div>
        ) : (
          <>
            <InputField<FormCliToken>
              variant="modal"
              extra="mb-3"
              label={'Name'}
              placeholder="John Doe's Macbook Pro"
              showIsRequiredAsterisk={true}
              type="text"
              register={register}
              name={'name'}
            />
            {errors.name && <ErrorBox error={errors.name} />}
          </>
        )}

        {token && (
          <div className="break-words mb-6 text-sm max-w-full text-yellow-400">
            This is the last time you will ever be able to see or download your
            CLI token. <br /> Please store it in a safe place.
          </div>
        )}

        <CustomModalButtons
          handleClose={handleClose}
          loading={postCliToken.isLoading}
          primaryButtonText={token ? 'Download ' : 'Create'}
          secondaryButtonText={token ? 'Close' : 'Cancel'}
        />
      </form>
    </CustomModal>
  );
}

export default CliTokenModal;
