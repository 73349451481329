import { GetCategories } from 'api/categories/get-categories/types';
import { create } from 'zustand';

interface State {
  category: GetCategories;
}

interface Action {
  setCategory: (category: GetCategories) => void;
  reset: () => void;
}

const initialState: State = {
  category: null,
};

export const useCategoryStore = create<State & Action>()(set => ({
  ...initialState,
  setCategory: category => set(() => ({ category: category })),
  reset: () => {
    set(initialState);
  },
}));
