import { TemplateDetails } from 'templates';
import { bannerImageTemplate } from 'templates/banner_image/config';
import { createDefaultInAppConfig } from 'templates/utils';
import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';
import { twoButtonTemplate } from '../two_button/config';

/**
 * used in setting initial inapp api data
 * and also when watching form value changes
 */
const defaultValues = {
  // state_1
  ...bannerImageTemplate.defaultValues,
  ...twoButtonTemplate.defaultValues,

  // preview
  preview_title:
    '<span><b>John Doe</b> invited you to Engagespot workspace</span>',
  preview_button1_label: 'Accept',
  preview_button2_label: 'Reject',
};

const getBlockConfig: TemplateDetails['blockConfig'] = (
  values = defaultValues,
) => {
  const bannerConfig = bannerImageTemplate.blockConfig(values);
  const twoButtonConfig = twoButtonTemplate.blockConfig(values);

  return {
    state_1: [...bannerConfig.state_1, ...twoButtonConfig.state_1],
    state_2: [],
    state_3: [],
  };
};

const state_1Config = createDefaultInAppConfig();
const { template } = state_1Config;

const bannerImageConsoleConfig =
  bannerImageTemplate.consoleInputs['state_1'].template[3];
const twoButtonConsoleConfig =
  twoButtonTemplate.consoleInputs['state_1'].template[3];

const consoleConfig = {
  state_1: {
    template: [
      template[0],
      template[1],
      template[2],
      bannerImageConsoleConfig,
      twoButtonConsoleConfig,
      template[3],
    ],
    batched_template: {},
  },
  state_2: createDefaultInAppConfig({ namePrefix: 'state_2' }),
  state_3: createDefaultInAppConfig({ namePrefix: 'state_3' }),
};

export const bannerImageTwoButtonTemplate: TemplateDetails = {
  blockConfig: (values: any) => getBlockConfig(values),
  states: twoButtonTemplate.states,
  consoleInputs: consoleConfig as inAppTemplate,
  defaultValues,
  info: twoButtonTemplate.info,
  stateToSettingsMapping: twoButtonTemplate.stateToSettingsMapping,
};
