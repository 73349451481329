import useGetClient from 'api/client/get-client';
import { APP_TO_ENV_VERSION } from 'config';
import semver from 'semver';
import { useAuthStore } from 'store/authStore';

export const useIsOldVersion = () => {
  const clients = useGetClient();
  const { clientId } = useAuthStore(state => state);

  const myClient = clients.data?.find?.(
    client => client.client?.id === clientId,
  );
  const clientVersion = myClient?.client?.version;

  if (clientVersion) {
    const isOldVersion = semver.lt(
      semver.coerce(clientVersion).version,
      semver.coerce(APP_TO_ENV_VERSION).version,
    );
    return { isOldVersion, isLoading: clients.isLoading };
  }

  return { isOldVersion: false, isLoading: clients.isLoading };
};
