export const logColors = {
  orange: '#EE9C50',
  light_green: '#79EE50',
  medium_green: '#39ea41',
  light_red: '#EE5D50',
  light_blue: '#EE5D50',
  light_violet: '#EE5D50',
  medium_blue: '#EE5D50',
  gray: 'gray',
};

export const logChipColors = {
  light_yellow: '#FFCC5B',
  light_green: '#3ED68C',
  light_blue: '#60AAED',
  light_violet: '#BCA5FF',
  light_red: '#E23838',
  light_gray: '#8d96a0',
  yellow: '#363408',
  green: '#0A221C',
  blue: '#041F42',
  violet: '#1F153D',
  red: '#3D1515',
  gray: '#21262d',
};
