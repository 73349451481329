import { IoMdClose } from 'react-icons/io';
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  SheetClose,
} from 'components/shadcn/drawer';
import React from 'react';

export const CustomDrawer = ({
  trigger,
  title,
  body,
  closeButtonRef,
  handleClose,
}: {
  trigger: () => JSX.Element;
  title: () => JSX.Element;
  body: () => JSX.Element;
  closeButtonRef?: React.MutableRefObject<any>;
  handleClose?: () => void;
}) => {
  return (
    <Sheet>
      <SheetTrigger>{trigger()}</SheetTrigger>
      <SheetContent className="overflow-auto">
        <SheetHeader>
          <SheetClose className="absolute right-4 top-4 rounded-sm opacity-70  transition-opacity hover:opacity-100 focus:outline-none  disabled:pointer-events-none data-[state=open]:bg-secondary">
            <button onClick={() => handleClose?.()}>
              <IoMdClose className="h-5 w-5 text-white" />
            </button>
            <span className="sr-only">Close</span>
          </SheetClose>

          <SheetTitle>{title()}</SheetTitle>
          <SheetDescription>{body()}</SheetDescription>
        </SheetHeader>
      </SheetContent>

      {/* for async closing */}
      <SheetClose className="pointer-events-none hidden">
        <button ref={closeButtonRef}></button>
      </SheetClose>
    </Sheet>
  );
};
