import { useQueryClient } from '@tanstack/react-query';
import usePutAppVerification from 'api/app/put-app-verification';
import { routes } from 'api/routes';
import Card from 'components/card';
import Switch from 'components/switch';
import useFunctionDebounce from 'utils/use-debounce';
import Header from './Header';
import TextContent from './TextContent';
import React from 'react';
import useCustomToast from 'utils/use-toast';

function Hmac({ appId, isEnabled }: { appId: number; isEnabled: boolean }) {
  const postAppVerification = usePutAppVerification();
  const queryClient = useQueryClient();
  const [enabled, setEnabled] = React.useState(isEnabled);
  const toast = useCustomToast();

  React.useEffect(() => {
    setEnabled(isEnabled);
  }, [isEnabled]);

  const handleVerification = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!appId) return;

    const data = {
      enableUserSignatureVerification: e.target.checked,
      appId,
    };

    postAppVerification.mutate(data, {
      onSuccess: () => {
        queryClient.invalidateQueries([routes['apps'], appId]);
        // !, because enabled is asynchronous and at this stage value is not updated
        toast.success(`hmac auth ${!enabled ? 'activated' : 'disabled'} `);
      },
    });
  };

  const optimisedHandleVerfication = useFunctionDebounce(handleVerification);

  return (
    <Card extra={'pb-8 px-6 pt-6'}>
      <Header>HMAC Authentication for In-App Inbox (Deprecated❗)</Header>

      <TextContent>
        HMAC Authentication was a legacy method (now deprecated) to ensure your
        users' notifications can be see only by themselves. If you're a new
        user, use the secure mode instead. If you are an existing user using
        HMAC Authentication, consider switching to secure mode.
      </TextContent>
      <div className="mt-4 flex items-center gap-3 ">
        <Switch
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEnabled(e.target.checked);
            optimisedHandleVerfication(e);
          }}
          checked={enabled}
        />
      </div>
    </Card>
  );
}

export default Hmac;
