import { ExploreCardsProps } from './types';

export const exploreSectionData: ExploreCardsProps[] = [
  {
    heading: 'Create a workflow',
    content: 'Build multi-channel notification workflows with a no-code editor',
    linkUrl: '/admin/workflow',
    linkText: 'Create workflow',
    newTab: false,
  },
  {
    heading: 'Add In-App Inbox',
    content: 'Add an in-app inbox to your app using component libraries.',
    linkUrl:
      'https://docs.engagespot.co/docs/features/in-app-inbox/introduction',
    linkText: 'Read docs',
    newTab: true,
  },
  {
    heading: 'Join Discord',
    content:
      'Join our community and get quick answers from 1000s of developers',
    linkUrl: 'https://disboard.org/server/join/936616763930587136',
    linkText: 'Join Discord',
    newTab: true,
  },
];
