import Cookies from 'js-cookie';
import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import routes from 'routes';
// import FixedPlugin from 'components/fixedPlugin/FixedPlugin';

export default function Auth() {
  const accessToken = Cookies.get('access_token');
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const invitationCode = new URLSearchParams(searchParams).get(
    'invitationCode',
  );
  const userType = new URLSearchParams(searchParams).get('userType');
  const isExistingUser = userType === 'existingUser';

  // Protected Pages
  React.useEffect(() => {
    // For Accepting workspace invitation, we are redirected to the register page
    // so we check for necessary params are in the url
    // if this is the case, then donot redirect to credentials page
    const acceptWorkspaceInvitationForExistingUser =
      location.pathname.includes('register') &&
      invitationCode &&
      isExistingUser;

    if (accessToken && !acceptWorkspaceInvitationForExistingUser) {
      navigate('/admin/api-credentials');
    }
  }, [accessToken, navigate, location, invitationCode, isExistingUser]);

  // Intercom
  React.useEffect(() => {
    const APP_ID = import.meta.env.VITE_REACT_APP_INTERCOM_APP_ID;

    if (!accessToken) {
      window.Intercom('shutdown');
      window.Intercom('boot', {
        app_id: APP_ID,
      });
    }
  }, [accessToken]);

  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === '/auth') {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };
  document.documentElement.dir = 'ltr';
  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full dark:!bg-background">
        <main className={`mx-auto min-h-screen`}>
          {/* <FixedPlugin /> */}
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}
