import { yupResolver } from '@hookform/resolvers/yup';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useWorkflowStore from 'store/workflowStore';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import {
  findSelectedNodeFromJson,
  findStepsBeforeSelectedNodeFromJson,
  handleJsonDiffCheck,
} from '../../functions';
import {
  useFormValidateOnLoad,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { WorkflowFormHeader } from './WorkflowFormHeader';
import { workflowDocLinks } from '../../variables';
import SelectField from 'components/fields/SelectField';

type FormWorkflowVariables = {
  name: string;
  value: string;
  triggerCondition?: string;
};

/**
 * This regex checks if our variable value is in correct format
 *
 * Our variable values can have atmost 3 cases
 * 1. Pure string , eg: "hello"
 * 2. Variable data, eg: data.name
 * 3. Combination of pure string and variable data. This can only be done using '+' in between them
 * eg: "hello" + data.name
 * eg: "hello" + data.name + "how are you ?"
 */
const variableMatchRegex =
  /^\s*(?:\bdata\.[a-zA-Z_$][\w.$]*|['][^']*['])(?:\s*\+\s*(?:\bdata\.[a-zA-Z_$][\w.$]*|['][^']*[']))*\s*$/;
const jsVariableNameRegex = /^\s*[a-zA-Z_$][a-zA-Z0-9_$]*\s*$/;

const schema = yup.object({
  name: yup
    .string()
    .required('Variable name is required')
    .matches(
      jsVariableNameRegex,
      'Wrong format: Variable names must start with a letter, _ or $, and cannot contain spaces',
    ),
  value: yup
    .string()
    .required('Variable value is required')
    .matches(
      variableMatchRegex,
      "Wrong format: Enclose string literals in single quotes (''), or use variables that start with data.",
    ),
});

const WorkflowVariableForm = ({ edit = false }: { edit?: boolean }) => {
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );

  const formMethods = useForm<FormWorkflowVariables>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const {
    register,
    reset,
    getValues,
    control,
    formState: { errors },
    trigger,
  } = formMethods;

  const stepsBeforeSelectedNodeFromJson = findStepsBeforeSelectedNodeFromJson({
    steps: workflowJson.steps,
    selectedNodeId: selectedNode?.id,
  });

  const defineVariableOptions = stepsBeforeSelectedNodeFromJson
    ?.filter(channel => channel?.ref?.includes('defineVariable'))
    ?.map(channel => ({
      label: channel?.config?.name,
      value: channel?.config?.name,
    }));

  const handleChange = () => {
    const { name, value, triggerCondition } = getValues();
    const selectedNodeId = selectedNode?.id;
    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      selected.triggerCondition = triggerCondition;
      selected.config = {
        ...selected.config,
        name: name.trim(),
        value,
      };
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });
    const selectedConfig = selectedNodeJson?.config;

    reset({
      ...selectedConfig,
      name: selectedConfig.name,
      value: selectedConfig.value,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useFormValidateOnLoad({
    dataLoaded,
    trigger,
  });

  useWatchWorkflowFormErrors({
    control,
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  return (
    <div>
      <WorkflowFormHeader
        heading={edit ? 'Edit Variable' : 'Define Variable'}
        docKey={edit ? 'editVariable' : 'defineVariable'}
      />

      <div className="text-[#ABB0B8] font-medium mb-3">
        {edit
          ? 'Modify Variables to use in workflow'
          : 'Create Variables to use in workflow'}
      </div>

      <FormProvider {...formMethods}>
        <form className="mt-6 flex flex-col gap-6">
          <div>
            {edit ? (
              <SelectField<FormWorkflowVariables>
                variant="styled"
                control={control}
                extra="w-full"
                label={'Select variable to edit'}
                placeholder={'Choose a variable'}
                extraInputClass=""
                name={'name'}
                styleVariant="workflow"
                extraItemClass=""
                options={defineVariableOptions ?? []}
                extraLabelClass="ml-1.5  !text-base font-medium dark:!text-gray-10"
              />
            ) : (
              <InputField<FormWorkflowVariables>
                label={'Name of the variable'}
                placeholder={'Enter the name of the variable'}
                register={register}
                name={'name'}
                extraLabelClass={'font-medium'}
                variant="workflow"
              />
            )}
            {errors.name && <ErrorBox error={errors.name} />}
          </div>

          <div>
            <InputField<FormWorkflowVariables>
              label={'Value'}
              placeholder={'Enter the value'}
              register={register}
              name={'value'}
              extraLabelClass={'font-medium'}
              variant="workflow"
              helperText={`Value should either be string literals, enclosed in single quotes (''), or variables that start with data.. Eg: 'hello' +  data.recipient.name. <a href="${workflowDocLinks['defineVariable']}" target="_blank" rel="noreferrer" style="color:#608aed;opacity:100%">Learn more</a>`}
              helperTextClassName="!opacity-[100%] text-gray-650"
            />
            {errors.value && <ErrorBox error={errors.value} />}
          </div>

          <WorkflowConditionForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default WorkflowVariableForm;
