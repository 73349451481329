import { FileFieldProps } from './types';
import DefaultFileVariant from './variants/default';
import SmallFileVariant from './variants/small';

export const disabledFileStyles =
  '!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)] text-[rgba(255,255,255,0.15)] disabled:cursor-not-allowed disabled:pointer-events-none';

const FileField = <T,>(props: FileFieldProps<T>) => {
  const {
    showIsRequiredAsterisk = false,
    variant = 'default',
    ...rest
  } = props;

  return (
    <>
      {variant === 'default' && (
        <DefaultFileVariant<T>
          {...rest}
          showIsRequiredAsterisk={showIsRequiredAsterisk}
        />
      )}

      {variant === 'small' && (
        <SmallFileVariant<T>
          {...rest}
          showIsRequiredAsterisk={showIsRequiredAsterisk}
        />
      )}
    </>
  );
};

export default FileField;
