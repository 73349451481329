import { yupResolver } from '@hookform/resolvers/yup';
import usePostAddApps from 'api/app/post-add-app';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { SelectOptions } from 'components/fields/SelectField/types';
import HeaderDoc from 'components/header/HeaderDoc';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import { cn } from 'utils/class-merge';
import { useIsOldVersion } from 'utils/use-old-version';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type FormAddApps = {
  name: string;
};

export const schema = yup.object({
  name: yup
    .string()
    .required('name is required')
    .matches(
      /^[a-zA-Z0-9_]*$/,
      'name can only contain letters, numbers, and underscores',
    )
    .min(2, 'name must be at least 2 characters'),
});

function NavbarModal({
  isOpen,
  onClose,
  setSelectedApp,
}: {
  onClose: () => void;
  isOpen: boolean;
  setSelectedApp: React.Dispatch<React.SetStateAction<SelectOptions>>;
}) {
  const postAddApps = usePostAddApps();
  const toast = useCustomToast();
  const { clientId } = useAuthStore(state => state);
  const { isOldVersion } = useIsOldVersion();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
  } = useForm<FormAddApps>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormAddApps> = values => {
    const data = {
      clientId,
      name: values.name,
    };

    postAddApps.mutate(data, {
      onSuccess: response => {
        setSelectedApp({
          label: String(response.data?.name),
          value: String(response.data.id),
        });
        toast.success('New environment has been created');
        handleClose();
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  const handleClose = () => {
    clearErrors('name');
    reset({});
    onClose();
  };

  return (
    <CustomModal isOpen={isOpen} onClose={handleClose}>
      <h1 className="mb-4 text-2xl font-bold">
        {isOldVersion ? 'Create App' : 'Create Environment'}
      </h1>

      <p className={cn('text-muted-foreground text-sm mb-4')}>
        Environments group resources and settings for management.
        <HeaderDoc
          link="https://docs.engagespot.co/docs/concepts/environment"
          text="Learn more"
        />
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField<FormAddApps>
          variant="modal"
          extra="mb-3"
          label={isOldVersion ? 'App Name' : 'Environment Name'}
          placeholder="Eg: staging"
          type="text"
          showIsRequiredAsterisk={true}
          register={register}
          name={'name'}
        />
        {errors.name && <ErrorBox error={errors.name} />}

        <CustomModalButtons
          handleClose={handleClose}
          primaryButtonText="Create"
          loading={postAddApps.isLoading}
        />
      </form>
    </CustomModal>
  );
}

export default NavbarModal;
