import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetProviders } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number, string]>): Promise<GetProviders> => {
  const [, appId, channel] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/channels/v1/${channel}/providers`,
  );

  return data;
};

const useGetProviders = (channel: string, enabled = true) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/providers`, appId, channel],
    queryFn: get,
    enabled: Boolean(channel) && enabled,
  });
};

export const useMutateGetProviders = () => {
  return useMutation({
    mutationFn: (values: { appId: number; channel: string }) =>
      get({
        queryKey: [`${routes['apps']}/providers`, values.appId, values.channel],
        meta: {},
      }),
  });
};

export default useGetProviders;
