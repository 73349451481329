import { getAbbreviateNumber } from 'utils/get-abbreviate-format';

export const PieLegendCard = ({
  channel,
  value,
  color,
}: {
  channel: string;
  value: string;
  color: string;
}) => {
  const abbreviateValue = getAbbreviateNumber(value);

  return (
    <div className="flex flex-col gap-2 w-full justify-center">
      <div className="flex items-center gap-2">
        <div
          style={{
            background: color,
          }}
          className="w-4 h-4 rounded-full"
        ></div>
        <h5 className="text-sm font-medium capitalize text-white/90">
          {channel}
        </h5>
      </div>

      <p className="font-medium text-xl">{abbreviateValue}</p>
    </div>
  );
};

export const PieLegendCardsLegacy = ({
  channel,
  value,
  color,
}: {
  channel: string;
  value: string;
  color: string;
}) => {
  const abbreviateValue = getAbbreviateNumber(value);

  return (
    <div className="flex flex-row gap-3 w-full items-center justify-between">
      <div className="flex gap-2 items-center rounded-lg">
        <span
          style={{
            background: color,
          }}
          className="w-3 h-3 rounded-sm"
        ></span>
        <h5 className="text-lg font-medium capitalize text-white/90">
          {channel}
        </h5>
      </div>

      <p
        style={{
          borderColor: color,
        }}
        // className="font-medium text-lg border rounded-md px-2 py-1"
        className="font-semibold border rounded-full px-3 py-0 "
      >
        {abbreviateValue}
      </p>
    </div>
  );
};
