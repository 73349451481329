import { ModalCloseButton } from '@chakra-ui/modal';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCancelWorkflow } from 'api/workflows-run';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import {
  CustomDeleteModalContent,
  CustomModal,
  CustomModalButtons,
} from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';

type FormCancelWorkflow = {
  cancellationKey: string;
};

export const schema = yup.object({
  cancellationKey: yup.string().required('Cancellation Key is required'),
});

function WorkflowCancelModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const params = useParams();
  const workflowId = Number(params.workflowId);
  const cancelWorkflow = useCancelWorkflow();
  const toast = useCustomToast();
  const { cancelWorkflowFor, setCancelWorkflowFor } = useWorkflowStore(
    state => state,
  );

  const defaultValues = React.useMemo(
    () => ({
      cancellationKey: '',
    }),
    [],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormCancelWorkflow>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const handleClose = () => {
    setCancelWorkflowFor(null);
    reset(defaultValues);
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit: SubmitHandler<FormCancelWorkflow> = values => {
    const data = {
      ...values,
      workflowId,
      ...(cancelWorkflowFor && {
        cancelFor: {
          recipients: [String(cancelWorkflowFor)],
        },
      }),
    };

    const finalActions = getFinalActions(
      cancelWorkflowFor
        ? 'workflow run cancelled successfully'
        : 'all workflow runs cancelled successfully',
    );
    cancelWorkflow.mutate(data, finalActions);
  };

  return (
    <CustomModal
      modalContentClassName="!min-w-[485px]"
      modalCardClassName="!min-w-[485px] !max-w-[450px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <ModalCloseButton className="absolute right-[24px] top-[24px] z-10 text-white" />
      <CustomDeleteModalContent
        content={
          cancelWorkflowFor ? 'Cancel Workflow Run' : 'Cancel All Workflow Runs'
        }
        type="heading"
      />

      <CustomDeleteModalContent
        content={
          cancelWorkflowFor
            ? 'Are you sure to cancel this workflow run ?'
            : 'Are you sure to cancel all the runs of this workflow ?'
        }
        type="text"
      />
      {/* <p className="mb-6 text-sm text-[#BFBFBF]">
              Read docs to learn more about Topic
            </p> */}

      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField<FormCancelWorkflow>
          variant="modal"
          extra="mb-3"
          label={'Cancellation Key'}
          placeholder={'Enter Cancellation Key'}
          showIsRequiredAsterisk={true}
          type="text"
          register={register}
          name={'cancellationKey'}
        />
        {errors.cancellationKey && <ErrorBox error={errors.cancellationKey} />}

        <CustomModalButtons
          handleClose={handleClose}
          loading={cancelWorkflow.isLoading}
          primaryButtonText="Submit"
          secondaryButtonText="Close"
          danger={true}
        />
      </form>
    </CustomModal>
  );
}

export default WorkflowCancelModal;
