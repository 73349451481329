import { MdKeyboardArrowRight } from 'react-icons/md';
import { ReactNode } from 'react';
import { cn } from 'utils/class-merge';

export const BaseNodeText = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  return (
    <span className={cn('text-xs text-[#ABB0B8] capitalize', className)}>
      {children}
    </span>
  );
};

export const LearnMore = ({
  link,
  text = 'Learn more',
}: {
  link: string;
  text?: string;
}) => {
  return (
    <a
      className="font-semibold text-[#60AAED] text-sm flex justify-between items-end hover:text-[#608aed]"
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <span>{text}</span>
      <MdKeyboardArrowRight className="w-5 h-5" />
    </a>
  );
};
