import usePutOrDeleteCategories from 'api/categories/put-delete-categories';
import { CustomDeleteModal } from 'components/modal';
import { useCategoryStore } from 'store/categoryStore';
import useCustomToast from 'utils/use-toast';

function DeleteCategoryModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { category, setCategory } = useCategoryStore(state => state);
  const deleteAddCategories = usePutOrDeleteCategories();
  const toast = useCustomToast();

  const handleSubmit = () => {
    deleteAddCategories.mutate(
      {
        type: 'delete',
        categoryId: category.id,
      },
      {
        onSuccess: () => {
          setCategory(null);
          toast.success('category removed successfully');
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteAddCategories.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove Category"
      text={'Are you sure to remove this category?'}
    />
  );
}

export default DeleteCategoryModal;
