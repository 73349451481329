import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { FormAddTemplates } from 'views/admin/dashboards/template/types';

type PostAddApps = FormAddTemplates;

const post = async (values: PostAddApps) => {
  const { appId, category, name, identifier } = values;

  const data = {
    category,
    name,
    identifier,
  };

  return axiosInstance.post(`${routes['apps']}/${appId}/templates`, data);
};

const usePostTemplates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: post,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/templates`]);
    },
  });
};

export default usePostTemplates;
