import { Button } from 'components/shadcn/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/shadcn/tooltip';
import { CheckIcon, CopyIcon } from 'lucide-react';
import React from 'react';
import { cn } from 'utils/class-merge';
import { copyToClipboard } from 'views/admin/dashboards/apiCredentials/functions';

function CopyButton({
  className,
  value,
  ...props
}: React.ComponentProps<typeof Button> & { value: string }) {
  const [hasCopied, setHasCopied] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      setHasCopied(false);
    }, 1500);
  }, [hasCopied]);

  return (
    <>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <Button
              variant="outline"
              onClick={() => {
                copyToClipboard(value);
                setHasCopied(true);
              }}
              className={cn('border-none px-0 py-0', className)}
              {...props}
            >
              {hasCopied ? (
                <CheckIcon className="h-4 w-4" />
              ) : (
                <CopyIcon className="h-4 w-4" />
              )}
            </Button>
          </TooltipTrigger>

          <TooltipContent>
            <p>Copy</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </>
  );
}

export default CopyButton;
