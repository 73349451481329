import Clock from '@assets/svg/analytics/alarm-clock.svg';
import Finger from '@assets/svg/analytics/cursor-finger.svg';
import DoubleTick from '@assets/svg/analytics/double-tick.svg';
import Plane from '@assets/svg/analytics/plane.svg';
import Bell from '@assets/svg/analytics/violet-bell.svg';
import { useDisclosure } from '@chakra-ui/hooks';
import useGetAppAnalytics from 'api/analytics/get-app-analytics';
import useGetChannelAnalytics from 'api/analytics/get-channel-analytics';
import useGetTemplateAnalytics from 'api/analytics/get-template-analytics';
import useGetTemplateChannelDetailsAnalytics from 'api/analytics/get-template-channel-details-analytics';
import useGetTemplateDetailsAnalytics from 'api/analytics/get-template-details-analytics';
import useGetTemplateNotificationAnalytics from 'api/analytics/get-template-notification-analytics';
import Card from 'components/card';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { DatePickerWithRange } from 'components/shadcn/date-range-picker';
import { usePaginationApiState } from 'components/table/pagination-state';
import { ANALYTICS_MIN_DATE } from 'config';
import { max, subDays } from 'date-fns';
import parse from 'date-fns/parse/index';
import React from 'react';
import { DateRange } from 'react-day-picker';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { getPercentage, getSum, isEmptyObj } from 'utils/functions';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import { AnalyticsCard } from './components/AnalyticsCard';
import AnalyticsLogsTable from './components/AnalyticsLogsTable';
import AnalyticsTemplatesTable from './components/AnalyticsTemplateTable';
import AnalyticsLineChart from './components/LineChart';
import AnalyticsPieChart from './components/PieChart';
import { PieLegendCard } from './components/PieLegendCard';
import {
  getAppAnalyticsData,
  getChannelAnalyticsData,
  getChannelAnalyticsGraphData,
  getTemplateAnalyticsData,
} from './functions';
import { pieChartColors } from './variables';

const Analytics = () => {
  const minDate = parse(ANALYTICS_MIN_DATE, 'dd-MM-yyyy', new Date());
  const maxDate = new Date();
  const [filter, setFilter] = React.useState('');
  const addTemplateModalActions = useDisclosure();
  const [searchParams] = useSearchParams();
  const templateId = new URLSearchParams(searchParams).get('templateId');
  const navigate = useNavigate();
  const { appId } = useAuthStore(state => state);
  const initialDate = max([minDate, subDays(new Date(), 30)]);
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: initialDate,
    to: new Date(),
  });
  const { pagination, setPagination } = usePaginationApiState();
  const getAppAnalytics = useGetAppAnalytics(appId, date);
  const getChannelAnalytics = useGetChannelAnalytics(appId, date);
  const getTemplateAnalytics = useGetTemplateAnalytics(appId, date);
  const getTemplateDetailsAnalytics = useGetTemplateDetailsAnalytics(
    appId,
    templateId,
    date,
  );
  const getTemplateChannelDetailsAnalytics =
    useGetTemplateChannelDetailsAnalytics(appId, templateId, date);
  const getTemplateNotificationAnalytics = useGetTemplateNotificationAnalytics({
    appId,
    templateId,
    date,
    ...pagination,
  });

  const setLimitedDate = (newDate: any) => {
    setDate({ from: newDate.from, to: newDate.to });
  };

  // formatted data
  const appAnalyticsData = getAppAnalyticsData(getAppAnalytics.data);
  const templateAnalyticsData = getTemplateAnalyticsData(
    getTemplateAnalytics.data,
  );

  const templateDetailsAnalyticsData = getTemplateAnalyticsData(
    getTemplateDetailsAnalytics.data,
  );

  const channelAnalyticsData = getChannelAnalyticsData(
    getChannelAnalytics.data,
  );

  const templateChannelAnalyticsData = getChannelAnalyticsData(
    getTemplateChannelDetailsAnalytics.data,
  );

  const channelAnalyticsGraphData = getChannelAnalyticsGraphData(
    getChannelAnalytics.data,
  );

  const templateChannelAnalyticsGraphData = getChannelAnalyticsGraphData(
    getTemplateChannelDetailsAnalytics.data,
  );

  const graphData = templateId
    ? templateChannelAnalyticsGraphData
    : channelAnalyticsGraphData;

  const channelData = templateId
    ? templateChannelAnalyticsData
    : channelAnalyticsData;

  const graphDataisFetched = templateId
    ? getTemplateChannelDetailsAnalytics.isFetched
    : getChannelAnalytics.isFetched;

  const total = graphData?.data?.sent?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  return (
    <>
      <div
        id="analytics-container"
        className="mt-5 grid h-full w-full grid-cols-12 gap-0 rounded-[20px]"
      >
        <div className="flex mb-3 col-span-12 w-full justify-between items-center">
          {templateId && (
            <IoArrowBack
              onClick={() => navigate(-1)}
              color="white"
              className="cursor-pointer"
              size={22}
            />
          )}

          {/* Header */}
          {!templateId && (
            <h2 className="font-bold col-span-12 text-2xl text-white w-full">
              Analytics Overview
            </h2>
          )}

          <div className="">
            <DatePickerWithRange
              disabled={{ before: minDate, after: maxDate }}
              date={date}
              setDate={setLimitedDate}
            />
          </div>
        </div>

        {templateId ? (
          <div className="col-span-12  mb-5">
            <Card
              extra={'px-6 py-6 w-full items-center flex !flex-row gap-[5rem]'}
            >
              <div className="flex gap-0 items-center">
                <img src={Bell} alt="" />

                {/* template name */}
                <div>
                  <h5 className="text-gray-620 text-[15px] font-medium">
                    Template Name
                  </h5>
                  <p className="font-bold text-xl">
                    {templateDetailsAnalyticsData?.[0]?.templateName ??
                      'Not found'}
                  </p>
                </div>
              </div>

              {/* channels */}
              <div>
                <h5 className="text-gray-620 text-[15px] font-medium">
                  Channels
                </h5>
                <p className="font-bold text-xl text-yellow-450">
                  {Object.keys(
                    isEmptyObj(channelData)
                      ? { 'no channels': '' }
                      : channelData,
                  ).join(',')}
                </p>
              </div>

              <div>
                <h5 className="text-gray-620 text-[15px] font-medium">
                  Delivered
                </h5>

                <p className="font-bold text-xl text-yellow-450">
                  {templateDetailsAnalyticsData?.[0]?.sent ?? 0}
                </p>
              </div>

              <div>
                <h5 className="text-gray-620 text-[15px] font-medium">
                  Seen Rate
                </h5>

                <p className="font-bold text-xl text-green-450">
                  {getPercentage(
                    templateDetailsAnalyticsData?.[0]?.seen,
                    templateDetailsAnalyticsData?.[0]?.sent,
                  )}{' '}
                  %
                </p>
              </div>

              <div>
                <h5 className="text-gray-620 text-[15px] font-medium">
                  Click Rate
                </h5>

                <p className="font-bold text-xl text-blue-450">
                  {getPercentage(
                    templateDetailsAnalyticsData?.[0]?.interacted,
                    templateDetailsAnalyticsData?.[0]?.sent,
                  )}{' '}
                  %
                </p>
              </div>

              <div>
                <h5 className="text-gray-620 text-[15px] font-medium">
                  Discarded
                </h5>

                <p className="font-bold text-xl text-red-400">
                  {templateDetailsAnalyticsData?.[0]?.discarded}
                </p>
              </div>
            </Card>
          </div>
        ) : (
          <div className="col-span-12  mb-5">
            <Card extra={'px-6 py-6 grid grid-cols-4 items-center gap-4'}>
              <AnalyticsCard
                icon={Clock}
                heading={getAbbreviateNumber(
                  appAnalyticsData?.['request_received'],
                )}
                text="Notifications Triggered"
              />
              <AnalyticsCard
                icon={Plane}
                heading={getAbbreviateNumber(appAnalyticsData?.['sent'])}
                text="Notifications Sent"
              />
              {/* <AnalyticsCard icon={Clock} heading="36%" text="Delivery Rate" /> */}
              <AnalyticsCard
                icon={DoubleTick}
                heading={`${getPercentage(
                  appAnalyticsData?.['seen'],
                  appAnalyticsData?.['sent'],
                )} %`}
                text="Seen Rate"
                iconWidth={12}
              />

              <AnalyticsCard
                icon={Finger}
                heading={`${getPercentage(
                  getSum(
                    appAnalyticsData?.['interacted'],
                    appAnalyticsData?.['read'],
                  ),
                  appAnalyticsData?.['sent'],
                )} %`}
                text="Click Rate"
              />
            </Card>
          </div>
        )}

        <div className="col-span-12 my-5 flex gap-3">
          {/* Line Chart */}
          <div className="w-full">
            <h3 className="font-bold text-lg text-white w-full mb-3">
              Engagement summary
            </h3>

            <Card extra={'px-6 py-6 min-h-[422px] !justify-center'}>
              {graphDataisFetched && (
                <div className="relative">
                  <AnalyticsLineChart values={graphData} />
                </div>
              )}
            </Card>
          </div>

          {/* Pie Chart */}
          <div className="w-full">
            <h3 className="font-bold text-lg text-white w-full mb-3">
              Channel summary
            </h3>

            <Card extra={'px-6 !flex !flex-row gap-4 py-2 min-h-[422px]'}>
              <div
                className={`${
                  Object.keys(channelData ?? {}).length > 0
                    ? 'w-[60%]'
                    : 'w-full'
                } relative top-4 self-center`}
              >
                {graphDataisFetched && <AnalyticsPieChart values={graphData} />}
              </div>

              {Object.keys(channelData).length > 0 && (
                <div className="grid grid-cols-2 auto-rows-max items-start gap-2 pt-4 w-[40%] relative top-1">
                  <div className="col-span-2 mb-4">
                    <h4 className="text-lg text-white/70">
                      Notifications sent
                    </h4>

                    <p className="text-white text-4xl font-bold mt-2">
                      {getAbbreviateNumber(total)}
                    </p>
                  </div>

                  {Object.keys(channelData).map((item, index) => (
                    <PieLegendCard
                      key={index}
                      value={channelData[item].sent}
                      channel={item}
                      color={pieChartColors[index]}
                    />
                  ))}
                </div>
              )}
            </Card>
          </div>
        </div>

        {/* Tables */}
        {!templateId && (
          <div className="col-span-12 my-5">
            <h3 className="font-bold text-lg text-white w-full mb-3">
              Template Analytics
            </h3>

            {getTemplateAnalytics.isLoading ? (
              <Card
                extra={
                  'w-full h-full sm:overflow-auto px-6 py-8 flex justify-center items-center'
                }
              >
                <LoaderSpinner width="40" />
              </Card>
            ) : (
              <AnalyticsTemplatesTable
                tableData={templateAnalyticsData ?? []}
                loading={getTemplateAnalytics.isLoading}
                editOpen={addTemplateModalActions.onOpen}
                globalFilter={filter}
                setGlobalFilter={setFilter}
              />
            )}
          </div>
        )}

        {templateId && (
          <div className="col-span-12 my-5">
            <h3 className="font-bold text-lg text-white w-full mb-3">
              Template Logs
            </h3>

            {getTemplateNotificationAnalytics.isLoading ? (
              <Card
                extra={
                  'w-full sm:overflow-auto px-6 !rounded-t-[0px] py-8 h-[150px] flex justify-center items-center'
                }
              >
                <LoaderSpinner width="40" />
              </Card>
            ) : (
              <AnalyticsLogsTable
                loading={getTemplateNotificationAnalytics.isLoading}
                tableData={
                  getTemplateNotificationAnalytics?.data?.results ?? []
                }
                totalPages={getTemplateNotificationAnalytics?.data?.totalPages}
                pagination={pagination}
                setPagination={setPagination}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Analytics;
