import Chip from 'components/chip';
import { ExploreCardsProps } from '../types';

export const ExploreCards = ({
  heading,
  content,
  linkText = 'Learn More',
  linkUrl,
  isRecommended,
  newTab,
}: ExploreCardsProps) => {
  return (
    <div className="col-span-3 flex flex-col relative justify-around gap-2 rounded-lg border border-white/20 p-5">
      {isRecommended && (
        <div className="absolute top-4 right-4">
          <Chip className="text-xs" text="Recommended" />
        </div>
      )}

      <h4 className="text-lg font-semibold">{heading}</h4>

      <p className="text-base mb-2">{content}</p>

      <a
        href={linkUrl}
        className="h-min w-fit font-semibold text-sm rounded-md bg-white px-3 py-2 text-black"
        target={newTab ? '_blank' : ''}
        rel="noreferrer"
      >
        {linkText}
      </a>
    </div>
  );
};
