import useGetClientDetails from 'api/client/get-client-details.ts';
import useGetClient from 'api/client/get-client';
import useGetClientApps from 'api/client/get-client-apps';
import { Portal } from '@chakra-ui/portal';
import { getMe } from 'api/profile/get-me';
// import Footer from 'components/footer/Footer';
import PremiumModal from 'components/modal/premium';
import Navbar from 'components/navbar';
import Sidebar from 'components/sidebar';
import Cookies from 'js-cookie';
import React from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import routes from 'routes';
import { usePremiumStore } from 'store/premiumStore';
import { useAuthStore } from 'store/authStore';

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const accessToken = Cookies.get('access_token');
  const [currentRoute, setCurrentRoute] = React.useState('Main Dashboard');
  const [hideSidebarView, setHideSidebarView] = React.useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isTemplateEditorPage = location.pathname.includes('template-editor');
  const isWorkflowEditorPage = location.pathname.includes('workflow-editor');
  const isLayoutEditorPage = location.pathname.includes('layout-editor');
  const isTranslationEditorPage =
    location.pathname.includes('translation-editor');
  const premiumModalActions = usePremiumStore(state => state);
  const { clientId } = useAuthStore(state => state);

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  React.useEffect(() => {
    window.addEventListener('resize', () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true),
    );
  }, []);

  React.useEffect(() => {
    getActiveRoute(routes);

    if (
      isTemplateEditorPage ||
      isWorkflowEditorPage ||
      isLayoutEditorPage ||
      isTranslationEditorPage
    ) {
      setHideSidebarView(true);
    } else {
      setHideSidebarView(false);
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  // Protected Pages
  React.useEffect(() => {
    if (!accessToken) {
      const visitedUrl = window.location.pathname;
      navigate(`/auth/login?redirect=${visitedUrl}`);
    }
  }, [accessToken, navigate]);

  // Intercom
  React.useEffect(() => {
    const APP_ID = import.meta.env.VITE_REACT_APP_INTERCOM_APP_ID;

    const initializeIntercom = async () => {
      if (accessToken) {
        try {
          const response = await getMe();
          const clientUser = response?.clientUser;

          window.Intercom('boot', {
            app_id: APP_ID,
            ...(clientUser && {
              name: `${clientUser?.firstName} ${clientUser?.lastName}`,
              email: clientUser?.email,
              user_id: clientUser?.id,
            }),
          });
        } catch (e) {
          console.log(e);
        }
      }
    };

    initializeIntercom();
  }, [accessToken]);

  // functions for changing the states from components
  const getActiveRoute = (routes: RoutesType[]): string => {
    let activeRoute = 'Default Brand Text';
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].items);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          setCurrentRoute(routes[i].name);
        }
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes: RoutesType[]): boolean => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveNavbar = getActiveNavbar(routes[i].items);
        if (collapseActiveNavbar !== activeNavbar) {
          return collapseActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].secondary;
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes: RoutesType[]): any => {
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };
  document.documentElement.dir = 'ltr';

  // Setting up AppId and ClientId
  useGetClient();
  useGetClientApps(clientId);
  useGetClientDetails(clientId);

  return (
    <>
      <PremiumModal
        onClose={premiumModalActions.onClose}
        isOpen={premiumModalActions.isOpen}
      />

      <div className="flex h-full w-full">
        {!hideSidebarView && (
          <Sidebar open={open} onClose={() => setOpen(false)} />
        )}
        {/* Navbar & Main Content */}
        <div className="h-full w-full  dark:bg-background">
          {/* Main Content */}
          <main
            className={`mx-2.5 flex-none transition-all dark:bg-background md:pl-2 ${
              hideSidebarView
                ? 'md:pr-2 !p-0 !mx-0'
                : 'xl:ml-[278px] 3xl:ml-[313px]'
            } `}
          >
            {/* Routes */}
            <div>
              <Portal>
                {!hideSidebarView && (
                  <Navbar
                    onOpenSidenav={() => setOpen(!open)}
                    brandText={currentRoute}
                    secondary={getActiveNavbar(routes)}
                    {...rest}
                  />
                )}
              </Portal>

              <div
                // className={`mx-auto min-h-screen p-2 ${
                className={`mx-auto p-2 ${
                  hideSidebarView ? '!p-0' : '!pt-[90px]'
                }  md:p-2`}
              >
                <Routes>
                  {getRoutes(routes)}
                  <Route
                    path="/"
                    element={<Navigate to="/admin/api-credentials" replace />}
                  />
                </Routes>
              </div>

              {/* {!isWorkflowEditorPage && (
                <div className="p-3">
                  <Footer />
                </div>
              )} */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
