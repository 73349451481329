import {
  TemplateBlock,
  TemplateBlocksRender,
} from '@engagespot/template-blocks';
// import Avatar from 'assets/svg/template/avatar.svg';
import Avatar from 'assets/svg/template/avatar.png';

export const InAppNotification = ({
  hasPreviewImage,
  previewImageSrc,
  previewMessage,
  previewTitle,
  templateConfig,
  isPreviewView = false,
  isBatchedTab = false,
}: {
  templateConfig: TemplateBlock[];
  previewMessage: string;
  previewTitle: string;
  previewImageSrc: string;
  hasPreviewImage: boolean;
  isPreviewView?: boolean;
  isBatchedTab?: boolean;
}) => {
  return (
    <div className={`flex gap-4 ${isPreviewView ? 'px-3 py-3' : 'px-5 py-5'} `}>
      {/* Avatar */}
      <>
        {hasPreviewImage ? (
          <img
            className="mb-1 h-8 w-8 self-start rounded-full object-contain outline-none"
            src={previewImageSrc as string}
            alt=""
            width={40}
          />
        ) : (
          <img
            className="mb-1 self-start rounded-full object-contain outline-none"
            src={Avatar}
            alt=""
            width={40}
          />
        )}
      </>

      <div className="relative top-1 flex w-full flex-col ">
        <h5
          className="mb-1 text-left text-sm"
          dangerouslySetInnerHTML={{ __html: previewTitle }}
        ></h5>

        {previewMessage && (
          <p
            className="mb-1 break-words pr-2 text-sm"
            dangerouslySetInnerHTML={{ __html: previewMessage }}
          ></p>
        )}

        <div
          className={`flex gap-3 text-[10px] font-normal ${
            isPreviewView ? 'text-gray-850' : 'text-[#919EAB]'
          } `}
        >
          <span>less than a minute ago</span>
          {/* <span>Communications</span> */}
        </div>

        {templateConfig && !isBatchedTab && (
          <div className="pt-3">
            <TemplateBlocksRender
              blocks={templateConfig}
            ></TemplateBlocksRender>
          </div>
        )}
      </div>

      {/* blue dot */}
      {!isPreviewView && (
        <span className="relative h-2 w-[9px] rounded-full bg-[#00B8D9]"></span>
      )}
    </div>
  );
};
