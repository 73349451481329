import { useQuery } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { Plans } from './types';
import { axiosLicensingInstance } from 'api/axios/licensing';

const get = async (): Promise<Plans[]> => {
  const { data } = await axiosLicensingInstance.get(`${routes['plans']}`);
  return data;
};

const useGetPlans = (enabled: boolean) => {
  return useQuery({
    queryKey: [routes['plans']],
    queryFn: get,
    enabled,
  });
};

export default useGetPlans;
