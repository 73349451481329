import { Switch } from 'components/shadcn/switch';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@radix-ui/react-hover-card';

export const WorkflowToggleButtons = ({
  onCheckedChange,
  leftContentText,
  leftTriggerText,
  rightContentText,
  rightTriggerText,
  checked,
}: {
  checked: boolean;
  onCheckedChange: () => void;
  leftTriggerText: string;
  leftContentText: string;
  rightTriggerText: string;
  rightContentText: string;
}) => {
  return (
    <div className="flex gap-3 py-1 items-center text-white/90 text-sm border border-gray-440 px-2 rounded-md">
      <HoverCard>
        <HoverCardTrigger>
          <span className="cursor-default">{leftTriggerText}</span>
        </HoverCardTrigger>

        <HoverCardContent className="relative top-2 z-[100] w-[250px] rounded-md border-2 border-gray-440 bg-gray-880 text-white p-2 text-sm">
          {leftContentText}
        </HoverCardContent>
      </HoverCard>

      <Switch
        checked={checked}
        onCheckedChange={value => {
          onCheckedChange();
        }}
      />

      {/* <HoverCard>
        <HoverCardTrigger>
          <span className="cursor-default">{rightTriggerText}</span>
        </HoverCardTrigger>

        <HoverCardContent className="relative top-2 z-[100] !w-[250px] rounded-md border-2 border-gray-440 bg-gray-880 text-white p-2 text-sm">
          {rightContentText}
        </HoverCardContent>
      </HoverCard> */}
    </div>
  );
};
