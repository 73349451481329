import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import useDeleteTopicsUsers from 'api/topics/delete-topics-user';
import { TopicSubscriber } from 'api/topics/get-topic-users/types';
import { CustomDeleteModal } from 'components/modal';
import { useSearchParams } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';

function DeleteTopicsUserModal({
  isOpen,
  onClose,
  selectedTopicUser,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedTopicUser: TopicSubscriber;
}) {
  const [searchParams] = useSearchParams();
  const topicId = new URLSearchParams(searchParams).get('topicId');
  const deleteTopicsUsers = useDeleteTopicsUsers();
  const toast = useCustomToast();
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    deleteTopicsUsers.mutate(
      {
        userId: selectedTopicUser.user.id,
        topicId,
      },
      {
        onSuccess: () => {
          toast.success('user removed from topic successfully');
          queryClient.invalidateQueries([
            `${routes['topics']}/${topicId}/users`,
          ]);
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteTopicsUsers.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove User"
      text={'Are you sure to remove this user from this topic?'}
    />
  );
}

export default DeleteTopicsUserModal;
