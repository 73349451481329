import { useEditWorkflowJson } from 'api/workflows';
import { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useReactFlow } from 'reactflow';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import { createNewConnections } from '../../functions';
import { getDraggableNodeDimensions } from '../../layout';
import { WorkflowSidebarCardsProps } from '../../types';
import { WORKFLOW_DEFAULT_NODES_IDS } from '../../variables';

const nodeDimensions = getDraggableNodeDimensions();

const WorkflowSidebarCards = ({
  handleDragEnd,
  heading,
  text,
  type,
  draggedChannel,
}: WorkflowSidebarCardsProps) => {
  const reactFlowInstance = useReactFlow();
  const editWorkflowJson = useEditWorkflowJson();
  const params = useParams();
  const workflowId = params.workflowId;
  const { setDraggedType, setDraggedChannel, workflowJson, edges } =
    useWorkflowStore(useShallow(state => state));
  const dragImageRef = useRef<HTMLDivElement>(null);

  const onDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
    handleDragEnd();
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>) => {
    setDraggedType(type);
    if (type === 'channel' && draggedChannel) {
      setDraggedChannel(draggedChannel);
    }
    const dragImage = dragImageRef.current;
    if (dragImage) {
      document.body.appendChild(dragImage);

      // style polyfill for safari browser
      dragImage.style.display = 'flex';
      dragImage.style.width = `${nodeDimensions.width}px`;
      dragImage.style.height = `${nodeDimensions.height}px`;
      dragImage.style.color = `white`;
      dragImage.style.background = `#16171C`;

      dragImage.dataset.mirror_drag = 'true';

      e.dataTransfer.setDragImage(
        dragImage,
        dragImage.clientWidth,
        dragImage.clientHeight,
      );
    }
  };

  return (
    <>
      <div
        ref={dragImageRef}
        className="border border-[#3A3F47] bg-[#16171C] text-white flex flex-col py-2 px-4 text-sm fixed pointer-events-none"
        style={{ display: 'none', ...nodeDimensions }}
      >
        <span className="text-white">{heading}</span>
      </div>

      <div
        onClick={() => {
          const lastEdge = edges.find(
            edge => edge.target === WORKFLOW_DEFAULT_NODES_IDS.end,
          );

          if (type === 'channel' && draggedChannel) {
            setDraggedChannel(draggedChannel);
          }

          createNewConnections({
            source: lastEdge.source,
            target: lastEdge.target,
            draggedType: type,
            workflowJson,
            workflowId: Number(workflowId),
            editWorkflowJson,
            reactFlowInstance,
            reOrderedNode: null,
          });
        }}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        className="border border-[#3A3F47] flex flex-col py-2 px-4 cursor-grab"
        draggable={true}
      >
        <span className="text-white font-semibold capitalize">{heading}</span>
        <span className="text-[#ABB0B8]">{text}</span>
      </div>
    </>
  );
};

export default WorkflowSidebarCards;
