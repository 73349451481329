import { NodeProps, Position } from 'reactflow';
import { NodeData, WorkflowNodeTypes } from '../../types';
import WorkFlowHandles from '../handles/WorkflowHandles';
import { getNodeDimensions } from 'views/admin/dashboards/workflowEditor/layout';
import { useShallow } from 'zustand/react/shallow';
import useWorkflowStore from 'store/workflowStore';

type SelectedNodeTypes = Extract<WorkflowNodeTypes, 'start'>;

const text: Record<SelectedNodeTypes, string> = {
  start: 'Api call to trigger an event',
};

const EndNode = (node: NodeProps<NodeData>) => {
  const { selectedNode } = useWorkflowStore(useShallow(state => state));

  return (
    <>
      {!node.data.hideTopHandle && (
        <WorkFlowHandles type="target" position={Position.Top} />
      )}

      <button
        style={{ ...getNodeDimensions(node.type as WorkflowNodeTypes) }}
        className={`bg-[#16171C] text-white rounded-full flex flex-col gap-1 p-4 justify-center 
        ${node.type === 'end' ? 'items-center' : 'items-start'}  w-full h-full
         ${selectedNode?.id === node.id ? 'border border-[#27F05B]' : 'border border-[#3A3F47]'}`}
      >
        <span className="text-sm relative top-[2px]">End</span>

        <span className="text-xs text-[#ABB0B8]">
          {text?.[node.type as SelectedNodeTypes]}
        </span>
      </button>

      {!node.data.hideBottomHandle && (
        <WorkFlowHandles type="source" position={Position.Bottom} />
      )}
    </>
  );
};

export default EndNode;
