import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  VerticalAlignBottomOutlined,
  VerticalAlignCenterOutlined,
  VerticalAlignTopOutlined,
} from '@mui/icons-material';
import { Stack, ToggleButton } from '@mui/material';
import { ImageProps, ImagePropsSchema } from '@usewaypoint/block-image';

import BaseSidebarPanel from './helpers/BaseSidebarPanel';
import RadioGroupInput from './helpers/inputs/RadioGroupInput';
import TextDimensionInput from './helpers/inputs/TextDimensionInput';
import TextInput from './helpers/inputs/TextInput';
import MultiStylePropertyPanel from './helpers/style-inputs/MultiStylePropertyPanel';
import { CustomDropZone } from 'components/fields/FileField/dropzone';
import { Upload } from 'lucide-react';
import { useForm } from 'react-hook-form';
import useCustomToast from 'utils/use-toast';
import { AiTwotoneDelete } from 'react-icons/ai';
import clsx from 'clsx';
import { buttonVariants } from 'components/shadcn/button';
import {
  setFileToUpload,
  editorStateStore,
  setFileToDelete,
} from 'email-editor/documents/editor/EditorContext';

type ImageSidebarPanelProps = {
  id: string;
  data: ImageProps;
  setData: (v: ImageProps) => void;
};
export default function ImageSidebarPanel({
  id,
  data,
  setData,
}: ImageSidebarPanelProps) {
  const [, setErrors] = useState<Zod.ZodError | null>(null);
  const imageUrl = data?.props?.url;

  const updateData = (d: unknown) => {
    const res = ImagePropsSchema.safeParse(d);
    if (res.success) {
      setData({
        ...res.data,
        props: {
          ...res.data.props,
          uploadType: (d as any)?.props?.uploadType || 'url',
        },
      } as ImageProps);
      setErrors(null);
    } else {
      setErrors(res.error);
    }
  };

  const { control } = useForm({
    mode: 'onChange',
  });
  const toast = useCustomToast();

  const [tab, setTab] = React.useState('url');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };
  const uploadType = (data as any).props?.uploadType;
  React.useEffect(() => {
    setTab(uploadType ?? 'url');
  }, [uploadType]);

  return (
    <BaseSidebarPanel title="Image block">
      <Box
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          border: 1,
          borderColor: 'divider',
          p: 2,
          pt: 0,
          borderRadius: 2,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
          <Tabs value={tab} onChange={handleTabChange} centered>
            <Tab value={'url'} label="Url" />
            <Tab value={'file'} label="File" />
          </Tabs>
        </Box>

        {tab === 'file' && (
          <div className="flex flex-col gap-2">
            <CustomDropZone
              name="upload"
              control={control}
              handleOnDrop={(acceptedFiles: File[]) => {
                acceptedFiles.forEach((file: File) => {
                  try {
                    const url = URL.createObjectURL(file);
                    updateData({
                      ...data,
                      props: { ...data.props, url, uploadType: 'file' },
                    });

                    const currentFilesToUpload =
                      editorStateStore.getState().filesToUpload;
                    setFileToUpload([...currentFilesToUpload, { id, file }]);
                  } catch (e) {
                    toast.showError(e);
                  }
                });
              }}
              content={(open, isDragActive) => (
                <div className="flex items-center">
                  <button
                    type="button"
                    onClick={open}
                    className={clsx(
                      'font-semibold bg-[#EDEDEE] h-[100px] w-full flex gap-2 items-center justify-center',
                      imageUrl && 'gap-0 flex-col p-0 m-0',
                    )}
                  >
                    {imageUrl ? (
                      <>
                        <img
                          className="w-full h-full object-contain cursor-pointer rounded-lg"
                          src={imageUrl}
                          alt=""
                        />
                      </>
                    ) : (
                      <>
                        <Upload className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
                        Upload
                      </>
                    )}
                  </button>
                </div>
              )}
            />

            {data.props?.url && (
              <button
                type="button"
                className={clsx(
                  buttonVariants({
                    size: 'sm',
                    variant: 'secondary',
                  }),
                  'w-full p-3 flex gap-2 !bg-red-500',
                )}
                onClick={e => {
                  const currentFilesToDelete =
                    editorStateStore.getState().filesToDelete;
                  const imageFileName = imageUrl.split('/').pop();
                  setFileToDelete([
                    ...currentFilesToDelete,
                    { id: imageFileName },
                  ]);

                  updateData({
                    ...data,
                    props: {
                      ...data.props,
                      url: null,
                      uploadType: 'file',
                    },
                  });

                  e.stopPropagation();
                }}
              >
                <AiTwotoneDelete className="h-4 w-4" />
                Delete
              </button>
            )}
          </div>
        )}

        {tab === 'url' && (
          <TextInput
            label="Source URL"
            defaultValue={data.props?.url ?? ''}
            onChange={v => {
              const url = v.trim().length === 0 ? null : v.trim();
              updateData({
                ...data,
                props: {
                  ...data.props,
                  url,
                  uploadType: 'url',
                },
              });
            }}
          />
        )}
      </Box>

      <TextInput
        label="Alt text"
        defaultValue={data.props?.alt ?? ''}
        onChange={alt => updateData({ ...data, props: { ...data.props, alt } })}
      />
      <TextInput
        label="Click through URL"
        defaultValue={data.props?.linkHref ?? ''}
        onChange={v => {
          const linkHref = v.trim().length === 0 ? null : v.trim();
          updateData({ ...data, props: { ...data.props, linkHref } });
        }}
      />
      <Stack direction="row" spacing={2}>
        <TextDimensionInput
          label="Width"
          defaultValue={data.props?.width}
          onChange={width =>
            updateData({ ...data, props: { ...data.props, width } })
          }
        />
        <TextDimensionInput
          label="Height"
          defaultValue={data.props?.height}
          onChange={height =>
            updateData({ ...data, props: { ...data.props, height } })
          }
        />
      </Stack>

      <RadioGroupInput
        label="Alignment"
        defaultValue={data.props?.contentAlignment ?? 'middle'}
        onChange={contentAlignment =>
          updateData({ ...data, props: { ...data.props, contentAlignment } })
        }
      >
        <ToggleButton value="top">
          <VerticalAlignTopOutlined fontSize="small" />
        </ToggleButton>
        <ToggleButton value="middle">
          <VerticalAlignCenterOutlined fontSize="small" />
        </ToggleButton>
        <ToggleButton value="bottom">
          <VerticalAlignBottomOutlined fontSize="small" />
        </ToggleButton>
      </RadioGroupInput>

      <MultiStylePropertyPanel
        names={['backgroundColor', 'textAlign', 'padding']}
        value={data.style}
        onChange={style => updateData({ ...data, style })}
      />
    </BaseSidebarPanel>
  );
}
