import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { FormAddTopic } from 'views/admin/dashboards/topics/types';

type PutAddTopics = FormAddTopic & {
  appId: number;
};

const post = async (values: PutAddTopics) => {
  const { appId, identifier, name } = values;

  const data = {
    identifier,
    name,
  };

  return axiosInstance.post(`${routes['apps']}/${appId}/topics`, data);
};

const usePostTopics = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: post,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/topics`]);
    },
  });
};

export default usePostTopics;
