import { useGetCommit, usePublishChanges, useRevertChanges } from 'api/commits';
import { PromotedCommits } from 'api/commits/types';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { ScrollArea } from 'components/shadcn/scroll-area';
import { CircleChevronDown, CornerDownRight } from 'lucide-react';
import useCustomToast from 'utils/use-toast';

function RevertModal({
  isOpen,
  onClose,
  selected,
}: {
  onClose: () => void;
  isOpen: boolean;
  selected: PromotedCommits['results'][0];
}) {
  const toast = useCustomToast();
  const postCommits = usePublishChanges();
  const revertChanges = useRevertChanges();
  const getCommit = useGetCommit(selected?.commitId);
  const selectedChanges = getCommit.data?.results.map?.(row => ({
    ...row,
    entityType: row.entityType,
  }));

  const handleClose = () => {
    onClose();
  };

  const getFinalActions = (message: string) => {
    return {
      onSuccess: () => {
        toast.success(message);
        handleClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    };
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      commitId: selected.commitId,
    };

    const finalActions = getFinalActions('changes reverted successfully');
    revertChanges.mutate(data, finalActions);
  };

  return (
    <CustomModal
      modalContentClassName="!max-w-[516px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-3 text-2xl font-bold">Revert</h1>

      <p className="mb-6 text-medium text-[#BFBFBF]">
        Reverting this will undo the changes below (and also any subsequent
        changes made) to these entities.
      </p>

      <form onSubmit={onSubmit}>
        <ScrollArea type="auto" className="h-24 w-full rounded-md border mb-4">
          <div className="p-4">
            <div className="flex gap-2">
              <CircleChevronDown className="w-4 h-4" />

              <h4 className="mb-4 text-base leading-none text-gray-970 font-semibold">
                Changed Entities
              </h4>
            </div>

            <div className="flex gap-4 relative bottom-1 items-start">
              <CornerDownRight className="w-4 h-4 text-gray-820 relative left-1" />

              {selectedChanges?.length > 0 && (
                <div className="flex flex-col relative">
                  {selectedChanges?.map(changes => (
                    <div
                      key={changes?.id}
                      className="text-sm text-gray-820 font-semibold mb-1"
                    >
                      {changes.entityType} - {changes?.relatedEntity?.name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </ScrollArea>

        <CustomModalButtons
          danger={true}
          handleClose={handleClose}
          loading={postCommits.isLoading}
          primaryButtonText="Revert"
        />
      </form>
    </CustomModal>
  );
}

export default RevertModal;
