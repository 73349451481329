import useGetMe from 'api/profile/get-me';
import Card from 'components/card';
import ProfilePicModal from './ProfilePicModal';
import { useDisclosure } from '@chakra-ui/hooks';
import { GoPencil } from 'react-icons/go';

const UserDetails = () => {
  const getMe = useGetMe();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ProfilePicModal isOpen={isOpen} onClose={onClose} />

      <Card extra={'w-full py-[18px] px-[18px] h-[140px]'}>
        <div className="flex items-center gap-3">
          <button
            onClick={onOpen}
            className="relative flex h-[90px] w-[90px] items-center justify-center rounded-full"
          >
            {/* pencil icon */}
            <div className="absolute bottom-0 right-0 rounded-3xl bg-white p-2">
              <GoPencil className="text-black " />
            </div>

            {getMe.data?.clientUser?.profilePicture ? (
              <img
                className="h-full w-full rounded-full"
                src={getMe.data?.clientUser?.profilePicture}
                alt="phot"
              />
            ) : (
              <button className="h-full w-full rounded-full bg-night-100 text-4xl text-white">
                {getMe.data?.clientUser?.firstName?.charAt(0).toUpperCase()}
              </button>
            )}
          </button>
          <div>
            <p className="text-xl font-bold text-navy-700 dark:text-white">
              {getMe.data?.clientUser?.firstName}
            </p>
            <p className="text-base text-gray-600">
              {getMe.data?.clientUser?.email}
            </p>
          </div>
        </div>
      </Card>
    </>
  );
};

export default UserDetails;
