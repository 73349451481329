import { GetLayouts } from 'api/layouts/types';
import { create } from 'zustand';

interface State {
  layout: GetLayouts[0];
  layoutIdentifier: string;
  layoutModalState: 'add' | 'edit';
  savedLayout: GetLayouts[0];
}

interface Action {
  setLayout: (layout: GetLayouts[0]) => void;
  setSavedLayout: (savedLayout: GetLayouts[0]) => void;
  setLayoutIdentifier: (layoutIdentifier: string) => void;
  setLayoutModalState: (layoutModalState: 'add' | 'edit') => void;
  reset: () => void;
}

const initialState: State = {
  layout: null,
  savedLayout: null,
  layoutIdentifier: null,
  layoutModalState: 'add',
};

export const useLayoutStore = create<State & Action>()(set => ({
  ...initialState,
  setLayout: layout => set(() => ({ layout: layout })),
  setSavedLayout: savedLayout => set(() => ({ savedLayout })),
  setLayoutIdentifier: layoutIdentifier => {
    set(() => ({ layoutIdentifier }));
  },
  setLayoutModalState: layoutModalState =>
    set(() => ({
      layoutModalState,
    })),
  reset: () => {
    set(initialState);
  },
}));
