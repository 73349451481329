import CheckMark from '@assets/svg/billing/check-mark-green.svg';
import { CustomModal } from 'components/modal';

function CancelMessageModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const handleClose = () => {
    onClose();
  };

  return (
    <CustomModal
      modalCardClassName="!pb-0 !pt-0 !px-10 !py-8"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="mb-5 flex justify-center">
        <img src={CheckMark} width={145} alt="" />
      </div>

      <h1 className="mb-4 text-center text-2xl font-bold">
        We're sorry to see you go!
      </h1>

      <p className="mb-5 text-center font-medium text-white/50">
        You've successfully canceled your subscription. We hope you've had a
        positive experience with our service so far.
      </p>

      <div className="flex justify-center">
        <button
          className="rounded-lg border border-[#FFFFFF5C] px-8 py-3 font-medium text-white"
          type="button"
          onClick={handleClose}
        >
          Go to Billing
        </button>
      </div>
    </CustomModal>
  );
}

export default CancelMessageModal;
