import { useDisclosure } from '@chakra-ui/hooks';
import { deleteFilesFromServer } from 'api/files/delete';
import { uploadFilesToServerAndGetUrl } from 'api/files/post';
import {
  useGetEmailLayouts,
  useGetLayoutDetails,
  usePatchUpdateLayoutJson,
  usePostSaveEmailLayout,
} from 'api/layouts';
import {
  GetTemplateContent,
  GetTemplateContents,
} from 'api/templates/get-template/types';
import { useGetDefaultTranslation } from 'api/translations';
import useGetUserAttributes from 'api/users/get-users-attributes';
import { useEditWorkflowJson, useSaveEmailWorkflow } from 'api/workflows';
import ErrorBox from 'components/error/ErrorBox';
import CheckField from 'components/fields/CheckField';
import InputField from 'components/fields/InputField';
import { buttonVariants } from 'components/shadcn/button';
import CustomEmailEditor from 'email-editor';
import {
  resetDocument,
  setDocument,
  useDocument,
  useFilesToDelete,
  useFilesToUpload,
} from 'email-editor/documents/editor/EditorContext';
import { produce } from 'immer';
import React from 'react';
import EmailEditor from 'react-email-editor';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useLayoutStore } from 'store/layoutStore';
import { useTemplateStore } from 'store/templateStore';
import useWorkflowStore from 'store/workflowStore';
import { cn } from 'utils/class-merge';
import { R } from 'utils/remeda-utils';
import useCustomToast from 'utils/use-toast';
import { findStepsBeforeSelectedNodeFromJson } from 'views/admin/dashboards/workflowEditor/functions';
import { useShallow } from 'zustand/react/shallow';
import {
  checkIfOldEmailEditorVersion,
  onEmailTemplateReady,
  onEmailTemplateSave,
} from '../../functions';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';
import { TEMPLATE_EDITOR_TYPES } from '../../variables/constants';
import {
  TemplateEditorAvailabeChannels,
  templateEditorInputConfig,
} from '../../variables/inputs';
import AttachmentModal from '../AttachmentModal';
import { EmailLayoutModal } from '../EmailLayoutModal';
import { Paperclip } from 'lucide-react';
import EMPTY_EMAIL_MESSAGE from 'email-editor/getConfiguration/sample/empty-email-message';
import { EmailLoadingAnimation } from '../EmailLoader';

const EmailPreview = ({
  saveRef,
  form,
  setIsEmailDirty,
  emailLayoutRef,
  actingAsLayoutEditor = false,
  actingAsTemplateEditor = false,
  emailEditorRef,
}: {
  saveRef: React.MutableRefObject<HTMLButtonElement>;
  form: UseFormReturn<FieldValues, any>;
  setIsEmailDirty: React.Dispatch<React.SetStateAction<boolean>>;
  emailLayoutRef: React.MutableRefObject<HTMLButtonElement>;
  actingAsLayoutEditor?: boolean;
  actingAsTemplateEditor?: boolean;
  emailEditorRef: React.MutableRefObject<any>;
}) => {
  const params = useParams();
  const templateId = params.templateId;
  const layoutId = params.layoutId;
  const templateIdentifier = params.templateIdentifier;
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const editorType = actingAsLayoutEditor
    ? TEMPLATE_EDITOR_TYPES.LAYOUT
    : params.type;
  const saveEmailWorkflow = useSaveEmailWorkflow();
  const postSaveEmailLayout = usePostSaveEmailLayout();
  const patchUpdateLayoutJson = usePatchUpdateLayoutJson();

  const editWorkflowJson = useEditWorkflowJson();
  const getUserAttributes = useGetUserAttributes();
  const batchingEmailEditorRef = React.useRef(null);
  const { loading, setLoading, tab } = useTemplateStore(state => state);
  const toast = useCustomToast();
  const channelType = 'email';
  const getTemplateBatchingContent = useGetTemplateChannelData();
  const savedTemplateData = React.useMemo(
    () => getTemplateBatchingContent,
    [getTemplateBatchingContent],
  );
  const getLayoutDetails = useGetLayoutDetails(
    Number(layoutId),
    actingAsLayoutEditor,
  );
  const { selectedNode, workflowJson } = useWorkflowStore(
    useShallow(state => state),
  );
  const stepsBeforeSelectedNodeFromJson = findStepsBeforeSelectedNodeFromJson({
    steps: workflowJson?.steps,
    selectedNodeId: selectedNode?.id,
  });
  const document = useDocument();
  const filesToUpload = useFilesToUpload();
  const filesToDelete = useFilesToDelete();

  const [isEmailReady, setIsEmailReady] = React.useState(false);
  const [isEmailDesignLoading, setIsEmailDesignLoading] = React.useState(true);
  const getDefaultTranslation = useGetDefaultTranslation();
  const { setLayoutIdentifier } = useLayoutStore(state => state);
  const emailLayoutModalActions = useDisclosure();
  const attachmentModalActions = useDisclosure();
  const isOldVersion = checkIfOldEmailEditorVersion({
    actingAsTemplateEditor,
    actingAsLayoutEditor,
    workflowVersion: workflowJson?._version,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    resetField,
  } = form;

  const [dataLoaded, setDataLoaded] = React.useState(false);
  const getEmailLayouts = useGetEmailLayouts(true);

  const onReady = async () => {
    if (dataLoaded) return;

    await onEmailTemplateReady({
      emailEditorRef,
      content: savedTemplateData as GetTemplateContents['contents'][0],
      isOldVersion,
    });

    setDataLoaded(true);
  };

  React.useEffect(() => {
    reset({
      ...savedTemplateData,
      subject: (savedTemplateData?.content as GetTemplateContent)?.subject,
      attachmentKey: (savedTemplateData?.content as GetTemplateContent)
        ?.attachmentKey,
      batchingSubject: (
        savedTemplateData?.batchingContent as GetTemplateContent
      )?.subject,
    });
  }, [reset, savedTemplateData]);

  const onSubmit = async (values: any) => {
    try {
      if (actingAsLayoutEditor) {
        const hasBodyForReplacement = R.pipe(
          document,
          R.pickBy((value, key) => {
            if (value?.type === 'Html') {
              return value?.data?.props?.contents?.includes('{{body}}');
            }

            if (value?.type === 'Text') {
              return value?.data?.props?.text?.includes('{{body}}');
            }

            return false;
          }),
          R.keys(),
          R.length,
          R.isTruthy,
        );

        if (!hasBodyForReplacement) {
          return toast.error(
            'Add HTML or Text block with {{body}} content to enable content replacement',
            {
              className: 'csm-toast !normal-case',
            },
          );
        }
      }

      // handle file uploads
      const uploadFilesResponse = await Promise.all(
        filesToUpload.map(data => {
          const imageFormData = new FormData();
          imageFormData.append('image', data.file);
          return uploadFilesToServerAndGetUrl(imageFormData);
        }),
      );

      // handle file deletion
      await Promise.all(
        filesToDelete.map(data => {
          return deleteFilesFromServer(data.id);
        }),
      );

      const documentWithFileUploads = produce(document, draft => {
        uploadFilesResponse.forEach((response, index) => {
          (draft?.[filesToUpload?.[index]?.id]?.data as any).props.url =
            response.data.url;
        });
      });

      setDocument(documentWithFileUploads);

      onEmailTemplateSave({
        batchingEmailEditorRef,
        emailEditorRef,
        id: actingAsLayoutEditor ? layoutId : templateId,
        templateIdentifier,
        setLoading,
        toast,
        channelType,
        values,
        tab,
        setIsEmailDirty,
        editorType: editorType,
        editWorkflowJson,
        saveEmailWorkflow,
        isOldVersion,
        document: documentWithFileUploads,
        postSaveEmailLayout,
        patchUpdateLayoutJson,
        layoutName: getLayoutDetails?.data?.name,
      });

      // would be dirty instead and block page
      resetField('batchingEnabled', {
        defaultValue: false, // Since workflows, no need to support template batching
        keepDirty: false,
      });
    } catch (e) {
      console.log({ e });
    }
  };

  // fallback of 5 seconds for disabling loaders
  React.useEffect(() => {
    setTimeout(() => {
      setIsEmailDesignLoading(false);
    }, 5000);
  }, []);

  // If no saved data hide design loader
  React.useEffect(() => {
    if (!isEmailReady) return;

    const emailData = (savedTemplateData?.['content'] as GetTemplateContent)
      ?.bodyJsonTemplate;

    if (!emailData) {
      setIsEmailDesignLoading(false);
    }
  }, [isEmailReady, savedTemplateData]);

  // attach event listeners and tags
  React.useEffect(() => {
    if (!isEmailReady) return;

    function isUpdated() {
      setIsEmailDirty(true);
    }

    const isEmailDesignLoaded = () => {
      setIsEmailDesignLoading(false);
    };

    const emailEditor = emailEditorRef.current;
    const recipientAttributes = getUserAttributes.data?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.attribute]: {
          name: `{{recipient.${curr.attribute}}}`,
          value: `{{recipient.${curr.attribute}}}`,
        },
      };
    }, {});

    const workflowVariableAttributes = stepsBeforeSelectedNodeFromJson
      ?.filter(channel => channel?.ref?.includes('defineVariable'))
      ?.reduce((acc, curr) => {
        const { name, value } = curr.config;

        return {
          ...acc,
          [name]: {
            name: `{{variables.${name}}}`,
            value: `{{variables.${value}}}`,
          },
        };
      }, {});

    const translationAttributes = R.pipe(
      R.keys(getDefaultTranslation.data?.jsonSpec ?? {}),
      R.map(key => ({
        name: `{{translations.${key}}}`,
        value: `{{translations.${key}}}`,
      })),
    );

    const mergeTags = {
      recipient: {
        name: '{{recipient}}',
        mergeTags: {
          ...recipientAttributes,
        },
      },
      ...(Object.keys(workflowVariableAttributes ?? {})?.length > 0 && {
        variables: {
          name: '{{variables}}',
          mergeTags: {
            ...workflowVariableAttributes,
          },
        },
      }),
      ...(R.keys(translationAttributes)?.length > 0 && {
        translations: {
          name: '{{translations}}',
          mergeTags: {
            ...translationAttributes,
          },
        },
      }),
    };

    // email variables called tags
    emailEditor.setMergeTags(mergeTags);

    // event listeners
    emailEditor.addEventListener('design:updated', isUpdated);
    emailEditor.addEventListener('design:loaded', isEmailDesignLoaded);

    return () => {
      emailEditor.removeEventListener('design:updated', isUpdated);
      emailEditor.removeEventListener('design:loaded', isEmailDesignLoaded);
    };
  }, [
    emailEditorRef,
    getDefaultTranslation.data?.jsonSpec,
    getUserAttributes.data,
    isEmailReady,
    setIsEmailDirty,
    stepsBeforeSelectedNodeFromJson,
  ]);

  React.useEffect(() => {
    const handleLoadNewEmailEditorDesign = async () => {
      if (isOldVersion) return;
      if (dataLoaded) return;
      if (
        actingAsLayoutEditor
          ? getLayoutDetails.isLoading || getLayoutDetails.isFetching
          : // there is no loading flag, so we need to check if data is loaded
            !savedTemplateData
      ) {
        return;
      }

      // defaults ui to empty email
      resetDocument(EMPTY_EMAIL_MESSAGE);

      const content = actingAsLayoutEditor
        ? ({
            content: getLayoutDetails?.data?.jsonSpec,
          } as unknown as GetTemplateContents['contents'][0])
        : (savedTemplateData as GetTemplateContents['contents'][0]);

      // no saved data to load
      if (!content?.content) {
        setIsEmailDesignLoading(false);
        setDataLoaded(true);
        return;
      }

      await onEmailTemplateReady({
        emailEditorRef,
        content,
        isOldVersion,
      });

      setIsEmailDesignLoading(false);
      setDataLoaded(true);
    };

    handleLoadNewEmailEditorDesign();
  }, [
    actingAsLayoutEditor,
    dataLoaded,
    emailEditorRef,
    getLayoutDetails?.data,
    isOldVersion,
    savedTemplateData,
    getLayoutDetails.isLoading,
    getLayoutDetails.isFetching,
  ]);

  React.useEffect(() => {
    const handleSetNewEmailEditorLayout = async () => {
      if (getEmailLayouts.isLoading || actingAsLayoutEditor || isOldVersion)
        return;

      const savedLayoutIdentifier = savedTemplateData?.content?.layout;

      if (savedLayoutIdentifier) {
        setLayoutIdentifier(savedLayoutIdentifier);
        form.setValue('layout', savedLayoutIdentifier);
        return;
      }

      const defaultLayout = R.find(getEmailLayouts.data, layout => {
        return layout.isDefault;
      });
      const selectedIdentifier = defaultLayout?.identifier;
      setLayoutIdentifier(selectedIdentifier);
      form.setValue('layout', selectedIdentifier);
    };

    handleSetNewEmailEditorLayout();
  }, [
    actingAsLayoutEditor,
    form,
    getEmailLayouts.data,
    getEmailLayouts.isLoading,
    savedTemplateData,
    setLayoutIdentifier,
    isOldVersion,
  ]);

  return (
    <>
      {/* hidden email layout modal open button */}
      <button
        ref={emailLayoutRef}
        onClick={emailLayoutModalActions.onOpen}
        hidden={true}
      ></button>
      <EmailLayoutModal
        batchingEmailEditorRef={batchingEmailEditorRef}
        emailEditorRef={emailEditorRef}
        onClose={emailLayoutModalActions.onClose}
        isOpen={emailLayoutModalActions.isOpen}
      />
      <AttachmentModal
        isOpen={attachmentModalActions.isOpen}
        onClose={attachmentModalActions.onClose}
        form={form}
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cn(
          'flex w-full flex-col gap-3 bg-white py-1',
          actingAsLayoutEditor && 'gap-0 py-0 h-full',
          isEmailDesignLoading && 'py-0',
        )}
      >
        <div className="flex">
          <div
            className={cn(
              'grid w-[55%] md:w-[50%] mb-2 gap-5 mx-4',
              tab === 'template'
                ? 'grid-cols-2'
                : 'grid-cols-[auto_45%_55%] lg:grid-cols-[auto_35%_55%] 2xl:grid-cols-[auto_25%_75%]',
              actingAsLayoutEditor && 'hidden',
            )}
          >
            {templateEditorInputConfig?.['email']?.[tab]?.map(option => (
              <div className="w-full" key={option.name}>
                {!actingAsLayoutEditor && option.type === 'input' && (
                  <InputField<any>
                    variant="modal"
                    label={option.label}
                    placeholder={option.placeholder}
                    type="text"
                    showIsRequiredAsterisk={Boolean(option.required)}
                    register={register}
                    name={option.name}
                    extraLabelClass={
                      'font-medium relative top-1 dark:!text-black'
                    }
                    extraInputClass={
                      'border-1 border-[#525151] dark:!bg-white dark:!text-black h-[38px]'
                    }
                    required={Boolean(option.required)}
                    disabled={false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsEmailDirty(true);
                      setValue(option.name, e.target.value);
                    }}
                  />
                )}

                {option.type === 'checkbox' && (
                  <CheckField<any>
                    label={option.label}
                    placeholder={option.placeholder}
                    showIsRequiredAsterisk={Boolean(option.required)}
                    register={register}
                    name={option.name}
                    required={Boolean(option.required)}
                    extra="w-max relative top-[2px]"
                  />
                )}

                {errors[option.name] && (
                  <ErrorBox
                    error={
                      errors[option.name].type === 'required'
                        ? `This field is required`
                        : errors[option.name].type
                    }
                  />
                )}
              </div>
            ))}
          </div>

          {channel === 'email' && (
            <div
              className={cn(
                'flex items-center gap-3 ml-auto pr-4 pt-5 relative top-[2px]',
                actingAsLayoutEditor && 'pt-0',
              )}
            >
              <button
                type="button"
                onClick={() => {
                  attachmentModalActions.onOpen();
                }}
                className={cn(
                  buttonVariants({
                    size: 'sm',
                  }),
                  'border border-input flex gap-1',
                )}
              >
                <Paperclip className="w-4 h-4" />

                {savedTemplateData?.content?.attachmentKey
                  ? 'Edit Attachment Key'
                  : 'Add Attachment Key'}
              </button>
            </div>
          )}
        </div>

        {/* <p className="text-sm text-white opacity-[35%] mx-4">
          You can use template variables to add dynamic data to your messages -
          <a
            className="text-decoration-line: underline"
            href="https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables"
            target="_blank"
            rel="noreferrer"
          >
            Read guide
          </a>
          . Free plans will have a "Powered by Engagespot" branding on all
          emails.
        </p> */}

        {isOldVersion ? (
          <div
            className={`relative ${
              tab === 'template'
                ? 'csm-email-preview-parent block h-full'
                : 'hidden'
            }`}
          >
            <EmailLoadingAnimation isLoading={isEmailDesignLoading} />
            <EmailEditor
              style={{
                marginBottom: '10px',
              }}
              ref={emailEditorRef}
              onReady={() => {
                onReady();
                setIsEmailReady(true);
              }}
              appearance={{
                theme: 'dark',
              }}
              tools={{
                image: {
                  properties: {
                    src: {
                      // @ts-ignore
                      value: {
                        url: 'https://cdn.engagespot.co/misc/image_placeholder.png',
                      },
                    },
                  },
                },
              }}
            />
          </div>
        ) : (
          <div
            className={cn(
              'h-full relative overflow-auto z-10 border-t border-input/20',
              isEmailDesignLoading && 'border-none',
            )}
          >
            <EmailLoadingAnimation isLoading={isEmailDesignLoading} />
            <CustomEmailEditor />
          </div>
        )}

        {/* hidden submit button */}
        <button
          type="submit"
          disabled={
            loading || (tab === 'template' && isOldVersion && !isEmailReady)
          }
          hidden={true}
          ref={saveRef}
        >
          submit
        </button>
      </form>
    </>
  );
};

export default EmailPreview;
