import { Plans } from 'api/billing/get-plans/types';
import { create } from 'zustand';

interface State {
  plan: Plans;
  discountModalState: boolean;
}

interface Action {
  setPlan: (plan: Plans) => void;
  setDiscountModalState: (state: boolean) => void;
  reset: () => void;
}

const initialState: State = {
  plan: null,
  discountModalState: false,
};

export const useBillingStore = create<State & Action>()(set => ({
  ...initialState,
  setPlan: plan => set(() => ({ plan })),
  setDiscountModalState: state =>
    set(() => ({
      discountModalState: state,
    })),
  reset: () => {
    set(initialState);
  },
}));
