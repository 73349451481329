import useGetClientApps from 'api/client/get-client-apps';
import { SelectOptions } from 'components/fields/SelectField/types';
import { useAuthStore } from 'store/authStore';

export const useGetAppOptions = () => {
  const { clientId, appId } = useAuthStore(state => state);
  const getClientApps = useGetClientApps(clientId);
  const appsOptions = getClientApps?.data?.reduce((acc, curr) => {
    return [
      ...acc,
      {
        label: curr.name,
        value: String(curr.id),
      },
    ];
  }, [] as SelectOptions[]);

  const appsOptionsExcludingCurrentApp = appsOptions?.filter(
    option => option.value !== String(appId),
  );

  const currentAppOrder = getClientApps.data?.find(
    app => app.id === appId,
  )?.order;

  const appsOptionsWithHigherOrderThanCurrentApp = getClientApps.data?.reduce(
    (acc, curr) => {
      if (curr.order > currentAppOrder) {
        return [
          ...acc,
          {
            label: curr.name,
            value: String(curr.id),
          },
        ];
      }

      return acc;
    },
    [] as SelectOptions[],
  );

  return {
    appsOptions,
    appsOptionsExcludingCurrentApp,
    appsOptionsWithHigherOrderThanCurrentApp,
  };
};
