import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';

type PatchClientUser = {
  clientId: number;
  name: string;
};

const patch = async (values: PatchClientUser) => {
  const { clientId, ...data } = values;
  return axiosGlobalInstance.patch(`${routes['clients']}/${clientId}`, data);
};

const usePatchClientUser = () => {
  return useMutation({
    mutationFn: (values: PatchClientUser) => patch(values),
  });
};

export default usePatchClientUser;
