import getSymbolFromCurrency from 'currency-symbol-map';

const currentSelectedCurrencySymbol = 'USD';

export const getCurrencySymbol = () => {
  return getSymbolFromCurrency(currentSelectedCurrencySymbol);
};

export const centsToDollars = (cents: number | string) => {
  return Number(cents) / 100;
};
