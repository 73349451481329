import { useMutation } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { routes } from 'api/routes';

type DeleteCancelPlan = {
  clientId: string;
};

const remove = (values: DeleteCancelPlan) => {
  const { clientId } = values;
  return axiosLicensingInstance.delete(
    `${routes['client']}/${clientId}/subscription`,
  );
};

const useCancelSubscription = () => {
  return useMutation({
    mutationFn: remove,
  });
};

export default useCancelSubscription;
