import { oneInputTemplate } from 'templates/one_input/config';
import { componentBlockConfig } from 'templates/types';
import { createDefaultInAppConfig } from 'templates/utils';
import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';

/**
 * used in setting initial inapp api data
 * and also when watching form value changes
 */
const defaultValues = oneInputTemplate.defaultValues;

const getBlockConfig = (values = defaultValues): componentBlockConfig => {
  const oneInputConfig = oneInputTemplate.blockConfig(values);

  return {
    state_1: [
      {
        id: 'state_1_rating_star1',
        type: 'rating-star' as const,
        text: '', // rating is not bound to console inputs, as we need to get input from the user
        variant: 'primary',
      },
      ...oneInputConfig.state_1,
    ],
    state_2: [],
  };
};

const state_1Config = createDefaultInAppConfig();
const { template } = state_1Config;

const oneInputConsoleConfig =
  oneInputTemplate.consoleInputs['state_1'].template[3];

export const consoleConfig = {
  state_1: {
    template: [
      template[0],
      template[1],
      template[2],
      {
        type: 'div',
        name: 'parent',
        children: [
          {
            name: 'state_1_rating_star1',
            additionalSettings: {
              label: 'Input_id',
              required: false,
              placeholder: 'switch',
              name: 'state_1_rating_star1_active',
              type: 'switch',
              additionalSettingsEnableHidden: true,
              overwriteHeading: 'Rating Advanced settings',
            },
          },
        ],
      },
      oneInputConsoleConfig,
      template[3],
    ],
    batched_template: {},
  },
  state_2: createDefaultInAppConfig({ namePrefix: 'state_2' }),
};

export const ratingStarOneInputTemplate = {
  blockConfig: (values: any) => getBlockConfig(values),
  states: oneInputTemplate.states,
  consoleInputs: consoleConfig as inAppTemplate,
  defaultValues,
  info: oneInputTemplate.info,
  stateToSettingsMapping: oneInputTemplate.stateToSettingsMapping,
};
