import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { routes } from 'api/routes';
import { GetUpcomingInvoices } from './types';

export type GetPlansParams = {
  billableComponent: 'notification';
};

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<GetUpcomingInvoices> => {
  const [, clientId] = queryKey;

  const { data } = await axiosLicensingInstance.get(
    `${routes['client']}/${clientId}/invoices/upcoming`,
  );

  return data;
};

const useGetUpcomingInvoices = (clientId: string, enabled: boolean) => {
  return useQuery({
    queryKey: [`${routes['client']}/invoices/upcoming`, clientId],
    queryFn: get,
    enabled: Boolean(clientId) && enabled,
  });
};

export default useGetUpcomingInvoices;
