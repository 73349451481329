import { PaginationState } from '@tanstack/react-table';
import InputField from 'components/fields/InputField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { Search } from 'lucide-react';
import React from 'react';
import useFunctionDebounce from 'utils/use-debounce';
import { SearchBarProps } from './searchbar';

export type SearchBarApiProps = {
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  isFetching?: boolean;
} & SearchBarProps;

const SearchBarApi = ({
  setFilter,
  setPagination,
  filter,
  isFetching = false,
}: SearchBarApiProps) => {
  const [input, setInput] = React.useState(filter);

  const handleSetFilters = (value: string) => {
    setFilter(value);
    setPagination(state => ({ ...state, pageNo: 1 }));
  };

  const optimisedHandleSetFilters = useFunctionDebounce(handleSetFilters, 300);

  const handleChange = (value: string) => {
    setInput(value);
    optimisedHandleSetFilters(value);
  };

  return (
    <>
      <div className="relative flex items-center">
        <Search className="absolute left-2.5 top-[30%] h-4 w-4 text-muted-foreground" />

        <InputField
          placeholder="Search..."
          extraInputClass="w-full rounded-lg h-[36px] bg-background pl-8 md:w-[200px] lg:w-[336px] text-white"
          variant="default"
          onChange={(e: any) => handleChange(e.target.value)}
          value={input ?? ''}
        />

        {filter && isFetching && (
          <div className="absolute top-[25%] right-5">
            <LoaderSpinner
              width="20"
              strokeColor="gray"
              parentClass="relative left-2"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SearchBarApi;
