import {
  QueryFunctionContext,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetUserDetails } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number, string]>): Promise<GetUserDetails> => {
  const [, appId, identifier] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/users/${identifier}/profile`,
  );

  return data;
};

export const useMutateGetUserDetails = () => {
  const { appId } = useAuthStore(state => state);

  return useMutation({
    mutationFn: ({ identifier }: { identifier: string }) =>
      get({
        queryKey: [`${routes['apps']}/user-details`, appId, identifier],
        meta: {},
      }),
  });
};

const useGetUserDetails = ({
  identifier,
  enabled = true,
}: {
  identifier: string;
  enabled?: boolean;
}) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/user-details`, appId, identifier],
    queryFn: get,
    enabled: Boolean(identifier) && enabled,
  });
};

export default useGetUserDetails;
