import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import { PaginationState } from '@tanstack/react-table';
import { Button } from 'components/shadcn/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/shadcn/select';
import React from 'react';
import { PAGE_SIZES_ARRRAY } from './pagination-state';

export type Pagination = {
  limit: number;
  pageNo: number;
};

function CustomTableApiPagination({
  pagination,
  setPagination,
  totalPages,
}: {
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  totalPages: number;
}) {
  return (
    <>
      <div className="flex items-center justify-end px-2 text-white mt-3">
        <div className="flex justify-between items-center space-x-6 lg:space-x-8">
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">Rows per page</p>
            <Select
              value={String(pagination.pageSize)}
              onValueChange={value => {
                setPagination(state => ({
                  ...state,
                  pageSize: Number(value),
                }));
              }}
            >
              <SelectTrigger className="h-8 w-[70px]">
                <SelectValue placeholder={pagination.pageSize} />
              </SelectTrigger>
              <SelectContent side="top">
                {PAGE_SIZES_ARRRAY.map(pageSize => (
                  <SelectItem key={pageSize} value={`${pageSize}`}>
                    {pageSize}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {pagination.pageIndex} of {totalPages}
          </div>

          <div className="flex items-center space-x-2">
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() =>
                setPagination(state => ({
                  ...state,
                  pageIndex: 1,
                }))
              }
              disabled={pagination.pageIndex === 1}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>

            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() =>
                setPagination(state => ({
                  ...state,
                  pageIndex: state.pageIndex - 1,
                }))
              }
              disabled={pagination.pageIndex === 1}
            >
              <span className="sr-only">Go to previous page</span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>

            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => {
                setPagination(state => ({
                  ...state,
                  pageIndex: state.pageIndex + 1,
                }));
              }}
              disabled={pagination.pageIndex === totalPages}
            >
              <span className="sr-only">Go to next page</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>

            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() =>
                setPagination(state => ({
                  ...state,
                  pageIndex: totalPages,
                }))
              }
              disabled={pagination.pageIndex === totalPages}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomTableApiPagination;
