import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { FormAddTemplates } from 'views/admin/dashboards/template/types';

type PutAddApps = Partial<FormAddTemplates> & {
  templateId: number;
  type: 'delete' | 'put';
};

const putOrDelete = async (values: PutAddApps) => {
  const { templateId, name, type, category, identifier } = values;

  const url = `${routes['templates']}/${templateId}`;

  const data = {
    name,
    category,
    identifier,
  };

  if (type === 'delete') {
    return axiosInstance.delete(url);
  }

  return axiosInstance.put(url, data);
};

const usePutOrDeleteTemplates = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: putOrDelete,
    onSuccess: () => {
      queryClient.invalidateQueries([`${routes['apps']}/templates`]);
    },
  });
};

export default usePutOrDeleteTemplates;
