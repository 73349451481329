import { axiosInstance } from 'api/axios';

export type UpdateTemplatePutReturnType = {
  id?: number;
  channel: string;
  content: {
    title: string;
    message: string;
  };
  isDisabled: boolean;
  order: number;
  trigger: string;
  triggerDelay: number;
  triggerCondition?: any;
  batchingEnabled: boolean;
  batchingWindow: number;
  batchingContent?: any;
};

export const updateTemplates = ({
  channelType,
  data,
  id,
  type,
}: {
  id: string;
  channelType: string;
  data: any;
  type: 'put_content' | 'patch_content' | 'put_batching_content';
}) => {
  let url = `/v1/templates/${id}/contents/${channelType}`;
  let method = 'put';

  if (type === 'patch_content') {
    method = 'patch';
  }

  if (type === 'put_batching_content') {
    url = `${url}/batchingContent`;
  }

  return axiosInstance({ method, url, data });
};
