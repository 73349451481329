import { yupResolver } from '@hookform/resolvers/yup';
import usePostResetPassword from 'api/reset-password/post-reset-password';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import Default from 'layouts/auth/types/Default';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';
import { schema } from './schema';
import { IFormResetPassword, IPostResetPassword } from './types';
import {
  AuthHeading,
  AuthSubmitButton,
  AuthText,
} from 'components/auth/AuthComponents';

function ResetPasswordDefault() {
  const postResetPassword = usePostResetPassword<IPostResetPassword>();
  const navigate = useNavigate();
  const toast = useCustomToast();
  const [searchParams] = useSearchParams();
  const passwordResetHash = new URLSearchParams(searchParams).get(
    'resetPasswordHash',
  );
  const redirect = new URLSearchParams(searchParams).get('redirect');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormResetPassword>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<IFormResetPassword> = values => {
    const data = {
      passwordResetHash,
      newPassword: values.newPassword,
    };

    postResetPassword.mutate(data, {
      onSuccess: () => {
        toast.success('password reset successfully');

        if (Boolean(redirect)) {
          return navigate(`/auth/login?redirect=${redirect}`);
        } else {
          return navigate('/auth/login');
        }
      },
    });
  };

  return (
    <Default
      maincard={
        <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="mt-[10vh] min-h-[605px] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]"
          >
            <AuthHeading text="Reset password" />

            <div className="mb-9 mt-2 text-lg">
              <AuthText text="Enter your new password!" />
            </div>

            <div className="mb-7">
              <InputField<IFormResetPassword>
                variant="dark"
                extra="mb-2"
                label="New Password"
                placeholder="Enter new password"
                type="text"
                showIsRequiredAsterisk={true}
                register={register}
                name={'newPassword'}
              />
              {errors.newPassword && <ErrorBox error={errors.newPassword} />}
            </div>

            <div className="mb-7">
              <InputField<IFormResetPassword>
                variant="dark"
                extra="mb-2"
                label="Confirm Password"
                placeholder="Confirm new password"
                type="text"
                showIsRequiredAsterisk={true}
                register={register}
                name={'confirmPassword'}
              />
              {errors.confirmPassword && (
                <ErrorBox error={errors.confirmPassword} />
              )}
            </div>

            <div className="mt-8">
              {postResetPassword.error && (
                <div className="mb-3">
                  <ErrorBox error={postResetPassword.error} />
                </div>
              )}

              <AuthSubmitButton
                isLoading={postResetPassword.isLoading}
                disabled={postResetPassword.isLoading}
                text={'Save Password'}
                className="!mt-0"
              />
            </div>
          </form>
        </div>
      }
    />
  );
}

export default ResetPasswordDefault;
