import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetTemplateContents } from './types';
import { routes } from 'api/routes';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetTemplateContents> => {
  const [, templateId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['templates']}/${templateId}`,
  );
  return data;
};

const useGetTemplate = (templateId: number, enabled = true) => {
  return useQuery({
    queryKey: [`${routes['templates']}`, templateId],
    queryFn: get,
    enabled: Boolean(templateId && enabled),
    staleTime: 1000 * 5, // 5 seconds
  });
};

export default useGetTemplate;
