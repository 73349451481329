import { useMutation, useQueryClient } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { ChannelTriggers } from 'views/admin/dashboards/templateChannels/components/TriggerModal';
import { GetTemplateContents } from './get-template/types';

type PatchContentData = {
  isDisabled?: boolean;
  batchingEnabled?: boolean;
  batchingWindow?: number;
  trigger?: ChannelTriggers;
  triggerDelay?: number;
  triggerCondition?: string;
};

export type PatchContent = {
  channelType: string;
  templateId: string;
  data: PatchContentData;
};

const patch = async (values: PatchContent) => {
  const { templateId, channelType, data } = values;

  return axiosInstance.patch(
    `${routes['templates']}/${templateId}/contents/${channelType}`,
    data,
  );
};

const usePatchTemplateContent = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: patch,
    onMutate: async newData => {
      const templateId = Number(newData.templateId);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([`${routes['templates']}`, templateId]);

      // Snapshot the previous value
      const previousData: GetTemplateContents = queryClient.getQueryData([
        `${routes['templates']}`,
        templateId,
      ]);

      const newMutatedData = previousData.contents.reduce((acc, curr) => {
        let currentValue = curr;

        if (curr.channel === newData.channelType) {
          currentValue = {
            ...curr,
            isDisabled: newData.data.isDisabled,
          };
        }

        return [...acc, currentValue];
      }, []);

      // Optimistically update to the new value
      queryClient.setQueryData([`${routes['templates']}`, templateId], () => {
        return {
          ...previousData,
          contents: newMutatedData,
        };
      });

      return { previousData, templateId };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (_err, _newData, context) => {
      queryClient.setQueryData(
        [`${routes['templates']}`, context.templateId],
        context.previousData,
      );
    },
    // Always refetch after error or success:
    onSettled: (_newData, _error, _variables, context) => {
      queryClient.invalidateQueries([
        `${routes['templates']}`,
        context.templateId,
      ]);
    },
  });
};

export default usePatchTemplateContent;
