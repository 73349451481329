import EngageSpotSquare from '@assets/img/others/engagespot-square.png';
import usePostResendVerificationEmail from 'api/resend-verification-email/post-resend-verification-email';
import {
  AuthHeading,
  AuthSubmitButton,
  AuthText,
} from 'components/auth/AuthComponents';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useTimer } from 'utils/use-timer';
import useCustomToast from 'utils/use-toast';

function VerificationDefault() {
  const navigate = useNavigate();
  const toast = useCustomToast();
  const email = useAuthStore(state => state.email);
  const setEmail = useAuthStore(state => state.setEmail);
  const postResendVerificationEmail = usePostResendVerificationEmail();
  const [timer, setTimer] = React.useState(0);

  const resendVerificationEmail = () => {
    const data = {
      email,
    };

    postResendVerificationEmail.mutate(data, {
      onSuccess: () => {
        toast.success(
          "We've resent your verification email. Make sure to check your spam folder.",
        );
        setTimer(50);
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  // on leaving page clear email
  React.useEffect(() => {
    const beforeunload = () => {
      setEmail('');
    };

    window.addEventListener('beforeunload', beforeunload);

    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [setEmail]);

  React.useEffect(() => {
    if (!email) {
      navigate('/auth/login');
    }
  }, [email, navigate]);

  useTimer({ timer, setTimer });

  return (
    <div className="flex h-[100vh] flex-col items-center justify-center">
      <img className="relative bottom-9" src={EngageSpotSquare} alt="" />

      <AuthHeading text="Check your inbox" />

      <AuthText
        text={`We just sent a verification link to ${email}. \n Please click on the link you received to verify your email.`}
        className="mt-3 whitespace-pre-wrap text-center text-lg"
      />

      <button
        onClick={() => {
          resendVerificationEmail();
        }}
        disabled={postResendVerificationEmail.isLoading || timer !== 0}
        className={`hover:text-yellow-60 mb-4 ml-1 mt-2 ${
          timer === 0 ? 'border-b-2 disabled:text-yellow-600' : ''
        }   border-dotted border-yellow-450 font-normal text-yellow-450 hover:text-yellow-600 disabled:cursor-not-allowed`}
      >
        {timer === 0 ? (
          "Didn't receive the email? Resend"
        ) : (
          <span className="text-white">
            Resend again in <span className="text-yellow-450">{timer}s</span>
          </span>
        )}
      </button>

      <form
        className=""
        onSubmit={e => {
          e.preventDefault();
          navigate('/auth/login');
        }}
      >
        {/* <AuthSubmitButton
          className="!mt-0 px-9"
          disabled={postResendVerificationEmail.isLoading}
          text={'< Back to login'}
        /> */}
      </form>
    </div>
  );
}

export default VerificationDefault;
