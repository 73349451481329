import { Handle, HandleType, Position } from 'reactflow';

const WorkFlowHandles = ({
  type,
  position,
  className,
}: {
  type: HandleType;
  position: Position;
  className?: string;
}) => {
  return (
    <Handle
      className={`workflow_handle ${className}`}
      type={type}
      position={position}
    />
  );
};

export default WorkFlowHandles;
