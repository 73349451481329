// ********************************
// * IMPORTANT *
// * UNIQUE ID OF DEFAULT STATE , DO NOT CHANGE THIS CASUALLY *

export const DEFAULT_TEMPLATE_STATE = 'state_1';

// ********************************

export const createDefaultInAppConfig = (
  {
    namePrefix,
  }: {
    namePrefix?: string;
  } = {
    namePrefix: 'state_1',
  },
) => {
  return {
    template: [
      {
        label: 'Avatar',
        required: false,
        placeholder: 'https://example.com',
        name: `${namePrefix}_icon`,
        type: 'input|file',
      },
      {
        label: 'Title',
        required: true,
        placeholder: 'Title',
        name: `${namePrefix}_title`,
        type: 'textarea' as const,
        helperText:
          "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
        rows: 3,
      },
      {
        label: 'Message',
        required: false,
        placeholder: 'Message',
        name: `${namePrefix}_message`,
        type: 'textarea' as const,
      },
      {
        label: 'Action Url',
        required: false,
        placeholder: 'Action Url',
        name: `${namePrefix}_url`,
        type: 'input' as const,
      },
    ],
    batched_template: {},
  };
};

export const createUniqueTemplateBlockId = ({
  templateIdentifier,
  batchingState,
  templateState,
  index,
}: {
  templateIdentifier: string;
  batchingState: 0 | 1;
  templateState: string;
  index: number;
}) => {
  const uniqueBlockId = `${templateIdentifier}_b${batchingState}_${templateState}_i${index}`;
  return uniqueBlockId;
};

export const getBlockActivateName = (name: string) => {
  return `${name}_active`;
};

export const getBlockLinkName = (name: string) => {
  return `${name}_link`;
};

export const getBlockIsExternalLinkName = (name: string) => {
  return `${name}_external_link`;
};
