import { yupResolver } from '@hookform/resolvers/yup';
import SelectField from 'components/fields/SelectField';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import {
  convertMillisecondsToTime,
  convertTimeToMilliseconds,
  findSelectedNodeFromJson,
  handleJsonDiffCheck,
  handleTimeExceedsErrors,
} from '../../functions';
import {
  useFormValidateOnLoad,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import { timeSchema } from '../../schemas';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import WorkflowIntervalInputs from '../inputs/WorkflowIntervalInputs';
import { WorkflowFormHeader } from './WorkflowFormHeader';

type FormWorkflowBatch = {
  days?: string;
  hours?: string;
  mins?: string;
  secs?: string;
  batchOrder?: string;
  batchWindowType?: string;
  triggerCondition?: string;
};

const WorkflowBatchForm = () => {
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );
  const formMethods = useForm<FormWorkflowBatch>({
    mode: 'onChange',
    resolver: yupResolver(timeSchema),
  });

  const { setValue, reset, getValues, control, trigger } = formMethods;

  const handleChange = () => {
    const selectedNodeId = selectedNode?.id;
    const dataValues = getValues();
    const {
      days,
      hours,
      mins,
      secs,
      triggerCondition,
      batchOrder,
      batchWindowType,
    } = dataValues;

    const batchWindow = convertTimeToMilliseconds({
      days,
      hours,
      mins,
      secs,
    });

    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      selected.triggerCondition = triggerCondition;

      selected.config = {
        ...selected.config,
        batchOrder,
        batchWindowType,
        batchWindow,
      };
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });
    const selectedConfig = selectedNodeJson?.config;

    const { days, hours, mins, secs } = convertMillisecondsToTime(
      selectedConfig?.batchWindow,
    );

    reset({
      days: String(days),
      hours: String(hours),
      mins: String(mins),
      secs: String(secs),
      batchOrder: selectedConfig?.batchOrder,
      batchWindowType: selectedConfig?.batchWindowType,
      triggerCondition: selectedNodeJson?.triggerCondition,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useFormValidateOnLoad({
    dataLoaded,
    trigger,
  });

  useWatchWorkflowFormErrors({
    control,
    handleErrors: ({ setErrors, errors }) =>
      handleTimeExceedsErrors({
        setErrors,
        errors,
        dataValues: getValues(),
      }),
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  return (
    <div>
      <WorkflowFormHeader heading="Batch" docKey="batch" />

      <div className="text-[#ABB0B8] font-medium mb-3">
        Set a duration for this batch function.
      </div>

      <FormProvider {...formMethods}>
        <form className="mt-6 flex flex-col gap-6">
          <SelectField<FormWorkflowBatch>
            variant="styled"
            control={control}
            extra="w-full"
            label={'Order'}
            placeholder={'Batch Order'}
            name={'batchOrder'}
            styleVariant="workflow"
            options={[
              {
                value: 'asc',
                label: 'Ascending',
              },
              {
                value: 'desc',
                label: 'Descending',
              },
            ]}
          />

          <SelectField<FormWorkflowBatch>
            variant="styled"
            control={control}
            extra="w-full"
            label={'Window Type'}
            placeholder={'Batch Window Type'}
            name={'batchWindowType'}
            styleVariant="workflow"
            options={[
              {
                value: 'fixed',
                label: 'Fixed',
              },
            ]}
          />

          <div>
            <WorkflowIntervalInputs
              name={'days'}
              handleChange={handleChange}
              setValue={setValue}
              inputLabel={'Batch Window'}
              blockHeading="Delay type"
              blockText="days"
            />

            <WorkflowIntervalInputs
              name={'hours'}
              handleChange={handleChange}
              setValue={setValue}
              blockText="hours"
            />

            <WorkflowIntervalInputs
              name={'mins'}
              handleChange={handleChange}
              setValue={setValue}
              blockText="minutes"
            />

            <WorkflowIntervalInputs
              name={'secs'}
              handleChange={handleChange}
              setValue={setValue}
              blockText="seconds"
            />
          </div>

          <WorkflowConditionForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default WorkflowBatchForm;
