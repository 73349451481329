import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@radix-ui/react-hover-card';
import { Channels } from 'api/channels/get-channels/types';
import { GetProviders } from 'api/providers/get-providers/types';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import { useForm } from 'react-hook-form';
import { PiWarningCircleBold } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { docLinkCreator, getInstanceName } from '../functions';
import ChannelNote from './ChannelNote';

function ConfigModal({
  isOpen,
  onClose,
  provider,
  saveProviderSubmit,
  isLoading,
  providerIdentifier,
}: {
  isLoading: boolean;
  onClose: () => void;
  isOpen: boolean;
  providerIdentifier: string;
  provider:
    | GetProviders['availableProviders'][0]
    | GetProviders['configuredProviders'][0];
  saveProviderSubmit: ({
    handleClose,
    values,
  }: {
    values: any;
    handleClose: () => void;
  }) => void;
}) {
  const params = useParams();
  const channel = params.channel as Channels;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    reValidateMode: 'onBlur',
  });

  const configured = 'id' in provider;

  const configOptions = configured
    ? provider.provider.configOptions
    : provider.configOptions;

  React.useEffect(() => {
    if (!configured) {
      const providerInstanceName = getInstanceName({ name: provider.name });

      reset({
        instanceName: Boolean(provider.name) ? providerInstanceName : '',
      });

      return;
    }

    const savedValues = provider.configs.reduce((acc, curr) => {
      return {
        ...acc,
        [curr['config']]: curr['value'],
      };
    }, {});

    reset({
      ...savedValues,
      instanceName: Boolean(provider.instanceName)
        ? provider.instanceName
        : 'DEFAULT INSTANCE',
    });
  }, [reset, provider, isOpen, setValue, configured]);

  const handleClose = () => {
    reset({ instanceName: '' });
    onClose();
  };

  const onSubmit = (values: any) => {
    saveProviderSubmit({
      handleClose,
      values,
    });
  };

  const docLink = docLinkCreator({
    section: 'channels',
    channel,
    providerIdentifier,
  });

  return (
    <CustomModal
      modalContentClassName="!min-w-[580px] !top-[25%]"
      modalCardClassName="!max-w-[580px] !overflow-auto"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-3 text-2xl font-bold capitalize">
        {configured ? provider.provider.name : provider.name} Provider
        Configuration
      </h1>
      {/* <p className="mb-6 text-sm text-[#BFBFBF]">
              Some providers require additional setup before it can work
              properly. Make sure to read
              <a
                href={`https://docs.engagespot.co/docs/features/channels-and-providers/enabling-a-channel`}
                className="mx-1 font-bold text-brand-500 hover:text-brand-500"
                target="_blank"
                rel="noreferrer"
              >
                docs
              </a>
              to learn more about enabling providers.
            </p> */}

      {Boolean(docLink) && (
        <p className="mb-6 text-sm text-[#BFBFBF]">
          Read
          <a
            href={docLink}
            className="mx-1 font-bold text-brand-500 hover:text-brand-500"
            target="_blank"
            rel="noreferrer"
          >
            docs
          </a>
          to learn more about this providers and its configurations
        </p>
      )}

      <form
        className={Boolean(docLink) ? '' : 'mt-3'}
        onSubmit={handleSubmit(onSubmit)}
      >
        <InputField
          variant="modal"
          extra="mb-3"
          placeholder="Enter any name you want to uniquely identify this provider instance"
          type="text"
          showIsRequiredAsterisk={true}
          register={register}
          required={true}
          name={'instanceName'}
          extraLabelClass="!uppercase"
          customLabelContent={() => (
            <div className="flex items-center gap-3">
              <label
                className={`ml-1.5 text-sm font-medium text-navy-700 dark:text-white`}
              >
                {'INSTANCE NAME'}
                {Boolean(true) && <span className="text-brand-400">*</span>}
              </label>

              {Boolean(true) && (
                <HoverCard>
                  <HoverCardTrigger>
                    <PiWarningCircleBold
                      size={14}
                      color="gray"
                      className="relative cursor-pointer"
                    />
                  </HoverCardTrigger>

                  <HoverCardContent className="relative top-2 z-[100] !w-[250px] rounded-xl border border-white/30 bg-gray-850 p-2 text-sm">
                    {
                      'To uniquely idenitfy an instance of a provider, since we can add multiple instance of the same provider'
                    }
                  </HoverCardContent>
                </HoverCard>
              )}
            </div>
          )}
        />
        {errors?.['instanceName'] && (
          <ErrorBox
            error={
              errors['instanceName'].type === 'required'
                ? `This field is required`
                : errors['instanceName'].type
            }
          />
        )}

        {configOptions.map(option => (
          <>
            <InputField
              variant="modal"
              extra="mb-3"
              placeholder="Enter Values"
              type="text"
              showIsRequiredAsterisk={Boolean(option.required)}
              register={register}
              name={option.config}
              extraLabelClass="!uppercase"
              required={Boolean(option.required)}
              customLabelContent={() => (
                <div className="flex items-center gap-3">
                  <label
                    className={`ml-1.5 text-sm font-medium text-navy-700 dark:text-white`}
                  >
                    {option.config}
                    {Boolean(option.required) && (
                      <span className="text-brand-400">*</span>
                    )}
                  </label>

                  {Boolean(option.description) && (
                    <HoverCard>
                      <HoverCardTrigger>
                        <PiWarningCircleBold
                          size={14}
                          color="gray"
                          className="relative cursor-pointer"
                        />
                      </HoverCardTrigger>

                      <HoverCardContent className="relative top-2 z-[100] !w-[250px] rounded-xl border border-white/30 bg-gray-850 p-2 text-sm">
                        {option.description}
                      </HoverCardContent>
                    </HoverCard>
                  )}
                </div>
              )}
            />
            {errors[option.config] && (
              <ErrorBox
                error={
                  errors[option.config].type === 'required'
                    ? `This field is required`
                    : errors[option.config].type
                }
              />
            )}
          </>
        ))}

        {configured && provider.callbackURL && (
          <div>
            <label
              className={`ml-3 text-sm font-bold text-navy-700 dark:text-white`}
            >
              CALLBACK URL
            </label>

            <div className="mt-3  flex h-12 w-full items-center overflow-x-auto whitespace-nowrap rounded-xl border-0 px-3 dark:!bg-gray-150/100">
              {provider.callbackURL}
            </div>
          </div>
        )}

        <ChannelNote
          channel={channel}
          providerIdentifier={providerIdentifier}
        />

        <CustomModalButtons loading={isLoading} handleClose={handleClose} />
      </form>
    </CustomModal>
  );
}

export default ConfigModal;
