import TableMenu from 'components/table/table-menu';
import React from 'react';
import { TeamAction, useTeamStore } from 'store/teamStore';

function CardMenu(props: { onOpen: () => void; invitationId: number }) {
  const { setInvitationId, setTypeOfAction } = useTeamStore(state => state);
  const { onOpen, invitationId } = props;
  const handleMenuClick = (actionType: TeamAction) => {
    setTypeOfAction(actionType);
    setInvitationId(invitationId);
    onOpen();
  };

  return (
    <>
      <TableMenu
        config={[
          {
            text: 'Cancel Invitation',
            action: () => handleMenuClick('delete_invitations'),
          },
          {
            text: 'Resent Invitation',
            action: () => handleMenuClick('resend_invitations'),
          },
        ]}
      />
    </>
  );
}

export default CardMenu;
