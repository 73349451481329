import { create } from 'zustand';

interface State {
  isOpen: boolean;
  text: string;
}

interface Action {
  onOpen: () => void;
  onClose: () => void;
  setText: (text: string) => void;
  reset: () => void;
}

const initialState: State = {
  isOpen: false,
  text: 'Say goodbye to limitations! Upgrade to unlock unrestricted access to all areas of the platform, ensuring you never miss out on valuable resources, insights, or services.',
};

export const usePremiumStore = create<State & Action>()(set => ({
  ...initialState,
  onOpen: () => set(() => ({ isOpen: true })),
  onClose: () => set(() => ({ isOpen: false })),
  setText: text => set(() => ({ text })),
  reset: () => {
    set(initialState);
  },
}));
