import { useDisclosure } from '@chakra-ui/hooks';
import { useQueryClient } from '@tanstack/react-query';
import useCancelSubscription from 'api/billing/delete-cancel-plan';
import useGetLicense from 'api/billing/get-license';
import { routes } from 'api/routes';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import { useForm } from 'react-hook-form';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import CancelMessageModal from './CancelMessageModal';

function CancelPlanModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const clientId = license.data?.InstanceClientIdentifer;
  const cancelPlan = useCancelSubscription();
  const toast = useCustomToast();
  const cancelPlanMessageModalActions = useDisclosure();
  const queryClient = useQueryClient();

  const { handleSubmit } = useForm();

  const handleClose = () => {
    onClose();
  };

  const onSubmit = () => {
    const data = {
      clientId,
    };

    cancelPlan.mutate(data, {
      onSuccess: () => {
        handleClose();
        cancelPlanMessageModalActions.onOpen();
        queryClient.invalidateQueries([`${routes['client']}/subscription`]);
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  return (
    <>
      <CancelMessageModal
        isOpen={cancelPlanMessageModalActions.isOpen}
        onClose={cancelPlanMessageModalActions.onClose}
      />

      <CustomModal isOpen={isOpen} onClose={handleClose}>
        <div className="rounded-t-[20px] border border-white bg-[#363A42] px-4 py-2 pt-5">
          <h1 className="mb-3 text-2xl font-bold">
            Are You Sure You Want to <br /> Cancel?
          </h1>
        </div>

        <form
          className="rounded-b-[20px] border border-white bg-[#26292F] px-10 py-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <ul className="flex list-disc flex-col gap-2 text-base font-medium leading-[28px]">
            <li>
              Premium Features: Say goodbye to exclusive features that enhance
              your experience with us.
            </li>
            <li>
              Priority Support: As a subscriber, you enjoy faster and top-notch
              support from our team.
            </li>
            <li>
              Future Upgrades: We're continuously working on exciting upgrades,
              and as a subscriber, you get early access.
            </li>
            <li>
              Engaging Community: Join our vibrant community to connect with
              like-minded individuals and share insights.
            </li>
            <li>
              Reliable Service: Trust in our service's stability and reliability
              that subscribers appreciate.
            </li>
          </ul>

          <div className="mt-6 flex gap-4">
            <button
              className="linear flex w-full justify-center rounded-xl border border-[#EE5D50] bg-[#332423] py-3 text-base font-medium text-[#EE5D50]  transition duration-200 hover:text-[#EE5D50]/80 disabled:text-[#EE5D50]/40"
              type="submit"
              disabled={cancelPlan.isLoading}
            >
              No, I still want to cancel
              {cancelPlan.isLoading && (
                <LoaderSpinner
                  strokeColor={'#EE5D50'}
                  parentClass="relative left-1"
                  width="18"
                />
              )}
            </button>
            <button
              className="linear flex w-full justify-center rounded-xl border border-[white] bg-[#26292F] py-3 text-base font-medium text-white transition duration-200 "
              type="button"
              onClick={handleClose}
            >
              Yes, keep my subscription
            </button>
          </div>
        </form>
      </CustomModal>
    </>
  );
}

export default CancelPlanModal;
