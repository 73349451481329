import { useStore } from '@nanostores/react';
import { allTemplateDetails } from 'templates';
import {
  $currentTemplate,
  $templateConfigAllStates,
  $templateConfigValues,
} from 'templates/store';
import { DEFAULT_TEMPLATE_STATE } from 'templates/utils';

export const useGetFilteredTemplateConfigStates = () => {
  const templateConfigAllStates = useStore($templateConfigAllStates);
  const templateConfigValues = useStore($templateConfigValues);
  const currentTemplate = useStore($currentTemplate);
  const currentTemplateStateToSettingsMapping =
    allTemplateDetails?.[currentTemplate]?.['stateToSettingsMapping'];

  const filteredTemplateConfigAllStates = templateConfigAllStates?.filter(
    state => {
      if (state.key === DEFAULT_TEMPLATE_STATE) return true; // all templates start with default
      const keyToCheck = currentTemplateStateToSettingsMapping?.[state.key];
      return templateConfigValues[keyToCheck];
    },
  );

  return filteredTemplateConfigAllStates;
};
