import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { GetClientApps } from './types';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import React from 'react';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetClientApps[]> => {
  const [, clientId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['clients']}/${clientId}/apps`,
  );
  return data;
};

const useGetClientApps = (clientId: number) => {
  const { setAppId, appId } = useAuthStore(state => state);

  const query = useQuery({
    queryKey: [`${routes['clients']}/apps`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
    staleTime: Infinity,
  });

  React.useEffect(() => {
    if (query.data && !appId) {
      setAppId(query.data?.[0]?.id);
    }
  }, [query.data, setAppId, appId]);

  return query;
};

export default useGetClientApps;
