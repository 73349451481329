import { logChipColors } from 'variables/chipColors';

export enum WorkflowRunState {
  RUNNING = 'running',
  SUCCESS = 'success',
  FAILED = 'failed',
  BATCHED = 'batched',
  CANCELLED = 'cancelled',
}

export enum WorkflowRunStepState {
  SCHEDULED = 'scheduled',
  QUEUED = 'queued',
  RUNNING = 'running',
  SUCCESS = 'success',
  FAILED = 'failed',
  SKIPPED = 'skipped',
  BATCHED = 'batched',
}

export const workflowRunChipBg = {
  batched: logChipColors.yellow,
  queued: logChipColors.yellow,
  failed: logChipColors.red,
  success: logChipColors.green,
  running: logChipColors.blue,
  scheduled: logChipColors.violet,
  skipped: logChipColors.gray,
  cancelled: logChipColors.gray,
};

export const workflowRunChipColor = {
  batched: logChipColors.light_yellow,
  queued: logChipColors.light_yellow,
  failed: logChipColors.light_red,
  success: logChipColors.light_green,
  running: logChipColors.light_blue,
  scheduled: logChipColors.light_violet,
  skipped: logChipColors.light_gray,
  cancelled: logChipColors.light_gray,
};
