import { useDisclosure } from '@chakra-ui/hooks';
import {
  GetTopicUsers,
  TopicSubscriber,
} from 'api/topics/get-topic-users/types';
import CopyCell from 'components/copy/CopyCell';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/shadcn/hover-card';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import TableMenu from 'components/table/table-menu';
import React from 'react';
import { channelsLogoBlackAndWhite } from 'variables/channelsLogo';
import DeleteTopicsUserModal from './DeleteUserModal';

function TopicsUsersTable(props: {
  tableData: any;
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  setSelectedTopicUser: React.Dispatch<React.SetStateAction<TopicSubscriber>>;
  selectedTopicUser: TopicSubscriber;
  loading: boolean;
}) {
  const { tableData, editOpen: onOpen, globalFilter, loading } = props;
  const deleteTemplateModalActions = useDisclosure();

  type TopicsAction = 'edit_topic' | 'delete_topic';

  const handleMenuClick = (
    selected: TopicSubscriber,
    actionType: TopicsAction,
  ) => {
    props.setSelectedTopicUser(selected);

    if (actionType === 'delete_topic') {
      return deleteTemplateModalActions.onOpen();
    }

    onOpen();
  };

  const columnConfig: ColumnConfig<GetTopicUsers['subscribers'][0]> = [
    {
      accessorKey: 'user.identifier',
      header: () => (
        <p className="text-base font-semibold text-gray-600 dark:text-white">
          User Identifier
        </p>
      ),
      cellFunction: info => <CopyCell value={info.getValue()} />,
    },
    {
      accessorKey: 'subscribedChannels',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Channels
        </p>
      ),
      cellFunction: info => {
        const channels =
          info.getValue() as GetTopicUsers['subscribers'][0]['subscribedChannels'];

        return (
          <div className="flex gap-1">
            {channels?.map(item => (
              <HoverCard key={item.channel}>
                <HoverCardTrigger>
                  <img
                    width={25}
                    src={(channelsLogoBlackAndWhite as any)?.[item.channel]}
                    alt=""
                  />
                </HoverCardTrigger>

                <HoverCardContent className="!w-auto p-2 text-sm">
                  {item.channel}
                </HoverCardContent>
              </HoverCard>
            ))}
          </div>
        );
      },
    },
    {
      accessorKey: 'user',
      header: () => (
        <p className="text-sm font-semibold text-gray-600 dark:text-white">
          Action
        </p>
      ),
      cellFunction: info => (
        <TableMenu
          config={[
            {
              text: 'Edit',
              action: () => handleMenuClick(info.row.original, 'edit_topic'),
            },
            {
              text: 'Delete',
              action: () => handleMenuClick(info.row.original, 'delete_topic'),
            },
          ]}
        />
      ),
    },
  ];

  const columns = columnGenerator<GetTopicUsers['subscribers'][0]>({
    columnConfig,
  });

  return (
    <>
      <DeleteTopicsUserModal
        isOpen={deleteTemplateModalActions.isOpen}
        onClose={deleteTemplateModalActions.onClose}
        selectedTopicUser={props.selectedTopicUser}
      />

      {/* table */}
      <CustomTable
        loading={loading}
        columns={columns}
        emptyTableMessage="You haven't attached any users to this topic"
        extraTdClass={'hover:cursor-pointer'}
        tableData={tableData}
        paginationType="table"
        globalFilter={globalFilter}
      />
    </>
  );
}

export default TopicsUsersTable;
