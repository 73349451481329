import { useMutation } from '@tanstack/react-query';
import { axiosLicensingInstance } from 'api/axios/licensing';
import { routes } from 'api/routes';

type PostDefaultPaymentMethod = {
  clientId: string;
  paymentMethodId: string;
};

const post = (values: PostDefaultPaymentMethod) => {
  const { clientId, paymentMethodId } = values;

  return axiosLicensingInstance.post(
    `${routes['client']}/${clientId}/paymentMethod/${paymentMethodId}/default`,
  );
};

const usePostDefaultPaymentMethod = () => {
  return useMutation({
    mutationFn: post,
  });
};

export default usePostDefaultPaymentMethod;
