import { Edge, Node, NodeProps } from 'reactflow';
import PlaceholderEdge from './components/edges/PlaceHolderEdge';
import WorkflowEdge from './components/edges/WorkflowEdge';
import BaseNode from './components/nodes/BaseNode';
import LabelNode from './components/nodes/LabelNode';
import { WorkflowIdKeys, WorkflowJson } from './types';
import EndNode from './components/nodes/EndNode';
import EmptyNode from './components/nodes/EmptyNode';
import { WORKFLOW_NEW_EMAIL_EDITOR_LAUNCH_VERSION } from 'config';

export const proOptions = {
  hideAttribution: true,
};

export const edgeTypes = {
  workflow: WorkflowEdge,
  placeholder: PlaceholderEdge,
};

export const workflowErrorTypes = {
  NOT_CONFIGURED: 'not_configured',
  CHANNEL_DISABLED: 'channel_disabled',
} as const;

export type WorkflowErrorTypesValues =
  (typeof workflowErrorTypes)[keyof typeof workflowErrorTypes];

export const labelsMapping: Record<WorkflowJson['steps'][0]['type'], string> = {
  delay: 'Delay',
  start: 'Trigger',
  end: 'End',
  branch: 'Branch',
  batch: 'Batch',
  waitForInput: 'Wait For Input',
  updatePreference: 'Update Preference',
  channel: 'Channel',
  triggerWorkflow: 'Trigger Workflow',
  fetch: 'API Request',
  defineVariable: 'Define Variable',
  editVariable: 'Edit Variable',
  empty: '',
  // label: "Test",
  // base: "Base",
};

export const nodeTypes = {
  label: LabelNode,
  empty: EmptyNode,
  start: (values: NodeProps) => BaseNode(values),
  end: (values: NodeProps) => EndNode(values),
  base: (values: NodeProps) => BaseNode(values),
  branch: (values: NodeProps) => BaseNode(values),
  delay: (values: NodeProps) => BaseNode(values),
  batch: (values: NodeProps) => BaseNode(values),
  channel: (values: NodeProps) => BaseNode(values),
  waitForInput: (values: NodeProps) => BaseNode(values),
  updatePreference: (values: NodeProps) => BaseNode(values),
  triggerWorkflow: (values: NodeProps) => BaseNode(values),
  fetch: (values: NodeProps) => BaseNode(values),
  defineVariable: (values: NodeProps) => BaseNode(values),
  editVariable: (values: NodeProps) => BaseNode(values),
};

export const INDEX_NOT_FOUND = -1;

export const WORKFLOW_INITIAL_VERSION = '1.0.0';

export const WORKFLOW_PLACEHOLDER_POSIITON = {
  x: 0,
  y: 0,
};

export const WORKFLOW_DEFAULT_NODES_IDS = {
  start: 'start_1',
  end: 'end_1',
};

export const WORKFLOW_DEFAULT_NODES: Node[] = [
  {
    id: WORKFLOW_DEFAULT_NODES_IDS.start,
    type: 'start',
    data: { label: 'Trigger', hideTopHandle: true },
    position: WORKFLOW_PLACEHOLDER_POSIITON,
  },
  {
    id: WORKFLOW_DEFAULT_NODES_IDS.end,
    type: 'end',
    data: { label: 'End', hideBottomHandle: true },
    position: WORKFLOW_PLACEHOLDER_POSIITON,
  },
];

export const WORKFLOW_DEFAULT_NODES_JSON: WorkflowJson['steps'] = [
  {
    ref: WORKFLOW_DEFAULT_NODES_IDS.start,
    type: 'start',
  },
  {
    ref: WORKFLOW_DEFAULT_NODES_IDS.end,
    type: 'end',
  },
];

export const DEFAULT_WORKFLOW_JSON: WorkflowJson = {
  name: '',
  steps: [],
  errors: [],
  _version: WORKFLOW_NEW_EMAIL_EDITOR_LAUNCH_VERSION,
};

export const WORKFLOW_DEFAULT_ID_KEY_MAP: Record<WorkflowIdKeys, number> = {
  // functions
  start: 0,
  end: 0,
  delay: 0,
  branch: 0,
  subBranch: 0,
  batch: 0,
  waitForInput: 0,
  updatePreference: 0,
  triggerWorkflow: 0,
  fetch: 0,
  empty: 0,
  defineVariable: 0,
  editVariable: 0,

  // channels
  channel: 0,
  inApp: 0,
  webPush: 0,
  mobilePush: 0,
  sms: 0,
  email: 0,
  chat: 0,
  slack: 0,
  discord: 0,
  webhook: 0,
  whatsapp: 0,
};

export const WORKFLOW_DEFAULT_EDGES: Edge[] = [
  {
    id: `e${WORKFLOW_DEFAULT_NODES_IDS.start}-${WORKFLOW_DEFAULT_NODES_IDS.end}`,
    source: WORKFLOW_DEFAULT_NODES_IDS.start,
    target: WORKFLOW_DEFAULT_NODES_IDS.end,
    type: 'workflow',
  },
];

export const workflowDocLinks = {
  delay:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/delay-function',
  batch:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/batch-function',
  branch:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/branch-function',
  update_preference:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/update-pref-function',
  trigger_workflow:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/trigger-workflow-function',
  wait_for_input:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/wait-for-input-function',
  fetch:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/fetch-function',
  channels:
    'https://docs.engagespot.co/docs/features/channels-and-providers/understanding',
  condition_builder:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/step-conditions',
  defineVariable:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/define-variable-function',
  defineVariable_condition:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/define-variable-function#adding-define-variable-step',
  editVariable:
    'https://docs.engagespot.co/docs/features/workflows/designing-workflow/edit-variable-function',
};
