import { useQueryClient } from '@tanstack/react-query';
import useDeletePaymentMethod from 'api/billing/delete-payment-method';
import useGetLicense from 'api/billing/get-license';
import { routes } from 'api/routes';
import { CustomDeleteModal } from 'components/modal';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';

function DeleteCreditCardModal({
  isOpen,
  onClose,
  paymentMethodId,
}: {
  onClose: () => void;
  isOpen: boolean;
  paymentMethodId: string;
}) {
  const deletePaymentMethod = useDeletePaymentMethod();
  const toast = useCustomToast();
  const { clientId } = useAuthStore(state => state);
  const license = useGetLicense(clientId);
  const queryClient = useQueryClient();

  const handleSubmit = () => {
    const data = {
      clientId: license.data?.InstanceClientIdentifer,
      paymentMethodId,
    };

    deletePaymentMethod.mutate(data, {
      onSuccess: () => {
        toast.success('payment method removed successfully');
        queryClient.invalidateQueries([`${routes['clients']}/paymentMethods`]);
        onClose();
      },
      onError: (error: any) => {
        toast.showError(error);
      },
    });
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deletePaymentMethod.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove Payment Method"
      text={'Are you sure to remove this payment method?'}
    />
  );
}

export default DeleteCreditCardModal;
