import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { GetTopics } from './types';
import { useAuthStore } from 'store/authStore';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetTopics> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(`${routes['apps']}/${appId}/topics`);
  return data;
};

const useGetTopics = () => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/topics`, appId],
    queryFn: get,
  });
};

export default useGetTopics;
