import { useDeleteLayout } from 'api/layouts';
import { CustomDeleteModal } from 'components/modal';
import { useLayoutStore } from 'store/layoutStore';
import useCustomToast from 'utils/use-toast';

function DeleteWorkflowModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { layout } = useLayoutStore(state => state);
  const deleteLayout = useDeleteLayout();
  const toast = useCustomToast();

  const handleSubmit = () => {
    deleteLayout.mutate(
      {
        layoutId: layout.id,
      },
      {
        onSuccess: () => {
          toast.success('layout deleted successfully');
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteLayout.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove Layout"
      text={'Are you sure to remove this layout?'}
    />
  );
}

export default DeleteWorkflowModal;
