import { CustomModal } from 'components/modal';
import { buttonVariants } from 'components/shadcn/button';
import { cn } from 'utils/class-merge';
import { saveAsFile } from 'utils/functions';

const SiginKeyModal = ({
  onClose,
  publicPrivateKey,
  isOpen,
}: {
  isOpen: boolean;
  onClose: () => void;
  publicPrivateKey: {
    publicKey: string;
    privateKey: string;
  };
}) => {
  return (
    <CustomModal
      modalCardClassName="!min-w-[300px] !max-w-[475px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <div className="mb-0 w-full px-0">
        <h1 className="mb-2 text-center text-2xl font-semibold">
          Download your signing key
        </h1>
        <p className="mb-4 text-center text-xs font-semibold text-[#BFBFBF]">
          You should use your private signing key to sign your user tokens.{' '}
          <span className="text-brand-500">
            This is the last time you will ever be able to see or download your
            private key.
          </span>
        </p>
        <p className="mb-0 text-center font-medium">
          Keep it in a safe place, such as environment variable and never commit
          the key to a source code repository.
        </p>

        {/* private key preview */}
        {/* <div className="mb-4">
                <h3 className="mb-2">Private Key</h3>
                <div className="h-24 overflow-scroll rounded-lg bg-gray-850  p-4 text-white">
                  <p className="text-clip break-words text-sm font-semibold">
                    {publicPrivateKey.privateKey}
                  </p>
                </div>
              </div> */}

        {/* pubic key preview */}
        {/* <div className="mb-4">
                <h3 className="mb-2">Public Key</h3>
                <div className="h-24 overflow-scroll rounded-lg bg-gray-850  p-4 text-white">
                  <p className="text-clip break-words text-sm font-semibold">
                    {publicPrivateKey.publicKey}
                  </p>
                </div>
              </div> */}
      </div>

      <div>
        <div className="mt-5 flex gap-4 items-center">
          <button
            // className="linear flex w-full justify-center rounded-xl border border-gray-850 bg-night-100 py-3 text-base font-medium text-white transition duration-200  dark:text-white "
            className={cn(
              buttonVariants({
                size: 'lg',
              }),
              'w-full py-3 font-semibold bg-night-100 text-white hover:bg-night-200 border border-input',
            )}
            type="button"
            onClick={() =>
              saveAsFile({
                fileContent: publicPrivateKey.publicKey,
                fileName: 'engagespot_rsa_public_key.txt',
              })
            }
          >
            Download Public Key
          </button>
          <button
            // className="linear flex w-full justify-center rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
            className={cn(
              buttonVariants({
                size: 'lg',
              }),
              'w-full py-3 font-semibold',
            )}
            onClick={() =>
              saveAsFile({
                fileContent: publicPrivateKey.privateKey,
                fileName: 'engagespot_rsa_private_key.txt',
              })
            }
          >
            Download Private Key
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default SiginKeyModal;
