import { yupResolver } from '@hookform/resolvers/yup';
import { Channels } from 'api/channels/get-channels/types';
import ErrorBox from 'components/error/ErrorBox';
import SelectField from 'components/fields/SelectField';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useWorkflowStore from 'store/workflowStore';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import {
  findSelectedNodeFromJson,
  findStepsBeforeSelectedNodeFromJson,
  handleJsonDiffCheck,
} from '../../functions';
import {
  useFormValidateOnLoad,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { WorkflowFormHeader } from './WorkflowFormHeader';

type FormWorkflowWaitForInput = {
  stepRef?: string;
  triggerCondition?: string;
};

const schema = yup.object({
  stepRef: yup
    .string()
    .required('Selecting a previous channel step is required'),
});

const WorkflowWaitForInputForm = () => {
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );

  const channelsBeforeSelectedNodeFromJson =
    findStepsBeforeSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId: selectedNode?.id,
      selectOnlyChannels: true,
    });

  const channelOptions = channelsBeforeSelectedNodeFromJson
    ?.filter(
      channel =>
        channel.channelKey === 'sms' || channel.channelKey === 'whatsapp',
    )
    ?.map(channel => ({
      label: channel.ref,
      value: channel.ref,
    }));

  const formMethods = useForm<FormWorkflowWaitForInput>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const {
    reset,
    getValues,
    control,
    trigger,
    formState: { errors },
  } = formMethods;

  const handleChange = () => {
    const { stepRef, triggerCondition } = getValues();
    const selectedNodeId = selectedNode?.id;
    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      const selectedChannelKey = stepRef.split('_')[0];
      selected.channelKey = selectedChannelKey as Channels;
      selected.triggerCondition = triggerCondition;
      selected.config = {
        ...selected.config,
        stepRef: stepRef,
      };
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode?.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });
    const selectedConfig = selectedNodeJson?.config;

    reset({
      ...selectedConfig,
      triggerCondition: selectedNodeJson.triggerCondition,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useFormValidateOnLoad({
    dataLoaded,
    trigger,
  });

  useWatchWorkflowFormErrors({
    control,
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  return (
    <div>
      <WorkflowFormHeader heading="Wait for input" docKey="wait_for_input" />

      <div className="text-[#ABB0B8] font-medium mb-3">
        Pause until an input is received
      </div>

      <FormProvider {...formMethods}>
        <form className="mt-6 flex flex-col gap-6">
          <div>
            <SelectField<FormWorkflowWaitForInput>
              variant="styled"
              control={control}
              extra="w-full"
              label={'Choose a previous sms or whatsapp step'}
              placeholder={'Choose step'}
              extraInputClass=""
              name={'stepRef'}
              styleVariant="workflow"
              extraItemClass=""
              options={channelOptions ?? []}
            />
            {errors.stepRef && <ErrorBox error={errors.stepRef} />}
          </div>

          <WorkflowConditionForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default WorkflowWaitForInputForm;
