import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetUserAttributes } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetUserAttributes[]> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/profile-attributes`,
  );

  return data;
};

const useGetUserAttributes = () => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/user-attributes`, appId],
    queryFn: get,
  });
};

export default useGetUserAttributes;
