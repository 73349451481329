import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PostRsaKey = {
  rsa_public_key: File | Blob;
  appId: number;
};

const post = async (values: PostRsaKey) => {
  const { appId, rsa_public_key } = values;

  const formData = new FormData();
  formData.append('rsa_public_key', rsa_public_key);

  return axiosInstance.post(`${routes['apps']}/${appId}/rsa-key`, formData);
};

const usePostRsaKey = () => {
  return useMutation({
    mutationFn: (values: PostRsaKey) => post(values),
  });
};

export default usePostRsaKey;
