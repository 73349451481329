import { useQueryClient } from '@tanstack/react-query';
import useDeleteChannelRoutes from 'api/channel-routes/delete-channel-routes';
import { routes } from 'api/routes';
import { CustomDeleteModal } from 'components/modal';
import { useParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import { useChannelRouteStore } from 'store/channelRouteStore';
import useCustomToast from 'utils/use-toast';

function DeleteChannelRouteModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { channelRoute } = useChannelRouteStore(state => state);
  const deleteChannelRoutes = useDeleteChannelRoutes();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { appId } = useAuthStore(state => state);
  const params = useParams();
  const channel = params.channel;
  const { setChannelRoute } = useChannelRouteStore(state => state);

  const handleSubmit = () => {
    deleteChannelRoutes.mutate(
      {
        routeId: channelRoute.id,
      },
      {
        onSuccess: () => {
          toast.success('channel route removed successfully');
          queryClient.invalidateQueries([
            `${routes['routes']}`,
            appId,
            channel,
          ]);
          setChannelRoute(null);
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteChannelRoutes.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove Route"
      text={'Are you sure to remove this route?'}
    />
  );
}

export default DeleteChannelRouteModal;
