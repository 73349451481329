import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PostAddApps = {
  templateContentId: number;
  state: string;
  stateSpec: any;
  templateId: string;
};

const post = async (values: PostAddApps) => {
  const { templateId, ...data } = values;

  return axiosInstance.post(
    `${routes['templates']}/${templateId}/${routes['content-state']}`,
    data,
  );
};

const usePostTemplateState = () => {
  return useMutation({
    mutationFn: post,
  });
};

export default usePostTemplateState;
