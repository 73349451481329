import { Channels } from 'api/channels/get-channels/types';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import InAppPreview from './preview/InAppPreview';
import MobilePushPreview from './preview/MobilePushPreview';
import SmsPreview from './preview/SmsPreview';
import WebPushPreview from './preview/WebPushPreview';
import WhatsAppPreview from './preview/WhatsAppPreview';

const TemplatePreview = ({
  form,
}: {
  form: UseFormReturn<FieldValues, any>;
}) => {
  const params = useParams();
  const channel = params.channel as Channels;

  type ChannelsPreview = {
    [key in Channels]: JSX.Element | null;
  };

  const channelPreviewMapping: ChannelsPreview = {
    inApp: <InAppPreview form={form} />,
    chat: null,
    email: null,
    mobilePush: <MobilePushPreview control={form.control} />,
    slack: null,
    sms: <SmsPreview control={form.control} />,
    webPush: <WebPushPreview control={form.control} />,
    whatsapp: <WhatsAppPreview control={form.control} />,
    discord: null,
    webhook: null,
  };

  return <>{channelPreviewMapping?.[channel] ?? null}</>;
};

export default TemplatePreview;
