import Popular from '@assets/img/billing/popular.png';
import RightArrow from '@assets/svg/billing/right-arrow.svg';
import WhiteTick from '@assets/svg/billing/tick.svg';
import useGetPlans from 'api/billing/get-plans';
import { Plans } from 'api/billing/get-plans/types';
import { useBillingStore } from 'store/billingStore';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import { isEnterpriseEdition } from 'utils/get-editions';

const PricingCards = ({
  featureList,
  highlighted = false,
  handleCloseModal,
  plan,
  tagline,
}: {
  featureList: string[];
  highlighted?: boolean;
  handleCloseModal: () => void;
  plan: Plans;
  tagline: string;
}) => {
  // For notification based plans, 1 Unit = 1000 notifications.
  // For MAU based plans, 1 Unit = 10 monthly active users.
  // monthlyUnitsIncluded - How many units included in this plan
  // overagePricePerUnit - What will be the overage charge for a single unit
  const notificationUnit = {
    notification: 1000,
    mau: 10,
  };

  const monthlyUnitsIncluded =
    Number(plan.prices?.[0]?.monthly_units_included) *
    notificationUnit?.['notification'];
  const abbreviatedMonthlyUnitsIncluded =
    getAbbreviateNumber(monthlyUnitsIncluded);
  const overagePricePerUnit = plan.prices?.[0]?.overage_price_per_unit;
  const { setPlan, setDiscountModalState } = useBillingStore(state => state);

  const upgradePlan = (plan: Plans) => {
    setPlan(plan);
    setDiscountModalState(true);
    handleCloseModal();
  };

  return (
    <div
      className={`relative mt-5 flex  flex-col rounded-3xl border ${
        highlighted ? 'border-[#ff75bf]' : 'border-[#242424]'
      } bg-[#161616] px-8 pb-12 pt-14 xl:px-16`}
    >
      {/* absolute */}
      {highlighted && (
        <div className="absolute right-5 top-5 flex items-center gap-3 rounded-full bg-brand-600/20 px-4 py-1 font-medium text-[#c51258]">
          <img width={14} src={Popular} alt="" />
          Popular
        </div>
      )}

      <div className="mb-8 flex flex-col">
        <h5 className="text-[1.35rem] font-normal  text-white/70">
          {plan.name}
        </h5>
        <p className="text-[2.8rem] font-medium">
          ${plan.prices?.[0]?.price}{' '}
          <span className="relative right-2 text-base text-[#b6b0a6]">
            /month
          </span>
        </p>
        <p className="text-lg text-[#e29e37]">{tagline}</p>
      </div>

      <div className="mb-8 text-white">
        <p className="mb-1">
          Included {abbreviatedMonthlyUnitsIncluded} notifications
        </p>
        <span>(${overagePricePerUnit} per additional 1K notifications)</span>
      </div>

      <div className="mb-8 h-[1px] bg-[#242424]"></div>

      <div className="mb-8 flex flex-col gap-1">
        {featureList?.map(feature => (
          <div className="flex gap-2">
            <img width={18} src={WhiteTick} alt="" />
            <div>{feature}</div>
          </div>
        ))}
      </div>

      <button
        onClick={() => upgradePlan(plan)}
        className={`flex items-center justify-center gap-1 rounded-full ${
          highlighted
            ? 'group relative overflow-hidden  text-white  transition-colors  duration-200 hover:bg-brand-400 disabled:bg-brand-400/50 disabled:text-white/50'
            : 'bg-white text-black hover:bg-white/90 disabled:bg-white/70 disabled:text-black/50'
        }  px-7 py-4 `}
      >
        {/* shooting star animation */}
        {highlighted && (
          <>
            <span>
              <span className="spark mask-gradient absolute inset-0 h-[100%] w-[100%] animate-flip overflow-hidden rounded-full [mask:linear-gradient(white,_transparent_50%)] before:absolute before:aspect-square before:w-[200%] before:rotate-[-90deg] before:animate-rotate before:bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
            </span>
            <span className="backdrop group-hover:bg-slate-800 absolute inset-[2px] rounded-full bg-brand-850 transition-colors duration-200" />
          </>
        )}

        <span className="z-10">Upgrade</span>
        {!highlighted && <img width={20} src={RightArrow} alt="" />}
      </button>
    </div>
  );
};

const PricingTable = (props: { handleCloseModal: () => void }) => {
  const enterpriseEdition = isEnterpriseEdition();
  const getPlans = useGetPlans(enterpriseEdition);

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {getPlans.data
        .filter(item => item.identifier !== 'free')
        .map(plan => (
          <PricingCards
            plan={plan}
            featureList={plan.features}
            highlighted={plan.identifier === 'growth'}
            tagline={plan.plan_tagline}
            {...props}
          />
        ))}
    </div>
  );
};

export default PricingTable;
