import { GetChannels } from 'api/channels/get-channels/types';
import Plus from 'assets/svg/integrations/plus.svg';
import RightArrow from 'assets/svg/integrations/right-arrow.svg';
import PlusBrand from 'assets/svg/integrations/plus-brand.svg';
import RightArrowBrand from 'assets/svg/integrations/right-arrow-brand.svg';
import Dash from 'components/dash';
import { useNavigate } from 'react-router-dom';
import { channelsLogoColor } from 'variables/channelsLogo';
import Chip, { chipBgColors, chipTextColors } from 'components/chip';

function ChannelsCard({ channel: channelData }: { channel: GetChannels }) {
  const navigate = useNavigate();
  const configured = channelData.configuredProviders.length > 0;
  const active = channelData.configuredProviders.some(
    provider => provider?.enabled === true,
  );

  const handleClick = () => {
    navigate(`/admin/provider/${channelData.channel}`);
  };

  return (
    <button
      onClick={handleClick}
      className="group rounded-[20px]  bg-night-100 px-5 py-5 border border-input"
    >
      <div className="flex items-center justify-between">
        {/* Upper */}
        <div className="mb-4 flex items-center gap-3">
          <img
            width={44}
            src={channelsLogoColor?.[channelData.channel]}
            alt=""
          />
          <p className="text-[22px] font-semibold capitalize">
            {channelData.channel}
          </p>
        </div>

        {/* Chip */}
        <div className="h-8">
          {configured ? (
            <Chip
              text={active ? 'Active' : 'Inactive'}
              className={`${chipBgColors['green']} ${
                active ? chipTextColors['green'] : chipTextColors['yellow']
              }`}
            />
          ) : null}
        </div>
      </div>

      {/* Dash */}
      <Dash />

      {/* bottom content */}
      <div className="flex items-center justify-between">
        {/* icons */}
        <div className="flex">
          {channelData.configuredProviders.slice(0, 10).map(item => (
            <div className="csm-stack h-8 w-8 rounded-full bg-white p-[6px]">
              <img src={item.icon} alt="" />
            </div>
          ))}
        </div>

        {/* text */}
        <div>
          {configured ? (
            <div className="flex items-center justify-between gap-3">
              <p className="text-sm font-medium group-hover:text-brand-500">
                View All Providers
              </p>
              <img
                className="block group-hover:hidden"
                src={RightArrow}
                alt=""
              />
              <img
                className="hidden group-hover:block"
                src={RightArrowBrand}
                alt=""
              />
            </div>
          ) : (
            <div className="flex  items-center justify-between gap-3">
              <img className="block group-hover:hidden" src={Plus} alt="" />
              <img
                className="hidden group-hover:block"
                src={PlusBrand}
                alt=""
              />
              <p className="text-sm font-medium group-hover:text-brand-500">
                Add Providers
              </p>
            </div>
          )}
        </div>
      </div>
    </button>
  );
}

export default ChannelsCard;
