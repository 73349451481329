import { creditCards } from 'views/admin/dashboards/billing/variables';
import CreditCardMenu from './CreditCardMenu';

function MasterCard({
  number,
  brand,
  paymentMethodId,
  isDefault,
}: {
  number: string;
  brand: string;
  paymentMethodId: string;
  isDefault: boolean;
}) {
  return (
    <div className="relative flex h-32 w-full flex-col justify-between rounded-xl bg-white bg-cover bg-no-repeat px-[20px] py-2 text-black">
      <div className="absolute right-4 top-4 flex gap-2">
        {/* default mark */}
        {isDefault && (
          <div className="rounded-lg bg-[#2D95CF33] px-3 py-1 text-xs font-medium capitalize text-[#2D95CF]">
            default
          </div>
        )}

        <CreditCardMenu
          isDefault={isDefault}
          paymentMethodId={paymentMethodId}
          vertical={true}
        />
      </div>

      <div className="flex items-center justify-between">
        {(creditCards as any)[brand] ? (
          <img src={(creditCards as any)[brand]} width={50} alt="" />
        ) : (
          <h2 className="relative top-2 text-xl font-bold tracking-wider">
            {brand}
          </h2>
        )}
      </div>

      <h3 className="text-lg font-medium tracking-widest">{number}</h3>
    </div>
  );
}

export default MasterCard;
