import Pen from 'assets/svg/profile/pen.svg';
import Delete from 'assets/svg/profile/delete.svg';
import CustomSetUp from 'components/actions/CustomSetUp';
import Card from 'components/card';
import { useDisclosure } from '@chakra-ui/hooks';
import AppModal from './AppsModal';
import DeleteAppModal from './DeleteAppsModal';
import { getFirstWordAndLetter } from 'utils/functions';
import React from 'react';
import { useAuthStore } from 'store/authStore';
import useGetClientApps from 'api/client/get-client-apps';
import { GetClientApps } from 'api/client/get-client-apps/types';
import { useIsOldVersion } from 'utils/use-old-version';

type ActionButtonType = {
  item: GetClientApps;
  showDelete: boolean;
  showEdit: boolean;
};

const Apps = () => {
  const appModalActions = useDisclosure();
  const deleteAppModalActions = useDisclosure();
  const { clientId } = useAuthStore(state => state);
  const getClientApps = useGetClientApps(clientId);
  const [selectedApp, setSelectedApp] = React.useState<GetClientApps>(null);
  const { isOldVersion } = useIsOldVersion();

  const ActionButtons = ({ item, showDelete, showEdit }: ActionButtonType) => {
    return (
      <>
        {showEdit && (
          <button
            onClick={() => {
              setSelectedApp(item);
              appModalActions.onOpen();
            }}
          >
            <img src={Pen} alt="" />
          </button>
        )}

        {/* On  Deleting Current App, selecting next app logic is in  useGetClientApps */}
        {showDelete && (
          <button
            onClick={() => {
              setSelectedApp(item);
              deleteAppModalActions.onOpen();
            }}
          >
            <img src={Delete} alt="" />
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <AppModal
        isOpen={appModalActions.isOpen}
        onClose={appModalActions.onClose}
        selected={selectedApp}
      />
      <DeleteAppModal
        isOpen={deleteAppModalActions.isOpen}
        onClose={deleteAppModalActions.onClose}
        selected={selectedApp}
      />

      <Card extra={'w-full  py-8'}>
        {/* Header */}
        <div className="mb-5 flex flex-col items-start justify-between gap-2 px-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {isOldVersion ? 'Apps' : 'Environments'}
          </h4>
        </div>

        {getClientApps.data?.map(item => {
          const name = item.name;
          const { firstLetter } = getFirstWordAndLetter(name);
          const showDelete = isOldVersion
            ? getClientApps.data?.length > 1
            : !item.isDefault || !item.isProduction;
          const showEdit = isOldVersion;

          return (
            <CustomSetUp<ActionButtonType>
              key={item.id}
              name={name}
              py="py-3"
              iconText={firstLetter}
              actionButtons={ActionButtons}
              actionProps={{ item, showDelete, showEdit }}
            />
          );
        })}
      </Card>
    </>
  );
};

export default Apps;
