import MagicHat from '@assets/img/3d/color/magic-hat.png';
import { TemplateBlock } from '@engagespot/template-blocks';
import { yupResolver } from '@hookform/resolvers/yup';
import CookingAnimation from 'animations/cooking.json';
import usePostAi from 'api/ai/post-ai';
import ErrorBox from 'components/error/ErrorBox';
import TextField from 'components/fields/TextField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import Lottie from 'lottie-react';
import React from 'react';
import { SubmitHandler, UseFormReturn, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTemplateStore } from 'store/templateStore';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import {
  getAiModalData,
  handleFormSetValues,
  handleGetInAppConfigValues,
  handleSelectInAppLayout,
} from '../functions';
import { TemplateEditorAvailabeChannels } from '../variables/inputs';

type FormAi = {
  prompt: string;
};

export const schema = yup.object({
  prompt: yup.string().required('prompt is required'),
});

function AiModal({
  isOpen,
  onClose,
  form,
}: {
  onClose: () => void;
  isOpen: boolean;
  form: UseFormReturn<any, any>;
}) {
  const params = useParams();
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const postAi = usePostAi();
  const toast = useCustomToast();
  const { tab } = useTemplateStore(state => state);
  const [showAnimation, setShowAnimation] = React.useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormAi>({
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    onClose();
  };

  const onSubmit: SubmitHandler<FormAi> = values => {
    setShowAnimation(true);

    postAi.mutate(
      {
        channel: channel,
        prompt: values.prompt,
      },
      {
        onSuccess: response => {
          const data = response.data;
          const { content } = data;

          const finalPromptData = getAiModalData({
            data,
            channel,
            tab,
          });

          if (channel === 'inApp' && tab !== 'batched_template') {
            const blocks: TemplateBlock[] = content.blocks;
            handleSelectInAppLayout(blocks);
            const data = handleGetInAppConfigValues(finalPromptData);
            handleFormSetValues(data, form);
          } else {
            handleFormSetValues(finalPromptData, form);
          }

          reset({
            prompt: '',
          });
          toast.success('success');
          handleClose();
          setShowAnimation(false);
        },
        onError: () => {
          setShowAnimation(false);
        },
      },
    );
  };

  return (
    <CustomModal
      modalContentClassName="!top-[45%]"
      modalCardClassName="!min-w-[485px] !max-w-[450px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      {!showAnimation && (
        <div className="flex gap-3">
          <div>
            <img className="relative bottom-2" src={MagicHat} alt="" />
          </div>

          <div>
            <h1 className="mb-3 text-2xl font-bold">Crafting Magic</h1>
            <p className="mb-6  text-sm text-[#BFBFBF]">
              AI-Powered Notification Generation
            </p>
          </div>
        </div>
      )}

      {!showAnimation && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField<FormAi>
            variant="modal"
            extra="mb-3"
            label={''}
            placeholder="Give a descriptive prompt. Eg: Create a friend request notification with 2 buttons accept and reject. Also include accept and reject states with a title for each of them"
            showIsRequiredAsterisk={true}
            register={register}
            name={'prompt'}
          />
          {errors.prompt && <ErrorBox error={errors.prompt} />}
          {postAi.isError && <ErrorBox error={postAi.error} />}

          <div className="mt-6 flex gap-4">
            <button
              className={
                'linear relative mx-auto flex w-min justify-center rounded-xl bg-brand-500 px-12 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700 '
              }
              type="submit"
              disabled={postAi.isLoading}
            >
              <span className="z-10">Generate</span>
              {postAi.isLoading && <LoaderSpinner />}
            </button>
          </div>
        </form>
      )}

      {showAnimation && (
        <div className="relative bottom-[10px]">
          <div className="flex max-h-[282px] overflow-hidden rounded-lg">
            <Lottie animationData={CookingAnimation} loop={true} />
          </div>

          <div>
            <div className="linear group relative flex items-center justify-center  gap-1 overflow-hidden rounded-xl  px-4 pb-2 text-center text-xl font-medium drop-shadow-lg transition duration-200  hover:cursor-pointer  disabled:cursor-auto disabled:opacity-60">
              <span className="gradient-text z-10 animate-gradient text-red-500/0">
                Generating your magic content
              </span>
            </div>
          </div>
        </div>
      )}
    </CustomModal>
  );
}

export default AiModal;
