import { EngageSpotChannels } from 'api/channels/get-channels/types';
import { SelectOptions } from 'components/fields/SelectField/types';

export const defaultConditionExpressions = [
  {
    condition_step_reference: undefined as undefined,
    condition_step_expression: undefined as undefined,
    condition_type: undefined as undefined,
    condition_property: '',
    condition_operator: undefined as undefined,
    condition_value: undefined as undefined,
    condition_message_status: undefined as undefined,
    condition_step_join_operator: '',
    showForm: false,
  },
];

export const conditionTypeOptions = [
  {
    label: 'Step',
    value: 'step',
  },
  {
    label: 'Recipient',
    value: 'recipient',
  },
  {
    label: 'Data',
    value: 'data',
  },
];

export const stateOptions = [
  {
    label: 'scheduled',
    value: 'scheduled',
  },
  {
    label: 'queued',
    value: 'queued',
  },
  {
    label: 'running',
    value: 'running',
  },
  {
    label: 'success',
    value: 'success',
  },
  {
    label: 'failed',
    value: 'failed',
  },
  {
    label: 'skipped',
    value: 'skipped',
  },
  {
    label: 'batched',
    value: 'batched',
  },
];

export const deliveryStatusOptions = [
  { label: 'delivery_attempted', value: 'delivery_attempted' },
  { label: 'delivered', value: 'delivered' },
];

export const messageStatusOptions = [
  ...deliveryStatusOptions,
  { label: 'seen', value: 'seen' },
  { label: 'read', value: 'read' },
  { label: 'interacted', value: 'interacted' },
  { label: 'archived', value: 'archived' },
  { label: 'not_sent', value: 'not_sent' },
];

export const expressionStateOptions = [
  {
    label: 'state',
    value: 'state',
  },
];

export const expressionMessageOptions = [
  {
    label: 'message',
    value: 'message',
  },
];

export const expressionDataOptions = [
  {
    label: 'data',
    value: 'data',
  },
];

export const expressionStateDataOptions = [
  ...expressionStateOptions,
  ...expressionDataOptions,
];

export const expressionStateMessageOptions = [
  ...expressionStateOptions,
  ...expressionMessageOptions,
];

/**
 * This data is also used to split the conditions in regex,
 * as operators are always at the middle of each single expression
 *
 * IMPORTANT: Donot change the order of these keys,
 * as these values are used in the regex search
 * and  if `>` is before `>=`, only `>` will be considered and
 * '=' sign will be ignored, and the search will have unexpected outcomes
 */
export const operatorSymbols = {
  is_equal_to: '==',
  is_not_equal_to: '!=',
  lesser_than_or_equal_to: '<=',
  greater_than_or_equal_to: '>=',
  greater_than: '>',
  lesser_than: '<',
};

export const truthyOperatorOptions = [
  {
    label: 'is equal to',
    value: operatorSymbols.is_equal_to,
  },
  {
    label: 'is not equal to',
    value: operatorSymbols.is_not_equal_to,
  },
];

export const trueOrFalseOptions = [
  {
    label: 'true',
    value: 'true',
  },
  {
    label: 'false',
    value: 'false',
  },
];

export const operatorOptions = [
  {
    label: 'is equal to',
    value: operatorSymbols.is_equal_to,
  },
  {
    label: 'is not equal to',
    value: operatorSymbols.is_not_equal_to,
  },
  {
    label: 'greater than',
    value: operatorSymbols.greater_than,
  },
  {
    label: 'greater than or equal to',
    value: operatorSymbols.greater_than_or_equal_to,
  },
  {
    label: 'lesser than',
    value: operatorSymbols.lesser_than,
  },
  {
    label: 'lesser than or equal to',
    value: operatorSymbols.lesser_than_or_equal_to,
  },
];

export const operatorsDescription = {
  '==': 'equal to',
  '!=': 'not equal to',
  '>': 'greater than',
  '>=': 'greater than or equal to',
  '<': 'less than',
  '<=': 'less than or equal to',
};

export const truthyDescription = {
  '&&': 'AND',
  '||': 'OR',
};

// message status
export enum MessageDeliveryStatus {
  REPLIED = 'replied',
  DELIVERY_ATTEMPTED = 'delivery_attempted',
  DELIVERED = 'delivered',
  SENT = 'sent',
  NOT_SENT = 'not_sent',
  SEEN = 'seen',
  READ = 'read',
  INTERACTED = 'interacted',
}

export const messageDeliveryStatusOptions = {
  REPLIED: {
    label: MessageDeliveryStatus.REPLIED,
    value: MessageDeliveryStatus.REPLIED,
  },
  DELIVERY_ATTEMPTED: {
    label: MessageDeliveryStatus.DELIVERY_ATTEMPTED,
    value: MessageDeliveryStatus.DELIVERY_ATTEMPTED,
  },
  DELIVERED: {
    label: MessageDeliveryStatus.DELIVERED,
    value: MessageDeliveryStatus.DELIVERED,
  },
  SENT: {
    label: MessageDeliveryStatus.SENT,
    value: MessageDeliveryStatus.SENT,
  },
  NOT_SENT: {
    label: MessageDeliveryStatus.NOT_SENT,
    value: MessageDeliveryStatus.NOT_SENT,
  },
  SEEN: {
    label: MessageDeliveryStatus.SEEN,
    value: MessageDeliveryStatus.SEEN,
  },
  READ: {
    label: MessageDeliveryStatus.READ,
    value: MessageDeliveryStatus.READ,
  },
  INTERACTED: {
    label: MessageDeliveryStatus.INTERACTED,
    value: MessageDeliveryStatus.INTERACTED,
  },
};

export const messageStatusChannelMapping: Record<
  EngageSpotChannels,
  SelectOptions[]
> = {
  sms: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.DELIVERED,
  ],
  inApp: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.SEEN,
    messageDeliveryStatusOptions.DELIVERED,
    messageDeliveryStatusOptions.READ,
    messageDeliveryStatusOptions.INTERACTED,
  ],
  email: [
    messageDeliveryStatusOptions.DELIVERED,
    messageDeliveryStatusOptions.DELIVERY_ATTEMPTED,
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.SEEN,
    messageDeliveryStatusOptions.READ,
    messageDeliveryStatusOptions.INTERACTED,
  ],
  slack: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.DELIVERED,
  ],
  discord: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.DELIVERED,
  ],
  webhook: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.DELIVERED,
  ],
  mobilePush: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.DELIVERED,
  ],
  webPush: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.DELIVERED,
  ],
  whatsapp: [
    messageDeliveryStatusOptions.SENT,
    messageDeliveryStatusOptions.NOT_SENT,
    messageDeliveryStatusOptions.DELIVERY_ATTEMPTED,
    messageDeliveryStatusOptions.DELIVERED,
    messageDeliveryStatusOptions.READ,
    messageDeliveryStatusOptions.REPLIED,
  ],
};
