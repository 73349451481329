import { GetCategories } from 'api/categories/get-categories/types';
import TableMenu from 'components/table/table-menu';
import { useCategoryStore } from 'store/categoryStore';

type CategoryAction = 'edit_categories' | 'delete_categories';

function CardMenu(props: {
  editOpen: () => void;
  deleteOpen: () => void;
  selected: GetCategories;
}) {
  const { setCategory } = useCategoryStore(state => state);
  const { editOpen, selected, deleteOpen } = props;

  const handleMenuClick = (actionType: CategoryAction) => {
    setCategory(selected);

    if (actionType === 'edit_categories') {
      return editOpen();
    }

    if (actionType === 'delete_categories') {
      return deleteOpen();
    }
  };

  return (
    <>
      <TableMenu
        config={[
          {
            text: 'Edit',
            action: () => handleMenuClick('edit_categories'),
          },
          {
            text: 'Delete',
            action: () => handleMenuClick('delete_categories'),
          },
        ]}
      />
    </>
  );
}

export default CardMenu;
