import { phoneRegex } from 'utils/variables';
import * as yup from 'yup';

export const schema = yup.object({
  firstName: yup.string().required('name is required').max(50),
  password: yup.string().trim().required('password is required').max(25).min(5),
  dummy_phone: yup.string().test('valid_phone_number_check', function (value) {
    const dialCode = this.parent?.dummy_dialCode;

    if (value === '' || value === dialCode) {
      return true;
    }

    if (!phoneRegex.test(value)) {
      return this.createError({
        message: `invalid phone number`,
      });
    }

    return true;
  }),
  // agree_tos: yup
  //   .boolean()
  //   .oneOf([true], 'please agree to our terms and conditions'),
  marketing_optin: yup.boolean(),
  source: yup.string(),
  invitationCode: yup.string(),
});
