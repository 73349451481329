import { convertMillisecondsToTime, isInteger } from '../../functions';

export const DelaySubText = ({ delayFor }: { delayFor: string }) => {
  const { days, hours, mins, secs } = convertMillisecondsToTime(delayFor);
  const timeInIntervals = `For ${Boolean(days) ? `${days} days` : ''} ${Boolean(hours) ? `${hours} hours` : ''}
       ${Boolean(mins) ? `${mins} mins` : ''} ${Boolean(secs) ? `${secs} secs` : ''}`;
  const delayForIsInteger = isInteger(delayFor);

  return (
    <span className="text-xs text-[#ABB0B8]">
      {!delayForIsInteger
        ? `For ${delayFor?.length > 30 ? `${delayFor?.slice(0, 28)}...` : delayFor}`
        : delayFor === '0'
          ? 'for 0 seconds'
          : timeInIntervals}
    </span>
  );
};
