import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import { buttonVariants } from 'components/shadcn/button';
import { cn } from 'utils/class-merge';

function SigninKeyConfirmModal({
  isOpen,
  onClose,
  handleGenerate,
  loading,
}: {
  onClose: () => void;
  isOpen: boolean;
  handleGenerate: () => Promise<void>;
  loading: boolean;
}) {
  const handleSubmit = () => {
    handleGenerate();
  };

  return (
    <CustomModal
      modalCardClassName="!min-w-[350px] !max-w-[450px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="mx-auto mb-[20px] text-2xl font-bold text-red-700">
        Regenerate Signing Key
      </h1>
      <p className="mx-auto mb-[20px] text-center text-lg text-white font-bold">
        Are you sure? <br />
        <span className="text-white relative text-base top-2 font-medium">
          Warning: This will invalidate all your existing user tokens unless you
          have programmed to issue new tokens for your users.
        </span>
      </p>
      <div className="flex gap-2">
        <button
          className={cn(
            buttonVariants({
              size: 'lg',
            }),
            'bg-red-800 hover:bg-red-800/80 text-white w-full py-3 capitalize font-semibold',
          )}
          onClick={handleSubmit}
          disabled={loading}
        >
          regenerate
          {loading && <LoaderSpinner strokeColor="white" />}
        </button>
      </div>
    </CustomModal>
  );
}

export default SigninKeyConfirmModal;
