import { atom, onMount, task } from 'nanostores';
import {
  DEFAULT_TEMPLATE_ID,
  inAppTemplateIds,
  TemplateStates,
  allTemplateDetails,
} from 'templates';
import { componentBlockConfig } from './types';
import { DEFAULT_TEMPLATE_STATE } from './utils';

/**
 * Types
 */
type TemplateConfigStore = componentBlockConfig[typeof DEFAULT_TEMPLATE_STATE];

export type templateConfigState = 'default' | 'accept' | 'reject';

/**
 * Initial Values
 */
export const $currentTemplate = atom<inAppTemplateIds>(null);
export const $templateConfigValues = atom<any>();
export const $templateConfigCurrentState = atom<string>(DEFAULT_TEMPLATE_STATE);
export const $templateConfigAllStates = atom<TemplateStates[]>([]);
export const $templateConfig = atom<TemplateConfigStore>([]);
/**
 * Set functions
 */
// export function setTemplateConfigValues(values: templateConfigValues) {
export function setTemplateConfigValues(values: any) {
  $templateConfigValues.set(values);
}

/**
 * General functions
 */
const getConfigFromValues = () => {
  const values = allTemplateDetails?.[$currentTemplate.get()]?.[
    'blockConfig'
  ]?.($templateConfigValues.get());

  $templateConfig.set(values?.[$templateConfigCurrentState.get()]);
};

const getDefaultValues = () => {
  const values = allTemplateDetails?.[$currentTemplate.get()]?.defaultValues;
  $templateConfigValues.set(values);
};

const getStates = () => {
  const values = allTemplateDetails?.[$currentTemplate.get()]?.states;
  $templateConfigAllStates.set(values);
};

export const setToDefaultTemplate = () => {
  $currentTemplate.set(DEFAULT_TEMPLATE_ID);
};

export const templateDataReset = () => {
  $currentTemplate.set(null);
  $templateConfigValues.set({});
  $templateConfigCurrentState.set(DEFAULT_TEMPLATE_STATE);
  $templateConfigAllStates.set([]);
  $templateConfig.set([]);
};

const handleMount = () => {
  if ($currentTemplate.get() === null) {
    setToDefaultTemplate();
  }

  getStates();
  getDefaultValues();
  getConfigFromValues();
};

/**
 * Subscriptions
 */
$templateConfigValues.subscribe(() => {
  getConfigFromValues();
});

$templateConfigCurrentState.subscribe(() => {
  getConfigFromValues();
});

$currentTemplate.subscribe(() => {
  handleMount();
});

/**
 * Life Cycle Methods
 */
onMount($templateConfig, () => {
  task(async () => {
    handleMount();
  });
});
