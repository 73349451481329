import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
} from '@radix-ui/react-icons';
import * as React from 'react';

import { Button } from 'components/shadcn/button';

import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
  CommandSeparator,
} from 'components/shadcn/command';

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'components/shadcn/popover';

import useGetClient from 'api/client/get-client';
import { SelectOptions } from 'components/fields/SelectField/types';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import { useAuthStore } from 'store/authStore';
import { cn } from 'utils/class-merge';
import AddWorkspaceModal from './AddWorkspaceModal';
import { useDisclosure } from '@chakra-ui/hooks';
import Chip, { chipBgColors } from 'components/chip';
import { REGIONS } from 'variables/regions';

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface WorkSpaceSwitcherProps extends PopoverTriggerProps {
  className?: string;
}

export type RegionSelectOption = SelectOptions & { region: string };

const DataRegionChip = ({
  isUSRegion,
  className,
}: {
  isUSRegion?: boolean;
  className?: string;
}) => {
  return (
    <Chip
      text={isUSRegion ? 'US' : 'EU'}
      className={cn(
        `py-1 rounded-md text-xs font-semibold ml-3`,
        isUSRegion ? chipBgColors['green'] : chipBgColors['blue'],
        className,
      )}
    />
  );
};

export default function WorkSpaceSwitcher({
  className,
}: WorkSpaceSwitcherProps) {
  const [open, setOpen] = React.useState(false);
  const { setAppId, setClientId, clientId, setRegion } = useAuthStore(
    state => state,
  );
  const getClient = useGetClient();
  const [selectedWorkSpace, setSelectedWorkSpace] =
    React.useState<RegionSelectOption>();
  const addWorkspaceModalActions = useDisclosure();

  const teams = getClient?.data?.reduce((acc, curr) => {
    return [
      ...acc,
      {
        label: curr.client.name,
        value: String(curr.client.id),
        region: curr.client.region,
      },
    ];
  }, [] as RegionSelectOption[]);

  React.useEffect(() => {
    if (selectedWorkSpace) return;

    const currentWorkSpace = teams?.find(
      team => team.value === String(clientId),
    );

    if (currentWorkSpace) {
      setSelectedWorkSpace(currentWorkSpace);
    }
  }, [clientId, teams, selectedWorkSpace]);

  const handleSwitchWorkSpaces = (id: number, region?: string | null) => {
    /**
     * Reset the app id to 0, so that in layouts > admin > index.tsx, custom useEffect hooks will fireoff
     * and fetch all the apps data for the selected client
     */
    setAppId(0);
    setClientId(id);
    setRegion(region);
  };

  const handleAddWorkspace = () => {
    addWorkspaceModalActions.onOpen();
  };

  const isSelectedUSRegion =
    selectedWorkSpace?.region === REGIONS['US_WEST_2'] ||
    selectedWorkSpace?.region === null;

  return (
    <>
      <AddWorkspaceModal
        isOpen={addWorkspaceModalActions.isOpen}
        onClose={addWorkspaceModalActions.onClose}
        setSelectedWorkSpace={setSelectedWorkSpace}
        handleSwitchWorkSpaces={handleSwitchWorkSpaces}
      />

      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a workspace"
            className={cn(
              'w-[235px] text-white  justify-between hover:bg-muted/50 border border-[#3A3F47]',
              className,
            )}
          >
            <Avatar className="mr-2 h-6 w-6 grayscale">
              <AvatarImage
                // src={`https://avatar.vercel.sh/1.png`}
                src={''}
                alt={'avatar'}
                className="grayscale"
              />
              <AvatarFallback className="text-xs grayscale border border-white/20">
                {selectedWorkSpace?.label?.charAt(0).toUpperCase()}
              </AvatarFallback>
            </Avatar>

            <div className="ml-1 text-left text-white font-semibold flex flex-col justify-center items-center gap-0">
              <span>{selectedWorkSpace?.label}</span>
            </div>

            {selectedWorkSpace?.region && (
              <DataRegionChip isUSRegion={isSelectedUSRegion} />
            )}

            <div className="ml-auto flex gap-2 items-center">
              <CaretSortIcon className="h-4 w-4 shrink-0 opacity-50" />
            </div>
          </Button>
        </PopoverTrigger>

        <PopoverContent className="w-[235px] p-0">
          <Command>
            <CommandList>
              <CommandGroup className="text-lg" heading={'Workspace'}>
                <CommandItem value="-" className="hidden" />
                {teams?.map(team => {
                  const isUSRegion =
                    team.region === REGIONS['US_WEST_2'] ||
                    team.region === null;

                  return (
                    <CommandItem
                      key={team.value}
                      onSelect={() => {
                        const selectedClient = getClient.data?.find(
                          item => item.client.id === Number(team.value),
                        )?.client;

                        setSelectedWorkSpace(team);
                        setOpen(false);
                        handleSwitchWorkSpaces(
                          Number(team.value),
                          selectedClient.region,
                        );
                      }}
                      className={cn(
                        'text-sm',
                        selectedWorkSpace?.value === team.value &&
                          'bg-muted/50',
                      )}
                    >
                      <Avatar className="mr-2 h-5 w-5">
                        <AvatarImage
                          // src={`https://avatar.vercel.sh/1.png`}
                          src={''}
                          alt={team.label}
                          className="grayscale"
                        />
                        <AvatarFallback className="text-xs border border-white/20">
                          {team?.label?.charAt(0).toUpperCase()}
                        </AvatarFallback>
                      </Avatar>

                      <span>{team.label}</span>

                      <CheckIcon
                        className={cn(
                          'ml-2 h-4 w-4',
                          selectedWorkSpace?.value === team.value
                            ? 'opacity-100'
                            : 'opacity-0',
                        )}
                      />

                      <DataRegionChip
                        className="ml-auto"
                        isUSRegion={isUSRegion}
                      />
                    </CommandItem>
                  );
                })}
              </CommandGroup>
            </CommandList>

            <CommandSeparator />

            <CommandList>
              <CommandGroup>
                <CommandItem
                  className="cursor-pointer"
                  onSelect={() => {
                    setOpen(false);
                    handleAddWorkspace();
                  }}
                >
                  <PlusCircledIcon className="mr-2 h-5 w-5" />
                  Create Workspace
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </>
  );
}
