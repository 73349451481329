import Recieved from 'assets/svg/logs/recieved.svg';
import { engageSpotEventColorMapping, productLogos } from '../variables';

function OrderStatusCard() {
  return (
    <div className="mb-6 flex justify-between rounded-2xl px-3 py-2 hover:bg-[#353B47]">
      <div className="flex items-center gap-2">
        <div className="h-14 w-14  rounded-full bg-white p-3">
          <img src={productLogos['img1']} alt="" />
        </div>

        <div>
          <p
            style={{ color: engageSpotEventColorMapping['request_received'] }}
            className={`mb-2 flex  items-center justify-center rounded-lg bg-night-100 px-2 py-[6px]   text-sm font-medium capitalize `}
          >
            Request Received
          </p>

          <p className="relative left-1 text-xs text-[#A3AED0]">
            27 Jul 2022, 10:30
          </p>
        </div>
      </div>

      {/* status icon */}
      <div className="relative right-4 flex items-center">
        <img width={20} src={Recieved} alt="" />
      </div>
    </div>
  );
}

export default OrderStatusCard;
