import Apps from './components/Apps';

const DashboardSettings = () => {
  return (
    <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
      <div className="col-span-1 flex flex-col gap-5">
        <Apps />
      </div>
    </div>
  );
};

export default DashboardSettings;
