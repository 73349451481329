import { GetClientUsers } from 'api/client/get-client-users.ts/types';
import { create } from 'zustand';

export type TeamAction =
  | 'delete_user_account'
  | 'edit_user_account'
  | 'resend_invitations'
  | 'delete_invitations';

interface State {
  typeOfAction: TeamAction;
  clientUserId: number;
  invitationId: number;
  client: GetClientUsers;
}

interface Action {
  setTypeOfAction: (typeOfAction: TeamAction) => void;
  setClientUserId: (clientUserId: number) => void;
  setClient: (client: GetClientUsers) => void;
  setInvitationId: (invitationId: number) => void;
  reset: () => void;
}

const initialState: State = {
  typeOfAction: 'delete_invitations',
  invitationId: 0,
  clientUserId: 0,
  client: null,
};

export const useTeamStore = create<State & Action>()(set => ({
  ...initialState,
  setTypeOfAction: typeOfAction => set(() => ({ typeOfAction: typeOfAction })),
  setClientUserId: clientUserId => set(() => ({ clientUserId: clientUserId })),
  setClient: client => set(() => ({ client: client })),
  setInvitationId: invitationId => set(() => ({ invitationId: invitationId })),
  reset: () => {
    set(initialState);
  },
}));
