import { GetChannelRoutes } from 'api/channel-routes/get-channel-routes/types';
import { create } from 'zustand';

interface State {
  channelRoute: GetChannelRoutes;
}

interface Action {
  setChannelRoute: (category: GetChannelRoutes) => void;
  reset: () => void;
}

const initialState: State = {
  channelRoute: null,
};

export const useChannelRouteStore = create<State & Action>()(set => ({
  ...initialState,
  setChannelRoute: channelRoute => set(() => ({ channelRoute })),
  reset: () => {
    set(initialState);
  },
}));
