export const HtmlJsonData = {
  counters: {
    u_column: 1,
    u_row: 1,
    u_content_heading: 1,
    u_content_button: 1,
  },
  body: {
    id: 'gQrgMuuboF',
    rows: [
      {
        id: 'SDDXgmpDcw',
        cells: [1],
        columns: [
          {
            id: 'WXt1qNOebC',
            contents: [
              {
                id: 'igPTIlJeDS',
                type: 'heading',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  headingType: 'h1',
                  fontSize: '22px',
                  textAlign: 'center',
                  lineHeight: '140%',
                  linkStyle: {
                    inherit: true,
                    linkColor: '#0000ee',
                    linkHoverColor: '#0000ee',
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_heading_1',
                    htmlClassNames: 'u_content_heading',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'Welcome to our platform',
                },
              },
              {
                id: 'fEm2DSiau2',
                type: 'button',
                values: {
                  containerPadding: '10px',
                  anchor: '',
                  href: {
                    name: 'web',
                    values: {
                      href: '',
                      target: '_blank',
                    },
                  },
                  buttonColors: {
                    color: '#FFFFFF',
                    backgroundColor: '#3AAEE0',
                    hoverColor: '#FFFFFF',
                    hoverBackgroundColor: '#3AAEE0',
                  },
                  size: {
                    autoWidth: true,
                    width: '100%',
                  },
                  textAlign: 'center',
                  lineHeight: '120%',
                  padding: '10px 20px',
                  border: {},
                  borderRadius: '4px',
                  displayCondition: null,
                  _meta: {
                    htmlID: 'u_content_button_1',
                    htmlClassNames: 'u_content_button',
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: 'Click here to get started',
                  calculatedWidth: 188,
                  calculatedHeight: 54,
                },
              },
            ],
            values: {
              backgroundColor: '',
              padding: '0px',
              border: {},
              _meta: {
                htmlID: 'u_column_1',
                htmlClassNames: 'u_column',
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: '',
          columnsBackgroundColor: '',
          backgroundImage: {
            url: '',
            fullWidth: true,
            repeat: false,
            center: true,
            cover: false,
          },
          padding: '0px',
          anchor: '',
          hideDesktop: false,
          _meta: {
            htmlID: 'u_row_1',
            htmlClassNames: 'u_row',
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: 'center',
      popupWidth: '600px',
      popupHeight: 'auto',
      borderRadius: '10px',
      contentAlign: 'center',
      contentVerticalAlign: 'center',
      contentWidth: '500px',
      fontFamily: {
        label: 'Arial',
        value: 'arial,helvetica,sans-serif',
      },
      textColor: '#000000',
      popupBackgroundColor: '#FFFFFF',
      popupBackgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: true,
      },
      popupOverlay_backgroundColor: 'rgba(0, 0, 0, 0.1)',
      popupCloseButton_position: 'top-right',
      popupCloseButton_backgroundColor: '#DDDDDD',
      popupCloseButton_iconColor: '#000000',
      popupCloseButton_borderRadius: '0px',
      popupCloseButton_margin: '0px',
      popupCloseButton_action: {
        name: 'close_popup',
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: '#e7e7e7',
      backgroundImage: {
        url: '',
        fullWidth: true,
        repeat: false,
        center: true,
        cover: false,
      },
      preheaderText: '',
      linkStyle: {
        body: true,
        linkColor: '#0000ee',
        linkHoverColor: '#0000ee',
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: {
        htmlID: 'u_body',
        htmlClassNames: 'u_body',
      },
    },
  },
  schemaVersion: 10,
};
