import { Control, FieldValues, useWatch } from 'react-hook-form';
import AudioCall from 'assets/svg/template-editor/audio-call.svg';
import Camera from 'assets/svg/template-editor/camera.svg';
import ChevronLeftBlue from 'assets/svg/template-editor/chevron-left-blue.svg';
import Signal from 'assets/svg/template-editor/signal.svg';
import VideoCall from 'assets/svg/template-editor/video-call.svg';
import WhatsappBottom from 'assets/svg/template-editor/whatsapp-bottom.svg';
import WhatsappPattern from 'assets/svg/template-editor/whatsapp-pattern.svg';
import WhatsappVerification from 'assets/svg/template-editor/whatsapp-verification.svg';
import React from 'react';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';
import { GetTemplateContent } from 'api/templates/get-template/types';
import { useTemplateStore } from 'store/templateStore';

const WhatsAppPreview = ({
  control,
}: {
  control: Control<FieldValues, any>;
}) => {
  const formValues = useWatch({
    control,
  });
  const savedTemplateData = useGetTemplateChannelData();
  const { tab } = useTemplateStore(state => state);
  const defaultContentText = 'Notification Content';

  const [previewContent, setPreviewContent] = React.useState({
    message: '',
    batchingMessage: '',
    url: '',
  });

  // Form Values being updated
  React.useEffect(() => {
    setPreviewContent({
      message: formValues?.['message'],
      batchingMessage: formValues?.['batchingMessage'],
      url: formValues?.['url'],
    });
  }, [formValues]);

  // Initial Api Data
  React.useEffect(() => {
    const savedTemplateDataContent = savedTemplateData?.content;
    const savedBatchedTemplateDataContent = savedTemplateData?.batchingContent;

    setPreviewContent({
      message: savedTemplateDataContent?.message,
      batchingMessage: savedBatchedTemplateDataContent?.message,
      url: savedTemplateDataContent?.[
        `${savedTemplateDataContent?.['messageType']}Url` as keyof GetTemplateContent
      ] as string,
    });
  }, [savedTemplateData?.batchingContent, savedTemplateData?.content]);

  return (
    <div className="relative bottom-2 mx-auto flex max-h-[490px] min-h-[490px] w-[250px] flex-col overflow-clip rounded-[30px] border-[12px] border-black bg-white">
      {/* header */}
      <div className="rounded-t-[30px] bg-white px-3 pb-2 pt-3">
        {/* top 1 */}
        <div className="mb-[0.7rem] flex justify-between">
          <div className="text-xs font-semibold text-black">9:21</div>

          <img
            className="pointer-events-none relative left-3 self-center"
            width={65}
            src={Camera}
            alt=""
          />

          <img
            className="pointer-events-none relative"
            width={50}
            src={Signal}
            alt=""
          />
        </div>

        {/* top 2 */}
        <div className="flex justify-between">
          <img
            className="pointer-events-none relative"
            src={ChevronLeftBlue}
            alt=""
            width={22}
          />

          <div className="flex items-center gap-1">
            <div className={`h-6 w-6 rounded-full bg-[#A6BBD4]`}></div>
            <div className="flex items-center gap-2">
              <div className="text-[0.7rem] font-light text-night-100">
                Templates
              </div>
              <img
                className="pointer-events-none relative"
                src={WhatsappVerification}
                alt=""
                width={12}
              />
            </div>
          </div>

          <img
            className="pointer-events-none relative"
            src={VideoCall}
            alt=""
            width={20}
          />
          <img
            className="pointer-events-none relative"
            src={AudioCall}
            alt=""
            width={15}
          />
        </div>
      </div>

      {/* middle whatsapp content */}
      <div
        style={{
          background: `rgba(236, 230, 223, 1) url(${WhatsappPattern}) no-repeat center center`,
        }}
        className={`relative h-full px-3 pt-3`}
      >
        <div className="transparent"></div>
        {/* img content */}
        {tab === 'template' && (
          <div className={'relative z-10 mb-2 rounded-lg'}>
            <img
              className="max-h-[120px] w-full rounded-lg object-cover"
              src={
                typeof previewContent.url === 'string' &&
                Boolean(previewContent.url)
                  ? previewContent.url
                  : Array.isArray(previewContent.url) &&
                      previewContent.url.length > 0
                    ? URL.createObjectURL(previewContent.url[0] as File)
                    : ''
              }
              alt=""
            />
          </div>
        )}

        <div className="relative z-10 w-4/5 rounded-lg bg-white p-2 text-xs text-night-100">
          {tab === 'template'
            ? Boolean(previewContent.message)
              ? previewContent.message
              : defaultContentText
            : Boolean(previewContent.batchingMessage)
              ? previewContent.batchingMessage
              : defaultContentText}
        </div>
      </div>

      {/* bottom */}
      <div className="mt-auto">
        <img
          className="pointer-events-none relative"
          src={WhatsappBottom}
          alt=""
        />
      </div>
    </div>
  );
};

export default WhatsAppPreview;
