import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type PatchClientUser = {
  appId: number;
  name: string;
};

const put = async (values: PatchClientUser) => {
  const { appId, ...data } = values;
  return axiosInstance.put(`${routes['apps']}/${appId}`, data);
};

const usePutApp = () => {
  return useMutation({
    mutationFn: (values: PatchClientUser) => put(values),
  });
};

export default usePutApp;
