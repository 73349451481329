import useGetClientApps from 'api/client/get-client-apps';
import { useAuthStore } from 'store/authStore';

export const useAppLevel = () => {
  const { clientId, appId } = useAuthStore(state => state);

  const getClientApps = useGetClientApps(clientId);
  const isDefaultApp = getClientApps.data?.find?.(
    app => app.id === appId,
  )?.isDefault;
  const isProductionApp = getClientApps.data?.find?.(
    app => app.id === appId,
  )?.isProduction;

  return { isDefaultApp, isProductionApp };
};
