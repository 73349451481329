import { useState, useCallback } from 'react';

function useFunctionDebounce(
  functionToDebounce: (value: any) => void,
  delay = 500,
) {
  const [timer, setTimer] = useState(null);

  const debouncedFunction = useCallback(
    (value: any) => {
      if (timer !== null) {
        clearTimeout(timer);
      }

      let functionCallPromise = setTimeout(() => {
        functionToDebounce(value);
      }, delay);

      setTimer(functionCallPromise);
    },
    [functionToDebounce, timer, delay],
  );

  return debouncedFunction;
}

export default useFunctionDebounce;
