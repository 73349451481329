import { useQueryClient } from '@tanstack/react-query';
import useDeleteClientUser from 'api/client/delete-client-user';
import { GetClients } from 'api/client/get-client/types';
import useGetMe from 'api/profile/get-me';
import { routes } from 'api/routes';
import { CustomDeleteModal } from 'components/modal';
import { useAuthStore } from 'store/authStore';
import { useGetOwnerWorkspace } from 'utils/get-workspace';
import useCustomToast from 'utils/use-toast';

function DeleteOrganisationModal({
  isOpen,
  onClose,
  selectedClient,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedClient: GetClients;
}) {
  const { clientId, setClientId, setAppId } = useAuthStore(state => state);
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const deleteInvitation = useDeleteClientUser();
  const getMe = useGetMe();
  const clientUserId = getMe.data?.clientUser?.id;
  const ownerWorkspace = useGetOwnerWorkspace();

  const handleSubmit = () => {
    const data = {
      clientId: selectedClient?.client?.id,
      clientUserId: clientUserId,
    };

    deleteInvitation.mutate(data, {
      onSuccess: () => {
        toast.success('workspace removed successfully');
        queryClient.invalidateQueries([routes['clients']]);

        // we are deleting the workspace we are currently in
        if (clientId === selectedClient?.client?.id) {
          setAppId(0);
          setClientId(ownerWorkspace.client.id);
        }

        onClose();
      },
      onError: err => toast.showError(err),
    });
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteInvitation.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove Organisation"
      text={'Are you sure to remove this organisation?'}
    />
  );
}

export default DeleteOrganisationModal;
