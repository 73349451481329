import { Badge } from 'components/shadcn/badge';

export const findPublishStatus = ({
  hasPublishedJsonSpec,
  hasUnpublishedChanges,
}: {
  hasPublishedJsonSpec: boolean;
  hasUnpublishedChanges: boolean;
}) => {
  const publishStatus = hasPublishedJsonSpec
    ? hasUnpublishedChanges
      ? 2 // has unpublished changes
      : 1 // published
    : 0; // unpublished

  return publishStatus;
};

export const PublishStatusBadge = ({
  publishStatus,
}: {
  publishStatus: number;
}) => {
  return (
    <Badge
      variant={
        publishStatus === 2
          ? 'warning'
          : publishStatus === 1
            ? 'success'
            : 'danger'
      }
    >
      {publishStatus === 2
        ? 'Pending'
        : publishStatus === 1
          ? 'Published'
          : 'UnPublished'}
    </Badge>
  );
};
