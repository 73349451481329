import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetUsers } from './types';

const get = async ({
  queryKey,
}: QueryFunctionContext<
  [string, number, number, number, string]
>): Promise<GetUsers> => {
  const [, appId, pageNo, limit, filter] = queryKey;
  const { data } = await axiosInstance.get(`${routes['apps']}/${appId}/users`, {
    params: {
      pageNo,
      limit,
      identifier: filter,
    },
  });

  return data;
};

const useGetUsers = ({
  pageSize,
  pageIndex,
  filter,
  staleTime,
}: {
  pageSize: number;
  pageIndex: number;
  filter: string;
  staleTime?: number;
}) => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/users`, appId, pageIndex, pageSize, filter],
    queryFn: get,
    enabled: Boolean(appId),
    ...(staleTime && { staleTime }),
  });
};

export default useGetUsers;
