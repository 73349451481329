import Pen from 'assets/svg/profile/pen.svg';
import Delete from 'assets/svg/profile/delete.svg';
import CustomSetUp from 'components/actions/CustomSetUp';
import Card from 'components/card';
import { useDisclosure } from '@chakra-ui/hooks';
import OrganisationModal from './OrganisationModal';
import DeleteOrganisationModal from './DeleteOrganisationModal';
import useGetClient from 'api/client/get-client';
import { getFirstWordAndLetter } from 'utils/functions';
import { GetClients } from 'api/client/get-client/types';
import React from 'react';

type ActionButtonType = { item: GetClients };

const Organisation = () => {
  const organisationModalActions = useDisclosure();
  const deleteOrganisationModalActions = useDisclosure();
  const getClient = useGetClient();
  const [selectedClient, setSelectedClient] = React.useState<GetClients>(null);

  const ActionButtons = ({ item }: ActionButtonType) => {
    return (
      <>
        {item.role === 'owner' && (
          <button
            onClick={() => {
              setSelectedClient(item);
              organisationModalActions.onOpen();
            }}
          >
            <img src={Pen} alt="" />
          </button>
        )}

        {item.role !== 'owner' && (
          <button
            onClick={() => {
              setSelectedClient(item);
              deleteOrganisationModalActions.onOpen();
            }}
          >
            <img src={Delete} alt="" />
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <OrganisationModal
        isOpen={organisationModalActions.isOpen}
        onClose={organisationModalActions.onClose}
        selectedClient={selectedClient}
      />
      <DeleteOrganisationModal
        isOpen={deleteOrganisationModalActions.isOpen}
        onClose={deleteOrganisationModalActions.onClose}
        selectedClient={selectedClient}
      />

      <Card extra={'w-full  py-8'}>
        {/* Header */}
        <div className="mb-5 flex flex-col items-start justify-between gap-2 px-8">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            My Organisation
          </h4>
        </div>

        {getClient.data?.map(item => {
          const name = item.client.name;
          const { firstLetter } = getFirstWordAndLetter(name);

          return (
            <CustomSetUp<ActionButtonType>
              name={name}
              py="py-3"
              iconText={firstLetter}
              actionButtons={ActionButtons}
              actionProps={{ item }}
            />
          );
        })}
      </Card>
    </>
  );
};

export default Organisation;
