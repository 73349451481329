import { PiWarningCircleBold } from 'react-icons/pi';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@radix-ui/react-hover-card';
import { NodeProps, Position } from 'reactflow';
import { NodeData, WorkflowErrors, WorkflowNodeTypes } from '../../types';
import WorkFlowHandles from '../handles/WorkflowHandles';
import { getNodeDimensions } from 'views/admin/dashboards/workflowEditor/layout';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import { findSelectedNodeFromJson } from '../../functions';
import { cn } from 'utils/class-merge';
import { WorkflowUniqueId } from './UniqueId';
import { R } from 'utils/remeda-utils';

const LabelNode = (node: NodeProps<NodeData>) => {
  const { setSelectedNode, workflowJson, nodes, showAllNodeRefs } =
    useWorkflowStore(useShallow(state => state));

  const selectedJson = findSelectedNodeFromJson({
    steps: workflowJson?.steps,
    selectedNodeId: node.id,
  });

  const parentNode = nodes.find(
    node => node.id === selectedJson?.branchParentRef,
  );

  const subBranchError = workflowJson?.errors?.reduce(
    (acc, error) => {
      const childError = error?.errors?.find(
        childError => childError.stepRef === node.id,
      );

      if (childError) return childError;
      else return acc;
    },
    {} as WorkflowErrors['errors'][0],
  );

  return (
    <>
      <WorkFlowHandles type="target" position={Position.Top} />
      <button
        onClick={e => {
          if (node.type === 'end' || node.type === 'start') return;

          setSelectedNode(parentNode);
        }}
        className={cn(
          'bg-[#3A3F47] w-fit flex  items-center text-[10px]  py-1 px-3 text-white font-light',
          !R.isEmpty(subBranchError) ? 'justify-between ' : 'justify-center ',
          showAllNodeRefs ? 'rounded-t-lg' : 'rounded-lg',
        )}
        style={{
          ...getNodeDimensions(node.type as WorkflowNodeTypes),
        }}
      >
        <span className="overflow-hidden whitespace-nowrap text-ellipsis">
          {selectedJson?.name}
        </span>

        {showAllNodeRefs && (
          <WorkflowUniqueId
            className="top-[30px] bg-[#3A3F47] py-0 left-0 justify-center"
            showCopyButton={false}
            type={node.type as WorkflowNodeTypes}
            id={node.id}
            idClassName="relative bottom-1"
          />
        )}

        {!R.isEmpty(subBranchError) && (
          <div className="">
            <HoverCard>
              <HoverCardTrigger>
                <PiWarningCircleBold size={12} color="red" />
              </HoverCardTrigger>

              <HoverCardContent
                className={cn(
                  'text-red-350  relative top-2 z-[100] w-fit rounded border border-gray-880 bg-gray-850 p-3 text-sm flex flex-col items-start gap-2',
                )}
              >
                <span className="text-xs">
                  {subBranchError?.message ??
                    'This step is in an invalid state'}
                </span>
              </HoverCardContent>
            </HoverCard>
          </div>
        )}
      </button>

      <WorkFlowHandles type="source" position={Position.Bottom} />
    </>
  );
};

export default LabelNode;
