import { GetChannelRoutes } from 'api/channel-routes/get-channel-routes/types';
import Dropdown from 'components/dropdown';
import React from 'react';
import { BsThreeDots, BsThreeDotsVertical } from 'react-icons/bs';
import { useChannelRouteStore } from 'store/channelRouteStore';

type Actions = 'delete' | 'edit';

function ChannelRouteActionMenu(props: {
  transparent?: boolean;
  isEditable?: boolean;
  vertical?: boolean;
  buttonClass?: string;
  handleDeleteModalOpen: () => void;
  route: GetChannelRoutes;
  handleEditModalOpen: () => void;
}) {
  const [openWrapper, setOpenWrapper] = React.useState(false);
  const { setChannelRoute } = useChannelRouteStore(state => state);

  const {
    transparent,
    vertical,
    buttonClass,
    handleDeleteModalOpen,
    route,
    handleEditModalOpen,
  } = props;

  const handleMenuClick = (actionType: Actions) => {
    if (actionType === 'delete') {
      setChannelRoute(route);
      handleDeleteModalOpen();
    }

    if (actionType === 'edit') {
      setChannelRoute(route);
      handleEditModalOpen();
    }
  };

  return (
    <Dropdown
      openWrapper={openWrapper}
      setOpenWrapper={setOpenWrapper}
      button={
        <button
          onClick={e => e.stopPropagation()}
          className={`flex items-center text-xl hover:cursor-pointer ${
            transparent
              ? 'bg-none text-white hover:bg-none active:bg-none'
              : vertical
                ? 'bg-none text-gray-450 dark:text-white'
                : ' p-2 text-brand-500 dark:bg-night-100 dark:text-white'
          } linear justify-center rounded-[20px] font-bold ${buttonClass}`}
        >
          {vertical ? (
            <p className="text-[24px] hover:cursor-pointer">
              <BsThreeDotsVertical />
            </p>
          ) : (
            <BsThreeDots className="h-6 w-6" />
          )}
        </button>
      }
      animation={'origin-[90%_-15%] transition-all duration-300 ease-in-out'}
      classNames={`${transparent ? 'top-8' : 'top-9'} right-0 w-max`}
      children={
        <div className="z-50 w-32 rounded-xl border border-[#434343] bg-white px-3 py-2 text-sm shadow-xl shadow-shadow-500 dark:!bg-gray-850 dark:shadow-none">
          <button
            onClick={e => {
              e.stopPropagation();
              handleMenuClick('edit');
            }}
            className="flex w-full cursor-pointer items-center gap-2 rounded-xl p-2 px-4 font-medium text-white  hover:bg-black"
          >
            Edit
          </button>

          {!route.isDefault && (
            <button
              onClick={e => {
                e.stopPropagation();
                handleMenuClick('delete');
              }}
              className="flex w-full cursor-pointer items-center gap-2 rounded-xl p-2 px-4 font-medium text-white  hover:bg-black"
            >
              Delete
            </button>
          )}
        </div>
      }
    />
  );
}

export default ChannelRouteActionMenu;
