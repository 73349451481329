import clsx from 'clsx';
import { buttonVariants } from 'components/shadcn/button';
import React from 'react';
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryProps } from 'react-error-boundary';
import { errorMessageParser } from 'utils/get-error';

const ErrorFallback = ({
  buttonRef,
  error,
  minimal = false,
}: {
  buttonRef: React.MutableRefObject<any>;
  error: string;
  minimal?: boolean;
}) => {
  const { resetBoundary } = useErrorBoundary();

  return (
    <>
      {minimal ? (
        <div className="text-center text-xs font-light text-red-500">ERROR</div>
      ) : (
        <div className="flex flex-col h-full w-full items-center justify-center">
          <div className="text-center text-medium font-light text-red-500">
            ERROR - {error ?? 'Something went wrong'}
          </div>

          <button
            type="button"
            ref={buttonRef}
            className={clsx(
              buttonVariants({
                size: 'sm',
                variant: 'outline_hard',
              }),
              'mt-2 !bg-black',
            )}
            onClick={() => {
              resetBoundary();
            }}
          >
            Try again
          </button>
        </div>
      )}
    </>
  );
};

const CustomErrorBoundary = (
  props: Partial<ErrorBoundaryProps> & {
    minimal?: boolean;
  },
) => {
  const buttonRef = React.useRef(null);
  const [error, setError] = React.useState('');

  React.useEffect(() => {
    buttonRef?.current?.click();
  }, []);

  return (
    <ErrorBoundary
      onError={(error, info) => {
        if (props.onError) {
          props.onError(error, info);
        } else {
          const errorMessage = errorMessageParser(error);
          setError(errorMessage);
        }
      }}
      fallback={
        props.fallback || (
          <ErrorFallback
            minimal={props.minimal}
            error={error}
            buttonRef={buttonRef}
          />
        )
      }
      onReset={() =>
        props.onReset?.({
          reason: 'keys',
          prev: [],
          next: [],
        })
      }
    >
      {props.children}
    </ErrorBoundary>
  );
};

export default CustomErrorBoundary;
