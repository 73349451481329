import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import useGetLicense from 'api/billing/get-license';
import usePutChangePlans from 'api/billing/put-change-plan';
import { routes } from 'api/routes';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal } from 'components/modal';
import React from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { BsFillTagFill } from 'react-icons/bs';
import { IoMdClose } from 'react-icons/io';
import { useAuthStore } from 'store/authStore';
import { useBillingStore } from 'store/billingStore';
import { centsToDollars, getCurrencySymbol } from 'utils/get-currency';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { StripePayment } from '../../types';

type FormDiscountCoupons = {
  coupon: string;
};

export const schema = yup.object({
  coupon: yup.string().required('Coupon is required'),
});

function DiscountModal({
  stripeModalOpen,
  setPaymentDetails,
  paymentDetails,
}: {
  stripeModalOpen: () => void;
  setPaymentDetails: React.Dispatch<React.SetStateAction<StripePayment>>;
  paymentDetails: StripePayment;
}) {
  const toast = useCustomToast();

  const defaultValues = React.useMemo(
    () => ({
      coupon: '',
    }),
    [],
  );

  const { register, handleSubmit, reset, control, setFocus } =
    useForm<FormDiscountCoupons>({
      resolver: yupResolver(schema),
      defaultValues,
    });

  const currencySymbol = getCurrencySymbol();
  const { discountModalState, setDiscountModalState } = useBillingStore(
    state => state,
  );
  const couponWatch = useWatch({
    control,
    name: 'coupon',
  });

  const [showCouponCard, setShowCouponCard] = React.useState(false);
  const [showCouponInput, setShowCouponInput] = React.useState(false);
  const [loading, setLoading] = React.useState({
    coupon: false,
    proceed: false,
  });

  const handleClose = (clearPaymentDetails = true) => {
    reset(defaultValues);
    setDiscountModalState(false);
    setShowCouponCard(false);
    setShowCouponInput(false);

    if (clearPaymentDetails) {
      setPaymentDetails(null);
    }

    setLoading({
      coupon: false,
      proceed: false,
    });
  };

  const { clientId } = useAuthStore(state => state);
  const queryClient = useQueryClient();
  const putChangePlans = usePutChangePlans();
  const license = useGetLicense(clientId);
  const { plan } = useBillingStore(state => state);

  const onSubmit: SubmitHandler<FormDiscountCoupons> = values => {
    onProceed('coupon', values);
  };

  const onProceed = (
    type: 'coupon' | 'proceed',
    values?: FormDiscountCoupons,
  ) => {
    const id = Number(plan?.prices?.[0]?.id);

    if (!id) return toast.error('plan not found');
    if (
      !license.data?.InstanceClientIdentifer &&
      !license.data?.license?.licenseAuthToken
    )
      return toast.error('license not found');

    // already payment intent is created when calling discount coupon
    if (type === 'proceed' && paymentDetails) {
      handleClose(false);
      stripeModalOpen();
      return;
    }

    setLoading({
      coupon: type === 'coupon',
      proceed: type === 'proceed',
    });

    const data = {
      clientId: String(license.data?.InstanceClientIdentifer),
      price_id: Number(id),
      auth_token: license.data.license.licenseAuthToken,
      ...(values?.coupon && {
        coupon: values?.coupon,
      }),
    };

    putChangePlans.mutate(data, {
      onSuccess: response => {
        const r = response.data;

        if (!r.paymentSucceeded && r.paymentIntent) {
          setPaymentDetails({
            mode: 'payment',
            paymentIntent: r.paymentIntent,
            newSubscription: r.newSubscription,
          });

          setLoading({
            coupon: false,
            proceed: false,
          });

          // coupon add
          if (type === 'coupon' && values?.coupon) {
            setShowCouponCard(true);
          }

          // coupon remove
          if (type === 'coupon' && !values?.coupon) {
            setShowCouponCard(false);
            reset({
              coupon: '',
            });
            setTimeout(() => {
              setFocus('coupon');
            }, 100);
          }

          if (type === 'proceed') {
            handleClose(false);
            stripeModalOpen();
          }
        } else {
          //Payment intent is created from the backend,
          // so if we already have card details it will automatically switch plans
          // No need to open a stripe modal again
          if (type === 'proceed') {
            toast.success(
              'Your plan has been updated successfully. It could take a few minutes to migrate to a new plan',
            );

            queryClient.invalidateQueries([
              `${routes['clients']}/subscription`,
            ]);

            handleClose();
          }

          // if in coupon mode and for some reason no paymentIntent
          setLoading({
            coupon: false,
            proceed: false,
          });
        }
      },
      onError: () => {
        setLoading({
          coupon: false,
          proceed: false,
        });
      },
    });
  };

  return (
    <CustomModal isOpen={discountModalState} onClose={handleClose}>
      <div className="mb-8 flex flex-col">
        <h5 className="text-[1.35rem] font-normal  text-white/70">
          {plan?.name}
        </h5>
        <p className="text-[2.8rem] font-medium">
          {currencySymbol}
          {plan?.prices?.[0]?.price}{' '}
          <span className="relative right-2 text-base text-[#b6b0a6]">
            /month
          </span>
        </p>
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Subtotal */}
        <div className="mb-3 flex justify-between border-b border-white/30 px-2 pb-3">
          <p className="font-medium">Subtotal</p>
          <p className="font-semibold">
            {currencySymbol}
            {plan?.prices[0].price}
          </p>
        </div>

        <div className="px-2 py-2">
          {paymentDetails && showCouponCard ? (
            <div>
              {/* Coupon */}
              <div className="flex items-center justify-between">
                {/* coupon */}
                <div className="relative">
                  <div className="flex items-center gap-2 rounded-md bg-[#f3f3f3] p-2">
                    <BsFillTagFill color="gray" size={18} />
                    <span className="font-medium text-black">
                      {paymentDetails.newSubscription.discount?.coupon.name}
                    </span>

                    <button
                      type="button"
                      onClick={() => {
                        onProceed('coupon');
                      }}
                      className="ml-2 opacity-60 hover:opacity-100"
                    >
                      {loading.coupon ? (
                        <LoaderSpinner strokeColor="gray" parentClass="" />
                      ) : (
                        <IoMdClose size={18} color="black" />
                      )}
                    </button>
                  </div>
                </div>

                {/* discount details */}
                <div className="text-white/70">
                  -{currencySymbol}
                  {Number(
                    plan?.prices[0].price -
                      centsToDollars(paymentDetails?.paymentIntent.amount),
                  )}
                </div>
              </div>

              {/* Percent off */}
              <div className="relative left-2 mt-3 text-[13px] text-white/70">
                {paymentDetails?.newSubscription.discount?.coupon.percent_off}%
                off
              </div>
            </div>
          ) : (
            <div>
              {/* Coupon Input */}
              {showCouponInput ? (
                <div className={`relative`}>
                  <InputField<FormDiscountCoupons>
                    variant="modal"
                    extra="mb-3"
                    label={''}
                    placeholder="Add promotion code"
                    type="text"
                    register={register}
                    name={'coupon'}
                    onBlur={e => {
                      if (e.target.value === '') {
                        setShowCouponInput(false);
                      }
                    }}
                  />

                  {couponWatch !== '' && (
                    <button
                      type="submit"
                      className="absolute right-4 top-0 h-11 text-sm font-semibold"
                      disabled={loading.coupon}
                    >
                      {loading.coupon ? (
                        <LoaderSpinner parentClass="relative" />
                      ) : (
                        'Apply'
                      )}
                    </button>
                  )}
                </div>
              ) : (
                // Coupon Show Button
                <button
                  onClick={() => {
                    setShowCouponInput(true);
                    setTimeout(() => {
                      setFocus('coupon');
                    }, 100);
                  }}
                  className="text-blue-400"
                >
                  Add promotion code
                </button>
              )}
            </div>
          )}
        </div>

        {putChangePlans.isError && (
          <ErrorBox error={putChangePlans.error} errorKey="message" />
        )}

        {/* Total Due */}
        <div className="mt-3 flex justify-between border-t border-white/30 px-2 pt-3">
          <p className="font-medium">Total Due</p>
          <p className="font-semibold">
            {currencySymbol}
            {paymentDetails
              ? centsToDollars(paymentDetails?.paymentIntent.amount)
              : plan?.prices[0].price}
          </p>
        </div>
      </form>

      <div className="mt-6 flex gap-4">
        <button
          className="linear flex w-full justify-center rounded-xl bg-night-100 py-3 text-base font-medium text-white transition duration-200  dark:text-white "
          type="button"
          disabled={loading.proceed || loading.coupon}
          onClick={() => handleClose()}
        >
          Cancel
        </button>
        <button
          className="linear flex w-full justify-center rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-500 dark:active:bg-brand-400 dark:disabled:bg-brand-700"
          type="button"
          disabled={loading.proceed || loading.coupon}
          onClick={() => onProceed('proceed', undefined)}
        >
          Proceed
          {loading.proceed && <LoaderSpinner />}
        </button>
      </div>
    </CustomModal>
  );
}

export default DiscountModal;
