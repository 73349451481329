import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@radix-ui/react-hover-card';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import useWorkflowStore from 'store/workflowStore';
import { cn } from 'utils/class-merge';
import { checkJsonHasErrors } from '../../functions';

const WorkflowPublishButton = ({
  onClick,
  className,
  isLoading,
  text = 'Publish workflow',
}: {
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
  text?: string;
}) => {
  const { hasJsonChanges } = useWorkflowStore(state => state);
  const hasErrors = checkJsonHasErrors();
  const isDisabled = isLoading || !hasJsonChanges || hasErrors;

  return (
    <>
      <HoverCard>
        <HoverCardTrigger>
          <button
            disabled={isDisabled}
            onClick={onClick}
            className={cn(
              // 'bg-night-400 text-white border border-gray-440 px-4 py-1 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed',
              'bg-white text-night-400 border border-gray-440 px-4 py-1 rounded-md flex gap-2 items-center disabled:opacity-50 disabled:cursor-not-allowed',
              className,
            )}
          >
            {/* {showIcon && <img width={12} src={Add} alt="add" color="black" />} */}

            <span>{text}</span>

            {isLoading && <LoaderSpinner parentClass="relative left-1" />}
          </button>
        </HoverCardTrigger>

        {isDisabled && (
          <HoverCardContent className="relative top-2 z-[100] !w-[250px] rounded-md border-2 border-gray-440 bg-gray-880 text-white p-2 text-sm">
            {!hasJsonChanges
              ? "You haven't made any changes to this workflow yet"
              : hasErrors
                ? 'Cannot publish this workflow because some steps are in invalid state'
                : 'This workflow cannot be commited in its current state'}
          </HoverCardContent>
        )}
      </HoverCard>
    </>
  );
};

export default WorkflowPublishButton;
