import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from 'components/shadcn/hover-card';
import { HiOutlineEye } from 'react-icons/hi';
import { IoMdInformationCircleOutline } from 'react-icons/io';

import { format, parseISO } from 'date-fns';
import React from 'react';

import { useDisclosure } from '@chakra-ui/hooks';
import { PaginationState } from '@tanstack/react-table';
import { GetLogs, GetLogsResult } from 'api/logs/get-logs/types';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import { useNavigate } from 'react-router-dom';
import { eventChipBg, eventChipColor } from '../variables';
import DetailsModal from './DetailsModal';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import { BellDot } from 'lucide-react';

function LogsTable(props: {
  tableData: any;
  totalPages: number;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  loading: boolean;
}) {
  const { tableData, pagination, setPagination, totalPages, loading } = props;
  const detailsModalActions = useDisclosure();
  const [selectedUser] = React.useState<GetLogs>();
  const navigate = useNavigate();

  // const [selectedUser, setSelectedUser] = React.useState<GetLogs>();

  // const handleViewUserDetails = (item: GetLogs) => {
  //   detailsModalActions.onOpen();
  //   setSelectedUser(item);
  // };

  const columnConfig: ColumnConfig<GetLogsResult> = [
    {
      accessorKey: 'requestId',
      header: 'RequestId',
      cellFunction: info => (
        <p className="py-[10px]  text-xs text-navy-700 dark:text-white">
          {info.getValue() || ''}
        </p>
      ),
    },
    {
      accessorKey: 'identifier',
      header: 'Recipient',
      cellFunction: info =>
        info.getValue() && (
          <button
            onClick={e => {
              e.stopPropagation();
              navigate(`/admin/users?search=${info.getValue()}`);
            }}
            className="group flex items-center hover:outline outline-input relative right-3  justify-between gap-3 rounded-md px-3 py-2  text-base text-navy-700 hover:bg-night-100 dark:text-white"
          >
            {info.getValue()}
            <div className="opacity-0 group-hover:opacity-100">
              <HiOutlineEye size={20} />
            </div>
          </button>
        ),
    },
    {
      accessorKey: 'channel',
      header: 'Channel',
      cellFunction: info => (
        <p className="py-[10px]  text-sm text-navy-700 dark:text-white">
          {info.getValue() || ''}
        </p>
      ),
    },
    {
      accessorKey: 'providerIcon',
      header: 'Provider',
      cellFunction: info => {
        const providerName = info.row.original.providerName;
        return (
          <HoverCard>
            <HoverCardTrigger>
              <img width={20} src={info.getValue()} alt={providerName} />
            </HoverCardTrigger>
            <HoverCardContent className="!w-auto p-2 text-sm">
              {providerName}
            </HoverCardContent>
          </HoverCard>
        );
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Timestamp',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMM yyyy, h:mm aaa');
        return <p className=" text-sm text-navy-700 dark:text-white">{date}</p>;
      },
    },
    {
      accessorKey: 'event',
      header: 'Event',
      size: 20,
      cellFunction: info => {
        const event = info.row.original.event;
        const reason = info.row.original.reason;
        return (
          <HoverCard>
            <HoverCardTrigger>
              <div className="flex gap-2">
                <div
                  style={{
                    background: eventChipBg[event],
                    color: eventChipColor[event],
                  }}
                  className={`relative  flex gap-1 select-none items-center whitespace-nowrap rounded-lg px-[10px] py-[5px] text-sm font-medium capitalize`}
                >
                  {info.getValue().replace('_', ' ')}
                  {reason && <IoMdInformationCircleOutline />}
                </div>
              </div>
            </HoverCardTrigger>
            {reason && (
              <HoverCardContent className="break-all">
                <h4 className="mb-1">Reason</h4>
                <p className="text-sm">{reason}</p>
              </HoverCardContent>
            )}
          </HoverCard>
        );
      },
    },
  ];

  const columns = columnGenerator<GetLogsResult>({ columnConfig });

  return (
    <>
      <DetailsModal
        isOpen={detailsModalActions.isOpen}
        onClose={detailsModalActions.onClose}
        selectedUser={selectedUser}
      />

      {/* table */}
      <CustomTable
        loading={loading}
        tableData={tableData}
        columns={columns}
        emptyTableMessage="It looks like the logs are empty. Once you send a notification, you'll see the logs here"
        emptyTableMessageClassName="text-lg"
        paginationType="api"
        pagination={pagination}
        setPagination={setPagination}
        totalPages={totalPages}
        emptyAdditionalJsx={() => (
          <EmptyTableMessage
            handleCreate={() => {
              navigate('/admin/compose/send');
            }}
            searchInput={''}
            docsLink={
              'https://docs.engagespot.co/docs/rest-api/#tag/Notifications/paths/~1v3~1notifications/post'
            }
            heading={'No Logs Found  🔍'}
            message={
              'It looks like there are no logs available at the moment. Once you start sending notifications, the logs will appear here to help you track and manage them effectively.'
            }
            searchEmptyHeading={getSearchEmptyHeading('notifications')}
            searchEmptyMessage={getSearchEmptyMessage('notifications')}
            Icon={BellDot}
          />
        )}
      />
    </>
  );
}

export default LogsTable;
