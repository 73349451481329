import { useSetDefaultLayout } from 'api/layouts';
import { CustomDeleteModal } from 'components/modal';
import { useLayoutStore } from 'store/layoutStore';
import useCustomToast from 'utils/use-toast';

function DefaultModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const setDefaultLayout = useSetDefaultLayout();
  const toast = useCustomToast();
  const { layout } = useLayoutStore(state => state);

  const handleSubmit = () => {
    setDefaultLayout.mutate(
      {
        layoutId: layout.id,
      },
      {
        onSuccess: () => {
          toast.success('Layout made default successfully');
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={setDefaultLayout.isLoading}
      handleSubmit={handleSubmit}
      heading={'Default Layout'}
      text={`Are you sure to make this layout default?`}
      primaryButtonText={'Make Default'}
      textClassName={'text-green-370'}
      primaryButtonClassName={
        'bg-green-750 text-green-20 disabled:bg-green-750/60'
      }
      loadingStrokeColor="#8AFF88"
    />
  );
}

export default DefaultModal;
