import AppleLogoGray from 'assets/svg/template-editor/apple-logo-gray.svg';
import AppleLogoWhite from 'assets/svg/template-editor/apple-logo-white.svg';
import Chrome from 'assets/svg/template-editor/chrome.svg';
import Close from 'assets/svg/template-editor/close.svg';
import Collapse from 'assets/svg/template-editor/collapse.svg';
import MoreOptions from 'assets/svg/template-editor/more-options.svg';
import WindowsLogoGray from 'assets/svg/template-editor/windows-logo-gray.svg';
import WindowsLogoWhite from 'assets/svg/template-editor/windows-logo-white.svg';
import { motion } from 'framer-motion';
import React from 'react';
import { Control, FieldValues, useWatch } from 'react-hook-form';
import { useTemplateStore } from 'store/templateStore';
import { useMobileWebInAppTemplateDefaultPreviewSetup } from '../../hooks/commonTemplatePreviewSetup';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';

const WebPushPreview = ({
  control,
}: {
  control: Control<FieldValues, any>;
}) => {
  const { tab } = useTemplateStore(state => state);
  const [device, setDevice] = React.useState<'imac' | 'windows'>('imac');
  const isApple = device === 'imac';
  const savedTemplateData = useGetTemplateChannelData();

  const handleSwitch = () => {
    setDevice(isApple ? 'windows' : 'imac');
  };

  const formValues = useWatch({
    control,
  });

  const { hasPreviewImage, previewImageSrc, previewMessage, previewTitle } =
    useMobileWebInAppTemplateDefaultPreviewSetup({
      formValues,
      savedTemplateData,
    });

  return (
    <>
      <div className="relative bottom-[66px] mx-auto flex w-[35%]  max-w-[350px] flex-col items-center gap-6">
        {/* switch */}
        <button
          onClick={() => handleSwitch()}
          className={`rounded-[50px]; relative flex h-[39px] w-[78px] cursor-pointer ${
            isApple ? 'justify-start' : 'justify-end'
          }  rounded-3xl bg-night-100 p-[3px]`}
        >
          {/* icons */}
          <img
            className={`absolute left-[10px] top-[22%] z-10`}
            src={isApple ? AppleLogoWhite : AppleLogoGray}
            alt=""
          />
          <img
            className="absolute right-[10px] top-[27%] z-10"
            src={isApple ? WindowsLogoGray : WindowsLogoWhite}
            alt=""
          />

          {/* handle */}
          <motion.div
            layout
            transition={{
              duration: 0.3,
            }}
            className="z-0 h-[33px] w-[33px] rounded-[40px] bg-[#01B574]"
          ></motion.div>
        </button>

        {/* Pc notification */}
        <div
          className={`overflow-hidden rounded-2xl ${
            isApple ? 'bg-white' : 'bg-[#EEEEEE]'
          }  min-w-[345px] pb-4`}
        >
          {/* apple close */}
          <div
            className={`flex ${
              isApple ? 'h-8' : 'h-0'
            }  justify-end gap-3 pr-3`}
          >
            <img width={10} src={MoreOptions} alt="" />
            <img width={10} src={Collapse} alt="" />
          </div>

          {/* img content */}
          <div className={'mb-5'}>
            <img
              className="max-h-[192px] w-[345px] object-cover"
              src={hasPreviewImage ? (previewImageSrc as string) : ''}
              alt=""
            />
          </div>

          {/* text content */}
          <div
            className={`mb-8  flex ${
              isApple ? '' : 'flex-col pl-9'
            } items-start gap-4 px-4`}
          >
            {isApple ? (
              <img width={32} src={Chrome} alt="" />
            ) : (
              <div
                className={`flex w-full justify-between ${
                  tab === 'batched_template' ? 'mt-3' : ''
                }`}
              >
                <div className="flex items-center gap-2">
                  <img width={18} src={Chrome} alt="" />
                  <div className="text-sm font-semibold text-[#191919]">
                    Chrome
                  </div>
                </div>

                <div className="flex items-center gap-8">
                  <img width={12} src={MoreOptions} alt="" />
                  <img width={10} src={Close} alt="" />
                </div>
              </div>
            )}

            <div>
              <h5 className="mb-2  text-sm font-bold text-black">
                {previewTitle}
              </h5>
              <p className=" text-sm font-medium text-night-120">
                {previewMessage}
              </p>
            </div>
          </div>

          {/* buttons */}
          <div
            className={`flex  px-3 text-night-120 ${
              isApple ? 'flex-col text-[15px]' : 'gap-2 text-[16px]'
            }  `}
          >
            <button
              className={`${
                isApple
                  ? 'border-b border-t border-[#F3F3F3] py-1'
                  : 'w-1/2  rounded-md  bg-[#FAFAFA] py-2'
              }`}
            >
              Share
            </button>

            <button
              className={`${
                isApple
                  ? 'border-b border-[#F3F3F3] py-1'
                  : 'w-1/2   rounded-md bg-[#FAFAFA] py-2'
              }`}
            >
              Settings
            </button>

            {isApple && (
              <button className="border-b border-[#F3F3F3] py-1">Close</button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default WebPushPreview;
