import { useEffect } from 'react';

export function useSetReactPhoneInputCountryWidth({
  shouldFocus = false,
}: { shouldFocus?: boolean } = {}) {
  useEffect(() => {
    const telephoneInput = document.querySelector?.(
      'input[type="tel"]',
    ) as HTMLElement;
    const showFlagBtn = document.querySelector?.(
      '.selected-flag',
    ) as HTMLElement;

    if (!showFlagBtn || !telephoneInput) return;

    if (shouldFocus) {
      telephoneInput.focus();
    }

    const setCssVariableWidth = () => {
      const elementForWidthReference = document.querySelector?.(
        '#phone__input__container',
      ) as HTMLElement;
      const width = elementForWidthReference?.clientWidth;
      document.documentElement.style.setProperty(
        '--country-list-width',
        `${width ?? 0}px`,
      );
    };

    const setCountryListWidth = () => {
      showFlagBtn.addEventListener('click', setCssVariableWidth);
    };

    const removeCountryListWidth = () => {
      showFlagBtn.removeEventListener('click', setCssVariableWidth);
    };

    setCountryListWidth();
    return removeCountryListWidth;
  }, [shouldFocus]);
}
