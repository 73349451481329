import { Row } from '@tanstack/react-table';
import { UnCommitedChanges } from 'api/commits/types';
import useGetMe from 'api/profile/get-me';
import { Avatar, AvatarFallback, AvatarImage } from 'components/shadcn/avatar';
import { Checkbox } from 'components/shadcn/checkbox';
import { CustomHoverCard } from 'components/shadcn/hover-card';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { format } from 'date-fns';
import parseISO from 'date-fns/parseISO';
import { BellDot, CircleX } from 'lucide-react';
import React from 'react';
import { cn } from 'utils/class-merge';

function ChangesTable(props: {
  tableData: any;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  publishModalOpen: () => void;
  changesDiffModalOpen: () => void;
  loading: boolean;
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Row<UnCommitedChanges>[]>
  >;
}) {
  const getMe = useGetMe();

  const {
    tableData,
    globalFilter,
    publishModalOpen,
    loading,
    setSelectedRows,
    changesDiffModalOpen,
  } = props;

  const columnConfig: ColumnConfig<UnCommitedChanges> = [
    {
      accessorKey: 'jsonSpec.errors',
      size: 110,
      maxSize: 110,
      header: ({ table }) => (
        <Checkbox
          checked={
            table.getIsAllPageRowsSelected() ||
            Boolean(table.getIsSomePageRowsSelected() && 'indeterminate')
          }
          onCheckedChange={value => {
            table.getRowModel().rows.forEach(row => {
              if (row.original.jsonSpec?.errors?.length > 0) {
                return;
              }

              table.getRow(row.id).toggleSelected(!!value);
            });
          }}
          aria-label="Select all"
        />
      ),
      cellFunction: info => {
        const row = info.row;
        const original = info.row.original;
        const errors = original?.jsonSpec?.errors;
        const hasErrors = errors?.length > 0;

        return (
          <div onClick={e => e.stopPropagation()}>
            {hasErrors ? (
              <div>
                <CustomHoverCard
                  children={
                    <CircleX className="text-red-500 opacity-100 text-sm h-4 w-4" />
                  }
                  content={
                    <div className="flex flex-col gap-2">
                      <p className="text-sm">
                        This workflow has errors. Please fix them before
                        publishing
                      </p>
                    </div>
                  }
                />
              </div>
            ) : (
              <Checkbox
                checked={row.getIsSelected()}
                onCheckedChange={value => row.toggleSelected(!!value)}
                aria-label="Select row"
              />
            )}
          </div>
        );
      },
    },
    {
      accessorKey: 'clientUser',
      header: 'Changed by',
      cellFunction: info => {
        const clientUser = info.getValue();
        const currentUser = getMe.data;
        const firstName =
          clientUser?.firstName ?? currentUser?.clientUser?.firstName;
        const profilePicture =
          clientUser?.profilePicture ?? currentUser?.clientUser?.profilePicture;
        const hasErrors = info.row.original?.jsonSpec?.errors?.length > 0;

        return (
          <div
            className={cn('flex gap-2 items-center', hasErrors && 'opacity-30')}
          >
            <span>
              <Avatar className="h-6 w-6">
                <AvatarImage src={profilePicture} alt={firstName} />
                <AvatarFallback className="text-white text-xs">
                  {firstName?.charAt(0).toUpperCase()}
                </AvatarFallback>
              </Avatar>
            </span>
            <span>{firstName}</span>
          </div>
        );
      },
    },
    {
      accessorKey: 'entityType',
      header: 'Type',
      cellFunction: info => {
        const hasErrors = info.row.original?.jsonSpec?.errors?.length > 0;
        return (
          <span className={cn(hasErrors && 'opacity-30')}>
            {info.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => {
        const hasErrors = info.row.original?.jsonSpec?.errors?.length > 0;
        return (
          <span className={cn(hasErrors && 'opacity-30')}>
            {info.getValue()}
          </span>
        );
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Created At',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMMM yyyy, h:mm aaa');
        const hasErrors = info.row.original?.jsonSpec?.errors?.length > 0;
        return <span className={cn(hasErrors && 'opacity-30')}>{date}</span>;
      },
    },
    {
      accessorKey: 'jsonSpec.steps.batchingEnabled',
      header: () => '',
      cellFunction: info => {
        const hasErrors = info.row.original?.jsonSpec?.errors?.length > 0;
        const data = [info.row];

        const changesOption = [
          {
            text: 'View Changes',
            action: () => {
              setSelectedRows(data);
              changesDiffModalOpen();
            },
          },
        ];

        const changesAndPublishOption = [
          ...changesOption,
          {
            text: 'Publish',
            action: () => {
              setSelectedRows([info.row]);
              publishModalOpen();
            },
          },
        ];

        return (
          <div className={cn('flex gap-0', hasErrors && 'opacity-30')}>
            {
              <TableMenu
                config={hasErrors ? changesOption : changesAndPublishOption}
              />
            }
          </div>
        );
      },
    },
  ];

  const columns = columnGenerator({ columnConfig });

  return (
    <CustomTable
      rowClick={row => {
        setSelectedRows([row]);
        changesDiffModalOpen();
      }}
      showSelectedRows={true}
      tableData={tableData}
      columns={columns}
      paginationType="table"
      globalFilter={globalFilter}
      loading={loading}
      setSelectedRows={setSelectedRows}
      emptyAdditionalJsx={() => (
        <EmptyTableMessage
          searchInput={globalFilter}
          docsLink={
            'https://docs.engagespot.co/docs/features/workflows/publish'
          }
          heading={'Nothing to Publish 🗂️'}
          message={
            'You don’t have any pending changes to be published. Create or edit workflows to see them here.'
          }
          searchEmptyHeading={getSearchEmptyHeading('unpublished changes')}
          searchEmptyMessage={getSearchEmptyMessage('unpublished changes')}
          Icon={BellDot}
          hideSecondary={true}
          messsageClassName="max-w-[350px]"
        />
      )}
    />
  );
}

export default ChangesTable;
