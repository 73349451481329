import { DropResult } from 'react-beautiful-dnd';
import {
  WORKFLOWDATA_EVENT_TRIGGERED_ID,
  ChannelWorkflow,
} from './variables/data';
import { QueryClient, UseMutationResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { PostTemplateChannelOrder } from 'api/templates/post-template-channel-order';
import { routes } from 'api/routes';
import { GetTemplateContents } from 'api/templates/get-template/types';
import { customToast } from '../../../../utils/use-toast';

const handleOptimisticUpdate = async ({
  newchannelIds,
  templateId,
  queryClient,
}: {
  templateId: number;
  queryClient: QueryClient;
  newchannelIds: string[];
}) => {
  // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
  await queryClient.cancelQueries([`${routes['templates']}`, templateId]);

  // Snapshot the previous value
  const previousData: GetTemplateContents = queryClient.getQueryData([
    `${routes['templates']}`,
    templateId,
  ]);

  const newMutatedData = previousData.contents.reduce((acc, curr) => {
    let currentValue = curr;

    const currentPosition = newchannelIds.findIndex(
      id => Number(id) === curr.id,
    );

    currentValue = {
      ...curr,
      order: currentPosition + 1,
    };

    return [...acc, currentValue];
  }, []);

  // Optimistically update to the new value
  queryClient.setQueryData([`${routes['templates']}`, templateId], () => {
    return {
      ...previousData,
      contents: newMutatedData,
    };
  });
};

export const handleDragEnd = async ({
  result,
  channelWorkFlowData,
  setChannelWorkFlowData,
  postTemplateChannelOrder,
  templateId,
  queryClient,
  toast,
}: {
  result: DropResult;
  channelWorkFlowData: ChannelWorkflow;
  setChannelWorkFlowData: (
    value: React.SetStateAction<ChannelWorkflow>,
  ) => void;
  templateId: number;
  postTemplateChannelOrder: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    PostTemplateChannelOrder,
    unknown
  >;
  queryClient: QueryClient;
  toast: customToast;
}) => {
  const { destination, source, draggableId } = result;

  if (!destination) {
    return;
  }

  if (destination.index === 0 || source.index === 0) {
    return;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return;
  }

  const column =
    channelWorkFlowData.columns[source.droppableId as 'addedChannels'];
  const newchannelIds = Array.from(column.channelIds);
  newchannelIds.splice(source.index, 1);
  newchannelIds.splice(destination.index, 0, draggableId);

  const newColumn = {
    ...column,
    channelIds: newchannelIds,
  };

  const newState = {
    ...channelWorkFlowData,
    columns: {
      ...channelWorkFlowData.columns,
      [newColumn.id]: newColumn,
    },
  };

  setChannelWorkFlowData(newState);

  // optimistic update
  handleOptimisticUpdate({
    newchannelIds,
    queryClient,
    templateId,
  });

  // api call
  const data = {
    templateId,
    contentIds: newColumn.channelIds
      .filter(ids => ids !== WORKFLOWDATA_EVENT_TRIGGERED_ID)
      .map(item => Number(item)),
  };

  postTemplateChannelOrder.mutate(data, {
    onError: err => {
      toast.showError(err);
    },
  });
};
