import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';

type PostResendInvitation = {
  invitationId: number;
};

const post = async (values: PostResendInvitation) => {
  const { invitationId } = values;
  return axiosGlobalInstance.post(
    `${routes['invitations']}/${invitationId}/resend`,
  );
};

const useResendInvitation = () => {
  return useMutation({
    mutationFn: (values: PostResendInvitation) => post(values),
  });
};

export default useResendInvitation;
