import Card from 'components/card';
import { disabledFileStyles } from '../index';
import { FileFieldProps } from '../types';
import { CustomDropZone } from '../dropzone';

const SmallFileVariant = <T,>({
  label,
  showIsRequiredAsterisk = false,
  control,
  required,
  name,
  extraInputClass,
  extraLabelClass,
  extra,
  setValue,
  accept,
  disabled,
}: FileFieldProps<T>) => {
  return (
    <Card extra={`w-full !rounded-[0px] border-none ${extra}`}>
      {label && (
        <label
          className={`ml-1.5 text-sm font-medium text-navy-700 dark:text-white ${extraLabelClass}`}
        >
          {label}
          {showIsRequiredAsterisk && <span className="text-brand-400">*</span>}
        </label>
      )}

      <div className="mt-2 flex w-full items-center justify-center rounded-[20px]">
        <CustomDropZone
          control={control}
          name={name}
          setValue={setValue}
          required={required}
          accept={accept}
          disabled={disabled}
          content={(open, isDragActive) => (
            <button
              type="button"
              onClick={open}
              disabled={disabled}
              className={`flex h-[41px] w-full flex-col items-center justify-center rounded-l-md  bg-gray-100 px-[5px]   ${
                isDragActive ? 'dark:!bg-night-100/60' : 'dark:!bg-night-100'
              } ${disabled ? disabledFileStyles : ''} ${extraInputClass}`}
            >
              <p
                className={`text-sm font-bold ${
                  disabled
                    ? 'dark:text-[rgba(255,255,255,0.15)]'
                    : 'text-navy-700 dark:text-white'
                } `}
              >
                Drop your Image here, or
                <span className="pl-2 font-bold text-brand-500 dark:text-brand-400">
                  Click to browse
                </span>
              </p>
            </button>
          )}
        />
      </div>
    </Card>
  );
};

export default SmallFileVariant;
