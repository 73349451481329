// colour assets
// import ColorWebPush from "assets/svg/integrations/color/web-push.svg";
// import ColorMobilePush from "assets/svg/integrations/color/mobile-push.svg";
// import ColorInApp from "assets/svg/integrations/color/inapp.svg";
// import ColorSms from "assets/svg/integrations/color/sms.svg";
// import ColorWhatsapp from "assets/svg/integrations/color/whatsapp.svg";
// import ColorSlack from "assets/svg/integrations/color/slack.svg";
// import ColorEmail from "assets/svg/integrations/color/email.svg";
// import ColorDiscord from "assets/svg/integrations/color/discord.svg";
// import ColorWebhook from "assets/svg/integrations/color/color_webhook.png";

// latest black and white assets
// import WebPush from 'assets/svg/integrations/web-push.svg';
// import MobilePush from 'assets/svg/integrations/mobile-push.svg';
// import InApp from 'assets/svg/integrations/inapp.svg';
// import Sms from 'assets/svg/integrations//sms.svg';
// import Whatsapp from 'assets/svg/integrations/whatsapp.svg';
// import Slack from 'assets/svg/integrations/slack.svg';
// import Discord from 'assets/svg/integrations/discord.svg';
// import Email from 'assets/svg/integrations/email.svg';
// import Webhook from 'assets/svg/integrations/webhook.svg';
// import EventTrigger from 'assets/svg/integrations/event_trigger.svg';

// 3d assets

// 3d color
import EventTrigger from 'assets/img/3d/color/lightning.png';
import WebPush from 'assets/img/3d/color/web-push.png';
import Sms from 'assets/img/3d/color/sms.png';
import InApp from 'assets/img/3d/color/bell.png';
import Webhook from 'assets/img/3d/color/webhook.png';
import MobilePush from 'assets/img/3d/color/mobile.png';
import Discord from 'assets/img/3d/color/discord.png';
import Email from 'assets/img/3d/color/email.png';
import Slack from 'assets/img/3d/color/slack.png';
import Whatsapp from 'assets/img/3d/color/whatsapp.png';

// 3d black n white color
import BnWWebPush from 'assets/img/3d/clay/web-push.png';
import BnWSms from 'assets/img/3d/clay/sms.png';
import BnWInApp from 'assets/img/3d/clay/bell.png';
import BnWWebhook from 'assets/img/3d/clay/webhook.png';
import BnWMobilePush from 'assets/img/3d/clay/mobile.png';
import BnWDiscord from 'assets/img/3d/clay/discord.png';
import BnWEmail from 'assets/img/3d/clay/email.png';
import BnWSlack from 'assets/img/3d/clay/slack.png';
import BnWWhatsapp from 'assets/img/3d/clay/whatsapp.png';

import { Channels } from 'api/channels/get-channels/types';

type ChannelsObj = { [actions in Channels]?: string };

export let channelsLogoColor: ChannelsObj = {
  webPush: WebPush,
  mobilePush: MobilePush,
  inApp: InApp,
  sms: Sms,
  whatsapp: Whatsapp,
  slack: Slack,
  email: Email,
  discord: Discord,
  webhook: Webhook,
};

export let channelsLogo: ChannelsObj & { event_trigger: string } = {
  ...channelsLogoColor,
  event_trigger: EventTrigger,
};

export let channelsLogoBlackAndWhite: ChannelsObj = {
  webPush: BnWWebPush,
  mobilePush: BnWMobilePush,
  inApp: BnWInApp,
  sms: BnWSms,
  whatsapp: BnWWhatsapp,
  slack: BnWSlack,
  email: BnWEmail,
  discord: BnWDiscord,
  webhook: BnWWebhook,
};
