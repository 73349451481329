import { axiosInstance } from 'api/axios';
import { HtmlJsonData } from 'mock/htmljson.data';
import { HtmlTemplateData } from 'mock/htmltemplate.data';

export const getTemplateDataFromJson = (jsonUrl: string) => {
  return axiosInstance
    .get(jsonUrl)
    .then(res => {
      return res.data;
    })
    .catch(e => {
      const urlType = jsonUrl.split('.').pop();
      if (urlType === 'json') {
        return HtmlJsonData;
      } else if (urlType === 'html') {
        return HtmlTemplateData;
      } else {
        throw e;
      }
    });
};
