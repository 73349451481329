import Back from 'assets/svg/template/back.svg';
import { cn } from 'utils/class-merge';

const WorkflowBackButton = ({
  onClick,
  className,
  disabled,
}: {
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <button
      disabled={disabled}
      className={cn('mb-2 w-fit', className)}
      onClick={onClick}
    >
      <img className={''} src={Back} alt="" />
    </button>
  );
};

export default WorkflowBackButton;
