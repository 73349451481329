import {
  useGetAllWorkflowRuns,
  useGetWorkflowRuns,
  useGetWorkflowRunsDetails,
} from 'api/workflows-run';
import { usePaginationApiState } from 'components/table/pagination-state';
import { cn } from 'utils/class-merge';
import WorkflowRunStepsTable from './WorkflowRunStepsTable';
import WorkflowRunTable from './WorkflowRunTable';
import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useGetWorkflowOverviewPageParams } from '../hooks';

export const WorkflowRuns = ({
  workflowRunId,
  setWorkflowRunId,
  inLogsPage = false,
  setWorkflowId,
  workflowId,
}: {
  workflowRunId: number;
  setWorkflowRunId: React.Dispatch<React.SetStateAction<number>>;
  inLogsPage?: boolean;
  setWorkflowId?: React.Dispatch<React.SetStateAction<number>>;
  workflowId: number;
}) => {
  const [filter, setFilter] = React.useState('');
  const { pagination, setPagination } = usePaginationApiState();
  const navigate = useNavigate();
  const { currentView } = useGetWorkflowOverviewPageParams();

  const getWorkflowRuns = useGetWorkflowRuns(
    workflowId,
    inLogsPage ? false : true,
  );
  const getAllWorkflowRuns = useGetAllWorkflowRuns({
    ...pagination,
    enabled: inLogsPage ? true : false,
  });
  const getWorkflowRunsDetails = useGetWorkflowRunsDetails(
    workflowId,
    workflowRunId,
  );

  return (
    <div className="col-span-12">
      {workflowRunId ? (
        <>
          {/* Header */}
          <div
            className={cn(
              'relative flex gap-4 w-fit',
              inLogsPage ? 'top-4' : '',
            )}
          >
            <MdKeyboardBackspace
              onClick={() => {
                setWorkflowRunId(null);

                if (!inLogsPage) {
                  navigate(
                    `/admin/workflow-overview/${workflowId}?view=${currentView}`,
                  );
                }
              }}
              color="white"
              size={25}
              className="cursor-pointer"
            />

            <div className="relative text-lg font-medium text-white">
              <h5>Workflow Run Steps</h5>
            </div>
          </div>

          <WorkflowRunStepsTable
            loading={getWorkflowRunsDetails.isLoading}
            tableData={getWorkflowRunsDetails?.data ?? []}
            globalFilter={filter}
            setGlobalFilter={setFilter}
          />
        </>
      ) : (
        <>
          {!inLogsPage && (
            <div className="relative text-lg font-medium text-white">
              <h5>Workflow Runs</h5>
            </div>
          )}

          <>
            <WorkflowRunTable
              tableData={
                inLogsPage
                  ? getAllWorkflowRuns.data?.results ?? []
                  : getWorkflowRuns?.data ?? []
              }
              totalCount={getAllWorkflowRuns?.data?.total}
              globalFilter={filter}
              setGlobalFilter={setFilter}
              setSelected={setWorkflowRunId}
              pagination={pagination}
              setPagination={setPagination}
              inLogsPage={inLogsPage}
              setWorkflowId={setWorkflowId}
              loading={
                inLogsPage
                  ? getAllWorkflowRuns.isLoading
                  : getWorkflowRuns.isLoading
              }
            />
          </>
        </>
      )}
    </div>
  );
};
