import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

type TPostAppVerification = {
  appId: number;
};

const put = async (values: TPostAppVerification) => {
  const { appId } = values;
  return axiosInstance.put(`${routes['apps']}/${appId}/secure-auth`);
};

const usePutSecureAuth = () => {
  return useMutation({
    mutationFn: (values: TPostAppVerification) => put(values),
  });
};

export default usePutSecureAuth;
