import Card from 'components/card';
import Header from './Header';
import TextContent from './TextContent';

function GetStarted() {
  return (
    <Card extra={'pb-8 px-6 pt-6'}>
      <Header>Getting Started</Header>
      <TextContent>
        <p>
          Read our{' '}
          <a
            className="text-decoration-line: underline"
            href="https://docs.engagespot.com/docs/introduction/getting-started"
            target="_blank"
            rel="noreferrer"
          >
            Getting Started
          </a>{' '}
          guide to learn how to add Engagespot to your web or mobile app. If you
          need help, send an email to support@engagespot.co
        </p>
      </TextContent>
    </Card>
  );
}

export default GetStarted;
