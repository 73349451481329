import BottomLogo from '@assets/svg/sidebar/engagespot-logo.webp';

function SidebarBottomLogo() {
  return (
    <div className="flex items-center justify-center gap-3 mb-8">
      <div
        className=""
        style={{ textAlign: 'center', color: '#9f9c9c', fontSize: '11px' }}
      >
        <img src={BottomLogo} className="" width={160} alt="bottom logo" />v
        {__VERSION__} ({__EDITION__ === 'enterprise' ? 'ee' : 'ce'})
      </div>
    </div>
  );
}

export default SidebarBottomLogo;
