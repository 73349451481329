import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

export type PostTemplateChannelOrder = {
  templateId: number;
  contentIds: number[];
};

const post = async (values: PostTemplateChannelOrder) => {
  const { templateId, ...data } = values;

  return axiosInstance.post(
    `${routes['templates']}/${templateId}/contents/order`,
    data,
  );
};

const usePostTemplateChannelOrder = () => {
  return useMutation({
    mutationFn: post,
  });
};

export default usePostTemplateChannelOrder;
