export const webHookDefaultValue = {
  type: 'get',
  headers: [
    {
      key: '',
      value: '',
    },
  ],
  params: [
    {
      key: '',
      value: '',
    },
  ],
  // batching
  batching_type: 'get',
  batching_headers: [
    {
      key: '',
      value: '',
    },
  ],
  batching_params: [
    {
      key: '',
      value: '',
    },
  ],
};
