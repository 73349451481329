import { componentBlockConfig, templateInfo } from 'templates/types';
import { inAppTemplate } from 'views/admin/dashboards/templateEditor/variables/inputs';
import {
  DEFAULT_TEMPLATE_STATE,
  createDefaultInAppConfig,
} from 'templates/utils';

/**
 * used in setting initial inapp api data
 * and also when watching form value changes
 */
const defaultValues = {
  state_1_icon: '',
  state_1_icon_select: 'file',
  state_1_title: 'Notification Title',
  state_1_message: '',
  state_1_url: '',

  // preview
  preview_title: '<b>John Doe</b> liked your profile pic',
};

const info: templateInfo = {
  template: 'default',
  category: 'default',
  tags: ['content'],
};

const stateConfig = [
  {
    key: DEFAULT_TEMPLATE_STATE,
    label: 'State 1',
  },
];

export const consoleConfig = {
  state_1: createDefaultInAppConfig(),
};

const getBlockConfig = (values = defaultValues): componentBlockConfig => {
  return {
    state_1: [],
  };
};

export const defaultTemplate = {
  blockConfig: (values: any) => getBlockConfig(values),
  states: stateConfig,
  consoleInputs: consoleConfig as inAppTemplate,
  defaultValues,
  info,
};
