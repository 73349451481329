import { Button } from 'components/shadcn/button';
import SearchBar from 'components/table/searchbar';
import SearchBarApi, {
  SearchBarApiProps,
} from 'components/table/searchbar-api';
import { CirclePlus } from 'lucide-react';
import { cn } from 'utils/class-merge';

const PageHeader = <T extends 'table' | 'api'>({
  setFilter,
  setPagination,
  filter,
  isFetching,
  paginationType,
  handleCreate,
  buttonText,
  heading,
  text,
  headingClassName,
  textClassName,
  textParentClass,
  parentClass,
  showSearchBar = true,
}: {
  paginationType?: T;
  handleCreate?: () => void;
  buttonText?: string;
  heading: JSX.Element | string;
  text: JSX.Element | string;
  headingClassName?: string;
  textClassName?: string;
  textParentClass?: string;
  parentClass?: string;
  showSearchBar?: boolean;
} & (T extends 'api' ? SearchBarApiProps : Partial<SearchBarApiProps>)) => {
  return (
    <div className={cn('col-span-12', parentClass)}>
      <div className={cn('mb-4 text-gray-220', textParentClass)}>
        <h2
          className={cn(
            'text-2xl mb-1 font-bold tracking-tight',
            headingClassName,
          )}
        >
          {heading}
        </h2>
        <p className={cn('text-muted-foreground', textClassName)}>{text}</p>
      </div>

      <div
        className={cn(
          'flex w-full items-center justify-between',
          !showSearchBar && 'justify-end',
        )}
      >
        {showSearchBar && paginationType === 'api' && (
          <SearchBarApi
            filter={filter}
            setPagination={setPagination}
            setFilter={setFilter}
            isFetching={isFetching}
          />
        )}

        {showSearchBar && paginationType === 'table' && (
          <SearchBar filter={filter} setFilter={setFilter} />
        )}

        {buttonText && (
          <Button
            className="font-bold"
            onClick={() => {
              handleCreate();
            }}
          >
            <CirclePlus className="mr-2 h-4 w-4 font-bold relative bottom-[1px]" />
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default PageHeader;
