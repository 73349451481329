import { useEffect } from 'react';

// timer function
export const useTimer = ({
  timer,
  setTimer,
}: {
  timer: number;
  setTimer: React.Dispatch<React.SetStateAction<number>>;
}) => {
  useEffect(() => {
    let timeId: NodeJS.Timeout;

    if (timer !== 0) {
      timeId = setInterval(() => {
        setTimer(val => val - 1);
      }, 1000);
    }

    if (timer === 0 && timeId!) {
      clearInterval(timeId!);
    }

    return () => {
      clearInterval(timeId!);
    };
  }, [timer, setTimer]);
};
