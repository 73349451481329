import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { useUploadFilesToServerAndGetUrl } from 'api/files/post';
import useGetMe from 'api/profile/get-me';
import usePatchUserDetails from 'api/profile/patch-user-details';
import { routes } from 'api/routes';
import ErrorBox from 'components/error/ErrorBox';
import { CustomModal, CustomModalButtons } from 'components/modal';
import { SubmitHandler, useForm } from 'react-hook-form';
import useCustomToast from 'utils/use-toast';
import { profilePicSchema } from '../schema';

type FormAddProfilePic = {
  file: File[];
};

function ProfilePicModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const patchUserDetails = usePatchUserDetails();
  const uploadFilesToServerAndGetUrl = useUploadFilesToServerAndGetUrl();
  const toast = useCustomToast();
  const getMe = useGetMe();
  const queryClient = useQueryClient();

  const {
    register,
    handleSubmit,
    formState: { errors },
    clearErrors,
    reset,
    watch,
  } = useForm<FormAddProfilePic>({
    resolver: yupResolver(profilePicSchema),
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormAddProfilePic> = values => {
    const file = values.file[0];
    const imageFormData = new FormData();
    imageFormData.append('image', file as Blob);

    uploadFilesToServerAndGetUrl.mutate(imageFormData, {
      onSuccess: response => {
        const uploadedUrl = response.data.url;

        const data = {
          clientId: getMe.data.clientUser.id,
          profilePicture: uploadedUrl,
        };

        return patchUserDetails.mutate(data, {
          onSuccess: () => {
            toast.success('profile picture updated successfully');
            queryClient.invalidateQueries([routes['me']]);
            handleClose();
          },
          onError: error => {
            toast.showError(error);
          },
        });
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  const handleClose = () => {
    clearErrors('file');
    reset({});
    onClose();
  };

  const handleOpenFileSelect = () => {
    document.getElementById('profile_pic_upload').click();
  };

  const watchFile = watch('file');

  return (
    <CustomModal
      modalCardClassName=""
      modalContentClassName="!min-w-[350px]"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mx-auto mb-[20px] text-2xl font-bold">Change Avatar</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          hidden
          id="profile_pic_upload"
          accept="image/*"
          type="file"
          {...register('file')}
        />

        {/* preview */}
        {Boolean(watchFile?.length) ? (
          <img
            onClick={() => handleOpenFileSelect()}
            className="mx-auto h-32 w-32 cursor-pointer rounded-lg"
            src={URL.createObjectURL(watchFile?.[0])}
            alt="preview"
          />
        ) : (
          <button
            onClick={() => handleOpenFileSelect()}
            type="button"
            className="mx-auto grid h-32 w-32 place-content-center rounded-lg border border-night-100 bg-gray-10 text-night-100"
          >
            <div className="">Upload</div>
          </button>
        )}

        <div className="flex w-full justify-center">
          {errors.file && <ErrorBox error={errors.file} />}
        </div>

        <CustomModalButtons
          handleClose={handleClose}
          loading={
            patchUserDetails.isLoading || uploadFilesToServerAndGetUrl.isLoading
          }
          primaryButtonText="Change"
        />
      </form>
    </CustomModal>
  );
}

export default ProfilePicModal;
