import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { Invitation } from './types';
import { routes } from 'api/routes';
import { axiosGlobalInstance } from 'api/axios/global';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<Invitation> => {
  const [, invitationCode] = queryKey;
  const { data } = await axiosGlobalInstance.get(
    `${routes['invitations']}/${invitationCode}`,
  );
  return data;
};

const useGetInvitation = (invitationCode: string, enabled: boolean) => {
  return useQuery({
    queryKey: [routes['invitations'], invitationCode],
    queryFn: get,
    enabled,
  });
};

export default useGetInvitation;
