import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

export type PostChannelRoutes = {
  name: string;
  entryCondition: string;
  providerInstances: {
    instanceId: number | string;
    delay: number | string;
  }[];
  channel?: string;
  appId?: number;
  routeId?: number;
};

const post = async (values: PostChannelRoutes) => {
  const { channel, appId, ...data } = values;
  return axiosInstance.post(
    `${routes['apps']}/${appId}/channels/${channel}/routes`,
    data,
  );
};

const usePostChannelRoutes = () => {
  return useMutation({
    mutationFn: post,
  });
};

export default usePostChannelRoutes;
