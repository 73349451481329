import useGetChannels from 'api/channels/get-channels';
import React, { useEffect } from 'react';
import { Control, FieldError, UseFormTrigger } from 'react-hook-form';
import useWorkflowStore from 'store/workflowStore';
import { getCurrentJsonWithErrors } from './functions';
import { HandleErrorsProps, SetErrorsProps } from './types';

export const useIsDataLoaded = () => {
  const [dataLoaded, setDataLoaded] = React.useState(false);
  return {
    dataLoaded,
    setDataLoaded,
  };
};

export const useGetWorkflowChannels = () => {
  const getChannels = useGetChannels({ staleTime: Infinity });
  const configuredChannels = getChannels.data?.filter(
    channel => channel.configuredProviders?.length > 0,
  );
  const enabledChannels = configuredChannels?.filter(channel =>
    channel.configuredProviders.some(item => item.enabled === true),
  );

  return {
    enabledChannels,
    allChannels: getChannels.data,
    isLoading: getChannels.isLoading,
  };
};

export const useFormValidateOnLoad = <T>({
  dataLoaded,
  trigger,
}: {
  dataLoaded: boolean;
  trigger: UseFormTrigger<T>;
}) => {
  React.useEffect(() => {
    dataLoaded && trigger();
  }, [trigger, dataLoaded]);
};

export function useWatchWorkflowFormErrors<T>({
  control,
  handleErrors,
}: {
  control: Control<T, any>;
  handleErrors?: (props: HandleErrorsProps) => void;
}) {
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    state => state,
  );
  const selectedNodeId = selectedNode?.id;

  const setErrors = React.useCallback(
    ({ errors, stepRef }: SetErrorsProps) => {
      const errorsMap = Object.keys(errors).map(error => {
        const fieldError: FieldError = (errors as any)[error];

        return {
          errorType: fieldError.type,
          message: fieldError.message,
          stepRef: error,
        };
      });

      const stepError = {
        stepRef,
        errors: errorsMap,
      };

      const updatedJsonWithErrors = getCurrentJsonWithErrors({
        workflowJson,
        error: stepError,
      });

      // errors is directly comming from the backend now
      // no need for additional mapping from frontend
      setWorkflowJson(workflowJson);
    },
    [setWorkflowJson, workflowJson],
  );

  useEffect(() => {
    const sub = control._subjects.state.subscribe({
      next: ({ errors }) => {
        if (handleErrors) handleErrors({ errors, setErrors });
        else setErrors({ errors, stepRef: selectedNodeId });
      },
    });

    return sub.unsubscribe;
  }, [control, handleErrors, selectedNodeId, setErrors]);
}

export const useWatchWorkflowForm = <T>({
  control,
  handleChange,
}: {
  control: Control<T, any>;
  handleChange: () => void;
}) => {
  useEffect(() => {
    const sub = control._subjects.values.subscribe({
      next: () => {
        handleChange();
      },
    });

    return sub.unsubscribe;
  }, [control, handleChange]);
};
