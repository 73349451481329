import useGetChannels from 'api/channels/get-channels';
import {
  useEditWorkflowJson,
  useGetVersionedWorkflowJson,
  useGetWorkflowJson,
} from 'api/workflows';
import React, { DragEvent, DragEventHandler } from 'react';
import { useParams } from 'react-router-dom';
import {
  Background,
  BackgroundVariant,
  Controls,
  MarkerType,
  ReactFlow,
  ReactFlowInstance,
  ReactFlowProvider,
  SelectionMode,
} from 'reactflow';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import WorkflowHeader from './components/header/WorkflowHeader';
import WorkflowSidebar from './components/sidebar/WorkflowSidebar';
import {
  createNewConnections,
  createNodesAndEdgesFromJsonData,
  getKeyMapIdFromWorkflowJson,
  handleDeselectAndSave,
  handleJsonDiffCheck,
  handleReactFlowFitView,
} from './functions';
import { WorkflowJson } from './types';
import {
  edgeTypes,
  nodeTypes,
  proOptions,
  WORKFLOW_INITIAL_VERSION,
} from './variables';

const WorkflowEditor = ({ preview = false }: { preview?: boolean }) => {
  const params = useParams();
  const workflowId = params.workflowId;
  const [reactFlowInstance, setReactFlowInstance] =
    React.useState<ReactFlowInstance<any, any>>(null);
  const editWorkflowJson = useEditWorkflowJson();
  const {
    nodes,
    edges,
    setDraggedType,
    onEdgesChange,
    onNodesChange,
    reOrderedNode,
    draggedType,
    setReOrderedNode,
    selectedNode,
    setWorkflowJson,
    workflowJson,
    setIdKeyMap,
    layoutMode,
    setPublishedWorkflowJson: setPublishedWorkflow,
  } = useWorkflowStore(useShallow(state => state));

  const getWorkflowJson = useGetWorkflowJson(Number(workflowId));
  const lastVersionLength = getWorkflowJson.data?.versions?.length;
  const lastPublishedVersionId =
    getWorkflowJson.data?.versions?.[lastVersionLength - 1]?.id;
  const getVersionedWorkflowJson = useGetVersionedWorkflowJson(
    Number(workflowId),
    lastPublishedVersionId,
  );
  const getChannels = useGetChannels();

  React.useEffect(() => {
    if (getWorkflowJson.isLoading) return;
    if (getChannels.isLoading) return;

    const workflowJsonSpec = getWorkflowJson.data?.jsonSpec;
    const updatedWorkflowJson: WorkflowJson = {
      name: workflowJsonSpec?.name || getWorkflowJson.data?.name,
      steps: workflowJsonSpec?.steps || [],
      errors: workflowJsonSpec?.errors || [],
      _version: workflowJsonSpec?._version || WORKFLOW_INITIAL_VERSION,
    };

    const publishedWorkflowJsonSpec = getVersionedWorkflowJson.data;
    const publishedWorkflowJson: WorkflowJson = publishedWorkflowJsonSpec
      ? {
          name: publishedWorkflowJsonSpec?.name || updatedWorkflowJson.name,
          steps: publishedWorkflowJsonSpec?.steps || [],
          errors: publishedWorkflowJsonSpec?.errors || [],
          _version:
            publishedWorkflowJsonSpec?._version || WORKFLOW_INITIAL_VERSION,
        }
      : {
          name: updatedWorkflowJson.name,
          steps: [] as WorkflowJson['steps'],
          errors: [] as WorkflowJson['errors'],
          _version: updatedWorkflowJson?._version,
        };

    const updatedKeyMapId = getKeyMapIdFromWorkflowJson({
      steps: updatedWorkflowJson.steps,
    });

    setPublishedWorkflow(publishedWorkflowJson);
    setWorkflowJson(updatedWorkflowJson);
    handleJsonDiffCheck({
      workflowJson: updatedWorkflowJson,
    });
    createNodesAndEdgesFromJsonData({
      json: updatedWorkflowJson,
    });
    setIdKeyMap(updatedKeyMapId);

    if (layoutMode === 'automatic') {
      handleReactFlowFitView({
        reactFlowInstance,
        fitDuration: 1000,
        timeoutDuration: 100,
      });
    }
  }, [
    getChannels?.isLoading,
    getWorkflowJson?.isLoading,
    getWorkflowJson.data,
    getVersionedWorkflowJson.data,
    setWorkflowJson,
    setIdKeyMap,
    setPublishedWorkflow,
    reactFlowInstance,
    getChannels.data,
    layoutMode,
  ]);

  const onDragOver = React.useCallback((event: any) => {
    event.preventDefault();
  }, []);

  const handleDragEnd = () => {
    setDraggedType(null);
    setReOrderedNode(null);

    const dragImage = document.querySelector<HTMLElement>(
      '[data-mirror_drag="true"]',
    );

    if (dragImage) {
      dragImage.style.display = 'none';

      if (dragImage.parentElement) {
        dragImage.parentElement.removeChild(dragImage);
      }
    }
  };

  const panOnDrag = [1, 2];

  const onDrop: DragEventHandler = (evt: DragEvent<HTMLDivElement>) => {
    if (evt.target instanceof SVGElement && draggedType) {
      const selectedDraggedType = draggedType;
      const selectedReOrderedNode = reOrderedNode;
      const isBranchNode = selectedReOrderedNode?.type === 'branch';

      handleDragEnd();

      let { source, target } = evt.target.dataset;

      if (isBranchNode) {
        source = selectedReOrderedNode.data?.branchSourceId;
        target = selectedReOrderedNode.data?.branchTargetId;
      }

      if (source && target) {
        createNewConnections({
          source,
          target,
          draggedType: selectedDraggedType,
          reOrderedNode: selectedReOrderedNode,
          workflowJson,
          workflowId: Number(workflowId),
          editWorkflowJson,
          reactFlowInstance,
        });
      }
    }
  };

  return (
    <ReactFlowProvider>
      <div>
        {!preview && <WorkflowHeader isLoading={editWorkflowJson.isLoading} />}

        <div className="flex">
          <div
            style={{
              width: preview ? '100%' : '73%',
              height: preview ? '40vh' : `calc(100vh - 4rem)`,
            }}
            onDragOver={onDragOver}
          >
            <ReactFlow
              nodes={getWorkflowJson.isLoading ? [] : nodes}
              edges={edges}
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              edgeTypes={edgeTypes}
              nodeTypes={nodeTypes}
              fitView={true}
              proOptions={proOptions}
              nodesDraggable={false}
              panOnScroll={true}
              panOnDrag={panOnDrag}
              selectionOnDrag={true}
              nodesConnectable={false}
              zoomOnDoubleClick={false}
              selectionMode={SelectionMode.Partial}
              onDrop={onDrop}
              onInit={reactFlowInstance =>
                setReactFlowInstance(reactFlowInstance)
              }
              minZoom={1}
              maxZoom={2}
              fitViewOptions={{
                minZoom: 1,
                maxZoom: 2,
              }}
              defaultEdgeOptions={{
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                },
                style: {
                  strokeWidth: 1,
                },
                animated: true,
              }}
              onPaneClick={e => {
                e.persist();

                if (selectedNode) {
                  handleDeselectAndSave({
                    workflowId: Number(workflowId),
                    editWorkflowJson,
                    workflowJson,
                  });
                }
              }}
              deleteKeyCode={null}
            >
              <Background
                color="#3A3F47"
                variant={BackgroundVariant.Dots}
                gap={40}
              />
              <Controls position={'top-right'} showInteractive={false} />
            </ReactFlow>
          </div>

          {/* sidebar */}
          {!preview && <WorkflowSidebar handleDragEnd={handleDragEnd} />}
        </div>
      </div>
    </ReactFlowProvider>
  );
};

export default WorkflowEditor;
