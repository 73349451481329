import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { PaymentMethods } from './types';
import { axiosLicensingInstance } from 'api/axios/licensing';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, string]>): Promise<PaymentMethods> => {
  const [, clientId] = queryKey;

  const { data } = await axiosLicensingInstance.get(
    `clients/${clientId}/paymentMethods`,
  );

  return data;
};

const useGetPaymentMethods = (clientId: string) => {
  return useQuery({
    queryKey: [`${routes['clients']}/paymentMethods`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
    staleTime: 5 * 1000,
  });
};

export default useGetPaymentMethods;
