import { yupResolver } from '@hookform/resolvers/yup';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import {
  convertMillisecondsToTime,
  convertTimeToMilliseconds,
  findSelectedNodeFromJson,
  handleJsonDiffCheck,
  handleTimeExceedsErrors,
  isInteger,
} from '../../functions';
import {
  useFormValidateOnLoad,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import { timeSchema } from '../../schemas';
import WorkflowIntervalInputs from '../inputs/WorkflowIntervalInputs';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { WorkflowFormHeader } from './WorkflowFormHeader';
import SelectField from 'components/fields/SelectField';
import TextField from 'components/fields/TextField';
import ErrorBox from 'components/error/ErrorBox';

type DelayType = 'static' | 'dynamic';

type FormWorkflowDelay = {
  days?: string;
  hours?: string;
  mins?: string;
  secs?: string;
  triggerCondition?: string;
  delayType: DelayType;
  delayFor?: string;
};

const WorkflowDelayForm = () => {
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );

  const formMethods = useForm<FormWorkflowDelay>({
    mode: 'onChange',
    resolver: yupResolver(timeSchema),
  });

  const {
    setValue,
    reset,
    getValues,
    trigger,
    control,
    register,
    formState: { errors },
  } = formMethods;

  const delayType = useWatch({
    control,
    name: 'delayType',
  });

  const handleChange = () => {
    const selectedNodeId = selectedNode?.id;
    const dataValues = getValues();
    const { days, hours, mins, secs, triggerCondition, delayFor } = dataValues;

    const calculatedDelayFor = convertTimeToMilliseconds({
      days,
      hours,
      mins,
      secs,
    });

    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      selected.triggerCondition = triggerCondition;

      selected.config = {
        ...selected.config,
        delayFor:
          delayType === 'dynamic'
            ? String(delayFor).trim()
            : String(calculatedDelayFor),
      };
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // default value of delayType
  React.useEffect(() => {
    setValue('delayType', 'static', {
      shouldValidate: true,
    });
  }, [setValue]);

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode?.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });

    const selectedConfig = selectedNodeJson?.config;

    const { days, hours, mins, secs } = convertMillisecondsToTime(
      selectedConfig?.delayFor,
    );

    reset({
      days: String(days),
      hours: String(hours),
      mins: String(mins),
      secs: String(secs),
      triggerCondition: selectedNodeJson?.triggerCondition,
      delayType: isInteger(selectedConfig?.delayFor) ? 'static' : 'dynamic',
      delayFor: selectedConfig?.delayFor,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useFormValidateOnLoad({
    dataLoaded,
    trigger,
  });

  useWatchWorkflowFormErrors({
    control,
    handleErrors: ({ setErrors, errors }) =>
      handleTimeExceedsErrors({
        setErrors,
        errors,
        dataValues: getValues(),
      }),
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  return (
    <div>
      <WorkflowFormHeader heading="Delay" docKey="delay" />

      <div className="text-[#ABB0B8] font-medium mb-3">
        Set a duration for this delay function.
      </div>

      <FormProvider {...formMethods}>
        <form className="mt-6 flex flex-col gap-6">
          <SelectField<FormWorkflowDelay>
            variant="styled"
            control={control}
            extra="w-full"
            label={'Delay Type'}
            placeholder={'Delay Type'}
            name={'delayType'}
            styleVariant="workflow"
            options={[
              {
                value: 'static',
                label: 'Static',
              },
              {
                value: 'dynamic',
                label: 'Dynamic',
              },
            ]}
            onChange={(value: DelayType) => {
              setValue('delayType', value);

              if (value === 'static') {
                setValue('secs', '5');
                setValue('mins', '0');
                setValue('days', '0');
                setValue('hours', '0');
              }

              if (value === 'dynamic') {
                setValue('delayFor', '');
              }
            }}
          />

          {!delayType || delayType === 'static' ? (
            <div>
              <WorkflowIntervalInputs
                name={'days'}
                handleChange={handleChange}
                setValue={setValue}
                inputLabel={'Interval'}
                blockHeading="Delay type"
                blockText="days"
              />

              <WorkflowIntervalInputs
                name={'hours'}
                handleChange={handleChange}
                setValue={setValue}
                blockText="hours"
              />

              <WorkflowIntervalInputs
                name={'mins'}
                handleChange={handleChange}
                setValue={setValue}
                blockText="minutes"
              />

              <WorkflowIntervalInputs
                name={'secs'}
                handleChange={handleChange}
                setValue={setValue}
                blockText="seconds"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-2">
              <TextField<FormWorkflowDelay>
                extraInputClass={
                  'border dark:bg-night-100 focus:border border-[#525151]'
                }
                label={'Interval'}
                placeholder={'Sample javascript expression'}
                register={register}
                name={'delayFor'}
                extraLabelClass={'font-medium'}
                variant="workflow"
              />

              {errors.delayFor && <ErrorBox error={errors.delayFor} />}
            </div>
          )}

          <WorkflowConditionForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default WorkflowDelayForm;
