import { QueryFunctionContext, useQuery } from '@tanstack/react-query';
import { GetClientInvitations } from './types';
import { routes } from 'api/routes';
import { axiosGlobalInstance } from 'api/axios/global';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetClientInvitations[]> => {
  const [, clientId] = queryKey;
  const { data } = await axiosGlobalInstance.get(
    `${routes['clients']}/${clientId}/clientUserInvitations`,
  );
  return data;
};

const useGetClientInvitations = (clientId: number) => {
  return useQuery({
    queryKey: [`${routes['clients']}/clientUserInvitations`, clientId],
    queryFn: get,
    enabled: Boolean(clientId),
  });
};

export default useGetClientInvitations;
