import { Table as TTable, flexRender } from '@tanstack/react-table';
import { cn } from 'utils/class-merge';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/shadcn/table';

function CustomTableBody({
  table,
  extraTdClass,
  rowClick,
  emptyAdditionalJsx,
  emptyTableMessageClassName,
  loading,
}: {
  rowClick?: (prop: any) => void;
  table: TTable<any>;
  extraTdClass?: string;
  emptyAdditionalJsx?: () => JSX.Element;
  emptyTableMessageClassName?: string;
  loading?: boolean;
}) {
  return (
    <div className="rounded-md border">
      <Table className="max-h-[460px]">
        <TableHeader>
          {table.getHeaderGroups().map(headerGroup => (
            <TableRow className={'hover:bg-background'} key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map(row => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                onClick={() => {
                  rowClick?.(row);
                }}
              >
                {row.getVisibleCells().map(cell => (
                  <TableCell
                    className={cn(loading && 'py-4', extraTdClass)}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow className="hover:bg-background">
              <TableCell
                colSpan={5}
                className={cn(
                  'h-[50vh] text-center text-white w-full',
                  emptyTableMessageClassName,
                )}
              >
                {emptyAdditionalJsx?.()}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}

export default CustomTableBody;
