import SelectField from 'components/fields/SelectField';
import { CustomHoverCard } from 'components/shadcn/hover-card';
import { Control } from 'react-hook-form';
import { PiWarningCircleBold } from 'react-icons/pi';
import { cn } from 'utils/class-merge';
import { REGIONS } from 'variables/regions';

export const RegionSelect = ({
  control,
  labelClassName,
}: {
  control: Control<any, any>;
  labelClassName?: string;
}) => {
  const regionOptions = [
    {
      label: 'US',
      value: REGIONS['US_WEST_2'],
    },

    {
      label: 'EU',
      value: REGIONS['EU_CENTRAL_1'],
    },
  ];

  return (
    <SelectField
      variant="styled"
      extra=""
      placeholder=""
      showIsRequiredAsterisk={false}
      control={control}
      name={'region'}
      extraInputClass="border border-gray-440 !h-[48px] !rounded-md !bg-night-400 text-white !text-base  outline-none"
      extraItemClass={'!text-base'}
      options={regionOptions}
      customLabelContent={() => (
        <div className="flex items-center gap-2 mb-2">
          <label className={cn(labelClassName)}>{'Data center'}</label>

          <CustomHoverCard
            content={
              'You can choose to store your account data in either the EU or the US. All of your data will be securely stored in the location you select.'
            }
          >
            <PiWarningCircleBold
              size={14}
              color="gray"
              className="relative cursor-pointer"
            />
          </CustomHoverCard>
        </div>
      )}
    />
  );
};
