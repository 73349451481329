import { useDisclosure } from '@chakra-ui/hooks';
import useGetLicense from 'api/billing/get-license';
import useGetSubscriptionDetails from 'api/billing/get-subscription-details';
import useGetPlanUsage from 'api/usuage/get-plan-usage';
import SubIcon1 from 'assets/svg/billing/sub-icon-1.svg';
import SubIcon2 from 'assets/svg/billing/sub-icon-2.svg';
import SubIcon3 from 'assets/svg/billing/sub-icon-3.svg';
import Card from 'components/card';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useAuthStore } from 'store/authStore';
import { getCurrencySymbol } from 'utils/get-currency';
import { isEnterpriseEdition } from 'utils/get-editions';
import { StripePayment, StripePromise } from '../types';
import CancelPlanModal from './CancelPlanModal';
import Header from './Header';
import DiscountModal from './Pricing/DiscountModal';
import PricingModal from './Pricing/modal';
import StopCancelationModal from './StopCancelationModal';
import StripePaymentModal from './StripePaymentModal';
import useGetUpcomingInvoices from 'api/billing/get-upcoming-invoices';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';

const Card1Additional = ({
  onUpgrade,
  onCancel,
  cancellationDate,
  onResubscribe,
  isPaidPlan,
}: {
  onUpgrade: () => void;
  onCancel: () => void;
  onResubscribe: () => void;
  cancellationDate: string | null;
  isPaidPlan: boolean;
}) => {
  return (
    <div className="flex w-full gap-2">
      <button
        className={cn(
          buttonVariants({
            size: 'default',
            className: 'w-full rounded-md font-semibold text-base',
          }),
        )}
        onClick={cancellationDate ? onResubscribe : onUpgrade}
      >
        {cancellationDate ? 'Resubscribe' : 'Upgrade '}
      </button>

      {isPaidPlan && (
        <button
          className="linear flex w-full justify-center rounded-xl border border-white/40 bg-night-100 py-2 text-base font-medium text-white transition duration-200  dark:text-white "
          type="button"
          onClick={cancellationDate ? onUpgrade : onCancel}
        >
          {cancellationDate ? 'Upgrade' : 'Cancel'}
        </button>
      )}
    </div>
  );
};

const Card2Additional = () => {
  return (
    <div>
      <button className="font-bold text-white underline">View Usage</button>
    </div>
  );
};

const Card3Additional = ({ date }: { date: string }) => {
  if (!date) return null;

  const dateString = format(parseISO(date), 'MMMM d, yyyy');

  return (
    <div className="text-sm text-[#8C93A0]">
      Payment on <span className="font-bold text-white">{dateString}</span>
    </div>
  );
};

const SubCard = ({
  bodyContainerClass: bodyContainerFlexDirection = 'flex-col',
  heading,
  subHeading,
  text,
  icon,
  additonalJsx,
  cardClass,
  subHeadingExtraClass = '',
  headingExtraClass = '',
}: {
  heading: string;
  subHeading: string;
  text: (() => JSX.Element) | string;
  bodyContainerClass?: string;
  icon: string;
  additonalJsx: () => JSX.Element;
  cardClass: string;
  subHeadingExtraClass?: string;
  headingExtraClass?: string;
}) => {
  return (
    <div
      className={`relative flex  justify-between rounded-[20px] bg-night-100 p-9 pb-8 ${cardClass}`}
    >
      <div className="w-full">
        <h3 className={`mb-1 text-[1.97rem] font-bold ${headingExtraClass}`}>
          {heading}
        </h3>
        <div className={`flex ${bodyContainerFlexDirection}`}>
          <p className={`mb-3 text-lg font-bold ${subHeadingExtraClass}`}>
            {subHeading}
          </p>
          <p className="mb-5 capitalize text-sm text-[#8C93A0]">
            {typeof text === 'string' ? text : text()}
          </p>
        </div>
        {additonalJsx()}
      </div>
    </div>
  );
};

function SubscriptionDetails({ promise }: { promise: StripePromise }) {
  const pricingModalActions = useDisclosure();
  const cancelPlanModalActions = useDisclosure();
  const stopCancelationModalActions = useDisclosure();
  const { clientId: clientUserId } = useAuthStore(state => state);
  const enterpriseEdition = isEnterpriseEdition();
  const license = useGetLicense(clientUserId);
  const getSubscriptionDetails = useGetSubscriptionDetails(
    license.data?.InstanceClientIdentifer,
    enterpriseEdition,
  );
  const currentPlan = getSubscriptionDetails.data;
  const currencySymbol = getCurrencySymbol();
  const cancellationEffectiveDate =
    getSubscriptionDetails.data?.cancellationEffectiveDate;
  const cancellationDate = cancellationEffectiveDate
    ? format(parseISO(cancellationEffectiveDate), 'd.MM.yyyy')
    : null;
  const [paymentDetails, setPaymentDetails] = React.useState<StripePayment>();
  const paymentModalActions = useDisclosure();
  const planUsage = useGetPlanUsage();
  const isPaidPlan = planUsage?.data?.licenseSummary?.plan !== 'free';
  const upcomingInvoices = useGetUpcomingInvoices(
    license.data?.InstanceClientIdentifer,
    enterpriseEdition,
  );
  const amountDue = upcomingInvoices.data?.amount_due;

  return (
    <>
      <PricingModal
        isOpen={pricingModalActions.isOpen}
        onClose={pricingModalActions.onClose}
      />

      <StripePaymentModal
        isOpen={paymentModalActions.isOpen}
        onClose={paymentModalActions.onClose}
        paymentDetails={paymentDetails}
        promise={promise}
      />

      <DiscountModal
        stripeModalOpen={paymentModalActions.onOpen}
        setPaymentDetails={setPaymentDetails}
        paymentDetails={paymentDetails}
      />

      <CancelPlanModal
        isOpen={cancelPlanModalActions.isOpen}
        onClose={cancelPlanModalActions.onClose}
      />

      <StopCancelationModal
        isOpen={stopCancelationModalActions.isOpen}
        onClose={stopCancelationModalActions.onClose}
      />

      <Card extra={'pb-8 px-6 pt-6'}>
        <Header>Subscription</Header>

        <div className="flex w-full justify-between gap-7">
          <SubCard
            heading={`${currencySymbol}${Math.round(
              Number(currentPlan?.amount),
            )}/month`}
            subHeading={
              cancellationDate
                ? `Current Subscription Plan will be cancelled on ${cancellationDate}`
                : 'Current Subscription Plan'
            }
            subHeadingExtraClass={cancellationDate ? 'text-[#EE5353]' : ''}
            text={currentPlan.planName ?? ''}
            icon={SubIcon1}
            additonalJsx={() =>
              Card1Additional({
                onUpgrade: () => {
                  setPaymentDetails(null);
                  pricingModalActions.onOpen();
                },
                onCancel: cancelPlanModalActions.onOpen,
                cancellationDate,
                onResubscribe: stopCancelationModalActions.onOpen,
                isPaidPlan: isPaidPlan,
              })
            }
            cardClass="w-[39%] border border-input"
          />

          {isPaidPlan && (
            <SubCard
              heading={`${currencySymbol}${currentPlan?.billedOverages}`}
              headingExtraClass="text-[#DA9227]"
              subHeadingExtraClass="text-[#DA9227]"
              subHeading="Overage Charges"
              text={`${currentPlan?.freePlanOverLimitWarningsSent} Extra notification Used.For ever 1k notification charged $2.0`}
              icon={SubIcon2}
              additonalJsx={Card2Additional}
              cardClass="w-[28%]"
            />
          )}

          {isPaidPlan && !cancellationDate && (
            <SubCard
              heading={`${currencySymbol} ${Number(amountDue) / 100}`}
              subHeading="Upcoming Payment"
              text={() => (
                <div>
                  monthly based price +{' '}
                  <span className="text-[#DA9227]">overage charges</span>
                </div>
              )}
              icon={SubIcon3}
              additonalJsx={() =>
                Card3Additional({ date: currentPlan?.nextBillingOn })
              }
              cardClass="w-[33%]"
              bodyContainerClass="flex-col-reverse"
            />
          )}
        </div>
      </Card>
    </>
  );
}

export default SubscriptionDetails;
