import usePutOrDeleteTemplates from 'api/templates/put-delete-templates';
import { CustomDeleteModal } from 'components/modal';
import { useTemplateStore } from 'store/templateStore';
import useCustomToast from 'utils/use-toast';

function DeleteTemplateModal({
  isOpen,
  onClose,
}: {
  onClose: () => void;
  isOpen: boolean;
}) {
  const { template } = useTemplateStore(state => state);
  const deleteAddTemplates = usePutOrDeleteTemplates();
  const toast = useCustomToast();

  const handleSubmit = () => {
    deleteAddTemplates.mutate(
      {
        type: 'delete',
        templateId: template.id,
      },
      {
        onSuccess: () => {
          toast.success('template removed successfully');
          onClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  return (
    <CustomDeleteModal
      isOpen={isOpen}
      onClose={onClose}
      loading={deleteAddTemplates.isLoading}
      handleSubmit={handleSubmit}
      heading="Remove Template"
      text={'Are you sure to remove this template?'}
    />
  );
}

export default DeleteTemplateModal;
