import { PaginationState } from '@tanstack/react-table';
import { GetUserResult } from 'api/users/get-users/types';
import CopyCell from 'components/copy/CopyCell';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import TableMenu from 'components/table/table-menu';
import { format, parseISO } from 'date-fns';
import { Users } from 'lucide-react';
import React from 'react';

function UsersTable(props: {
  tableData: any;
  detailsModalOpen: () => void;
  preferenceModalOpen: () => void;
  setSelectedUser: React.Dispatch<React.SetStateAction<GetUserResult>>;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  editModalOpen: () => void;
  loading?: boolean;
  searchInput?: string;
  totalPages: number;
}) {
  const {
    tableData,
    detailsModalOpen,
    preferenceModalOpen,
    setSelectedUser,
    pagination,
    setPagination,
    editModalOpen,
    loading,
    searchInput,
    totalPages,
  } = props;

  const handleAddUser = () => {
    editModalOpen();
  };

  const handleViewUserDetails = (
    item: GetUserResult,
    type: 'show_details' | 'edit' | 'show_preference',
  ) => {
    if (type === 'show_details') {
      detailsModalOpen();
    }

    if (type === 'show_preference') {
      preferenceModalOpen();
    }

    if (type === 'edit') {
      editModalOpen();
    }

    setSelectedUser(item);
  };

  const columnConfig: ColumnConfig<GetUserResult> = [
    {
      accessorKey: 'identifier',
      header: () => 'Identifier',
      cellFunction: info => <CopyCell value={info.getValue()} />,
    },
    {
      accessorKey: 'id',
      header: 'Email',
      cellFunction: info => {
        const profileDatas = info.row?.original?.profileDatas;
        const email = profileDatas.find?.(
          data => data.attribute === 'email',
        )?.value;

        return Boolean(email) ? email : '-';
      },
    },
    {
      accessorKey: 'lastActivityAt',
      header: 'Phone number',
      cellFunction: info => {
        const profileDatas = info.row?.original?.profileDatas;
        const phone = profileDatas.find?.(
          data => data.attribute === 'phoneNumber',
        )?.value;

        return Boolean(phone) ? phone : '-';
      },
    },
    {
      accessorKey: 'createdAt',
      header: 'Created at',
      cellFunction: info => {
        const date = format(parseISO(info.getValue()), 'd MMMM yyyy, h:mm aaa');
        return date;
      },
    },
    {
      accessorKey: 'siteId',
      cellFunction: info => {
        return (
          <TableMenu
            config={[
              {
                text: 'Edit',
                action: () => handleViewUserDetails(info.row.original, 'edit'),
              },
              {
                text: 'Show Details',
                action: () =>
                  handleViewUserDetails(info.row.original, 'show_details'),
              },
              {
                text: 'Show Preference',
                action: () =>
                  handleViewUserDetails(info.row.original, 'show_preference'),
              },
            ]}
          />
        );
      },
    },
  ];

  const columns = columnGenerator<GetUserResult>({ columnConfig });

  return (
    <CustomTable
      emptyTableMessage="No recipients yet! Add users to start sending notifications."
      loading={loading}
      tableData={tableData}
      columns={columns}
      pagination={pagination}
      setPagination={setPagination}
      totalPages={totalPages}
      paginationType="api"
      emptyAdditionalJsx={() => (
        <EmptyTableMessage
          handleCreate={handleAddUser}
          searchInput={searchInput}
          docsLink={
            'https://docs.engagespot.com/docs/features/user/who-is-user'
          }
          heading={"You haven't created any users yet"}
          message={
            'A user is the recipient who can receive notifications from your app. You can create a user in Engagespot using our API, front-end in-app library, or manually.'
          }
          searchEmptyHeading={getSearchEmptyHeading('users')}
          searchEmptyMessage={getSearchEmptyMessage('users')}
          Icon={Users}
        />
      )}
    />
  );
}

export default UsersTable;
