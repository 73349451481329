import axios from 'axios';
import { API_LICENSING_URL } from 'config';
import {
  axiosLicenseRequestInterceptor,
  axiosResponseErrorInterceptor,
} from './common';

const baseURL = API_LICENSING_URL;

export const axiosLicensingInstance = axios.create({
  baseURL,
});

axiosLicensingInstance.interceptors.request.use(
  axiosLicenseRequestInterceptor,
  err => Promise.reject(err),
);

axiosLicensingInstance.interceptors.response.use(
  response => response,
  axiosResponseErrorInterceptor,
);
