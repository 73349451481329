import { useEditWorkflowJson } from 'api/workflows';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useWorkflowStore from 'store/workflowStore';
import { useShallow } from 'zustand/react/shallow';
import {
  findSelectedNodeFromJson,
  handleJsonDiffCheck,
  handleNavigateToChannelEditor,
} from '../../functions';
import {
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { WorkflowFormHeader } from './WorkflowFormHeader';

type FormWorkflowFetch = {
  triggerCondition?: string;
};

const WorkflowFetchForm = () => {
  const params = useParams();
  const workflowId = Number(params.workflowId);
  const navigate = useNavigate();
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );
  const formMethods = useForm<FormWorkflowFetch>({
    mode: 'onChange',
  });
  const { reset, getValues, control } = formMethods;
  const editWorkflowJson = useEditWorkflowJson();

  const handleChange = () => {
    const { triggerCondition } = getValues();
    const selectedNodeId = selectedNode?.id;
    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      selected.triggerCondition = triggerCondition;
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });

    reset({
      triggerCondition: selectedNodeJson.triggerCondition,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useWatchWorkflowFormErrors({
    control,
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  return (
    <div>
      <WorkflowFormHeader heading="API Request" docKey="fetch" />

      <div className="text-[#ABB0B8] font-medium mb-3">
        Make an HTTP request to any endpoint.
      </div>

      <>
        <button
          onClick={() =>
            handleNavigateToChannelEditor({
              selectedNodeId: selectedNode.id,
              workflowId,
              navigate,
              editWorkflowJson,
            })
          }
          className="border w-fit border-gray-440  h-12 mt-2 rounded-md p-3 px-6 text-white"
        >
          Edit Request
        </button>

        <FormProvider {...formMethods}>
          <form className="mt-6">
            <WorkflowConditionForm />
          </form>
        </FormProvider>
      </>
    </div>
  );
};

export default WorkflowFetchForm;
