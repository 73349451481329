import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { Channels } from 'api/channels/get-channels/types';

type PostAi = {
  channel: Channels;
  prompt: string;
};

const post = async (values: PostAi) => {
  return axiosGlobalInstance.post(`/v1/ai`, values);
};

const usePostAi = () => {
  return useMutation({
    mutationFn: (values: PostAi) => post(values),
  });
};

export default usePostAi;
