import useGetProviders from 'api/providers/get-providers';
import useSaveProvider, { SaveProvider } from 'api/providers/save-provider';
import React from 'react';
import { getInstanceName } from '../providers/functions';
import { useAuthStore } from 'store/authStore';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { isEnterpriseEdition } from 'utils/get-editions';
import useGetUsers from 'api/users/get-users';
import { usePaginationApiState } from 'components/table/pagination-state';
import usePutAddUsers from 'api/users/put-add-users';
import useGetMe from 'api/profile/get-me';

export const useSetEmailDefaultProvider = () => {
  const enterpriseEdition = isEnterpriseEdition();
  const { appId } = useAuthStore(state => state);
  const emailProviders = useGetProviders(
    'email',
    enterpriseEdition ? true : false,
  );
  const saveProvider = useSaveProvider();
  const [emailDefaultProviderAdded, setEmailDefaultProviderAdded] =
    React.useState(false);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    // default email provider is only available on enterprise edition
    if (!enterpriseEdition) return;

    const configuredEmailProviders = emailProviders.data?.configuredProviders;
    const availableEmailProviders = emailProviders.data?.availableProviders;
    const emailDefaultProviderIsConfigured = configuredEmailProviders?.some(
      provider => provider?.provider?.identifier?.includes('default'),
    );

    if (
      !emailDefaultProviderIsConfigured &&
      !emailDefaultProviderAdded &&
      !saveProvider.isLoading &&
      !emailProviders.isLoading &&
      !emailProviders.isFetching
    ) {
      const channel = 'email';
      const defaultEmailProvider = availableEmailProviders?.find(provider =>
        provider.identifier.includes('default'),
      );

      if (defaultEmailProvider) {
        const providerInstanceName = getInstanceName({
          name: defaultEmailProvider?.name,
        });

        const submitData: SaveProvider = {
          data: {
            instanceName: providerInstanceName,
            providerIdentifier: defaultEmailProvider?.identifier,
            config: {},
          },
          channel,
          appId,
          type: 'post',
        };

        saveProvider.mutate(submitData, {
          onSuccess: () => {
            setEmailDefaultProviderAdded(true);
            queryClient.invalidateQueries([
              `${routes['apps']}/providers`,
              appId,
              channel,
            ]);
          },
        });
      }
    }
  }, [
    enterpriseEdition,
    appId,
    emailDefaultProviderAdded,
    emailProviders.data,
    emailProviders.isLoading,
    emailProviders.isFetching,
    queryClient,
    saveProvider,
  ]);
};

export const useSetSmsDefaultProvider = () => {
  const enterpriseEdition = isEnterpriseEdition();
  const smsProviders = useGetProviders('sms', enterpriseEdition ? true : false);
  const [smsDefaultProviderAdded, setSmsDefaultProviderAdded] =
    React.useState(false);
  const { appId } = useAuthStore(state => state);
  const saveProvider = useSaveProvider();
  const queryClient = useQueryClient();

  React.useEffect(() => {
    // default sms provider is only available on enterprise edition
    if (!enterpriseEdition) return;

    const configuredSmsProviders = smsProviders.data?.configuredProviders;
    const availableSmsProviders = smsProviders.data?.availableProviders;
    const smsDefaultProviderIsConfigured = configuredSmsProviders?.some(
      provider => provider?.provider?.identifier?.includes('default'),
    );

    if (
      !smsDefaultProviderIsConfigured &&
      !smsDefaultProviderAdded &&
      !saveProvider.isLoading &&
      !smsProviders.isLoading &&
      !smsProviders.isFetching
    ) {
      const channel = 'sms';
      const defaultSmsProvider = availableSmsProviders?.find(provider =>
        provider.identifier.includes('default'),
      );

      if (defaultSmsProvider) {
        const providerInstanceName = getInstanceName({
          name: defaultSmsProvider?.name,
        });

        const submitData: SaveProvider = {
          data: {
            instanceName: providerInstanceName,
            providerIdentifier: defaultSmsProvider?.identifier,
            config: {},
          },
          channel,
          appId,
          type: 'post',
        };

        saveProvider.mutate(submitData, {
          onSuccess: () => {
            setSmsDefaultProviderAdded(true);
            queryClient.invalidateQueries([
              `${routes['apps']}/providers`,
              appId,
              channel,
            ]);
          },
        });
      }
    }
  }, [
    enterpriseEdition,
    appId,
    saveProvider,
    smsDefaultProviderAdded,
    smsProviders.data,
    smsProviders.isLoading,
    smsProviders.isFetching,
    queryClient,
  ]);
};

export const useSetDefaultUser = () => {
  const { pagination } = usePaginationApiState();
  const getUsers = useGetUsers({
    ...pagination,
    filter: '',
    staleTime: Infinity,
  });
  const { appId } = useAuthStore(state => state);
  const noUsers = getUsers?.data?.total === 0;
  const putAddUsers = usePutAddUsers();
  const getMe = useGetMe();

  React.useEffect(() => {
    if (
      !getUsers.isLoading &&
      !getUsers.isFetching &&
      !putAddUsers.isLoading &&
      noUsers
    ) {
      const profile = {
        identifier: getMe.data?.clientUser?.email,
        name: getMe.data?.clientUser?.firstName,
        phoneNumber: getMe.data?.clientUser?.phone,
        email: getMe.data?.clientUser?.email,
        appId,
      };

      putAddUsers.mutate(profile);
    }
  }, [
    appId,
    getMe.data,
    getUsers.isFetching,
    getUsers.isLoading,
    noUsers,
    putAddUsers,
  ]);
};
