import { useAuthStore } from './authStore';
import { useTeamStore } from './teamStore';

export const useResetStore = () => {
  const authStore = useAuthStore(state => state);
  const teamStore = useTeamStore(state => state);

  const resetAllStores = () => {
    authStore.reset();
    teamStore.reset();
  };

  return resetAllStores;
};
