import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { AnalyticsGraphData } from '../types';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import { NoData } from './NoData';
import { pieChartColors } from '../variables';

const getAnalyticsGraphData = ({ values }: { values: AnalyticsGraphData }) => {
  const total = values.data.sent.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  const data: { series: ApexOptions['series']; options: ApexOptions } = {
    series: values.data.sent,
    options: {
      colors: pieChartColors,
      labels: values.categories,
      chart: {
        type: 'donut',
      },
      states: {
        hover: {
          filter: {
            type: 'none',
          },
        },
      },
      stroke: {
        show: true,
        curve: 'monotoneCubic',
        lineCap: 'butt',
        colors: ['#26292f'],
        width: 11,
      },
      plotOptions: {
        pie: {
          customScale: 0.85,
          donut: {
            labels: {
              show: false,
              name: {},
              value: {
                formatter: function (val: any) {
                  return val;
                },
                color: 'white',
              },
              total: {
                fontFamily: 'DM Sans',
                fontWeight: '600',
                show: true,
                label: 'Notification Send',
                formatter: function (val: any) {
                  return `${getAbbreviateNumber(total)}`;
                },
                color: 'white',
              },
            },
            size: '50%',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
        labels: {
          colors: 'white',
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return data;
};

const AnalyticsPieChart = ({ values }: { values: AnalyticsGraphData }) => {
  const data = getAnalyticsGraphData({ values });
  const noData = data?.series?.length === 0;

  return (
    <>
      {noData ? (
        <NoData
          emptyMessage="Insights will appear once notifications are sent."
          emptyMessageClassName="text-lg text-center"
          className="!top-0"
        />
      ) : (
        <ReactApexChart
          options={data.options}
          series={data.series}
          type="donut"
          height={350}
        />
      )}
    </>
  );
};

export default AnalyticsPieChart;
