import BgDots from '@assets/img/templates/bg-dots.png';
import useGetChannels from 'api/channels/get-channels';
import { Channels } from 'api/channels/get-channels/types';
import useGetTemplate from 'api/templates/get-template';
import usePostTemplateChannelOrder from 'api/templates/post-template-channel-order';
import AddChannel from 'assets/svg/integrations/add.svg';
import Dropdown from 'components/dropdown';
import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { channelsLogo } from 'variables/channelsLogo';
import { DroppableColumn } from './components/DroppableColumn';
import channelWorkFlowDataSet, {
  WORKFLOWDATA_EVENT_TRIGGERED_ID,
  ChannelWorkflow,
  allChannelWorkflowData,
} from './variables/data';
import { handleDragEnd } from './functions';
import { useQueryClient } from '@tanstack/react-query';
import useCustomToast from 'utils/use-toast';
import { useAuthStore } from 'store/authStore';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { TEMPLATE_EDITOR_TYPES } from '../templateEditor/variables/constants';

const TemplateChannels = () => {
  const navigate = useNavigate();
  const params = useParams();
  const templateId = params.templateId;
  const postTemplateChannelOrder = usePostTemplateChannelOrder();
  const hiddenChannels = ['chat'];
  const queryClient = useQueryClient();
  const { appId } = useAuthStore(state => state);
  const toast = useCustomToast();
  const [searchParams] = useSearchParams();
  const visitedAppId = new URLSearchParams(searchParams).get('appId');

  const getChannels = useGetChannels();
  const getTemplate = useGetTemplate(Number(templateId));
  const templateIdentifier = getTemplate.data?.identifier;
  const configuredChannels = getTemplate.data?.contents.map(
    channel => channel.channel,
  );
  const templateName = getTemplate.data?.name;

  const [openWrapper, setOpenWrapper] = React.useState(false);
  const [channelWorkFlowData, setChannelWorkFlowData] =
    React.useState<ChannelWorkflow>(channelWorkFlowDataSet);

  const onDragEnd = (result: DropResult) => {
    handleDragEnd({
      channelWorkFlowData,
      postTemplateChannelOrder,
      result,
      setChannelWorkFlowData,
      templateId: Number(templateId),
      queryClient,
      toast,
    });
  };

  const handleViewChannel = (channelIdentifier: Channels) => {
    navigate(
      `/admin/template-editor/${templateId}/${channelIdentifier}/${templateIdentifier}/${TEMPLATE_EDITOR_TYPES.TEMPLATE}`,
    );
  };

  React.useEffect(() => {
    if (!getTemplate.data?.contents) return;

    const selectedTasksIds = getTemplate.data?.contents
      ?.sort((a, b) => (a.order > b.order ? 1 : -1))
      ?.map(item => String(item.id));

    setChannelWorkFlowData(initialData => {
      const channels = getTemplate.data?.contents.reduce(
        (acc, curr) => {
          const channelContentId = Object.keys(allChannelWorkflowData).find(
            item =>
              allChannelWorkflowData[item].channelIdentifier === curr.channel,
          );

          const currentChannel = getChannels.data?.find(
            item => item.channel === curr.channel,
          );
          const isConfigured = currentChannel?.configuredProviders.length > 0;
          const isActive = currentChannel?.configuredProviders.some(
            provider => provider?.enabled === true,
          );

          return {
            ...acc,
            [String(curr.id)]: {
              id: String(curr.id),
              content: allChannelWorkflowData[channelContentId]?.content,
              channelIdentifier: curr.channel,
              isDisabled: curr.isDisabled,
              isConfigured,
              isActive,
            },
          };
        },
        {
          [WORKFLOWDATA_EVENT_TRIGGERED_ID]:
            allChannelWorkflowData[WORKFLOWDATA_EVENT_TRIGGERED_ID],
        },
      );

      // initialData is used to accomodate dummy event trigger
      // found in the initial data
      const newState = {
        ...initialData,
        channels,
        columns: {
          ...initialData.columns,
          addedChannels: {
            ...initialData.columns['addedChannels'],
            channelIds: [WORKFLOWDATA_EVENT_TRIGGERED_ID, ...selectedTasksIds],
          },
        },
      };

      return newState;
    });
  }, [getChannels.data, getTemplate.data?.contents]);

  React.useEffect(() => {
    if (Number(visitedAppId) !== appId) {
      navigate('/admin/template');
    }
  }, [appId, navigate, visitedAppId]);

  const cssOriginObj = {
    0: 'origin-[-5%_15%]',
    1: 'origin-[-5%_15%]',
    2: 'origin-[-5%_18%]',
    3: 'origin-[-5%_22%]',
    4: 'origin-[-5%_30%]',
  };

  const noOfTasks =
    channelWorkFlowData?.columns?.['addedChannels']?.channelIds?.length;
  const cssOrigin = (cssOriginObj as any)?.[noOfTasks] ?? 'origin-[-5%_45%]';
  const notAddedChannels = Object.keys(allChannelWorkflowData).filter(item => {
    const currentChannelIdentifier =
      allChannelWorkflowData?.[item]?.channelIdentifier;

    return (
      !configuredChannels?.includes(currentChannelIdentifier as Channels) &&
      !hiddenChannels.includes(currentChannelIdentifier) &&
      currentChannelIdentifier !== 'event_trigger'
    );
  });

  return (
    <>
      <h3 className="font-medium text-white">
        Editing template &gt; {templateName}
      </h3>
      <p className="mt-2 text-sm text-gray-650">
        🔄 Workflow edits are automatically saved.
      </p>
      <div
        style={{
          backgroundImage: `url(${BgDots})`,
        }}
        className="csm-bg-dot relative top-6 flex h-[78vh] overflow-y-auto rounded-md border border-[#3E3C3C] p-4"
      >
        {/* channels drag and drop */}
        <div className="flex w-[93%] flex-col items-center">
          <DragDropContext onDragEnd={onDragEnd}>
            {channelWorkFlowData.columnOrder.map(columnId => {
              const column =
                channelWorkFlowData.columns[columnId as 'addedChannels'];
              const channels = column.channelIds.map(
                ids => channelWorkFlowData.channels[Number(ids)],
              );

              return (
                <DroppableColumn
                  key={column.id}
                  column={column}
                  channels={channels}
                  templateIdentifier={templateIdentifier}
                />
              );
            })}
          </DragDropContext>

          {notAddedChannels?.length !== 0 && (
            <Dropdown
              openWrapper={openWrapper}
              setOpenWrapper={setOpenWrapper}
              // animation={"origin-[-5%_15%] transition-all duration-300 ease-in-out"}
              animation={`${cssOrigin} transition-all duration-300 ease-in-out`}
              button={
                <button
                  className={`relative mx-auto rounded-full bg-gray-850 ${
                    !getTemplate.isLoading ? 'p-2' : ''
                  } `}
                >
                  {!getTemplate.isLoading && <img src={AddChannel} alt="" />}

                  {getTemplate.isLoading && (
                    <div className="absolute right-0 top-1">
                      <LoaderSpinner
                        width="40"
                        strokeColor="white"
                        parentClass="relative left-2"
                      />
                    </div>
                  )}
                </button>
              }
              // classNames={"py-2 -top-[160px] left-[40px] w-max"}
              classNames={'py-2 -top-[180%] left-[40px] w-max'}
              children={
                <div className="flex h-max w-56 flex-col justify-start  rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500   dark:text-white dark:shadow-none ">
                  <div className="w-auto rounded-2xl bg-gray-850 px-4 py-8">
                    <div className="flex flex-col items-center gap-5 overflow-y-auto">
                      {notAddedChannels
                        ?.filter(
                          channel =>
                            channel !== WORKFLOWDATA_EVENT_TRIGGERED_ID,
                        )
                        ?.map(item => {
                          return (
                            <button
                              onClick={() =>
                                handleViewChannel(
                                  allChannelWorkflowData?.[item]
                                    ?.channelIdentifier as Channels,
                                )
                              }
                              className="flex w-full items-center justify-start gap-3"
                              key={
                                allChannelWorkflowData[item].channelIdentifier
                              }
                            >
                              <img
                                width={44}
                                src={
                                  channelsLogo?.[
                                    allChannelWorkflowData[item]
                                      .channelIdentifier
                                  ]
                                }
                                alt=""
                              />
                              <p>{allChannelWorkflowData[item].content}</p>
                            </button>
                          );
                        })}
                    </div>
                  </div>
                </div>
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default TemplateChannels;
