import { useDisclosure } from '@chakra-ui/hooks';
import useGetInvoices from 'api/billing/get-invoices';
import useGetPlans from 'api/billing/get-plans';
import useGetSubscriptionDetails from 'api/billing/get-subscription-details';
import Card from 'components/card';
import LoaderModal from 'components/loader/LoaderModal';
import { STRIPE_API_KEY } from 'config';
import { isEnterpriseEdition } from 'utils/get-editions';
import { CountCards } from './components/CountCards';
import Header from './components/Header';
import InvoiceTable from './components/InvoiceTable';
import PaymentMethod from './components/PaymentMethod';
import PaymentStatus from './components/PaymentStatus';
import SubscriptionDetails from './components/SubscriptionDetails';
import Usuage from './components/Usuage';
import { StripePromise } from './types';
import useGetLicense from 'api/billing/get-license';
import { useAuthStore } from 'store/authStore';
import useGetPlanUsage from 'api/usuage/get-plan-usage';

const enterpriseEdition = isEnterpriseEdition();

let promise: StripePromise = null;

if (enterpriseEdition) {
  promise = import('@stripe/stripe-js').then(stripe =>
    stripe.loadStripe(STRIPE_API_KEY),
  );
}

const Billing = () => {
  const enterpriseEdition = isEnterpriseEdition();
  const loaderModalActions = useDisclosure();
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const getInvoices = useGetInvoices(
    license.data?.InstanceClientIdentifer,
    enterpriseEdition,
  );
  const invoiceData = getInvoices.data?.data?.filter(invoice => {
    if (invoice.status === 'draft' || invoice.status === 'void') return false;
    return true;
  });

  const planUsage = useGetPlanUsage();
  const isPaidPlan = planUsage?.data?.licenseSummary?.plan !== 'free';

  useGetPlans(enterpriseEdition);
  const getSubscriptionDetails = useGetSubscriptionDetails(
    license.data?.InstanceClientIdentifer,
    enterpriseEdition,
  );

  return (
    <>
      {/* Checks if payment method add successfull or not */}
      <PaymentStatus promise={promise} />

      {enterpriseEdition && !getSubscriptionDetails.isFetched && (
        <LoaderModal
          isOpen={getSubscriptionDetails.isLoading}
          onClose={loaderModalActions.onClose}
        />
      )}

      {enterpriseEdition
        ? getSubscriptionDetails.isFetched && (
            <div className="flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
              <div className="h-full w-full rounded-[20px]">
                <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
                  <div className="mb-5">
                    <div className="col-span-6 flex h-full w-full flex-col gap-[22px] rounded-xl 3xl:col-span-4">
                      <SubscriptionDetails promise={promise} />
                      <Usuage />
                      {isPaidPlan && <PaymentMethod promise={promise} />}
                      {invoiceData?.length > 0 && (
                        <InvoiceTable
                          loading={getInvoices.isLoading}
                          tableData={invoiceData}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        : planUsage.isFetched && (
            <Card extra={'pb-8 px-6 pt-6'}>
              <Header>Usage</Header>

              <div className="grid w-full grid-cols-3 justify-between gap-7">
                {/* Trigger Count */}
                <CountCards
                  className="!text-yellow-500"
                  content="Notification Triggers"
                  heading={String(planUsage.data?.usageSummary?.triggerCount)}
                />

                {/* Delivery Count */}
                <CountCards
                  className="!text-green-500"
                  content="Notification Deliveries"
                  heading={String(planUsage.data?.usageSummary?.deliveryCount)}
                />
              </div>
            </Card>
          )}
    </>
  );
};

export default Billing;
