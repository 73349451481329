import { handleGithubRedirect } from 'utils/github-redirect';
import GitHubDark from '@assets/svg/github-dark.svg';
import { Link } from 'react-router-dom';
import LoaderSpinner from 'components/loader/LoaderSpinner';

export const AuthHeading = ({ text }: { text: string }) => {
  return (
    <h3 className="text-[22px] font-bold text-navy-700 dark:text-white">
      {text}
    </h3>
  );
};

export const AuthText = ({
  text,
  className = '',
}: {
  text: string;
  className?: string;
}) => {
  return (
    <span className={`font-normal  text-gray-10 ${className}`}>{text}</span>
  );
};

export const AuthTextWithLink = ({
  text,
  linkText,
  linkTo,
  parentClass = '',
}: {
  text: string | JSX.Element;
  linkText: string;
  linkTo: string;
  parentClass?: string;
}) => {
  return (
    <div className={`text-lg ${parentClass}`}>
      <span className="font-normal  text-gray-10">{text}</span>

      <Link
        to={linkTo}
        className="hover:text-yellow-60 ml-1 font-normal text-yellow-450 hover:text-yellow-600"
      >
        {linkText}
      </Link>
    </div>
  );
};

export const AuthAgreeToTerms = ({ text }: { text: string }) => {
  return (
    <div className="mt-4 text-sm">
      <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
        {text}
      </span>
      <a
        href={'https://engagespot.co/legal/termsandconditions'}
        className="hover:text-yellow-60 ml-1 font-normal text-yellow-450 hover:text-yellow-600"
        target="_blank"
        rel="noreferrer"
      >
        Terms of service
      </a>

      <span className="ml-1 text-sm font-medium text-navy-700 dark:text-gray-500">
        and
      </span>

      <a
        href={'https://engagespot.co/legal/privacy-policy'}
        className="hover:text-yellow-60 ml-1 font-normal text-yellow-450 hover:text-yellow-600"
        target="_blank"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </div>
  );
};

export const AuthORBlock = () => {
  return (
    <div className="my-6 flex items-center  gap-3">
      <div className="h-px w-full bg-gray-200 dark:!bg-night-200" />
      <p className="text-sm text-gray-600"> OR </p>
      <div className="h-px w-full bg-gray-200 dark:!bg-night-200" />
    </div>
  );
};

export const AuthGithubButton = () => {
  return (
    <button
      type="button"
      onClick={handleGithubRedirect}
      className="mb-6 flex h-[50px] w-full items-center justify-center gap-2 rounded-[5px] border border-gray-440 bg-night-400 text-gray-20 hover:cursor-pointer"
    >
      <div className="rounded-full text-xl">
        <img className="scale-90" src={GitHubDark} alt="github" />
      </div>
      <p className="text-lg font-medium">Login with Github</p>
    </button>
  );
};

export const AuthSubmitButton = ({
  isLoading,
  text,
  disabled,
  className = '',
}: {
  isLoading?: boolean;
  text: string;
  disabled?: boolean;
  className?: string;
}) => {
  return (
    <button
      disabled={disabled}
      className={`linear mt-8 flex w-full justify-center rounded-[5px] bg-white py-3 text-lg font-medium text-night-100 transition duration-200 hover:opacity-90 dark:disabled:bg-gray-250/60 ${className}`}
    >
      {text}
      {isLoading && <LoaderSpinner />}
    </button>
  );
};
