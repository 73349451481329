import dagre from 'dagre';
import { Edge, Node } from 'reactflow';
import { WorkflowNodeTypes } from './types';

type NodeComponents = Extract<
  WorkflowNodeTypes,
  'base' | 'label' | 'end' | 'empty'
>;

const WORKFLOW_NODE_WIDTHS: Record<NodeComponents, number> = {
  base: 200,
  label: 75,
  end: 100,
  empty: 5,
};

const WORKFLOW_NODE_HEIGHTS: Record<NodeComponents, number> = {
  base: 50,
  label: 30,
  end: 30,
  empty: 5,
};

export const getNodeDimensions = (type: WorkflowNodeTypes) => {
  if (type === 'end') {
    return {
      width: WORKFLOW_NODE_WIDTHS.end,
      height: WORKFLOW_NODE_HEIGHTS.end,
    };
  }
  if (type === 'label') {
    return {
      width: WORKFLOW_NODE_WIDTHS.label,
      height: WORKFLOW_NODE_HEIGHTS.label,
    };
  }

  if (type === 'empty') {
    return {
      width: WORKFLOW_NODE_WIDTHS.empty,
      height: WORKFLOW_NODE_HEIGHTS.empty,
    };
  }
  return {
    width: WORKFLOW_NODE_WIDTHS.base,
    height: WORKFLOW_NODE_HEIGHTS.base,
  };
};

export const getDraggableNodeDimensions = () => {
  return {
    width: WORKFLOW_NODE_WIDTHS.base,
    height: WORKFLOW_NODE_HEIGHTS.base * 0.75,
  };
};

export function layoutNodes(
  nodes: Node[],
  edges: Edge[],
  nodeYAxisMultipler = 1,
): { layoutedNodes: Node[]; layoutedEdges: Edge[] } {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({
    rankdir: 'BT',
    nodesep: 250,
    ranksep: 60,
  });

  nodes.forEach(node => {
    dagreGraph.setNode(node.id, {
      ...getNodeDimensions(node.type as WorkflowNodeTypes),
      ...(node.type === 'branch' ? { weight: 10 } : {}),
    });
  });

  edges.forEach(edge => {
    // dagreGraph.setEdge(edge.source, edge.target);
    dagreGraph.setEdge(edge.target, edge.source);
  });

  dagre.layout(dagreGraph);

  // const nodesWithLevels = assignLevelsToNodes(nodes, nodes[0].id);
  // console.log('nodes with levels', nodesWithLevels);

  const branchMap = new Map();

  nodes.forEach(node => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const { height, width } = getNodeDimensions(node.type as WorkflowNodeTypes);

    let x = nodeWithPosition.x - width / 2;
    let y =
      branchMap.get(node.id) ??
      nodeWithPosition.y * nodeYAxisMultipler - height / 2;

    node.position = {
      x,
      y,
    };

    if (node.type === 'branch') {
      const getSubBranchHeight = () => {
        let leastY = Infinity;

        node.data.childrenNodeIds.forEach((childNode: any) => {
          const childNodePosition = dagreGraph.node(childNode.id);
          leastY = Math.min(leastY, childNodePosition.y);
          leastY -= height / 3.5;
        });

        node.data.childrenNodeIds.forEach((childNode: any) => {
          branchMap.set(childNode.id, leastY);
        });

        /**
     
      const branchEnd = getBranchEndNode(node.data.childrenNodeIds);
      let leftNode = getNextLeftNode(branchNode);
      let rightNode = getNextRightNode(branchNode);
      while (leftNode !== branchEnd && rightNode !== branchEnd) {
        let leftNodePosition = dagreGraph.node(leftNode.id);
        let rightNodePosition = dagreGraph.node(rightNode.id);
        let newNodePosition = Math.min(leftNodePosition.y, rightNodePosition.y);
        branchMap.set(rightNode.id, newNodePosition);
        branchMap.set(leftNode.id, newNodePosition);
        leftNode = getNextLeftNode(leftNode);
        rightNode = getNextRightNode(rightNode);
      }
      */
      };
      // getSubBranchHeight();
    }

    return node;
  });

  return { layoutedNodes: nodes, layoutedEdges: edges };
}
