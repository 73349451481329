import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

export type NotificationData = {
  notification: {
    templateIdentifier?: string;
    templateId?: string;
    category?: string;
    title?: string;
    message?: string;
    icon?: string | File[];
    url?: string;
    workflowId?: string;
    workflow?: {
      identifier: string;
    };
  };

  data?: {
    [x: string]: string;
  };

  sendTo: {
    recipients?: string[];
    topics?: string[];
  };

  override?: { channels: string[]; channel?: { [x: string]: any } };
};

export type PostNotification = NotificationData & {
  engagespot_api_key: string;
  engagespot_api_secret: string;
};

const post = async (values: PostNotification) => {
  const { engagespot_api_key, engagespot_api_secret, ...data } = values;

  return axiosInstance.post(`${routes['notification']}`, data, {
    headers: {
      'X-ENGAGESPOT-API-KEY': engagespot_api_key,
      'X-ENGAGESPOT-API-SECRET': engagespot_api_secret,
    },
  });
};

const usePostNotification = () => {
  return useMutation({
    mutationFn: post,
  });
};

export default usePostNotification;
