import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';

const remove = async ({ routeId }: { routeId: number }) => {
  return axiosInstance.delete(`${routes['routes']}/${routeId}`);
};

const useDeleteChannelRoutes = () => {
  return useMutation({
    mutationFn: remove,
  });
};

export default useDeleteChannelRoutes;
