import { GetCategories } from 'api/categories/get-categories/types';
import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import React from 'react';
import CardMenu from './CardMenu';
import EmptyTableMessage, {
  getSearchEmptyHeading,
  getSearchEmptyMessage,
} from 'components/table/empty-table-message';
import { Users } from 'lucide-react';
import CopyCell from 'components/copy/CopyCell';

function CategoriesTable(props: {
  tableData: any;
  editOpen: () => void;
  deleteOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  const { tableData, editOpen, deleteOpen, globalFilter, loading } = props;

  const columnConfig: ColumnConfig<GetCategories> = [
    {
      accessorKey: 'identifier',
      header: 'Identifier',
      cellFunction: info => <CopyCell value={info.getValue()} />,
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'createdAt',
      header: 'Action',
      cellFunction: info => (
        <CardMenu
          deleteOpen={deleteOpen}
          selected={info.row.original}
          editOpen={editOpen}
        />
      ),
    },
  ];

  const columns = columnGenerator<GetCategories>({ columnConfig });

  return (
    <CustomTable
      loading={loading}
      emptyTableMessage="No worries! Create your first category"
      tableData={tableData}
      columns={columns}
      paginationType="table"
      globalFilter={globalFilter}
      emptyAdditionalJsx={() => (
        <EmptyTableMessage
          handleCreate={() => {
            editOpen();
          }}
          searchInput={globalFilter}
          docsLink={
            'https://docs.engagespot.co/docs/features/category/what-are-categories'
          }
          heading={"You haven't created any categories yet"}
          message={
            'Grouping notifications under categories will help your users set preferences in receiving notifications from categories'
          }
          searchEmptyHeading={getSearchEmptyHeading('categories')}
          searchEmptyMessage={getSearchEmptyMessage('categories')}
          Icon={Users}
        />
      )}
    />
  );
}

export default CategoriesTable;
