import { SelectFieldProps } from './types';
import DefaultSelectVariant from './variants/default';
import StyledSelectVariant from './variants/styled';

function SelectField<T>(props: SelectFieldProps<T>) {
  const {
    showIsRequiredAsterisk = false,
    variant = 'default',
    ...rest
  } = props;

  return (
    <>
      {variant === 'default' && (
        <DefaultSelectVariant<T>
          {...rest}
          showIsRequiredAsterisk={showIsRequiredAsterisk}
        />
      )}

      {variant === 'styled' && (
        <StyledSelectVariant<T>
          {...rest}
          showIsRequiredAsterisk={showIsRequiredAsterisk}
        />
      )}
    </>
  );
}

export default SelectField;
