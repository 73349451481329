import { QueryClient, UseMutationResult } from '@tanstack/react-query';
import { GetProviders } from 'api/providers/get-providers/types';
import { SaveProvider } from 'api/providers/save-provider';
import { routes } from 'api/routes';
import { AxiosResponse } from 'axios';
import { customToast } from 'utils/use-toast';
import { Channels } from 'api/channels/get-channels/types';
import { NavigateFunction } from 'react-router-dom';

export const docLinkCreator = ({
  section = 'channels',
  channel,
  providerIdentifier,
}: {
  section: string;
  channel: Channels;
  providerIdentifier: string;
}) => {
  const baseUrl = `https://docs.engagespot.co/docs/features`;

  if (providerIdentifier === 'default_inapp') {
    return `${baseUrl}/in-app-inbox/introduction`;
  }

  const noDocPresentProviders = ['default_email', 'default_sms'];
  if (noDocPresentProviders.includes(providerIdentifier)) {
    return '';
  }

  const providerPaths: Record<string, string> = {
    fcm: 'FCM-provider',
    slack: 'slack-provider',
    default_webhook: 'default-webhook-provider',
    discord: 'discord-provider',
    sendgrid_email: 'sendgrid-provider',
    smtp_email: 'smtp-provider',
    default_webpush: 'default-web-push-provider',
  };

  const channelsPaths: Record<string, string> = {
    webPush: 'web-push',
    mobilePush: 'mobile-push',
  };

  let providerPath = providerPaths[providerIdentifier] || providerIdentifier;
  let channelsPath = channelsPaths[channel] || channel;

  if (providerPath.includes(channel)) {
    providerPath = providerPath.replace(`_${channel}`, '');
  }

  return `${baseUrl}/${section}/${channelsPath}/${providerPath}`;
};

export const handleProviderSave = ({
  handleClose,
  values,
  appId,
  provider,
  saveProvider,
  toast,
  channel,
  firstConfiguredProvider,
  queryClient,
  invalidateRoutes = false,
  invalidateChannels = false,
  redirectBack = false,
  navigate,
}: {
  values: any;
  handleClose: () => void;
  provider:
    | GetProviders['availableProviders'][0]
    | GetProviders['configuredProviders'][0];
  appId: number;
  saveProvider: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    SaveProvider,
    unknown
  >;
  toast: customToast;
  channel: string;
  firstConfiguredProvider: boolean;
  queryClient: QueryClient;
  invalidateRoutes?: boolean;
  invalidateChannels?: boolean;
  navigate?: NavigateFunction;
  redirectBack?: boolean;
}) => {
  const { instanceName, ...data } = values;
  const configured = 'id' in provider;

  const submitData: SaveProvider = {
    data: {
      instanceName,
      providerIdentifier: configured
        ? String(provider.id)
        : provider.identifier,
      config: {
        ...data,
      },
    },
    channel: provider.channel,
    appId,
    type: configured ? 'patch' : 'post',
  };

  saveProvider.mutate(submitData, {
    onSuccess: () => {
      toast.success('provider config saved');
      queryClient.invalidateQueries([`${routes['apps']}/providers`]);

      if (firstConfiguredProvider && invalidateChannels) {
        queryClient.invalidateQueries([`${routes['apps']}/channels`]);
      }

      if (firstConfiguredProvider && invalidateRoutes) {
        queryClient.invalidateQueries([`${routes['routes']}`, appId, channel]);
      }

      if (redirectBack) {
        navigate?.(-1);
      }

      handleClose();
    },
    onError: error => {
      toast.showError(error);
    },
  });
};

export const getInstanceName = ({ name }: { name: string }) => {
  const providereName = name?.toUpperCase().replaceAll(' ', '_');
  return `${providereName}_INSTANCE_01`;
};
