import { AppAnalytics } from 'api/analytics/get-app-analytics/types';
import { ChannelAnalytics } from 'api/analytics/get-channel-analytics/types';
import { TemplateAnalytics } from 'api/analytics/get-template-analytics/types';
import { EngagespotEvent } from 'api/logs/get-logs/types';
import { capitalizeFirstLetter, getSum } from 'utils/functions';
import { getAbbreviateNumber } from 'utils/get-abbreviate-format';
import { ChannelAnalyticsData, TemplateAnalyticsData } from './types';

export const getAppAnalyticsData = (data: AppAnalytics[]) => {
  const appAnalyticsJsonData = data?.reduce(
    (acc, curr) => {
      return {
        ...acc,
        [curr.event]: curr.count,
      };
    },
    {} as { [x in keyof EngagespotEvent]: string },
  );

  return appAnalyticsJsonData;
};

export const getTemplateAnalyticsData = (data: TemplateAnalytics[]) => {
  const templateAnalyticsData = data?.reduce(
    (accumulator, item) => {
      const { templateId, templateIdentifier, templateName, event, count } =
        item;

      if (!templateId) return accumulator;

      if (!accumulator[templateId]) {
        accumulator[templateId] = {
          templateId,
          templateIdentifier,
          templateName,
          seen: '0',
          sent: '0',
          interacted: '0',
          discarded: '0',
        };
      }

      if (event === 'sent') {
        accumulator[templateId].sent = getAbbreviateNumber(count);
      } else if (event === 'seen') {
        accumulator[templateId].seen = getAbbreviateNumber(count);
      } else if (event === 'interacted' || event === 'read') {
        accumulator[templateId].interacted = getAbbreviateNumber(
          getSum(accumulator[templateId].interacted, count),
        );
      } else if (event === 'discarded' || event === 'failed') {
        accumulator[templateId].discarded = getAbbreviateNumber(
          getSum(accumulator[templateId].discarded, count),
        );
      }

      return accumulator;
    },
    {} as {
      [x: string]: TemplateAnalyticsData;
    },
  );

  const templateAnalyticsJsonData = Object.values(templateAnalyticsData ?? {});

  return templateAnalyticsJsonData;
};

export const getChannelAnalyticsData = (data: ChannelAnalytics[]) => {
  const templateAnalyticsData = data?.reduce(
    (accumulator, item) => {
      const { channel, event, count } = item;

      if (!channel) return accumulator;

      if (!accumulator[channel]) {
        accumulator[channel] = {
          channel,
          seen: '0',
          sent: '0',
          interacted: '0',
        };
      }

      if (event === 'sent') {
        accumulator[channel].sent = getAbbreviateNumber(count);
      } else if (event === 'seen') {
        accumulator[channel].seen = getAbbreviateNumber(count);
      } else if (event === 'interacted' || event === 'read') {
        accumulator[channel].interacted = getAbbreviateNumber(
          getSum(accumulator[channel].interacted, count),
        );
      }

      return accumulator;
    },
    {} as {
      [x: string]: ChannelAnalyticsData;
    },
  );

  const channelAnalyticsJsonData = templateAnalyticsData ?? {};

  return channelAnalyticsJsonData;
};

export const getChannelAnalyticsGraphData = (data: ChannelAnalytics[]) => {
  const channelAnalyticsData = getChannelAnalyticsData(data);

  const channelAnalyticsLineBarData = Object.keys(channelAnalyticsData).reduce(
    (acc, curr) => {
      return {
        ...acc,
        sent: [...acc.sent, Number(channelAnalyticsData[curr].sent)],
        seen: [...acc.seen, Number(channelAnalyticsData[curr].seen)],
        interacted: [
          ...acc.interacted,
          Number(channelAnalyticsData[curr].interacted),
        ],
      };
    },
    {
      sent: [],
      seen: [],
      interacted: [],
    },
  );

  return {
    categories: Object.keys(channelAnalyticsData).map(channel =>
      capitalizeFirstLetter(channel),
    ),
    data: channelAnalyticsLineBarData,
  };
};
