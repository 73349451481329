import Card from 'components/card';
import NewCard from 'components/card/NewCard';
import MasterCard from 'views/admin/dashboards/billing/components/CreditCard';
import Header from './Header';
import useGetPaymentMethods from 'api/billing/get-payment-methods';
import { StripePromise } from '../types';
import useGetLicense from 'api/billing/get-license';
import { useAuthStore } from 'store/authStore';

function PaymentMethod({ promise }: { promise: StripePromise }) {
  const { clientId: clientUserId } = useAuthStore(state => state);
  const license = useGetLicense(clientUserId);
  const getPaymentMethods = useGetPaymentMethods(
    license.data?.InstanceClientIdentifer,
  );

  return (
    <Card data-scrollkey="add_new_credit_card" extra={'pb-8 px-6 pt-6'}>
      <Header>Payment Method</Header>

      <div
        className={`relative justify-between rounded-[20px] bg-night-100 p-9 pb-8`}
      >
        <h4 className="mb-2 text-xl text-[#8A91A0]">Saved Cards</h4>

        {/* MasterCard */}
        <div className="mt-[25px] grid grid-cols-4 gap-6 px-[5px] md:px-40 xl:px-1">
          {getPaymentMethods.data?.data?.map(method => (
            <MasterCard
              key={method.id}
              number={`**** **** **** ${method?.card?.last4}`}
              brand={method?.card?.brand}
              paymentMethodId={method.id}
              isDefault={method.isDefault}
            />
          ))}
          <NewCard promise={promise} />
        </div>
      </div>
    </Card>
  );
}

export default PaymentMethod;
