import { Channels } from 'api/channels/get-channels/types';

export type TemplateInputs = {
  label: string;
  required?: boolean;
  placeholder?: string;
  name: string;
  type:
    | 'input'
    | 'textarea'
    | 'checkbox'
    | 'button'
    | 'file'
    | 'select'
    | 'input|file'
    | 'div'
    | 'editor'
    | 'switch';
  rows?: number;
  additionalSettings?: TemplateInputs;
  additionalSettingsEnableHidden?: boolean;
  children?: TemplateInputs[];
  className?: string;
  helperText?: string;
  overwriteHeading?: string;
};

export type TemplateEditorAvailabeChannels = Exclude<
  Channels,
  'chat' | 'webhook'
>;

type PartialTemplateEditorInput = {
  [k in TemplateEditorAvailabeChannels]: {
    template: TemplateInputs[];
    batched_template: TemplateInputs[];
  };
};

export type inAppTemplate = {
  // different template states
  [x: string]: {
    template: TemplateInputs[];
    batched_template: TemplateInputs[] | {};
  };
};

export type inAppConfig = {
  // different templates
  [x: string]: inAppTemplate;
};

type TemplateEditorInput = PartialTemplateEditorInput;

// batched configs
export const batchedTemplateType1: TemplateInputs[] = [
  {
    label: 'Enable Batching',
    name: 'batchingEnabled',
    type: 'checkbox',
  },
  {
    label: 'Batching Window (in ms)',
    required: true,
    placeholder: 'Enter batching window (in ms)',
    name: 'batchingWindow',
    type: 'input',
  },
  {
    label: 'Batching Message',
    required: false,
    placeholder: 'Message you want to convey',
    name: 'batchingContent',
    type: 'textarea',
  },
];

const [batchedTemplateValue1, batchedTemplateValue2, batchedTemplateValue3] =
  batchedTemplateType1;

const batchedTemplateType2: TemplateInputs[] = [
  batchedTemplateValue1,
  batchedTemplateValue2,
  {
    label: 'Batching Avatar',
    required: false,
    placeholder: 'Content',
    name: 'batchingIcon',
    type: 'input|file' as const,
  },
  {
    label: 'Batching Title',
    required: true,
    placeholder: 'Short and descriptive',
    name: 'batchingTitle',
    type: 'input',
  },
  batchedTemplateValue3,
];

const batchedTemplateType3: TemplateInputs[] = [
  batchedTemplateValue1,
  batchedTemplateValue2,
  {
    label: 'Batched Email Subject',
    required: true,
    placeholder: 'Enter Email Subject',
    name: 'batchingSubject',
    type: 'input',
  },
];

const batchedTemplateType4: TemplateInputs[] = [
  batchedTemplateValue1,
  batchedTemplateValue2,
  {
    label: 'Message Type',
    required: true,
    placeholder: 'Enter Message Type',
    name: 'batchingMessageType',
    type: 'select',
  },
  {
    label: 'Batching Content',
    required: true,
    placeholder: 'Message you want to convey',
    name: 'batchingMessage',
    type: 'textarea',
  },
  {
    label: 'Image Url',
    required: true,
    placeholder: 'https://example.com',
    name: 'batchingUrl',
    type: 'input|file',
  },
];

const batchedTemplateType5: TemplateInputs[] = [
  batchedTemplateValue1,
  batchedTemplateValue2,
  {
    label: 'Message',
    required: true,
    placeholder: 'Enter',
    name: 'batchingMessage',
    type: 'textarea',
    helperText:
      "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
  },
  {
    label: 'User Name',
    required: false,
    placeholder: 'Enter',
    name: 'batchingUsername',
    type: 'input',
  },
  {
    label: 'Avatar Url',
    required: false,
    placeholder: 'Enter',
    name: 'batchingAvatarUrl',
    type: 'input',
  },
  {
    label: 'Embeds',
    required: false,
    placeholder: 'Enter',
    name: 'batchingEmbeds',
    type: 'editor',
  },
];

const batchedTemplateType6: TemplateInputs[] = [
  batchedTemplateValue1,
  batchedTemplateValue2,
  {
    label: 'Message',
    required: true,
    placeholder: 'Enter',
    name: 'batchingMessage',
    type: 'textarea',
    helperText:
      "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
  },
  {
    label: 'Blocks',
    required: false,
    placeholder: 'Enter',
    name: 'batchingBlocks',
    type: 'editor',
  },
];

// repeated configs
const mobileWebInAppCommonTemplate: TemplateInputs[] = [
  {
    label: 'Avatar',
    required: false,
    placeholder: 'Content',
    name: 'icon',
    type: 'input|file' as const,
  },
  {
    label: 'Title',
    required: true,
    placeholder: 'Title',
    name: 'title',
    type: 'textarea' as const,
    rows: 3,
    helperText:
      "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
  },
  {
    label: 'Message',
    required: false,
    placeholder: 'Message',
    name: 'message',
    type: 'textarea' as const,
  },
  {
    label: 'Action Url',
    required: false,
    placeholder: 'Action Url',
    name: 'url',
    type: 'input' as const,
  },
];

/**
 *
 * Main config
 *
 * INFO: inApp config will be taken directly from allTemplateDetails
 *
 */
export const templateEditorInputConfig: TemplateEditorInput = {
  sms: {
    template: [
      {
        label: 'Content',
        required: true,
        placeholder: 'Content',
        name: 'content',
        type: 'textarea',
        helperText:
          "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
      },
    ],
    batched_template: batchedTemplateType1,
  },
  discord: {
    template: [
      {
        label: 'Message',
        required: true,
        placeholder: 'Enter',
        name: 'message',
        type: 'textarea',
        helperText:
          "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
      },
      {
        label: 'User Name',
        required: false,
        placeholder: 'Enter',
        name: 'username',
        type: 'input',
      },
      {
        label: 'Avatar Url',
        required: false,
        placeholder: 'Enter',
        name: 'avatar_url',
        type: 'input',
      },
      {
        label: 'Embeds',
        required: false,
        placeholder: 'Enter',
        name: 'embeds',
        type: 'editor',
      },
    ],
    batched_template: batchedTemplateType5,
  },
  slack: {
    template: [
      {
        label: 'Message',
        required: true,
        placeholder: 'Enter',
        name: 'message',
        type: 'textarea',
        helperText:
          "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
      },
      {
        label: 'Blocks',
        required: false,
        placeholder: 'Enter',
        name: 'blocks',
        type: 'editor',
      },
    ],
    batched_template: batchedTemplateType6,
  },
  email: {
    template: [
      {
        label: 'Email Subject',
        required: true,
        placeholder: 'Enter Email Subject',
        name: 'subject',
        type: 'input',
      },
    ],
    batched_template: batchedTemplateType3,
  },
  mobilePush: {
    template: mobileWebInAppCommonTemplate,
    batched_template: batchedTemplateType2,
  },
  webPush: {
    template: mobileWebInAppCommonTemplate,
    batched_template: batchedTemplateType2,
  },
  inApp: {
    template: mobileWebInAppCommonTemplate,
    batched_template: batchedTemplateType2,
  },
  whatsapp: {
    template: [
      {
        label: 'Using Twilio Provider',
        name: 'usingTwilioProvider',
        type: 'checkbox',
      },
      {
        label: 'Content Template ID',
        required: false,
        placeholder: 'Enter Content Template ID',
        name: 'contentSid',
        type: 'input',
      },
      {
        label: 'Message Service ID',
        required: false,
        placeholder: 'Enter Message Service ID',
        name: 'messagingServiceSid',
        type: 'input',
      },
      {
        label: 'Message Type',
        required: true,
        placeholder: 'Enter Message Type',
        name: 'messageType',
        type: 'select',
      },
      {
        label: 'Content',
        required: false,
        placeholder: 'Content',
        name: 'message',
        type: 'textarea',
        helperText:
          "You can use template variables to add dynamic data to your messages - <a class='text-decoration-line: underline' href='https://docs.engagespot.co/docs/features/workflows/template-editor/overview#template-variables' target='_blank' rel='noreferrer'>Read guide</a>",
      },
      {
        label: 'Image Url',
        required: true,
        placeholder: 'https://example.com',
        name: 'url',
        type: 'input|file',
      },
    ],
    batched_template: batchedTemplateType4,
  },
};
