import EmptyLightAnimation from 'animations/empty-light.json';
import useGetUserDetails from 'api/users/get-users-details';
import { GetUserDetails } from 'api/users/get-users-details/types';
import { GetUserResult } from 'api/users/get-users/types';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import { CustomModal, CustomModalPrimaryButton } from 'components/modal';
import Lottie from 'lottie-react';

function DetailsModal({
  isOpen,
  onClose,
  selectedUser,
}: {
  onClose: () => void;
  isOpen: boolean;
  selectedUser: GetUserResult;
}) {
  const getUserDetails = useGetUserDetails({
    identifier: selectedUser?.identifier,
    enabled: isOpen,
  });

  const noData =
    getUserDetails.isFetched &&
    Object.keys(getUserDetails.data ?? {}).length === 0;

  const ContentCard = ({
    heading,
    text,
  }: {
    heading: string;
    text: string;
  }) => {
    return (
      <div className="mb-4">
        <p className="text-sm capitalize text-gray-550">{heading}</p>
        <p className="text-base font-medium text-navy-700 dark:text-white">
          {text ? text : 'No data'}
        </p>
      </div>
    );
  };

  return (
    <CustomModal
      modalCardClassName="!max-w-[450px] !max-h-[500px]"
      isOpen={isOpen}
      onClose={onClose}
    >
      <h1 className="mb-6 text-2xl  font-bold">User Details</h1>

      {/* Content */}
      <div
        className={`flex flex-col ${
          getUserDetails.isLoading ? 'items-center' : 'items-start'
        } ${noData ? 'hidden' : ''} break-all justify-center rounded-2xl bg-white bg-clip-border px-3 py-4  dark:!bg-gray-450`}
      >
        {getUserDetails.isLoading && (
          <LoaderSpinner parentClass="!left-0 h-32 flex justify-center items-center" />
        )}

        {!noData &&
          Object.keys(getUserDetails.data ?? {}).map(item =>
            typeof getUserDetails.data?.[item as keyof GetUserDetails] ===
            'string' ? (
              <ContentCard
                heading={item}
                text={getUserDetails.data?.[item as keyof GetUserDetails]}
              />
            ) : (
              <ContentCard
                heading={item}
                text={JSON.stringify(
                  getUserDetails.data?.[item as keyof GetUserDetails],
                  null,
                  4,
                )}
              />
            ),
          )}
      </div>

      {noData && (
        <div>
          <div className="flex max-h-[200px] overflow-hidden rounded-lg">
            <Lottie
              height={10}
              animationData={EmptyLightAnimation}
              loop={true}
            />
          </div>

          <div className="relative bottom-3 text-center text-lg font-semibold">
            No User Details
          </div>
        </div>
      )}

      <div className="mt-6 flex justify-end gap-4">
        <CustomModalPrimaryButton
          loading={false}
          onClick={onClose}
          text="Close"
          className="w-1/2"
        />
      </div>
    </CustomModal>
  );
}

export default DetailsModal;
