import { useDisclosure } from '@chakra-ui/hooks';
import { useGetCliTokens } from 'api/cli';
import HeaderDoc from 'components/header/HeaderDoc';
import PageHeader from 'components/header/PageHeader';
import React from 'react';
import { useCategoryStore } from 'store/categoryStore';
import CliTable from './components/CliTable';
import CliTokenModal from './components/CliTokenModal';

const DashboardCliTokens = () => {
  const [filter, setFilter] = React.useState('');
  const getCliTokens = useGetCliTokens();
  const addCategoryModalActions = useDisclosure();
  const { setCategory } = useCategoryStore(state => state);
  const deleteCategoryModalActions = useDisclosure();

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <CliTokenModal
        isOpen={addCategoryModalActions.isOpen}
        onClose={addCategoryModalActions.onClose}
      />

      {/* Header */}
      <PageHeader
        heading={'CLI Tokens'}
        text={
          <>
            CLI Tokens are used to authenticate publish through CLI.
            <HeaderDoc
              link="https://docs.engagespot.co/docs/engagespot-cli/overview"
              text="Learn more"
            />
          </>
        }
        buttonText="Create CLI Token"
        filter={filter}
        setFilter={setFilter}
        paginationType="table"
        handleCreate={() => {
          setCategory(null);
          addCategoryModalActions.onOpen();
        }}
      />

      {/* Tables */}
      <CliTable
        loading={getCliTokens.isLoading}
        tableData={getCliTokens?.data ?? []}
        editOpen={addCategoryModalActions.onOpen}
        deleteOpen={deleteCategoryModalActions.onOpen}
        globalFilter={filter}
        setGlobalFilter={setFilter}
      />
    </div>
  );
};

export default DashboardCliTokens;
