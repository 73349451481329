import { Channels } from 'api/channels/get-channels/types';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import { GetTemplateContent } from 'api/templates/get-template/types';
import { updateTemplates } from 'api/templates/update-templates';
import { useEditWorkflowJson } from 'api/workflows';
import Delete from 'assets/svg/template/delete.svg';
import Card from 'components/card';
import ErrorBox from 'components/error/ErrorBox';
import CheckField from 'components/fields/CheckField';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import { TextEditor } from 'components/text-editor';
import React from 'react';
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormReset,
  UseFormReturn,
  UseFormSetError,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { TemplateTabs, useTemplateStore } from 'store/templateStore';
import useCustomToast from 'utils/use-toast';
import { setUpdatedWorkflowJson } from 'views/admin/dashboards/workflowEditor/functions';
import { useGetEditorType } from '../../hooks/getEditorType';
import { useGetTemplateChannelData } from '../../hooks/getTemplateChannelData';
import { webHookDefaultValue } from '../../variables/defaultValue';
import { TemplateEditorAvailabeChannels } from '../../variables/inputs';
import TemplateEditorComponents from '../TemplateEditorComponents';
import TemplateEditorPreviewHeading from '../TemplateEditorPreviewHeading';
import TemplateEditorPreview from '../TemplatePreview';

const WebHookPreview = ({
  saveRef,
  form,
}: {
  saveRef: React.MutableRefObject<HTMLButtonElement>;
  form: UseFormReturn<FieldValues, any>;
}) => {
  const params = useParams();
  const templateId = params.templateId;
  const templateIdentifier = params.templateIdentifier;
  const channel = params.channel as TemplateEditorAvailabeChannels;
  const { editorTypeTemplate, editorTypeWorkflow } = useGetEditorType();

  const editWorkflowJson = useEditWorkflowJson();
  const { loading, setLoading, tab } = useTemplateStore(state => state);
  const toast = useCustomToast();
  const getTemplateBatchingContent = useGetTemplateChannelData();
  const savedTemplateData = React.useMemo(
    () => getTemplateBatchingContent,
    [getTemplateBatchingContent],
  );
  const templateTab = tab === 'template';
  const queryClient = useQueryClient();
  const isFetchChannel = channel === ('fetch' as Channels);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
    clearErrors,
    reset,
    setValue,
  } = form;

  const isBatchingEnabled = useWatch({
    control,
    name: 'batchingEnabled',
  });

  const convertObjKeyValues = (data: { [x: string]: string }[]) => {
    if (data?.length === 0) {
      return [
        {
          key: '',
          value: '',
        },
      ];
    }

    return data?.map(obj => ({
      key: Object.keys(obj)[0],
      value: Object.values(obj)[0],
    }));
  };

  React.useEffect(() => {
    const content = savedTemplateData?.content as GetTemplateContent;
    const batchingContent =
      savedTemplateData?.batchingContent as GetTemplateContent;

    if (!content) return;

    const headers = convertObjKeyValues(content.headers);
    const params = convertObjKeyValues(content.params);

    const filledData = {
      batchingEnabled: savedTemplateData.batchingEnabled,
      batchingWindow: savedTemplateData.batchingWindow,
      type: content.type,
      url: content.url,
      headers,
      params,
      body: isFetchChannel
        ? content?.body
        : JSON.stringify(content?.body, undefined, 4),
      ...(batchingContent
        ? {
            batching_type: batchingContent.type,
            batching_body: isFetchChannel
              ? batchingContent?.body
              : JSON.stringify(batchingContent?.body, undefined, 4),
            batching_headers: convertObjKeyValues(batchingContent?.headers),
            batching_params: convertObjKeyValues(batchingContent?.params),
          }
        : {
            batching_type: webHookDefaultValue['type'],
            batching_body: '',
            batching_headers: webHookDefaultValue['batching_headers'],
            batching_params: webHookDefaultValue['batching_params'],
          }),
    };

    reset(filledData);
  }, [savedTemplateData, reset, isFetchChannel]);

  const flatMapKeyValue = (item: any): any => {
    if (!Boolean(item.key) && !Boolean(item.value)) return [];

    return {
      [item.key]: item.value,
    };
  };

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);

      const data = {
        content: {
          type: values.type,
          ...(values.body && {
            body: isFetchChannel ? values.body : JSON.parse(values.body),
          }),
          ...(values.url && {
            url: values.url,
          }),
          headers: values.headers.flatMap(flatMapKeyValue),
          params: values.params.flatMap(flatMapKeyValue),
        },
      };

      if (editorTypeTemplate) {
        await updateTemplates({
          id: templateId,
          channelType: channel,
          data: data,
          type: 'put_content',
        });

        await updateTemplates({
          id: templateId,
          channelType: channel,
          data: {
            batchingEnabled: isBatchingEnabled,
            batchingWindow: parseInt(values.batchingWindow),
          },
          type: 'patch_content',
        });

        if (isBatchingEnabled) {
          const batchingData = {
            content: {
              type: values.batching_type,
              body: values.batching_body,
              headers: values.batching_headers.flatMap(flatMapKeyValue),
              params: values.batching_params.flatMap(flatMapKeyValue),
            },
          };

          await updateTemplates({
            id: templateId,
            channelType: channel,
            data: batchingData,
            type: 'put_batching_content',
          });
        }

        queryClient.invalidateQueries({
          queryKey: [`${routes['templates']}`],
        });
      }

      if (editorTypeWorkflow) {
        await setUpdatedWorkflowJson({
          templateIdentifier,
          template: data?.content,
          editWorkflowJson,
          templateId,
          updateConfigInstead: isFetchChannel,
        });
      }

      toast.success('template saved successfully');
      setLoading(false);
    } catch (e) {
      toast.showError(e);
      setLoading(false);
    }
  };

  return (
    <>
      {/* Left Side */}
      <Card
        extra={`${!isFetchChannel ? ' w-[70%]' : ' w-[99%]'}  p-6 overflow-scroll`}
      >
        {/* Preview Heading */}
        <TemplateEditorPreviewHeading />

        {/* Content */}
        <div className="mt-6 flex justify-between gap-10">
          {/* Inputs */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={`flex ${!isFetchChannel ? ' w-[70%]' : ' w-[60%]'} flex-col gap-3`}
          >
            {!templateTab && (
              <>
                <CheckField<any>
                  label={'Enable Batching'}
                  placeholder={''}
                  showIsRequiredAsterisk={false}
                  register={register}
                  name={'batchingEnabled'}
                  required={false}
                />

                <InputField<any>
                  variant="auth"
                  label={'Batching Window (in ms)'}
                  placeholder={'Enter batching window (in ms)'}
                  type="text"
                  showIsRequiredAsterisk={false}
                  register={register}
                  name={`batchingWindow`}
                  extraLabelClass={'font-medium'}
                  extraInputClass={
                    'border dark:bg-night-100 focus:border border-[#525151]'
                  }
                  disabled={
                    tab === 'batched_template'
                      ? !Boolean(isBatchingEnabled)
                      : false
                  }
                />
              </>
            )}

            {templateTab ? (
              <FormFields
                key={'default_form_field'}
                control={control}
                register={register}
                tab={tab}
                isBatchingEnabled={isBatchingEnabled}
                templateTab={templateTab}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                reset={reset}
                setValue={setValue}
              />
            ) : (
              <FormFields
                key={'batching_form_field'}
                control={control}
                register={register}
                tab={tab}
                isBatchingEnabled={isBatchingEnabled}
                templateTab={templateTab}
                clearErrors={clearErrors}
                setError={setError}
                errors={errors}
                setValue={setValue}
                reset={reset}
              />
            )}

            <button
              type="submit"
              disabled={loading}
              hidden={true}
              ref={saveRef}
            >
              submit
            </button>
          </form>

          {/* Preview */}
          <TemplateEditorPreview form={form} />
        </div>
      </Card>

      {/* Right Side */}
      {!isFetchChannel && (
        <Card extra={'w-[30%] relative p-4 overflow-scroll'}>
          {/* Components */}
          <TemplateEditorComponents />
        </Card>
      )}
    </>
  );
};

const FormFields = ({
  control,
  register,
  tab,
  isBatchingEnabled,
  templateTab,
  clearErrors,
  setError,
  errors,
  reset,
  setValue,
}: {
  control: Control<FieldValues, any>;
  register: UseFormRegister<FieldValues>;
  tab: TemplateTabs;
  isBatchingEnabled: any;
  templateTab: boolean;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  errors: FieldErrors<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  const namePrefix = templateTab ? '' : 'batching_';
  const { editorTypeWorkflow } = useGetEditorType();

  const requestTypeOptions = [
    {
      label: 'GET',
      value: 'get',
    },
    {
      label: 'POST',
      value: 'post',
    },
    {
      label: 'PUT',
      value: 'put',
    },
  ];

  return (
    <>
      {editorTypeWorkflow && (
        <InputField<any>
          variant="auth"
          label={'Url'}
          placeholder={'Enter url'}
          type="text"
          register={register}
          name={`${namePrefix}url`}
          extraLabelClass={'font-medium'}
          extraInputClass={
            'border dark:bg-night-100 focus:border border-[#525151]'
          }
          disabled={
            tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
          }
        />
      )}

      <SelectField
        variant="styled"
        control={control}
        extra="mb-3"
        label={'Type'}
        placeholder={''}
        showIsRequiredAsterisk={true}
        required={true}
        name={`${namePrefix}type`}
        extraInputClass="dark:!bg-night-100 border-2 border-[#525151]"
        options={requestTypeOptions}
        disabled={
          tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
        }
      />
      {errors['type'] && <ErrorBox error={errors['type']} />}

      <FormArrayFields
        control={control}
        name={`${namePrefix}headers`}
        register={register}
        tab={tab}
        isBatchingEnabled={isBatchingEnabled}
        label={'Header'}
        errors={errors}
        reset={reset}
        setValue={setValue}
      />

      <FormArrayFields
        control={control}
        name={`${namePrefix}params`}
        register={register}
        tab={tab}
        isBatchingEnabled={isBatchingEnabled}
        label={'Param'}
        errors={errors}
        reset={reset}
        setValue={setValue}
      />

      <Controller
        name={`${namePrefix}body`}
        control={control}
        render={({ field: { ref, ...field }, fieldState: { error } }) => {
          return (
            <TextEditor
              {...field}
              label={'Body'}
              setError={setError}
              clearErrors={clearErrors}
              error={error}
              height="225px"
              className="border-2 border-[#525151] focus:border dark:bg-night-100"
              disabled={
                tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false
              }
            />
          );
        }}
      />
      {errors[`${namePrefix}body`] && (
        <ErrorBox error={errors[`${namePrefix}body`]} />
      )}
    </>
  );
};

const FormArrayFields = ({
  control,
  name,
  register,
  tab,
  isBatchingEnabled,
  label,
  errors,
  reset,
  setValue,
}: {
  control: Control<FieldValues, any>;
  name: string;
  register: UseFormRegister<FieldValues>;
  tab: TemplateTabs;
  isBatchingEnabled: any;
  label: string;
  errors: FieldErrors<FieldValues>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: name,
  });

  const isDisabled =
    tab === 'batched_template' ? !Boolean(isBatchingEnabled) : false;

  return (
    <div className="mb-3 flex flex-col gap-2">
      <label className="">{label}</label>
      <ul className="flex flex-col gap-5">
        {fields.map((item, index) => {
          let errorObj = (errors as any)?.[name]?.[index];

          if (errorObj?.message) {
            errorObj = errorObj?.message;
          }

          const keyError = errorObj?.key;
          const valueError = errorObj?.value;

          return (
            <>
              <li
                className={`relative flex ${
                  errorObj ? 'items-center' : 'items-end '
                }  gap-3`}
                key={item.id}
              >
                <div className=" flex w-[80%] items-start gap-3">
                  <div className="w-full">
                    <InputField<any>
                      variant="auth"
                      label={`${label} Key`}
                      placeholder={''}
                      type="text"
                      showIsRequiredAsterisk={false}
                      register={register}
                      name={`${name}.${index}.key`}
                      extraLabelClass={'font-medium'}
                      extraInputClass={
                        'border dark:bg-night-100 focus:border border-[#525151] w-full'
                      }
                      disabled={isDisabled}
                      extra="w-full"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue(`${name}.${index}.key`, e.target.value, {
                          shouldDirty: true,
                        })
                      }
                    />
                    {keyError && <ErrorBox error={keyError} />}
                  </div>

                  <div className="w-full">
                    <InputField<any>
                      variant="auth"
                      label={`${label} Value`}
                      placeholder={''}
                      type="text"
                      showIsRequiredAsterisk={false}
                      register={register}
                      name={`${name}.${index}.value`}
                      extraLabelClass={'font-medium'}
                      extraInputClass={
                        'border dark:bg-night-100 focus:border border-[#525151]'
                      }
                      disabled={isDisabled}
                      extra="w-full"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setValue(`${name}.${index}.value`, e.target.value, {
                          shouldDirty: true,
                        })
                      }
                    />
                    {valueError && <ErrorBox error={valueError} />}
                  </div>
                </div>

                <div className="flex w-[20%] items-start gap-3">
                  {fields.length - 1 === index && (
                    <button
                      type="button"
                      onClick={() =>
                        append({
                          key: '',
                          value: '',
                        })
                      }
                      className={`self-end rounded-xl bg-[#00A86B] px-5 py-2 text-xl font-medium text-white disabled:bg-gray-420`}
                      disabled={isDisabled}
                    >
                      +
                    </button>
                  )}

                  {index !== 0 && (
                    <button
                      type="button"
                      onClick={() => {
                        remove(index);

                        reset(undefined, {
                          keepValues: true,
                          keepDirty: false,
                          keepDefaultValues: false,
                        });
                      }}
                      className={`self-end rounded-xl bg-[#FC5A5A] px-4 py-3 text-base font-medium text-white disabled:bg-gray-420`}
                      disabled={isDisabled}
                    >
                      <img width={18} src={Delete} alt="" />
                    </button>
                  )}
                </div>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default WebHookPreview;
