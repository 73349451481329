import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { RiEyeCloseLine } from 'react-icons/ri';
import { yupResolver } from '@hookform/resolvers/yup';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { passwordSchema } from '../schema';
import { FormChangePassword } from '../types';
import useCustomToast from 'utils/use-toast';
import useGetMe from 'api/profile/get-me';
import usePutPassword from 'api/profile/change-password/put-password';
import ErrorBox from 'components/error/ErrorBox';
import LoaderSpinner from 'components/loader/LoaderSpinner';
import React from 'react';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';

type ShowInputs = {
  currentPassword: boolean;
  newPassword: boolean;
  confirmNewPassword: boolean;
};

const EyeButton = ({
  show,
  setShow,
  id,
}: {
  show: ShowInputs;
  setShow: React.Dispatch<React.SetStateAction<ShowInputs>>;
  id: 'currentPassword' | 'newPassword' | 'confirmNewPassword';
}) => {
  const togglePasswordView = () =>
    setShow(prev => ({
      ...prev,
      [id]: !prev[id],
    }));

  return (
    <button
      type="button"
      onClick={togglePasswordView}
      className="absolute right-4 top-1/2 translate-y-1/2 text-white"
    >
      {show[id] ? <RiEyeCloseLine /> : <MdOutlineRemoveRedEye />}
    </button>
  );
};

const Password = () => {
  const toast = useCustomToast();
  const getMe = useGetMe();
  const clientId = getMe.data?.clientUser?.id;
  const putChangePassword = usePutPassword();
  const [show, setShow] = React.useState<ShowInputs>({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const defaultValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormChangePassword>({
    resolver: yupResolver(passwordSchema),
    reValidateMode: 'onChange',
    defaultValues,
  });

  const onSubmit: SubmitHandler<FormChangePassword> = values => {
    const data = {
      ...values,
      clientId,
    };

    putChangePassword.mutate(data, {
      onSuccess: () => {
        toast.success('password change success');
        reset({ ...defaultValues });
      },
      onError: error => {
        toast.showError(error);
      },
    });
  };

  return (
    <Card extra={'w-full p-4'}>
      {/* Header */}
      <div className="w-full px-2">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Change Password
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can set your new password
        </p>
      </div>
      {/* inputs */}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="mt-7 grid grid-cols-1 gap-3"
      >
        <div className="relative">
          <InputField<FormChangePassword>
            variant="auth"
            extra=""
            label="Old Password"
            placeholder="Your Old Password"
            id="password"
            showIsRequiredAsterisk={true}
            register={register}
            name={'currentPassword'}
            type={show['currentPassword'] ? 'text' : 'password'}
          />
          <EyeButton show={show} setShow={setShow} id="currentPassword" />
        </div>

        {errors.currentPassword && <ErrorBox error={errors.currentPassword} />}

        <div className="relative">
          <InputField<FormChangePassword>
            variant="auth"
            extra=""
            label="New Password"
            placeholder="Your New Password"
            id="password"
            showIsRequiredAsterisk={true}
            register={register}
            name={'newPassword'}
            type={show['newPassword'] ? 'text' : 'password'}
          />
          <EyeButton show={show} setShow={setShow} id="newPassword" />
        </div>

        {errors.newPassword && <ErrorBox error={errors.newPassword} />}

        <div className="relative">
          <InputField<FormChangePassword>
            variant="auth"
            extra=""
            label="New Password Confirmation"
            placeholder="Confirm New Password"
            id="password"
            showIsRequiredAsterisk={true}
            register={register}
            name={'confirmNewPassword'}
            type={show['confirmNewPassword'] ? 'text' : 'password'}
          />
          <EyeButton show={show} setShow={setShow} id="confirmNewPassword" />
        </div>
        {errors.confirmNewPassword && (
          <ErrorBox error={errors.confirmNewPassword} />
        )}

        <div className="mt-12 flex w-full justify-end">
          <button
            disabled={putChangePassword.isLoading}
            className={cn(
              buttonVariants({
                className: 'rounded-md text-base disabled:opacity-75',
              }),
            )}
          >
            Change Password
            {putChangePassword.isLoading && (
              <LoaderSpinner
                strokeColor="black"
                parentClass="relative text-gray left-2"
              />
            )}
          </button>
        </div>
      </form>
    </Card>
  );
};

export default Password;
