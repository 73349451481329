import { useMutation } from '@tanstack/react-query';
import { axiosGlobalInstance } from 'api/axios/global';
import { routes } from 'api/routes';
import Cookies from 'js-cookie';
import { useAuthStore } from 'store/authStore';

type TPostVerifyEmail = {
  verificationCode: string;
};

const post = async (values: TPostVerifyEmail) => {
  const { verificationCode } = values;
  return axiosGlobalInstance.post(
    `${routes['verifyEmail']}/${verificationCode}`,
  );
};

const usePostVerifyEmail = () => {
  const { reset } = useAuthStore(state => state);

  return useMutation({
    mutationFn: (values: TPostVerifyEmail) => post(values),
    onSuccess: response => {
      reset();
      const token = response.data.token;
      Cookies.set('access_token', token);
    },
  });
};

export default usePostVerifyEmail;
