import Bin from 'assets/svg/common/bin.svg';
import InviteUser from 'assets/svg/teams/invite-user.svg';
import { FormInviteUsers } from '../types';

const InvitationCard = ({
  item,
  invitations,
  setInvitations,
}: {
  item: FormInviteUsers;
  invitations: FormInviteUsers[];
  setInvitations: React.Dispatch<React.SetStateAction<FormInviteUsers[]>>;
}) => {
  const handleDelete = () => {
    const alreadyAddedEmail = invitations.filter(
      invite => invite.clientUserEmail !== item.clientUserEmail,
    );

    setInvitations(alreadyAddedEmail);
  };

  const handleRoleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    const index = invitations.findIndex(
      invite => invite.clientUserEmail === item.clientUserEmail,
    );

    if (index === -1) return; // not found

    const tempInvite = [...invitations];
    tempInvite[index] = {
      clientUserEmail: item.clientUserEmail,
      role: value,
    };

    setInvitations(tempInvite);
  };

  return (
    <div className="mb-5 flex justify-between">
      <div className="flex items-center gap-3">
        <img src={InviteUser} alt="invite user" />

        <div>
          <h4 className="text-white">{item.clientUserEmail}</h4>
        </div>
      </div>

      <div className="flex items-center gap-3">
        <select
          onChange={e => handleRoleChange(e)}
          value={item.role}
          className="csm-select-dropdown pl-4 flex h-[48px] w-fit appearance-none items-center justify-center rounded-xl bg-white/0 !pr-8  text-sm outline-none dark:!border-white/50 dark:bg-night-100 dark:text-white"
        >
          <option value="admin">Admin</option>
          <option value="user">User</option>
        </select>

        <button onClick={handleDelete}>
          <img className="scale-75" src={Bin} alt="bin" />
        </button>
      </div>
    </div>
  );
};

export default InvitationCard;
