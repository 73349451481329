import { useQuery, QueryFunctionContext } from '@tanstack/react-query';
import { axiosInstance } from 'api/axios';
import { routes } from 'api/routes';
import { useAuthStore } from 'store/authStore';
import { GetTemplate } from '../get-template/types';

const get = async ({
  queryKey,
}: QueryFunctionContext<[string, number]>): Promise<GetTemplate[]> => {
  const [, appId] = queryKey;
  const { data } = await axiosInstance.get(
    `${routes['apps']}/${appId}/templates`,
  );
  return data;
};

const useGetAppTemplates = () => {
  const { appId } = useAuthStore(state => state);

  return useQuery({
    queryKey: [`${routes['apps']}/templates`, appId],
    queryFn: get,
    enabled: Boolean(appId),
  });
};

export default useGetAppTemplates;
