import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { routes } from 'api/routes';
import useGetTemplate from 'api/templates/get-template';
import usePatchTemplateContent from 'api/templates/patch-content';
import Delete from 'assets/svg/template/delete.svg';
import ErrorBox from 'components/error/ErrorBox';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import { SelectOptions } from 'components/fields/SelectField/types';
import { CustomModal, CustomModalButtons } from 'components/modal';
import React from 'react';
import {
  Control,
  FieldValues,
  SubmitHandler,
  UseFormSetValue,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useCustomToast from 'utils/use-toast';
import * as yup from 'yup';
import { ChannelWorkflow } from '../variables/data';

export const schema = yup.object({
  trigger: yup.string().required('Trigger is required'),
  triggerDelay: yup
    .number()
    .typeError('Trigger Delay must be a number')
    .max(60000)
    .typeError('Max value should be <= 60000'),
});

export type ChannelTriggers = 'always' | 'delayed';

type FormTriggerConditions = {
  trigger?: ChannelTriggers;
  triggerDelay?: number;
  triggerCondition?: string;
  triggerConditionBuilder?: {
    connector: string;
    channel: string;
    event: string;
    operator: string;
    value: string;
  }[];
};

function TriggerModal({
  isOpen,
  onClose,
  selected,
  channelOptions,
}: {
  onClose: () => void;
  isOpen: boolean;
  selected: ChannelWorkflow['channels']['x'];
  channelOptions: SelectOptions[];
}) {
  const patchContent = usePatchTemplateContent();
  const toast = useCustomToast();
  const params = useParams();
  const templateId = params.templateId;
  const getTemplate = useGetTemplate(Number(templateId));
  const queryClient = useQueryClient();
  const currentChannelConfig = getTemplate.data?.contents?.find(
    item => item.channel === selected.channelIdentifier,
  );

  const defaultTriggerConditionBuilder = React.useMemo(
    () => ({
      connector: '&&',
      channel: channelOptions?.[0]?.value,
      event: 'delivered',
      operator: '===',
      value: 'false',
    }),
    [channelOptions],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm<FormTriggerConditions>({
    resolver: yupResolver(schema),
    defaultValues: {
      trigger: 'always',
      triggerConditionBuilder: [defaultTriggerConditionBuilder],
    },
  });

  React.useEffect(() => {
    if (patchContent.isLoading) return;

    if (currentChannelConfig) {
      function stringToArrayOfObjects(str: string) {
        if (!Boolean(str)) return [];

        const connectorRegex = /(&&|\|\|)/g;
        const connectorRegexMatches = str.match(connectorRegex);
        const array = str.split(/&&|\|\|/);
        const availableChannelIdentifiers = channelOptions.map(
          option => option.value,
        );

        const result = array.reduce((acc, element, index) => {
          const subarray = element.split('.');
          const subsubarray = subarray[subarray.length - 1].split(/===|!==/);

          const operator = subarray[subarray.length - 1].split(' ')[1]?.trim();
          const channel = subarray[2]?.trim();
          const event = subsubarray[0]?.trim();
          const value = subsubarray[1]?.trim();
          const connector = connectorRegexMatches?.[index - 1] ?? '';

          if (!availableChannelIdentifiers.includes(channel)) {
            return acc;
          }

          let obj = {
            connector,
            channel,
            event,
            operator,
            value,
          };

          return [...acc, obj];
        }, []);

        return result;
      }

      reset({
        trigger: currentChannelConfig.trigger,
        triggerDelay: currentChannelConfig.triggerDelay,
        triggerCondition: currentChannelConfig.triggerCondition,
        triggerConditionBuilder: stringToArrayOfObjects(
          currentChannelConfig.triggerCondition,
        ),
      });
    }
  }, [
    reset,
    currentChannelConfig,
    isOpen,
    defaultTriggerConditionBuilder,
    patchContent.isLoading,
    channelOptions,
  ]);

  const handleClose = () => {
    onClose();
    reset({});
  };

  const onSubmit: SubmitHandler<FormTriggerConditions> = values => {
    const triggerCondition = values.triggerConditionBuilder?.reduce(
      (acc, condition, index) => {
        let data = `data.channel.${condition.channel}.${condition.event} ${condition.operator} ${condition.value}`;

        if (index > 0) {
          data = ` ${condition.connector} ${data}`;
        }

        return acc + data;
      },
      '',
    );

    patchContent.mutate(
      {
        channelType: selected.channelIdentifier,
        templateId: templateId,
        data: { ...values, triggerCondition },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([routes['templates']]);
          toast.success('channel triggers updated successfully');
          handleClose();
        },
        onError: (error: any) => {
          toast.showError(error);
        },
      },
    );
  };

  const options = [
    {
      label: 'ALWAYS',
      value: 'always',
    },
    {
      label: 'DELAYED',
      value: 'delayed',
    },
  ];

  const watchTrigger = useWatch({
    control,
    name: 'trigger',
  });

  return (
    <CustomModal
      modalContentClassName="!min-w-[885px] !top-[20%]"
      modalCardClassName="max-h-[600px] overflow-scroll"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <h1 className="mb-3 text-2xl font-bold">
        Trigger Configuration - {selected.content}
      </h1>
      <p className="mb-6 text-sm text-[#BFBFBF]">
        Read docs to learn more about channel triggers and their configurations
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-center gap-5">
          <div className="w-full">
            <SelectField<FormTriggerConditions>
              variant="styled"
              styleVariant="modal"
              control={control}
              extra="mb-3"
              label={'Trigger'}
              placeholder={''}
              showIsRequiredAsterisk={true}
              required={true}
              name={'trigger'}
              options={options}
              disabled={false}
            />
            {errors.trigger && <ErrorBox error={errors.trigger} />}
          </div>

          <div className="w-full">
            <InputField<FormTriggerConditions>
              variant="modal"
              extra="mb-3"
              label={'Trigger Delay (ms)'}
              placeholder="Trigger delay in milliseconds. Max is 60,000ms (1min)"
              showIsRequiredAsterisk={false}
              type="number"
              register={register}
              name={'triggerDelay'}
              disabled={watchTrigger !== 'delayed'}
            />
            {errors.triggerDelay && <ErrorBox error={errors.triggerDelay} />}
          </div>
        </div>

        {channelOptions.length > 0 && (
          <>
            <FormArrayFields
              control={control}
              label="Trigger Condition"
              name="triggerConditionBuilder"
              defaultValues={defaultTriggerConditionBuilder}
              disabled={watchTrigger !== 'delayed'}
              channelOptions={channelOptions}
              setValue={setValue}
            />
            {errors.triggerConditionBuilder && (
              <ErrorBox error={errors.triggerConditionBuilder} />
            )}
          </>
        )}

        <CustomModalButtons
          loading={patchContent.isLoading}
          handleClose={handleClose}
          containerClassName="mx-auto mt-6 flex w-1/2 gap-4"
        />
      </form>
    </CustomModal>
  );
}

const FormArrayFields = ({
  control,
  name,
  label,
  defaultValues,
  disabled,
  channelOptions,
  setValue,
}: {
  control: Control<FieldValues, any>;
  name: string;
  label: string;
  defaultValues: any;
  disabled: boolean;
  channelOptions: SelectOptions[];
  setValue: UseFormSetValue<FormTriggerConditions>;
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const eventOptions = [
    {
      label: 'Delivered',
      value: 'delivered',
    },
    {
      label: 'Seen',
      value: 'seen',
    },
    {
      label: 'Interacted',
      value: 'interacted',
    },
  ];

  const operatorOptions = [
    {
      label: 'Equals',
      value: '===',
    },
    {
      label: 'Not Equals',
      value: '!==',
    },
  ];

  const valueOptions = [
    {
      label: 'True',
      value: 'true',
    },
    {
      label: 'False',
      value: 'false',
    },
  ];

  const triggerConditionBuilderWatch = useWatch({
    name: 'triggerConditionBuilder',
    control,
  });

  return (
    <div className="mb-3 flex flex-col gap-2">
      <label className="">{label}</label>

      {fields.length === 0 && (
        <div className="mt-3">
          <button
            type="button"
            onClick={() => append(defaultValues)}
            className={`self-end rounded-md bg-white px-5 py-2 text-sm font-medium text-black disabled:bg-gray-420 disabled:text-white/30`}
            disabled={disabled}
          >
            Create Trigger Condition
          </button>
        </div>
      )}

      <ul className="flex flex-col gap-5">
        {fields.map((item, index) => {
          const connectorValue =
            triggerConditionBuilderWatch?.[index]?.connector;

          return (
            <>
              <li className="flex flex-col" key={item.id}>
                {index !== 0 && (
                  <div className="relative right-3 mb-4 flex w-[85%] justify-center gap-3">
                    <button
                      onClick={() =>
                        setValue(
                          `triggerConditionBuilder.${index}.connector`,
                          '&&',
                        )
                      }
                      type="button"
                      className={`self-end rounded-xl ${
                        connectorValue === '&&'
                          ? 'bg-white text-black'
                          : 'bg-gray-850 text-white'
                      }  px-4 py-3 text-base font-medium  disabled:bg-gray-420`}
                    >
                      AND
                    </button>

                    <button
                      type="button"
                      className={`self-end rounded-xl ${
                        connectorValue === '||'
                          ? 'bg-white text-black'
                          : 'bg-gray-850 text-white'
                      }  px-4 py-3 text-base font-medium  disabled:bg-gray-420`}
                      onClick={() =>
                        setValue(
                          `triggerConditionBuilder.${index}.connector`,
                          '||',
                        )
                      }
                    >
                      OR
                    </button>
                  </div>
                )}

                <div className="flex w-full items-end gap-3">
                  <div className="flex w-[85%] items-end gap-3">
                    <SelectField<FormTriggerConditions>
                      variant="styled"
                      styleVariant="modal"
                      control={control}
                      extra="mb-3 w-full"
                      label={'Channel'}
                      placeholder={''}
                      showIsRequiredAsterisk={false}
                      required={true}
                      name={`triggerConditionBuilder.${index}.channel`}
                      options={channelOptions}
                      disabled={disabled}
                    />

                    <SelectField<FormTriggerConditions>
                      variant="styled"
                      styleVariant="modal"
                      control={control}
                      extra="mb-3 w-full"
                      label={'Event'}
                      placeholder={''}
                      showIsRequiredAsterisk={false}
                      required={true}
                      name={`triggerConditionBuilder.${index}.event`}
                      options={eventOptions}
                      disabled={disabled}
                    />

                    <SelectField<FormTriggerConditions>
                      variant="styled"
                      styleVariant="modal"
                      control={control}
                      extra="mb-3 w-full"
                      label={'Operator'}
                      placeholder={''}
                      showIsRequiredAsterisk={false}
                      required={true}
                      name={`triggerConditionBuilder.${index}.operator`}
                      options={operatorOptions}
                      disabled={disabled}
                    />

                    <SelectField<FormTriggerConditions>
                      variant="styled"
                      styleVariant="modal"
                      control={control}
                      extra="mb-3 w-full"
                      label={'Value'}
                      placeholder={''}
                      showIsRequiredAsterisk={false}
                      required={true}
                      name={`triggerConditionBuilder.${index}.value`}
                      options={valueOptions}
                      disabled={disabled}
                    />
                  </div>

                  <div className="mb-3 flex w-[15%] items-end gap-3">
                    {fields.length - 1 === index && (
                      <button
                        type="button"
                        onClick={() => append(defaultValues)}
                        className={`self-end rounded-xl bg-[#00A86B] px-5 py-2 text-xl font-medium text-white disabled:bg-gray-420`}
                        disabled={disabled}
                      >
                        +
                      </button>
                    )}

                    <button
                      type="button"
                      onClick={() => remove(index)}
                      className={`self-end rounded-xl bg-[#FC5A5A] px-4 py-3 text-base font-medium text-white disabled:bg-gray-420`}
                      disabled={disabled}
                    >
                      <img width={18} src={Delete} alt="" />
                    </button>
                  </div>
                </div>
              </li>
            </>
          );
        })}
      </ul>
    </div>
  );
};

export default TriggerModal;
