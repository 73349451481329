import * as yup from 'yup';

export const schema = yup.object({
  email: yup
    .string()
    .trim()
    .required('email is required')
    .email('email must be valid'),
  password: yup.string().trim().required('password is required'),
});
