import Chip from 'components/chip';

const CustomSetUp = <T extends unknown>(props: {
  name: string;
  py?: string;
  border?: string;
  img?: string;
  chipText?: string;
  actionButtons: (props: T) => JSX.Element;
  actionProps?: T;
  iconText?: string;
}) => {
  const {
    name,
    py,
    border,
    img,
    actionButtons,
    chipText,
    actionProps,
    iconText,
  } = props;

  return (
    <div
      className={`flex items-center justify-between px-8 dark:!border-white/10 md:flex-row ${py} ${border} `}
    >
      <div className="flex items-center gap-5">
        {img && (
          <div className="h-10 w-10 rounded-full bg-white p-2">
            <img src={img} alt="" />
          </div>
        )}

        {iconText && (
          <button className="h-10 w-10 rounded-full bg-night-100 text-xl text-white">
            {iconText}
          </button>
        )}

        <p className="text-base font-bold text-navy-700 dark:text-white md:mb-0">
          {name}
        </p>

        {chipText && <Chip text={'Configured'} />}
      </div>

      <div className="flex flex-col items-center justify-center gap-5 md:flex-row">
        {actionButtons(actionProps)}
      </div>
    </div>
  );
};

export default CustomSetUp;
