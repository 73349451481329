import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/hooks';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@radix-ui/react-hover-card';
import { useQueryClient } from '@tanstack/react-query';
import { GetProviders } from 'api/providers/get-providers/types';
import useSaveProvider from 'api/providers/save-provider';
// import Plus from 'assets/svg/common/plus.svg';
import Chip, { chipBgColors, chipTextColors } from 'components/chip';
import { PiWarningCircleBold } from 'react-icons/pi';
import { useParams } from 'react-router-dom';
import { useAuthStore } from 'store/authStore';
import useCustomToast from 'utils/use-toast';
import { handleProviderSave } from '../functions';
import ConfigModal from './ConfigModal';
import ProviderActionMenu from './ProviderActionMenu';
import { cn } from 'utils/class-merge';
import { buttonVariants } from 'components/shadcn/button';
import { Plus } from 'lucide-react';

function ProvidersCard({
  provider,
  isAddedInRoute = false,
  firstConfiguredProvider = false,
  providerIdentifier,
}: {
  provider:
    | GetProviders['availableProviders'][0]
    | GetProviders['configuredProviders'][0];
  isAddedInRoute?: boolean;
  firstConfiguredProvider?: boolean;
  providerIdentifier: string;
}) {
  const params = useParams();
  const channel = params.channel;
  const [searchParams] = useSearchParams();
  const redirectBack = new URLSearchParams(searchParams).get('redirectBack');
  const configModalActions = useDisclosure();
  const active = 'enabled' in provider ? provider?.enabled : false;
  const saveProvider = useSaveProvider();
  const toast = useCustomToast();
  const queryClient = useQueryClient();
  const { appId } = useAuthStore(state => state);
  const configured = 'id' in provider;
  const navigate = useNavigate();

  const handleSetConfig = () => {
    if (configured) return configModalActions.onOpen();
    configModalActions.onOpen();
  };

  const handleSubmit = ({
    handleClose,
    values,
  }: {
    values: any;
    handleClose: () => void;
  }) => {
    return handleProviderSave({
      handleClose,
      values,
      appId,
      provider,
      saveProvider,
      toast,
      channel,
      firstConfiguredProvider,
      queryClient,
      invalidateRoutes: true,
      redirectBack: redirectBack === 'true' ? true : false,
      invalidateChannels: redirectBack === 'true' ? true : false,
      navigate,
    });
  };

  return (
    <button
      onClick={handleSetConfig}
      className={`group relative flex border border-input items-center gap-4 rounded-[20px]  bg-night-100 px-4 py-4 hover:bg-night-100/75 ${
        configured && !isAddedInRoute ? 'border border-orange-400' : ''
      }`}
    >
      <ConfigModal
        isLoading={saveProvider.isLoading}
        isOpen={configModalActions.isOpen}
        onClose={configModalActions.onClose}
        provider={provider}
        saveProviderSubmit={handleSubmit}
        providerIdentifier={providerIdentifier}
      />

      {configured && (
        <div className="absolute right-1 top-1 flex items-center">
          {!isAddedInRoute && (
            <>
              <HoverCard>
                <HoverCardTrigger>
                  <PiWarningCircleBold size={20} color="orange" />
                </HoverCardTrigger>

                <HoverCardContent className="relative top-2 z-[100] !w-[250px] rounded-xl border border-orange-400 bg-gray-850 p-2 text-sm">
                  {`This provider is currently not assigned to any active routes, which means notifications won't be sent throught it. To enable notifications, please add this provider to a route.`}
                </HoverCardContent>
              </HoverCard>
            </>
          )}

          <ProviderActionMenu
            provider={provider}
            buttonClass={'group-hover:bg-night-100/75'}
            handleConfigModalOpen={configModalActions.onOpen}
          />
        </div>
      )}

      {/* Image */}
      <div
        className={`h-12 w-12 ${
          configured ? 'self-start' : ''
        }  rounded-full bg-white p-3`}
      >
        <img src={configured ? provider.provider.icon : provider.icon} alt="" />
      </div>

      <div className="flex flex-col items-start justify-start">
        <p className="mb-1 text-left  text-base font-bold">
          {configured ? provider.provider.name : provider.name}
        </p>

        {configured && (
          <p className="mb-1 text-left  text-xs font-bold text-gray-600">
            {provider.instanceName ?? 'DEFAULT INSTANCE'}
          </p>
        )}

        <div className="flex items-center  gap-3">
          <span className="font-medium capitalize text-[#83858A]">
            {provider.channel}
          </span>

          {/* Chip */}
          {configured && !active && (
            <div className="w-fit">
              <Chip
                text={active ? 'Active' : 'Disabled'}
                className={`${chipBgColors['green']} ${
                  active ? chipTextColors['green'] : chipTextColors['yellow']
                }`}
              />
            </div>
          )}
        </div>
      </div>

      {/* Submit  */}
      {!configured && (
        <div className="ml-auto flex gap-2">
          <button
            className={cn(
              buttonVariants({
                size: 'default',
                className: 'rounded-md text-base text-black',
              }),
            )}
          >
            <Plus className="scale-[65%]" />
            Add
          </button>
        </div>
      )}
    </button>
  );
}

export default ProvidersCard;
