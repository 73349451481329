import { yupResolver } from '@hookform/resolvers/yup';
import ErrorBox from 'components/error/ErrorBox';
import SelectField from 'components/fields/SelectField';
import TextField from 'components/fields/TextField';
import { produce } from 'immer';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useWorkflowStore from 'store/workflowStore';
import * as yup from 'yup';
import { useShallow } from 'zustand/react/shallow';
import { findSelectedNodeFromJson, handleJsonDiffCheck } from '../../functions';
import {
  useFormValidateOnLoad,
  useGetWorkflowChannels,
  useIsDataLoaded,
  useWatchWorkflowForm,
  useWatchWorkflowFormErrors,
} from '../../hooks';
import WorkflowConditionForm from '../condition_builder/WorkflowCondition';
import { WorkflowFormHeader } from './WorkflowFormHeader';

type FormWorkflowUpdatePreferences = {
  channel?: string;
  preferenceExpression?: string;
  triggerCondition?: string;
  categoryIdentifier?: string;
};

const schema = yup.object({
  channel: yup.string().required('Selecting a channel is required'),
});

const WorkflowPreferencesForm = () => {
  const { dataLoaded, setDataLoaded } = useIsDataLoaded();
  const { enabledChannels } = useGetWorkflowChannels();
  const enabledChannelsOptions = enabledChannels?.map(item => ({
    label: item.channel,
    value: item.channel,
  }));

  const { selectedNode, workflowJson, setWorkflowJson } = useWorkflowStore(
    useShallow(state => state),
  );

  const formMethods = useForm<FormWorkflowUpdatePreferences>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  const {
    register,
    reset,
    getValues,
    control,
    formState: { errors },
    trigger,
  } = formMethods;

  const handleChange = () => {
    const {
      channel,
      preferenceExpression,
      triggerCondition,
      categoryIdentifier,
    } = getValues();
    const selectedNodeId = selectedNode?.id;
    const jsonResult = produce(workflowJson, draft => {
      const selected = findSelectedNodeFromJson({
        steps: draft.steps,
        selectedNodeId,
      });

      selected.triggerCondition = triggerCondition;
      selected.config = {
        ...selected.config,
        channel,
        preferenceExpression,
        categoryIdentifier,
      };
    });

    handleJsonDiffCheck({
      workflowJson: jsonResult,
    });

    setWorkflowJson(jsonResult);
  };

  // Reset saved data
  React.useEffect(() => {
    if (dataLoaded) return;

    const selectedNodeId = selectedNode.id;
    const selectedNodeJson = findSelectedNodeFromJson({
      steps: workflowJson.steps,
      selectedNodeId,
    });
    const selectedConfig = selectedNodeJson?.config;
    const channel = selectedConfig.channel;

    reset({
      ...selectedConfig,
      triggerCondition: selectedNodeJson.triggerCondition,
      channel: Boolean(channel) ? channel : undefined,
    });

    setDataLoaded(true);
  }, [dataLoaded, reset, selectedNode, setDataLoaded, workflowJson]);

  useFormValidateOnLoad({
    dataLoaded,
    trigger,
  });

  useWatchWorkflowFormErrors({
    control,
  });

  useWatchWorkflowForm({
    control,
    handleChange,
  });

  return (
    <div>
      <WorkflowFormHeader
        heading="Update Preference"
        docKey="update_preference"
      />

      <div className="text-[#ABB0B8] font-medium mb-3">
        Update preference from actions
      </div>

      <FormProvider {...formMethods}>
        <form className="mt-6 flex flex-col gap-6">
          <div>
            <SelectField<FormWorkflowUpdatePreferences>
              variant="styled"
              control={control}
              extra="w-full"
              label={'Channel'}
              placeholder={'Select Channel'}
              extraInputClass="capitalize"
              name={'channel'}
              styleVariant="workflow"
              extraItemClass="capitalize"
              options={enabledChannelsOptions ?? []}
            />
            {errors.channel && <ErrorBox error={errors.channel} />}
          </div>

          <TextField<FormWorkflowUpdatePreferences>
            extraInputClass={
              'border dark:bg-night-100 focus:border border-[#525151]'
            }
            label={'Category Identifier'}
            placeholder={'Sample javascript expression'}
            register={register}
            name={'categoryIdentifier'}
            extraLabelClass={'font-medium'}
            variant="workflow"
          />

          <TextField<FormWorkflowUpdatePreferences>
            extraInputClass={
              'border dark:bg-night-100 focus:border border-[#525151]'
            }
            label={'Preference'}
            placeholder={'Sample javascript expression'}
            register={register}
            name={'preferenceExpression'}
            extraLabelClass={'font-medium'}
            variant="workflow"
          />

          <WorkflowConditionForm />
        </form>
      </FormProvider>
    </div>
  );
};

export default WorkflowPreferencesForm;
