import {
  CellContext,
  createColumnHelper,
  DeepKeys,
  HeaderContext,
} from '@tanstack/react-table';

export type ColumnConfig<T extends object> = {
  accessorKey: DeepKeys<T>;
  header?: string | ((info: HeaderContext<T, any>) => string | JSX.Element);
  cellFunction?: (info: CellContext<T, any>) => string | JSX.Element;
  size?: number;
  maxSize?: number;
}[];

export const columnGenerator = <T extends object>({
  columnConfig,
}: {
  columnConfig: ColumnConfig<T>;
}) => {
  const columnHelper = createColumnHelper<T>();

  return columnConfig.map(config =>
    columnHelper.accessor(config.accessorKey as any, {
      header: info =>
        typeof config.header === 'string'
          ? config.header
          : config.header?.(info) ?? null,
      cell: info => config.cellFunction?.(info) ?? info.getValue() ?? '-',
      ...(config.size && { size: config.size }),
      ...(config.maxSize && { maxSize: config.maxSize }),
    }),
  );
};
