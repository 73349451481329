import CustomTable from 'components/table';
import {
  ColumnConfig,
  columnGenerator,
} from 'components/table/column-generator';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getPercentage } from 'utils/functions';
import { TemplateAnalyticsData } from '../types';

function AnalyticsTemplatesTable(props: {
  tableData: TemplateAnalyticsData[];
  editOpen: () => void;
  globalFilter: string;
  setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}) {
  const { tableData, loading } = props;
  const navigate = useNavigate();

  type TemplateAction =
    | 'edit_templates'
    | 'delete_templates'
    | 'view_templates';

  const handleMenuClick = (
    selected: TemplateAnalyticsData,
    actionType: TemplateAction,
  ) => {
    if (actionType === 'view_templates') {
      return navigate(`/admin/analytics?templateId=${selected.templateId}`);
    }
  };
  const columnConfig: ColumnConfig<TemplateAnalyticsData> = [
    {
      accessorKey: 'templateName',
      header: 'Template Name',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'templateIdentifier',
      header: 'Template Identifier',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'sent',
      header: 'Triggers',
      cellFunction: info => info.getValue(),
    },
    {
      accessorKey: 'seen',
      header: 'Seen %',
      cellFunction: info => {
        const totalSent = info.row.original.sent;
        return `${getPercentage(info.getValue(), totalSent)} %`;
      },
    },
    {
      accessorKey: 'interacted',
      header: 'Click %',
      cellFunction: info => {
        const totalSent = info.row.original.sent;
        return `${getPercentage(info.getValue(), totalSent)} %`;
      },
    },
    {
      accessorKey: 'discarded',
      header: 'Discarded %',
      cellFunction: info => {
        const totalSent = info.row.original.sent;
        return `${getPercentage(info.getValue(), totalSent)} %`;
      },
      size: 150,
    },
  ];

  const columns = columnGenerator<TemplateAnalyticsData>({
    columnConfig,
  });

  return (
    <>
      <div className="mt-8 overflow-visible">
        {/* table */}
        <CustomTable
          loading={loading}
          columns={columns}
          tableData={tableData}
          emptyTableMessage="You haven't created any templates. Read <a class='text-decoration-line: underline' target='_blank' href='https://docs.engagespot.com/docs/features/templates/introduction'>this guide</a> to learn how to create templates"
          rowClick={row => {
            const selected = row.original;
            handleMenuClick(selected, 'view_templates');
          }}
          extraTdClass={'hover:cursor-pointer'}
          paginationType="table"
        />
      </div>
    </>
  );
}

export default AnalyticsTemplatesTable;
