import axios from 'axios';
import {
  axiosRequestInterceptor,
  axiosResponseErrorInterceptor,
} from './common';
import { useAuthStore } from 'store/authStore';
import { REGIONS } from 'variables/regions';
import { API_BASE_URLS, DEFAULT_API_BASE_URL } from 'variables/apiBaseUrls';

export const axiosInstance = axios.create({
  baseURL: DEFAULT_API_BASE_URL,
});

console.log('Creating axios instance with ', DEFAULT_API_BASE_URL);

axiosInstance.interceptors.request.use(
  config => {
    const { region } = useAuthStore.getState();

    const axiosConfig = axiosRequestInterceptor(config);

    axiosConfig.baseURL =
      API_BASE_URLS[region as REGIONS] ?? DEFAULT_API_BASE_URL;

    return axiosConfig;
  },
  err => Promise.reject(err),
);

axiosInstance.interceptors.response.use(
  response => response,
  axiosResponseErrorInterceptor,
);
