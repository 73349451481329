import { AnimatePresence, motion } from 'framer-motion';

const icon = {
  hidden: {
    opacity: 0.4,
    pathLength: 0,
    fill: 'rgba(255, 255, 255, 0.4)',
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    fill: 'rgba(255, 255, 255, 1)',
  },
};

const EmailAnimationPath = () => (
  <div className="w-[150px] h-[100px] flex justify-center items-center overflow-hidden rounded-[30px]">
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      className="w-[56%] overflow-visible stroke-white stroke-[2] stroke-linejoin-round stroke-linecap-round"
    >
      <motion.path
        d="M0 30 L50 70 L100 30 V90 H0 Z M0 30 L50 0 L100 30"
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          default: {
            duration: 2,
            ease: 'easeInOut',
            repeat: Infinity, // Repeat the animation infinitely
            repeatType: 'loop', // Specifies the type of repetition
          },
          fill: {
            duration: 2,
            ease: [1, 0, 0.8, 1],
            repeat: Infinity, // Repeat the fill animation infinitely
            repeatType: 'loop', // Specifies the type of repetition
          },
        }}
      />
    </motion.svg>
  </div>
);

export const EmailLoadingAnimation = ({
  isLoading,
}: {
  isLoading: boolean;
}) => {
  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          key={'email-loading-animation'}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
          className="absolute pointer-events-none z-[9999] flex flex-col gap-2 h-full w-full items-center justify-center bg-gray-850"
        >
          <EmailAnimationPath />

          <p className="text-white">
            Loading your email. Hang tight, we'll have it ready in just a
            moment.
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
