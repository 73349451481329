import { useDisclosure } from '@chakra-ui/hooks';
import useGetTopicUsers from 'api/topics/get-topic-users';
import { TopicSubscriber } from 'api/topics/get-topic-users/types';
import useGetTopics from 'api/topics/get-topics';
import { GetTopics } from 'api/topics/get-topics/types';
import PageHeader from 'components/header/PageHeader';
import React from 'react';
import { MdKeyboardBackspace } from 'react-icons/md';
import { useNavigate, useSearchParams } from 'react-router-dom';
import TopicsModal from './components/TopicsModal';
import TopicsTable from './components/TopicsTable';
import TopicsUsersModal from './components/TopicsUsersModal';
import TopicsUsersTable from './components/TopicsUsersTable';

const Topics = () => {
  const [searchParams] = useSearchParams();
  const topicId = new URLSearchParams(searchParams).get('topicId');
  const [filter, setFilter] = React.useState('');
  const getTopics = useGetTopics();
  const addTopicsModalActions = useDisclosure();
  const topicUsersModalActions = useDisclosure();
  const navigate = useNavigate();
  const [selectedTopic, setSelectedTopic] = React.useState<GetTopics>(null);
  const [selectedTopicUser, setSelectedTopicUser] =
    React.useState<TopicSubscriber>(null);
  const getTopicUsers = useGetTopicUsers(topicId);

  React.useEffect(() => {
    if (!topicId && !getTopics.isLoading) {
      getTopics.refetch();
    }
  }, [getTopics, topicId]);

  return (
    <div className="grid h-full w-full grid-cols-12 gap-0 rounded-[20px]">
      <TopicsModal
        isOpen={addTopicsModalActions.isOpen}
        onClose={addTopicsModalActions.onClose}
        selectedTopic={selectedTopic}
      />

      <TopicsUsersModal
        isOpen={topicUsersModalActions.isOpen}
        onClose={topicUsersModalActions.onClose}
        selectedTopicUser={selectedTopicUser}
      />

      {/* Header */}
      {topicId && (
        <MdKeyboardBackspace
          onClick={() => navigate(-1)}
          color="white"
          size={25}
          className="relative left-2 bottom-2 cursor-pointer"
        />
      )}

      <div className="col-span-12">
        {topicId && (
          <>
            <div className="flex gap-6">
              {getTopicUsers.isFetched && (
                <>
                  <div>
                    <h6 className="text-gray-10 text-[15px]">Topic Name</h6>
                    <p className="text-xl text-white">
                      {getTopicUsers.data?.name}
                    </p>
                  </div>

                  <div>
                    <h6 className="text-gray-10 text-[15px]">Identifier</h6>
                    <p className="text-xl text-yellow-450">
                      {getTopicUsers.data?.identifier}
                    </p>
                  </div>

                  <div>
                    <h6 className="text-gray-10 text-[15px]">
                      No of Subscribers
                    </h6>
                    <p className="text-xl text-yellow-450">
                      {getTopicUsers.data?.subscribers?.length}
                    </p>
                  </div>
                </>
              )}
            </div>
          </>
        )}

        <PageHeader
          handleCreate={() => {
            setSelectedTopic(null);
            setSelectedTopicUser(null);

            if (topicId) {
              topicUsersModalActions.onOpen();
            } else {
              addTopicsModalActions.onOpen();
            }
          }}
          paginationType="table"
          heading={topicId ? '' : 'Topics'}
          text={'Topics are used to categorize recipients'}
          setFilter={setFilter}
          filter={filter}
          buttonText={topicId ? 'Add Users' : 'Create Topic'}
          headingClassName={topicId ? 'hidden' : ''}
        />
      </div>

      {/* Tables */}
      <>
        {topicId ? (
          <TopicsUsersTable
            tableData={getTopicUsers?.data?.subscribers ?? []}
            editOpen={topicUsersModalActions.onOpen}
            globalFilter={filter}
            setGlobalFilter={setFilter}
            setSelectedTopicUser={setSelectedTopicUser}
            selectedTopicUser={selectedTopicUser}
            loading={getTopicUsers.isLoading}
          />
        ) : (
          <TopicsTable
            loading={getTopics.isLoading}
            tableData={getTopics?.data ?? []}
            editOpen={addTopicsModalActions.onOpen}
            globalFilter={filter}
            setGlobalFilter={setFilter}
            setSelectedTopic={setSelectedTopic}
            selectedTopic={selectedTopic}
          />
        )}
      </>
    </div>
  );
};

export default Topics;
